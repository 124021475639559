import { combineReducers } from 'redux';
import JobTaskReducer from './JobTaskReducer';
import JobQuoteReducer from './JobQuoteReducer';
import UserReducer from './UserReducer';
import AssetReducer from './AssetReducer';

const rootReducer = combineReducers({
	JobTask: JobTaskReducer,
	User: UserReducer,
	AssetsInfo: AssetReducer,
	JobQuotesInfo: JobQuoteReducer
});

export default rootReducer;
