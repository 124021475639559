import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import { useTranslation } from 'react-i18next';

const GoogleMapsModal = props => {
	const [t, i18n] = useTranslation();
	return (
	<Dialog
		open={props.open}
		onClose={props.handleGoogleMapsDialogVisibility}
		aria-labelledby={t("Google Map For Site")}
		fullScreen={true}
		maxWidth="lg"
	>
		<iframe
			style={{ width: '100%', height: '100%' }}
			src={`https://maps.google.com/maps?q=${props.latitude},${props.longitude}&output=embed`}
		/>

		<DialogActions>
			<Button
				onClick={props.handleGoogleMapsDialogVisibility}
				color="primary"
				variant="contained"
				style={{ backgroundColor: 'var(--light-red)', color: 'white' }}
			>
				<Icon>close</Icon>&nbsp; {t("Close")}
			</Button>
		</DialogActions>
	</Dialog>
)};

export default GoogleMapsModal;
