import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import { Button, Icon } from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import FieldHolder from '../../components/styled-components/FieldHolder';
import { fire } from '../..';
import { parseDate2 } from '../../utils/Times';
import { getLocation } from '../../utils/Geolocation';
import SubmitFormModal from './SubmitFormModal';
import { withTranslation } from 'react-i18next';

interface FormHeaderProps {
  formDetails: Forms.ExtraFormDetails;
  readyToSubmit: boolean;
  taskNumber: string;
  fromFormLibrary: boolean;
  assetName?: string | null;
  siteName?: string | null;
  formTemplateFBID?: string;
  history?: History;
  t: any;
  match?: match;
  fromFormLib?: boolean;
}

interface FormHeaderState {
  submitFormModalOpen: boolean;
  completedDate: number;
}

class FormHeader extends React.Component<FormHeaderProps, FormHeaderState> {
  constructor(props: FormHeaderProps) {
    super(props);
    this.state = {
      submitFormModalOpen: false,
      completedDate: Date.now(),
    };
  }

  async submitForm() {
    const geoLocation = await getLocation();
    await fire.submitForm(
      this.props.formDetails.Id,
      this.props.formDetails.formRef,
      geoLocation.Latitude,
      geoLocation.Longitude,
      this.state.completedDate,
    );

    if (
      this.props.fromFormLibrary &&
      !isNullOrUndefined(this.props.formTemplateFBID) &&
      !isNullOrUndefined(this.props.history)
    ) {
      await fire.UpdateFormLibrary(this.props.formTemplateFBID, null, null);

      //update form template, so resource app form library updates automatically.
      await fire.UpdateFormTemplateFormSubmit(this.props.formTemplateFBID, this.props.formDetails.Id);
    }
    if (this.props.fromFormLibrary) {
      this.props.history.push(`/form-library`);
    } else {
      const currentForm = this.props.match.params.jobtaskid;
      if (currentForm === "Asset") {
        this.props.history.push(`/asset-nmdetails/${this.props.formDetails.AssetFBID}/list/list`);
      } else if (currentForm === "Site") {
        this.props.history.push(`/site-details/${this.props.formDetails.SiteFBID}`);
      } else if (currentForm === "Location") {
        this.props.history.push(`/location-details/${this.props.formDetails.LocationFBID}`);
      } else if (currentForm === "Sublocation") {
        this.props.history.push(`/sublocation-details/${this.props.formDetails.SublocationFBID}`);
      } else if (this.props.formDetails.JobTaskFBID) {
        this.props.history.push(`/job-task-details/${this.props.formDetails.JobTaskFBID}`);
      }
    }
  }

  handleFormSubmit = () => {
    this.handleSubmitFormModal();
    this.submitForm();
  }

  handleSubmitFormModal = () => {
    this.setState({
      submitFormModalOpen: !this.state.submitFormModalOpen,
    });
  };

  handleSubmitFormDate = (value: number) => {
    this.setState({
      completedDate: value,
    });
  };

  render() {
    return (
      <div className="top-card card-shadow" style={{ border: '1px solid grey' }}>
        {this.props.fromFormLibrary ?
          <FieldHolder content={this.props.formDetails.FormName} label={this.props.t("Form Name")} />
          :
          <FieldHolder fullLine={true} content={this.props.formDetails.FormName} label={this.props.t("Form Name")} />
        }
        {this.props.fromFormLibrary ?
          <FieldHolder
            content={
              this.props.formDetails.CurrentFormDate !== null && this.props.formDetails.CurrentFormDate !== undefined
                ? parseDate2('Date', this.props.formDetails.CurrentFormDate.toString())
                : ''
            }
            label={this.props.t("Form Date")}
          />
          : null}

        {!this.props.fromFormLibrary ? <FieldHolder content={this.props.taskNumber} label={this.props.t("Job Task")} /> : null}
        {this.props.fromFormLibrary ? <FieldHolder content={this.props.formDetails.LastUpdatedByUserName} label={this.props.t("Last Update By")} /> : null}
        {this.props.fromFormLibrary ?
          <FieldHolder
            content={
              this.props.formDetails.LastUpdatedDate !== null && this.props.formDetails.LastUpdatedDate !== undefined
                //? unixToDateString(this.props.formDetails.LastUpdatedDate)
                ? parseDate2('DateTime', this.props.formDetails.LastUpdatedDate.toString())
                : ''
            }
            label={this.props.t("Last Update Date")}
          />
          : null}
        <FieldHolder content={this.props.t(this.props.formDetails.Status)} label={this.props.t("Status")} />

        {!isNullOrUndefined(this.props.formDetails.CompletedDate) ?
          <FieldHolder
            content={
              this.props.formDetails.CompletedDate !== null && this.props.formDetails.CompletedDate !== undefined
                ? parseDate2('DateTime', this.props.formDetails.CompletedDate.toString())
                : 'N/A'
            }
            label={this.props.t("Submitted Date")}
          />
          : null}

        {/* Site, location, sub location, asset name */}
        {this.props.formDetails.SiteName && (
          <FieldHolder content={this.props.formDetails.SiteName} label={this.props.t('Site')} />
        )}

        {this.props.formDetails.LocationName && (
          <FieldHolder content={this.props.formDetails.LocationName} label={this.props.t('SubLocation Name')} />
        )}

        {this.props.formDetails.SubLocationName && (
          <FieldHolder content={this.props.formDetails.SubLocationName} label={this.props.t('SubLocation Name')} />
        )}

        {this.props.formDetails.AssetName && (
          <FieldHolder content={this.props.formDetails.AssetName} label={this.props.t('Asset Name')} />
        )}

        {this.props.readyToSubmit && isNullOrUndefined(this.props.formDetails.CompletedDate) ?
          <Button
            onClick={this.handleSubmitFormModal}
            style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
            className={'full-line'}
            color="primary"
          >
            <Icon style={{ fontSize: 20 }}>save</Icon>&nbsp;
            <p style={{ marginTop: 2 }}>{this.props.t("Submit")}</p>
          </Button>
          : null}

        <SubmitFormModal
          handleSubmitFormDate={this.handleSubmitFormDate}
          handleSubmitFormModal={this.handleSubmitFormModal}
          submitForm={this.handleFormSubmit}
          submitFormModalOpen={this.state.submitFormModalOpen}
          value={this.state.completedDate || Date.now()}
        />
      </div>
    );
  }
};

export default withTranslation()(FormHeader)
