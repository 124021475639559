/* eslint-disable prettier/prettier */
import Firebase from '../../Firebase';
import IndexDb from './IndexDb';
import TrackplanDatabase from './TrackplanDb';
import Dexie from 'dexie';
import { installOfflineWatcher } from '../../Network';
import {
	TaskLeaveSite,
	NewJobRequest,
	AddPhotoExistingJobTask,
	AddDocumentExistingJobTask,
	AddExpense,
	AddDocumentToExpense,
	AddFormPhotoAnswer,
	AddFormDocumentAnswer,
	CreateRequestPhotosAdd,
	AddPhoto,
	AddDocument,
} from './LocalQueueActions';
import { generateGuid } from '../../Guids';

export default class LocalQueue {
	fire: Firebase;
	indexDb: IndexDb;
	db: TrackplanDatabase;

	constructor(firebase: Firebase, indexDb: IndexDb) {
		this.fire = firebase;
		this.indexDb = indexDb;
		this.db = this.indexDb.db;

		//creates a listener that watches queue table and calls handleNewEntryToLocalQueue when fired
		this.queueListener();

		//watches when back online and gets items in queue
		installOfflineWatcher(offline => {
			if (!offline) {
				this.getDocumentsInLocalQueue();
			}
		});
	}

	queueListener() {
		this.db.localQueue.hook('creating', (key: string, object: indexDb.LocalQueue, transaction: Dexie.Transaction) =>
			this.handleNewEntryToLocalQueue(object),
		);
	}

	// Saves event to local queue, this event will be processed by another function that watches queue
	async saveToLocalJobQueue(
		documentId: string,
		jobTaskQueueObject: object,
		docToUpdateObject?: object,
		docToUpdateRef?: string,
		guid?: string,
		arrayBuffer?: ArrayBuffer,
		fileName?: string,
		PhotoFBID?: string
	) {
		//Converts objects to strings as db will not accept objects as is
		const docObjectSerialized = JSON.stringify(docToUpdateObject);
		const jobTaskObjectSerialized = JSON.stringify(jobTaskQueueObject);

		//Writes transaction to the db
		return this.db
			.transaction('rw!', this.db.localQueue, () => {
				const newQueueObject: indexDb.LocalQueue = {
					id: guid === undefined ? generateGuid() : guid,
					documentId,
					jobTaskQueueObject: jobTaskObjectSerialized,
					docToUpdateObject: docObjectSerialized,
					docToUpdateRef,
					clientID: this.fire.ClientID as string,
					userUID: this.fire.currentUser.uid,
					arrayBuffer,
					fileName,
					photoId: PhotoFBID
				};

				return this.db.localQueue.add(newQueueObject).catch(err => console.error(err));
			})
			.catch(err => console.error(`Error when adding object to localJobQueue - error : ${err}`));
	}

	// gets all the objects in the queue and sends to handle method which process records, this method is called on init of app
	getDocumentsInLocalQueue() {
		this.db.localQueue.toArray().then(async (records) => {
			for (const localQueueObj of records) {
				if (!navigator.onLine) break;
				await this.handleNewEntryToLocalQueue(localQueueObj);
			}
		});
	}

	//this will watch the local queue and fire when a record has been added
	async handleNewEntryToLocalQueue(object: indexDb.LocalQueue) {
		//if offline or no one is logged in, exit
		if (!navigator.onLine || this.fire.currentUser === null) return;

		//parses the strings back to objects
		const docToUpdateObject = object.docToUpdateObject !== undefined ? JSON.parse(object.docToUpdateObject) : undefined;
		const jobTaskQueueObject = JSON.parse(object.jobTaskQueueObject);

		//gets the job queue action
		const jobQueueAction = jobTaskQueueObject['JobAction'];

		switch (jobQueueAction) {
			case 'TaskLeaveSite':
				return TaskLeaveSite(
					jobTaskQueueObject,
					docToUpdateObject,
					object,
					this.removeItemFromQueue,
					this.removeJobRequestPhotos,
					this.getJobRequestPhoto,
					this.fire,
				);

			case 'NewJobRequest':
				return NewJobRequest(
					jobTaskQueueObject,
					object,
					this.removeItemFromQueue,
					this.removeJobRequestPhotos,
					this.getJobRequestPhotos,
					this.fire,
				);

			case 'AddPhotoExistingJobTask':
				return await AddPhotoExistingJobTask(
					object,
					this.getPhoto,
					this.removeItemFromQueueByItemId,
					jobTaskQueueObject,
					this.fire
				);

			case 'AddDocumentExistingJobTask':
				const documentGuid = jobTaskQueueObject['DocumentGuid'];
				const documentNewFBID = jobTaskQueueObject['DocumentNewFBID'];
				return AddDocumentExistingJobTask(
					object,
					this.getDocumentByGuid,
					this.removeItemFromQueue,
					documentGuid,
					documentNewFBID,
					this.fire,
				);

			case 'AddDocument':
				const isPhoto = jobTaskQueueObject['IsPhoto'];
				const associatedFBID = jobTaskQueueObject['AssociatedFBID'];
				const type = jobTaskQueueObject['Type'];

				if (isPhoto) {
					return await AddPhoto(
						object,
						this.getPhoto,
						this.removeItemFromQueueByItemId,
						jobTaskQueueObject,
						this.fire,
						type,
						associatedFBID
					);
				}
				const documentID = jobTaskQueueObject['DocumentGuid'];
				const docNewFBID = jobTaskQueueObject['DocumentNewFBID'];

				return AddDocument(
					object,
					this.getDocument,
					this.removeItemFromQueue,
					documentID,
					docNewFBID,
					this.fire,
					associatedFBID,
					type
				);

			case 'AddExpense':
				return AddExpense(object, this.getDocumentsForExpense, this.removeItemFromQueue, this.fire);

			case 'AddDocumentExistingExpense':
				return AddDocumentToExpense(object, this.getDocumentByGuid, this.removeItemFromQueue, this.fire);

			case 'FormAnswer':
				if (
					jobTaskQueueObject.QuestionType === 'Photo' ||
					jobTaskQueueObject.QuestionType === 'Pass/Fail' ||
					jobTaskQueueObject.QuestionType === 'Yes/No' ||
					jobTaskQueueObject.QuestionType === 'Signature'
				) {
					return await AddFormPhotoAnswer(object,
						docToUpdateObject,
						this.removeItemFromQueueByItemId,
						this.getPhoto,
						this.fire
					);
				} else {
					return AddFormDocumentAnswer(object, docToUpdateObject, this.removeItemFromQueue, this.getDocumentByDocId);
				}

			case 'NewRequestPhotos':
				return CreateRequestPhotosAdd(
					jobTaskQueueObject,
					object,
					this.getJobRequestPhotos,
					this.removeItemFromQueue,
					this.fire,
					this.indexDb,
				);
			default:
				break;
		}
	}

	// remove job item from local queue
	removeItemFromQueue = (firebaseId: string) => {
		return this.db.transaction('rw!', this.db.localQueue, async () => {
			await this.db.localQueue
				.where('documentId')
				.equals(firebaseId)
				.delete();
		});
	};


	removeItemFromQueueByItemId = (itemId: string) => {
		return this.db.transaction('rw!', this.db.localQueue, async () => {
			await this.db.localQueue
				.where('id')
				.equals(itemId)
				.delete();
		});
	};

	getDocument = (guid: string): Dexie.Promise<indexDb.Documents | undefined> => {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents
				.where('guid')
				.equals(guid)
				.first();
		});
	};

	// remove job request photos from local table
	removeJobRequestPhotos = (guid: string) => {
		return this.db.transaction('rw!', this.db.requestPhotos, async () => {
			await this.db.requestPhotos
				.where('guid')
				.equals(guid)
				.delete();
		});
	};

	RemovePhotos = (documentId: string) => {
		return this.db.transaction('rw!', this.db.photos, async () => {
			await this.db.photos
				.where('documentId')
				.equals(documentId)
				.delete();
		});
	};

	deletePhoto = (id: string) => {
		return this.db.transaction('rw!', this.db.photos, async () => {
			await this.db.photos
				.where('id')
				.equals(id)
				.delete();
		});
	};

	// get photo from db
	getPhoto = (guid: string): Dexie.Promise<indexDb.Photos | undefined> => {
		return this.db.transaction('r!', this.db.photos, async () => {
			return await this.db.photos
				.where('guid')
				.equals(guid)
				.first();
		});
	};

	// get photo from db
	getJobRequestPhoto = (guid: string): Dexie.Promise<indexDb.RequestPhotos | undefined> => {
		return this.db.transaction('r!', this.db.requestPhotos, async () => {
			return await this.db.requestPhotos
				.where('guid')
				.equals(guid)
				.first();
		});
	};

	getJobRequestPhotos = (guid: string): Dexie.Promise<indexDb.RequestPhotos[]> => {
		return this.db.transaction('r!', this.db.requestPhotos, async () => {
			return await this.db.requestPhotos
				.where('guid')
				.equals(guid)
				.toArray();
		});
	};

	// get photo from db
	getPhotos = (guid: string): Dexie.Promise<indexDb.Photos[]> => {
		return this.db.transaction('r!', this.db.photos, async () => {
			return await this.db.photos
				.where('guid')
				.equals(guid)
				.toArray();
		});
	};

	// get documents from db
	getDocumentByGuid = (guid: string): Dexie.Promise<indexDb.Documents | undefined> => {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents
				.where('guid')
				.equals(guid)
				.first();
		});
	};

	getDocumentByDocId = (documentId: string): Dexie.Promise<indexDb.Documents | undefined> => {
		return this.db.transaction('r!', this.db.documents, async () => {
			return await this.db.documents
				.where('documentId')
				.equals(documentId)
				.first();
		});
	};

	// get documents from db for expense - documentId is the Id of the expense
	getDocumentsForExpense = (documentId: string): Dexie.Promise<indexDb.RequestDocuments[] | undefined> => {
		return this.db.transaction('r!', this.db.requestDocuments, async () => {
			return await this.db.requestDocuments
				.where('guid')
				.equals(documentId)
				.toArray();
		});
	};

	// remove documents from documents  - documentId is the Id of the expense (or maybe job task)
	removeDocuments = (documentId: string): Dexie.Promise<number> => {
		return this.db.transaction('rw!', this.db.documents, async () => {
			return await this.db.documents
				.where('documentId')
				.equals(documentId)
				.delete();
		});
	};
}
function isNullOrUndefined(arrayBuffer: ArrayBuffer | undefined) {
	throw new Error('Function not implemented.');
}

