import { useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { isNullOrUndefined } from 'util';
import { fire } from '../../index';
import { orderAlphabetically } from '../../utils/forms/FormHelpers';
import { generateFirebaseId } from '../../utils/Guids';

interface associatedFormResource {
	NewFormCreated: string;
	FormNewQuestionAnswer: string;
	AssociatedFBID: string;
}

const useFormsListCards = (associatedFBID: string, fromView: string, areTemplatesRestricted: boolean, restrictedTemplates: string[]) => {
	const [forms, setForms] = useState<Forms.Form[]>([]);
	const [displayDialog, setDisplayDialog] = useState(false);
	const [availableForms, setAvailableForms] = useState<FormTemplates.FormTemplate[]>([]);
	const [associatedResource, setAssociatedResource] = useState<associatedFormResource>();
	const history = useHistory();
	const [formLoading, setFormLoading] = useState(false);

	useEffect(() => {
		switch (fromView) {
			case "Site":
				fire.getFormsForSite(associatedFBID)
					.get().then(handleForms);
				break;

			case "Location":
				fire.getFormsForLocation(associatedFBID)
					.get().then(handleForms);
				break;

			case "Sublocation":
				fire.getFormsForSublocation(associatedFBID)
					.get().then(handleForms);
				break;

			case "Asset":
				fire.getFormsForAsset(associatedFBID)
					.get().then(handleForms);
				break;
		}

		getAvailableForms();
	}, []);

	const getAvailableForms = () => {
		if (areTemplatesRestricted) {
			getRestrictedTemplates();
			return;
		}

		fire.getAvailableForms().then(forms => {
			// @ts-ignore
			if (forms.empty) {
				setAvailableForms([]);
				return;
			}

			// @ts-ignore
			let formsArray: FormTemplates.FormTemplate[] = [];
			forms.docs.forEach(doc => {
				const data = doc.data() as FormTemplates.FormTemplate;
				data.Id = doc.id;
				formsArray.push(data);
			});

			forms.docs.forEach(doc => doc.ref.collection('QuestionAnswers').get());

			//Filter the form templates: only show templates that either are not restricted to a site/location, or that are restricted but to the
			//current rendered site / location.
			const filteredFormArray = formsArray.filter(form => {
				var templateContainsFBID = false;
				if (form.RestrictedSitesLocations != null)
					templateContainsFBID = form.RestrictedSitesLocations.includes(associatedFBID);

				return ((form.RestrictedToSiteLocation == false || isNullOrUndefined(form.RestrictedToSiteLocation)) || templateContainsFBID);
			});

			//sort forms
			orderAlphabeticallyArray(filteredFormArray)

			setAvailableForms(filteredFormArray);
		});
	};

	const handleClick = async (row: Forms.Form) => {
		history.push(`/form-details/${fromView}/${row.Id}`);
	}

	const attachForm = async (selectedForm: FormTemplates.FormTemplate) => {
		if (!selectedForm) return;
		const FormTemplateFBID = selectedForm.Id;
		const FormFBID = await generateFirebaseId();
		var newFormData = generateFormData(selectedForm);

		setFormLoading(true)
		await fire.CreateForm(newFormData, FormFBID);
		await fire.postToJobQueue(FormFBID, {
			JobAction: "NewFormCreated",
			AssociatedFBID: associatedFBID,
			FormTemplateFBID: FormTemplateFBID,
			FormFBID: FormFBID,
			Type: fromView
		})
		await fire.getFormTemplateSections(FormTemplateFBID).then(async sectionsTemplate => {
			let sectionsData = sectionsTemplate.data();

			await fire.getFormTemplateQuestions(FormTemplateFBID).then(async questions => {
				if (questions.docs.length > 0) {
					var parsedQuestions = questions.docs;
					var sections: any = [];
					if (sectionsData && sectionsData.Sections) {
						sections = sectionsData.Sections;
					} else {
						alert("firebase could not retrieve the sections information")
						return
					}
					for (const question of parsedQuestions) {
						const QuestionAnswerFBID = generateFirebaseId();
						const data = question.data() as Forms.QuestionAnswer;
						const QuestionFBID = question.id;

						if (data.IsRepeatable) {
							const section = sections.find(x => x.SectionName === data.Section.Name); /* avoid grouping name in the future */

							if (section) {
								if (section.RepeatableSections[0] === undefined) {
									section.RepeatableSections.push(QuestionAnswerFBID);
								} else {
									section.RepeatableSections[0] = section.RepeatableSections[0] + "," + QuestionAnswerFBID;
								}
							} else {
								var newSectionTest = await sections.push({ SectionName: data.Section.Name, IsRepeatable: true, RepeatableSections: [[QuestionAnswerFBID]], TemplateSectionID: data.Section.TemplateSectionID });
							}
						};

						if (data.TemplateSectionID <= 0)
							data.TemplateSectionID = 1;
						await fire.CreateFormQuestionAnswers(data, FormFBID, QuestionAnswerFBID);
						await fire.postToJobQueue(
							QuestionAnswerFBID,
							{
								JobAction: "FormNewQuestionAnswer",
								AssociatedFBID: associatedFBID,
								FormTemplateFBID: FormTemplateFBID,
								FormFBID: FormFBID,
								QuestionFBID: QuestionFBID,
								TemplateResponseFBID: FormFBID,
								DateCreated: new Date().getTime(),
							},
							new Date().getTime() + '-' + QuestionAnswerFBID)
					};

					newFormData.Sections = sections;
					await fire.updateForm(FormFBID, newFormData);
				}
			});
		})

		setFormLoading(false);
		setDisplayDialog(!displayDialog);
		history.push(`/form-details/${fromView}/${FormFBID}`);
		return;
	};

	const generateFormData = (selectedForm: FormTemplates.FormTemplate) => {
		var formData = {
			CompletedByUser: null,
			CompletedDate: null,
			CurrentFormDate: null,
			FormName: selectedForm.FormName,
			FormTemplateFBID: selectedForm.Id,
			FormType: selectedForm.FormType,
			IsCompleted: false,
			IsRequiredForJobCompletion: false,
			AssociatedFBID: associatedFBID,
			Status: 'Pending',
			LastUpdatedDate: null,
			LastUpdatedByUserFBID: null,
			LastUpdatedByUserName: null,
			CreatedDate: new Date().getTime(),
			Type: fromView,
			Sections: {},
			JobFBID: null,
			JobTaskFBID: null
		};

		if (fromView == "Site") {
			formData["SiteFBID"] = associatedFBID;
		} else {
			formData["SiteFBID"] = null;
		}

		if (fromView == "Location") {
			formData["LocationFBID"] = associatedFBID;
		} else {
			formData["LocationFBID"] = null;
		}

		if (fromView == "Asset") {
			formData["AssetFBID"] = associatedFBID;
		} else {
			formData["AssetFBID"] = null;
		}

		if (fromView == "Sublocation") {
			formData["SublocationFBID"] = associatedFBID;
		} else {
			formData["SublocationFBID"] = null;
		}

		return formData;

	};

	const handleForms = async (querySnapshot: any) => {
		if (querySnapshot.empty)
			return setForms([]);

		const formsArray = await querySnapshot.docs.map(async doc => {
			const data = doc.data() as Forms.Form;
			data.Id = doc.id;

			return data;
		});

		Promise.all(formsArray).then(forms => {
			querySnapshot.docs.forEach(doc => doc.ref.collection('QuestionAnswers').get());
			forms = sortingFunction(forms)
			setForms(forms as Forms.Form[])
		});
	};

	const sortingFunction = (formsList: any) => {
		return formsList.sort((a, b) => {
			if (a.CreatedDate && b.CreatedDate) {
				if (a.CreatedDate === b.CreatedDate) return 0;
				else if (a.CreatedDate === 0) return -1;
				else if (b.CreatedDate === 0) return 1;
				else if (a.CreatedDate < b.CreatedDate) return 1;
				else if (b.CreatedDate < a.CreatedDate) return -1;
				else return 0;
			}
			else { return -1 }
		});
	};

	const getRestrictedTemplates = async () => {
		// @ts-ignore
		let formsArray: FormTemplates.FormTemplate[] = [];

		for (var templateFBID of restrictedTemplates) {
			if (templateFBID == null) return;

			await fire.getFormTemplate(templateFBID).then(async doc => {
				var formTemplate = await doc.data() as FormTemplates.FormTemplate;
				formTemplate.Id = templateFBID;
				formsArray.push(formTemplate);
			});
		};

		setAvailableForms(formsArray);
	};

	return { forms, displayDialog, setDisplayDialog, availableForms, attachForm, handleClick, formLoading };
};

export default useFormsListCards;


function orderAlphabeticallyArray(list: FormTemplates.FormTemplate[]) {
	return list.sort((a, b) => orderAlphabetically(a.FormName.toLowerCase(), b.FormName.toLowerCase()));
}

