import * as React from "react";
import CircularProgress from "@material-ui/core/CircularProgress";

const ImageLoadingSpinner = () => (
    <div
        className="grid-photo"
        style={{
            backgroundColor: "grey",
            display: "flex",
            alignItems: "center",
            justifyContent: "center"
        }}
    >
        <CircularProgress size={40} style={{ color: "white" }} />
    </div>
);

export default ImageLoadingSpinner;