import * as React from 'react';
import QuestionLayout from './QuestionLayout';
import { FormGroup, FormControlLabel, Checkbox } from '@material-ui/core';
import { fire } from '../..';
import { orderAlphabetically } from '../../utils/forms/FormHelpers';

interface MultiSelectProps {
	data: Forms.QuestionAnswer;
	formComplete: boolean;
}
interface MultiSelectState {
	answers: Forms.MultiSelectAnswer[];
}

export default class MultiSelectQuestion extends React.Component<MultiSelectProps, MultiSelectState> {
	constructor(props: Readonly<MultiSelectProps>) {
		super(props);
		this.state = {
			answers: this.orderAlphabeticallyArray(this.props.data.MultiSelectAnswers),
		};
	}

	UNSAFE_componentWillReceiveProps(newProps: MultiSelectProps) {
		if (
			newProps.data.MultiSelectAnswers !== this.props.data.MultiSelectAnswers &&
			newProps.data.MultiSelectAnswers !== null
		) {
			this.setState({
				answers: this.orderAlphabeticallyArray(newProps.data.MultiSelectAnswers),
			});
		}
	}

	orderAlphabeticallyArray(list: Forms.MultiSelectAnswer[]) {
		return list.sort((a, b) => orderAlphabetically(a.Value.toLowerCase(), b.Value.toLowerCase()));
	}

	onClick = (dropDownId: number, Checked: boolean) => {

		const array: Forms.MultiSelectAnswer[] = this.state.answers;
		const index = array.findIndex(obj => obj.Id === dropDownId);
		array[index].Selected = Checked;

		this.setState({ answers: array });

		this.props.data.questionRef.update({ MultiSelectAnswers: array });
		fire.answerQuestion({ ...this.props.data, Answer: dropDownId.toString() }, { Checked });
		fire.UpdateFormAfterAnswer(this.props.data.FormFBID);
	};

	render() {
		return (
			<>
				<QuestionLayout>
					{this.props.data.QuestionTitle}
					<MultiSelectAnswer
						dropdownAnswers={this.state.answers}
						onClick={this.onClick}
						formComplete={this.props.formComplete}
					/>
				</QuestionLayout>
			</>
		);
	}
}

const MultiSelectAnswer = props => {
	if (props.dropdownAnswers === undefined || props.dropdownAnswers === null) return null;
	return (
		<FormGroup>
			{props.dropdownAnswers.map(answer => (
				<CheckBoxAnswer
					key={answer.Id}
					classes={{ root: 'multi-select-checkbox' }}
					answer={answer}
					onClick={props.onClick}
					value={answer.Value}
					disabled={props.formComplete}
				/>
			))}
		</FormGroup>
	);
};

const CheckBoxAnswer = props => {

	const [placeholderValue, setPlaceholderValue] = React.useState(false)

	const onClick = (answerId, checked) => {
		props.onClick(answerId, checked)
	}

	React.useEffect(() => {
		setPlaceholderValue(props.answer.Selected)
	}, [])

	React.useEffect(() => {
		setPlaceholderValue(props.answer.Selected)
	}, [props.answer.Selected])

	return (
		<FormControlLabel style={{ paddingBottom: '8px', paddingLeft: '8px' }}
			control={
				<Checkbox
					key={`checkAnswer_${props.answer.Id}`}
					classes={props.classes}
					checked={placeholderValue}
					onChange={(_, checked) => onClick(props.answer.Id, checked)}
					value={props.answer.Value}
					disabled={props.disabled}
				/>
			}
			label={props.answer.Value}
		/>
	);
}
