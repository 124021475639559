import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import { isNullOrUndefined } from 'util';

// Fire
import { fire } from '../../index';

// Shared Components
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';

// Material UI
import {
    Button,
    Grid,
    IconButton,
    Icon,
} from '@material-ui/core';

import { unixToDateString } from '../../utils/Times';
import EditCostDialog from '../../components/costs/EditCostDialog';
import { withTranslation } from 'react-i18next';

interface JobCostDetailsProps {
    history: History;
    match: match;
    t: any;
}

interface JobCostDetailsState {
    loading: boolean;
    JobTaskFBID: string;
    JobCostFBID: string;
    JobCostDetails: Cost.Cost | null;
    editCostDialogOpen: boolean;
    TotalAmount: number;
}

class JobCostDetails extends React.Component<JobCostDetailsProps, JobCostDetailsState> {
    
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            JobTaskFBID: '',
            JobCostFBID: '',
            JobCostDetails: null,
            editCostDialogOpen: false,
            TotalAmount: 0,
        }
    }

    componentDidMount() {
        this.setState({ JobCostFBID: this.props.match.params.id }, () => {
            this.getJobCost();
        })
    }

    getJobCost = () => {
        this.setState({ loading: true });


        if(!isNullOrUndefined(this.state.JobCostFBID)) {
            fire.baseQuery
                .collection('Costs')
                .doc(this.state.JobCostFBID)
                .onSnapshot(snap => {
                    const CostData = snap.data() as Cost.Cost;
                    this.setState({ JobCostDetails: CostData }, this.calculateTotalAmount);
                })
        }

        this.setState({ loading: false });
    }

    calculateTotalAmount = () => {
        if(!isNullOrUndefined(this.state.JobCostDetails)) {
            const { Materials, Labour, CallOut } = this.state.JobCostDetails;
            const total = (+Materials) + (+Labour) + (+CallOut);
            const roundedTotal = (Math.round(total * 100) / 100).toFixed(2);
            this.setState({
                TotalAmount: parseFloat(roundedTotal)
            });
        }
    }

    deleteJobCost = (JobCostFBID) => {
        const confirm = window.confirm(this.props.t('Are you sure you want to delete the Job Cost?'));

        if(confirm) {
            
            fire.baseQuery
            .collection('Costs')
            .doc(JobCostFBID)
            .delete();
            
            const JobCostObj = {
                JobAction: 'CostDelete',
                JobCostFBID,
            }
            
            fire.postToJobQueue(JobCostFBID, JobCostObj).catch(err => {
                alert(this.props.t('There was an error removing the job cost. If problem persists please email - support@trackplanfm.com'));
                console.log(err);
            });

            if(!isNullOrUndefined(this.state.JobCostDetails)) {
                this.props.history.push(`/job-task-details/${this.state.JobCostDetails.JobTaskFBID}`);
            }
        }
    }

    handleEditCostDialog = () => {
        this.setState({ editCostDialogOpen: !this.state.editCostDialogOpen });
    }

    backButton = () => {
        if(!isNullOrUndefined(this.state.JobCostDetails)) {
            this.props.history.replace(`/job-task-details/${this.state.JobCostDetails.JobTaskFBID}`);
        }
    }

    checkStatusForActions = (status) => {
        if(status === 'Pending' || status === 'From Quote Approval') {
            return false;
        }
        return true;
    }
    
    render() {
        if(this.state.JobCostDetails !== null) {
            const { JobCostDetails } = this.state;
            return (
                <div>
                    <SideDrawer
                        history={this.props.history}
                        title={this.props.t("Job Cost")}
                        rightMenuButton={
                            <div>
                                <BackButton callbackMethod={this.backButton} />
                            </div>
                        }
                    />

                    <Grid container={true} direction="column" justify="center" alignItems="center" style={{ marginTop: '80px' }}>
                        <div className="main">
                            <div>
                                <div className="middle-row" style={{ padding: 20}}>
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Invoice Number:") + " "}</p>
                                        <p className="content">{JobCostDetails.InvoiceNumber || '#'}</p>
                                    </div>
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Invoice Date:") + " "}</p>
                                        <p className="content">{unixToDateString(JobCostDetails.InvoiceDate, true) || 'N/A'}</p>
                                    </div>
                                    <br />
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Description") + ": "}</p>
                                        <p className="content">{JobCostDetails.Description}</p>
                                    </div>
                                    <br />
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Labour") + ": "}</p>
                                        <p className="content">{ parseFloat(JobCostDetails.Materials).toFixed(2) }</p>
                                    </div>
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Materials") + ": "}</p>
                                        <p className="content">{ parseFloat(JobCostDetails.Labour).toFixed(2) }</p>
                                    </div>
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Other") + ": "}</p>
                                        <p className="content">{ parseFloat(JobCostDetails.CallOut).toFixed(2) }</p>
                                    </div>
                                    <br />
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Total Amount") + ": "}</p>
                                        <p className="content">{this.state.TotalAmount}</p>
                                    </div>
                                    <br /><br /><br />
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Status") + ": "}</p>
                                        <p className="content">{this.props.t(JobCostDetails.CostStatus)}</p>
                                    </div>
                                </div>

                                <Grid container alignItems="flex-start" justify="flex-end" direction="row" style={{ margin: '15px' }}>
                                    <EditCostDialog 
                                        open={this.state.editCostDialogOpen}
                                        closeModal={this.handleEditCostDialog}
                                        JobCostFBID={this.state.JobCostFBID}
                                        JobCostDetails={this.state.JobCostDetails}
                                    />
                                    <Button color="primary" disabled={this.checkStatusForActions(JobCostDetails.CostStatus)} onClick={() => this.handleEditCostDialog()} variant="contained" style={{ marginRight: 10 }}>
                                        <Icon>edit</Icon>&nbsp;{this.props.t("Edit")}
                                    </Button>
                                    <Button color="secondary" disabled={this.checkStatusForActions(JobCostDetails.CostStatus)} variant="contained" style={{ marginRight: 10 }} onClick={() => this.deleteJobCost(JobCostDetails.CostFBID)}>
                                        <Icon>delete</Icon>&nbsp;{this.props.t("Delete")}
                                    </Button>
                                </Grid>


                            </div>
                        </div>
                    </Grid>
                </div>
            )
        }

        return <LoadingSpinner color="primary" text={this.props.t("Loading Job Cost")} />
        
    }
};

export default withTranslation()(JobCostDetails);