// Third Party
import * as React from 'react';
import * as loadImage from 'blueimp-load-image';
import { History } from 'history';
import { Button, Dialog, DialogContent, DialogTitle, Grid, Icon, IconButton, Typography } from '@material-ui/core';
import TouchAppIcon from '@material-ui/icons/TouchApp';
import SignaturePad from 'signature_pad';
import { match } from 'react-router';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { isNullOrUndefined } from 'util';
import firebase from 'firebase';

// Local imports
import { fire, idb, localQueue } from '../../index';
import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import JobTaskActionButton from '../../components/job-task/JobTaskActionButton';
import AddNoteDialogPopup from '../../components/job-task/AddNoteDialogPopup';
import LeaveSiteDialogPopup from '../../components/job-task/LeaveSiteDialogPopup';
import GoogleMapsModal from '../../components/shared/GoogleMapsModal';
import BackButton from '../../components/shared/BackButton';
import AddStockModal from '../../components/job-task-stock/AddStockModal';

import FormsList from '../../components/forms/FormsList';
import JobTaskPhotosCard from '../../components/job-task/JobTaskPhotosCard';
import DocumentsCard from '../../components/shared/DocumentsCard';
import JobTaskQuotes from '../../components/job-task/JobTaskQuotes';
import JobTaskCosts from '../../components/job-task/JobTaskCosts';
import JobTaskStock from '../../components/job-task-stock/JobTaskStock';
import AssetJobList from '../../components/asset/AssetJobList';
import ExpensesListCard from '../Expenses/ExpensesListCard';
import JobTaskNotes from '../../components/job-task/JobTaskNotes';

// Utils
import { unixToDateString } from '../../utils/Times';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import { areAllFormsSubmitted, orderAlphabetically } from '../../utils/forms/FormHelpers';
import { generateFirebaseId, generateGuid } from '../../utils/Guids';
import { fileToArrayBuffer } from '../../utils/Converters';
import { windowError } from '../../utils/WindowError';
import { getLocation } from '../../utils/Geolocation';
import { areAllFormsComplete } from '../../utils/forms/FormHelpers';
import { JOB_DOCUMENT_SOURCE_FROM_APP } from '../../utils/database/indexdb/IndexDb';

// Styles
import '../../styles/job-task/job-task-details.css';
import '../../styles/job-task/job-task-details-alt.css';
import PhotoCameraIcon from '@material-ui/icons/PhotoCamera';
import CloseIcon from '@material-ui/icons/Close';
import QRCode from 'react-qr-code';
import { withTranslation } from 'react-i18next';
import { BusinessTypes } from '../../utils/shared';

interface JobTaskDetailsScreenProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface JobTaskDetailsScreenState {
	JobTask: JobTask.JobTask | null;
	completeDialogOpen: boolean;
	noteDialogOpen: boolean;
	stockDialogOpen: boolean;
	googleMapDialogOpen: boolean;
	TaskId: string;
	cameraOpen: boolean;
	completeNote: string;
	newNote: string;
	moreWorkRequired: boolean;
	moreWorkRequiredString: string;
	moreWorkRequiredReasons: [MoreWorkRequiredReasons.MoreWorkRequiredReasons] | [];
	moreWorkRequiredValue: string;
	moreWorkFollowUpQuestion: string | null;
	moreWorkFollowUpAnswer: string | null;
	jobTaskStock: [JobTask.JobTaskStock] | null;
	riskAssessmentsArray: any;
	permitsArray: any[];
	disableButtons: boolean;
	Forms: Forms.Form[];
	ClientJobNumber: number;
	AssetsArr: Asset[];
	AvailableForms: FormTemplates.FormTemplate[];
	fromAssetFBID: string;
	DisplayQR: boolean;
	photosAttached: boolean;
	photosExpandTakenForApp: boolean;
	photosExpandSentByMainApp: boolean;
	photosExpandUncategorised: boolean;
	stockExpand: boolean;
	notesExpand: boolean;
	expensesExpand: boolean;
}

class JobTaskDetails extends React.Component<JobTaskDetailsScreenProps, JobTaskDetailsScreenState> {
	private signaturePad: SignaturePad | null;
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.signaturePad = null;
		this.state = {
			JobTask: null,
			completeDialogOpen: false,
			noteDialogOpen: false,
			googleMapDialogOpen: false,
			TaskId: '',
			cameraOpen: false,
			completeNote: '',
			newNote: '',
			moreWorkRequired: false,
			moreWorkRequiredString: 'false',
			moreWorkRequiredReasons: [],
			moreWorkRequiredValue: '',
			moreWorkFollowUpQuestion: null,
			moreWorkFollowUpAnswer: null,
			stockDialogOpen: false,
			jobTaskStock: null,
			riskAssessmentsArray: [],
			permitsArray: [],
			disableButtons: false,
			Forms: [],
			ClientJobNumber: 0,
			AssetsArr: [],
			fromAssetFBID: '',
			// @ts-ignore
			AvailableForms: [],
			DisplayQR: false,
			photosAttached: false,
			photosExpandTakenForApp: false,
			photosExpandSentByMainApp: false,
			photosExpandUncategorised: false,
			stockExpand: false,
			notesExpand: false,
			expensesExpand: false,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'JobTaskDetailsScreen',
		);
	}

	componentDidMount() {
		const { id, fromassetfbid } = this.props.match.params as any;
		this.setState({ TaskId: id });


		if (!isNullOrUndefined(fromassetfbid)) {
			this.setState({ fromAssetFBID: fromassetfbid });
		} else {
		}

		//fetches risk assessments array
		areAllFormsComplete(id, 'Risk Assessment').then(riskAssessments =>
			this.setState({ riskAssessmentsArray: riskAssessments }),
		);

		//fetches risk assessments array
		areAllFormsComplete(id, 'Permit To Work').then(permits => this.setState({ permitsArray: permits }));
		this.updateIsViewed();

		this.unsubscribe = fire.getDocumentQuery('JobTasks', id)
			.onSnapshot(async jobTaskObj => {
				var jobTask = await jobTaskObj.data() as JobTask.JobTask;

				if (jobTask.JobFBID != null) {
					var job = await fire.getJob(jobTask.JobFBID).then(job => {
						var parsedJob = job.data();
						if (parsedJob != undefined && parsedJob.CreatedByUser != "" && parsedJob.CreatedByUser != undefined)
							jobTask.CreatedBy = parsedJob.CreatedByUser;
					}).then(res => {
						this.setState({ JobTask: jobTask });
					})
						.catch(err => {
							console.warn("Created by Field will be loaded when the app is online")
						})
				}
				else {
					this.setState({ JobTask: jobTask });
				}

				console.log(this.state.JobTask);
				this.getForms(id);
				this.getAvailableForms();
				if (!isNullOrUndefined(this.state.JobTask) && !isNullOrUndefined(this.state.JobTask.JobFBID)) {
					this.getAssetIDsNew(this.state.JobTask.JobFBID);
				}

				const data = jobTaskObj.data() as JobTask.JobTask;
				this.setState({ JobTask: jobTask, ClientJobNumber: data.ClientJobNumber });
			});
	}

	updateIsViewed() {
		const { id } = this.props.match.params as any;
		fire.updateIsViewed(id);
	}

	getForms = (TaskId: string) => {
		fire.baseQuery
			.collection('Forms')
			.where('JobTaskFBID', '==', TaskId)
			.get()
			.then((queryResponse) => {
				if (queryResponse.empty) return this.setState({ Forms: [] });
				const formsArray = queryResponse.docs.map(doc => {
					const data = doc.data() as Forms.Form;
					data.Id = doc.id;
					return data;
				});

				//preload question answers for forms
				queryResponse.docs.forEach(doc => doc.ref.collection('QuestionAnswers').get());

				this.setState({ Forms: this.sortingFunction(formsArray) });
			})
	};

	sortingFunction = (formsList: any) => {
		return formsList.sort((a, b) => {
			if (a.CreatedDate && b.CreatedDate) {
				if (a.CreatedDate === b.CreatedDate) return 0;
				else if (a.CreatedDate === 0) return -1;
				else if (b.CreatedDate === 0) return 1;
				else if (a.CreatedDate < b.CreatedDate) return 1;
				else if (b.CreatedDate < a.CreatedDate) return -1;
				else return 0;
			}
			else { return -1 }
		});
	};

	getAvailableForms = () => {
		fire.baseQuery
			.collection('FormTemplates')
			.where('Scope', '==', 'JobForm')
			.orderBy("FormName")
			.get()
			.then(queryResponse => {
				// @ts-ignore
				if (queryResponse.empty) return this.setState({ AvailableForms: [] });

				// @ts-ignore
				let formsArray: FormTemplates.FormTemplate[] = [];
				queryResponse.docs.forEach(doc => {
					const data = doc.data() as FormTemplates.FormTemplate;
					data.Id = doc.id;
					formsArray.push(data);
				});



				//preload question answers for forms
				queryResponse.docs.forEach(doc => doc.ref.collection('QuestionAnswers').get());

				//sort forms
				this.orderAlphabeticallyArray(formsArray)

				this.setState({ AvailableForms: formsArray });
			})
		// .onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
		// 	// @ts-ignore
		// 	if (querySnapshot.empty) return this.setState({ AvailableForms: [] });

		// 	// @ts-ignore
		// 	let formsArray: FormTemplates.FormTemplate[] = [];
		// 	querySnapshot.docs.forEach(doc => {
		// 		const data = doc.data() as FormTemplates.FormTemplate;
		// 		data.Id = doc.id;
		// 		formsArray.push(data);
		// 	});



		// 	//preload question answers for forms
		// 	querySnapshot.docs.forEach(doc => doc.ref.collection('QuestionAnswers').get());

		// 	//sort forms
		// 	this.orderAlphabeticallyArray(formsArray)

		// 	this.setState({ AvailableForms: formsArray });
		// });
	};

	orderAlphabeticallyArray(list: FormTemplates.FormTemplate[]) {
		return list.sort((a, b) => orderAlphabetically(a.FormName.toLowerCase(), b.FormName.toLowerCase()));
	}

	componentWillUnmount() {
		this.unsubscribe = null;
		idb.removeAttachmentsForJobTask(this.state.TaskId).then();
	}

	getMoreWorkRequiredReasons = () => {
		const tempArray: any = [];
		fire
			.getCollectionWhere('Settings', 'SettingType', 'MoreWorkRequiredOptions')
			.then(query => {
				if (query.empty) {
					return;
				}
				query.docs.forEach(doc => {
					tempArray.push({
						Id: doc.id,
						Description: doc.data().Description,
						FollowUpQuestion: doc.data().FollowUpQuestion,
					});
				});
			})
			.then(() => {
				this.setState({ moreWorkRequiredReasons: tempArray });
			});
	};

	handleMoreWorkRequiredSelection = (element: HTMLOptionElement) => {
		const followUpQuestion = element.dataset.follow === undefined ? null : element.dataset.follow;
		this.setState({
			moreWorkRequiredValue: element.value,
			moreWorkFollowUpQuestion: followUpQuestion,
		});
	};

	followNoteChange = val => {
		this.setState({ moreWorkFollowUpAnswer: val });
	};

	handleLeaveSiteDialogVisibility = async () => {
		idb.hasPhotosAttached(this.state.TaskId).then(hasPhotosAttached => {
			this.setState({
				photosAttached: hasPhotosAttached
			})
		});
		this.setState({
			completeDialogOpen: !this.state.completeDialogOpen,
		});
		this.getMoreWorkRequiredReasons();
	};

	handleNoteDialogVisibility = () => {
		this.setState({ noteDialogOpen: !this.state.noteDialogOpen });
	};

	handleGooglemapDialogVisibility = () => {
		this.setState({ googleMapDialogOpen: !this.state.googleMapDialogOpen });
	};

	handleCameraDialog = () => {
		this.props.history.push({
			pathname: '/take-photo',
			state: {
				jobTaskId: this.state.TaskId,
				newJob: false,
				fromFormFail: false,
				isComplete: !isNullOrUndefined(this.state.JobTask) && !isNullOrUndefined(this.state.JobTask.CompletedDate),
			},
		});
	};

	initializeSignaturePad = () => {
		if (this.props.UserSettings.AskForCustomerSignature) {
			const signatureCanvas = document.getElementById('signature') as HTMLCanvasElement;
			if (signatureCanvas.parentElement !== null) {
				signatureCanvas.width = signatureCanvas.parentElement.clientWidth;
				signatureCanvas.height = signatureCanvas.parentElement.clientHeight;

				this.signaturePad = new SignaturePad(signatureCanvas);
			}
		}
	};

	clearSignature = () => {
		if (this.signaturePad !== null) {
			this.signaturePad.clear();
		}
	};

	completeNoteChange = (e: any) => {
		this.setState({ completeNote: e.target.value });
	};

	newNoteChange = (e: any) => {
		this.setState({ newNote: e.target.value });
	};

	handleAddNewNote = () => {
		this.setState({ noteDialogOpen: !this.state.noteDialogOpen });

		const firebaseId = generateFirebaseId();

		fire.addJobTaskNote(this.state.TaskId, this.state.newNote, firebaseId).then();

		const NewNoteQueueObj = {
			NewNote: this.state.newNote,
			NoteFBID: firebaseId,
			JobAction: 'AddNoteJobTask',
		};

		fire.postToJobQueue(this.state.TaskId, NewNoteQueueObj).catch(err => {
			alert(this.props.t('There was an error adding job note, if problem persists please email - support@trackplanfm.com'));
			console.error(err);
		});

		this.handleNotesExpand(true)
	};

	acceptTask = async () => {
		const confirmed = window.confirm(this.props.t('Are you sure you wish to accept this task?'));
		const acceptedDateTime = new Date().getTime();

		const localObj = {
			AcceptedDate: acceptedDateTime,
			Status: 'Accepted',
		};

		const geoLocation = await getLocation();

		const jobTaskQueueObj = {
			JobNumber: this.state.ClientJobNumber,
			Latitude: geoLocation.Latitude,
			Longitude: geoLocation.Longitude,
			JobAction: 'AcceptedJobTask',
			AcceptedDateTime: acceptedDateTime,
		};

		if (confirmed) {
			fire.updateJobTaskActions(this.state.TaskId, localObj, false).catch(err => console.error(err));
			fire.postToJobQueue(this.state.TaskId, jobTaskQueueObj).catch(err => console.error(err));
		}
	};

	startTravel = async () => {
		const confirmed = window.confirm(this.props.t('Are you sure you wish to start travel for this task?'));
		const startedTravelDateTime = new Date().getTime();

		const localObj = {
			TravelStartDate: startedTravelDateTime,
			Status: 'Travel Start',
			ArrivedDate: null,
			LeftDate: null,
		};

		const geoLocation = await getLocation();

		const jobTaskQueueObj = {
			JobNumber: this.state.ClientJobNumber,
			Latitude: geoLocation.Latitude,
			Longitude: geoLocation.Longitude,
			JobAction: 'TaskTravelStart',
			StartedTravelDateTime: startedTravelDateTime,
		};

		if (confirmed) {
			fire.updateJobTaskActions(this.state.TaskId, localObj, false).catch(err => console.error(err));
			fire.postToJobQueue(this.state.TaskId, jobTaskQueueObj).catch(err => console.error(err));

			if (this.state.JobTask !== null) {
				if (this.state.ClientJobNumber === 0) {
					fire.updateCurrentActiveJobTask(`Started Travel - Unsynced Task`, this.state.TaskId);
				} else {
					fire.updateCurrentActiveJobTask(`Started Travel - ${this.state.JobTask.JobTaskNumber}`, this.state.TaskId);
				}
			}
		}
	};

	arriveOnSite = async () => {
		const confirmed = window.confirm(this.props.t('Are you sure you wish to start this task?'));
		const arrivedOnSiteDateTime = new Date().getTime();

		const localObj = {
			ArrivedDate: arrivedOnSiteDateTime,
			Status: 'Start Task',
			LeftDate: null,
		};

		const geoLocation = await getLocation();

		const jobTaskQueueObj = {
			JobNumber: this.state.ClientJobNumber,
			Latitude: geoLocation.Latitude,
			Longitude: geoLocation.Longitude,
			JobAction: 'TaskArriveOnSite',
			ArrivedOnSiteDateTime: arrivedOnSiteDateTime,
		};

		if (confirmed) {
			fire.updateJobTaskActions(this.state.TaskId, localObj, false).catch(err => console.error(err));
			fire.postToJobQueue(this.state.TaskId, jobTaskQueueObj).catch(err => console.error(err));

			if (this.state.JobTask !== null) {
				if (this.state.ClientJobNumber === 0) {
					fire.updateCurrentActiveJobTask(`Started - Unsynced Task`, this.state.TaskId);
				} else {
					fire.updateCurrentActiveJobTask(`Started - ${this.state.JobTask.JobTaskNumber}`, this.state.TaskId);
				}
			}
		}
	};

	leaveSite = async (TravellingHome: boolean) => {

		//check if any forms on job are not submitted
		const areAllFormsComplete = await areAllFormsSubmitted(this.state.TaskId);
		if (!areAllFormsComplete && !this.state.moreWorkRequired) return alert(this.props.t('Form Not Submitted - Cannot Complete Task'));

		// If more work required, then photos and signature is not required.
		// It will be required only when they complete the task.
		if (this.props.UserSettings.RequirePhotoBeforeCompleting && !this.state.photosAttached && !this.state.moreWorkRequired) {
			return alert(this.props.t('A photo is required before you can complete task.'));
		}

		this.setState({ disableButtons: true });
		const leftTaskDateTime = new Date().getTime();

		const localObj: any = {
			LeftDate: leftTaskDateTime,
			Status: this.state.moreWorkRequired ? 'Left Task' : 'Completed',
			CompletedDate: leftTaskDateTime,
			IsClosed: true,
		};

		if (this.state.moreWorkRequired) {
			localObj.ArrivedDate = null;
			localObj.TravelStartDate = null;
			localObj.LeftDate = null;
			localObj.CompletedDate = null;
			localObj.IsClosed = false;
		}

		const geoLocation = await getLocation();

		const jobTaskQueueObj = {
			JobNumber: this.state.ClientJobNumber,
			Latitude: geoLocation.Latitude,
			Longitude: geoLocation.Longitude,
			JobAction: 'TaskLeaveSite',
			CompleteNote: this.state.completeNote,
			MoreWorkRequired: this.state.moreWorkRequired,
			MoreWorkRequiredReasonFBID: this.state.moreWorkRequiredValue,
			MoreWorkRequiredFollowUpAnswer: this.state.moreWorkFollowUpAnswer,
			FirebaseStoragePath: null,
			TravellingHome,
			LeftTaskDateTime: leftTaskDateTime,
		};

		let TravelHomeGoTo: boolean;
		if (this.props.UserSettings.AskForCustomerSignature && !this.state.moreWorkRequired) {
			TravelHomeGoTo = await this.leaveWithSignature(jobTaskQueueObj, localObj);
		} else {
			TravelHomeGoTo = await this.leaveWithoutSignature(localObj, jobTaskQueueObj);
		}

		if (!this.state.moreWorkRequired) {
			idb.updatePhotosToComplete(this.state.TaskId);
			idb.updateDocumentsToComplete(this.state.TaskId);

			if (this.state.Forms.length > 0) {
				this.state.Forms.forEach(form => {
					idb.updateFormPhotosToComplete(form.Id);
					idb.updateFormDocumentsToComplete(form.Id);
				});
			}
		}

		// Travelling home
		if (TravelHomeGoTo) this.travellingHome(TravellingHome, localObj.Status);
	};

	travellingHome(TravellingHome: boolean, Status: string) {
		if (this.state.JobTask !== null && !TravellingHome) {
			fire.updateCurrentActiveJobTask(null, null);
		} else {
			const task = this.state.JobTask as JobTask.JobTask;
			if (this.state.ClientJobNumber === 0) {
				fire.updateCurrentActiveJobTask(`Travelling Home - Unsynced Task`, this.state.TaskId);
			} else {
				fire.updateCurrentActiveJobTask(`Travelling Home - ${task.JobTaskNumber}`, this.state.TaskId);
			}
		}

		const now = new Date().getTime();
		let obj: object;

		if (this.state.moreWorkRequired) {
			obj = {
				Status,
				LeftDate: now,
			};
		} else {
			obj = {
				Status,
				LeftDate: now,
				CompletedDate: now,
				IsClosed: true,
			};
		}

		fire.updateTask(this.state.TaskId, obj).then();
	}

	leaveWithoutSignature(localObj: any, jobTaskQueueObj: any) {
		this.setState({
			completeDialogOpen: !this.state.completeDialogOpen,
		});

		fire.updateJobTaskActions(this.state.TaskId, localObj, jobTaskQueueObj).then();
		fire.postToJobQueue(this.state.TaskId, jobTaskQueueObj).then();

		this.setState({
			disableButtons: false,
		});

		return true;
	}

	async leaveWithSignature(jobTaskQueueObj, docToUpdateObject) {
		if (this.signaturePad == null) {
			return false;
		}

		if (this.signaturePad.isEmpty()) {
			alert(this.props.t('Please provide a signature.'));
			//this causes leave site modal to dissapear
			this.setState({
				disableButtons: false,
			});
			return false;
		} else {
			//this causes leave site modal to dissapear
			this.setState({
				completeDialogOpen: !this.state.completeDialogOpen,
			});

			const signatureUrl = this.signaturePad.toDataURL('base64');

			const arrayBuffer = await fetch(signatureUrl).then(res => res.arrayBuffer());

			const guid = await idb.setPhotoForRequest(arrayBuffer, generateGuid());

			//get a reference path to current document
			const docToUpdateReferencePath = fire.baseQuery.collection('JobTasks').doc(this.state.TaskId).path;

			//saves to local job queue to be sent up to firebase at later stage
			await localQueue.saveToLocalJobQueue(
				this.state.TaskId,
				jobTaskQueueObj,
				docToUpdateObject,
				docToUpdateReferencePath,
				guid,
			);

			this.setState({
				disableButtons: false,
			});

			return true;
		}
	}

	handleMoreWorkRequired = val => {
		this.setState({
			moreWorkRequiredString: val,
			moreWorkRequired: val === 'true',
		});
	};


	backButton = () => {
		if (!isNullOrUndefined(this.props.history.location.state) && this.props.history.location.state.fromNotifications) {
			this.props.history.push({
				pathname: '/notifications',
				state: {
					yAxisScroll: this.props.history.location.state.yAxisPos,
				}
			});
		}
		else if (isNullOrUndefined(this.state.fromAssetFBID) || this.state.fromAssetFBID === '') {

			this.props.history.push('/job-task-list');
		}
		else {
			//this is called if thee job details page is called from the job list on an asset details page
			//but we can only go back to the asset list at present, bot the asset details, as the asset details is a modal - need it to be transformed into a view
			this.props.history.push(`/asset-nmdetails/${this.state.fromAssetFBID}`);
		}
	};

	openImageDialog = () => {
		const imageUploadButton = document.getElementById('image-upload');
		if (imageUploadButton !== null) {
			imageUploadButton.click();
		}
	};

	blobToFile = (theBlob, fileName) => {
		//A Blob() is almost a File() - it's just missing the two properties below which we will add
		theBlob.lastModifiedDate = new Date();
		theBlob.name = fileName;
		return theBlob;
	}

	saveImage = async (selectorFiles: FileList | null, isComplete: boolean) => {

		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 800,
			useWebWorker: true
		}

		if (selectorFiles !== null) {
			for (let i = 0; i < selectorFiles.length; i++) {
				const photo: File = selectorFiles[i];
				const CompressionRatio = GetFileCompressionRatio(photo);
				loadImage(photo,
					(canvas: HTMLCanvasElement) => {
						canvas.toBlob(async blob => {
							const arrayBuffer = await fileToArrayBuffer(blob as Blob);
							const photoNewFBID = generateFirebaseId();
							await idb
								.savePhotoToLocal(
									arrayBuffer,
									this.state.TaskId,
									isComplete.toString(),
									photoNewFBID,
									`Contractor-Photo-${new Date().getTime()}.jpeg`,
									undefined,
									undefined,
									undefined,
									fire.currentUser.email || undefined,
									JOB_DOCUMENT_SOURCE_FROM_APP,
									new Date().getTime(),
									undefined,
									undefined,
									undefined,
									BusinessTypes.JobTask
								)
								.then(async guid => {

									const jobTaskQueueObject = {
										JobAction: 'AddPhotoExistingJobTask',
										PhotoGuid: guid,
										PhotoNewFBID: photoNewFBID
									};
									await localQueue.saveToLocalJobQueue(
										this.state.TaskId,
										jobTaskQueueObject,
										undefined,
										undefined,
										undefined,
										arrayBuffer,
									);
								})
								.then(() => {
									this.forceUpdate();
								})
						});
					},
					{
						maxWidth: 800, maxHeight: 800, orientation: true,
						//downsamplingRatio: CompressionRatio, 
						canvas: true
					}
				);
			}
			this.handlePhotosExpandTakenForApp(true)
		}
	};

	getAssetIDsNew = id => {
		let AssetArray: Asset[] = [];

		fire.baseQuery
			.collection('AssetsToJobs')
			.where('JobFBID', '==', id)
			.get()
			.then(AssetJobs => {
				AssetArray = AssetJobs.docs.map(assetJob => {
					let data = assetJob.data() as Asset;
					data.AssetName = isNullOrUndefined(data.AssetName) ? '' : data.AssetName;
					return data;
				});
				AssetArray.sort((a, b) => a.AssetName.toLowerCase().localeCompare(b.AssetName.toLowerCase()));
				this.setState({ AssetsArr: AssetArray });
			});
	};

	removeAttachedAsset = assetID => {
		if (confirm(this.props.t('Are you sure you want to remove this asset from the job?'))) {
			fire.getCollection('AssetsToJobs', 'JobFBID').then(AssetJobs => {
				AssetJobs.docs.forEach(AssetJob => {
					const data = AssetJob.data();
					if (data.AssetFBID === assetID && this.state.JobTask != null && data.JobFBID === this.state.JobTask.JobFBID) {
						fire.removeAttachedAsset(AssetJob.id).then();
						const RemoveAsset = {
							AssetFBID: assetID,
							JobFBID: this.state.JobTask.JobFBID,
							JobAction: 'RemoveAssetFromJob',
						};

						fire.postToJobQueue(AssetJob.id, RemoveAsset).then();
						this.getAssetIDsNew(this.state.JobTask.JobFBID);
					}
				});
			});
		}
	};

	stockDialogClose = () => {
		this.setState({ stockDialogOpen: !this.state.stockDialogOpen });
	};

	addStockAction = () => {
		this.handleStockExpand(true)
		return;
	};

	handlePhotosExpandTakenForApp = (value) => {
		this.setState({ photosExpandTakenForApp: value })
	}

	handlePhotosExpandSentByMainApp = (value) => {
		this.setState({ photosExpandSentByMainApp: value })
	}

	handlePhotosExpandUncategorised = (value) => {
		this.setState({ photosExpandUncategorised: value })
	}

	handleStockExpand = (value) => {
		this.setState({ stockExpand: value })
	}

	handleExpensesExpand = (value) => {
		this.setState({ expensesExpand: value })
	}

	handleNotesExpand = (value) => {
		this.setState({ notesExpand: value })
	}

	render() {
		if (this.state.JobTask !== null) {
			const titleOne = this.state.JobTask.JobTaskNumber.toString() === '0-001' ? this.props.t('Job Task') : this.props.t('Job Task No.');
			const titleTwo =
				this.state.JobTask.JobTaskNumber.toString() === '0-001' ? this.props.t(`Not Synced`) : `${this.state.JobTask.JobTaskNumber}`;
			const task = this.state.JobTask;
			const settingCanUseAssets =
				isNullOrUndefined(this.props.UserSettings.AssetsEnabled) || this.props.UserSettings.AssetsEnabled;
			const settingCanUseExpenses =
				isNullOrUndefined(this.props.UserSettings.EnableExpenses) || this.props.UserSettings.EnableExpenses;
			return (
				<div id="task-details-root">
					<SideDrawer
						history={this.props.history}
						title={titleOne}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
						title2={titleTwo}
					/>

					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main">
							<div id="details-outer">
								<div className="top-card-alt card-shadow-alt">
									<div>
										<FieldHolderLink content={task.Site} label={this.props.t("Site")} link={task.SiteFBID} field="site" bold={true} />
										<FieldHolderLink content={task.Location} label={this.props.t("Location")} link={task.LocationFBID} field="location" />
										<FieldHolder content={task.JobType} label={this.props.t("Job Type")} />
										<FieldHolder content={unixToDateString(task.ExpectedDate)} label={this.props.t("Expected Start")} />
										<FieldHolder content={!isNullOrUndefined(task.Priority) ? task.Priority : ''} label={this.props.t("Priority")} />
										<FieldHolder fullLine={true} content={task.Description} label={this.props.t("Description")} whitespace={"pre-wrap"} />
										{task.ResourceNames ? <FieldHolder fullLine={true} content={task.ResourceNames}
											label={this.props.t("Resources Assigned")} whitespace={"pre-wrap"} /> : null}
									</div>
									<div className='main-card-left'>
										<FieldHolder content={this.props.t(task.Status)} label={this.props.t("Status")} bold={true} />
										<FieldHolderLink content={task.SubLocation} label={this.props.t("Sub Location")} link={task.SubLocationFBID}
											field="sublocation" />
										<FieldHolder content={task.JobSubType} label={this.props.t("Job SubType")} />
										{task.ExpectedCompleteDate > 0 ? <FieldHolder content={unixToDateString(task.ExpectedCompleteDate)}
											label={this.props.t("Expected Complete")} /> : ""}
										{task.CreatedBy ? <FieldHolder content={task.CreatedBy} label={this.props.t("Created By")} /> : null}
										{
											task.IsTransportJob && (
												<>
													<FieldHolder fullLine={true} content={!isNullOrUndefined(task.TransportPickupAddress) ?
														task.TransportPickupAddress : ''} label={this.props.t("Pickup Address")} whitespace={"pre-wrap"} />
													<FieldHolder fullLine={true} content={!isNullOrUndefined(task.TransportDestinationAddress) ?
														task.TransportDestinationAddress : ''} label={this.props.t("Destination Address")}
														whitespace={"pre-wrap"} />
												</>
											)
										}
									</div>
								</div>

								{(this.state.JobTask.IsQREnabled && !isNullOrUndefined(this.state.JobTask.QRCode)) &&
									<>
										<Button
											fullWidth
											variant="contained"
											onClick={() => this.setState({ DisplayQR: true })}
											className='qr-code-button'
										>
											<PhotoCameraIcon className='photo-icon-holder' />
											{this.props.t("Show QR Code")}
										</Button>
										<Dialog open={this.state.DisplayQR} onClose={() => this.setState({ DisplayQR: false })}>
											<DialogTitle>
												<div className='qr-code-modal-header'>
													<Typography variant="h6">{this.props.t("QR Code")}</Typography>
													<IconButton onClick={() => this.setState({ DisplayQR: false })}><CloseIcon /></IconButton>
												</div>
											</DialogTitle>
											<DialogContent dividers>
												<QRCode
													bgColor="#FFFFFF"
													fgColor="#000000"
													level="L"
													size={256}
													value={this.state.JobTask.QRCode}
												/>
											</DialogContent>
										</Dialog>
									</>
								}

								<div className="top-card-alt card-shadow-alt">
									<span className='information-card-title'>{this.props.t('Information')}</span>
									<div className={'full-line'}>
										<span className="almost-bold-text">
											{this.props.t("Address")}
											{isNullOrUndefined(task.Latitude) || isNullOrUndefined(task.Longitude) ? null : (
												<IconButton
													className='icon-button-holder'
													onClick={() => this.handleGooglemapDialogVisibility()}
												>
													<Icon className='pin-icon-holder'>pin_drop</Icon>
												</IconButton>
											)}
										</span> : <span className="field-content-alt">{task.Address}</span>

										<GoogleMapsModal
											open={this.state.googleMapDialogOpen}
											handleGoogleMapsDialogVisibility={this.handleGooglemapDialogVisibility}
											latitude={task.Latitude}
											longitude={task.Longitude}
										/>
										<br></br>
									</div>
									<div className={'full-line'}>&nbsp;</div>
									<>
										<div><span className='almost-bold-text '>{this.props.t("Contact")}</span>: <span
											className='field-content-alt'>{task.Contact}</span></div>
										<div><span className='almost-bold-text '>{this.props.t("Telephone")}</span>: <span
											className='field-content-alt'>{task.Telephone.toString()}</span></div>
									</>
								</div>

								<div className="action-buttons">
									<LeaveSiteDialogPopup
										open={this.state.completeDialogOpen}
										handleLeaveSiteDialogVisibility={this.handleLeaveSiteDialogVisibility}
										initializeSignaturePad={this.initializeSignaturePad}
										clearSignature={this.clearSignature}
										handleLeaveSite={this.leaveSite}
										completeNoteChange={this.completeNoteChange}
										signatureEnabled={this.props.UserSettings.AskForCustomerSignature}
										JobTaskLeaveLabel={this.props.UserSettings.JobTaskLeaveLabel}
										enableTravelHome={this.props.UserSettings.EnableStartTravel}
										moreWorkRequired={this.state.moreWorkRequiredString}
										handleMoreWorkRequired={this.handleMoreWorkRequired}
										moreWorkRequiredReasons={this.state.moreWorkRequiredReasons}
										moreWorkRequiredValue={this.state.moreWorkRequiredValue}
										moreWorkFollowUpQuestion={this.state.moreWorkFollowUpQuestion}
										handleMoreWorkRequiredSelection={this.handleMoreWorkRequiredSelection}
										followNoteChange={this.followNoteChange}
										permitsButton={null}
										permitsObj={{
											jobTaskId: this.state.TaskId,
											permitsArray: this.state.permitsArray,
											history: this.props.history,
										}}
										disableButtons={this.state.disableButtons}
									/>

									{(!isNullOrUndefined(task.AcceptedDate) ||
										!isNullOrUndefined(task.TravelStartDate) ||
										!isNullOrUndefined(task.ArrivedDate) ||
										!isNullOrUndefined(task.LeftDate)) && (
											<div className="top-card-alt card-shadow-alt">
												{!isNullOrUndefined(task.AcceptedDate) && (
													<FieldHolder content={unixToDateString(task.AcceptedDate)} label={this.props.t("Accepted Date")} />
												)}
												{!isNullOrUndefined(task.TravelStartDate) && (
													<FieldHolder content={unixToDateString(task.TravelStartDate)} label={this.props.t("Travel Start Date")} />
												)}
												{!isNullOrUndefined(task.ArrivedDate) && (
													<FieldHolder content={unixToDateString(task.ArrivedDate)} label={this.props.t("Start Task Date")} />
												)}
												{!isNullOrUndefined(task.LeftDate) && (
													<FieldHolder content={unixToDateString(task.LeftDate)} label={this.props.t("Left Date")} />
												)}
											</div>
										)}

									{(this.state.permitsArray.length < 1) ? null :
										<Button
											onClick={() => {
												const url = `/form-details/${this.state.TaskId}/${this.state.permitsArray[0]}`;
												this.props.history.push(url);
											}}
											variant="contained"
											className='permit-to-work-button'
										>
											<Icon style={{ fontSize: 20 }}>assignment</Icon>&nbsp;
											<p style={{ marginTop: 2 }}>{this.props.t("Complete Permit To Work")}</p>
										</Button>}

									{/*  Action Buttons */}
									<JobTaskActionButton
										userSettings={this.props.UserSettings}
										JobTask={task}
										acceptTask={this.acceptTask}
										startTravel={this.startTravel}
										arriveOnSite={this.arriveOnSite}
										leaveSite={this.handleLeaveSiteDialogVisibility}
										currentJobTask={this.props.UserSettings.CurrentJobTask}
										taskId={this.state.TaskId}
										style={{ marginBottom: '25px' }}
										riskAssessmentsArray={this.state.riskAssessmentsArray}
										history={this.props.history}
									/>
								</div>

								<FormsList
									Forms={this.state.Forms}
									TaskId={this.state.TaskId}
									JobID={this.state.JobTask.JobFBID || ""}
									history={this.props.history}
									AvailableForms={this.state.AvailableForms}
									showAttachFormButton={true}
									UserSettings={this.props.UserSettings}
								/>

								<JobTaskPhotosCard
									history={this.props.history}
									documentId={this.state.TaskId}
									isComplete={!isNullOrUndefined(task.CompletedDate)}
									expandTakenForApp={this.state.photosExpandTakenForApp}
									handleExpandTakenForApp={this.handlePhotosExpandTakenForApp}
									expandSentByMainApp={this.state.photosExpandSentByMainApp}
									handleExpandSentByMainApp={this.handlePhotosExpandSentByMainApp}
									expandUncategorised={this.state.photosExpandUncategorised}
									handleExpandUncategorised={this.handlePhotosExpandUncategorised}
									openImageDialog={this.openImageDialog}
									saveImage={this.saveImage}
									completedDate={task.CompletedDate}
								/>

								<DocumentsCard fromView="JobTask" associatedFBID={this.state.TaskId} />

								{this.props.UserSettings.EnableQuotes ? (
									<JobTaskQuotes jobTaskFBID={this.state.TaskId} history={this.props.history} />
								) : null}

								{this.props.UserSettings.EnableCosts ? (
									<JobTaskCosts jobTaskFBID={this.state.TaskId} history={this.props.history} />
								) : null}

								{this.props.UserSettings.EnableStock ? (
									<div>
										{/* Job Task Stock */}
										<AddStockModal
											open={this.state.stockDialogOpen}
											stockDialogClose={this.stockDialogClose}
											addStockAction={this.addStockAction}
											jobTaskFBID={this.state.TaskId}
										/>

										<JobTaskStock jobTaskFBID={this.state.TaskId} fire={fire} expand={this.state.stockExpand}
											handleExpand={this.handleStockExpand} stockDialogClose={this.stockDialogClose} />
									</div>
								) : null}

								{settingCanUseAssets && (
									<AssetJobList
										Assets={this.state.AssetsArr}
										JobId={this.state.JobTask.JobFBID}
										TaskId={this.state.TaskId}
										removeAssetAttached={this.removeAttachedAsset}
										history={this.props.history}
										documentID={this.state.JobTask.JobFBID ? this.state.JobTask.JobFBID : ''}
										jobTaskID={this.state.TaskId}
									/>
								)}

								{settingCanUseExpenses && (
									<ExpensesListCard
										jobTaskNumber={this.state.JobTask.JobTaskNumber}
										jobTaskFBID={this.state.TaskId}
										isJobTaskPreset={true}
										expand={this.state.expensesExpand}
										handleExpand={this.handleExpensesExpand}
									/>
								)}

								{/* Job Notes */}
								<AddNoteDialogPopup
									open={this.state.noteDialogOpen}
									handleNoteDialogVisibility={this.handleNoteDialogVisibility}
									newNoteChange={this.newNoteChange}
									handleAddNewNote={this.handleAddNewNote}
								/>
								<JobTaskNotes jobTaskFBID={this.state.TaskId} expand={this.state.notesExpand} handleExpand={this.handleNotesExpand}
									handleNoteDialogVisibility={this.handleNoteDialogVisibility} />
							</div>
						</div>
					</Grid>
				</div>
			);
		}
		return <LoadingSpinner color="primary" text={this.props.t("Loading Task Details")} />;
	}
}

//Checks to see if being passed in link URL and if so sets a link tag around the field content.
const FieldHolderLink = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p className={props.bold ? 'field-content-link break-word-wrap almost-bold-text' : 'field-content-link break-word-wrap'}>
			{!isNullOrUndefined(props.link) ? (<Link to={`/${props.field}-details/${props.link}`} >{props.content}<TouchAppIcon fontSize="small"
				className='touch-app-icon-color'></TouchAppIcon></Link>) : props.content}
		</p>
		<p className="field-label-alt">{props.label}</p>
	</div>
);

const FieldHolder = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p className={props.bold ? 'field-content-alt break-word-wrap almost-bold-text' : 'field-content-alt break-word-wrap'}>
			{props.content}{' '}
			{isNullOrUndefined(props.content2) ? (
				''
			) : (
				<p>
					<span className="field-content2">{props.content2}</span>
				</p>
			)}
		</p>
		<p className="field-label-alt">{props.label}</p>
	</div>
);

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps, null)(JobTaskDetails));
