import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import { connect } from 'react-redux';
import { isNullOrUndefined } from 'util';
import firebase from 'firebase';

// Firebase
import { fire } from '../../index';

// Config File
import Api from '../../apiConfiguration.json';

// Material UI
import { Grid } from '@material-ui/core';

// Components
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import FormHeader from '../../components/forms/FormHeader';
import OuterCard from '../../components/shared/OuterCard';
import FormSection from '../../components/forms/FormSection';

// Utils
import { groupAndSort, isQuestionComplete, nameSort, orderSort } from '../../utils/forms/FormHelpers';
import { LocalstorageRemoveItem } from '../../utils/LocalStorage';
import { windowError, SendErrorData } from '../../utils/WindowError';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { getBaseURL } from '../../utils/getBaseURL';

// CSS
import '../../styles/forms/forms.css';
import { withTranslation } from 'react-i18next';
import { fireFormConverter } from '../../utils/FirebaseConverters';
import TransparentLoadingSpinner from '../../components/shared/TransparentLoadingSpinner';

interface FormLibraryDetailsProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface FormLibraryDetailsState {
	questionAnswers: Forms.Section[] | null;
	formDetails: Forms.ExtraFormDetails;
	jobTaskId: string;
	loading: boolean;
	formComplete: boolean;
	readyToSubmit: boolean;
	FormId: string;
	JobNumber: string;
	TaskNumber: string;
	siteName: string | null;
	assetName: string | null;
	formTemplateFBID: string;
	arrowsLoading: boolean;
}

class FormLibraryDetailsScreen extends React.Component<FormLibraryDetailsProps, FormLibraryDetailsState> {
	unsubscribe: any = null;
	constructor(props: FormLibraryDetailsProps) {
		super(props);
		this.reloadFormQuestions = this.reloadFormQuestions.bind(this);
		this.setLoading = this.setLoading.bind(this);
		this.state = {
			loading: true,
			questionAnswers: null,
			jobTaskId: '',
			formDetails: {
				Id: '',
				FormType: '',
				FormName: '',
				Status: '',
				IsCompleted: false,
				CompletedDate: null,
				formRef: null,
				IsRequiredForJobCompletion: false,
				JobTaskFBID: '',
				JobTaskNumber: '',
				LastUpdatedByUserFBID: '',
				LastUpdatedByUserName: '',
				LastUpdatedDate: null,
				Sections: null,
				FormTemplateFBID: '',
				AssetFBID: null,
				LocationFBID: null,
				SiteFBID: null,
				SublocationFBID: null,
				AssetName: '',
				SiteName: '',
				SubLocationName: '',
				LocationName: '',
			},
			formComplete: false,
			readyToSubmit: false,
			FormId: '',
			JobNumber: '',
			TaskNumber: '',
			siteName: null,
			assetName: null,
			formTemplateFBID: '',
			arrowsLoading: false,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'FormLibraryDetailsScreen',
		);
	}

	async componentDidMount() {
		const { formtemplateid, formid } = this.props.match.params as any;

		await fire.baseQuery
			.collection('FormTemplates')
			.doc(formtemplateid)
			.get()
			.then(templateObj => {
				const data = templateObj.data() as FormTemplates.FormTemplate;
				this.setState({ assetName: data.AssetName, siteName: data.SiteName, formTemplateFBID: formtemplateid});
			});

		this.setState({ FormId: formid });
		const query = fire.baseQuery.collection('Forms').doc(formid);

		try {
			await this.getFormDetails(query);
			this.setState({ loading: false });
		} catch (error) {
			console.error(error);
		} finally {
			this.setState({ loading: false });
		}
	}

	async reloadFormQuestions(formFBID: string, sections: Forms.Section[]) {
		const query = fire.baseQuery.collection('Forms').doc(formFBID);
		this.setState({loading: true});
		await this.getFormDetails(query);
		this.getQuestionAnswers(query, sections);
	}

	async setLoading(value: boolean) {
		this.setState({ arrowsLoading: value });
	};

	getFormDetails = async (query: firebase.firestore.DocumentReference) => {
		return new Promise((res, rej) => {
			query.withConverter(fireFormConverter).onSnapshot(async doc => {
				try {
					if (doc.exists) {
				const data = doc.data()!

				if (this.state.questionAnswers !== null) {
					let discrepancy = false;
					for (let i = 0; i < data.Sections.length; i++) {
						if (data.Sections[i]['RepeatableSections'].length !== this.state.formDetails.Sections[i].RepeatableSections.length) {
							discrepancy = true
						}
					}
	
					//the number of entries on a repeatable section is not matching, something needs to render
					if (discrepancy) {
						this.unsubscribe()
						this.unsubscribe = query
							.collection('QuestionAnswers')
							.onSnapshot(this.handleSections);
					}
				}

				this.setState({ formDetails: await fire.getExtraFormDetails(data), loading: false});
				if (this.state.questionAnswers === null) {
					this.getQuestionAnswers(query, data.Sections);
				}
				res()
			}
				} catch (e) {
					rej(e)
				}
				
		});
		})
	};

	getQuestionAnswers = (query: firebase.firestore.DocumentReference, sections: Forms.Section[]) => {
		if(this.state.questionAnswers === null){
			this.unsubscribe = query.collection('QuestionAnswers').onSnapshot(this.handleSections);
		} else {
			let discrepancy = false;
			for (let i = 0; i < sections.length; i++) {
				if (sections[i]['RepeatableSections'].length !== this.state.formDetails.Sections[i].RepeatableSections.length) {
					discrepancy = true
				}
			}

			//the number of entries on a repeatable section is not matching, something needs to render
			if (discrepancy) {
				this.unsubscribe()
				this.unsubscribe = query
					.collection('QuestionAnswers')
					.onSnapshot(this.handleSections);
			}
		}
	};

	handleSections = async (querySnapshot: firebase.firestore.QuerySnapshot) => {
		
		//if snapshot is empty exit
		if (querySnapshot.empty && this.state.formDetails.Sections == null) {
			this.setState({
				readyToSubmit: true,
			});
			return;
		}
		

		let questionAnswersArray: Forms.QuestionAnswer[] = [];

		for (const doc of querySnapshot.docs) {
			const data = doc.data() as Forms.QuestionAnswer;
			data.Id = doc.id;
			data.questionRef = doc.ref;
			data.CompletedAnswer = isQuestionComplete(data);
			data.FormFBID = this.state.formDetails.Id;
			questionAnswersArray.push(data);
		}

		//are all questions answered
		questionAnswersArray = questionAnswersArray.filter(data => data.IsRepeatable == false);
		const nonRepeatableQuestions = questionAnswersArray.every(data => data.CompletedAnswer);

		await fire.getFormSections(this.state.FormId).then(results => {
			var form = results.data();
			if (form != undefined) {
				//groups qa's by their section name
				groupAndSort(
					questionAnswersArray,
					form.Sections,
					this.state.FormId,
					(question: Forms.QuestionAnswer) => question.Section.Name,
					(question: Forms.QuestionAnswer) => question.Section.Order,
				).then(async res => {
					const groupedArray = res[0] as Forms.Section[];
					const allQuestionsAnswered = res[1];
					const formComplete = allQuestionsAnswered && !isNullOrUndefined(this.state.formDetails.CompletedDate);

					if(this.state.questionAnswers){
						for (let index = 0; index < this.state.questionAnswers.length; index++) {
							if((groupedArray[index].CurrentSectionNumber !== this.state.questionAnswers[index].CurrentSectionNumber)){
								let realCurrent = this.state.questionAnswers[index].CurrentSectionNumber;
								let lastEntry = groupedArray[index].CurrentSectionNumber;

								if(realCurrent && lastEntry && (realCurrent < lastEntry) 
								&& this.state.questionAnswers[index].QuestionFBIDs.length === groupedArray[index].QuestionFBIDs.length){
									groupedArray[index].CurrentSectionNumber = realCurrent							
									this.viewPreviousSection(realCurrent+1, groupedArray[index].Name, true)
								}
								if(lastEntry && (this.state.questionAnswers[index].QuestionFBIDs.length < groupedArray[index].QuestionFBIDs.length)){
									this.viewPreviousSection(lastEntry-1, groupedArray[index].Name, false, groupedArray)
								}
							}
						}
						await this.changeQuestionAnswers(groupedArray, formComplete, (allQuestionsAnswered && nonRepeatableQuestions))
					}else{
						await this.changeQuestionAnswers(groupedArray, formComplete, (allQuestionsAnswered && nonRepeatableQuestions))
					}
				});
			}
		})
	};

	async changeQuestionAnswers(groupedArray, formCompleted, allQuestionsAnswered) {
		this.setState({
			questionAnswers: groupedArray,
			loading: false,
			formComplete: formCompleted,
			readyToSubmit: allQuestionsAnswered,
		});
	}

	backButton = () => {
		this.props.history.push(`/form-library`);
	};

	viewPreviousSection = async(currentCount: number, sectionName: string, isPreviousSection: boolean,  arrayQuestions?: Forms.Section[] | null) => {
		let newCount = 0;

		this.setState({ arrowsLoading: true })

		if (isPreviousSection) newCount = currentCount - 1;
		else newCount = currentCount + 1;

		let newQuestionAnswers: Forms.Section[] | null;

		if(arrayQuestions){
			newQuestionAnswers = arrayQuestions;
		}else{
			newQuestionAnswers = this.state.questionAnswers;
		}
		
		if(newQuestionAnswers != null){
			let sectionArrayIndex = newQuestionAnswers.findIndex(element => element.Name == sectionName) || 0;
			newQuestionAnswers[sectionArrayIndex].QuestionAnswers = [];
			newQuestionAnswers[sectionArrayIndex].CurrentSectionNumber = newCount;

			for(const fbid of newQuestionAnswers[sectionArrayIndex].QuestionFBIDs[newCount - 1]){
					if(newQuestionAnswers != null){
						await fire.getQuestion(this.state.formDetails.Id, fbid).then(doc => {
							const data = doc.data() as Forms.QuestionAnswer;
							if(data != undefined && newQuestionAnswers != null){
								data.Id = doc.id;
								data.questionRef = doc.ref;
								data.FormFBID = this.state.formDetails.Id;
								data.CompletedAnswer = isQuestionComplete(data);
								newQuestionAnswers[sectionArrayIndex].QuestionAnswers.push(data);	
							}
						});	
					}
			};

			let tempQuestionAnswers = newQuestionAnswers[sectionArrayIndex].QuestionAnswers;
						tempQuestionAnswers = tempQuestionAnswers.sort((a, b) => orderSort(a.Order, b.Order));
						tempQuestionAnswers = tempQuestionAnswers.sort((a, b) =>
							nameSort(a.Order, b.Order, a.QuestionTitle, b.QuestionTitle),
						);

						newQuestionAnswers[sectionArrayIndex].QuestionAnswers = tempQuestionAnswers;
						this.setState({ questionAnswers: newQuestionAnswers, arrowsLoading: false });	
		}		
	};

	_renderSections = () => {
		if (this.state.questionAnswers === null) {
			return (
				<OuterCard styles="outer-card">
					<p style={{ color: '#e91e63' }}>{this.props.t("Loading questions...")}</p>
				</OuterCard>
			);
		}
		else if (this.state.questionAnswers.length < 1) {
			return (
				<OuterCard styles="outer-card">
					<p style={{ color: '#e91e63' }}>{this.props.t("No questions for this form.")}</p>
				</OuterCard>
			);
		}
		return <>{this.state.questionAnswers.map(array => (
			<FormSection
				key={array.Name}
				sectionName={array.Name}
				questionAnswers={array.QuestionAnswers}
				IsSectionCompleted={array.SectionComplete}
				IsFormCompleted={this.state.formComplete}
				formId={this.state.FormId}
				formTemplateFBID={this.state.formTemplateFBID}
				taskId={this.state.jobTaskId}
				history={this.props.history}
				formName={this.state.formDetails.FormName}
				canCreateRequest={this.props.UserSettings.CanCreateRequest}
				IsRepeatable={array.IsRepeatable}
				RepeatableSectionCount={array.RepeatableSectionCount}
				viewPreviousSection={this.viewPreviousSection}
				currentSectionNumber={array.CurrentSectionNumber}
				templateSectionID={array.TemplateSectionID}
				reloadFormQuestions={this.reloadFormQuestions}
				highestTemplateSectionID={array.HighestTemplateSectionID}
				readyToSubmit={this.state.readyToSubmit}
				setLoading={this.setLoading}
				formDetailsStatus={this.state.formDetails.Status}
			/>
		))}</>
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('FormLibraryFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}

	render() {
		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={this.props.t('Form Details')}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					rightMenuButton={<BackButton callbackMethod={this.backButton} />}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main">
						<div id="details-outer">
							<FormHeader
								formDetails={this.state.formDetails}
								readyToSubmit={this.state.readyToSubmit}
								taskNumber={this.state.TaskNumber}
								fromFormLibrary={true}
								assetName={this.state.assetName}
								siteName={this.state.siteName}
								formTemplateFBID={this.state.formTemplateFBID}
								history={this.props.history}
							/>
							{this._renderSections()}
						</div>
					</div>
				</Grid>
				{this.state.arrowsLoading && <TransparentLoadingSpinner/>}
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings as Store.UserSettings,
});

export default withTranslation()(connect(mapStateToProps, null)(FormLibraryDetailsScreen));
