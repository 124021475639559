import React, { useState } from 'react';
import { Icon, Button } from '@material-ui/core';
import { downloadFile, removePhoto } from '../../utils/forms/FormQuestionHelpers';
import { isIos, isIOS13 } from '../../utils/IsIos';
import PhotosGrid from '../shared/PhotosGrid';
import imgNotAvailable from '../../assets/images/not-available.jpg';
import { isNullOrUndefined } from 'util';
import OuterCard from '../shared/OuterCard';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { validateUploadedImages } from '../../utils/shared';

const PhotoAnswer = (props: {
	fileName: string | null;
	photoUrl: string | null;
	inputRef: React.RefObject<HTMLInputElement>;
	fileChangeListener: (event: React.ChangeEvent<HTMLInputElement>) => void;
	formComplete: boolean;
	photosArray: JobTask.JobTaskPhoto[];
	documentReference: Forms.QuestionAnswer;
	handleRemovePhoto: (guid: string, reference: any) => void;
	loading: boolean;
	size: number;
	expand: boolean;
	handleExpand: (value: boolean) => void;
}) => {
	const [t, i18n] = useTranslation();
	const [errorMsg, setErrorMsg] = useState<string>("");

	if (props.loading) {
		return (
			<OuterCard styles="outer-card">
				<CircularProgress size={18} color={'secondary'} style={{ marginRight: 10 }} />
				<h2 className="hot-pink loading-photos"> {t("Loading") + " " + props.size + " " + t("Photos.")}</h2>
			</OuterCard>
		);
	}
	return (
		<div className="file-input-container">
			{props.photosArray.length < 1 ? (
				<Button
					onClick={() => {
						if (props.inputRef.current !== null) {
							props.inputRef.current.click();
						}
					}}
					className="upload-Btn"
					variant="outlined"
					color="inherit"
					size="small"
					style={{ display: `${props.formComplete ? 'none' : 'inline-flex'}` }}
				>
					<Icon className="button-icon" fontSize="small">
						camera_alt
					</Icon>
					{t("Take Photo")}
				</Button>
			) : (
				<Button
					onClick={() => {
						if (props.inputRef.current !== null) {
							props.inputRef.current.click();
						}
					}}
					className="upload-Btn"
					variant="outlined"
					color="inherit"
					size="small"
					style={{ display: `${props.formComplete ? 'none' : 'inline-flex'}`, marginBottom: '10px' }}
				>
					<Icon className="button-icon" fontSize="small">
						camera_alt
					</Icon>
					{t("Add Another Photo")}
				</Button>
			)}
			{errorMsg && (
				<p className="error-msg">
					{errorMsg}
				</p>
			)}
			<input
				disabled={props.formComplete}
				ref={props.inputRef}
				onChange={(_event) => {
					if (validateUploadedImages(_event.target.files)) {
						setErrorMsg("");
						props.fileChangeListener(_event);
					} else {
						setErrorMsg(t("Please check the file types. Only Photos are supported."))
					}
				}}
				type="file"
				name="file"
				id="file"
				className="inputfile"
				style={{ display: 'none' }}
			/>
			{!props.formComplete ? (
				<PhotosGrid
					color={null}
					photos={props.photosArray}
					titleText={t("Question Photos")}
					noPhotosText={t("No Photos")}
					isForRequest={true}
					canRemovePhoto={true}
					canDownloadPhoto={!isIos() || (isIos() && isIOS13())}
					handleDownloadPhoto={downloadFile}
					handleRemovePhoto={props.handleRemovePhoto}
					documentReference={props.documentReference}
					expand={props.expand}
					handleExpand={props.handleExpand}
					fromPhotoAnswer
					showActionButton={false}
					openImageDialog={() => {
						if (props.inputRef.current !== null) {
							props.inputRef.current.click();
						}
					}}
				/>
			) : (
				<PhotosGrid
					color={null}
					photos={props.photosArray}
					titleText={t("Question Photos")}
					noPhotosText={t("No Photos")}
					isForRequest={true}
					canRemovePhoto={false}
					canDownloadPhoto={!isIos() || (isIos() && isIOS13())}
					handleDownloadPhoto={downloadFile}
					documentReference={props.documentReference}
					expand={props.expand}
					handleExpand={props.handleExpand}
					showActionButton={false}
					openImageDialog={() => {
						if (props.inputRef.current !== null) {
							props.inputRef.current.click();
						}
					}}
				/>
			)}
		</div>
	);
};

export default PhotoAnswer;