import * as React from 'react';
import { useState, useRef, useEffect } from 'react';
import { connect } from 'react-redux';
import Dexie from 'dexie';
import { History } from 'history';

import { clearCurrentJobtasksInfo } from '../../actions/JobTaskActions';

import { isNullOrUndefined } from 'util';

import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Fab,
	FormControlLabel,
	Grid,
	Icon,
	IconButton,
	Input,
	InputLabel,
	NativeSelect,
} from '@material-ui/core';
import { AsyncPaginate } from 'react-select-async-paginate';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTheme } from '@material-ui/core/styles';



import { fire, localQueue, idb } from '../../index';
import JobTaskCard from '../../components/job-task/JobTaskCard';
import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import FloatingTaskStatusButton from '../../components/shared/FloatingTaskStatusButton';
import { LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { isTodaysDate } from '../../utils/Times';
import PushNotification from '../../utils/PushNotification';
import { windowError, SendErrorData } from '../../utils/WindowError';
import Api from '../../apiConfiguration.json';
import '../../styles/job-task/job-task-list.css';
import BackButton from '../../components/shared/BackButton';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { readQRCodeAndNavigate } from '../../utils/QRCode';
import i18n from '../../i18n';
import { useTranslation, withTranslation } from 'react-i18next';
import JobNumberSearch from '../../components/job-task/JobNumberSearch';
import { getArrayChunks } from '../../utils/Converters';
import { BusinessTypes } from '../../utils/shared';



interface JobTaskListScreenProps {
	history: History;
	UserSettings: Store.UserSettings;
	clearCurrentJobtasksInfo: () => void;
	JobTask: Store.JobTasks;
	t: any;
}

interface JobTaskListScreenState {
	jobTasks: JobTask.JobTask[];
	lastLoadedJobTask: any;
	loading: boolean;
	loadingMore: boolean;
	endOfList: boolean;
	searchModalOpen: boolean;
	searchInput: string;
	openClosedTaskFilter: string;
	plannedReactiveTaskFilter: string;
	filtersActive: boolean;
	isDueToday: boolean;
	documentsPerPage: number;
	yAxisScroll: number;
	order: string;
	activeNotifications: boolean;
	notificationColor: string;
	notificationCount: number;

	// Sites, Locations and Sub Locations
	siteFilter?: {
		value: number;
		label: string;
	} | null;
	sitesPerPage: number;
	lastLoadedSite: any;
	siteValue: string;
	loadedSites: [];
	endSite: boolean;

	locationsPerPage: number;
	lastLoadedLocation: any;
	locationValue: string;
	loadedLocations: [];
	endLocation: boolean;
	locationFilter?: {
		value: number;
		label: string;
	} | null;
	locationDisabled: boolean;

	subLocationsPerPage: number;
	lastLoadedSubLocation: any;
	subLocationValue: string;
	loadedSubLocations: [];
	endSubLocation: boolean;
	subLocationFilter?: {
		value: number;
		label: string;
	} | null;
	subLocationDisabled: boolean;

	enableTextSearchForSite: boolean;
	enableTextSearchForLocation: boolean;
	enableTextSearchForSubLocation: boolean;
	displayBackButton: boolean;
	SearchbyQr: boolean;
	cameraMode: string;
	jobNumber: string;
	dataDownloadLoading: boolean;
	showDownloadAlert: boolean;
	downloadItemText: string;
}

class JobTaskListScreen extends React.Component<JobTaskListScreenProps, JobTaskListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			jobTasks: [],
			lastLoadedJobTask: null,
			loading: false,
			loadingMore: false,
			endOfList: false,
			searchModalOpen: false,
			searchInput: '',
			openClosedTaskFilter: this.props.t('Open'),
			plannedReactiveTaskFilter: '',
			filtersActive: false,
			isDueToday: false,
			documentsPerPage: 20,
			yAxisScroll: 0,
			order: 'DESC',
			activeNotifications: false,
			notificationColor: 'white',
			notificationCount: 0,

			siteFilter: null,
			sitesPerPage: 50,
			lastLoadedSite: null,
			siteValue: '',
			loadedSites: [],
			endSite: false,

			locationsPerPage: 50,
			lastLoadedLocation: null,
			locationValue: '',
			loadedLocations: [],
			endLocation: false,
			locationFilter: null,
			locationDisabled: true,

			subLocationsPerPage: 50,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],
			endSubLocation: false,
			subLocationFilter: null,
			subLocationDisabled: true,

			enableTextSearchForSite: false,
			enableTextSearchForLocation: false,
			enableTextSearchForSubLocation: false,
			SearchbyQr: false,
			displayBackButton: false,
			cameraMode: 'environment',

			jobNumber: '',
			dataDownloadLoading: false,
			showDownloadAlert: false,
			downloadItemText: ""
		};
		this._onOfflineDataDownloadClick = this._onOfflineDataDownloadClick.bind(this);
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'JobTaskListScreen',
		);

		//Checks for documents in local queue and processes them if needed
		localQueue.getDocumentsInLocalQueue();

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('associated-jobtasks')
		) {

			this.setState({ displayBackButton: true });

			const {
				SelectedSiteID,
				SelectedSiteName,
				SelectedLocationID,
				SelectedLocationName,
				SelectedSubLocationID,
				SelectedSubLocationName
			} = this.props.history.location.state;

			const siteFilter = {
				value: SelectedSiteID,
				label: SelectedSiteName
			};

			const locationFilter = {
				value: SelectedLocationID,
				label: SelectedLocationName
			};

			const subLocationFilter = {
				value: SelectedSubLocationID,
				label: SelectedSubLocationName
			};

			if (this.props.history.location.state.SelectedSubLocationID != null) {
				this.setState({
					siteFilter,
					locationFilter,
					subLocationFilter
				}, () => this.handleLocalStorageFilters());
				return;
			}

			else if (this.props.history.location.state.SelectedLocationID != null) {
				this.setState({
					siteFilter,
					locationFilter,
				}, () => this.handleLocalStorageFilters());
				return;
			}

			this.setState({
				siteFilter,
			}, () => this.handleLocalStorageFilters());
		}
	}

	async componentDidMount() {
		PushNotification(this.props.UserSettings.UserUID);
		this.getNotifications();

		console.log(this.props.UserSettings.ClientLanguage);
		if (!isNullOrUndefined(this.props.UserSettings.Language))
			i18n.changeLanguage(this.props.UserSettings.Language)
		else if (!isNullOrUndefined(this.props.UserSettings.ClientLanguage))
			i18n.changeLanguage(this.props.UserSettings.ClientLanguage)
		else
			i18n.changeLanguage("English");

		// Clear Scroll Y Redux State if coming from sidebar
		const routerState = this.props.history.location.state;
		if (!isNullOrUndefined(routerState) && routerState.clearScrollState) {
			this.props.clearCurrentJobtasksInfo();
		}


		if (navigator.onLine) {
			await fire.GetAppUpdateFlags();
			await this.checkIndexedDBVersion();
		}

		const localItem = (await LocalstorageGetItem('JobTaskFilterOptions')) || '{}';
		const JsonObj = JSON.parse(localItem);
		await this.setState(
			{
				searchInput: JsonObj.searchInput || '',
				plannedReactiveTaskFilter: JsonObj.plannedReactiveTaskFilter || '',
				openClosedTaskFilter: !isNullOrUndefined(JsonObj.openClosedTaskFilter) ? JsonObj.openClosedTaskFilter : 'Open',
				isDueToday: JsonObj.IsDueToday || false,
				siteFilter: JsonObj.siteFilter || null,
				locationFilter: JsonObj.locationFilter || null,
				subLocationFilter: JsonObj.subLocationFilter || null,
				yAxisScroll: JsonObj.YAxisScroll || 0,
				order: JsonObj.order || 'DESC',
				jobNumber: JsonObj.jobNumber || '',
			},
			() => {

				if (!isNullOrUndefined(this.state.siteFilter)) {
					fire.doesLocationsExist(this.state.siteFilter.value).then(async (res) => {
						await this.setState({ locationDisabled: !res });
					})
				}

				if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
					fire.doesSubLocationsExist(this.state.locationFilter.value).then(async (res) => {
						await this.setState({ subLocationDisabled: !res });
					})
				}

				this.setState({ filtersActive: this.areFiltersActive() }, () => {
					// window.addEventListener('scroll', this.handleScroll);

					// idb.removeCompletedPhotosFromTable();
					// idb.removeCompletedDocumentsFromTable();
					this.fetchJobTasks();
					// this.getSites();
				});
			},
		);
	}

	async checkIndexedDBVersion() {
		await new Dexie('TrackplanContractorDB').open().then(async db => {
			const DbVersion = parseInt(Api.INDEXEDDB_VERSION);
			if (db.verno !== DbVersion) {
				let URL = '';
				if (Api.ENVIRONMENT === 'development') {
					URL = 'https://resource-staging.trackplanfm.com/#/job-task-list';
				} else {
					URL = 'https://resource.trackplanfm.com/#/job-task-list';
				}

				await SendErrorData(
					this.props.UserSettings.Email,
					this.props.UserSettings.UserUID,
					this.props.UserSettings.ServerName,
					this.props.t("IndexedDB Version was not the latest version upgrade was required"),
					URL,
					'JobTaskListScreen',
				);
				self.indexedDB.deleteDatabase('TrackplanContractorDB');
				window.location.reload();
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe = null;
		window.removeEventListener('scroll', this.handleScroll);
	}

	loadOptions = async (siteName, loadedOptions) => {
		if (siteName !== this.state.siteValue) {
			await this.setState({ loadedSites: [], lastLoadedSite: null, siteValue: siteName });
			loadedOptions = [];
		}

		return new Promise((resolve) => {
			fire.getSearchSitesPaginated(this.state.sitesPerPage, this.state.siteValue, this.state.lastLoadedSite).get().then(docs => {
				if (!docs.empty) {
					let sites: any = [];
					docs.forEach(function (site) {
						const siteData = {
							value: site.data().SiteID,
							label: site.data().SiteName
						}
						sites.push(siteData);
					});
					this.setState({ loadedSites: sites, lastLoadedSite: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedSites,
							hasMore: !this.state.endSite
						});
					})
				} else {
					this.setState({ endSite: true });
					return resolve({
						options: [],
						hasMore: !this.state.endSite
					});
				}
			})
		})
	}

	loadLocations = async (locationName, loadedOptions) => {

		if (locationName !== this.state.locationValue) {
			await this.setState({ loadedLocations: [], lastLoadedLocation: null, locationValue: locationName });
			loadedOptions = [];
		}

		return new Promise((resolve) => {

			if (!isNullOrUndefined(this.state.siteFilter)) {
				fire.getSearchLocationsPaginated(this.state.locationsPerPage, this.state.siteFilter.value, this.state.locationValue, this.state.lastLoadedLocation).get().then(docs => {
					if (!docs.empty) {
						let locations: any = [];
						docs.forEach(function (location) {
							const locationData = {
								value: location.data().LocationID,
								label: location.data().LocationName
							}
							locations.push(locationData);
						});
						this.setState({ loadedLocations: locations, lastLoadedLocation: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedLocations,
								hasMore: !this.state.endLocation
							});
						})
					} else {
						this.setState({ endLocation: true });
						return resolve({
							options: [],
							hasMore: !this.state.endLocation
						});
					}
				})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	loadSubLocations = async (subLocationName, loadedOptions) => {
		if (subLocationName !== this.state.subLocationValue) {
			await this.setState({ loadedSubLocations: [], lastLoadedSubLocation: null, subLocationValue: subLocationName });
			loadedOptions = [];
		}

		return new Promise((resolve) => {

			if (!isNullOrUndefined(this.state.locationFilter)) {
				fire.getSearchSubLocationsPaginated(this.state.subLocationsPerPage, this.state.locationFilter.value, this.state.subLocationValue, this.state.lastLoadedSubLocation).get().then(docs => {
					if (!docs.empty) {
						let subLocations: any = [];
						docs.forEach(function (subLocation) {
							const subLocationData = {
								value: subLocation.data().SubLocationID,
								label: subLocation.data().SubLocationName
							}
							subLocations.push(subLocationData);
						});
						this.setState({ loadedSubLocations: subLocations, lastLoadedSubLocation: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedSubLocations,
								hasMore: !this.state.endSubLocation
							});
						})
					} else {
						this.setState({ endSubLocation: true });
						return resolve({
							options: [],
							hasMore: !this.state.endSubLocation
						});
					}
				})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	handleScroll = () => {
		this.setState({ yAxisScroll: window.scrollY }, () => {
			this.handleLocalStorageFilters(window.scrollY);
		});
	};

	handleLocalStorageFilters(yAxisScroll?: number) {
		const SearchArray = {
			searchInput: this.state.searchInput,
			openClosedTaskFilter: this.state.openClosedTaskFilter,
			plannedReactiveTaskFilter: this.state.plannedReactiveTaskFilter,
			IsDueToday: this.state.isDueToday,
			siteFilter: this.state.siteFilter,
			locationFilter: this.state.locationFilter,
			subLocationFilter: this.state.subLocationFilter,
			YAxisScroll: yAxisScroll || this.state.yAxisScroll,
			order: this.state.order,
			jobNumber: this.state.jobNumber,
		};

		LocalstorageSetItem({ Key: 'JobTaskFilterOptions', Value: SearchArray });
	}

	handleSearchModal = () => {
		this.setState({
			searchModalOpen: !this.state.searchModalOpen,
			lastLoadedSite: null,
			lastLoadedLocation: null,
			lastLoadedSubLocation: null
		});
	};

	handleOpenClosedFilter = selection => {
		this.setState({ openClosedTaskFilter: selection.target.value });
	};

	handlePlannedReactiveFilter = selection => {
		this.setState({ plannedReactiveTaskFilter: selection.target.value });
	};

	handleSearchInput = input => {
		this.setState({ searchInput: input });
	};


	getNotifications = () => {
		if ((/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) == false) {
			Notification.requestPermission(status => {
				//console.log('Notification permission status:', status);
			})
		};

		let query = fire.baseQuery.collection('JobTasks').where('IsViewed', '==', false).where('AssignedResource', '==', this.props.UserSettings.UserUID);
		query.onSnapshot(this.handleNotifications);
	}

	handleNotifications = async (query: firebase.default.firestore.QuerySnapshot) => {
		if (query.empty) {
			return;
		}

		let notificationTaskList = isNullOrUndefined(this.state.lastLoadedJobTask) ? [] : this.state.jobTasks;

		const docChanges = query.docChanges();
		for (const job of docChanges) {
			const notificationTask = job.doc.data() as JobTask.JobTask;
			notificationTaskList.push(notificationTask);

			// if (isTodaysDate(notificationTask.ExpectedDate)) {
			// 	fire.getFormsForJobTask(job.doc.id).onSnapshot(formDocs => {
			// 		if (formDocs.empty) return;
			// 		formDocs.docs.forEach(form => {
			// 			form.ref.collection('QuestionAnswers').get();
			// 		});
			// 	});
			// }

		}

		if (query.docs.length > this.state.notificationCount) {
			//Check if service worker is online and if the manager has approved the user to receive notifications.
			//this.props.userSettings.enableFirebaseNotifications (replace true with this for live deployment).

			this.setState({ activeNotifications: true, notificationColor: 'red', notificationCount: query.docs.length });
		}
	};

	fetchJobTasks = () => {
		const jobTaskFilterOptions = {
			// searchInput: this.state.searchInput,
			openClosed: this.state.openClosedTaskFilter,
			plannedReactive: this.state.plannedReactiveTaskFilter,
			isDueToday: this.state.isDueToday,
			site: !isNullOrUndefined(this.state.siteFilter) ? this.state.siteFilter.value : null,
			location: !isNullOrUndefined(this.state.locationFilter) ? this.state.locationFilter : null,
			subLocation: !isNullOrUndefined(this.state.subLocationFilter) ? this.state.subLocationFilter : null,
			order: this.state.order.toLowerCase(),
			jobNumber: this.state.jobNumber,
		};


		if (this.props.JobTask.totalJobtasksLoaded > 0) {
			const { totalJobtasksLoaded } = this.props.JobTask;

			this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
				this.unsubscribe = fire
					.getJobTaskPaginated(totalJobtasksLoaded, this.state.lastLoadedJobTask, jobTaskFilterOptions)
					//.onSnapshot(this.handleJobTaskSnapshot);
					.get()
					.then(query => {
						this.getJobTasksOnce(query);
					})

			});
		} else {
			this.setState({ yAxisScroll: window.scrollY }, () => {
				this.setState(
					{
						loading: true,
						filtersActive: this.areFiltersActive(),
					},
					() => {
						this.unsubscribe = fire
							.getJobTaskPaginated(this.state.documentsPerPage, this.state.lastLoadedJobTask, jobTaskFilterOptions)
							//.onSnapshot(this.handleJobTaskSnapshot);
							.get()
							.then(query => {
								this.getJobTasksOnce(query);
							})
					},
				);
			});
		}
	};

	getJobTasksOnce = (query: any) => {
		if (query.empty || query.docChanges().length < 1) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedJobTask)) this.setState({ jobTasks: [] });
			return;
		}

		let jobTaskList = isNullOrUndefined(this.state.lastLoadedJobTask) ? [] : this.state.jobTasks;

		const docChanges = query.docChanges();
		for (const job of docChanges) {
			const jobTask = job.doc.data() as JobTask.JobTask;
			//console.log(jobTask);
			//console.log(docChanges);

			//fetches data for forms and caches it
			/* OLD WAY */
			// if (isTodaysDate(jobTask.ExpectedDate)) {
			// 	job.doc.ref.collection('Forms').onSnapshot(formDocs => {
			// 		if (formDocs.empty) return;
			// 		formDocs.docs.forEach(form => {
			// 			form.ref.collection('QuestionAnswers').get();
			// 		});
			// 	});
			// }

			/* NEW WAY */
			if (isTodaysDate(jobTask.ExpectedDate)) {
				fire.getFormsForJobTask(job.doc.id).onSnapshot(formDocs => {
					if (formDocs.empty) return;
					formDocs.docs.forEach(form => {
						form.ref.collection('QuestionAnswers').get();
					});
				});
			}

			//checks if jobtask already exists in array
			const jobTaskIndex = jobTaskList.findIndex(x => x.Id === job.doc.id);
			const jobTaskObj = {
				...jobTask,
				Id: job.doc.id,
			};

			if (jobTaskIndex === -1) {
				jobTaskList.push(jobTaskObj);
			} else {
				jobTaskList[jobTaskIndex] = jobTaskObj;
				jobTaskList.splice(jobTaskIndex, 1);
			}

			if (job.type === 'removed') {
				jobTaskList.splice(jobTaskIndex, 1);
			}
		}

		// if (isNullOrUndefined(this.state.searchInput) && this.state.searchInput != '') {
		// 	jobTaskList = jobTaskList.filter(jobTask => {
		// 		return jobTask.Description
		// 			? jobTask.Description.toLowerCase().includes(this.state.searchInput.toLowerCase())
		// 			: null;
		// 	});
		// }

		// jobTaskList = this.sortingFunction(jobTaskList);

		this.setState(
			{
				jobTasks: jobTaskList,
				lastLoadedJobTask: query.docs[query.docs.length - 1],
				loading: false,
				loadingMore: false,
			},
			() => {
				if ((this.props.JobTask.totalJobtasksLoaded != 0)) {
					const { yAxisPos } = this.props.JobTask;

					window.scrollTo(0, yAxisPos);

					this.props.clearCurrentJobtasksInfo();
				} else {
					window.scrollTo(0, this.state.yAxisScroll);
				}
			},
		);
	};

	handleJobTaskSnapshot = (query: firebase.default.firestore.QuerySnapshot) => {
		if (query.empty || query.docChanges().length < 1) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedJobTask)) this.setState({ jobTasks: [] });
			return;
		}

		let jobTaskList = isNullOrUndefined(this.state.lastLoadedJobTask) ? [] : this.state.jobTasks;

		const docChanges = query.docChanges();
		for (const job of docChanges) {
			const jobTask = job.doc.data() as JobTask.JobTask;
			//console.log(jobTask);
			//console.log(docChanges);

			//fetches data for forms and caches it
			/* OLD WAY */
			// if (isTodaysDate(jobTask.ExpectedDate)) {
			// 	job.doc.ref.collection('Forms').onSnapshot(formDocs => {
			// 		if (formDocs.empty) return;
			// 		formDocs.docs.forEach(form => {
			// 			form.ref.collection('QuestionAnswers').get();
			// 		});
			// 	});
			// }

			/* NEW WAY */
			if (isTodaysDate(jobTask.ExpectedDate)) {
				fire.getFormsForJobTask(job.doc.id).onSnapshot(formDocs => {
					if (formDocs.empty) return;
					formDocs.docs.forEach(form => {
						form.ref.collection('QuestionAnswers').get();
					});
				});
			}

			//checks if jobtask already exists in array
			const jobTaskIndex = jobTaskList.findIndex(x => x.Id === job.doc.id);
			const jobTaskObj = {
				...jobTask,
				Id: job.doc.id,
			};

			if (jobTaskIndex === -1) {
				jobTaskList.push(jobTaskObj);
			} else {
				jobTaskList[jobTaskIndex] = jobTaskObj;
				jobTaskList.splice(jobTaskIndex, 1);
			}

			if (job.type === 'removed') {
				jobTaskList.splice(jobTaskIndex, 1);
			}
		}

		this.setState(
			{
				jobTasks: jobTaskList,
				lastLoadedJobTask: query.docs[query.docs.length - 1],
				loading: false,
				loadingMore: false,
			},
			() => {
				if ((this.props.JobTask.totalJobtasksLoaded != 0)) {
					const { yAxisPos } = this.props.JobTask;

					window.scrollTo(0, yAxisPos);

					this.props.clearCurrentJobtasksInfo();
				} else {
					window.scrollTo(0, this.state.yAxisScroll);
				}
			},
		);
	};

	sortingFunction = (jobTaskList: JobTask.JobTask[]) => {
		if (this.state.order === 'DESC') {
			return jobTaskList.sort((a, b) => {
				if (a.ExpectedDate === b.ExpectedDate) return 0;
				else if (a.ExpectedDate === 0) return -1;
				else if (b.ExpectedDate === 0) return 1;
				else if (a.ExpectedDate < b.ExpectedDate) return 1;
				else if (b.ExpectedDate < a.ExpectedDate) return -1;
				else return 0;
			});
		} else {
			return jobTaskList.sort((a, b) => {
				if (a.ExpectedDate === b.ExpectedDate) return 0;
				else if (a.ExpectedDate === 0) return -1;
				else if (b.ExpectedDate === 0) return 1;
				else if (a.ExpectedDate > b.ExpectedDate) return 1;
				else if (b.ExpectedDate > a.ExpectedDate) return -1;
				else return 0;
			});
		}
	}

	async handleClearSearch() {
		await this.setState(
			{
				loading: true,
				searchInput: '',
				openClosedTaskFilter: '',
				plannedReactiveTaskFilter: '',
				filtersActive: false,
				isDueToday: false,
				siteFilter: null,
				endOfList: false,
				loadedSites: [],
				lastLoadedSite: null,
				lastLoadedJobTask: null,
				locationFilter: null,
				subLocationFilter: null,
				locationValue: '',
				subLocationValue: '',
				loadedSubLocations: [],
				loadedLocations: [],
				lastLoadedLocation: null,
				lastLoadedSubLocation: null,
				locationDisabled: true,
				subLocationDisabled: true,
				jobNumber: '',
			},
			() => {
				this.handleLocalStorageFilters();
				this.fetchJobTasks();
				// this.filterJobTasks(this.state.jobTasks);
			},
		);
	}

	backButton = () => {
		window.history.back();
	};

	clearSelectedSitesOrLocations = async (typeId) => {
		// typeId = 1 (Site), typeId = 2 (Location), typeId = 3 (Sub Location)
		// If site is cleared, then location and sub location is also cleared as well since they depend on selected site id.
		// If location is cleared, then sub location is also cleared since it depends on selected location id.

		if (typeId === 1) {
			await this.setState({
				lastLoadedSite: null,
				loadedSites: [],
				siteFilter: null,
				siteValue: '',
				endSite: false,
				locationDisabled: true,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2) {
			await this.setState({
				lastLoadedLocation: null,
				loadedLocations: [],
				locationFilter: null,
				locationValue: '',
				endLocation: false,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2 || typeId === 3) {
			await this.setState({
				lastLoadedSubLocation: null,
				loadedSubLocations: [],
				subLocationFilter: null,
				subLocationValue: '',
				endSubLocation: false,
			});
		}

		this.handleLocalStorageFilters();
	}

	async handleJobTaskSearch() {
		this.handleLocalStorageFilters();

		await this.setState(
			{
				loading: true,
				searchModalOpen: false,
				filtersActive: this.areFiltersActive(),
				yAxisScroll: this.state.yAxisScroll,
				lastLoadedJobTask: null,
				endOfList: false,
				loadedSites: [],
				lastLoadedSite: null,
				siteValue: '',
				loadedLocations: [],
				loadedSubLocations: [],
				locationValue: '',
				subLocationValue: '',
				lastLoadedLocation: null,
				lastLoadedSubLocation: null,

			},
			() => {
				this.fetchJobTasks();
				//console.log(this.state);
				// this.filterJobTasks(this.state.jobTasks);
			},
		);
	}

	async handleOrderFilter() {
		const newOrder = this.state.order === 'DESC' ? 'ASC' : 'DESC';
		await this.setState({ order: newOrder, loading: true, lastLoadedJobTask: null, endOfList: false }, async () => {
			// const jobList = await this.sortingFunction(this.state.jobTasks);
			// await this.filterJobTasks(jobList);
			this.handleLocalStorageFilters();
			this.fetchJobTasks();
		});
	}

	handleIsDueTodayCheckbox = isChecked => {
		this.setState({ isDueToday: isChecked });
	};



	handleSiteFilter = site => {
		this.setState({
			siteFilter: { value: site.value, label: site.label },
			lastLoadedSite: null,
			locationFilter: null,
			loadedLocations: [],
			loadedSubLocations: [],
			lastLoadedLocation: null,
			lastLoadedSubLocation: null,
			subLocationFilter: null,
			locationValue: '',
			subLocationValue: '',

			locationDisabled: true,
			subLocationDisabled: true,
		}, () => {
			if (!isNullOrUndefined(this.state.siteFilter)) {
				fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
					this.setState({
						locationDisabled: !res
					})
				})
			}
		});
	}

	handleLocationFilter = location => {
		this.setState({
			locationFilter: { value: location.value, label: location.label },
			lastLoadedLocation: null,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],

			subLocationDisabled: true,
		}, () => {
			if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
				fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
					this.setState({
						subLocationDisabled: !res
					})
				})
			}
		});

	}

	handleSubLocationFilter = subLocation => {
		this.setState({
			subLocationFilter: { value: subLocation.value, label: subLocation.label },
			lastLoadedSubLocation: null
		});
	}

	handleJobNumber = (jobNumber: string) => {
		this.setState({
			jobNumber: jobNumber,
		})
	}

	areFiltersActive() {
		const freeTextSearch = this.state.searchInput.toLowerCase();
		const openClosedSearch = this.state.openClosedTaskFilter;
		const reactivePlannedSearch = this.state.plannedReactiveTaskFilter;
		const isDueTodaySearch = this.state.isDueToday;
		const siteSearch = this.state.siteFilter;
		const locationSearch = this.state.locationFilter;
		const subLocationSearch = this.state.subLocationFilter;
		const jobNumber = this.state.jobNumber;
		let filtersActive = false;

		if (
			freeTextSearch !== '' ||
			openClosedSearch !== '' ||
			reactivePlannedSearch !== '' ||
			!isNullOrUndefined(siteSearch) ||
			!isNullOrUndefined(locationSearch) ||
			!isNullOrUndefined(subLocationSearch) ||
			isDueTodaySearch ||
			(!isNullOrUndefined(jobNumber) && jobNumber !== '')
		) {
			filtersActive = true;
		}

		return filtersActive;
	}

	/* Download data for offline start */

	private async _onOfflineDataDownloadClick() {
		if (navigator.onLine) {
			this.setState({ dataDownloadLoading: true, downloadItemText: "Task Jobs" });
			let taskArrayChunks: string[] = [];
			const loadedTaskJobIds = this.state.jobTasks &&
				this.state.jobTasks.map(_task => _task.JobFBID) || [];

			const loadedTaskIds = this.state.jobTasks &&
				this.state.jobTasks.map(_task => _task.FirebaseId) || [];

			const completedTaskIds = this.state.jobTasks &&
				this.state.jobTasks.filter(_task => _task.CompletedDate)
					.map(task => task.FirebaseId) || [];

			const activeLoadedTaskIds = this.state.jobTasks &&
				this.state.jobTasks.filter(_task => !_task.CompletedDate)
					.map(_task => _task.FirebaseId);

			if (loadedTaskIds && loadedTaskIds.length) {
				taskArrayChunks = getArrayChunks(loadedTaskIds, 10);
			}

			// Clear inactive task docs from indexdb

			if (loadedTaskJobIds && loadedTaskJobIds.length) {
				await idb.removeInactiveDocumentsFromIDB(
					BusinessTypes.JobTask,
					loadedTaskIds,
					completedTaskIds
				);
			}

			// get Jobs
			if (loadedTaskJobIds && loadedTaskJobIds.length) {
				const taskJobArrayChunks = getArrayChunks(loadedTaskJobIds, 10);
				for (let chunk of taskJobArrayChunks) {
					await fire.getjobsForLoadedTasks(chunk)
						.then(() => console.info("Task Jobs downloaded"))
						.catch((err) => console.warn(`Error with downloading task jobs ${err}`));
				}
			}

			// get Task Notes

			if (taskArrayChunks && taskArrayChunks.length) {
				this.setState({ downloadItemText: "Task Notes" });
				for (let chunk of taskArrayChunks) {
					await fire.getjobTaskNotesForLoadedTasks(chunk)
						.then(() => console.info("Task Notes downloaded"))
						.catch((err) => console.warn(`Error with downloading task notes  => ${err}`));
				}
			}

			// get task forms

			if (taskArrayChunks && taskArrayChunks.length) {
				this.setState({ downloadItemText: "Task Forms" });
				for (let chunk of taskArrayChunks) {
					await fire.getjobFormsLoadedTasks(chunk)
						.then(() => console.info("Task Forms downloaded"))
						.catch((err) => console.warn(`Error with downloading task Forms ${err}`));
				}
				this.setState({ downloadItemText: "Task Form Templates" });
				await fire.getFormTemplates()
					.then(() => console.info("Task Form Templates downloaded"))
					.catch((err) => console.warn(`Error with downloading task Form Templates ${err}`));
			}

			if (activeLoadedTaskIds && activeLoadedTaskIds.length) {
				this.setState({ downloadItemText: "Task Photos" });
				for (let taskId of activeLoadedTaskIds) {
					await idb.syncPhotosOrDocsFromFireBase(
						`JobTasks/${taskId}/Documents`,
						`${taskId}`,
						BusinessTypes.JobTask,
						true
					);
				}
			}

			this.setState({ dataDownloadLoading: false, downloadItemText: "" });
		} else {
			this.setState({ showDownloadAlert: true }, () => {
				setTimeout(() => {
					this.setState({ showDownloadAlert: false });
				}, 2000);
			})
		}
	}

	/* Download data for offline end */

	render() {
		if (this.state.loading && !this.state.filtersActive) {
			return <LoadingSpinner text={this.props.t("Loading job tasks...")} />;
		}
		if (this.state.loading && this.state.filtersActive) {
			return <LoadingSpinner text={this.props.t("Searching job tasks...")} />;
		}

		if (this.state.dataDownloadLoading && this.state.dataDownloadLoading) {
			return <LoadingSpinner
				text={this.props.t(`Downloading ${this.state.downloadItemText}...`)}
			/>;
		}

		if (this.state.showDownloadAlert) {
			return <LoadingSpinner
				text={this.props.t("Download not available as you are offline")}
				showAlert
			/>;
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={this.props.t("Jobs")}
					rightMenuButton={
						<div>
							{' '}
							{navigator.onLine && (
								<IconButton
									onClick={this._onOfflineDataDownloadClick} color="primary">
									<Icon
										style={{ color: 'white', marginRight: "0.8em" }}
										title='Download data for offline use'
									>
										download_for_offline
									</Icon>
								</IconButton>
							)}
							<IconButton
								onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })}
								color="primary">
								<Icon style={{ color: 'white', marginRight: "0.8em" }}>
									image_search
								</Icon>
							</IconButton>
							{!this.state.displayBackButton ? <IconButton onClick={() => this.handleOrderFilter()} style={{ marginRight: "0.8em" }}>
								<Icon style={{ color: 'white' }}>autorenew</Icon>
							</IconButton> : null}
							{this.props.UserSettings.CanCreateRequest && <NewJobRequestButton history={this.props.history} />}
							<IconButton onClick={() => this.props.history.push('/notifications')} >
								<Icon style={{ color: this.state.notificationColor }}>notifications</Icon>
								<p style={{ color: 'white' }}>{this.state.notificationCount}</p>
							</IconButton>
							{this.state.displayBackButton ? <BackButton callbackMethod={this.backButton} /> : ''}
						</div>
					}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main">
						{this.state.jobTasks.length > 0 ? (
							<div>
								{this.state.jobTasks.map((jobTask, i) => (
									<JobTaskCard numJobtasks={this.state.jobTasks.length} key={i} jobTask={jobTask} history={this.props.history} />
								))}

								{this.state.endOfList ? (
									<Button id="outer-card-button" variant="outlined" size="large" fullWidth disabled>
										{this.props.t("All Job Tasks Loaded")}
									</Button>
								) : (
									<Button
										id="outer-card-button"
										variant="outlined"
										color="primary"
										size="large"
										fullWidth
										onClick={() => this.fetchJobTasks()}
									>
										{this.props.t("Load More Job Tasks")}
									</Button>
								)}
							</div>
						) : (
							<NoJobTasks filtersActive={this.state.filtersActive} />
						)}

						<Fab
							className={this.state.filtersActive ? 'not-hidden' : 'hidden'}
							id="clear-search-fab"
							color="inherit"
							aria-label="Add"
							onClick={() => this.handleClearSearch()}
						>
							<Icon style={{ color: 'white', paddingTop: '2.5px' }}>clear</Icon>
						</Fab>

						<Fab id="search-fab" color="inherit" aria-label="Add" onClick={() => this.handleSearchModal()}>
							<Icon style={{ color: 'white' }}>search</Icon>
						</Fab>

						<FloatingTaskStatusButton history={this.props.history} />

						<SearchDialogPopup
							searchModalOpen={this.state.searchModalOpen}
							handleSearchModal={this.handleSearchModal}
							openClosedTaskFilter={this.state.openClosedTaskFilter}
							handleOpenClosedFilter={this.handleOpenClosedFilter}
							plannedReactiveTaskFilter={this.state.plannedReactiveTaskFilter}
							handlePlannedReactiveFilter={this.handlePlannedReactiveFilter}
							searchInput={this.state.searchInput}
							handleSearchInput={this.handleSearchInput}
							searchJobTasks={() => this.handleJobTaskSearch()}
							IsDueTodayCheckboxInput={this.state.isDueToday}
							handleIsDueTodayCheckbox={this.handleIsDueTodayCheckbox}
							siteFilter={this.state.siteFilter}
							siteValue={this.state.siteValue}
							loadOptions={this.loadOptions}
							handleSiteFilter={this.handleSiteFilter}
							locationFilter={this.state.locationFilter}
							locationValue={this.state.locationValue}
							loadLocations={this.loadLocations}
							handleLocationFilter={this.handleLocationFilter}
							subLocationFilter={this.state.subLocationFilter}
							subLocationValue={this.state.subLocationValue}
							loadSubLocations={this.loadSubLocations}
							handleSubLocationFilter={this.handleSubLocationFilter}
							clearSelectedSitesOrLocations={(typeId) => this.clearSelectedSitesOrLocations(typeId)}
							locationDisabled={this.state.locationDisabled}
							subLocationDisabled={this.state.subLocationDisabled}
							jobNumberValue={this.state.jobNumber}
							handleJobNumber={this.handleJobNumber}
						/>
					</div>
					<div>
						<QRCodeDialog
							isOpen={this.state.SearchbyQr}
							close={() => this.setState({ SearchbyQr: false })}
							history={this.props.history}
							readQRCodeAndNavigate={readQRCodeAndNavigate}
						/>
					</div>
				</Grid>
			</div>
		);
	}
}

const NoJobTasks = props => {
	const { t, i18n } = useTranslation();
	return (
		<div className="no-jobtasks-card">
			<h1 className="hot-pink">
				{props.filtersActive ? t('There are no job tasks matching your search criteria') : t('There are no job tasks.')}
			</h1>
		</div>
	);
};

const SearchDialogPopup = props => {
	const { t, i18n } = useTranslation();
	const [enableTextSearchSite, setTextSearchSite] = useState(false);
	const [enableTextSearchLocation, setTextSearchLocation] = useState(false);
	const [enableTextSearchSubLocation, setTextSearchSubLocation] = useState(false);
	const [siteMenuOpen, setSiteMenuOpen] = useState(false);
	const [locationMenuOpen, setLocationMenuOpen] = useState(false);
	const [sublocationMenuOpen, setSubLocationMenuOpen] = useState(false);
	const [showKeyboard, setShowKeyboard] = useState(false);

	const siteRef = useRef<HTMLInputElement>();
	const locationRef = useRef<HTMLInputElement>();
	const sublocationRef = useRef<HTMLInputElement>();

	useEffect(() => {
		updatePredicate();
	}, [window.innerWidth]);

	const updatePredicate = () => {
		setShowKeyboard(window.innerWidth < 600);
	}

	const openSiteMenu = () => {
		setSiteMenuOpen(true);
		if (!isNullOrUndefined(siteRef.current) && !isNullOrUndefined(siteRef.current.select)) {
			siteRef.current.focus();
		}
	}

	const closeSiteMenu = () => setSiteMenuOpen(false);

	const openLocationMenu = () => {
		setLocationMenuOpen(true);
		if (!isNullOrUndefined(locationRef.current) && !isNullOrUndefined(locationRef.current.select)) {
			locationRef.current.focus();
		}
	}

	const closeLocationMenu = () => setLocationMenuOpen(false);

	const openSubLocationMenu = () => {
		setSubLocationMenuOpen(true);
		if (!isNullOrUndefined(sublocationRef.current) && !isNullOrUndefined(sublocationRef.current.select)) {
			sublocationRef.current.focus();
		}
	}

	const closeSubLocationMenu = () => setSubLocationMenuOpen(false);

	return (
		<Dialog
			open={props.searchModalOpen}
			onClose={() => props.handleSearchModal()}
			fullScreen={useMediaQuery(useTheme().breakpoints.down('xs'))}
		>
			{/* <DialogTitle id="form-dialog-title">Search Job Tasks</DialogTitle> */}
			<DialogContent id="content" style={{ height: '570px' }}>
				<Grid container spacing={5}>
					<Grid item xs={6}>
						<InputLabel htmlFor="open-closed-helper">{t("Open/Closed")}</InputLabel>

						<NativeSelect
							value={props.openClosedTaskFilter}
							onChange={selection => props.handleOpenClosedFilter(selection)}
							input={<Input name="openClosed" id="open-closed-helper" />}
							style={{ width: 100 }}
							name={t("Open/Closed")}
						>
							<option value="None">{t("None")}</option>
							<option value="Open">{t("Open")}</option>
							<option value="Closed">{t("Closed")}</option>
						</NativeSelect>
					</Grid>

					<Grid item xs={6}>
						<Grid container justify="flex-end">
							<Grid item>
								<InputLabel htmlFor="planned-reactive-helper">{t("Reactive/Planned")}</InputLabel>

								<NativeSelect
									value={props.plannedReactiveTaskFilter}
									onChange={selection => props.handlePlannedReactiveFilter(selection)}
									input={<Input name="plannedReactive" id="planned-reactive-helper" />}
									name={t("Reactive/Planned")}
									style={{ width: 100 }}
								>
									<option value="">{t("None")}</option>
									<option value="Reactive">{t("Reactive")}</option>
									<option value="Planned">{t("Planned")}</option>
								</NativeSelect>
							</Grid>
						</Grid>
					</Grid>

					<Grid item xs={6}>
						<InputLabel htmlFor="job-number">{t("Job Number")}</InputLabel>
						<JobNumberSearch value={props.jobNumberValue} onChange={props.handleJobNumber} />
					</Grid>

					<Grid item xs={6}>
						<FormControlLabel
							control={
								<Checkbox
									style={{ zoom: 1.25 }}
									color="primary"
									checked={props.IsDueTodayCheckboxInput}
									onChange={(event, value) => props.handleIsDueTodayCheckbox(value)}
								/>
							}
							label={t("Is Due Today")}
							labelPlacement="start"
						/>
					</Grid>

					<div style={{ display: 'flex', columnGap: 10, width: '100%', margin: '10px 20px' }}>
						<div style={{ flex: '1' }}>
							<p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<InputLabel htmlFor="site-helper" style={{ marginBottom: 5 }}>
									{t("Site")}
								</InputLabel>
								{
									showKeyboard && (
										<IconButton onClick={() => { setTextSearchSite(!enableTextSearchSite); openSiteMenu() }}>
											<Icon style={{ color: enableTextSearchSite ? 'green' : '' }} >keyboard</Icon>
										</IconButton>
									)
								}
							</p>
							<AsyncPaginate
								id="site-helper"
								value={props.siteFilter}
								selectRef={siteRef}
								onFocus={openSiteMenu}
								onBlur={closeSiteMenu}
								menuIsOpen={siteMenuOpen}
								onChange={(e) => { props.handleSiteFilter(e); setSiteMenuOpen(false); }}
								loadOptions={props.loadOptions}
								blurInputOnSelect={true}
								loadingMessage={() => t("Loading Sites...")}
								cacheUniqs={[props.siteFilter, props.siteValue]}
								noOptionsMessage={() => t("No Sites Found")}
								isSearchable={showKeyboard ? enableTextSearchSite : true}
							/>
						</div>
						<div style={{ flex: '0', marginTop: 28, justifySelf: 'flex-end' }}>
							{!isNullOrUndefined(props.siteFilter) && props.siteFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedSitesOrLocations(1)} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

					<div style={{ display: 'flex', columnGap: 10, width: '100%', margin: '10px 20px' }}>
						<div style={{ flex: '1' }}>
							<p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<InputLabel htmlFor="location-helper" style={{ marginBottom: 5 }}>
									{t("Location")}
								</InputLabel>
								{
									(showKeyboard && !props.locationDisabled) && (
										<IconButton onClick={() => { setTextSearchLocation(!enableTextSearchLocation); openLocationMenu(); }}>
											<Icon style={{ color: enableTextSearchLocation ? 'green' : '' }} >keyboard</Icon>
										</IconButton>
									)
								}
							</p>
							<AsyncPaginate
								id="location-helper"
								value={props.locationFilter}
								selectRef={locationRef}
								onFocus={openLocationMenu}
								onBlur={closeLocationMenu}
								menuIsOpen={locationMenuOpen}
								onChange={(e) => { props.handleLocationFilter(e); setLocationMenuOpen(false); }}
								loadOptions={props.loadLocations}
								blurInputOnSelect={true}
								loadingMessage={() => t("Loading Locations...")}
								cacheUniqs={[props.siteFilter, props.siteValue, props.locationFilter, props.locationValue]}
								isDisabled={props.locationDisabled}
								noOptionsMessage={() => t("No Locations Found")}
								isSearchable={showKeyboard ? enableTextSearchLocation : true}
							/>
						</div>
						<div style={{ flex: '0', marginTop: 28, justifySelf: 'flex-end' }}>
							{!isNullOrUndefined(props.locationFilter) && props.locationFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedSitesOrLocations(2)} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

					<div style={{ display: 'flex', columnGap: 10, width: '100%', margin: '10px 20px' }}>
						<div style={{ flex: '1' }}>
							<p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
								<InputLabel htmlFor="sublocation-helper" style={{ marginBottom: 5 }}>
									{t("Sub Location")}
								</InputLabel>
								{
									(showKeyboard && !props.subLocationDisabled) && (
										<IconButton onClick={() => { setTextSearchSubLocation(!enableTextSearchSubLocation); openSubLocationMenu() }}>
											<Icon style={{ color: enableTextSearchSubLocation ? 'green' : '' }} >keyboard</Icon>
										</IconButton>
									)
								}
							</p>
							<AsyncPaginate
								id="sublocation-helper"
								value={props.subLocationFilter}
								selectRef={sublocationRef}
								onFocus={openSubLocationMenu}
								onBlur={closeSubLocationMenu}
								menuIsOpen={sublocationMenuOpen}
								onChange={(e) => { props.handleSubLocationFilter(e); setSubLocationMenuOpen(false); }}
								loadOptions={props.loadSubLocations}
								blurInputOnSelect={true}
								loadingMessage={() => t("Loading Sub Locations...")}
								cacheUniqs={[props.siteFilter, props.siteValue, props.locationFilter, props.locationValue, props.subLocationValue, props.subLocationFilter]}
								isDisabled={props.subLocationDisabled}
								noOptionsMessage={() => t("No Sub Locations Found")}
								isSearchable={showKeyboard ? enableTextSearchSubLocation : true}
							/>
						</div>
						<div style={{ flex: '0', marginTop: 28, justifySelf: 'flex-end' }}>
							{!isNullOrUndefined(props.subLocationFilter) && props.subLocationFilter !== '' && (
								<div>
									<IconButton onClick={() => props.clearSelectedSitesOrLocations(3)} style={{ cursor: 'pointer' }}>
										<Icon color="error">
											delete_forever
										</Icon>
									</IconButton>
								</div>
							)}
						</div>
					</div>

				</Grid>
			</DialogContent>
			<DialogActions classes={{ root: 'action-buttons-list' }}>
				<Button onClick={props.handleSearchModal} color="primary">
					{t("Cancel")}
				</Button>
				<Button onClick={props.searchJobTasks} color="primary">
					{t("Search")}
				</Button>
			</DialogActions>
		</Dialog>
	)
}

const NewJobRequestButton = props => (
	<IconButton style={{ marginRight: "1em" }} onClick={() => props.history.push('/new-request')}>
		<Icon style={{ color: 'white' }}>add_circle_outline</Icon>
	</IconButton>
);

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
	JobTask: state.JobTask
});

const mapDispatchToProps = dispatch => ({
	clearCurrentJobtasksInfo: () => dispatch(clearCurrentJobtasksInfo())
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(JobTaskListScreen));
