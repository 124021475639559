import React, { useEffect, useReducer, useRef, useState } from 'react';
import { isNullOrUndefined } from 'util';

import { fire, idb, localQueue } from '../../index';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import { generateFirebaseId } from '../../utils/Guids';
import * as loadImage from 'blueimp-load-image';

import { JOB_DOCUMENT_SOURCE_FROM_APP, MIME_TYPE_DOCUMENT } from '../../utils/database/indexdb/IndexDb';
import GetMimeType from '../../utils/MimeTypes';
import { BusinessTypes } from '../../utils/shared';

interface SelectedRow {
	FileName: string;
	UploadedBy?: string;
	Source?: string;
	DateCreated?: number;
	DocumentTags?: indexDb.DocumentTag[];
	DocumentId: string;
	SelectedDocumentGuid: string;
	DocumentFBID?: string;
}

const useDocumentsCard = (associatedFBID: string, fromView: string) => {
	const [documents, setDocuments] = useState<indexDb.Documents[]>([]);
	const [activeRow, setActiveRow] = useState<number | null>(null);
	const [downloading, setDownloading] = useState<boolean>(false);
	const [detailsModalOpen, setDetailsModalOpen] = useState<boolean>(false);
	const [tagModalOpen, setTagModalOpen] = useState<boolean>(false);
	const [selectedRow, setSelectedRow] = useState<SelectedRow>({
		FileName: '',
		UploadedBy: '',
		Source: '',
		DateCreated: 0,
		DocumentTags: [],
		DocumentId: '',
		SelectedDocumentGuid: '',
	});

	const [, forceUpdate] = useReducer(x => x + 1, 0);

	useEffect(() => {
		reloadDocuments();
		watchDocuments();		
	}, []);

	const watchDocuments = () => {
		idb.db.documents.hook('creating', (_primaryKey, document) => {
			if (document.mimeType === MIME_TYPE_DOCUMENT) {
				addDocument(document);
			}
		});
	}

	const reloadDocuments = async () => {
		await idb.getDocumentsWithoutPhotos(associatedFBID).then(documents => {
			if (documents.length > 0) {
				documents.forEach(document => {
					addDocument(document);
				});
			}
		});
	};

	const addDocument = (document: indexDb.Documents) => {
		var newDocuments = documents;
		if (!document.documentFBID && document.guid && !checkdocumentExistsByGuid(document.guid)) {
			newDocuments.push(document);
			setDocuments(newDocuments);
		} else if (!documentExists(document.documentFBID)) {
			newDocuments.push(document);
			setDocuments(newDocuments);
		}
		//Document exists.
		else {
			//Check if tags.length has changed. If so, replace old document with new document.
			var existingDocumentIndex = newDocuments.findIndex(doc => doc.guid === document.guid);
			var existingDocument = newDocuments[existingDocumentIndex];
			if (document && document.tags && existingDocument && existingDocument.tags) {
				if (document.tags.length != existingDocument.tags.length) {
					newDocuments[existingDocumentIndex] = document;
					setDocuments(newDocuments);
				}
			}
		}
		// @ts-ignore
		forceUpdate(1);
	};

	const documentExists = documentFBID => documents.some(el => documentFBID === el.documentFBID);
	const checkdocumentExistsByGuid = docGuid => documents.some(el => docGuid === el.guid);


	const openDocumentDialog = () => {
		const documentUploadButton = document.getElementById('document-upload');
		if (documentUploadButton !== null) {
			documentUploadButton.click();
		}
	};

	const handleDownloadDocument = (row: indexDb.Documents, rowIndex: number) => {
		setDownloading(true);
		setActiveRow(rowIndex);

		//gets the mime type of the file
		const mimeType =
			isNullOrUndefined(row.mimeType) || row.mimeType === '' ? GetMimeType(row.documentName) : row.mimeType;

		//creates a new file with the mime type
		const file = new File([row.document], row.documentName, { type: mimeType });

		//saves the file with the filename
		saveAs(file);
		setDownloading(false);
	};

	const saveDocument = (selectorFiles: FileList | null) => {
		if (selectorFiles !== null && selectorFiles.length > 0) {
			const document: File = selectorFiles[0];

			if (document['type'].split('/')[0] === 'image') {
				const extension = document.name.split('.').pop();
				const CompressionRatio = GetFileCompressionRatio(document);

				loadImage(
					document,
					(canvas: HTMLCanvasElement) => {
						canvas.toBlob(async blob => {
							let b: any = blob;
							b.lastModifiedDate = new Date();
							b.name = `Contractor-Photo-${new Date().getTime()}.` + extension;

							addDocumentToJobQueue(blob as File, b.name);
						});
					},
					{
						maxWidth: 800, maxHeight: 800, orientation: true,
						//downsamplingRatio: CompressionRatio, 
						canvas: true
					},
				);
			} else {
				addDocumentToJobQueue(document, document.name);
			}
		}
	};

	const addDocumentToJobQueue = async (document: File, documentName: string) => {
		const documentNewFBID = generateFirebaseId();
		let businessType;
		if (fromView === "Asset") {
			businessType = BusinessTypes.Asset
		} else if (fromView === "Site") {
			businessType = BusinessTypes.Site
		} else if (fromView === "Location") {
			businessType = BusinessTypes.Locations
		} else if (fromView === "SubLocation") {
			businessType = BusinessTypes.SubLocations
		}

		idb.saveDocumentToLocal(
			document as File,
			associatedFBID,
			'',
			documentNewFBID,
			undefined,
			undefined,
			fire.currentUser.email || undefined,
			JOB_DOCUMENT_SOURCE_FROM_APP,
			undefined,
			[],
			businessType,
		)
			.then(object => {
				const jobTaskQueueObject = {
					JobAction: 'AddDocument',
					DocumentGuid: object.Guid,
					DocumentNewFBID: documentNewFBID,
					DocumentFBID: documentNewFBID,
					Type: fromView,
					AssociatedFBID: associatedFBID,
				};

				localQueue
					.saveToLocalJobQueue(
						associatedFBID,
						jobTaskQueueObject,
						undefined,
						undefined,
						undefined,
						object.File,
						documentName,
					)
					.then(res => {
						reloadDocuments();
						// @ts-ignore
						forceUpdate(1);
					});
			});
	};

	const toggleDetailsModal = (row: any) => {
		if (row == null) {
			setDetailsModalOpen(!detailsModalOpen);
			return;
		}

		let newSelectedRow: SelectedRow = {
			FileName: row.documentName,
			UploadedBy: row.uploadedBy,
			Source: row.source,
			DateCreated: row.dateCreated,
			DocumentTags: row.tags,
			DocumentId: row.documentId,
			SelectedDocumentGuid: row.guid,
			DocumentFBID: row.id
		};
		setSelectedRow(newSelectedRow);
		setDetailsModalOpen(!detailsModalOpen);
	};

	const toggleTagModal = (row: indexDb.Documents | null) => {
		if (row == null) {
			setTagModalOpen(!tagModalOpen);
			return;
		}

		let newSelectedRow: SelectedRow = {
			FileName: row.documentName,
			UploadedBy: row.uploadedBy,
			Source: row.source,
			DateCreated: row.dateCreated,
			DocumentTags: row.tags,
			DocumentId: row.documentId,
			DocumentFBID: row.documentFBID,
			SelectedDocumentGuid: row.guid,
		};

		console.log(newSelectedRow);

		setSelectedRow(newSelectedRow);
		setTagModalOpen(!tagModalOpen);
	}


	return {
		documents,
		saveDocument,
		openDocumentDialog,
		handleDownloadDocument,
		downloading,
		activeRow,
		detailsModalOpen,
		toggleDetailsModal,
		tagModalOpen,
		toggleTagModal,
		reloadDocuments,
		selectedRow,
		setSelectedRow,
	};
};

export default useDocumentsCard;
