/* eslint-disable prettier/prettier */
import Firebase from '../../Firebase';
import Dexie from 'dexie';
import { uploadToFirebaseStorage } from '../../forms/FormQuestionHelpers';
import { isNullOrUndefined } from 'util';
import { idb } from '../../..';
import indexDb from './IndexDb';
import { generateGuid, generateFirebaseId } from '../../Guids';

export async function TaskLeaveSite(
	jobTaskQueueObject: any,
	docToUpdateObject: any,
	localQueueObject: indexDb.LocalQueue,
	removeItemFromQueue: (id: string) => void,
	deletePhoto: (id: string) => void,
	getPhoto: (guid: string) => Dexie.Promise<indexDb.RequestPhotos | undefined>,
	fire: Firebase,
) {
	//gets photo from db
	const photo = await getPhoto(localQueueObject.id);

	if (photo === undefined) return;

	const blob = new Blob([photo.file]);

	//upload signature for task leave site
	const imageDownloadUrl = await fire.uploadBlobImage(blob, localQueueObject.userUID, localQueueObject.clientID);

	//if cannot upload file, return
	if (imageDownloadUrl === null) return;

	//update queue object with new firebase url for signature
	jobTaskQueueObject.FirebaseStoragePath = imageDownloadUrl;

	//decides whether date fields need to be reset or not
	const deleteFields = jobTaskQueueObject.JobAction === 'TaskLeaveSite' && jobTaskQueueObject.MoreWorkRequired;

	//updates firebase and job queue, if successful, remove item from local queue
	return fire
		.updateJobTaskActions(localQueueObject.documentId, docToUpdateObject, deleteFields)
		.then(() => fire.postToJobQueue(localQueueObject.documentId, jobTaskQueueObject))
		.then(() => removeItemFromQueue(localQueueObject.documentId))
		.then(() => deletePhoto(localQueueObject.id))
		.catch(err => console.error(`There has been an error updating job task actions, error - ${err}`));
}

export async function NewJobRequest(
	jobTaskQueueObject: any,
	localQueueObject: indexDb.LocalQueue,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	removeJobRequestPhotos: (guid: string) => Dexie.Promise<void>,
	getJobRequestPhotos: (documentId: string) => Dexie.Promise<indexDb.RequestPhotos[]>,
	fire: Firebase,
) {
	//gets photo from db
	const photos = await getJobRequestPhotos(localQueueObject.documentId);
	if (photos.length < 1) return;

	const DocumentID = jobTaskQueueObject['ObjectFBID'];
	//create empty promise array
	const array: Promise<string | null>[] = [];

	//loops through all photos and uploads to firebase
	photos.forEach(photo => {
		const blob = new Blob([photo.file]);
		const url = fire.uploadBlobImage(blob, localQueueObject.userUID, localQueueObject.clientID);
		array.push(url);
	});

	//when all photos have been uploaded, send back to trackplan
	return Promise.all(array).then(urls => {
		//adds firebase urls to job queue object
		jobTaskQueueObject['FirebasePhotos'] = urls;

		fire
			.postToJobQueue(DocumentID, jobTaskQueueObject)
			.then(() => removeItemFromQueue(localQueueObject.documentId)) //remove job from local queue
			.then(() => removeJobRequestPhotos(localQueueObject.documentId)) // remove photos from job request table
			.catch(err => console.error(err));
	});
}

export async function AddPhotoExistingJobTask(
	localQueueObject: indexDb.LocalQueue,
	getPhoto: (documentId: string) => Dexie.Promise<indexDb.Photos | undefined>,
	removeItemFromQueueByItemId: (documentId: string) => Dexie.Promise<void>,
	jobTaskQueueObject: any,
	fire: Firebase,
): Promise<boolean> {
	return new Promise(async (resolve) => {
		if (!isNullOrUndefined(localQueueObject.arrayBuffer)) {
			const photoGuid = jobTaskQueueObject['PhotoGuid'];
			const blob = new Blob([localQueueObject.arrayBuffer]);
			let uploadSuccess = true;
			await fire
				.uploadImageExistingJob(
					blob,
					localQueueObject.documentId,
					jobTaskQueueObject,
					photoGuid
				)
				.then(async (res) => {
					if (res) {
						await removeItemFromQueueByItemId(localQueueObject.id);
					}
					else {
						uploadSuccess = false;
					}
					resolve(uploadSuccess);
				})
				.catch(err => {
					console.error('Upload Image Existing Job', err);
					resolve(false);
				});
		}
	})
}

export async function AddDocumentExistingJobTask(
	localQueueObject: indexDb.LocalQueue,
	getDocument: (documentId: string) => Dexie.Promise<indexDb.Documents | undefined>,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	documentGuid: string,
	documentNewFBID: string,
	fire: Firebase,
) {
	if (!isNullOrUndefined(localQueueObject.arrayBuffer)) {
		const blob = new Blob([localQueueObject.arrayBuffer]);
		return fire
			.uploadDocument(
				blob,
				localQueueObject.fileName as string,
				localQueueObject.documentId,
				documentNewFBID,
				documentGuid,
			)
			.then(() => removeItemFromQueue(localQueueObject.documentId))
			.catch(err => console.error('Upload Image Existing Job', err));
	}
}

export async function AddPhoto(
	localQueueObject: indexDb.LocalQueue,
	getPhoto: (documentId: string) => Dexie.Promise<indexDb.Photos | undefined>,
	removeItemFromQueueByItemId: (documentId: string) => Dexie.Promise<void>,
	jobQueueObject: any,
	fire: Firebase,
	type: string,
	associatedFBID: string
): Promise<boolean> {
	return new Promise(async (resolve) => {
		if (!isNullOrUndefined(localQueueObject.arrayBuffer)) {
			const photoGuid = jobQueueObject['PhotoGuid'];
			const blob = new Blob([localQueueObject.arrayBuffer]);
			const photoId = localQueueObject.photoId;
			let uploadSuccess = true;
			await fire
				.uploadImage(
					blob,
					localQueueObject.documentId,
					type,
					associatedFBID,
					photoId || "",
					photoGuid,
					true,
				)
				.then(async (res) => {
					if (res) {
						await removeItemFromQueueByItemId(localQueueObject.id);
					}
					else {
						uploadSuccess = false;
					}
					resolve(uploadSuccess);
				})
				.catch(err => {
					console.error('Upload Image Existing Job', err);
					resolve(false);
				});
		}
	})
}

export async function AddDocument(
	localQueueObject: indexDb.LocalQueue,
	getDocument: (documentId: string) => Dexie.Promise<indexDb.Documents | undefined>,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	documentGuid: string,
	documentNewFBID: string,
	fire: Firebase,
	associatedFBID: string,
	type: string
) {
	console.log(localQueueObject);
	if (!isNullOrUndefined(localQueueObject.arrayBuffer)) {
		const blob = new Blob([localQueueObject.arrayBuffer]);
		return fire
			.addDocument(
				blob,
				localQueueObject.fileName as string,
				localQueueObject.documentId,
				documentNewFBID,
				documentGuid,
				type,
				associatedFBID
			)
			.then(() => removeItemFromQueue(localQueueObject.documentId))
			.catch(err => console.error('Upload Image Existing Job', err));
	}
}

export async function AddExpense(
	localQueueObject: indexDb.LocalQueue,
	getDocumentsForExpense: (documentId: string) => Dexie.Promise<indexDb.RequestDocuments[] | undefined>,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	fire: Firebase,
) {
	const jobTaskQueue = JSON.parse(localQueueObject.jobTaskQueueObject);

	const ExpenseObj = {
		AssignedResource: fire.currentUser.uid,
		DateCreated: Date.now(),
		Description: jobTaskQueue['Description'],
		Note: null,
		Status: 'Pending',
		Supplier: jobTaskQueue['Supplier'],
		UnitPrice: jobTaskQueue['UnitPrice'],
		Units: jobTaskQueue['Units'],
		JobTaskFBID: jobTaskQueue['JobTaskFBID']
	};

	return getDocumentsForExpense(jobTaskQueue.DocumentId)
		.then(async documents => {
			//checks if documents are undefined
			documents = documents === undefined ? [] : documents;

			//uploads documents and returns array of promises
			const promiseArray = documents.map(doc => {
				const blob = new Blob([doc.file]);
				//I THINK WE MAY NEED TO SWAP THINGS AROUND HERE AND USE documentTableBuid for the expenseId and guid for the unique document id/FBID/Guid
				//return fire.uploadExpenseDocumentOnCreate(blob, doc.filename, localQueueObject.documentId, doc.guid);
				return fire.uploadExpenseDocumentOnCreate(blob, doc.filename, localQueueObject.documentId, doc.documentTableGuid as string);
			});

			//waits for all promises to resolve
			const docs = await Promise.all(promiseArray);

			//creates a new queue object
			const queueObj = {
				...ExpenseObj,
				ExpenseDocuments: docs,
				JobAction: 'AddExpense',
			};

			//uploads to queue
			await fire.postToJobQueue(localQueueObject.documentId, queueObj);
		})
		.then(() => removeItemFromQueue(localQueueObject.documentId))
		.then(() => {
			idb.removeDocumentsForRequestByGuid(localQueueObject.documentId)

		})
		.catch(err => console.error(err));
}

//this is only called when adding a document to an existing expense.  Not when adding a document to a new expense.
export async function AddDocumentToExpense(
	localQueueObject: indexDb.LocalQueue,
	getDocument: (guid: string) => Dexie.Promise<indexDb.Documents | undefined>,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	fire: Firebase,
) {
	const obj = JSON.parse(localQueueObject.jobTaskQueueObject);

	return getDocument(obj.Guid)
		.then(document => {
			if (document === undefined) return;

			const blob = new Blob([document.document]);
			fire.uploadExpenseDocument(blob, document.documentName, localQueueObject.documentId, document.guid);
		})
		.then(() => removeItemFromQueue(localQueueObject.documentId))
		.catch(err => console.error(err));
}

export async function AddFormPhotoAnswer(
	localQueueObject: indexDb.LocalQueue,
	docToUpdateObj: any,
	removeItemFromQueueByItemId: (itemId: string) => Dexie.Promise<void>,
	getPhoto: (guid: string) => Dexie.Promise<indexDb.Photos | undefined>,
	fire: Firebase,
): Promise<boolean> {
	return new Promise(async (resolve) => {
		let documentUploadScucess = true;
		if (isNullOrUndefined(localQueueObject.docToUpdateRef)) return;

		const jobTaskQueueObj = JSON.parse(localQueueObject.jobTaskQueueObject);
		const blob = new Blob([localQueueObject.arrayBuffer as ArrayBuffer]);

		if (jobTaskQueueObj.QuestionType === 'Signature') {
			docToUpdateObj = null;
		}

		const filename = `photo-${new Date().getTime()}.jpeg`;
		if (jobTaskQueueObj.QuestionType !== 'Signature') {
			await fire.uploadPhotoForQuestionAnswer(
				blob,
				filename,
				localQueueObject.docToUpdateRef,
				localQueueObject.id,
				jobTaskQueueObj,
				localQueueObject.photoId as string,
			).then(async res => {
				if (res) {
					await removeItemFromQueueByItemId(localQueueObject.id);
				} else {
					documentUploadScucess = false;
				}
			}).catch(() => documentUploadScucess = false);
			resolve(documentUploadScucess);
		} else {
			uploadToFirebaseStorage(
				blob,
				filename,
				jobTaskQueueObj,
				localQueueObject.docToUpdateRef,
				docToUpdateObj).then(() => {
					removeItemFromQueueByItemId(localQueueObject.id);
					resolve(true);
				});
		}
	})
}

export async function AddFormDocumentAnswer(
	localQueueObject: indexDb.LocalQueue,
	docToUpdateObj: any,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	getDocument: (documentId: string) => Dexie.Promise<indexDb.Documents | undefined>,
) {
	if (isNullOrUndefined(localQueueObject.docToUpdateRef)) return;

	const jobTaskQueueObj = JSON.parse(localQueueObject.jobTaskQueueObject);
	const blob = new Blob([localQueueObject.arrayBuffer as ArrayBuffer]);

	return uploadToFirebaseStorage(
		blob,
		localQueueObject.fileName as string,
		jobTaskQueueObj,
		localQueueObject.docToUpdateRef,
		docToUpdateObj,
	).then(() => removeItemFromQueue(localQueueObject.documentId));
}

export async function CreateRequestPhotosAdd(
	jobTaskQueueObject: any,
	localQueueObject: indexDb.LocalQueue,
	getJobRequestPhotos: (documentId: string) => Dexie.Promise<indexDb.RequestPhotos[]>,
	removeItemFromQueue: (documentId: string) => Dexie.Promise<void>,
	fire: Firebase,
	idb: indexDb,
) {
	removeItemFromQueue(localQueueObject.documentId);

	const PhotoGuidInCreateJob = jobTaskQueueObject['guid'];
	const JobDocumentId = jobTaskQueueObject['documentId'];

	const photos = await getJobRequestPhotos(localQueueObject.documentId);
	if (photos.length < 1) return;

	//create empty promise array
	const array: Promise<string | null>[] = [];

	//loops through all photos and uploads to firebase
	photos.forEach(photo => {
		const blob = new Blob([photo.file]);
		const url = fire.uploadBlobImage(blob, localQueueObject.userUID, localQueueObject.clientID);
		array.push(url);
	});

	delete jobTaskQueueObject['documentId'];
	delete jobTaskQueueObject['guid'];

	const URLArray: (string | null)[] = [];
	await Promise.all(array).then(urls => {
		urls.forEach(url => {
			URLArray.push(url);
		});
	});

	jobTaskQueueObject['FirebasePhotos'] = URLArray;

	await fire.postToJobQueue(JobDocumentId, jobTaskQueueObject).catch(err => {
		alert('There was an error adding job request, if problem persists please email - support@trackplanfm.com');
		console.error(err);
	});

	return idb.removePhotosForRequest(PhotoGuidInCreateJob).catch(err => console.error(err));
}
