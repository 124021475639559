export const SAVE_ISSUE_STATE = 'SAVE_ISSUE_STATE';
export const CLEAR_ISSUE_STATE = 'CLEAR_ISSUE_STATE';
export const CURRENT_JOBTASKS = 'CURRENT_JOB_TASKS';
export const CURRENT_JOBTASKS_SCROLLY = 'CURRENT_JOBTASKS_SCROLLY';
export const CLEAR_JOBTASKS_STATE = 'CLEAR_JOBTASKS_STATE';

export function SaveNewIssueState(state: object) {
	return dispatch => {
		dispatch({
			type: SAVE_ISSUE_STATE,
			payload: state,
		});
	};
}

export function ClearNewIssueState() {
	return dispatch => {
		dispatch({
			type: CLEAR_ISSUE_STATE,
		});
	};
}

export function jobtasksLoaded(totalJobtasksLoaded : number) {
    return dispatch => {
        dispatch({
            type: CURRENT_JOBTASKS,
            payload: totalJobtasksLoaded
        });
    };
}

export function currentScrollPosition(yAxisPos : number) {
    return dispatch => {
        dispatch({
            type: CURRENT_JOBTASKS_SCROLLY,
            payload: yAxisPos
        });
    };
}

export function clearCurrentJobtasksInfo() {
    return dispatch => {
        dispatch({
            type: CLEAR_JOBTASKS_STATE
        })
    }
}