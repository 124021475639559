export const CURRENT_QUOTES = 'CURRENT_QUOTES';
export const CURRENT_QUOTES_SCROLLY = 'CURRENT_QUOTES_SCROLLY';
export const CLEAR_QUOTES_STATE = 'CLEAR_QUOTES_STATE';

export function quotesLoaded(totalQuotesLoaded : number) {
    return dispatch => {
        dispatch({
            type: CURRENT_QUOTES,
            payload: totalQuotesLoaded
        });
    };
}

export function currentScrollPosition(yAxisPos : number) {
    return dispatch => {
        dispatch({
            type: CURRENT_QUOTES_SCROLLY,
            payload: yAxisPos
        });
    };
}

export function clearCurrentJobQuotesInfo() {
    return dispatch => {
        dispatch({
            type: CLEAR_QUOTES_STATE
        })
    }
}