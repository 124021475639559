import { useTranslation } from "react-i18next";
import React from 'react';
import PhotosCardDialog from "../photos/PhotosCardDialog";

import usePhotosCards from "../../hooks/PhotosCard/usePhotosCard";

type PhotosCardsProps = {
  associatedFBID: string;
  fromView: string;
};

const PhotosCards = ({ associatedFBID, fromView }: PhotosCardsProps) => {
  const {
    openImageDialog,
    saveImage,
    handleDownloadPhoto,
    photos,
    syncDocsLoading,
    showPhotosLoadMore,
    loadMorePhotos,
    disableLoadMoreBtn
  } = usePhotosCards(associatedFBID, fromView);

  const { t } = useTranslation();

  return (
    <>
      <PhotosCardDialog
        // @ts-ignore
        documentId={associatedFBID}
        color=""
        photos={photos}
        titleText={t("Photos")}
        noPhotosText={t("No Photos")}
        isForRequest={false}
        canRemovePhoto={false}
        canDownloadPhoto={true}
        numberOfPhotos={0}
        documentReference={associatedFBID}
        displayDownloadAll={false}
        canEditPhoto={false}
        addPhoto={openImageDialog}
        handleDownloadPhoto={handleDownloadPhoto}
        photosLoading={syncDocsLoading}
        showLoadMoreButton={showPhotosLoadMore}
        loadMorePhotosCilck={loadMorePhotos}
        disableLoadMoreBtn={disableLoadMoreBtn}
      />
      <input
        id="image-upload"
        type="file"
        multiple
        onChange={e => saveImage(e.target.files)}
        style={{ display: 'none' }}
      />
    </>
  );
};

export default PhotosCards;
