const cardColour = status => {
	switch (status) {
		case 'Completed':
		case 'Submitted':
		case 'Approved':
		case 'Completed - Costs Finalised':
			return '#2E7D32';

		case 'Pending':
		case 'Quote Approved':
			return '#583d76';
		case 'Quote Requested':
			return '#b29acc';
		case 'Quote Received':
			return '#7858aa';
		case 'Instructed':
			return '#FFC107';
		case 'Quote Submitted':
			return '#794bab';

		case 'Accepted':
		case 'Accepted Task':
			return '#66BB6A';

		case 'Arrived':
		case 'Started':
		case 'Start Task':
			return '#FFC107';

		case 'Started Travel':
		case 'Travel Start':
			return '#2196F3';

		case 'Left Site':
			return '#F57F17';

		case 'Left Task':
			return '#F57F17';

		case 'Cancelled':
		case 'UnderQuery':
		case 'Under Query':
			return '#c62828';

		default:
			return 'transparent';
	}
};

export default cardColour;
