import * as React from 'react';
import { Icon, Button } from '@material-ui/core';
import { downloadFile } from '../../utils/forms/FormQuestionHelpers';
import { isIos, isIOS13 } from '../../utils/IsIos';

const DocumentAnswer = (props: {
	fileName: string | null;
	fileUrl: string | null;
	inputRef: React.RefObject<HTMLInputElement>;
	fileChangeListener: (event: React.ChangeEvent<HTMLInputElement>) => void;
	formComplete: boolean;
}) => {
	if (props.formComplete && props.fileName === 'unavailable') {
		return (
			<div className="file-input-container">
				<p style={{ color: 'var(--hot-pink)' }}>Document not available when offline</p>
			</div>
		);
	} else {
		return (
			<div className="file-input-container">
				{props.fileName === null || props.fileName === 'unavailable' ? (
					<Button
						onClick={() => {
							if (props.inputRef.current !== null) {
								props.inputRef.current.click();
							}
						}}
						className="upload-Btn"
						variant="outlined"
						color="inherit"
						size="small"
						style={{ display: `${props.formComplete ? 'none' : 'inline-flex'}` }}
					>
						<Icon className="button-icon" fontSize="small">
							cloud_upload
						</Icon>
						Upload
					</Button>
				) : (
					<div className="alternative-buttons-container">
						<Button
							className="upload-Btn"
							color="inherit"
							size="small"
							variant="outlined"
							onClick={() => {
								if (props.inputRef.current !== null) {
									props.inputRef.current.click();
								}
							}}
							style={{ display: `${props.formComplete ? 'none' : 'inline-flex'}` }}
						>
							<Icon className="button-icon" fontSize="small">
								cached
							</Icon>
							Change
						</Button>
						{isIos() && !isIOS13() ? null : (
							<Button
								className="upload-Btn"
								variant="outlined"
								color="inherit"
								size="small"
								onClick={() => downloadFile(props.fileUrl as string, props.fileName as string)}
							>
								<Icon className="button-icon" fontSize="small">
									cloud_download
								</Icon>
								Download
							</Button>
						)}
					</div>
				)}

				<input
					disabled={props.formComplete}
					ref={props.inputRef}
					onChange={props.fileChangeListener}
					type="file"
					name="file"
					id="file"
					className="inputfile"
					style={{ display: 'none' }}
					accept={'*'}
				/>
				<div className="file-name-container">
					<p className="file-name">{props.fileName !== 'unavailable' ? props.fileName : ''}</p>
				</div>
			</div>
		);
	}
};

export default DocumentAnswer;
