import Api from '../apiConfiguration.json';

export const getBaseURL = (ServerName: string) => {
	if (Api.ENVIRONMENT === 'development') {
		return 'https://staging-api.trackplanfm.com';
	}

	if (ServerName === 'Ireland') {
		return 'https://api.trackplanfm.com';
	} else if (ServerName === 'Europe2') {
		return 'https://europe2-api.trackplanfm.com';
	} else {
		return 'https://aus-api.trackplanfm.com';
	}
};