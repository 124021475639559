// React
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { History } from 'history';
import { isNullOrUndefined } from 'util';

// Search Pagination for Sites
import { AsyncPaginate } from 'react-select-async-paginate';

// Actions
import { clearCurrentJobQuotesInfo } from '../../actions/JobQuoteActions';

// Firebase
import firebase from 'firebase';
import { fire } from '../../index';

// Material UI
import { Grid, Button, IconButton, Icon, Dialog, DialogActions, DialogContent, Fab, NativeSelect, useMediaQuery, useTheme, Input, InputLabel } from '@material-ui/core';

// Components
import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import BackButton from '../../components/shared/BackButton';
import FloatingTaskStatusButton from '../../components/shared/FloatingTaskStatusButton';

import JobQuoteCard from '../../components/job-quote/JobQuoteCard';
import { LocalstorageSetItem, LocalstorageGetItem } from '../../utils/LocalStorage';
import IndexDb from '../../utils/database/indexdb/IndexDb';
import { useState } from 'react';
import { useRef } from 'react';
import { useTranslation, withTranslation } from 'react-i18next';

interface JobQuotesListScreenProps {
    history: History;
    match: match;
    UserSettings: Store.UserSettings;
    clearCurrentJobQuotesInfo: () => void;
    JobQuotesInfo: Store.JobQuotesInfoState;
    t: any;
}

interface JobQuotesListScreenState {
    UserUID: string;
    jobQuotes: JobQuote.JobQuote[];
    lastLoadedQuote: any;
    jobTaskID: string;
    addJobQuoteOpen: boolean;
    loading: boolean;
    loadingMore: boolean;
    endOfList: boolean;
    documentsPerPage: number;
    yAxisScroll: number;
    quoteStatusFilter: string;
    siteFilter?: {
        value: number;
        label: string;
    } | null;
    filtersActive: boolean;
    searchModalOpen: boolean;
    lastLoadedSite: any;
    sitesPerPage: number;
    siteValue: string;
    endSite: boolean;
    loadedSites: [];
    order: string;
}

class JobQuotesListScreen extends React.Component<JobQuotesListScreenProps, JobQuotesListScreenState> {
    private unsubscribe: any = null;

    constructor(props) {
        super(props);
        this.state = {
            jobQuotes: [],
            lastLoadedQuote: null,
            loading: false,
            jobTaskID: '',
            addJobQuoteOpen: false,
            UserUID: '',
            loadingMore: false,
            endOfList: false,
            documentsPerPage: 10,
            yAxisScroll: 0,
            quoteStatusFilter: '',
            siteFilter: null,
            filtersActive: false,
            searchModalOpen: false,
            lastLoadedSite: null,
            sitesPerPage: 50,
            siteValue: '',
            endSite: false,
            loadedSites: [],
            order: 'DESC',
        }
    }

    async componentDidMount() {
        // Clear Scroll Y Redux State if coming from sidebar
		const routerState = this.props.history.location.state;
		if(!isNullOrUndefined(routerState) && routerState.clearScrollState) {
			this.props.clearCurrentJobQuotesInfo();
		}

        const localItem = (await LocalstorageGetItem('JobQuoteFilterOptions')) || '{}';
        const JsonObj = JSON.parse(localItem);

        await this.setState({
            quoteStatusFilter: !isNullOrUndefined(JsonObj.quoteStatusFilter) ? JsonObj.quoteStatusFilter : '',
            siteFilter: JsonObj.siteFilter || null,
            yAxisScroll: JsonObj.yAxisScroll || 0,
            order: JsonObj.order || 'DESC',
        }, () => {
            this.setState({ filtersActive: this.areFiltersActive() }, () => {
                this.getJobQuotes();
            })
        })
    }
    
    componentWillUnmount() {
        this.unsubscribe = null;
        window.removeEventListener('scroll', this.handleScroll);
    }

    loadOptions = async(siteName, loadedOptions) => {
        if(siteName !== this.state.siteValue) {
            await this.setState({ loadedSites: [], lastLoadedSite: null, endSite: false, siteValue: siteName });
            loadedOptions = [];
        } 

        return new Promise((resolve) => {
            fire.getSearchSitesPaginated(this.state.sitesPerPage, this.state.siteValue, this.state.lastLoadedSite).get().then(docs => {
                if(!docs.empty) {
                    let sites:any = [];
                    docs.forEach(function(site) {
                        const siteData = {
                            value: site.data().SiteID,
                            label: site.data().SiteName
                        }
                        sites.push(siteData);
                    });
                    this.setState({ loadedSites: sites, lastLoadedSite: docs.docs[docs.docs.length - 1] }, () => {
                        return resolve({
                            options: this.state.loadedSites,
                            hasMore: !this.state.endSite
                        });
                    })
                } else {
                    this.setState({ endSite: true });
                    return resolve({
                        options: [],
                        hasMore: !this.state.endSite
                    });
                }
            })
        })
    }

    handleScroll = () => {
		this.setState({ yAxisScroll: window.scrollY }, () => {
			this.handleLocalStorageFilters(window.scrollY);
		});
    };
    
    handleLocalStorageFilters = (yAxisScroll?: number) => {
        const SearchArray = {
            siteFilter: this.state.siteFilter,
            quoteStatusFilter: this.state.quoteStatusFilter,
            YAxisScroll: yAxisScroll || this.state.yAxisScroll,
            order: this.state.order,
        }

        LocalstorageSetItem({ Key: 'JobQuoteFilterOptions', Value: SearchArray });
    }

    handleSearchModal = async () => {
        await this.setState({
            searchModalOpen: !this.state.searchModalOpen,
            lastLoadedSite: null,
        });
    }

    handleQuoteStatusFilter = selection => {
        this.setState({ quoteStatusFilter: selection.target.value });
    }

    handleSiteFilter = site => {
        this.setState({ siteFilter: { value: site.value, label: site.label }, lastLoadedSite: null });
    }

    getJobQuotes = () => {
        const jobQuoteFilterOptions = {
            quoteStatus: this.state.quoteStatusFilter,
            site: !isNullOrUndefined(this.state.siteFilter) ? this.state.siteFilter.value : null,
            order: this.state.order.toLowerCase(),
        };

        if(this.props.JobQuotesInfo.totalQuotesLoaded > 0) {
            const { totalQuotesLoaded } = this.props.JobQuotesInfo;
            this.setState({ yAxisScroll: window.scrollY }, () => {
                this.setState({ loading: true, filtersActive: this.areFiltersActive() }, () => {
                    this.unsubscribe = fire
                        .getQuotesPaginated(totalQuotesLoaded, this.state.lastLoadedQuote, jobQuoteFilterOptions)
                        .onSnapshot(this.handleQuoteSnapshot);
                });
            })
        } else {
            this.setState({ yAxisScroll: window.scrollY }, () => {
                this.setState({ loading: true, filtersActive: this.areFiltersActive() }, () => {
                    this.unsubscribe = fire
                        .getQuotesPaginated(this.state.documentsPerPage, this.state.lastLoadedQuote, jobQuoteFilterOptions)
                        .onSnapshot(this.handleQuoteSnapshot);
                });
            })
        }
    }

    async handleJobQuoteSearch() {
        this.handleLocalStorageFilters();

        await this.setState({
            loading: true,
            searchModalOpen: false,
            filtersActive: this.areFiltersActive(),
            yAxisScroll: this.state.yAxisScroll,
            lastLoadedQuote: null,
            loadedSites: [],
            lastLoadedSite: null,
            siteValue: '',
            endOfList: false
        }, () => {
            this.getJobQuotes();
        });
    }

    areFiltersActive() {
        const quoteStatusSearch = this.state.quoteStatusFilter;
        const siteSearch = this.state.siteFilter;
        
        let filtersActive = false;

        if(quoteStatusSearch !== '' || !isNullOrUndefined(siteSearch)) {
            filtersActive = true;
        }

        return filtersActive;
    }

    handleQuoteSnapshot = (query: firebase.firestore.QuerySnapshot) => {
        if(query.empty) {
            this.setState({ loading: false, loadingMore: false, endOfList: true });
            window.scrollTo(0, this.state.yAxisScroll);
            if(isNullOrUndefined(this.state.lastLoadedQuote)) this.setState({ jobQuotes: [] });
            return;
        }

        let QuoteList: JobQuote.JobQuote[] = isNullOrUndefined(this.state.lastLoadedQuote) ? [] : this.state.jobQuotes;

        const docChanges = query.docChanges();
        for (const quote of docChanges) {
            const jobQuote = quote.doc.data() as JobQuote.JobQuote;

            const jobQuoteIndex = QuoteList.findIndex(x => x.Id === quote.doc.id);

            const jobQuoteObj = {
                ...jobQuote,
                Id: quote.doc.id,
            };

            if(jobQuoteIndex === -1) {
                QuoteList.push(jobQuoteObj);
            } else {
                QuoteList[jobQuoteIndex] = jobQuoteObj
            }

            if(quote.type === 'removed') {
                QuoteList.splice(jobQuoteIndex, 1);
            }
        }

        QuoteList = this.sortingFunction(QuoteList);

        this.setState({
            jobQuotes: QuoteList,
            lastLoadedQuote: query.docs[query.docs.length - 1],
            loading: false,
            loadingMore: false,
        }, () => {
            if(this.props.JobQuotesInfo.totalQuotesLoaded != 0) {
                const { yAxisPos } = this.props.JobQuotesInfo;
                window.scrollTo(0, yAxisPos);

                this.props.clearCurrentJobQuotesInfo();
            } else {
                window.scrollTo(0, this.state.yAxisScroll);
            }
        })
    }

    sortingFunction = (QuoteList: JobQuote.JobQuote[]) => {
        if(this.state.order === 'DESC') {
            return QuoteList.sort((a, b) => {
                if (a.RequestedDate === b.RequestedDate) return 0;
                else if (a.RequestedDate === 0) return -1;
                else if (b.RequestedDate === 0) return 1;
                else if (a.RequestedDate < b.RequestedDate) return 1;
                else if (b.RequestedDate < a.RequestedDate) return -1;
                else return 0;
            });
        } else {
            return QuoteList.sort((a, b) => {
                if (a.RequestedDate === b.RequestedDate) return 0;
                else if (a.RequestedDate === 0) return -1;
                else if (b.RequestedDate === 0) return 1;
                else if (a.RequestedDate > b.RequestedDate) return 1;
                else if (b.RequestedDate > a.RequestedDate) return -1;
                else return 0;
            });
        }
    }

    async handleOrderFilter() {
        const newOrder = this.state.order === 'DESC' ? 'ASC' : 'DESC';
        await this.setState({ order: newOrder, loading: true, lastLoadedQuote: null, endOfList: false }, async () => {
            this.handleLocalStorageFilters();
            this.getJobQuotes();
        });
    }

    async handleClearSearch() {
        await this.setState({
            loading: true,
            quoteStatusFilter: '',
            filtersActive: false,
            lastLoadedQuote: null,
            endOfList: false,
            siteFilter: null,
            loadedSites: [],
            lastLoadedSite: null 
        }, () => {
            this.handleLocalStorageFilters();
            this.getJobQuotes();
        })
    }

    backButton = () => {
        this.props.history.replace('/job-task-list');
    }

    render() { 

        if(this.state.loading && !this.state.filtersActive) {
            return <LoadingSpinner color="primary" text={this.props.t("Loading Job Quotes")} />;
        }
        if(this.state.loading && this.state.filtersActive) {
            return <LoadingSpinner color="primary" text={this.props.t("Searching Job Quotes")} />;
        }

        return (
            <div>
                
                <SideDrawer
                    history={this.props.history}
                    title={this.props.t("Job Quotes")}
                    rightMenuButton={
                        <div>
                            <IconButton onClick={() => this.handleOrderFilter()} style={{ marginRight: 5 }}>
								<Icon style={{ color: 'white' }}>autorenew</Icon>
							</IconButton>
                            <BackButton callbackMethod={this.backButton} />
                        </div>
                    }
                />

                <Grid container={true} direction="column" justify="center" alignItems="center">
                    <div className="main">
                        {this.state.jobQuotes.length > 0 ? (
                            <div>
                                {this.state.jobQuotes.map((jobQuote, i) => (
                                    <JobQuoteCard numQuotes={this.state.jobQuotes.length} key={i} jobQuote={jobQuote} jobTaskID={this.state.jobTaskID} history={this.props.history} />
                                ))}

                                {this.state.endOfList ? (
                                    <Button id="outer-card-button" variant="outlined" size="large" fullWidth disabled>
                                        {this.props.t("All Job Quotes Loaded")}
                                    </Button>
                                ) : (
                                    <Button id="outer-card-button" variant="outlined" color="primary" size="large" fullWidth onClick={() => this.getJobQuotes()}>
                                        {this.props.t("Load More Job Quotes")}
                                    </Button>
                                )}
                            </div>
                        ) : (
                            <NoJobQuotes filtersActive={this.state.filtersActive} />
                        )}

                        <Fab
                            className={this.state.filtersActive ? 'not-hidden' : 'hidden'}
                            id="clear-search-fab"
                            color="inherit"
                            aria-label={this.props.t("Add")}
                            onClick={() => this.handleClearSearch()}
                        >
                            <Icon style={{ color: 'white', paddingTop: '2.5px' }}>clear</Icon>
                        </Fab>

                        <Fab 
                            id="search-fab" 
                            color="inherit" 
                            aria-label={this.props.t("Add")}
                            onClick={() => this.handleSearchModal()}
                        >
                            <Icon style={{ color: 'white' }}>search</Icon>
                        </Fab>

                        <FloatingTaskStatusButton history={this.props.history} />

                        <SearchDialogPopup
                            searchModalOpen={this.state.searchModalOpen}
                            handleSearchModal={this.handleSearchModal}
                            quoteStatusFilter={this.state.quoteStatusFilter}
                            handleQuoteStatusFilter={this.handleQuoteStatusFilter}
                            searchJobQuotes={() => this.handleJobQuoteSearch()}
                            siteFilter={this.state.siteFilter}
                            siteValue={this.state.siteValue}
                            loadOptions={this.loadOptions}
                            handleSiteFilter={this.handleSiteFilter}
                            clearSelectedSite={async () => {
                                await this.setState({ lastLoadedSite: null, loadedSites: [], siteFilter: null, siteValue: '', endSite: false }); this.handleLocalStorageFilters();}
                            }
                        />
                    </div>
                </Grid>
            </div>
        )
    }
}

const NoJobQuotes = props => {
    const [t, i18n] = useTranslation();
	return (
		<div className="no-jobtasks-card">
			<h1 className="hot-pink" >
				{props.filtersActive ? t('There are no job quotes matching your search criteria') : t('There are no job quotes.')}
			</h1>
		</div>
	);
};

const SearchDialogPopup = props => {
    const [t, i18n] = useTranslation();
    const [enableTextSearchSite, setTextSearchSite] = useState(false);
    const [siteMenuOpen, setSiteMenuOpen] = useState(false);
    const [showKeyboard, setShowKeyboard] = useState(false);

    const siteRef = useRef<HTMLInputElement>();

    useEffect(() => {
        updatePredicate();
    }, [window.innerWidth]);

    const updatePredicate = () => {
        setShowKeyboard(window.innerWidth < 600);
    }

    const openSiteMenu = () => {
		setSiteMenuOpen(true);
		if(!isNullOrUndefined(siteRef.current) && !isNullOrUndefined(siteRef.current.select)) {
			siteRef.current.focus();
		}
	}

	const closeSiteMenu = () => setSiteMenuOpen(false);
    
    return (
        <Dialog
            open={props.searchModalOpen}
            onClose={() => props.handleSearchModal()}
            fullScreen={useMediaQuery(useTheme().breakpoints.down('xs'))}
        >
            <DialogContent id="content" style={{ height: '520px' }}>
                <Grid container spacing={5}>
                    <Grid item xs={12}>
                        <InputLabel htmlFor="status-helper">{t("Quote Status")}</InputLabel>
                        <br />
                        <NativeSelect
                            value={props.quoteStatusFilter}
                            onChange={selection => props.handleQuoteStatusFilter(selection)}
                            input={<Input name="quoteStatus" id="status-helper" />}
                            name="Quote Status"
                            style={{ minWidth: 300, maxWidth: '100%' }}
                        >
                            <option value="">{t("None")}</option>
                            <option value="Pending">{t("Pending")}</option>
                            <option value="Submitted">{t("Submitted")}</option>
                            <option value="Approved">{t("Approved")}</option>
                            <option value="Rejected">{t("Rejected")}</option>
                        </NativeSelect>
                    </Grid>

                    <Grid item xs={12} style={{ width:'400px', maxWidth: '400px' }}>
                        <p style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                            <InputLabel htmlFor="site-helper" style={{ marginBottom: 5 }}>
                                {t("Site")}
                            </InputLabel>
                            {
                                showKeyboard && (
                                    <IconButton onClick={() => { setTextSearchSite(!enableTextSearchSite); openSiteMenu(); } }>
                                        <Icon style={{ color: enableTextSearchSite ? 'green' : '' }} >keyboard</Icon>	
                                    </IconButton>	
                                )
                            }	
                        </p>
                        <br />
                        <AsyncPaginate
                            id="site-helper"
                            value={props.siteFilter}
                            selectRef={siteRef}
                            onFocus={openSiteMenu}
                            onBlur={closeSiteMenu}
                            menuIsOpen={siteMenuOpen}
                            onChange={(e) => { props.handleSiteFilter(e); setSiteMenuOpen(false); }}
                            blurInputOnSelect={true}
                            loadOptions={props.loadOptions}
                            loadingMessage={() => t("Loading Sites...")}
                            cacheUniqs={[props.siteFilter, props.siteValue]}
                            noOptionsMessage={() => t("No Sites Found")}
                            isSearchable={ showKeyboard ? enableTextSearchSite : true }
                        />
                        <br />
                        {!isNullOrUndefined(props.siteFilter) && props.siteFilter !== '' && (
                            <div>
                                <b>{t("Selected" + ": ")}</b>
                                <IconButton onClick={props.clearSelectedSite} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
                                    <Icon color="error">
                                        delete_forever
                                    </Icon>
                                </IconButton>
                                {props.siteFilter.label}
                            </div>
                        )}
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions classes={{ root: 'action-buttons-list' }}>
                <Button onClick={props.handleSearchModal} color="primary">
                    {t("Cancel")}
                </Button>
                <Button onClick={props.searchJobQuotes} color="primary">
                    {t("Search")}
                </Button>
            </DialogActions>
        </Dialog>
    )
}

const mapStateToProps = (state: Store.Store) => ({
    UserSettings: state.User.UserSettings,
    JobQuotesInfo: state.JobQuotesInfo
});

const mapDispatchToProps = dispatch => ({
    clearCurrentJobQuotesInfo: () => dispatch(clearCurrentJobQuotesInfo())
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(JobQuotesListScreen));