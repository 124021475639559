import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Search Pagination for Sites
import { AsyncPaginate } from 'react-select-async-paginate';

import { isNullOrUndefined } from 'util';

function StatusDropdown(props) {
	const [t, i18n] = useTranslation();
	return (
		<div style={{flex: '1'}}>
		<AsyncPaginate
			id="status-helper"
			value={props.statusFilter}
			onChange={props.handleStatusFilter}
			loadOptions={props.loadStatuses}
			blurInputOnSelect={true}
			loadingMessage={() => t("Loading Statuses...")}
			cacheUniqs={[props.statusFilter]}
			noOptionsMessage={() => t("No Statuses Found")}
			isSearchable={false}
		/>
		<br/>
		<p className="field-label-alt">{t("Asset Status")}</p>
		<br/>
	</div>
	)
}

export default StatusDropdown;
