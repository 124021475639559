// React
import * as React from 'react';
import { isNullOrUndefined } from 'util';

// Img Import for React For images
import Img from 'react-image';

// Material UI
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	Grid,
	Icon,
	CircularProgress
} from '@material-ui/core';

// Components
import ImageLoadingSpinner from '../shared/ImageLoadingSpinner';
import DocumentDetails from '../shared/DocumentDetails';
import EditPhotoScreen from '../../views/Photos/EditPhotoScreen';
import { withTranslation } from 'react-i18next';

interface Photos {
	FirebaseStoragePath: string;
	Guid?: string;
	Filename?: string;
	UploadedBy?: string;
	Source?: string;
	DateCreated?: number;
}

interface PhotoGridState {
	photoGridXS: boolean | 1 | 2 | 10 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 'auto' | 11 | 12 | undefined;
	photoGridSpacing: 0 | 1 | 2 | 10 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | undefined;
	modalOpen: boolean;
	currentPhoto: string;
	Guid?: string;
	Filename?: string;
	UploadedBy?: string;
	Source?: string;
	DateCreated?: number;
	editMode?: boolean;
}

interface PhotoGridProps {
	history?: History;
	jobId?: string;
	isComplete?: boolean;
	photos: Photos[];
	canRemovePhoto: boolean;
	canDownloadPhoto: boolean;
	canEditPhoto?: boolean;
	handleDownloadPhoto?: (URL: string, FileName: string) => void;
	handleRemovePhoto?: (Guid: string, Reference: any) => void;
	documentReference?: any;
	t: any;
	showLoadMoreButton?: boolean;
	loadMorePhotosClick?: () => void;
	disableLoadMoreBtn?: boolean;
}

class PhotosGrid extends React.Component<PhotoGridProps, PhotoGridState> {
	constructor(props) {
		super(props);
		this.state = {
			photoGridSpacing: 1,
			photoGridXS: 'auto',
			modalOpen: false,
			currentPhoto: '',
			editMode: false,
		};
	}

	toggleModal = () => {
		this.setState({
			modalOpen: !this.state.modalOpen,
		});
	};

	editPhoto = (edit: boolean) => {
		this.setState({ editMode: edit });
	};

	closeAllDialogs = () => {
		this.setState({
			modalOpen: false,
			editMode: false,
		});
	};

	render() {
		if (this.props.canEditPhoto && this.state.editMode) {

			return (
				<Dialog open={this.state.editMode} onClose={() => this.editPhoto(false)} fullWidth={true}>
					<DialogContent style={{ textAlign: 'center' }}>
						<EditPhotoScreen
							photoUri={this.state.currentPhoto}
							clearPhoto={() => {
								this.editPhoto(false);
							}}
							// @ts-ignore
							DocumentID={this.props.jobId}
							newJob={false}
							// @ts-ignore
							history={this.props.history}
							guid={''}
							// @ts-ignore
							isComplete={this.props.isComplete}
							shouldRedirect={false}
							noRedirectCallback={this.closeAllDialogs}
						/>
					</DialogContent>
				</Dialog>
			);
		} else {
			return (
				<div style={{ width: 'auto' }}>
					<Grid container item spacing={this.state.photoGridSpacing}>
						{this.props.photos.map((photo, i) => (
							<Grid item xs={this.state.photoGridXS} key={i}>
								<Img
									className="grid-photo"
									src={photo.FirebaseStoragePath}
									loader={<ImageLoadingSpinner />}
									key={i}
									onClick={() => {
										this.setState({
											currentPhoto: photo.FirebaseStoragePath,
											Guid: photo.Guid,
											Filename: photo.Filename,
											UploadedBy: photo.UploadedBy,
											Source: photo.Source,
											DateCreated: photo.DateCreated,
										});
										this.toggleModal();
									}}
								/>
							</Grid>
						))}
					</Grid>

					{this.props.showLoadMoreButton && (
						<Button
							variant="outlined"
							color="primary"
							size="large"
							id="loadMoreBtn"
							fullWidth
							onClick={this.props.loadMorePhotosClick}
							disabled={this.props.disableLoadMoreBtn}
						>
							{this.props.t("Load More Photos")}
							{this.props.disableLoadMoreBtn && (
								<CircularProgress
									size={12}
									color={'inherit'} style={{ "marginLeft": "5px" }}
								/>
							)}
						</Button>
					)}

					<Dialog open={this.state.modalOpen} onClose={() => this.toggleModal()} fullWidth={false} maxWidth={false}>
						<Icon style={{ position: 'absolute', top: 5, right: 5, cursor: 'pointer' }} onClick={this.toggleModal}>
							close
						</Icon>
						<br /><br />
						<DialogContent>
							<Img src={this.state.currentPhoto} />

							<DocumentDetails
								Filename={this.state.Filename}
								UploadedBy={this.state.UploadedBy}
								Source={this.state.Source}
								DateCreated={this.state.DateCreated}
							/>
						</DialogContent>

						{(this.props.canRemovePhoto || this.props.canDownloadPhoto || this.props.canEditPhoto) && (
							<DialogActions style={{ borderTop: '1px solid var(--light-grey)' }}>
								<div style={{ marginTop: 10 }}>
									{this.props.canRemovePhoto && (
										<Button
											style={{ backgroundColor: 'var(--light-red)', color: 'white' }}
											variant="outlined"
											color="inherit"
											size="small"
											onClick={async () => {
												await this.toggleModal();
												if (
													!isNullOrUndefined(this.state.Guid) &&
													!isNullOrUndefined(this.props.documentReference) &&
													!isNullOrUndefined(this.props.handleRemovePhoto)
												) {
													this.props.handleRemovePhoto(this.state.Guid, this.props.documentReference);
												}
											}}
										>
											<Icon style={{ color: 'white' }} fontSize="small">
												clear
											</Icon>
											{this.props.t("Remove")}
										</Button>
									)}

									{this.props.canDownloadPhoto && (
										<Button
											style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
											variant="outlined"
											color="inherit"
											size="small"
											onClick={() => {
												if (
													!isNullOrUndefined(this.state.Filename) &&
													!isNullOrUndefined(this.props.handleDownloadPhoto)
												) {
													this.props.handleDownloadPhoto(this.state.currentPhoto, this.state.Filename);
												}
											}}
										>
											<Icon style={{ color: 'white' }} fontSize="small">
												cloud_download
											</Icon>
											&nbsp; {this.props.t("Download")}
										</Button>
									)}

									{this.props.canEditPhoto && (
										<Button
											style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
											variant="outlined"
											color="inherit"
											size="small"
											onClick={() => {
												this.editPhoto(true);
											}}
										>
											<Icon style={{ color: 'white' }} fontSize="small">
												edit
											</Icon>
											&nbsp; {this.props.t("Edit")}
										</Button>
									)}

								</div>
							</DialogActions>
						)}
					</Dialog>
				</div>
			);
		}
	}
}

export default withTranslation()(PhotosGrid);
