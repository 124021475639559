// React
import * as React from 'react';
import { isNullOrUndefined } from 'util';
// Material UI
import { CircularProgress } from '@material-ui/core';
// Components
import CardHeader from '../shared/CardHeader';
import PhotosGrid from './PhotosGrid';
import { withTranslation } from 'react-i18next';
import CardBorder from '../shared/CardBorder';

//card icon
import PHOTO from '../../assets/images/icons/PHOTO.svg'

interface Photos {
	FirebaseStoragePath: string;
	Guid?: string;
	Filename?: string;
	UploadedBy?: string;
	Source?: string;
	DateCreated?: number;
}

interface PhotoDialogProps {
	history?: History;
	documentId?: string;
	isComplete?: boolean;
	color?: string | null;
	photos: Photos[];
	titleText: string;
	noPhotosText: string;
	isForRequest: boolean;
	canRemovePhoto: boolean;
	canDownloadPhoto: boolean;
	handleDownloadPhoto?: (URL: string, FileName: string) => void;
	handleRemovePhoto?: (Guid: string, Reference: any) => void;
	handleDownloadAllPhotos?: () => void;
	documentReference?: any;
	numberOfPhotos?: number;
	displayDownloadAll?: boolean;
	canEditPhoto?: boolean;
	addPhoto?: () => any;
	t: any;
	photosLoading?: boolean;
	showLoadMoreButton?: boolean;
	loadMorePhotosCilck?: () => void;
	disableLoadMoreBtn?: boolean;
}

interface PhotosState {
	expand: boolean;
}

class PhotosCardDialog extends React.Component<PhotoDialogProps, PhotosState> {
	constructor(props) {
		super(props);
		this.state = { expand: false }
	}

	handleExpand = (value) => {
		this.setState({ expand: value })
	}

	render() {
		if (this.props.photos.length < 1 && this.props.isForRequest) {
			return null;
		}

		if (
			!isNullOrUndefined(this.props.numberOfPhotos) &&
			this.props.numberOfPhotos > 0 &&
			this.props.photos.length === 0
		) {
			return (
				<CardBorder styles="photo-card">
					<CircularProgress
						className="loading-photos-progress"
						size={18}
						color={'secondary'}
						style={{ marginRight: 10 }}
					/>
					<h2 className="hot-pink loading-photos"> {this.props.t("Loading") + this.props.numberOfPhotos + " " + this.props.t("Photos.")}</h2>
				</CardBorder>
			);
		} else {
			return (
				<div>
					<CardBorder marginBottom="15px" padding="0" styles='border-radius'>
						<CardHeader
							title={this.props.titleText}
							handleDownloadAllPhotos={this.props.handleDownloadAllPhotos}
							display={this.props.displayDownloadAll}
							textColor="white" backgroundColor="#3f51b5"
							showActionButton={true}
							actionButtonID="attach-form-button"
							actionButtonIcon="camera-fill"
							actionButtonText={this.props.t("Add")}
							actionButtonClickHandler={this.props.addPhoto}
							expand={this.state.expand}
							handleExpand={this.handleExpand}
							showExpand={true}
							showLoaderIcon={true}
							photosLoading={this.props.photosLoading}
							t={this.props.t}
							titleIcon={PHOTO}	
						/>
						{this.state.expand ? <div className="photos-container loading-elements-card-container">
							{(true && this.props.photosLoading) ?  (
					<>{this.props.t("Loading")} <CircularProgress
						size={10}
						color={'inherit'} style={{ "marginLeft": "5px" }} /></>
				) : <>{this.props.photos.length < 1 ? (<>
								{this.state.expand && <div className='no-elements-card-text'>
									<p>{this.props.t(this.props.noPhotosText)}</p>
								</div>}
							</>) : <PhotosGrid
								history={this.props.history}
								jobId={this.props.documentId}
								isComplete={this.props.isComplete}
								photos={this.props.photos}
								canRemovePhoto={this.props.canRemovePhoto}
								canDownloadPhoto={this.props.canDownloadPhoto}
								handleRemovePhoto={this.props.handleRemovePhoto}
								handleDownloadPhoto={this.props.handleDownloadPhoto}
								documentReference={this.props.documentReference}
								canEditPhoto={this.props.canEditPhoto}
								showLoadMoreButton={this.props.showLoadMoreButton}
								loadMorePhotosClick={this.props.loadMorePhotosCilck}
								disableLoadMoreBtn={this.props.disableLoadMoreBtn}
							/>}</>}
						</div> : <></>}
					</CardBorder>
				</div>
			);
		}
	}
}

export default withTranslation()((PhotosCardDialog));


