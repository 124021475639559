import React, { useEffect, useState } from 'react';
import { fire } from '../..';
import AssetFormList from '../asset/AssetFormList';
import AddAssetToFormButton from './AddAssetToFormButton';
import QuestionLayout from './QuestionLayout';

interface AssetQuestionProps {
	questionAnswer: Forms.QuestionAnswer;
	formComplete: boolean;
	formId: string;
	formTemplateFBID: string;
	taskID: string;
}

const AssetQuestion = (props: AssetQuestionProps) => {
	const { questionAnswer, formComplete, formId, formTemplateFBID, taskID } = props;
	const [assets, setAssets] = useState<Asset[]>([]);

	useEffect(() => {
		const assets: Asset[] = [];
		if (questionAnswer.Assets) {
			for (let assetId in questionAnswer.Assets) {
				assets.push(questionAnswer.Assets[assetId]);
			}
		}
		setAssets(assets);
	}, [questionAnswer]);

	const removeAsset = (assetFBID: string) => {
		console.log("This ran.");
		fire.removeAttachedAssetFromForm(formId, questionAnswer.Id, assetFBID);
	};

	return (
		<QuestionLayout>
			{questionAnswer.QuestionTitle}
			<>
				<AddAssetToFormButton formID={formId} disabled={formComplete} questionAnswerID={questionAnswer.Id} formTemplateFBID={formTemplateFBID} taskID={taskID} />
				<AssetFormList assets={assets} documentId={formId} removeAsset={removeAsset} />
			</>
		</QuestionLayout>
	);
};

export default AssetQuestion;
