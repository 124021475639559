import { isNullOrUndefined } from 'util';

interface SetItemProps {
	Key: string;
	Value: object | any;
}

export const LocalstorageGetItem = (props: string) => {
	const storageItem = localStorage.getItem(props);
	if (isNullOrUndefined(storageItem)) {
		return '';
	}

	return storageItem;
};

export const LocalstorageRemoveItem = (props: string) => localStorage.removeItem(props);

export const LocalstorageSetItem = (props: SetItemProps) =>
	localStorage.setItem(props.Key, JSON.stringify(props.Value));
