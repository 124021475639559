import { CircularProgress } from '@material-ui/core';
import * as React from 'react';

const InlineLoadingSpinner = props => (
	<div style={{ width: '100%', marginTop: '10px' }}>
		<CircularProgress size={props.size || 50} color={props.color || 'primary'} />
		<span style={{ marginLeft: '10px', fontSize: `${props.size}px`, color: '#e91e63' }}>
			{props.text || 'Loading...'}
		</span>
	</div>
);

export default InlineLoadingSpinner;
