import * as React from 'react';
import Webcam from 'react-webcam';
import Icon from '@material-ui/core/Icon';
import IconButton from '@material-ui/core/IconButton';
import EditPhotoScreen from './EditPhotoScreen';
import { History } from 'history';
import { windowError } from '../../utils/WindowError';
import { connect } from 'react-redux';

interface TakePhotoScreenState {
	photoUri: string;
	jobTaskId: string;
	newJob: boolean;
	guid: string | null;
	facingMode: any;
	isComplete: boolean;
	fromFormFail: boolean;
	formId: string;
	formFailData: any;
}
interface TakePhotoScreenProps {
	history: History;
	UserSettings: Store.UserSettings;
}

class TakePhotoScreen extends React.Component<TakePhotoScreenProps, TakePhotoScreenState> {
	private webcam: any = null;

	constructor(props) {
		super(props);
		this.state = {
			photoUri: '',
			jobTaskId: '',
			newJob: false,
			guid: null,
			facingMode: 'environment',
			isComplete: false,
			fromFormFail: false,
			formId: '',
			formFailData: '',
		};
	}

	UNSAFE_componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'TakePhotoScreen',
		);
		const { jobTaskId, newJob, guid, fromFormFail, formId, formFailData } = this.props.history.location.state;
		this.setState({ jobTaskId, newJob, guid, fromFormFail, formId, formFailData });
	}

	setRef = webcam => {
		this.webcam = webcam;
	};

	capture = () => {
		const imageSrc = this.webcam.getScreenshot();
		this.setState({ photoUri: imageSrc });
	};

	clearPhoto = () => {
		this.setState({ photoUri: '' });
	};

	switchCameras = () => {
		if (this.state.facingMode === 'user') {
			this.setState({ facingMode: 'environment' });
		} else {
			this.setState({ facingMode: 'user' });
		}
	};

	render() {
		const videoConstraints = {
			width: 1280,
			height: 720,
			facingMode: this.state.facingMode,
		};

		if (this.state.photoUri === '') {
			return (
				<div id="photo-screen-container">
					<Webcam
						audio={false}
						ref={this.setRef}
						screenshotFormat="image/jpeg"
						videoConstraints={videoConstraints}
						onUserMediaError={err => console.error(`Error getting user media: ${err}`)}
					/>
					<div className="photo-screen-actions">
						<IconButton color="inherit" aria-label="Back" onClick={() => this.props.history.goBack()}>
							<Icon className="white" fontSize="large">
								arrow_back
							</Icon>
						</IconButton>
						<IconButton color="inherit" aria-label="Take Photo" onClick={() => this.capture()}>
							<Icon className="white" fontSize="large">
								photo_camera
							</Icon>
						</IconButton>
						<IconButton color="inherit" aria-label="Change Camera" onClick={() => this.switchCameras()}>
							<Icon className="white" fontSize="large">
								compare_arrows
							</Icon>
						</IconButton>
					</div>
				</div>
			);
		}
		return (
			<EditPhotoScreen
				photoUri={this.state.photoUri}
				clearPhoto={this.clearPhoto}
				jobTaskId={this.state.jobTaskId}
				newJob={this.state.newJob}
				history={this.props.history}
				guid={this.state.guid}
				isComplete={this.state.isComplete}
				fromFormFail={this.state.fromFormFail}
				formId={this.state.formId}
				formFailData={this.state.formFailData}
			/>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default connect(mapStateToProps, null)(TakePhotoScreen);
