import { SAVE_ISSUE_STATE, CLEAR_ISSUE_STATE, CURRENT_JOBTASKS, CURRENT_JOBTASKS_SCROLLY, CLEAR_JOBTASKS_STATE } from '../actions/JobTaskActions';

const JobTasks: Store.JobTasks = {
	NewIssueState: null,
	totalJobtasksLoaded: 0,
	yAxisPos: 0
};

const JobTaskReducer = (state = JobTasks, action) => {
	switch (action.type) {
		case SAVE_ISSUE_STATE:
			state = {
				...state,
				NewIssueState: action.payload,
			};
			return state;

		case CLEAR_ISSUE_STATE:
			state = {
				...state,
				NewIssueState: null,
			};
			return state;
		
		case CURRENT_JOBTASKS: 
			state = {
				...state,
				totalJobtasksLoaded: action.payload
			}
			return state;

		case CURRENT_JOBTASKS_SCROLLY:
			state = {
				...state,
				yAxisPos: action.payload
			}
			return state;

		case CLEAR_JOBTASKS_STATE:
			state = {
				...state,
				totalJobtasksLoaded: JobTasks.totalJobtasksLoaded,
				yAxisPos: JobTasks.yAxisPos
			}
			return state;

		default:
			return state;
	}
};
export default JobTaskReducer;
