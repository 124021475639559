import * as React from 'react';
import OuterCard from '../shared/OuterCard';
import { Table, TableHead, TableRow, TableCell, TableBody, IconButton, Icon, Box } from '@material-ui/core';
import Firebase from '../../utils/Firebase';
import { fire } from '../..';
import EditStockModal from './EditStockModal';
import CardHeader from '../shared/CardHeader';
import { withTranslation } from 'react-i18next';
import { fireJobTaskStockConverter } from '../../utils/FirebaseConverters';

//card icon
import STOCK from '../../assets/images/icons/STOCK.svg'

interface JobTaskStockProps {
	jobTaskFBID: string;
	fire: Firebase;
	t: any;
	expand: boolean;
	handleExpand: (value: boolean) => void;
	stockDialogClose: () => void;
}

interface JobTaskStockState {
	stocks: JobTask.JobTaskStock[];
	editStockObject: JobTask.JobTaskStock;
	editStockOpen: boolean;
}

class JobTaskStock extends React.Component<JobTaskStockProps, JobTaskStockState> {
	constructor(props) {
		super(props);
		this.state = {
			stocks: [],
			editStockObject: {
				Name: '',
				Comment: '',
				FreeTextName: '',
				Quantity: "",
				StockFBID: '',
				JobTaskStockFBID: '',
				StorageAreaFBID: '',
				StorageAreaName: '',
			},
			editStockOpen: false,
		};
	}

	componentDidMount() {
		this.getStocks();
	}

	getStocks(): void {
		fire.baseQuery
			.collection(`JobTasks/${this.props.jobTaskFBID}/Stock`)
			.withConverter(fireJobTaskStockConverter)
			.onSnapshot(snap => {
				const stocks = snap.docs.map(doc => {
					const stock = doc.data();
					stock.JobTaskStockFBID = doc.id;
					return stock;
				});
				this.setState({ stocks });
			});
	}

	deleteStock(row: JobTask.JobTaskStock): void {
		const confirm = window.confirm(this.props.t('Are you sure you want to delete this stock record?'));
		if (confirm) {
			const ref = this.props.fire.baseQuery
				.collection('JobTasks')
				.doc(this.props.jobTaskFBID)
				.collection('Stock')
				.doc(row.JobTaskStockFBID);
			this.props.fire.deleteDocument(ref);

			//Delete stock
			const stockObject: Partial<JobTask.JobTaskStock> & { JobAction: string; StockAction: string } = {
				StockFBID: row.StockFBID,
				JobAction: 'StockJobTask',
				StockAction: 'Delete',
				JobTaskStockFBID: row.JobTaskStockFBID,
				StorageAreaFBID: row.StorageAreaFBID,
			};
			this.props.fire.postToJobQueue(this.props.jobTaskFBID, stockObject);
		}
	}
	editStock(row: JobTask.JobTaskStock): void {
		this.setState({ editStockOpen: true, editStockObject: row });
	}

	editStockDialogClose = () => {
		this.setState({ editStockOpen: !this.state.editStockOpen });
	};

	render() {
		return (
			<OuterCard id="stocks-card" padding="0px" styles='border-radius'>
				<CardHeader title={this.props.t('Job Task Stock')} titleIcon={STOCK} textColor="white" backgroundColor="#ffc107" expand={this.props.expand} 
				handleExpand={this.props.handleExpand} showExpand={true}
				showActionButton={true} actionButtonText={this.props.t("Add Stock")} actionButtonIcon="add_box" 
				actionButtonClickHandler={this.props.stockDialogClose} />
				{this.state.stocks.length < 1 ? 
				<div className='no-elements-card-text'>
					 {this.props.expand && <p>{this.props.t('No Job Task Stock')}</p>}
				</div> :
				<>{this.props.expand ? <Table>
					<TableHead>
						<TableRow style={{ padding: '0', paddingBottom: '5px' }}>
							<TableCell className="cell4" align="center">
								{this.props.t('Name')}
							</TableCell>
							<TableCell className="cell4" align="center">
								{this.props.t('Quantity')}
							</TableCell>
							<TableCell className="cell3" align="center">
								{this.props.t('Comment')}
							</TableCell>
							<TableCell className="cell5" padding="none" />
						</TableRow>
					</TableHead>
					<TableBody>
						{this.state.stocks.map((row, i) => {
							return (
								<TableRow key={i} data-rowid={i}>
									<TableCell className="cell4" align="center">
										{row.Name !== '' ? row.Name : row.FreeTextName}
									</TableCell>
									<TableCell className="cell4" align="center">
										{row.Quantity}
									</TableCell>
									<TableCell className="cell3" align="center">
										{row.Comment}
									</TableCell>
									<TableCell className="cell5" padding="none">
										<Box display="flex" justifyContent="flex-end">
											<Box marginRight={1}>
												<IconButton onClick={() => this.editStock(row)}>
													<Icon style={{ color: 'var(--light-blue)' }}>edit</Icon>
												</IconButton>
											</Box>
											<IconButton onClick={() => this.deleteStock(row)}>
												<Icon style={{ color: 'var(--hot-pink)' }}>delete</Icon>
											</IconButton>
										</Box>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table> : <></>}</>}
				<EditStockModal
					open={this.state.editStockOpen}
					stockObject={this.state.editStockObject}
					editStockDialogClose={this.editStockDialogClose}
					jobTaskFBID={this.props.jobTaskFBID}
					fire={this.props.fire}
				/>
			</OuterCard>
		);
	}
}

export default withTranslation()(JobTaskStock);
