import * as React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import { History } from 'history';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { fire, idb, localQueue } from '../../index';
import CreateExpenseDialog from '../../components/expenses/CreateExpenseDialog';
import { generateFirebaseId, generateGuid } from '../../utils/Guids';
import { isNullOrUndefined } from 'util';
import imageCompression from 'browser-image-compression';
import { windowError } from '../../utils/WindowError';
import { connect } from 'react-redux';
import OuterCard from '../../components/shared/OuterCard';
import { withTranslation } from 'react-i18next';
import ExpensesList from './ExpensesList';
import CardHeader from '../../components/shared/CardHeader';

//card icon
import EXPENSE from '../../assets/images/icons/EXPENSE.svg'

interface ExpensesProps {
    history: History;
    UserSettings: Store.UserSettings;
    jobTaskNumber?: string;
    jobTaskFBID?: string;
    isJobTaskPreset?: boolean;
    t: any;
    expand: boolean;
    handleExpand: () => void;
}

interface ExpensesState {
    expensesArray: Expense.Expense[];
    loading: boolean;
    expenseDialogOpen: boolean;
    newJobTaskNumber: string;
    newJobTask: string;
    newDescription: string;
    newSupplier: string;
    newQuantity: string;
    newPrice: string;
    newExpenseDocuments: File[];
    generatedDocumentId: string | null;
}

class ExpensesListCard extends React.Component<ExpensesProps, ExpensesState> {
    constructor(props) {
        super(props);
        this.state = {
            expensesArray: [],
            loading: true,
            expenseDialogOpen: false,
            newJobTaskNumber: this.props.jobTaskNumber || '',
            newJobTask: this.props.jobTaskFBID || '',
            newDescription: '',
            newSupplier: '',
            newQuantity: '',
            newPrice: '',
            newExpenseDocuments: [],
            generatedDocumentId: null,
        };
    }

    componentWillMount() {
        windowError(
            this.props.UserSettings.Email,
            this.props.UserSettings.UserUID,
            this.props.UserSettings.ServerName,
            'ExpensesListCard',
        );
    }

    componentDidMount() {
        // if component mounts get expenses
        this.getExpenses();
    }

    backButton = () => {
        this.props.history.replace('/');
    };

    handleExpenseDialogVisibility = () => {
        if (!isNullOrUndefined(this.state.generatedDocumentId)) {
            idb.removeDocumentsForRequestByGuid(this.state.generatedDocumentId);
            idb.removeDocumentByDocId(this.state.generatedDocumentId);
        }

        this.setState({
            expenseDialogOpen: !this.state.expenseDialogOpen,
            newExpenseDocuments: [],
            generatedDocumentId: generateFirebaseId(),
        });
    };

    handleCreateExpense = () => {
        if (
            this.state.newDescription &&
            this.state.newSupplier &&
            this.state.newPrice &&
            this.state.newQuantity &&
            this.state.generatedDocumentId
        ) {
            const newExpenseObj = {
                JobTaskFBID: this.state.newJobTask,
                JobTaskNumber: this.state.newJobTaskNumber,
                Description: this.state.newDescription,
                DateCreated: Date.now(),
                Status: 'Pending',
                Supplier: this.state.newSupplier,
                UnitPrice: parseFloat(this.state.newPrice).toFixed(2),
                Units: parseInt(this.state.newQuantity, 10).toString(),
                DocumentId: this.state.generatedDocumentId,
                AssignedResource: fire.currentUser.uid,
                JobAction: 'AddExpense',
            };

            fire.baseQuery
                .collection('Expenses')
                .doc(this.state.generatedDocumentId)
                .set(newExpenseObj);

            localQueue.saveToLocalJobQueue(this.state.generatedDocumentId, newExpenseObj).then(() => {
                this.setState({
                    expenseDialogOpen: !this.state.expenseDialogOpen,
                    newDescription: '',
                    newSupplier: '',
                    newPrice: '',
                    newQuantity: '',
                    generatedDocumentId: null,
                });
            });
        } else {
            alert(
                this.props.t('All fields must be entered before you can create the expense. Quantity and Price can also must be greater than 0.'),
            );
        }
    };

    newJobTaskChange = (object: { name: string; value: string }) => {
        this.setState({
            newJobTaskNumber: object.name,
            newJobTask: object.value
        });
    };

    newDescriptionChange = e => {
        this.setState({ newDescription: e.target.value });
    };

    newSupplierChange = e => {
        this.setState({ newSupplier: e.target.value });
    };

    newQuantityChange = e => {
        this.setState({ newQuantity: parseInt(e.target.value, 10).toString() });
    };

    newPriceChange = e => {
        const newPrice: string = parseFloat(e.target.value).toFixed(2);
        this.setState({ newPrice });
    };

    removeDocumentFromList = doc => {
        if (isNullOrUndefined(doc.id)) return;
        idb.removeDocumentsForRequestByDocumentTableGuid(doc.Guid);
        idb.removeDocument(doc.id).then(() => this.getDocuments());
    };

    getExpenses = () => {
        this.setState({ loading: true });

        const expensesCollection = isNullOrUndefined(this.props.jobTaskFBID)
            ? fire.baseQuery
                .collection('Expenses')
                .where('AssignedResource', '==', fire.resourceFBID)
                .orderBy('DateCreated', 'desc')
            : fire.baseQuery
                .collection('Expenses')
                .where('AssignedResource', '==', fire.resourceFBID)
                .where('JobTaskFBID', '==', this.props.jobTaskFBID)
                .orderBy('DateCreated', 'desc');

        expensesCollection.onSnapshot(query => {
            if (query.empty) {
                this.setState({ expensesArray: [], loading: false });
                return;
            }
            const expensesArray = query.docs.map(expenseRow => {
                const row = expenseRow.data() as Expense.Expense;
                row.ID = expenseRow.id;
                row.TotalCost = Number(row.UnitPrice) * Number(row.Units);
                return row;
            });
            this.setState({ expensesArray, loading: false });
        });
    };

    openDocumentDialog = () => {
        const imageUploadButton = document.getElementById('expense-document-upload');
        if (imageUploadButton !== null) {
            imageUploadButton.click();
        }
    };

    getDocuments = () => {
        if (isNullOrUndefined(this.state.generatedDocumentId)) return;
        idb.getDocuments(this.state.generatedDocumentId).then(documents => {
            const documentList = documents.map(doc => {
                const blob = new Blob([doc.document]);
                blob['name'] = doc.documentName;
                blob['id'] = doc.id;
                blob['Guid'] = doc.guid;

                return blob;
            });

            this.setState({ newExpenseDocuments: documentList as File[] });
        });
    };

    saveExpenseDocument = async (selectorFiles: FileList | null) => {
        const options = {
            maxSizeMB: 1,
            maxWidthOrHeight: 800,
            useWebWorker: true
        }

        if (selectorFiles !== null && !isNullOrUndefined(this.state.generatedDocumentId)) {
            const document: File = selectorFiles[0];

            if (document['type'].split('/')[0] === 'image') {
                const extention = document.name.split('.').pop();
                const compressedFile: Blob = await imageCompression(document, options);

                var b: any = compressedFile;
                b.lastModifiedDate = new Date();
                b.name = `Contractor-Photo-${new Date().getTime()}.` + extention;

                let newGuid = generateGuid();

                idb
                    .saveDocumentToLocal(compressedFile as File, this.state.generatedDocumentId as string, 'true', "", newGuid)
                    .then(object => {
                        idb.setDocumentForRequest(object.File, this.state.generatedDocumentId as string, b.name, object.Guid);
                        this.getDocuments();
                    });
            } else {
                idb.saveDocumentToLocal(document, this.state.generatedDocumentId, 'true', "", generateGuid()).then(object => {
                    idb.setDocumentForRequest(object.File, this.state.generatedDocumentId, document.name, object.Guid);
                    this.getDocuments();
                });
            }
        }
    };

    render() {

        //if it is called from the task details page then want zero padding
        let cellPadding = "7.5px";
        if (!isNullOrUndefined(this.props.jobTaskFBID)) {
            cellPadding = "0px";
        }

        // While loading Expenses
        if (this.state.loading) {
            return <LoadingSpinner text={this.props.t("Loading expenses...")} />;
        }

        return (
            <div>
                <OuterCard id="expenses-card" padding="0px" styles='border-radius'>
                    <CardHeader title={this.props.t('Expenses')} titleIcon={EXPENSE} textColor="white" backgroundColor="#ffc107"
                    expand={this.props.expand} handleExpand={this.props.handleExpand} showExpand={true} showActionButton={true}
                    actionButtonText={this.props.t("Create Expense")} actionButtonIcon="add" actionButtonClickHandler={this.handleExpenseDialogVisibility} />
                    <CreateExpenseDialog
                        open={this.state.expenseDialogOpen}
                        handleExpenseDialogVisibility={this.handleExpenseDialogVisibility}
                        newJobTaskChange={this.newJobTaskChange}
                        newDescriptionChange={this.newDescriptionChange}
                        newSupplierChange={this.newSupplierChange}
                        newQuantityChange={this.newQuantityChange}
                        newPriceChange={this.newPriceChange}
                        handleCreateExpense={this.handleCreateExpense}
                        handleUpdateExpense={null}
                        openDocumentDialog={this.openDocumentDialog}
                        saveExpenseDocument={this.saveExpenseDocument}
                        newExpenseDocuments={this.state.newExpenseDocuments}
                        removeDocumentFromList={this.removeDocumentFromList}
                        createExpense={true}
                        jobTaskDefaultValue={this.state.newJobTask}
                        descriptionDefaultValue={''}
                        supplierDefaultValue={''}
                        quantityDefaultValue={0}
                        priceDefaultValue={0}
                        isJobTaskPreset={this.props.isJobTaskPreset}
                    />
                    {this.state.expensesArray.length < 1 ?
                        <div className='no-elements-card-text'>
                            {this.props.expand && <p>{this.props.t('No Expenses')}</p>}
                        </div> :
                        <>{this.props.expand && <Grid container={true} direction="column" justify="center" alignItems="center">
                            <div style={{ maxWidth: '650px', width: '100%' }}>
                                <div style={{ paddingLeft: `${cellPadding}`, paddingRight: `${cellPadding}` }}>
                                    <ExpensesList expenses={this.state.expensesArray} jobTaskFBID={this.props.jobTaskFBID} />
                                </div>
                            </div>
                        </Grid>}</>}
                </OuterCard>
            </div>
        );
    }
}

const mapStateToProps = (state: Store.Store) => ({
    UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(
    mapStateToProps,
    null,
)(ExpensesListCard));
