import * as React from 'react';
import * as ReactDOM from 'react-dom';
import * as Sentry from '@sentry/browser';

import App from './App';
import { register } from './serviceWorker';
import IndexDb from './utils/database/indexdb/IndexDb';
import Firebase from './utils/Firebase';
import LocalQueue from './utils/database/indexdb/LocalQueue';
// import MessageBroker from './messageBroker';
import packageJson from '../package.json';
import { isIos, isIOS13 } from './utils/IsIos';

import './i18n';
import './index.css';

if (process.env.NODE_ENV === 'production') {
	Sentry.init({
		dsn: 'https://2bd193a29e194c1e9876684625197b61@sentry.io/1888513',
		release: 'trackplan-resource-app@' + packageJson.version,
	});
}

const fire = new Firebase();

ReactDOM.render(<App />, document.getElementById('root') as HTMLElement);

register();



var userAgent = window.navigator.userAgent;
//console.log(/^((?!chrome|android).)*safari/i.test(navigator.userAgent));

if((/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) == false){
if (!userAgent.match(/iPad/i) || !userAgent.match(/iPhone/i)){
Notification.requestPermission().then(function(status) {
	//console.log('Notification permission status:', status);

})}};




const idb = new IndexDb(fire);
const localQueue = new LocalQueue(fire, idb);
// new MessageBroker();

export { fire, idb, localQueue };
