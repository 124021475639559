import * as React from 'react';
import { Button, CircularProgress, Grid, Icon } from '@material-ui/core';
import { History } from 'history';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ExpandLessIcon from '@material-ui/icons/ExpandLess';

//style
import '../../styles/shared/card-header-alt.css'

interface CardHeaderProps {
	backgroundColor: string;
	textColor: string;
	title: string;
	titleIcon: string;
	showActionButton?: boolean | false;
	actionButtonID?: string;
	actionButtonIcon?: string;
	actionButtonText?: string;
	actionButtonClickHandler?: () => void;
	display?: boolean;
	handleDownloadAllPhotos?: () => void;
	expand: boolean;
	handleExpand: (value: boolean) => void;
	showExpand: boolean;
	isAssetJobHeader?: boolean;
	activeAsset?: Asset;
	handleRaiseNewJobfromAsset?: (activeAsset: Asset, history: History) => void;
	history?: History;
	showLoaderIcon?: boolean;
	photosLoading?: boolean;
	t?: any;
}

const controlFunction = (props: CardHeaderProps, command: string, e: React.MouseEvent) => {
	e.stopPropagation()
	if (command === 'expand') {
		return props.handleExpand(!props.expand)
	} else {
		if (props.actionButtonClickHandler) {
			props.handleExpand(true)
			return props.actionButtonClickHandler()
		}
	}
}

const CardHeader = (props: CardHeaderProps) => (
	<div className="alt-card-title full-line field-label">
		<Grid justify="space-between" container spacing={10} direction="row" alignItems="center" className='cursor-pointer'
		onClick={(e) => controlFunction(props, 'expand', e)}>
			<Grid item className='card-header-icon-n-title'>
				<img src={props.titleIcon} width='40' alt='title_icon'/>&nbsp;<span className='card-title-max-width'>{props.title}</span>
			</Grid>
			
			{props.display === true && (
					<Grid item alignItems="flex-end">
						<Icon className='cursor-pointer' onClick={props.handleDownloadAllPhotos}>get_app</Icon>
					</Grid>
				)
			}

			
			<Grid item className='expansion-icon-holder'>
				{props.showExpand && <>{props.expand === false ? <ExpandMoreIcon /> : <ExpandLessIcon />}</>}
			</Grid>
			
		</Grid>

		
		{props.showActionButton && props.expand && (
				props.isAssetJobHeader ? (
					<Grid item>
						<Button
							id={props.actionButtonID}
							variant="contained"
							color="inherit"
							className='action-button'
							onClick={(e) => props.handleRaiseNewJobfromAsset && props.activeAsset &&
								props.handleRaiseNewJobfromAsset(props.activeAsset, props.history)}
						>
							<Icon className='icon'>{props.actionButtonIcon}</Icon>
							&nbsp;
							<p className='label-margin'>{props.actionButtonText}</p>
						</Button>
					</Grid>) : (
					<Grid item>
						<Button
							id={props.actionButtonID}
							variant="contained"
							color="inherit"
							className='action-button'
							onClick={(e) => controlFunction(props, 'button', e)}
						>
							<Icon className='icon'>{props.actionButtonIcon}</Icon>
							&nbsp;
							<p className='label-margin'>{props.actionButtonText}</p>
						</Button>
					</Grid>
				)
			)}

	</div>
);

export default CardHeader;
