import * as React from 'react';
import { connect } from 'react-redux';
import Dexie from 'dexie';
import { History } from 'history';
import { isNullOrUndefined } from 'util';
import {
	Button,
	Checkbox,
	Dialog,
	DialogActions,
	DialogContent,
	Fab,
	FormControlLabel,
	Grid,
	Icon,
	IconButton,
	Input,
	InputLabel,
	NativeSelect,
	TextField,
} from '@material-ui/core';
import firebase from 'firebase';
import { fire, localQueue, idb } from '../../index';
import JobTaskCard from '../../components/job-task/JobTaskCard';
import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import FloatingTaskStatusButton from '../../components/shared/FloatingTaskStatusButton';
import { LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { isTodaysDate } from '../../utils/Times';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { IsChrome } from '../../utils/IsChrome';
import Api from '../../apiConfiguration.json';
import '../../styles/job-task/job-task-list.css';
import AutoCompleteDropDown from '../../components/inputs/AutoCompleteDropdown';

import BackButton from '../../components/shared/BackButton';
import { useTranslation, withTranslation } from 'react-i18next';


interface NotificationsListScreenProps {
	history: History;
	UserSettings: Store.UserSettings;
	t: any;
}

interface NotificationsListScreenState {
	jobTasks: JobTask.JobTask[];
	lastLoadedJobTask: any;
	loading: boolean;
	loadingMore: boolean;
	endOfList: boolean;
	searchModalOpen: boolean;
	searchInput: string;
	openClosedTaskFilter: string;
	plannedReactiveTaskFilter: string;
	filtersActive: boolean;
	isDueToday: boolean;
	documentsPerPage: number;
	yAxisScroll: number;
	order: string;
	sitesList: any[];
	siteFilter?: {
		name: string;
		value: number;
	} | null;
}

class NotificationsListScreen extends React.Component<NotificationsListScreenProps, NotificationsListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			jobTasks: [],
			lastLoadedJobTask: null,
			loading: false,
			loadingMore: false,
			endOfList: false,
			searchModalOpen: false,
			searchInput: '',
			openClosedTaskFilter: 'Open',
			plannedReactiveTaskFilter: '',
			filtersActive: false,
			isDueToday: false,
			documentsPerPage: 20,
			yAxisScroll: 0,
			order: 'DESC',
			sitesList: [],
			siteFilter: null,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'NotificationsList',
		);


		localQueue.getDocumentsInLocalQueue();
	}

	backButton = () => {
		this.props.history.push("/job-task-list");
	};

	async componentDidMount() {
		if (navigator.onLine) {
			await fire.GetAppUpdateFlags();
			await this.checkIndexedDBVersion();
		}

		const localItem = (await LocalstorageGetItem('JobTaskFilterOptions')) || '{}';
		const JsonObj = JSON.parse(localItem);
		await this.setState(
			{
				searchInput: JsonObj.searchInput || '',
				plannedReactiveTaskFilter: JsonObj.plannedReactiveTaskFilter || '',
				openClosedTaskFilter: !isNullOrUndefined(JsonObj.openClosedTaskFilter) ? JsonObj.openClosedTaskFilter : 'Open',
				isDueToday: JsonObj.IsDueToday || false,
				siteFilter: JsonObj.siteFilter || null,
				yAxisScroll: JsonObj.YAxisScroll || 0,
				order: JsonObj.order || 'DESC',
			},
			() => {
				this.setState({ filtersActive: this.areFiltersActive() }, () => {

					this.fetchJobTasks();
					this.getSites();
				});
			},
		);

		
	}

	async checkIndexedDBVersion() {
		await new Dexie('TrackplanContractorDB').open().then(async db => {
			const DbVersion = parseInt(Api.INDEXEDDB_VERSION);
			if (db.verno !== DbVersion) {
				let URL = '';
				if (Api.ENVIRONMENT === 'development') {
					URL = 'https://resource-staging.trackplanfm.com/#/job-task-list';
				} else {
					URL = 'https://resource.trackplanfm.com/#/job-task-list';
				}

				await SendErrorData(
					this.props.UserSettings.Email,
					this.props.UserSettings.UserUID,
					this.props.UserSettings.ServerName,
					'IndexedDB Version was not the latest version upgrade was required',
					URL,
					'JobTaskListScreen',
				);
				self.indexedDB.deleteDatabase('TrackplanContractorDB');
				window.location.reload();
			}
		});
	}

	componentWillUnmount() {
		this.unsubscribe = null;
		window.removeEventListener('scroll', this.handleScroll);
	}

	handleScroll = () => {
		this.setState({ yAxisScroll: window.scrollY }, () => {
			this.handleLocalStorageFilters(window.scrollY);
		});
	};

	handleLocalStorageFilters(yAxisScroll?: number) {
		const SearchArray = {
			searchInput: this.state.searchInput,
			openClosedTaskFilter: this.state.openClosedTaskFilter,
			plannedReactiveTaskFilter: this.state.plannedReactiveTaskFilter,
			IsDueToday: this.state.isDueToday,
			siteFilter: this.state.siteFilter,
			YAxisScroll: yAxisScroll || this.state.yAxisScroll,
			order: this.state.order,
		};

		LocalstorageSetItem({ Key: 'JobTaskFilterOptions', Value: SearchArray });
	}

	handleSearchModal = () => {
		this.setState({
			searchModalOpen: !this.state.searchModalOpen,
		});
	};

	handleOpenClosedFilter = selection => {
		this.setState({ openClosedTaskFilter: selection.target.value });
	};

	handlePlannedReactiveFilter = selection => {
		this.setState({ plannedReactiveTaskFilter: selection.target.value });
	};

	handleSearchInput = input => {
		this.setState({ searchInput: input });
	};

	fetchJobTasks = () => {
		const jobTaskFilterOptions = {
			openClosed: this.state.openClosedTaskFilter,
			plannedReactive: this.state.plannedReactiveTaskFilter,
			isDueToday: this.state.isDueToday,
			site: !isNullOrUndefined(this.state.siteFilter) ? this.state.siteFilter.value : null,
			order: this.state.order.toLowerCase(),
		};


		this.setState({ yAxisScroll: window.scrollY }, () => {
			this.setState(
				{
					loading: true,
					filtersActive: this.areFiltersActive(),
				},
				() => {
					this.unsubscribe = fire
						.getNotificationsPaginated(this.state.documentsPerPage, this.state.lastLoadedJobTask, jobTaskFilterOptions)
						.onSnapshot(this.handleJobTaskSnapshot);
				},
			);
		});
	
	};

	handleJobTaskSnapshot = (query: firebase.firestore.QuerySnapshot) => {
		if (query.empty || query.docChanges().length < 1) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedJobTask)) this.setState({ jobTasks: [] });
			return;
		}

		let jobTaskList = isNullOrUndefined(this.state.lastLoadedJobTask) ? [] : this.state.jobTasks;

		const docChanges = query.docChanges();
		for (const job of docChanges) {
			const jobTask = job.doc.data() as JobTask.JobTask;

			if (isTodaysDate(jobTask.ExpectedDate)) {
				fire.getFormsForJobTask(job.doc.id).onSnapshot(formDocs => {
					if (formDocs.empty) return;
					formDocs.docs.forEach(form => {
						form.ref.collection('QuestionAnswers').get();
					});
				});
			}

			const jobTaskIndex = jobTaskList.findIndex(x => x.Id === job.doc.id);
			const jobTaskObj = {
				...jobTask,
				Id: job.doc.id,
			};

			if (jobTaskIndex === -1) {
				jobTaskList.push(jobTaskObj);
			} else {
				jobTaskList[jobTaskIndex] = jobTaskObj;
			}

			if (job.type === 'removed') {
				jobTaskList.splice(jobTaskIndex, 1);
			}
			
		}

		jobTaskList = this.sortingFunction(jobTaskList);

		this.setState(
			{
				jobTasks: jobTaskList,
				lastLoadedJobTask: query.docs[query.docs.length - 1],
				loading: false,
				loadingMore: false,
			},
			() => {
				if(!isNullOrUndefined(this.props.history.location.state)) {
					const  yAxisPos  = this.props.history.location.state.yAxisScroll;
					window.scrollTo(0, yAxisPos);
					this.props.history.location.state = undefined;
				} else {
					window.scrollTo(0, this.state.yAxisScroll);
				}
			},
		);
	};

	sortingFunction = (jobTaskList: JobTask.JobTask[]) => {
		if (this.state.order === 'DESC') {
			return jobTaskList.sort((a, b) => {
				if (a.ExpectedDate === b.ExpectedDate) return 0;
				else if (a.ExpectedDate === 0) return -1;
				else if (b.ExpectedDate === 0) return 1;
				else if (a.ExpectedDate < b.ExpectedDate) return 1;
				else if (b.ExpectedDate < a.ExpectedDate) return -1;
				else return 0;
			});
		} else {
			return jobTaskList.sort((a, b) => {
				if (a.ExpectedDate === b.ExpectedDate) return 0;
				else if (a.ExpectedDate === 0) return -1;
				else if (b.ExpectedDate === 0) return 1;
				else if (a.ExpectedDate > b.ExpectedDate) return 1;
				else if (b.ExpectedDate > a.ExpectedDate) return -1;
				else return 0;
			});
		}
	}

	getSites = () => {
		const sitesArray: any[] = [];
		fire
			.getCollection('Sites', 'SiteName')
			.then(sites => {
				sites.docs.forEach(site => {
					const data = site.data();
					sitesArray.push({ name: data.SiteName, value: data.SiteID });
				});
				sitesArray.sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));
			})
			.then(() => {
				this.setState({ sitesList: sitesArray });
			});
	};

	async handleClearSearch() {
		await this.setState(
			{
				loading: true,
				searchInput: '',
				openClosedTaskFilter: '',
				plannedReactiveTaskFilter: '',
				filtersActive: false,
				isDueToday: false,
				siteFilter: null,
				lastLoadedJobTask: null,
			},
			() => {
				this.handleLocalStorageFilters();
				this.fetchJobTasks();
				// this.filterJobTasks(this.state.jobTasks);
			},
		);
	}

	async handleJobTaskSearch() {
		this.handleLocalStorageFilters();

		await this.setState(
			{
				loading: true,
				searchModalOpen: false,
				filtersActive: this.areFiltersActive(),
				yAxisScroll: this.state.yAxisScroll,
				lastLoadedJobTask: null,
			},
			() => {
				this.fetchJobTasks();
				// this.filterJobTasks(this.state.jobTasks);
			},
		);
	}

	async handleOrderFilter() {
		const newOrder = this.state.order === 'DESC' ? 'ASC' : 'DESC';
		await this.setState({ order: newOrder, loading: true, lastLoadedJobTask: null }, async () => {
			// const jobList = await this.sortingFunction(this.state.jobTasks);
			// await this.filterJobTasks(jobList);
			this.fetchJobTasks();
		});
	}

	handleIsDueTodayCheckbox = isChecked => {
		this.setState({ isDueToday: isChecked });
	};

	handleSiteChange = (object: { name: string; value: string }) => {
		let siteIDN = parseInt(object.value);
		if (!isNaN(siteIDN)) this.setState({ siteFilter: { name: object.name, value: siteIDN } });
	};

	clearSiteFilter = () => {
		this.setState({ siteFilter: null });
	};

	areFiltersActive() {
		const freeTextSearch = this.state.searchInput.toLowerCase();
		const openClosedSearch = this.state.openClosedTaskFilter;
		const reactivePlannedSearch = this.state.plannedReactiveTaskFilter;
		const isDueTodaySearch = this.state.isDueToday;
		const siteSearch = this.state.siteFilter;
		let filtersActive = false;

		if (
			freeTextSearch !== '' ||
			openClosedSearch !== '' ||
			reactivePlannedSearch !== '' ||
			!isNullOrUndefined(siteSearch) ||
			isDueTodaySearch
		) {
			filtersActive = true;
		}
		return filtersActive;
	}

	clearNotifications(){
		fire.clearNotifications(this.state.jobTasks);
		this.setState({jobTasks: []});
	}
	

	render() {
		if (this.state.loading && !this.state.filtersActive) {
			return <LoadingSpinner text={this.props.t("Loading notifications...")} />;
		}
		if (this.state.loading && this.state.filtersActive) {
			return <LoadingSpinner text={this.props.t("Searching notifications...")} />;
		}
		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={this.props.t("Notifications")}
					rightMenuButton={
						<div>
							{/* {' '}
							<IconButton onClick={() => this.handleOrderFilter()} style={{ marginRight: 5 }}>
								<Icon style={{ color: 'white' }}>autorenew</Icon>
							</IconButton> */}
							<BackButton callbackMethod={this.backButton} />
						</div>
					}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main">
						
						
						{this.state.jobTasks.length > 0 ? (
							<div>
								<Button
										id="outer-card-button"
										variant="contained"
										color="secondary"
										size="large"
										style={{ marginTop: 10 }}
										fullWidth
										onClick={() => this.clearNotifications()}
									>
										{this.props.t("Clear All Notifications")}
									</Button>
								{this.state.jobTasks.map((jobTask, i) => (
									<JobTaskCard jobsLoaded={this.state.jobTasks.length} lastLoaded={this.state.lastLoadedJobTask} key={i} jobTask={jobTask} history={this.props.history} fromNotifications={true} />
								))}

								{!this.state.endOfList ? (
									<Button id="outer-card-button" variant="outlined" size="large" fullWidth disabled>
										{this.props.t("All Notifications Loaded")}
									</Button>
								) : (
									<Button
										id="outer-card-button"
										variant="outlined"
										color="primary"
										size="large"
										fullWidth
										onClick={() => this.fetchJobTasks()}
									>
										{this.props.t("Load More Notifications")}
									</Button>
								)}
							</div>
						) : (
							<NoJobTasks filtersActive={this.state.filtersActive} />
						)}


					</div>
				</Grid>
			</div>
		);
	}
}

const NoJobTasks = props => {
	const [t, i18n] = useTranslation();
	return (
		<div className="no-jobtasks-card">
			<h1 className="hot-pink">
			{props.filtersActive ? t("There are no more unopened jobs.") : t("There are no notifications.")}
			</h1>
		</div>
	);
};


const NewJobRequestButton = props => (
	<IconButton onClick={() => props.history.push('/new-request')}>
		<Icon style={{ color: 'white' }}>add_circle_outline</Icon>
	</IconButton>
);

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(NotificationsListScreen));