import * as React from 'react';
import { CircularProgress, Box, NativeSelectProps } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { fireStorageAreaConverter } from '../../utils/FirebaseConverters';
import { fire } from '../..';
import { useEffect, useState } from 'react';
import { NativeSelectField } from '../shared/NativeSelectField';

const StorageAreaSelectField = (props: NativeSelectProps): JSX.Element => {
	const [t, _i18n] = useTranslation();
	return (
		<Box marginBottom={2}>
			<NativeSelectField id="stock-area-select" inputLabel={t('Storage Areas List')} {...props} />
		</Box>
	);
};

const StockNameSelectField = (props: NativeSelectProps): JSX.Element => {
	const [t, _i18n] = useTranslation();
	return (
		<Box marginBottom={2}>
			<NativeSelectField id="stock-name-select" inputLabel={t('Stock')} {...props} />
		</Box>
	);
};

const SmallCircularProgress = (): JSX.Element => <CircularProgress size={26} />;

/**
 * Loading Skeleton component rendering empty NativeSelects with 'Loading...' placeholders
 */
const LoadingOptions = (): JSX.Element => {
	const [t, _i18n] = useTranslation();
	return (
		<>
			<StorageAreaSelectField fullWidth IconComponent={SmallCircularProgress} disabled>
				<option value="">{t('Loading Storage Areas...')}</option>
			</StorageAreaSelectField>
			<StockNameSelectField fullWidth IconComponent={SmallCircularProgress} disabled>
				<option value="">{t('Loading Storage Area Details...')}</option>
			</StockNameSelectField>
		</>
	);
};

/**
 * HTML Option 'None' prepended to the storage area & stock name options
 */
const NoneOption = () => {
	const [t, _i18n] = useTranslation();
	return <option value="">{t('None')}</option>;
};

interface OptionType {
	/**
	 * The label of the option, ie. the storage area name
	 */
	text: string;
	/**
	 * The value of the option, ie. the storage area FBID
	 */
	value: string;
}

export interface StorageAreaStockSelected {
	storageArea: OptionType;
	stockName: OptionType;
}

/**
 * Use this hook to fetch the list of storage areas and their stocks
 */
function useFetchStorageAreas() {
	const [isLoading, setIsLoading] = useState(true);
	const [storageAreas, setStorageAreas] = useState<StorageArea[]>([]);
	useEffect(() => {
		const getStorageAreas = async () => {
			const storageAreas = await fire.getCollectionWithConverter(
				'StorageAreas',
				fireStorageAreaConverter,
				'StorageName',
			);
			setStorageAreas(storageAreas.docs.map(storageArea => storageArea.data()));
		};
		getStorageAreas().finally(() => setIsLoading(false));
	}, []); // run effect only on mount
	return { isLoading, storageAreas };
}

/**
 * Encapsuled stock selector container hooks
 */
function useStockSelectorContainer(storageAreas: StorageArea[], onChange: (value: StorageAreaStockSelected) => void) {
	// contains the selected storage area and stock name
	const [selectedStock, setSelectedStorage] = useState<StorageAreaStockSelected>(newEmptyStorageAreaStockSelected());

	// we prepend an empty option 'None' to the Storage area options
	const storageAreaOptions = [<NoneOption key={'none-storage-area'} />].concat(
		storageAreas.map(storageArea => (
			<option key={storageArea.ID} value={storageArea.FBID}>
				{storageArea.StorageName}
			</option>
		)),
	);
	const selectedStorageArea = storageAreas.find(area => area.ID === selectedStock.storageArea.value);
	const stockNameOptions = [<NoneOption key={'none-stock-item'} />].concat(
		selectedStorageArea
			? selectedStorageArea.StockToStorageArea.map(stock => (
					<option key={stock.StockFBID} value={stock.StockFBID}>
						{stock.StockName}
					</option>
			  ))
			: [],
	);
	// fire onChange prop when selected storage area and stock name change
	useEffect(() => {
		onChange(selectedStock);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedStock]);

	const onChangeStorageArea = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedStorage({
			storageArea: event.target.options[event.target.selectedIndex],
			stockName: { text: '', value: '' }, // don't forget to reset StockNameSelect !
		});
	};
	const onChangeStockName = (event: React.ChangeEvent<HTMLSelectElement>) => {
		setSelectedStorage(state => ({
			...state,
			stockName: event.target.options[event.target.selectedIndex], // the selected HTMLOptionElement containing the selected stock name and fbid
		}));
	};
	return [
		{ stockNameOptions, storageAreaOptions },
		{ onChangeStorageArea, onChangeStockName },
	] as const;
}

/**
 * Component containing Storage Area select and Stock Name select.
 * It also fetches from firebase the list of storage areas and their stocks.
 */
export function StockSelectorContainer({
	error,
	value,
	onChange,
}: {
	error?: boolean;
	onChange: (value: StorageAreaStockSelected) => void;
	value: StorageAreaStockSelected;
}): JSX.Element {
	const { isLoading, storageAreas } = useFetchStorageAreas();
	const [
		{ stockNameOptions, storageAreaOptions },
		{ onChangeStorageArea, onChangeStockName },
	] = useStockSelectorContainer(storageAreas, onChange);

	if (isLoading) {
		return <LoadingOptions />;
	}

	return (
		<>
			<StorageAreaSelectField error={error} value={value.storageArea.value} fullWidth onChange={onChangeStorageArea}>
				{storageAreaOptions}
			</StorageAreaSelectField>
			<StockNameSelectField error={error} value={value.stockName.value} fullWidth onChange={onChangeStockName}>
				{stockNameOptions}
			</StockNameSelectField>
		</>
	);
}

/**
 * Initialize an empty stock selected object. Each field is set to an empty string
 */
export function newEmptyStorageAreaStockSelected(): StorageAreaStockSelected {
	return {
		storageArea: { text: '', value: '' },
		stockName: { text: '', value: '' },
	};
}
