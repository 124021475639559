import { History } from 'history';
import React from 'react';
import { useTranslation } from 'react-i18next';
import BackButton from '../../components/shared/BackButton';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import QRCodeCard from '../../components/storage-areas/QRCodeCard';
import StocksTable from '../../components/storage-areas/StocksTable';
import StorageAreaCard from '../../components/storage-areas/StorageAreaCard';
import useStorageAreaDetails from '../../hooks/StorageAreas/useStorageAreaDetails';
import '../../styles/storage-areas/storage-area-details.css';

interface StorageAreaDetailsProps {
	storageArea: StorageArea;
	refetchStorageArea: () => void;
}

const StorageAreaDetails = ({ storageArea, refetchStorageArea }: StorageAreaDetailsProps) => {
	const [t, i18n] = useTranslation();
	return (
	<div className="storage-area-details-container">
		<div className="storage-area-details">
			<StorageAreaCard storageArea={storageArea} />
			<div className="storage-area-card">
				<QRCodeCard storageArea={storageArea} />
			</div>
			<div className="storage-area-card">
				{storageArea.StockToStorageArea.length === 0 ? (
					<div style={{ display: 'flex', justifyContent: 'center' }}>
						<h1 className="no-stocks-text">{t("No stocks to display")}</h1>
					</div>
				) : (
					<StocksTable storageArea={storageArea} refetchStorageArea={refetchStorageArea} />
				)}
			</div>
		</div>
	</div>
)};

interface StorageAreaDetailsViewProps {
	history: History;
}

const StorageAreaDetailsView = (props: StorageAreaDetailsViewProps) => {
	const { isLoading, storageArea, getStorageArea } = useStorageAreaDetails();
	const [t, i18n] = useTranslation();

	if (isLoading) {
		return (
			<>
				<SideDrawer
					history={props.history}
					title={t("Storage Area Details")}
					rightMenuButton={<BackButton callbackMethod={() => window.history.back()} />}
				/>
				<LoadingSpinner height="90vh" text={t("Loading Storage Area Details...")} />
			</>
		);
	}

	return (
		<>
			<SideDrawer
				history={props.history}
				title={(storageArea as StorageArea).StorageName}
				rightMenuButton={<BackButton callbackMethod={() => window.history.back()} />}
			/>
			<StorageAreaDetails storageArea={storageArea as StorageArea} refetchStorageArea={getStorageArea} />
		</>
	);
};

export default StorageAreaDetailsView;
