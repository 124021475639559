import * as React from 'react';
import firebase from 'firebase';
import Grid from '@material-ui/core/Grid/Grid';
import SideDrawer from '../../components/shared/SideDrawer';
import { History } from 'history';
import BackButton from '../../components/shared/BackButton';
import { fire } from '../../index';
import { unixToDateString, stringtoUnixUTC, unixToTimeString, parseDateForTimeRecords } from '../../utils/Times';
import styled from 'styled-components';
import {
	Dialog,
	DialogContent,
	DialogActions,
	Button,
	DialogTitle,
	TextField,
	Table,
	TableHead,
	TableRow,
	TableCell,
	TableBody,
	Icon,
} from '@material-ui/core';

import '../../styles/job-task/timesheet.css';
import cardColour from '../../utils/Colors';
import { match } from 'react-router-dom';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { isNullOrUndefined } from 'util';
import { windowError } from '../../utils/WindowError';
import { connect } from 'react-redux';
import { IsChrome } from '../../utils/IsChrome';
import { useTranslation, withTranslation } from 'react-i18next';
interface TimesheetsProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface TimesheetsState {
	timesheet: Timesheet | null;
	modalVisible: boolean;
	editedTimeRecordObject: any;
	timesheetFBID: string | null;
	timesheetSubmitted: boolean;
}

interface Timesheet {
	AssignedResource: string;
	DateCreated: number;
	EndPeriod: number;
	StartPeriod: number;
	Status: string;
	TimeRecords: TimeRecord[];
	TimesheetRef: firebase.firestore.DocumentReference;
}

interface TimeRecord {
	TimeRecordFBID: string;
	ArriveOnSite: number;
	Costs: number;
	Details: string;
	HoursOnSite: number;
	HoursTravelling: number;
	JobNumber: number;
	JobTaskNumber: string;
	LeftSite: number;
	TravelHome?: number | null;
	Site: string;
	TravelStart: number;
	UserEdited: boolean;
}

interface ModalProps {
	timeRecord: EditedTimeRecord | null;
	modalVisible: boolean;
	handleModalVisibility: () => void;
	handleSubmitChanges: () => void;
	handleEditField: (field: string, value: string) => void;
}

interface EditedTimeRecord {
	TravelStart: string;
	ArriveOnSite: string;
	LeftSite: string;
	TravelHome: string;
	TimeSheetFBID: string;
	TimeRecordFBID: string;
	EditTimeRecordNote: string;
}

class TimesheetDetailsScreen extends React.Component<TimesheetsProps, TimesheetsState> {
	constructor(props) {
		super(props);
		this.state = {
			timesheet: null,
			modalVisible: false,
			editedTimeRecordObject: null,
			timesheetFBID: null,
			timesheetSubmitted: false,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'TimesheetDetailsScreen',
		);
	}

	componentDidMount() {
		const { id } = this.props.match.params as any;
		this.setState({ timesheetFBID: id });
		this.getTimesheetDetails(id);
	}

	backButton = () => {
		this.props.history.push('/timesheets');
	};

	getTimesheetDetails = (timesheetFBID: string) => {
		fire.baseQuery
			.collection('Timesheets')
			.doc(timesheetFBID)
			.onSnapshot(query => {
				if (!query.exists) {
					return;
				}
				const timesheetData = query.data() as Timesheet;
				query.ref.collection('TimeRecords').onSnapshot(data => {
					if (data.empty) {
						timesheetData.TimeRecords = [];
						this.setState({
							timesheet: timesheetData,
							timesheetSubmitted: true,
						});
						return;
					}
					let timeRecords = data.docs.map(d => {
						const timeRecord = d.data() as TimeRecord;
						timeRecord.TimeRecordFBID = d.id;
						return timeRecord;
					}) as TimeRecord[];

					//sorts the time records by the most recent left site
					timeRecords = timeRecords.sort((a, b) => b.LeftSite - a.LeftSite);

					timesheetData.TimeRecords = timeRecords;
					this.setState({
						timesheet: timesheetData,
						timesheetSubmitted: timesheetData.Status !== 'Pending',
					});
				});
			});
	};

	handleEditButtonClick = (timeRecord: TimeRecord) => {
		if (this.state.timesheetFBID === null) {
			return;
		}
		const editObj: EditedTimeRecord = {
			TravelStart: parseDateForTimeRecords(timeRecord.TravelStart),
			ArriveOnSite: parseDateForTimeRecords(timeRecord.ArriveOnSite),
			LeftSite: parseDateForTimeRecords(timeRecord.LeftSite),
			TravelHome: parseDateForTimeRecords(timeRecord.TravelHome || null),
			TimeRecordFBID: timeRecord.TimeRecordFBID,
			TimeSheetFBID: this.state.timesheetFBID,
			EditTimeRecordNote: '',
		};
		this.setState({
			editedTimeRecordObject: editObj,
			modalVisible: true,
		});
	};

	handleModalVisibility = () => {
		this.setState({
			modalVisible: !this.state.modalVisible,
		});
	};

	handleSubmitChanges = () => {
		if (isNullOrUndefined(this.state.timesheetFBID)) {
			return;
		}

		const editedObj = {
			TravelStart: stringtoUnixUTC(this.state.editedTimeRecordObject.TravelStart),
			ArriveOnSite: stringtoUnixUTC(this.state.editedTimeRecordObject.ArriveOnSite),
			LeftSite: stringtoUnixUTC(this.state.editedTimeRecordObject.LeftSite),
			TravelHome: stringtoUnixUTC(this.state.editedTimeRecordObject.TravelHome),
			TimeSheetFBID: this.state.editedTimeRecordObject.TimeSheetFBID,
			EditTimeRecordNote: this.state.editedTimeRecordObject.EditTimeRecordNote,
			JobAction: 'EditTimeRecord',
		};

		const docReference = fire.baseQuery
			.collection('Timesheets')
			.doc(editedObj.TimeSheetFBID)
			.collection('TimeRecords')
			.doc(this.state.editedTimeRecordObject.TimeRecordFBID);

		const updateDoc = {
			TravelStart: editedObj.TravelStart,
			ArriveOnSite: editedObj.ArriveOnSite,
			LeftSite: editedObj.LeftSite,
			TravelHome: editedObj.TravelHome,
			UserEdited: true,
		};
		this.setState({ modalVisible: false });

		fire.updateDocument(docReference, updateDoc);
		fire.postToJobQueue(this.state.editedTimeRecordObject.TimeRecordFBID, editedObj);
		this.getTimesheetDetails(editedObj.TimeSheetFBID);
	};

	handleEditField = (field: string, value: string) => {
		const newObj: EditedTimeRecord = {
			...this.state.editedTimeRecordObject,
		};
		newObj[field] = value;
		this.setState({
			editedTimeRecordObject: newObj,
		});
	};

	handleTimesheetSubmit = () => {
		const confirmation = confirm(this.props.t('Are you sure you want to submit this timesheet? This action cannot be reversed.'));
		if (confirmation) {
			if (isNullOrUndefined(this.state.timesheetFBID)) {
				return;
			}
			const docReference = fire.baseQuery.collection('Timesheets').doc(this.state.timesheetFBID);

			// Update status of timesheet
			docReference.update({ Status: 'Submitted' });

			// Post to jobtask queue
			fire.postToJobQueue(this.state.timesheetFBID, {
				JobAction: 'SubmitTimesheet',
			});
		}
	};

	render() {
		if (this.state.timesheet === null) {
			return <LoadingSpinner text={this.props.t("Loading timesheet details...")} />;
		} else {
			return (
				<MainContainer>
					<SideDrawer
						history={this.props.history}
						title={this.props.t("Timesheet Details")}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main-timesheet">
							<div style={{ height: '25px' }} />
							<TimesheetHeader timesheet={this.state.timesheet} />
							<TimeRecords
								timeRecords={this.state.timesheet.TimeRecords}
								handleEdit={this.handleEditButtonClick}
								submitted={this.state.timesheetSubmitted}
							/>
							<EditTimeRecordModal
								timeRecord={this.state.editedTimeRecordObject}
								modalVisible={this.state.modalVisible}
								handleSubmitChanges={this.handleSubmitChanges}
								handleEditField={this.handleEditField}
								handleModalVisibility={this.handleModalVisibility}
							/>
							<div style={{ height: '25px' }} />
							{!this.state.timesheetSubmitted ? (
								<Button
									variant="contained"
									onClick={this.handleTimesheetSubmit}
									style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
								>
									<Icon>save</Icon>&nbsp; {this.props.t("Submit Timesheet")}
								</Button>
							) : null}
						</div>
					</Grid>
				</MainContainer>
			);
		}
	}
}

const TimesheetHeader = props => {
	const [t, i18n] = useTranslation();
	return (
	<TimesheetCard style={{ borderBottom: `4px solid ${cardColour(props.timesheet.Status)}` }}>
		<TopRow>
			<HeaderContainer>
				<Content>{props.timesheet.Status}</Content>
				<Label>{t("Status")}</Label>
			</HeaderContainer>
			<HeaderContainer>
				<Content>{unixToDateString(props.timesheet.StartPeriod, true)}</Content>
				<Label>{t("Start Period")}</Label>
			</HeaderContainer>
			<HeaderContainer>
				<Content>{unixToDateString(props.timesheet.EndPeriod, true)}</Content>
				<Label>{t("End Period")}</Label>
			</HeaderContainer>
		</TopRow>
	</TimesheetCard>
)};

const TimeRecords = props => {
	const [t, i18n] = useTranslation();
	if (props.timeRecords.length < 1) {
		return (
			<div className="no-jobtasks-card">
				<h1 className="hot-pink">{t("There are no time records.")}</h1>
			</div>
		);
	}
	return (
		<div className="time-sheet-details" style={{ overflowX: 'auto' }}>
			<Table>
				<TableHead>
					<TableRow>
						{!props.submitted ? (
							<TableCell classes={{ root: 'timesheet-cell header-text' }} padding="none">
								{t("Edit")}
							</TableCell>
						) : null}
						<TableCell classes={{ root: 'timesheet-cell header-text' }} padding="none">
							{t("Task Number")}
						</TableCell>
						<TableCell classes={{ root: 'timesheet-cell header-text' }} padding="none">
							{t("Travel Start")}
						</TableCell>
						<TableCell classes={{ root: 'timesheet-cell header-text' }} padding="none">
							{t("Start Task")}
						</TableCell>
						<TableCell classes={{ root: 'timesheet-cell header-text' }} padding="none">
							{t("Left Task")}
						</TableCell>
						<TableCell classes={{ root: 'timesheet-cell header-text' }} padding="none">
							{t("Arrive Home")}
						</TableCell>
					</TableRow>
				</TableHead>
				<TableBody>
					{props.timeRecords.map((timerecord: TimeRecord, i: number) => (
						<TableRow key={i} className={`${timerecord.UserEdited ? 'edited-row' : ''}`}>
							{!props.submitted ? (
								<TableCell classes={{ root: 'timesheet-cell' }} padding="none">
									<Icon color="secondary" onClick={() => props.handleEdit(timerecord)}>
										edit
									</Icon>
								</TableCell>
							) : null}
							<TableCell classes={{ root: 'timesheet-cell' }} padding="none">
								<p className="time-string">{timerecord.JobTaskNumber}</p>
								<small className="date-string">{timerecord.Site}</small>
							</TableCell>
							<TableCell classes={{ root: 'timesheet-cell' }} padding="none">
								<p className="time-string">{unixToTimeString(timerecord.TravelStart)}</p>
								<small className="date-string">{unixToDateString(timerecord.TravelStart, true)}</small>
							</TableCell>
							<TableCell classes={{ root: 'timesheet-cell' }} padding="none">
								<p className="time-string">{unixToTimeString(timerecord.ArriveOnSite)}</p>
								<small className="date-string">{unixToDateString(timerecord.ArriveOnSite, true)}</small>
							</TableCell>
							<TableCell classes={{ root: 'timesheet-cell' }} padding="none">
								<p className="time-string">{unixToTimeString(timerecord.LeftSite)}</p>
								<small className="date-string">{unixToDateString(timerecord.LeftSite, true)}</small>
							</TableCell>
							<TableCell classes={{ root: 'timesheet-cell' }} padding="none">
								<p className="time-string">
									{isNullOrUndefined(timerecord.TravelHome) ? null : unixToTimeString(timerecord.TravelHome)}
								</p>
								<small className="date-string">
									{isNullOrUndefined(timerecord.TravelHome) ? null : unixToDateString(timerecord.TravelHome, true)}
								</small>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
		</div>
	);
};

const EditTimeRecordModal = (props: ModalProps) => {
	const [t, i18n] = useTranslation();
	if (props.timeRecord === null) {
		return null;
	} else {
		return (
			<Dialog
				open={props.modalVisible}
				onClose={props.handleModalVisibility}
				aria-labelledby={t("Edit Time Records")}
				fullScreen={true}
				maxWidth="lg"
			>
				<DialogTitle>{t("Edit Time Records")}</DialogTitle>
				<DialogContent>
					<TextField
						autoComplete={IsChrome()}
						id="travel-start-edit"
						label={t("Travel Start")}
						type="datetime-local"
						defaultValue={props.timeRecord.TravelStart}
						onChange={val => props.handleEditField('TravelStart', val.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<div style={{ marginBottom: '25px' }} />
					<TextField
						autoComplete={IsChrome()}
						id="arrive-on-site-edit"
						label={t("Arrive On Task")}
						type="datetime-local"
						defaultValue={props.timeRecord.ArriveOnSite}
						onChange={val => props.handleEditField('ArriveOnSite', val.target.value)}
					/>
					<div style={{ marginBottom: '25px' }} />
					<TextField
						autoComplete={IsChrome()}
						id="left-task-edit"
						label={t("Left Task")}
						type="datetime-local"
						defaultValue={props.timeRecord.LeftSite}
						onChange={val => props.handleEditField('LeftSite', val.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<div style={{ marginBottom: '25px' }} />
					<TextField
						autoComplete={IsChrome()}
						id="left-task-edit"
						label={t("Arrive Home")}
						type="datetime-local"
						defaultValue={props.timeRecord.TravelHome}
						onChange={val => props.handleEditField('TravelHome', val.target.value)}
						InputLabelProps={{
							shrink: true,
						}}
					/>
					<div style={{ marginBottom: '25px' }} />
					<TextField
						autoComplete="off"
						id="filled-name"
						label={t("Reason for Edit")}
						type="text"
						name="ReasonForTimeSheetEdit"
						value={props.timeRecord.EditTimeRecordNote}
						onChange={val => props.handleEditField('EditTimeRecordNote', val.target.value)}
						margin="normal"
						style={{ width: '208px' }}
						multiline={true}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={props.handleModalVisibility}
						color="primary"
						variant="contained"
						style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
					>
						<Icon>undo</Icon>&nbsp; {t("Back")}
					</Button>
					<Button
						onClick={props.handleSubmitChanges}
						variant="contained"
						color="primary"
						style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
					>
						<Icon>save</Icon>&nbsp; {t("Submit")}
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
};

const TimesheetCard = styled.div`
	display: flex;
	flex-direction: column;
`;

const TopRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr;
	grid-column-gap: 7.5px;
	padding: 7.5px;
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
`;

const Label = styled.p`
	font-size: 12px;
	color: var(--dark-blue);
`;

const Content = styled.p`
	font-size: 14px;
	margin-bottom: 4px;
`;

const MainContainer = styled.div`
	margin: 5px 0 15px 0;
`;

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(
	mapStateToProps,
	null,
)(TimesheetDetailsScreen));
