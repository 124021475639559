import { useEffect, useRef, useState } from 'react';

/**
 * A hook to manipulate async paginate components.
 *
 * @export
 * @template OptionType
 * @template Additional
 * @param propsIsDisabledToWatchToClose async paginate react select component props, used to compare prev props
 */
export function useReactSelectAsyncPaginate<OptionType, Additional>(propsIsDisabledToWatchToClose?: boolean) {
	const prevProps = useRef<boolean>();
	const [isMenuOpen, setMenuOpen] = useState(false);
	const [showKeyboard, setShowKeyboard] = useState(false);
	const selectRef = useRef<HTMLInputElement>();

	// we close the menu if props have changed. prevents the menu from staying open when the component is set to "isDisabled".
	if (prevProps.current !== propsIsDisabledToWatchToClose) {
		prevProps.current = propsIsDisabledToWatchToClose;
		setMenuOpen(false);
	}

	// NOTE: I think what was intended here is to hide the keyboard when the screen is too small.
	// However, this will only works if the component render function is called
	// So the keyboard will not be hidden if the component is not re-rendered.
	// Maybe a fix for later.
	const width = window.innerWidth;
	useEffect(() => {
		updatePredicate();
	}, [width]);

	const updatePredicate = () => {
		setShowKeyboard(window.innerWidth < 600);
	};

	const openMenu = () => {
		setMenuOpen(true);
		// the last operand of the "&&" seems odd, but don't worry, it works.
		// if you want to understand why, 'select' is a react select internal, it's a ref to the input element.
		if (selectRef && selectRef.current && selectRef.current['select']) {
			selectRef.current.focus();
		}
	};

	const closeMenu = () => setMenuOpen(false);

	return [
		{
			isMenuOpen,
			showKeyboard,
			selectRef,
		},
		{
			openMenu,
			closeMenu,
		},
	] as const;
}
