import * as React from 'react';
import Img from 'react-image';
import { isNullOrUndefined } from 'util';
import { Button, CircularProgress, Dialog, DialogActions, DialogContent, Grid, Icon } from '@material-ui/core';
import OuterCard from './OuterCard';
import ImageLoadingSpinner from './ImageLoadingSpinner';
import CardHeader from './CardHeader';
import DocumentDetails from './DocumentDetails';
import EditPhotoScreen from '../../views/Photos/EditPhotoScreen';
import { withTranslation } from 'react-i18next';

//style
import '../../styles/photos/photos-grid-alt.css'

//card icon
import PHOTO from '../../assets/images/icons/PHOTO.svg'

interface Photos {
	FirebaseStoragePath: string;
	Guid?: string;
	Filename?: string;
	UploadedBy?: string;
	Source?: string;
	DateCreated?: number;
}

interface PhotoGridState {
	photoGridXS: boolean | 1 | 2 | 10 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 'auto' | 11 | 12 | undefined;
	photoGridSpacing: 0 | 1 | 2 | 10 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | undefined;
	modalOpen: boolean;
	currentPhoto: string;
	Guid?: string;
	Filename?: string;
	UploadedBy?: string;
	Source?: string;
	DateCreated?: number;
	editMode?: boolean;
}

interface PhotoGridProps {
	history?: History;
	jobTaskId?: string;
	isComplete?: boolean;
	color?: string | null;
	photos: Photos[];
	titleText: string;
	noPhotosText: string;
	isForRequest: boolean;
	canRemovePhoto: boolean;
	canDownloadPhoto: boolean;
	canEditPhoto?: boolean;
	handleDownloadPhoto?: (URL: string, FileName: string) => void | null;
	handleRemovePhoto?: (Guid: string, Reference: any) => void | null;
	handleDownloadAllPhotos?: () => void;
	documentReference?: any;
	numberOfPhotos?: number;
	displayDownloadAll?: boolean;
	t: any;
	expand: boolean;
	handleExpand: (value: boolean) => void;
	fromPhotoAnswer?: boolean;
	openImageDialog: (() => void) | undefined;
	showActionButton: boolean;
	fromTaskPhotos?: boolean;
	photosLoading?: boolean;
}

class PhotosGrid extends React.Component<PhotoGridProps, PhotoGridState> {
	constructor(props) {
		super(props);
		this.state = {
			photoGridSpacing: 1,
			photoGridXS: 'auto',
			modalOpen: false,
			currentPhoto: '',
		};
	}

	toggleModal = () => {
		this.setState({
			modalOpen: !this.state.modalOpen,
		});
	};

	editPhoto = (edit: boolean) => {
		this.setState({ editMode: edit });
	};

	closeAllDialogs = () => {
		this.setState({
			modalOpen: false,
			editMode: false,
		});
	};

	render() {
		if (this.props.photos.length < 1 && this.props.isForRequest) {
			return null;
		}

		if (this.props.fromTaskPhotos && this.props.photosLoading) {
			return (
				<OuterCard styles="outer-card">
					<CircularProgress size={18} color={'secondary'} style={{ marginRight: 10 }} />
					<p className="hot-pink"> {this.props.t("Loading")}</p>
				</OuterCard>
			)
		} else if (
			!isNullOrUndefined(this.props.numberOfPhotos) &&
			this.props.numberOfPhotos > 0 &&
			this.props.photos.length === 0
		) {
			return (
				<OuterCard styles="outer-card">
					<CircularProgress size={18} color={'secondary'} className='margin-top' />
					<p className="hot-pink"> {this.props.t("Loading") + " " + this.props.numberOfPhotos + " " + this.props.t("Photos.")}</p>
				</OuterCard>
			);
		} else if (this.props.canEditPhoto && this.state.editMode) {
			return (
				<Dialog open={this.state.editMode} onClose={() => this.editPhoto(false)} fullWidth={true}>
					<DialogContent className='text-align-center'>
						<EditPhotoScreen
							photoUri={this.state.currentPhoto}
							clearPhoto={() => {
								this.editPhoto(false);
							}}
							jobTaskId={this.props.jobTaskId}
							newJob={false}
							history={this.props.history}
							guid={''}
							isComplete={this.props.isComplete}
							shouldRedirect={false}
							noRedirectCallback={this.closeAllDialogs}
						/>
					</DialogContent>
				</Dialog>
			);
		} else {
			return (
				<>{(!!this.props.noPhotosText) ? <div>
					<OuterCard padding="0px" styles='border-radius'>
						<CardHeader
							title={this.props.titleText}
							titleIcon={PHOTO}
							textColor="white"
							backgroundColor="var(--dark-blue)"
							expand={this.props.expand}
							handleExpand={this.props.handleExpand}
							showExpand={true}
							showActionButton={this.props.showActionButton}
							actionButtonText={this.props.t("Add Photo")}
							actionButtonIcon="add_a_photo"
							actionButtonClickHandler={this.props.openImageDialog}
						/>

						{this.props.photos.length < 1 ? (<>
							{this.props.expand && <div className='no-elements-card-text'>
								<p>{this.props.t(this.props.noPhotosText)}</p>
							</div>}
						</>) : <>{this.props.expand ? <div className="photos-container">
							<Grid container item spacing={this.state.photoGridSpacing}>
								{this.props.photos.map((photo, i) => (
									<Grid item xs={this.state.photoGridXS} key={i}>
										<Img
											className="grid-photo"
											src={photo.FirebaseStoragePath}
											loader={<ImageLoadingSpinner />}
											key={i}
											onClick={() => {
												this.setState({
													currentPhoto: photo.FirebaseStoragePath,
													Guid: photo.Guid,
													Filename: photo.Filename,
													UploadedBy: photo.UploadedBy,
													Source: photo.Source,
													DateCreated: photo.DateCreated,
												});
												this.toggleModal();
											}}
										/>
									</Grid>
								))}
								<div className='download-icon-holder'>{this.props.displayDownloadAll === true && (
									<Grid item alignItems="flex-end">
										<Icon className='cursor-pointer' onClick={this.props.handleDownloadAllPhotos}>get_app</Icon>
									</Grid>
								)
								}</div>
							</Grid>
						</div> : <></>}</>}

						<Dialog open={this.state.modalOpen} onClose={() => this.toggleModal()} fullWidth={false} maxWidth={false}>
							<DialogContent className='text-align-center'>
								<Icon className='close-modal-button' onClick={this.toggleModal}>
									close
								</Icon>
								<br />
								<img className='image-holder' src={this.state.currentPhoto} />

								<DocumentDetails
									Filename={this.state.Filename}
									UploadedBy={this.state.UploadedBy}
									Source={this.state.Source}
									DateCreated={this.state.DateCreated}
								/>
							</DialogContent>

							{(this.props.canRemovePhoto || this.props.canDownloadPhoto || this.props.canEditPhoto) && (
								<DialogActions className='modal-border'>
									<div className='margin-top'>
										{this.props.canRemovePhoto && (
											<Button
												className='background-color-red color-white'
												variant="outlined"
												color="inherit"
												size="small"
												onClick={async () => {
													await this.toggleModal();
													if (this.props.fromPhotoAnswer) {
														if (this.state.Guid &&
															this.props.handleRemovePhoto
														) {
															this.props.handleRemovePhoto(this.state.Guid,
																this.props.documentReference);
														}
													} else {
														if (
															!isNullOrUndefined(this.state.Guid) &&
															!isNullOrUndefined(this.props.jobTaskId) &&
															!isNullOrUndefined(this.props.handleRemovePhoto)
														) {
															this.props.handleRemovePhoto(this.state.Guid, this.props.jobTaskId);
														}
													}
												}}
											>
												<Icon className='color-white' fontSize="small">
													clear
												</Icon>
												{this.props.t("Remove")}
											</Button>
										)}

										{this.props.canDownloadPhoto && (
											<Button
												className='background-color-grey color-white'
												variant="outlined"
												color="inherit"
												size="small"
												onClick={() => {
													if (
														!isNullOrUndefined(this.state.Filename) &&
														!isNullOrUndefined(this.props.handleDownloadPhoto)
													) {
														this.props.handleDownloadPhoto(this.state.currentPhoto, this.state.Filename);
													}
												}}
											>
												<Icon className='color-white' fontSize="small">
													cloud_download
												</Icon>
												&nbsp; {this.props.t("Download")}
											</Button>
										)}

										{this.props.canEditPhoto && (
											<Button
												className='background-color-grey color-white'
												variant="outlined"
												color="inherit"
												size="small"
												onClick={() => {
													this.editPhoto(true);
												}}
											>
												<Icon className='color-white' fontSize="small">
													edit
												</Icon>
												&nbsp; {this.props.t("Edit")}
											</Button>
										)}
									</div>
								</DialogActions>
							)}
						</Dialog>
					</OuterCard>
				</div> : <></>}</>
			);
		}
	}
}

export default withTranslation()(PhotosGrid);
