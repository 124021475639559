import firebase from 'firebase/app';
import 'firebase/app';
import 'firebase/auth';
import 'firebase/storage';
import 'firebase/firestore';
// import 'firebase/firebase-messaging';

// eslint-disable-next-line prettier/prettier
import {
	fireAssetConverter,
	fireLocationConverter,
	fireSubLocationConverter,
	fireTagConverter,
} from './FirebaseConverters';

import config from '../configuration.json';

import { updateUser, setUserSettings, loginUserError } from '../actions/UserActions';
import store from '../store';
import { isNullOrUndefined } from 'util';
import { generateFirebaseId, generateGuid } from './Guids';
import { DelayPostToJobQueue } from './DelayPostToQueue';
import { delay } from 'q';
import axios, { AxiosError } from 'axios';
import Api from '../apiConfiguration.json';
import { getBaseURL } from './getBaseURL';

import { SendErrorData } from './WindowError';
import ApiKeyObj from './ApiObjectKey';
import { JOB_DOCUMENT_SOURCE_FROM_APP } from './database/indexdb/IndexDb';
import { unixToDateString } from './Times';
import { idb } from '../index';
import { getLocation } from './Geolocation';

export default class Firebase {
	db: firebase.firestore.Firestore;
	auth: firebase.auth.Auth;
	currentUser: firebase.User;
	resourceFBID: string | undefined;
	ClientID: string | undefined;
	ref: firebase.storage.Reference;
	baseQuery: firebase.firestore.DocumentReference;
	settings: Store.UserSettings | undefined;
	// messaging?: firebase.messaging.Messaging;
	// messagingToken: string | null = null;
	// isProduction: boolean = process.env.NODE_ENV !== 'development';

	private resourceToSettingsDocId: string | undefined;

	constructor() {
		if (!firebase.apps.length) {
			firebase.initializeApp(config);
		}
		this.db = firebase.firestore();
		this.db.enablePersistence({ synchronizeTabs: true }).catch(err => {
			if (err.code === 'failed-precondition') {
				console.error('Enable Persistence: failed-precondition. Error: ' + err);
			} else if (err.code === 'unimplemented') {
				console.error('Enable Persistence: unimplemented');
			}
		});
		this.auth = firebase.auth();
		this.auth.setPersistence(firebase.auth.Auth.Persistence.LOCAL);
		this.currentUser = this.auth.currentUser as firebase.User;
		this.ref = firebase.storage().ref();
		this.updateCurrentUser();
		this.baseQuery = this.db.collection('Clients').doc('a');
		// if (this.isProduction) {
		// 	this.messaging = firebase.messaging();
		// }
	}

	// Get queries
	getCollectionQuery(Collection: string, OrderBy: string) {
		return this.baseQuery.collection(Collection).orderBy(OrderBy, 'desc');
	}

	getJobTaskCollectionQuery() {
		return this.baseQuery.collection('JobTasks').where('AssignedResource', '==', this.currentUser.uid);
	}

	getQuotesPaginated(itemsPerPage: number, lastQuote?: any, filters?: any) {
		let query = this.baseQuery.collection('Quotes').where('ResourceId', '==', this.currentUser.uid);

		if (!isNullOrUndefined(filters['quoteStatus']) && filters['quoteStatus'] !== '') {
			switch (filters['quoteStatus']) {
				case 'Pending':
					query = query.where('Status', '==', 'Pending');
					break;
				case 'Submitted':
					query = query.where('Status', '==', 'Submitted');
					break;
				case 'Approved':
					query = query.where('Status', '==', 'Approved');
					break;
				case 'Rejected':
					query = query.where('Status', '==', 'Rejected');
					break;
			}
		}

		if (!isNullOrUndefined(filters['site']) && filters['site'] !== '') {
			query = query.where('SiteID', '==', filters['site']);
		}

		if (lastQuote) query = query.startAfter(lastQuote);
		query = query.limit(itemsPerPage);
		return query;
	}

	getallJobTasks = (): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery.collection('JobTasks')
				.where('ResourcesToTask', 'array-contains', this.currentUser.uid)
				.get()
				.then(res => {
					const allTasks = res.docs.map(doc => doc.data());
					resolve(allTasks);
				}).catch(err => reject(err))
		})
	}

	async getJobTaskByFBID(JobTaskFBID: string) {
		return this.baseQuery
			.collection('JobTasks')
			.doc(JobTaskFBID)
			.get();
	}


	getJobTaskPaginated(itemsPerPage: number, lastAsset?: any, filters?: any) {
		//let query = this.baseQuery.collection('JobTasks').where('AssignedResource', '==', this.currentUser.uid);
		let query = this.baseQuery.collection('JobTasks').where('ResourcesToTask', 'array-contains', this.currentUser.uid);

		// if (!isNullOrUndefined(filters['searchInput']) && filters['searchInput'] != '') {
		// 	query = query.where('Description', '>=', filters['searchInput']);
		// 	query = query.orderBy('Description', filters['order']);
		// }

		if (!isNullOrUndefined(filters['jobNumber']) && filters['jobNumber'] != '') {
			// don't consider other filters when searching by job number
			return query.where('ClientJobNumber', '==', +filters['jobNumber']);
		}

		if (!isNullOrUndefined(filters['openClosed']) && filters['openClosed'] != '') {
			switch (filters['openClosed']) {
				case 'Open':
					query = query.where('IsClosed', '==', false);
					break;
				case 'Closed':
					query = query.where('IsClosed', '==', true);
					break;
			}
		}

		if (!isNullOrUndefined(filters['plannedReactive']) && filters['plannedReactive'] != '') {
			switch (filters['plannedReactive']) {
				case 'Planned':
					query = query.where('IsPlanned', '==', true);
					break;
				case 'Reactive':
					query = query.where('IsPlanned', '==', false);
					break;
			}
		}

		if (!isNullOrUndefined(filters['isDueToday']) && filters['isDueToday'])
			query = query
				.where('ExpectedDate', '>=', new Date().setHours(0, 0, 0, 0))
				.where('ExpectedDate', '<=', new Date().setHours(23, 59, 59, 999));

		if (!isNullOrUndefined(filters['site']) && isNullOrUndefined(filters['location']) && isNullOrUndefined(filters['subLocation'])) {
			query = query.where('SiteID', '==', filters['site']);
		}

		if (!isNullOrUndefined(filters['site']) && !isNullOrUndefined(filters['location']) && isNullOrUndefined(filters['subLocation'])) {
			//console.log("THIS RAN");
			//console.log(filters);
			query = query.where('SiteID', '==', filters['site']);
			let locationID = filters.location.value;
			query = query.where('LocationID', '==', locationID);
		}

		if (!isNullOrUndefined(filters['site']) && !isNullOrUndefined(filters['location']) && !isNullOrUndefined(filters['subLocation'])) {
			let locationID = filters.location.value;
			let sublocationID = filters.subLocation.value;
			// console.log(filters);
			// console.log(locationID);
			// console.log(sublocationID);
			query = query.where('LocationID', '==', locationID);
			query = query.where('SubLocationID', '==', sublocationID);
		}

		if (!isNullOrUndefined(filters['order']) && filters['order'] != '')
			query = query.orderBy('ExpectedDate', filters['order']);

		if (lastAsset) query = query.startAfter(lastAsset);

		query = query.limit(itemsPerPage);

		return query;
	}

	// getSitesPaginated = (items: number, input?: string, lastSite?: any) => {
	// 	let query = this.baseQuery.collection('Sites').orderBy('SiteName');
	// 	if(!isNullOrUndefined(input)) {
	// 		if(input.length === 1) {
	// 			query = query.where('Site1', '==', input);
	// 		} else if(input.length === 2) {
	// 			query = query.where('Site2', '==', input);
	// 		} else if(input.length === 3) {
	// 			query = query.where('Site3', '==', input);
	// 		}
	// 	}

	// 	if(lastSite) query = query.startAfter(lastSite);
	// 	query = query.limit(items);

	// 	return query;
	// }

	// getSitesPaginated2 = (items: number, input?: string, lastSite?: any) => {
	// 	let query = this.baseQuery.collection('Sites').orderBy('SiteName');

	// 	if(!isNullOrUndefined(input)) {
	// 		console.log(input);
	// 		query = query.startAt(input).endAt(input + '\uf8ff');
	// 	}

	// 	if(lastSite) query = query.startAfter(lastSite);
	// 	query = query.limit(items);

	// 	return query;
	// }

	getSearchAssetClassesPaginated = (items: number, lastAssetClass?: any) => {
		let query = this.baseQuery.collection('AssetClass').orderBy('AssetClassName')
			.where("Type", "==", "AssetClass");

		if (lastAssetClass) query = query.startAfter(lastAssetClass);
		query = query.limit(items);
		return query;
	}

	getSearchAssetSubClassesPaginated = (items: number, assetClassFBID, lastAssetSubClass?: any) => {
		let query = this.baseQuery.collection('AssetClass').orderBy('AssetSubClassName')
			.where("AssetClassFBID", "==", assetClassFBID)
			.where("Type", "==", "AssetSubClass");

		if (lastAssetSubClass) query = query.startAfter(lastAssetSubClass);
		query = query.limit(items);
		return query;
	}

	getSearchSitesPaginated = (items: number, input?: string, lastSite?: any, canAccessAllSites?: boolean, ContractFBID?: string | null) => {
		let query = this.baseQuery.collection('Sites').orderBy('SiteName');

		if (!isNullOrUndefined(canAccessAllSites) && !canAccessAllSites) {
			query = this.baseQuery.collection('Sites').where('UsersPermittedToSite', 'array-contains', this.currentUser.uid);
		}

		if (!isNullOrUndefined(ContractFBID)) {
			query = query.where("ContractFBID", "==", ContractFBID);
		}

		if (!isNullOrUndefined(input) && input !== '') {
			query = query.where('SiteNameArray', 'array-contains', input.toLowerCase());
		}

		if (lastSite) query = query.startAfter(lastSite);
		query = query.limit(items);
		return query;
	}


	getSearchLocationsPaginated = (items: number, siteId: number | string | null, input?: string, lastLocation?: any) => {
		let query = this.baseQuery
			.collection('Locations')
			.withConverter(fireLocationConverter)
			.orderBy('LocationName');
		if (typeof siteId === 'number') query = query.where('SiteID', '==', siteId);
		else if (typeof siteId === 'string') query = query.where('SiteFBID', '==', siteId);

		if (!isNullOrUndefined(input) && input !== '') {
			query = query.where('LocationNameArray', 'array-contains', input.toLowerCase());
		}

		if (lastLocation) query = query.startAfter(lastLocation);
		query = query.limit(items);
		return query;
	}

	getSearchSubLocationsPaginated = (
		items: number,
		locationId: number | null,
		input?: string,
		lastSubLocation?: any,
	) => {
		let query = this.baseQuery
			.collection('SubLocations')
			.withConverter(fireSubLocationConverter)
			.orderBy('SubLocationName');
		if (typeof locationId === 'number') query = query.where('LocationID', '==', locationId);

		if (!isNullOrUndefined(input) && input !== '') {
			query = query.where('SubLocationNameArray', 'array-contains', input.toLowerCase());
		}

		if (lastSubLocation) query = query.startAfter(lastSubLocation);
		query = query.limit(items);
		return query;
	}


	getJobTaskPhotos(Document: string) {
		return this.baseQuery
			.collection('JobTasks')
			.doc(Document)
			.collection('Photos');
	}

	getJobTaskNotes(Document: string) {
		return this.baseQuery
			.collection('JobTasks')
			.doc(Document)
			.collection('Notes');
	}

	getjobTaskNotesForLoadedTasks = (jobIds): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery
				.collection('JobTasks')
				.where(firebase.firestore.FieldPath.documentId(), 'in', jobIds)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						resolve([])
					} else {
						querySnapshot.forEach(document => {
							document.ref.collection('Notes').get()
								.then(res => {
									resolve(res)
								}).catch(err => reject(err))
						})
					}
				})
		})
	}


	getJobTaskDocuments(Document: string) {
		return this.baseQuery
			.collection('JobTasks')
			.doc(Document)
			.collection('Documents');
	}

	getExpensesDocuments(Document: string) {
		return this.baseQuery
			.collection('Expenses')
			.doc(Document)
			.collection('Documents');
	}

	getCollection(
		Collection: string,
		OrderBy?: string,
		OrderDirection?: 'desc' | 'asc',
	): Promise<firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>> {
		return this._getCollection(Collection, OrderBy, OrderDirection).get();
	}

	/**
	 * Do the same thing as {@link getCollection} but using a firestore data converter
	 */
	getCollectionWithConverter<T = firebase.firestore.DocumentData>(
		collection: string,
		converter: firebase.firestore.FirestoreDataConverter<T>,
		OrderBy?: string,
		OrderDirection?: 'desc' | 'asc',
	): Promise<firebase.firestore.QuerySnapshot<T>> {
		return this._getCollection(collection, OrderBy, OrderDirection)
			.withConverter(converter)
			.get();
	}

	private _getCollection(collection: string, OrderBy?: string, OrderDirection?: 'desc' | 'asc') {
		const query = this.baseQuery.collection(collection);
		let finalQuery: firebase.firestore.Query<firebase.firestore.DocumentData> = query;
		if (!isNullOrUndefined(OrderBy)) {
			finalQuery = finalQuery.orderBy(OrderBy, OrderDirection);
		}
		return finalQuery;
	}

	async getCollectionWhere(Collection: string, Field: string, SearchParam: string) {
		return this.baseQuery
			.collection(Collection)
			.where(Field, '==', SearchParam)
			.get();
	}

	getPhotos(Document: string, Collection: string) {
		return this.baseQuery
			.collection(Collection)
			.doc(Document)
			.collection('Documents');
	}


	updateDocumentGuid(itemId: string, collection: string, documentId: string, updateObj: any) {
		return this.baseQuery
			.collection(collection)
			.doc(itemId)
			.collection("Documents")
			.doc(documentId)
			.update(updateObj)
	}

	getJobTypes() {
		return this.baseQuery
			.collection("JobTypes")
			.where("IsPlanned", '!=', true)
			.get();
	}

	getDocuments(Document: string, Collection: string) {
		return this.baseQuery
			.collection(Collection)
			.doc(Document)
			.collection('Documents');
	}	

	async uploadImage(
		imageBlob: Blob,
		DocumentId: string,
		type: string,
		associatedFBID: string,
		photoFBID: string,
		newGuid?: string,
		SyncToQueue?: boolean,
	): Promise<boolean> {
		return new Promise((resolve) => {
			const unixTime = Date.now();
			const fileName = `${unixTime}.jpg`;
			const imgPath = `${this.ClientID}/${this.currentUser.uid}/images/${fileName}`;
			const photosCollection = `/Clients/${this.ClientID}/${type + "s"}/${DocumentId}/Documents`;
			let uploadSuccess = true;
			this.ref
				.child(imgPath)
				.put(imageBlob)
				.then(async (firebaseRef) => {
					uploadSuccess = await this.syncFirebasePhoto(
						firebaseRef,
						photosCollection,
						DocumentId,
						type,
						associatedFBID,
						photoFBID,
						newGuid,
						SyncToQueue
					);
					resolve(uploadSuccess);
				})
				.catch(error => {
					resolve(false);
					this.logException(error, 'Firebase', 'uploadImage');
					console.error(error);
				});
		})
	}

	async syncFirebasePhoto(
		firebaseRef: firebase.storage.UploadTaskSnapshot,
		photosCollection: string,
		DocumentId: string,
		type: string,
		associatedFBID: string,
		photoFBID:string,
		newGuid?: string,
		SyncToQueue?: boolean,
	): Promise<boolean> {
		return new Promise(async (resolve) => {
			const url: string | null = await firebaseRef.ref.getDownloadURL();
			const photoObj = {
				FirebaseStoragePath: url,
				FirebaseRef: firebaseRef.ref.fullPath,
				Guid: newGuid,
				Filename: `Contractor-Photo-${new Date().getTime()}.jpeg`,
				UploadedBy: this.currentUser.email,
				Source: JOB_DOCUMENT_SOURCE_FROM_APP,
				DateCreated: new Date().getTime(),
			};
			this.db
				.collection(photosCollection)
				.doc(photoFBID)
				.set(photoObj)				
				.then(photo => {
					if (SyncToQueue) {
						const data = {
							FirebaseStoragePath: url,
							JobAction: 'AddDocument',
							FBID: photoFBID,
							Type: type,
							AssociatedFBID: associatedFBID,
							DocumentFileName: photoObj.Filename,
							DocumentFBID: photoFBID
						};
						this.postToJobQueue(DocumentId, data);
					}
					resolve(true);
				}).catch(() => resolve(false));
		})

	}

	addDocument(
		document: Blob,
		documentName: string,
		documentID: string,
		documentNewFBID: string,
		documentGuid: string,
		collection: string,
		associatedFBID: string
	) {
		const uniqueFolderName = 'doc' + generateFirebaseId();
		const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${uniqueFolderName}/${documentName}`;
		return this.ref
			.child(docPath)
			.put(document)
			.then(firebaseRef => {
				return firebaseRef.ref.getDownloadURL();
			})
			.then(downloadUrl => {
				this.baseQuery
					.collection(collection + "s")
					.doc(associatedFBID)
					.collection('Documents')
					.doc(documentNewFBID)
					.set({
						FirebaseStoragePath: downloadUrl,
						Filename: documentName,
						Description: 'User Upload',
						Guid: documentGuid,
						UploadedBy: this.currentUser.email,
						Source: JOB_DOCUMENT_SOURCE_FROM_APP,
						DateCreated: new Date().getTime(),
					});

				return downloadUrl;
			})
			.then(url => {
				const jobTaskQueueData = {
					FirebaseStoragePath: url,
					JobAction: 'AddDocument',
					DocumentFileName: documentName,
					DocumentFBID: documentNewFBID,
					AssociatedFBID: associatedFBID,
					Type: collection
				};
				this.postToJobQueue(documentID, jobTaskQueueData);
			})
			.catch(error => console.error(error));
	}

	addTagToDocument(tagFBID: string, collection: string, associatedFBID: string, selectedDocumentGuid: string, selectedDocumentFBID): Promise<void> {
		return this.getDocumentTag(tagFBID).then(data => {
			let tag = data.data()
			if (tag !== undefined) {
				return this.baseQuery
					.collection(collection + 's')
					.doc(associatedFBID)
					.collection('Documents')
					.doc(selectedDocumentFBID)
					.get()
					.then(querySnapshot => {
						console.log("This ran.");
						if (querySnapshot != undefined) {
							this.baseQuery
								.collection(collection + 's')
								.doc(associatedFBID)
								.collection('Documents')
								.withConverter(fireTagConverter)
								.doc(querySnapshot.id)
								.update({
									DocumentTags: firebase.firestore.FieldValue.arrayUnion(tag),
								})
								.then(() => {
									console.log("This ran.");
									const jobQueueData = {
										JobAction: 'AddDocumentTag',
										DocumentFBID: querySnapshot.id,
										TagFBID: tagFBID,
										AssociatedFBID: associatedFBID,
									};

									this.postToJobQueue(tagFBID, jobQueueData);
								});
						} else if (querySnapshot == undefined) {
							console.error('Document not found');
						} else {
							console.error('Multiple documents found');
						}
					});
			}
		});
	}

	async getDocument(Collection: string, DocumentId: string) {
		return this.baseQuery
			.collection(Collection)
			.doc(DocumentId)
			.get();
	}

	getDocumentTags() {
		return this.baseQuery
			.collection("DocumentTags")
			.withConverter(fireTagConverter)
	}

	getDocumentTagsFiltered() {
		return this.getDocumentTags().where("TagAvailability", "in", ["All", "ResourceOnly"])
	}

	getDocumentTag(tagFBID: string) {
		return this.baseQuery
			.collection('DocumentTags')
			.doc(tagFBID)
			.withConverter(fireTagConverter)
			.get();
	}

	async getDocumentByRef(ref: string) {
		return this.db.doc(ref).get();
	}

	getDocumentQuery(Collection: string, DocumentId: string) {
		return this.baseQuery.collection(Collection).doc(DocumentId);
	}

	async updateDocument(documentRef: firebase.firestore.DocumentReference, updateObj: object) {
		return documentRef.update(updateObj);
	}

	async deleteDocument(documentRef: firebase.firestore.DocumentReference) {
		return documentRef.delete();
	}

	async postToJobQueue(documentId: string, jobTaskQueueData: any, dateTimeNow?: string) {
		jobTaskQueueData.ClientUID = this.ClientID;
		jobTaskQueueData.Timestamp = new Date().toUTCString();
		jobTaskQueueData.UID = this.currentUser.uid;
		jobTaskQueueData.DocumentId = documentId;
		jobTaskQueueData.ServerName = this.settings === undefined ? 'Ireland' : this.settings.ServerName;


		const TimeMilli = dateTimeNow === undefined || dateTimeNow === null ? new Date().getTime().toString() : dateTimeNow;
		const willDelay = DelayPostToJobQueue(jobTaskQueueData.JobAction);

		if (willDelay) {
			await delay(1000);
		}

		let collection = "JobTaskQueue".concat(jobTaskQueueData.ServerName);


		this.db
			.collection(collection)
			.doc(TimeMilli)
			.set(jobTaskQueueData);
	}

	async postToJobQueueLogin(documentId: string, jobTaskQueueData: any) {
		//console.log(documentId, jobTaskQueueData);
		jobTaskQueueData.Timestamp = new Date().toUTCString();
		jobTaskQueueData.DocumentId = documentId;

		const TimeMilli = new Date().getTime().toString();
		const willDelay = DelayPostToJobQueue(jobTaskQueueData.JobAction);

		if (willDelay) {
			await delay(1000);
		}

		return this.db
			.collection('JobTaskQueue')
			.doc(TimeMilli)
			.set(jobTaskQueueData);
	}

	async uploadImageExistingJob(
		imageBlob: Blob,
		DocumentId: string,
		jobTaskQueueObject: any,
		newGuid?: string
	): Promise<boolean> {
		return new Promise((resolve) => {
			const unixTime = Date.now();
			const fileName = `${unixTime}.jpg`;
			const imgPath = `${this.ClientID}/${this.currentUser.uid}/images/${fileName}`;
			const photosCollection = `/Clients/${this.ClientID}/JobTasks/${DocumentId}/Documents`;
			let uploadSuccess = true;
			this.ref
				.child(imgPath)
				.put(imageBlob)
				.then(async firebaseRef => {
					uploadSuccess = await this.syncFirebase(
						firebaseRef,
						photosCollection,
						DocumentId,
						jobTaskQueueObject,
						newGuid
					);
					resolve(uploadSuccess);
				})
				.catch(error => {
					this.logException(error, 'Firebase', 'uploadImageExistingJob');
					console.error(error);
				});
		})
	}

	async clearNotifications(JobTasks: any) {

		for (let i = 0; i < JobTasks.length; i++) {

			this.baseQuery
				.collection('JobTasks')
				.doc(JobTasks[i].Id)
				.update({
					IsViewed: true
				})
		}
	}

	syncFirebase(
		firebaseRef: firebase.storage.UploadTaskSnapshot,
		photosCollection: string,
		DocumentId: string,
		jobTaskQueueObject: any,
		newGuid?: string,
	): Promise<boolean> {
		return new Promise((resolve) => {
			let sucess = true;
			const photoNewFBID = jobTaskQueueObject['PhotoNewFBID'];
			firebaseRef.ref
				.getDownloadURL()
				.then(async (url) => {
					const geoLocation = await getLocation();
					const Latitude = geoLocation.Latitude;
					const Longitude = geoLocation.Longitude;
					const photoObj = {
						FirebaseStoragePath: url,
						FirebaseRef: firebaseRef.ref.fullPath,
						Guid: newGuid,
						Filename: `Contractor-Photo-${new Date().getTime()}.jpeg`,
						UploadedBy: this.currentUser.email,
						Source: JOB_DOCUMENT_SOURCE_FROM_APP,
						DateCreated: new Date().getTime(),
					};
					this.db
						.collection(photosCollection)
						.doc(photoNewFBID)
						.set(photoObj)
						.then(() => {
							const data = {
								FirebaseStoragePath: url,
								JobAction: 'AddPhotoExistingJobTask',
								PhotoFBID: photoNewFBID,
								Longitude,
								Latitude,
							};
							this.postToJobQueue(DocumentId, data);
						})
						.catch(() => {
							sucess = false
						})
					resolve(sucess);
				})
				.catch(error => {
					resolve(false);
					this.logException(error, 'Firebase', 'syncFirebase');
					console.error(error);
				});
		})
	}

	async uploadBase64Image(imageBlob: string, userUID: string, clientID: string): Promise<string | null> {
		const unixTime = Date.now();
		const fileName = `${unixTime}.jpg`;
		const imgPath = `${clientID}/${userUID}/images/${fileName}`;

		return this.ref
			.child(imgPath)
			.putString(imageBlob, 'base64')
			.then(firebaseRef => {
				return firebaseRef.ref.getDownloadURL();
			})
			.catch(error => console.error(error));
	}

	async uploadBlobImage(imageBlob: Blob, userUID: string, clientID: string) {
		const unixTime = Date.now();
		const fileName = `${unixTime}.jpg`;
		const imgPath = `${clientID}/${userUID}/images/${fileName}`;

		return this.ref
			.child(imgPath)
			.put(imageBlob)
			.then(firebaseRef => {
				return firebaseRef.ref.getDownloadURL();
			})
			.catch(error => console.error(error));
	}

	uploadDocument(
		document: Blob,
		documentName: string,
		documentID: string,
		documentNewFBID: string,
		documentGuid: string,
	) {
		const uniqueFolderName = 'doc' + generateFirebaseId();
		const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${uniqueFolderName}/${documentName}`;
		return this.ref
			.child(docPath)
			.put(document)
			.then(firebaseRef => {
				return firebaseRef.ref.getDownloadURL();
			})
			.then(downloadUrl => {
				this.baseQuery
					.collection('JobTasks')
					.doc(documentID)
					.collection('Documents')
					.doc(documentNewFBID)
					.set({
						FirebaseUrl: downloadUrl,
						FileName: documentName,
						Description: 'User Upload',
						Guid: documentGuid,
						UploadedBy: this.currentUser.email,
						Source: JOB_DOCUMENT_SOURCE_FROM_APP,
						DateCreated: new Date().getTime(),
					});

				return downloadUrl;
			})
			.then(url => {
				const jobTaskQueueData = {
					FirebaseStoragePath: url,
					JobAction: 'AddDocumentExistingJobTask',
					DocumentFileName: documentName,
					DocumentFBID: documentNewFBID,
				};
				this.postToJobQueue(documentID, jobTaskQueueData);
			})
			.catch(error => console.error(error));
	}

	uploadDocumentForFormAnswer(document: File | Blob, documentName: string): firebase.storage.UploadTask {
		const uniqueFolderName = 'doc' + generateFirebaseId();
		const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${uniqueFolderName}/${documentName}`;
		return this.ref.child(docPath).put(document);
	}

	async UpdateFormAfterAnswer(DocumentID: string) {
		let UserName = this.currentUser.email;

		if (!isNullOrUndefined(this.settings)) {
			if (!isNullOrUndefined(this.settings.Name)) {
				UserName = this.settings.Name;
			}
		}

		return this.baseQuery
			.collection('Forms')
			.doc(DocumentID)
			.update({
				LastUpdatedByUserName: UserName,
				LastUpdatedByUserFBID: this.currentUser.uid,
				LastUpdatedDate: Date.now(),
			});
	}

	async getFormSections(formTemplateFBID: string) {
		return this.baseQuery
			.collection('Forms')
			.doc(formTemplateFBID)
			.get();
	}

	updateForm(formFBID: string, form: any) {
		this.baseQuery
			.collection('Forms')
			.doc(formFBID)
			.update(form);
	}

	uploadExpenseDocumentOnCreate(document: Blob, documentName: string, expenseID: string, guid: string) {
		const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${documentName}`;

		return this.ref
			.child(docPath)
			.put(document)
			.then(firebaseRef => {
				return firebaseRef.ref.getDownloadURL();
			})
			.then(downloadURL => {
				const downloadObj = {
					FirebaseStoragePath: downloadURL,
					DocumentFileName: documentName,
					DocumentFBID: guid,
				};

				this.baseQuery
					.collection('Expenses')
					.doc(expenseID)
					.collection('Documents')
					.doc(guid)
					.set({
						FirebaseUrl: downloadURL,
						FileName: documentName,
						Description: 'User Upload',
						Guid: guid,
					});

				return downloadObj;
			});
	}

	async updateExpense(DocumentId: string, localObj: any) {
		return this.baseQuery
			.collection('Expenses')
			.doc(DocumentId)
			.update(localObj);
	}

	uploadExpenseDocument(document: Blob, documentName: string, expenseID: string, Guid: string) {
		const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${documentName}`;

		this.ref
			.child(docPath)
			.put(document)
			.then(firebaseRef => {
				return firebaseRef.ref.getDownloadURL();
			})
			.then(downloadUrl => {
				this.baseQuery
					.collection('Expenses')
					.doc(expenseID)
					.collection('Documents')
					.doc(Guid)
					.set({
						FirebaseUrl: downloadUrl,
						FileName: documentName,
						Description: 'User Upload',
						Guid,
					});
				// .add({
				// 	FirebaseUrl: downloadUrl,
				// 	FileName: documentName,
				// 	Description: 'User Upload',
				// 	Guid,
				// });

				return downloadUrl;
			})
			.then(url => {
				const jobTaskQueueData = {
					FirebaseStoragePath: url,
					JobAction: 'AddDocumentExistingExpense',
					DocumentFileName: documentName,
					ExpenseFBID: expenseID,
					DocumentFBID: Guid,
				};
				this.postToJobQueue(expenseID, jobTaskQueueData);
			}) // then part needs to e added here for the queue
			.catch(error => console.error(error));
	}

	async SubmitExpense(DocumentId: string, localObj: any) {
		return this.baseQuery
			.collection('Expenses')
			.doc(DocumentId)
			.update(localObj)
			.catch(err => console.error(err));
	}

	async addExpense(description: string, supplier: string, unitprice: string, units: string) {
		const ExpenseObj = {
			AssignedResource: this.currentUser.uid,
			DateCreated: Date.now(),
			Description: description,
			Note: null,
			Status: 'Pending',
			Supplier: supplier,
			UnitPrice: unitprice,
			Units: units,
		};

		return this.baseQuery.collection('Expenses').add(ExpenseObj);
	}

	updateCurrentActiveJobTask(currentTaskStatus: string | null, currentTaskId: string | null) {
		this.db
			.doc(`ResourcesToClient/${this.resourceToSettingsDocId}`)
			.update({
				CurrentJobTask: currentTaskId,
				CurrentJobTaskStatus: currentTaskStatus,
			})
			.catch(error => this.logException(error, 'Firebase', 'syncFirebase'));
	}

	updateTaskStatus(taskId: string, newStatus: string) {
		return this.baseQuery
			.collection('JobTasks')
			.doc(taskId)
			.update({ Status: newStatus });
	}

	updateTask(taskId: string, updateObj: object) {
		return this.baseQuery
			.collection('JobTasks')
			.doc(taskId)
			.update(updateObj);
	}

	async updateJobTaskActions(DocumentId: string, localObj: any, deleteValues: boolean) {
		if (deleteValues) {
			localObj.TaskArriveOnSite = firebase.firestore.FieldValue.delete();
			localObj.TaskLeaveSite = firebase.firestore.FieldValue.delete();
			localObj.TaskTravelStart = firebase.firestore.FieldValue.delete();
		}

		return this.baseQuery
			.collection('JobTasks')
			.doc(DocumentId)
			.update(localObj)
			.catch(err => console.error(`There was an error updating a local document - error : ${err}`));
	}

	async addJobTaskNote(DocumentId: string, note: string, guid: string) {
		const NoteObj = {
			CreatedBy: this.currentUser.email,
			DateAdded: Date.now(),
			Note: note,
			AddedByContractor: true,
		};

		return this.baseQuery
			.collection('JobTasks')
			.doc(DocumentId)
			.collection('Notes')
			.doc(guid)
			.set(NoteObj);
	}

	loadSiteAndLocationsAndPriorities() {
		this.baseQuery
			.collection('Sites')
			.get()
			.then(() => {
				this.db
					.collection('Clients')
					.doc(this.ClientID)
					.collection('Locations')
					.get()
					.then(() => {
						this.db
							.collection('Clients')
							.doc(this.ClientID)
							.collection('SubLocations')
							.get();
					});
			});

		this.baseQuery.collection('Priorities').get();
	}

	logException(ErrorMessage: string, FileName: string, MethodName: string) {
		this.baseQuery.collection('Exceptions').add({
			ErrorMessage,
			User: this.currentUser.email,
			DateTime: new Date().toUTCString(),
			FileName,
			MethodName,
		});
	}

	getResourceSettings() {
		return this.db.doc(`ResourcesToClient/${this.resourceToSettingsDocId}`);
	}

	private updateCurrentUser() {
		this.auth.onAuthStateChanged((user: firebase.User | null) => {
			if (user !== null) {
				store.dispatch(updateUser(user));
				this.currentUser = user;
				this.resourceFBID = user.uid;
				this.getUserSettings(user.uid);
			} else {
				store.dispatch(loginUserError(''));
			}
		});
	}

	private async getClientSettings(clientUID: string) {
		return this.db.doc(`Clients/${clientUID}`).get();
	}

	private getUserSettings(UserId: string) {
		this.db
			.collection('ResourcesToClient')
			.where('UserUID', '==', UserId)
			.limit(1)
			.onSnapshot(userSettings => {
				if (userSettings.docs.length > 0 && userSettings.docs[0].exists) {
					const settings = userSettings.docs[0].data() as Store.UserSettings;
					this.ClientID = settings.ClientUID;
					this.baseQuery = this.db.collection('Clients').doc(this.ClientID);
					this.resourceToSettingsDocId = userSettings.docs[0].id;
					this.getClientSettings(settings.ClientUID).then(clientSettings => {
						const { JobTaskLeaveLabel, ServerName, ClientLanguage, ClientCulture } = clientSettings.data() as any;
						settings.JobTaskLeaveLabel = isNullOrUndefined(JobTaskLeaveLabel) ? '' : JobTaskLeaveLabel;
						settings.ServerName = isNullOrUndefined(ServerName) ? 'Ireland' : ServerName;
						settings.ClientLanguage = isNullOrUndefined(ClientLanguage) ? 'English' : ClientLanguage;
						settings.ClientCulture = isNullOrUndefined(ClientCulture) ? 'en' : ClientCulture;
						settings.NewJobDatePriorityTreatment = isNullOrUndefined(settings.NewJobDatePriorityTreatment)
							? 'Neither'
							: settings.NewJobDatePriorityTreatment;
						this.settings = settings;
						store.dispatch(setUserSettings(settings));
					});
					this.loadSiteAndLocationsAndPriorities();
				}
			});
	}

	public answerQuestion(data: Forms.QuestionAnswer, customArgs?: object, DateTimeNow?: string) {
		const dataObj = {
			...customArgs,
			Answer: data.Answer,
			DocumentAnswer: data.DocumentAnswer,
			QuestionType: data.QuestionType,
			JobAction: 'FormAnswer',
			QuestionFBID: data.Id,
		};
		this.postToJobQueue(data.FormFBID, dataObj, DateTimeNow);
	}

	public submitForm(
		formFBID: string,
		formRef: firebase.firestore.DocumentReference | null,
		Latitude: number | null,
		Longitude: number | null,
		CompletedDate?: number,
	) {
		const dataObj = {
			JobAction: 'SubmitForm',
			Latitude: Latitude,
			Longitude: Longitude,
			SubmittedDate: CompletedDate,
		};
		if (formRef !== null) {
			formRef.update({ Status: 'Submitted', CompletedDate: CompletedDate || Date.now(), IsCompleted: true });
		}
		this.postToJobQueue(formFBID, dataObj);
	}

	async doesResourceExist(uid: string) {
		return this.db
			.collection('ResourcesToClient')
			.where('UserUID', '==', uid)
			.limit(1)
			.get()
			.then(resourceRecord => resourceRecord.docs.length > 0);
	}

	async isClientExpired(clientID: string) {
		try {
			return this.db
				.collection('Clients')
				.where('FBID', '==', clientID)
				.limit(1)
				.get()
				.then(client => {

					var clientDetails = client.docs[0].data();

					if (clientDetails.AccountExpiryDate === null || clientDetails.AccountExpiryDate === undefined) {
						console.log("no client expiry date found")
						//we decided to let pass users without an expired date set
						return false;
					}

					//this date needs to be in ms
					var expiryDate = clientDetails.AccountExpiryDate;
					var currentDate = Date.now();

					var objExpiryDate = new Date(expiryDate);
					var objCurrentDate = new Date(currentDate);

					return objExpiryDate < objCurrentDate;
				});
		} catch (error) {
			console.log(error)
			return false;
		}
	}

	async getClient(UserID: string, UserEmail: string) {
		let clientID = "";
		await this.db
			.collection(`ResourcesToClient`)
			.where("Email", "==", UserEmail.toLowerCase())
			.limit(1)
			.get()
			.then(users => {
				users.docs.forEach(user => {
					console.log("data", user)
					const data = user.data();
					clientID = data.ClientUID;
				});
			});

		return clientID;
	}

	async CreatePhotoPlaceholder(photoNewFBID: string, taskId: string, guid) {
		this.baseQuery
			.collection('JobTasks')
			.doc(taskId)
			.collection('Documents')
			.doc(photoNewFBID)
			.set({ Guid: guid });
	}

	// async CreatePhotoRecordForQuestionAnswer(ref: string, PhotoObj, PhotoFBID: string) {
	// 	this.db.doc(ref + '/Photos/' + PhotoFBID).set(PhotoObj);
	// }


	public CreatePhotoRecordForQuestionAnswer(
		ref: string, photoObj: any, photoFBID: string
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			this.db.doc(ref + '/Photos/' + photoFBID)
				.set(photoObj)
				.then(res => resolve(true))
				.catch(() => resolve(false));
		})
	}

	// async uploadPhotoForQuestionAnswer(
	// 	imageBlob: Blob,
	// 	documentName: string,
	// 	reference: string,
	// 	Guid: string,
	// 	questionData: any,
	// 	PhotoFBID: string,
	// ) {
	// 	const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${documentName}`;

	// 	const URL = await this.ref
	// 		.child(docPath)
	// 		.put(imageBlob)
	// 		.then(firebaseRef => {
	// 			return firebaseRef.ref.getDownloadURL();
	// 		})
	// 		.catch(error => console.error(error));

	// 	const PhotoObj = {
	// 		Guid: Guid,
	// 		Filename: documentName,
	// 		FirebaseRef: docPath,
	// 		FirebaseStoragePath: URL,
	// 	};

	// 	await this.CreatePhotoRecordForQuestionAnswer(reference, PhotoObj, PhotoFBID);
	// 	const DocumentAnswer = { FileName: documentName, DownloadUrl: URL, PhotoFBID };

	// 	this.answerQuestion(
	// 		{
	// 			...questionData,
	// 			DocumentAnswer,
	// 		},
	// 		{
	// 			PassFailTextBox: questionData.PassFailTextBox,
	// 			PassFailDateTimeStamp: questionData.PassFailDateTimeStamp,
	// 			UploadPhoto: true,
	// 		},
	// 	);
	// }

	public uploadPhotoForQuestionAnswer(
		imageBlob: Blob,
		documentName: string,
		reference: string,
		Guid: string,
		questionData: any,
		PhotoFBID: string,
	): Promise<boolean> {
		return new Promise((resolve, reject) => {
			const docPath = `${this.ClientID}/${this.currentUser.uid}/uploads/${documentName}`;
			this.ref
				.child(docPath)
				.put(imageBlob)
				.then(async firebaseRef => {
					let scuccess = true;
					const imgUrl = await firebaseRef.ref.getDownloadURL();
					const photoObj = {
						Guid: Guid,
						Filename: documentName,
						FirebaseRef: docPath,
						FirebaseStoragePath: imgUrl,
					};
					const test = await this.CreatePhotoRecordForQuestionAnswer(
						reference,
						photoObj,
						PhotoFBID
					);
					scuccess = test;

					const DocumentAnswer = {
						FileName: documentName,
						DownloadUrl: imgUrl,
						PhotoFBID
					};
					this.answerQuestion(
						{
							...questionData,
							DocumentAnswer,
						},
						{
							PassFailTextBox: questionData.PassFailTextBox,
							PassFailDateTimeStamp: questionData.PassFailDateTimeStamp,
							UploadPhoto: true,
						},
					);
					resolve(scuccess);
				}).catch(() => resolve(false));
		})
	}

	async DeleteFromQuestionAnswer(PhotoFBID: string, Path: any) {
		return this.db.doc(Path + '/Photos/' + PhotoFBID).delete();
	}

	getQuestionAnswerPhotoCollection(Path: any) {
		return this.db.doc(Path).collection('Photos');
	}

	async CreateJobTask(JobTaskObj: any, DocumentID: string) {
		return this.baseQuery
			.collection('JobTasks')
			.doc(DocumentID)
			.set(JobTaskObj);
	}

	async CreateJob(JobObj: any, Guid: string) {
		return this.baseQuery
			.collection('Jobs')
			.doc(Guid)
			.set(JobObj);
	}

	async GetSiteInformation(SiteID: number) {
		const sitearray: any[] = [];
		await this.baseQuery
			.collection('Sites')
			.where('SiteID', '==', SiteID)
			.limit(1)
			.get()
			.then(sites => {
				sites.docs.forEach(site => {
					const data = site.data();
					sitearray.push({ Address: data.Address, Contact: data.MainContact, Telephone: data.Telephone });
				});
			});

		return sitearray[0];
	}

	//this.state.quoteID)
	async getJobQuote(QuoteID: string) {
		await this.baseQuery
			.collection('Quotes')
			.doc(QuoteID)
			.get()
			.then(quotes => {
				const data = quotes.data();
				return data;
			});
	}

	// async getJobNumber(JobFBID: string) {
	// 	let data = '';

	// 	await this.baseQuery
	// 		.collection('Jobs')
	// 		.doc(JobFBID)
	// 		.get()
	// 		.then(data => {
	// 			data = data.get('JobNumber');
	// 		});

	// 	return data;
	// }

	async getJob(JobFBID: string) {
		return this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get();
	}

	async getjobsForLoadedTasks(jobIds) {
		return this.baseQuery
			.collection('Jobs')
			.where(firebase.firestore.FieldPath.documentId(), 'in', jobIds)
			.get();
	}

	async CreateJobQuote(JobQuoteObj: any, DocumentID: string) {
		return this.baseQuery
			.collection('Quotes')
			.doc(DocumentID)
			.set(JobQuoteObj);
	}

	async GetAppUpdateFlags() {
		const userData = this.db
			.collection('ResourcesToClient')
			.where('UserUID', '==', this.resourceFBID)
			.limit(1)
			.get();

		await userData.then(async userSettings => {
			if (userSettings.docs.length > 0 && userSettings.docs[0].exists) {
				const settings = userSettings.docs[0].data() as Store.UserSettings;

				if (settings.AppNeedsUpgrade === true) {
					await this.db.doc(`ResourcesToClient/${userSettings.docs[0].id}`).update({ AppNeedsUpgrade: false });
					alert('New version is available. The update will begin once the ok button is clicked.');

					await self.indexedDB.deleteDatabase('TrackplanContractorDB');
					window.location.reload();
					return;
				}

				if (settings.SendAppVersion === true) {
					await this.db.doc(`ResourcesToClient/${userSettings.docs[0].id}`).update({ SendAppVersion: false });

					const data = {
						VersionNumber: Api.VERSION,
						DbVersionNumber: Api.INDEXEDDB_VERSION,
						UserUID: this.resourceFBID,
						deviceInfo: navigator.appVersion,
						triggeredBy: 'Auto',
					};

					let server = '';
					if (!isNullOrUndefined(this.settings) && !isNullOrUndefined(this.settings.ServerName)) {
						server = this.settings.ServerName;
					} else {
						if (!isNullOrUndefined(this.settings)) {
							await SendErrorData(
								this.settings.Email,
								this.settings.UserUID,
								this.settings.ServerName,
								'settings or Servername for this user was undefined.',
								'',
								'',
							);
						}
					}

					await axios({
						data: data,
						method: 'post',
						url: getBaseURL(server) + '/api/upgradealert/resourceapp',
						headers: ApiKeyObj,
					}).catch((err: AxiosError) => {
						if (!isNullOrUndefined(this.settings)) {
							SendErrorData(
								this.settings.Email,
								this.settings.UserUID,
								this.settings.ServerName,
								err.response + ' ' + err.stack + ' ' + err.message,
								'',
								'',
							);
						}
					});

					return;
				}
			}
		});
	}

	getjobFormsLoadedTasks = (jobtaskIds): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery
				.collection('Forms')
				.where("JobTaskFBID", 'in', jobtaskIds)
				.get()
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						resolve([])
					} else {
						querySnapshot.forEach(documentRes => {
							documentRes.ref.collection('QuestionAnswers')
								.get().then(async doc => {
									for (const docItem of doc.docs) {
										let formatedData;
										formatedData = docItem.data() as Forms.QuestionAnswer;
										formatedData.Id = docItem.id;
										formatedData.questionRef = docItem.ref;
										if (formatedData.QuestionType === 'Photo') {
											await idb.getPhotos(formatedData.Id)
											this.getQuestionAnswerPhotoCollection(formatedData.questionRef.path)
												.get().then(async docs => {
													for (const doc of docs.docChanges()) {
														const change = doc;
														const Photo = doc.doc.data() as JobTask.JobTaskPhoto;
														await idb.downloadQuestionAnswerPhotoFromFirebase(
															!isNullOrUndefined(Photo.FirebaseStoragePath)
																? Photo.FirebaseStoragePath
																: (Photo.AzureUrl as string),
															formatedData.Id,
															!isNullOrUndefined(Photo.Guid) ? (Photo.Guid as string) : generateGuid(),
															change.doc.id,
															Photo.Filename as string,
															"true",
															documentRes.id,
														);
													}
												})
										}
									};
								});
						})
						resolve(querySnapshot)
					}
				})
		})
	}

	getJobTaskPhotosForLoadedTasks = (jobtaskIds): Promise<JobTask.JobTaskDocument[]> => {
		return new Promise(async (resolve, reject) => {
			let allTaskDocs: any = [];
			await this.baseQuery
				.collection('JobTasks')
				.where(firebase.firestore.FieldPath.documentId(), 'in', jobtaskIds)
				.get()
				.then(async (querySnapshot: any) => {
					await querySnapshot.forEach(async doc1 => {
						await doc1.ref.collection('Documents').get()
							.then(async res => {
								const taskDocs = await res
									.docs.map(doc => doc.data() as JobTask.JobTaskDocument);
								if (taskDocs && taskDocs.length) {
									allTaskDocs = [...allTaskDocs, ...taskDocs];
								}
							}).catch(err => reject(err))
					})
				})
			resolve(allTaskDocs);
		})
	}

	getFormTemplates = (): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery
				.collection('FormTemplates')
				.where('Scope', '==', 'JobForm')
				.orderBy("FormName")
				.get().then(async res => {
					if (res.empty) {
						resolve([])
					} else {
						for (let doc of res.docs) {
							await doc.ref.collection('Questions').get()
							await doc.ref.collection('QuestionAnswers').get()
						}
					}
					resolve(res)
				}).catch(err => reject(err))
		})
	}

	getFormsForJobTask(JobTaskFBID: string) {
		return this.baseQuery.collection('Forms').where('JobTaskFBID', '==', JobTaskFBID);
	}

	getFormTemplatesCollection() {
		return this.baseQuery.collection('FormTemplates').where('Scope', '==', "FormLibrary").orderBy('FormName', 'asc');
	}

	getFormTemplateQuestions(TemplateFBID: string) {
		return this.baseQuery
			.collection('FormTemplates')
			.doc(TemplateFBID)
			.collection('Questions')
			.get();
	}

	getFormTemplateSections(TemplateFBID: string) {
		return this.baseQuery
			.collection('FormTemplates')
			.doc(TemplateFBID)
			.get();
	}

	getFormTemplateQuestionsForSectionID(templateFBID: string, templateSectionID: number) {
		return this.baseQuery
			.collection('FormTemplates')
			.doc(templateFBID)
			.collection('Questions')
			.where('TemplateSectionID', '==', templateSectionID)
			.get();
	}

	CreateForm(FormObj: any, FirebaseID: string) {
		this.baseQuery
			.collection('Forms')
			.doc(FirebaseID)
			.set(FormObj);
	}

	async getFormTemplate(formTemplateFBID: string) {
		return this.baseQuery
			.collection('FormTemplates')
			.doc(formTemplateFBID)
			.get();
	}

	CreateFormQuestionAnswers(QuestionObj: any, FormFBID: string, QuestionAnswerFBID) {
		this.baseQuery
			.collection('Forms')
			.doc(FormFBID)
			.collection('QuestionAnswers')
			.doc(QuestionAnswerFBID)
			.set(QuestionObj);
	}

	UpdateFormTemplateFormStart(FormTemplateFBID: string, LastFormStartedOnManagerAppFBID: string | null) {
		// This is needed so that if form started on the manager app, then in the form library on the resource app it will update the form library straight away
		// This is possible as the "onstream" is triggered by a change on the FormTemplates document
		return this.baseQuery
			.collection('FormTemplates')
			.doc(FormTemplateFBID)
			.update({ LastFormStartedOnManagerAppFBID });
	}

	UpdateFormTemplateFormSubmit(FormTemplateFBID: string, LastFormSubmittedOnResourceAppFBID: string | null) {
		// This is needed so that if form submitted on the resource app, then in the form library on the resource app it will update the form library straight away
		// This is possible as the "onstream" is triggered by a change on the FormTemplates document
		this.baseQuery
			.collection('FormTemplates')
			.doc(FormTemplateFBID)
			.update({ LastFormSubmittedOnResourceAppFBID });
	}

	UpdateFormLibrary(FormTemplateFBID: string, FormFBID: string | null, CurrentFormDate: number | null) {
		// NOT NEEDED NOW AS WE GET THE CURRENT FORM FROM THE COLLECTION OF FORMS, NOT FROM THE FORMFBID FOR
		// THE FORM TEMPLATE
		// this.baseQuery
		// 	.collection('FormTemplates')
		// 	.doc(FormTemplateFBID)
		// 	.update({ FormFBID, CurrentFormDate });
	}

	getAssets() {
		return this.baseQuery.collection('Assets').orderBy('AssetName');
	}

	getAssetsPaginated(itemsPerPage: number, lastAsset: any = null, filters: any) {
		let query = this.baseQuery.collection('Assets').orderBy('AssetName');
		if (!isNullOrUndefined(filters['searchInput']) && filters['searchInput'] != '')
			query = query.where('AssetCode', '==', filters['searchInput']);
		if (!isNullOrUndefined(filters['assetClass']) && filters['assetClass'] != '')
			query = query.where('AssetClassFBID', '==', filters['assetClass']);
		if (!isNullOrUndefined(filters['assetSubClass']) && filters['assetSubClass'] != '')
			query = query.where('AssetSubClassFBID', '==', filters['assetSubClass']);
		if (!isNullOrUndefined(filters['site'])) query = query.where('SiteID', '==', filters['site']);
		if (!isNullOrUndefined(filters['location'])) query = query.where('LocationID', '==', filters['location']);
		if (!isNullOrUndefined(filters['subLocation'])) query = query.where('SubLocationID', '==', filters['subLocation']);
		if (lastAsset) query = query.startAfter(lastAsset);
		query = query.limit(itemsPerPage);

		return query;
	}

	getAssetsForSite(SiteID: any) {
		return this.baseQuery
			.collection('Assets')
			.where('SiteID', '==', SiteID)
			.orderBy('AssetName');
	}

	getAssetsForSitePaginated(SiteID: any, itemsPerPage: number, lastAsset?: any, filters?: any) {
		let query = this.baseQuery
			.collection('Assets')
			.where('SiteID', '==', SiteID)
			.orderBy('AssetName');
		if (filters['searchInput'] != '') query = query.where('AssetCode', '==', filters['searchInput']);
		if (!isNullOrUndefined(filters['assetClass']) && filters['assetClass'] != '')
			query = query.where('AssetClassFBID', '==', filters['assetClass']);
		if (!isNullOrUndefined(filters['assetSubClass']) && filters['assetSubClass'] != '')
			query = query.where('AssetSubClassFBID', '==', filters['assetSubClass']);
		if (!isNullOrUndefined(filters['site'])) query = query.where('SiteID', '==', filters['site']);
		if (!isNullOrUndefined(filters['location'])) query = query.where('LocationID', '==', filters['location']);
		if (!isNullOrUndefined(filters['subLocation'])) query = query.where('SubLocationID', '==', filters['subLocation']);
		if (lastAsset) query = query.startAfter(lastAsset);
		query = query.limit(itemsPerPage);

		return query;
	}

	async getAssetsCount() {
		let assetCount = 0;

		await this.baseQuery
			.collection('Assets')
			.orderBy('AssetName')
			.onSnapshot((querySnapshot: firebase.firestore.QuerySnapshot) => {
				assetCount = querySnapshot.size;
			});

		return assetCount;
	}

	async createAssetToJob(AssetJobobj: any, Guid: string) {
		let AssetName = '';
		let AssetClass = '';
		let AssetCode = '';
		let JobNumber = '';
		let JobDetails = '';
		let JobStatus = '';

		await this.baseQuery
			.collection('Assets')
			.doc(AssetJobobj.AssetFBID)
			.get()
			.then(async data => {
				AssetName = data.get('AssetName');
				AssetClass = data.get('AssetClass');
				AssetCode = data.get('AssetCode');
			});

		await this.baseQuery
			.collection('Jobs')
			.doc(AssetJobobj.JobFBID)
			.get()
			.then(data2 => {
				JobNumber = data2.get('JobNumber');
				JobDetails = data2.get('JobDetails');
				JobStatus = data2.get('JobStatus');
			});

		if (!isNullOrUndefined(JobNumber)) {
			//the job may not be created yet, so need to wait for the main app to send back down
			//we may need to add a simple job to begin with from newjobrequestscreen so it works ok when offline - WIP.
			await this.baseQuery
				.collection('AssetsToJobs')
				.doc(Guid) //.set(AssetJobobj);
				.set({
					JobFBID: AssetJobobj.JobFBID,
					AssetFBID: AssetJobobj.AssetFBID,
					DateCreated: Date.now(),
					AssetName: AssetName,
					AssetClass: AssetClass,
					AssetCode: AssetCode,
					JobNumber: JobNumber,
					JobDetails: JobDetails,
					JobStatus: JobStatus,
				});
		}

		return JobNumber;
	}

	async createAssetToForm(AssetFormobj: { AssetFBID: string, FormFBID: string, QuestionAnswerFBID: string }) {
		let AssetName = '';
		let AssetClass = '';
		let AssetCode = '';
		let FormName = '';
		let FormStatus = '';

		await this.baseQuery
			.collection('Assets')
			.doc(AssetFormobj.AssetFBID)
			.get()
			.then(async data => {
				AssetName = data.get('AssetName');
				AssetClass = data.get('AssetClass');
				AssetCode = data.get('AssetCode');
			});

		await this.baseQuery
			.collection('Forms')
			.doc(AssetFormobj.FormFBID)
			.get()
			.then(data => {
				FormName = data.get('FormName');
				FormStatus = data.get('Status');
			});

		await this.baseQuery
			.collection('Forms')
			.doc(AssetFormobj.FormFBID)
			.collection('QuestionAnswers')
			.doc(AssetFormobj.QuestionAnswerFBID)
			.update(
				{
					[`Assets.${AssetFormobj.AssetFBID}`]: {
						FormFBID: AssetFormobj.FormFBID,
						AssetFBID: AssetFormobj.AssetFBID,
						QuestionAnswerFBID: AssetFormobj.QuestionAnswerFBID,
						DateCreated: Date.now(),
						AssetName,
						AssetClass,
						AssetCode,
						FormName,
						FormStatus,
					}
				}
			);
	}

	async doesJobExist(JobFBID: string) {
		let doc = await this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get()
			.then(Record => {
				const job = Record.data() as JobTask.Job;
				if (isNullOrUndefined(job)) {
					return false;
				} else {
					if (job.JobNumber > 0) return true;
					else return false;
				}
			});
		return doc;
	}

	async doesAssetExist(AssetFBID: string) {
		return this.baseQuery
			.collection('Assets')
			.where('AssetFBID', '==', AssetFBID)
			.limit(1)
			.get()
			.then(Record => Record.docs.length > 0);
	}

	async doesLocationsExist(SiteID: number) {
		return this.baseQuery
			.collection('Locations')
			.where('SiteID', '==', SiteID)
			.limit(1)
			.get()
			.then(Record => Record.docs.length > 0);
	}

	async getQuestion(TemplateResponseFBID: string, QuestionFBID: string) {
		var get = await this.baseQuery
			.collection('Forms')
			.doc(TemplateResponseFBID)
			.collection('QuestionAnswers')
			.doc(QuestionFBID)
			.get();

		return get;
	}

	async doesSubLocationsExist(LocationID: number) {
		return this.baseQuery
			.collection('SubLocations')
			.where('LocationID', '==', LocationID)
			.limit(1)
			.get()
			.then(Record => Record.docs.length > 0);
	}

	async doesAssetToJobAlreadyExist(AssetFBID: string, JobFBID: string) {
		return this.baseQuery
			.collection('AssetsToJobs')
			.where('AssetFBID', '==', AssetFBID)
			.where('JobFBID', '==', JobFBID)
			.limit(1)
			.get()
			.then(Record => Record.docs.length > 0);
	}

	async getQuestionAnswer(FormFBID: string, QuestionAnswerFBID: string) {
		return this.baseQuery
			.collection('Forms')
			.doc(FormFBID)
			.collection('QuestionAnswers')
			.doc(QuestionAnswerFBID)
			.get();
	}

	async doesAssetToFormAlreadyExist(AssetFBID: string, FormFBID: string, QuestionAnswerFBID: string) {
		return this.getQuestionAnswer(FormFBID, QuestionAnswerFBID)
			.then(questionAnswer => {
				if (!questionAnswer.exists) {
					return false;
				}

				const questionAnswerData = questionAnswer.data();
				if (questionAnswerData === undefined) {
					return false;
				}

				// check if the asset fbid exists in the already attached assets on the question
				var assetExists = false;
				if (questionAnswerData.Assets && questionAnswerData.Assets.length > 0) {
					questionAnswerData.Assets.map(asset => {
						if (asset.AssetFBID == AssetFBID) {
							assetExists = true;
							return assetExists;
						}
					})
				}

				return assetExists;
			})
	}

	async getFormTemplateFBID(FormFBID: string): Promise<string> {
		return await this.baseQuery
			.collection('Forms')
			.doc(FormFBID)
			.get()
			.then(data => data.get('FormTemplateFBID'));
	}

	async getFormByFBID(FormFBID: string) {
		return await this.baseQuery
			.collection('Forms')
			.doc(FormFBID)
			.get()
	}

	async getJobNumber(JobFBID: string) {
		let data = '';

		await this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get()
			.then(data => {
				data = data.get('JobNumber');
			});

		return data;
	}

	async getJobLocation(JobFBID: string) {
		let site = '';

		await this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get()
			.then(data => {
				site = data.get('Site');
			});

		return site;
	}

	async getJobSiteID(JobFBID: string) {
		let siteID = 0;

		await this.baseQuery
			.collection('Jobs')
			.doc(JobFBID)
			.get()
			.then(data => {
				siteID = data.get('SiteID');
			});

		return siteID;
	}

	async getJobTaskLocation(JobTaskFBID: string) {
		let site = '';

		await this.baseQuery
			.collection('JobTasks')
			.doc(JobTaskFBID)
			.get()
			.then(data => {
				site = data.get('Site');
			});

		return site;
	}
	async CreateAsset(AssetObj: any, Guid: string) {
		return this.baseQuery
			.collection('Assets')
			.doc(Guid)
			.set(AssetObj);
	}

	async removeAttachedAsset(DocumentID: string) {
		return this.baseQuery
			.collection('AssetsToJobs')
			.doc(DocumentID)
			.delete();
	}

	async removeAttachedAssetFromForm(FormId: string, QuestionAnswerId: string, AssetFBID: string) {
		var parsedAsset;
		var asset = await this.baseQuery
			.collection('Forms')
			.doc(FormId)
			.collection('QuestionAnswers')
			.doc(QuestionAnswerId).get().then(asset => {
				parsedAsset = asset.data();
				if (parsedAsset && parsedAsset.Assets) {
					parsedAsset.Assets = parsedAsset.Assets.filter(asset => {
						if (asset.AssetFBID == AssetFBID)
							return false;
						else
							return true;
					})
				}
			});


		return this.baseQuery
			.collection('Forms')
			.doc(FormId)
			.collection('QuestionAnswers')
			.doc(QuestionAnswerId)
			.update({
				Assets: parsedAsset.Assets
			}).then(res => {
				let fbid = generateFirebaseId();
				this.postToJobQueue(fbid, {
					AssetFBID: AssetFBID,
					FormFBID: FormId,
					QuestionFBID: QuestionAnswerId,
					DateCreated: Date.now(),
					JobAction: 'FormAnswer',
					QuestionType: 'Asset',
					RemoveAsset: true
				});
			})
	}

	getNotificationsPaginated(itemsPerPage: number, lastAsset?: any, filters?: any) {
		let query = this.baseQuery.collection('JobTasks').where('AssignedResource', '==', this.currentUser.uid);

		// if (!isNullOrUndefined(filters['searchInput']) && filters['searchInput'] != '') {
		// 	query = query.where('Description', '>=', filters['searchInput']);
		// 	query = query.orderBy('Description', filters['order']);
		// }

		if (!isNullOrUndefined(filters['openClosed']) && filters['openClosed'] != '') {
			switch (filters['openClosed']) {
				case 'Open':
					query = query.where('IsClosed', '==', false);
					break;
				case 'Closed':
					query = query.where('IsClosed', '==', true);
					break;
			}
		}

		if (!isNullOrUndefined(filters['plannedReactive']) && filters['plannedReactive'] != '') {
			switch (filters['plannedReactive']) {
				case 'Planned':
					query = query.where('IsPlanned', '==', true);
					break;
				case 'Reactive':
					query = query.where('IsPlanned', '==', false);
					break;
			}
		}

		if (!isNullOrUndefined(filters['isDueToday']) && filters['isDueToday'])
			query = query
				.where('ExpectedDate', '>=', new Date().setHours(0, 0, 0, 0))
				.where('ExpectedDate', '<=', new Date().setHours(23, 59, 59, 999));

		if (!isNullOrUndefined(filters['site'])) query = query.where('SiteID', '==', filters['site']);

		if (!isNullOrUndefined(filters['order']) && filters['order'] != '')
			query = query.orderBy('ExpectedDate', filters['order']);

		if (lastAsset) query = query.startAfter(lastAsset);

		query = query.limit(itemsPerPage);

		query = this.baseQuery.collection('JobTasks').where('IsViewed', '==', false).where('AssignedResource', '==', this.currentUser.uid);

		return query;
	}

	async updateIsViewed(DocumentID: string) {

		return this.baseQuery
			.collection('JobTasks')
			.doc(DocumentID)
			.update({
				IsViewed: true
			});
	}

	async postSubscription(subscriptionObject: any, subscriptionID: string) {
		subscriptionObject['ResourceFBID'] = this.currentUser.uid;

		return this.baseQuery
			.collection('PushSubscriptions')
			.doc(subscriptionID)
			.set(subscriptionObject);
	}

	getSitesCollectionPaginated(itemsPerPage: number, lastSite?: any) {
		let query = this.baseQuery.collection('Sites').orderBy('SiteName', 'asc');
		if (lastSite) query = query.startAfter(lastSite);
		query = query.limit(itemsPerPage);

		return query;
	}

	async addQRLookup(QRCode, FBID, Type) {

		let check = true

		await this.baseQuery
			.collection('QRLookup')
			.where('QRCode', '==', QRCode)
			.get()
			.then(qrCodes => {
				if (qrCodes.size > 0) {
					check = false;
				}
				qrCodes.docs.map(qrCode => {
					//
				});
			});

		if (check) {
			this.baseQuery
				.collection('QRLookup')
				.add({
					QRCode: QRCode,
					Type: Type,
					LinkedFBID: FBID
				});
		}

		return check;
	}

	async getSiteInfoFromID(siteID) {
		return this.baseQuery
			.collection('Sites')
			.where("SiteID", "==", siteID)
			.limit(1)
			.get()
	}

	getSubLocationsCollectionPaginated(itemsPerPage: number, siteCode: number, lastResource?: any) {
		let query = this.baseQuery.collection('SubLocations').where("LocationID", "==", siteCode).orderBy('SubLocationName', 'asc');
		if (lastResource) query = query.startAfter(lastResource);
		query = query.limit(itemsPerPage);

		return query;
	}
	async getLocationInfoFromID(locationCode) {
		return this.baseQuery
			.collection('Locations')
			.where("LocationID", "==", locationCode)
			.limit(1)
			.get()
	}

	async getLocationInfo(locationFbid: string) {
		return this.baseQuery
			.collection('Locations')
			.withConverter(fireLocationConverter)
			.doc(locationFbid)
			.get();
	}

	async getSubLocationInfo(subLocationFbid: string) {
		return this.baseQuery
			.collection('SubLocations')
			.doc(subLocationFbid)
			.get();
	}

	async QRSearch(input) {
		return this.baseQuery
			.collection('QRLookup')
			.where('QRCode', '==', input)
			.get();
	}

	async getSiteInfo(siteFBID) {
		return this.baseQuery
			.collection('Sites')
			.doc(siteFBID)
			.get();
	}

	getLocationsCollectionPaginated(itemsPerPage: number, siteFBID: string, lastResource?: any) {
		let query = this.baseQuery.collection('Locations').where("SiteFBID", "==", siteFBID).orderBy('LocationName', 'asc');
		if (lastResource) query = query.startAfter(lastResource);
		query = query.limit(itemsPerPage);

		return query;
	}

	async InitializeResourceClient(UserID: string, UserEmail: string) {
		let userID = "";
		let clientID = "";

		await this.db
			.collection(`ResourcesToClient`)
			.where("Email", "==", UserEmail)
			.limit(1)
			.get()
			.then(users => {
				users.docs.forEach(user => {
					userID = user.id;
					const data = user.data();
					clientID = data.ClientUID;
				});
			});

		await this.db
			.collection('ResourcesToClient')
			.doc(userID)
			.update({
				UserUID: UserID
			});

		return clientID;
	}

	async getResourcesToClients(UserEmail: string) {
		return this.db
			.collection('ResourcesToClients')
			.where('Email', '==', UserEmail)
			.limit(1)
			.get()
			.then(sites => {
				sites.docs.forEach(site => {
					const data = site.data();
				});
			});
	}

	async getServerName(ClientUID: string) {
		return this.db
			.collection('Clients')
			.doc(ClientUID)
			.get();

	}

	async getResourceName(UserUID: string) {
		return this.db
			.collection('ResourcesToClients')
			.where('UserUID', '==', UserUID)
			.limit(1)
			.get();
	}

	async updateResourceAvailability(statusID: string) {
		const userData = this.db
			.collection('ResourcesToClient')
			.where('UserUID', '==', this.resourceFBID)
			.limit(1)
			.get();

		await userData.then(async userSettings => {
			if (userSettings.docs.length > 0 && userSettings.docs[0].exists) {
				await this.db.doc(`ResourcesToClient/${userSettings.docs[0].id}`).update({ ResourceAvailabilityStatus: statusID });
				return;
			}
		});
	}

	async isAssetCodeDuplicate(assetCode: any, assetFBID: any) {
		if (assetCode == null) {
			return false;
		};

		var isDuplicate = false;

		await this.baseQuery
			.collection('Assets')
			.where('AssetCode', '==', assetCode)
			.get()
			.then(async assets => {
				if (await assets.docs.length == 1) {
					await assets.docs.forEach(async asset => {
						if (await asset.data().AssetFBID == assetFBID) {
							isDuplicate = false;
						}
						else
							isDuplicate = true;
					});
				};
				if (assets.docs.length > 1)
					isDuplicate = true;
			});
		return isDuplicate;
	};

	async isQRDuplicate(QRCode: any, LinkedFBID: string) {
		if (QRCode == null || QRCode == "") {
			return false;
		};

		var isDuplicate = false;

		await this.baseQuery
			.collection('QRLookup')
			.where('QRCode', '==', QRCode)
			.get()
			.then(async qrcodes => {
				if (await qrcodes.docs.length == 1) {
					await qrcodes.docs.forEach(async qrcode => {
						if (await qrcode.data().LinkedFBID == LinkedFBID) {
							isDuplicate = false;
						}
						else
							isDuplicate = true;
					});
				};
				if (qrcodes.docs.length > 1)
					isDuplicate = true;
			});
		return isDuplicate;
	};

	async getSubLocationInfoFromID(sublocationID) {
		return this.baseQuery
			.collection('SubLocations')
			.where("SubLocationID", "==", sublocationID)
			.limit(1)
			.get()
	};

	getAssetStatusPaginated = (items: number, lastStatus?: any) => {
		let query = this.baseQuery.collection('Statuses').orderBy('StatusDescription')

		if (lastStatus) query = query.startAfter(lastStatus);
		query = query.limit(items);
		return query;
	};

	getSiteTypesPaginated = (items: number, lastSiteType?: any) => {
		let query = this.baseQuery.collection('SiteTypes').orderBy('SiteTypeName')

		if (lastSiteType) query = query.startAfter(lastSiteType);
		query = query.limit(items);
		return query;
	};

	getRegionsPaginated = (items: number, lastRegion?: any) => {
		let query = this.baseQuery.collection('Regions').orderBy('RegionName')

		if (lastRegion) query = query.startAfter(lastRegion);
		query = query.limit(items);
		return query;
	};

	getContractsPaginated = (items: number, lastContract?: any) => {
		let query = this.baseQuery.collection('Contracts').orderBy('ContractName')

		if (lastContract) query = query.startAfter(lastContract);
		query = query.limit(items);
		return query;
	};

	getLanguages = (lastLanguage?: any) => {
		console.log("This ran getLanguages");
		let query = this.db.collection('Languages').orderBy('Location', 'asc');
		if (lastLanguage)
			query = query.startAfter(lastLanguage);

		query = query.limit(20);
		return query;
		// return this.db
		// 	.collection("Languages")
		// 	.orderBy("Location", "asc")
		// 	.limit(20)
	};

	async updateUserLanguage(userUID: string | undefined, newLanguage: string, newCulture: string) {
		if (newCulture == "" || isNullOrUndefined(newCulture))
			newCulture = "en";
		if (newLanguage == "" || isNullOrUndefined(newLanguage))
			newLanguage = "English;"

		return this.db
			.collection("ResourcesToClient")
			.where("UserUID", "==", this.currentUser.uid)
			.limit(1)
			.get().then(userRecord => {
				var userDocument = userRecord.docs[0].id;
				this.db.collection("ResourcesToClient").doc(userDocument).update({ "Language": newLanguage, "Culture": newCulture });
			})
	};

	async getUserLanguage(userUID: string | undefined) {
		return this.db
			.collection("ResourcesToClient")
			.where("UserUID", "==", userUID)
			.limit(1)
			.get();
	};

	async getLanguage(languageLabel: string | undefined) {
		return this.db
			.collection("Languages")
			.where("LanguageLabel", "==", languageLabel)
			.limit(1)
			.get();
	};

	getStorageAreasCollectionPaginated(itemsPerPage: number, lastStorageArea?: any, filters?: any) {
		let query = this.baseQuery.collection('StorageAreas').limit(itemsPerPage);
		if (filters) {
			// if (filters.search !== '') query = query.where('?', '==', filters.search);
			if (filters.SiteFBID) query = query.where('SiteFBID', '==', filters.SiteFBID);
			if (filters.LocationFBID) query = query.where('LocationFBID', '==', filters.LocationFBID);
			if (filters.SubLocationFBID) query = query.where('SubLocationFBID', '==', filters.SubLocationFBID);
		}
		query = query.orderBy('StorageName', 'asc')
		if (lastStorageArea) query = query.startAfter(lastStorageArea);
		return query;
	}

	async UpdateQRCode(Collection: string, DocumentID: string, QR: string) {
		return this.baseQuery
			.collection(Collection)
			.doc(DocumentID)
			.update({
				QRCode: QR
			});
	}

	async RemoveCostRowQuote(QuoteID: string, DocumentID: string) {
		return this.baseQuery
			.collection('Quotes')
			.doc(QuoteID)
			.collection('CostRow')
			.doc(DocumentID)
			.delete();
	}

	async getSelectOptions(
		Input: string,
		Collection: string,
		NameProperty: string,
		OrderDirection: 'desc' | 'asc',
		DependsOn: any,
		DependsOnField: string | undefined,
	) {
		if (DependsOn && DependsOnField) {
			return this.baseQuery
				.collection(Collection)
				.where(DependsOnField, '==', DependsOn.fields[DependsOnField])
				.where(NameProperty, '>=', Input)
				.where(NameProperty, '<=', Input + '\uf8ff')
				.orderBy(NameProperty, OrderDirection)
				.get();
		} else {
			return this.baseQuery
				.collection(Collection)
				.where(NameProperty, '>=', Input)
				.where(NameProperty, '<=', Input + '\uf8ff')
				.orderBy(NameProperty, OrderDirection)
				.get();
		}
	}

	async getQRLookup(QRLookupFBID: string) {
		return this.db
			.collection('QRLookup')
			.doc(QRLookupFBID)
			.get();
	}


	getFormsForSite(SiteFBID: string) {
		return this.baseQuery.collection('Forms')
			.where('SiteFBID', '==', SiteFBID)
			.where('JobTaskFBID', '==', null)
			.where('JobFBID', '==', null)
			.where('AssetFBID', '==', null)
			.where('LocationFBID', '==', null)
			.where('SublocationFBID', '==', null)
	}

	getFormsForLocation(LocationFBID: string) {
		return this.baseQuery.collection('Forms').where('LocationFBID', '==', LocationFBID);
	}

	getFormsForSublocation(SublocationFBID: string) {
		return this.baseQuery.collection('Forms').where('SublocationFBID', '==', SublocationFBID);
	}

	getFormsForAsset(AssetFBID: string) {
		return this.baseQuery.collection('Forms')
			.where('AssetFBID', '==', AssetFBID)
			.where('JobTaskFBID', '==', null)
			.where('JobFBID', '==', null)
	}

	async getAvailableForms() {
		return this.baseQuery
			.collection('FormTemplates')
			.where('Scope', '==', 'JobForm')
			.orderBy('FormName')
			.get()
	}

	getFormsForLoadedData = (itemIDs, formFieldRef, fbCollection: string): Promise<any> => {
		return new Promise((resolve, reject) => {

			let query = this.baseQuery.collection('Forms')
				.where(`${formFieldRef}`, 'in', itemIDs);

			if (fbCollection === "Site") {
				query = query.where('JobTaskFBID', '==', null)
					.where('JobFBID', '==', null)
					.where('AssetFBID', '==', null)
					.where('LocationFBID', '==', null)
					.where('SublocationFBID', '==', null);
			}

			if (fbCollection === "Asset") {
				query = query
					.where('JobTaskFBID', '==', null)
					.where('JobFBID', '==', null);
			}

			query.get()
				.then((querySnapshot) => {
					if (querySnapshot.empty) {
						resolve([]);
					} else {
						querySnapshot.forEach(documentRes => {
							documentRes.ref.collection('QuestionAnswers')
								.onSnapshot(async doc => {
									for (const docItem of doc.docs) {
										let formatedData;
										formatedData = await docItem.data() as Forms.QuestionAnswer;
										formatedData.Id = await docItem.id;
										formatedData.questionRef = docItem.ref;
										if (formatedData.QuestionType === 'Photo') {
											await idb.getPhotos(formatedData.Id)
											await this.getQuestionAnswerPhotoCollection(formatedData.questionRef.path)
												.get()
												.then(async res => {
													for (const formDoc of res.docs) {
														const photo = formDoc.data() as JobTask.JobTaskPhoto;
														await idb.downloadQuestionAnswerPhotoFromFirebase(
															!isNullOrUndefined(photo.FirebaseStoragePath)
																? photo.FirebaseStoragePath
																: (photo.AzureUrl as string),
															formatedData.Id,
															!isNullOrUndefined(photo.Guid) ? (photo.Guid as string) : generateGuid(),
															formDoc.id,
															photo.Filename as string,
															"true",
															documentRes.id,
															fbCollection
														);
													}
												})
										}
									};
								})
						})
						resolve(querySnapshot)
					}
				}).catch(err => reject(err))
		})
	}

	getLocationsForLoadedSite = (siteFBIDs): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery.collection('Locations')
				.where('SiteFBID', 'in', siteFBIDs)
				.get()
				.then((res) => {
					const locations = res && res.docs.map(_loc => ({
						..._loc.data(),
						Id: _loc.id
					}))
					resolve(locations);
				})
		})
	}

	getSubLocationsForLoadedSite = (locationFBIds): Promise<any> => {
		return new Promise((resolve, reject) => {
			this.baseQuery.collection('SubLocations')
				.where('LocationID', 'in', locationFBIds)
				.get()
				.then((res) => {
					const subLocations = res && res.docs.map(_loc => ({
						..._loc.data(),
						Id: _loc.id
					}))
					resolve(subLocations);
				})
		})
	}


	// private getFormHeader(formFBID: string) {
	// 	// return this.db.doc(`Forms/${formFBID}`).get();
	// 	return this.baseQuery.collection('Forms').doc(formFBID);
	// }

	// Firebase Notifications
	// registerServiceWorker = () => {
	// 	navigator.serviceWorker
	// 		.getRegistration()
	// 		.then(registration => {
	// 			if (registration !== undefined && this.messaging !== undefined) {
	// 				this.messaging.useServiceWorker(registration);
	// 				this.watchForTokenRefresh();
	// 				this.handleMessage();
	// 				this.handleNotifications();
	// 			}
	// 		})
	// 		.catch(err => console.error(`Error getting registration ${err}`));
	// };

	// private handleNotifications = () => {
	// 	if (this.messaging !== undefined) {
	// 		this.messaging
	// 			.requestPermission()
	// 			.then(() => {
	// 				if (this.messaging !== undefined) {
	// 					this.messaging
	// 						.getToken()
	// 						.then(token => {
	// 							this.messagingToken = token;
	// 							this.postTokenToServer(token);
	// 						})
	// 						.catch(err => console.error('Could not get token.' + err));
	// 				}
	// 			})
	// 			.catch(err => console.error('Cannot show notifications, notification permission was denied.' + err));
	// 	}
	// };

	// private handleMessage() {
	// 	if (this.messaging !== undefined) {
	// 		this.messaging.onMessage(payload => {
	// 			const notificationTitle = payload.notification.title;
	// 			const notificationOptions = {
	// 				body: payload.notification.body,
	// 				icon: '/logo.png',
	// 				badge: '/badge.png',
	// 				data: payload.data,
	// 			};
	// 			navigator.serviceWorker.getRegistration().then(registration => {
	// 				if (registration !== undefined) {
	// 					registration.showNotification(notificationTitle, notificationOptions);
	// 				}
	// 			});
	// 		});
	// 	}
	// }

	// private watchForTokenRefresh() {
	// 	if (this.messaging !== undefined) {
	// 		this.messaging.onTokenRefresh(() => {
	// 			if (this.messaging !== undefined) {
	// 				this.messaging
	// 					.getToken()
	// 					.then(token => {
	// 						this.postTokenToServer(token);
	// 					})
	// 					.catch(err => console.error(err));
	// 			}
	// 		});
	// 	}
	// }

	// postTokenToServer(messagingToken: string | null) {
	// 	if (
	// 		this.messagingToken !== null &&
	// 		this.settings !== undefined &&
	// 		this.messagingToken !== this.settings.MessageToken
	// 	) {
	// 		console.log('Posting Token', messagingToken);
	// 		const messagingQueueObj = {
	// 			JobAction: 'SaveMessagingToken',
	// 			MessagingToken: messagingToken,
	// 			UID: this.resourceFBID,
	// 			ServerName: this.settings !== undefined ? this.settings.ServerName : '',
	// 		};
	// 		this.getResourceSettings().update({ MessageToken: messagingToken });
	// 		this.postToJobQueue('', messagingQueueObj);
	// 	}
	// }

	// async deleteMessagingToken() {
	// 	if (
	// 		this.messagingToken !== null &&
	// 		this.settings !== undefined &&
	// 		this.messagingToken === this.settings.MessageToken
	// 	) {
	// 		console.log('Deleting messaging token');
	// 		const messagingQueueObj = {
	// 			JobAction: 'DeleteMessagingToken',
	// 		};
	// 		this.getResourceSettings().update({ MessageToken: null });
	// 		return this.postToJobQueue('', messagingQueueObj);
	// 	}
	// 	return Promise.resolve();
	// }

	async getExtraFormDetails(form: Forms.Form): Promise<Forms.ExtraFormDetails> {
		let assetName: string | null = null;
		let siteName: string | null = null;
		let subLocationName: string | null = null;
		let locationName: string | null = null;
		if (form.AssetFBID) {
			const assetDoc = (
				await this.baseQuery
					.collection('Assets')
					.doc(form.AssetFBID)
					.withConverter(fireAssetConverter)
					.get()
			).data();
			assetName = assetDoc ? assetDoc.AssetName : '';
		}
		if (form.SiteFBID) {
			const siteDoc = (
				await this.baseQuery
					.collection('Sites')
					.doc(form.SiteFBID)
					.withConverter(fireAssetConverter)
					.get()
			).data();
			siteName = siteDoc ? siteDoc.SiteName : '';
		}
		if (form.LocationFBID) {
			const locationDoc = (
				await this.baseQuery

					.collection('Locations')
					.doc(form.LocationFBID)
					.withConverter(fireAssetConverter)
					.get()
			).data();
			locationName = locationDoc ? locationDoc.LocationName : '';
		}
		if (form.SublocationFBID) {
			const subLocationDoc = (
				await this.baseQuery
					.collection('SubLocations')
					.doc(form.SublocationFBID)
					.withConverter(fireAssetConverter)
					.get()
			).data();
			subLocationName = subLocationDoc ? subLocationDoc.SubLocationName : '';
		}
		return {
			...form,
			AssetName: assetName,
			SiteName: siteName,
			LocationName: locationName,
			SubLocationName: subLocationName,
		};
	}
}
