import { Icon, IconButton } from '@material-ui/core';
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Search Pagination for Sites
import { AsyncPaginate, LoadOptions } from 'react-select-async-paginate';

import { isNullOrUndefined } from 'util';
import { useReactSelectAsyncPaginate } from '../../../hooks/useReactSelectAsyncPaginate';
import ClearIndicator from './ClearIndicator';
import { ReactSelectLocationOptionType } from './LocationDropdown';
import { ReactSelectSiteOptionType } from './SiteDropdown';

/**
 * Describe options type for the React Select Async Paginate component.
 */
export type ReactSelectSublocationOptionType<T = number> = {
	value: T;
	label: string;
};

export type AsyncPaginateSublocationDropdownProps<OptionValueType, AdditionalType> = {
	subLocationFilter?: ReactSelectSublocationOptionType<OptionValueType> | null;
	isClearable?: boolean;
	onClear?: () => void;
	handleSubLocationFilter: (option: ReactSelectSublocationOptionType<OptionValueType> | null) => void;
	siteFilter?: ReactSelectSiteOptionType<OptionValueType> | null;
	siteInputValue?: string;
	locationFilter?: ReactSelectLocationOptionType<OptionValueType> | null;
	locationLabelValue?: string;
	subLocationInputValue?: string;
	loadSubLocations: LoadOptions<ReactSelectSublocationOptionType<OptionValueType>, AdditionalType>;
	subLocationDisabled?: boolean;
};

/**
 * A dropdown, used by AssetDetails and CreateNewForm modal, to select a sublocation
 * @template OptionValueType The type of the value of the option, normally a number or a string. Should contains a sublocation id or a sublocation fbid
 * @template AdditionalType The type of the additional data passed to loadOptions, such as a pagination cursor
 */
export function AsyncPaginateSubLocationsDropdown<OptionValueType, AdditionalType>(
	props: AsyncPaginateSublocationDropdownProps<OptionValueType, AdditionalType>,
) {
	const [t, _i18n] = useTranslation();
	// close the dropdown when it is disabled
	const [{ isMenuOpen, selectRef, showKeyboard }, { openMenu, closeMenu }] = useReactSelectAsyncPaginate(
		props.subLocationDisabled,
	);

	return (
		<AsyncPaginate
			id="sublocation-helper"
			value={props.subLocationFilter}
			selectRef={selectRef}
			debounceTimeout={300}
			onFocus={openMenu}
			onBlur={closeMenu}
			menuIsOpen={isMenuOpen}
			onChange={(e, meta) => {
				if (meta.action === 'clear' && props.onClear) {
					props.onClear();
				}
				props.handleSubLocationFilter(e);
				closeMenu();
			}}
			blurInputOnSelect
			loadOptions={props.loadSubLocations}
			loadingMessage={() => t('Loading Sites...')}
			cacheUniqs={[
				props.siteFilter,
				props.siteInputValue,
				props.locationFilter,
				props.locationLabelValue,
				props.subLocationInputValue,
				props.subLocationFilter,
			]}
			isDisabled={props.subLocationDisabled}
			noOptionsMessage={() => t('No Sub Locations Found')}
			isSearchable={!showKeyboard}
			isClearable={props.isClearable}
			components={{
				ClearIndicator,
			}}
		/>
	);
}

export default function SublocationDropdown<OptionValueType = number, AdditionalType = undefined>({
	subLocationFilter,
	handleSubLocationFilter,
	siteFilter,
	siteInputValue,
	locationFilter,
	locationLabelValue,
	subLocationInputValue,
	loadSubLocations,
	subLocationDisabled = false,
	clearSelectedSitesOrLocations,
}: AsyncPaginateSublocationDropdownProps<OptionValueType, AdditionalType> & {
	clearSelectedSitesOrLocations: (typeId: 1 | 2 | 3) => void;
}) {
	const [t, i18n] = useTranslation();

	return (
		<div style={{ flex: '1' }}>
			<AsyncPaginateSubLocationsDropdown<OptionValueType, AdditionalType>
				subLocationFilter={subLocationFilter}
				handleSubLocationFilter={handleSubLocationFilter}
				siteFilter={siteFilter}
				siteInputValue={siteInputValue}
				locationFilter={locationFilter}
				locationLabelValue={locationLabelValue}
				subLocationInputValue={subLocationInputValue}
				loadSubLocations={loadSubLocations}
				subLocationDisabled={subLocationDisabled}
			/>
			<br />
			<p className="field-label-alt">
				{t('Sublocation')}{' '}
				{!isNullOrUndefined(subLocationFilter) && (
					<IconButton onClick={() => clearSelectedSitesOrLocations(3)} style={{ cursor: 'pointer' }}>
						<Icon color="error">delete_forever</Icon>
					</IconButton>
				)}
			</p>
			<br />
		</div>
	);
}
