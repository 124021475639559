import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import { connect } from 'react-redux';

// Fire
import { fire } from '../../index';

// Shared Components
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';

// Material UI
import { Grid } from '@material-ui/core';
import { Icon, Button } from '@material-ui/core';
import styled from 'styled-components';

import { unixToDateString } from '../../utils/Times';

import AddQuoteCostRowModal from '../../components/job-quote/AddQuoteCostRowModal';
import EditQuoteCostRowModal from '../../components/job-quote/EditQuoteCostRowModal';
import { isNullOrUndefined } from 'util';
import { withTranslation } from 'react-i18next';


interface JobQuoteProps {
    history: History;
    location: Location;
    match: match;
    t: any;
}

interface JobQuoteState {
    addQuoteCostRowOpen: boolean;
    editQuoteCostRowOpen: boolean;
    costRows: JobQuote.CostRow[] | null;
    editCostRowObject: JobQuote.CostRow;
    JobQuoteFBID: string;
    jobQuoteDetails: JobQuote.JobQuote | null;
    loading: boolean;
}

class JobQuote extends React.Component<JobQuoteProps, JobQuoteState> {
    constructor(props) {
        super(props);
        this.state = {
            addQuoteCostRowOpen: false,
            editQuoteCostRowOpen: false,
            costRows: null,
            editCostRowObject: {
                Description: '',
                Labour: '',
                Materials: '',
                CallOut: '',
                JobQuoteFBID: '',
                Id: ''
            },
            JobQuoteFBID : '',
            jobQuoteDetails: null,
            loading: false, 
        }
    }

    componentDidMount() {
        this.setState({ JobQuoteFBID: this.props.match.params.id }, () => {
            this.getJobQuote();
            this.getCostRows();
        });
    }
    

    getCostRows = () => {
        const docId = this.state.JobQuoteFBID
        fire.baseQuery.collection(`Quotes/${docId}/CostRow`).onSnapshot(snap => {
            const rows = snap.docs.map(doc => {
                const row = doc.data() as JobQuote.CostRow;
                row.Id = doc.id;
                return row;
            });
            this.setState({ costRows: rows });
        })
    }

    closeAddCostRowModal = () => {
        this.setState({ addQuoteCostRowOpen: !this.state.addQuoteCostRowOpen })
    }

    closeEditCostRowModal = () => {
        this.setState({ editQuoteCostRowOpen: !this.state.editQuoteCostRowOpen });
    }

    addCostRow = () => {
        this.setState({ addQuoteCostRowOpen: true });
    }

    editCostRow = (row: JobQuote.CostRow): void => {
        this.setState({ editQuoteCostRowOpen: true, editCostRowObject: row as JobQuote.CostRow });
    }

    deleteCostRow = (row) => {
        const confirm = window.confirm(this.props.t("Are you sure you want to delete the Cost Row?"));

        if(confirm) {
            fire.RemoveCostRowQuote(this.state.JobQuoteFBID, row.Id);
        
            const costRowObj = {
                JobAction: 'QuoteCostRowDelete',
                JobQuoteFBID: this.state.JobQuoteFBID,
            }

            fire.postToJobQueue(row.Id, costRowObj).catch(err => {
                alert(this.props.t('There was an error removing cost row for job quote. If problem persists please email - support@trackplanfm.com'));
                console.log(err);
            });
        }
    }

    calculateTotal = (row) => {
        const totalCost = (+row.Materials) + (+row.Labour) + (+row.CallOut);
        return totalCost.toFixed(2);
    }

    getJobQuote = () => {
        this.setState({ loading: true });

        fire.baseQuery
            .collection('Quotes')
            .doc(this.state.JobQuoteFBID)
            .onSnapshot((snap) => {
                const quoteData = snap.data() as JobQuote.JobQuote;
                quoteData.Id = snap.id;
                this.setState({ 
                    jobQuoteDetails: quoteData,
                });
            });

        this.setState({ loading: false });
    }

    submitQuote = () => {
        if(this.state.jobQuoteDetails !== null && !isNullOrUndefined(this.state.costRows) && this.state.costRows.length > 0) {
            const confirm = window.confirm(this.props.t("Are you sure you want to submit the quote?"));

            if(confirm) {

                const { jobQuoteDetails } = this.state;
                const submittedDateTime = new Date().getTime();

                jobQuoteDetails['Status'] = "Submitted";

                fire.baseQuery.collection('Quotes').doc(jobQuoteDetails.Id).update({
                    Status: "Submitted",
                    SubmittedDate: submittedDateTime
                });
            
                const jobQuoteObj = {
                    JobAction: 'QuoteSubmit',
                    JobQuoteFBID: this.state.JobQuoteFBID
                }

                fire.postToJobQueue(this.state.JobQuoteFBID, jobQuoteObj).catch(err => {
                    alert(this.props.t('There was an error submitting the job quote. If problem persists please email - support@trackplanfm.com'));
                    console.log(err);
                });

                this.getJobQuote();
            }
        } else {
            alert(this.props.t('Job Quote cannot be Submitted without Having Cost Rows!'));
        }
    }

    backButton = () => {
        if(this.state.jobQuoteDetails !== null) {

            if(!isNullOrUndefined(this.props.history.location.state) &&  this.props.history.location.state.from === 'QuotesList') {
                this.props.history.replace(`/job-quotes-list/${this.state.jobQuoteDetails.JobTaskFBID}`);
            } else {
                this.props.history.replace(`/job-task-details/${this.state.jobQuoteDetails.JobTaskFBID}`);
            }

        }
    }

    render() {
        if(this.state.jobQuoteDetails !== null) {
            const { jobQuoteDetails, costRows } = this.state;
            const titleOne = this.state.jobQuoteDetails.JobQuoteNumber.toString() === '0-001' ? this.props.t('Job Quote') : this.props.t('Job Quote No.');
			const titleTwo =
				this.state.jobQuoteDetails.JobQuoteNumber.toString() === '0-001' ? this.props.t('Not Synced') : `${this.state.jobQuoteDetails.JobQuoteNumber}`;

            return (

                <div>
                    <SideDrawer
                        history={this.props.history}
                        title={titleOne}
                        title2={titleTwo}
                        rightMenuButton={
                            <div>
                                <BackButton callbackMethod={this.backButton} />
                            </div>
                        }
                    />

                    <Grid container={true} direction="column" justify="center" alignItems="center" style={{ marginTop: '80px' }}>
						<div className="main">
                            <div>
                                <div className="middle-row" style={{ padding: 20 }}>
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Description") + ": "}</p>
                                        <p className="content">{ jobQuoteDetails.Description }</p>
                                    </div>
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Reference") + ": "}</p>
                                        <p className="content">{ jobQuoteDetails.Reference }</p>
                                    </div>
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Site Name") + ":"}</p>
                                        <p className="content">{ jobQuoteDetails.SiteName }</p>
                                    </div>
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Requested Date") + " "}</p>
                                        <p className="content">{ unixToDateString(jobQuoteDetails.RequestedDate) }</p>
                                    </div>
                                    <br /><br />
                                    <div className="content-container">
                                        <p className="header">{this.props.t("Status")}</p>
                                        <p className="content">{this.props.t(jobQuoteDetails.Status)}</p>
                                    </div>
                                </div>

                                <Grid container alignItems="flex-start" justify="flex-end" direction="row" style={{ marginBottom: '10px', columnGap: '10px' }}>
                                    <Button color="primary" disabled={jobQuoteDetails.Status !== 'Pending'} onClick={this.addCostRow} variant="contained">
                                        <Icon>add</Icon>&nbsp; {this.props.t("Cost Row")}
                                    </Button>
                                    <Button style={jobQuoteDetails.Status === 'Pending' ? ({ marginRight: '20px', backgroundColor: 'orange', color: 'white' }) : ({ marginRight: '20px' })} disabled={jobQuoteDetails.Status !== 'Pending'} onClick={this.submitQuote} variant="contained">
                                        <Icon>assignment_turned_in</Icon>&nbsp; {this.props.t("Submit Quote")}
                                    </Button>
                                </Grid>

                                <AddQuoteCostRowModal jobQuote={jobQuoteDetails} open={this.state.addQuoteCostRowOpen} jobTaskID={jobQuoteDetails.JobTaskFBID} jobQuoteFBID={this.state.JobQuoteFBID} closeModal={this.closeAddCostRowModal} />
                                <EditQuoteCostRowModal 
                                    open={this.state.editQuoteCostRowOpen} 
                                    costRow={this.state.editCostRowObject} 
                                    closeModal={this.closeEditCostRowModal} 
                                    jobQuoteFBID={this.state.JobQuoteFBID} 
                                />
                                
                                <div className="header" style={{ padding: 20 }}>{this.props.t("Job Quote Cost Rows")}</div>
                                
                                {costRows !== null && costRows.length > 0 ? (
                                    
                                    costRows.map((row, i) => (
                                        <div key={i} id="details-outer" style={{ marginTop: 10 }}>
                                            <QuoteCard className="card-shadow">
                                                <TopRow>
                                                    <HeaderContainer style={{ width:'80%', marginRight: 'auto' }}>
                                                        <Content style={{ wordWrap: 'break-word' }}>
                                                            { row.Description }
                                                        </Content>
                                                        <Label>{this.props.t("Description")}</Label>
                                                    </HeaderContainer>
                                                    {jobQuoteDetails.Status === 'Pending' && (
                                                        <HeaderContainer>
                                                            <Content>
                                                                <Button color="primary" onClick={() => this.editCostRow(row)} style={{ minWidth: 'max-content', margin: 0, padding: 5 }}>
                                                                    <Icon>edit</Icon>
                                                                </Button>
                                                                <Button color="secondary" onClick={() => this.deleteCostRow(row)} style={{ minWidth: 'max-content', margin: 0, padding: 5 }}>
                                                                    <Icon>delete</Icon>
                                                                </Button>
                                                            </Content>
                                                        </HeaderContainer>
                                                    )}
                                                </TopRow>
                                                
                                                <BottomRow>
                                                    <HeaderContainer>
                                                        <Content>{ parseFloat(row.Materials).toFixed(2) }</Content>
                                                        <Label>{this.props.t("Materials")}</Label>
                                                    </HeaderContainer>
                                                    <HeaderContainer>
                                                        <Content>{ parseFloat(row.Labour).toFixed(2) }</Content>
                                                        <Label>{this.props.t("Labour")}</Label>
                                                    </HeaderContainer>
                                                    <HeaderContainer>
                                                        <Content>{ parseFloat(row.CallOut).toFixed(2) }</Content>
                                                        <Label>{this.props.t("Other")}</Label>
                                                    </HeaderContainer>
                                                    <HeaderContainer>
                                                        <Content>{ this.calculateTotal(row) }</Content>
                                                        <Label>{this.props.t("Total Cost")}</Label>
                                                    </HeaderContainer>
                                                </BottomRow>
                                            </QuoteCard>
                                        </div>
                                    ))
                                ) : (
                                    <Button id="outer-card-button" variant="outlined" color="primary" size="large" fullWidth disabled>
                                        {this.props.t("No Job Quote Rows Yet!")}
                                    </Button>   
                                )}
                            </div>
                        </div>
                    </Grid>
                </div>
            )
        }
        return <LoadingSpinner color="primary" text={this.props.t("Loading Job Quote")} />
    }
}

const QuoteCard = styled.div`
	display: flex;
	flex-direction: column;
`;

const TopRow = styled.div`
    display: flex;
    flex-direction: row;
    padding: 7.5px;
`;

const TopContent = styled.p`
	font-size: 14px;
	color: #000000;
	margin-bottom: 5px;
`;

const BottomRow = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-column-gap: 5px;
	grid-row-gap: 7px;
	padding: 7.5px;
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Label = styled.p`
	font-size: 14px;
	color: var(--dark-blue);
`;

const Content = styled.p`
	font-size: 14px;
	margin-bottom: 5px;
`;

const mapStateToProps = (state: Store.Store) => ({
    UserSettings: state.User.UserSettings
});

export default withTranslation()(connect(mapStateToProps)(JobQuote));