import AppBar from '@material-ui/core/AppBar';
import {
	Divider,
	Icon,
	IconButton,
	List,
	ListItem,
	ListItemIcon,
	ListItemText,
	SwipeableDrawer,
	Toolbar,
} from '@material-ui/core';
import imgLogo from '../../assets/images/logo.png';
import dimoLogo from '../../assets/images/dimologo.png';
import { History } from 'history';
import * as React from 'react';
import { connect } from 'react-redux';
import { isNullOrUndefined } from 'util';
import Dexie from 'dexie';

import '../../styles/side-drawer/side-drawer.css';
import { useTranslation, withTranslation } from 'react-i18next';

interface SideDrawerProps {
	history: History;
	title: string;
	rightMenuButton?: JSX.Element;
	User: Store.UserState;
	title2?: string;
	t: any;
}

interface SideDrawerState {
	DrawerOpen: boolean;
	indexedDBVersion: number;
	logoImage: string;
}

class SideDrawer extends React.Component<SideDrawerProps, SideDrawerState> {
	constructor(props) {
		super(props);
		this.state = {
			DrawerOpen: false,
			indexedDBVersion: 0,
			logoImage: imgLogo
		};
	}

	updateDrawerOpen() {
		this.setState({ DrawerOpen: !this.state.DrawerOpen });
	}

	async componentDidMount() {
		await new Dexie('TrackplanContractorDB').open().then(async db => {
			this.setState({ indexedDBVersion: db.verno });
		}).catch(err => {
			window.location.reload();
		})
		
		this.checkIfDimo();
	}

	checkIfDimo() {
		var url = window.location.href;
		if (url.includes("appres.trackplanfm.com")) {
			this.setState({ logoImage: dimoLogo });
		}
	}

	render() {
		const { UserSettings } = this.props.User;
		const titleTwoExists = !isNullOrUndefined(this.props.title2);
		return (
			<div className='drawer-div-container'>
				<AppBar className='appbar'>
					<Toolbar>
						<Icon onClick={() => this.updateDrawerOpen()} className='menu-icon'>menu</Icon>
						<h1 className='appbar-title'>
							{this.props.title}
							<br />
							{titleTwoExists ? `${this.props.title2}` : ''}
						</h1>
						{this.props.rightMenuButton !== null ? this.props.rightMenuButton : null}
					</Toolbar>
				</AppBar>

				<SwipeableDrawer
					variant="temporary"
					open={this.state.DrawerOpen}
					onClose={() => this.updateDrawerOpen()}
					onOpen={() => this.setState({ DrawerOpen: !this.state.DrawerOpen })}
				>
					<List>
						<ListItem>
							<img src={this.state.logoImage} alt="Logo" width="175" id="logo" />
						</ListItem>

						<ListItem button={true} onClick={() => this.props.history.replace('/Calendar')}>
							<ListItemIcon>
								<Icon>calendar_today</Icon>
							</ListItemIcon>
							<ListItemText primary={this.props.t("Calendar")} />
						</ListItem>

						<ListItem button={true} onClick={() => this.props.history.replace({ 
							pathname: '/job-task-list', state: { clearScrollState: true } 
							})}>
							<ListItemIcon>
								<Icon>list</Icon>
							</ListItemIcon>
							<ListItemText primary={this.props.t("Job Task List")} />
						</ListItem>

						{UserSettings !== null && (isNullOrUndefined(UserSettings.AssetsEnabled) || UserSettings.AssetsEnabled) && (
							<AssetList UserSettings={UserSettings} history={this.props.history} formTemplateFBID={''} taskID={''} />
						)}

						{/* Timesheets */}
						{UserSettings !== null && UserSettings.EnableTimesheets ? (
							<ListItem button={true} onClick={() => this.props.history.replace('/timesheets')}>
								<ListItemIcon>
									<Icon>date_range</Icon>
								</ListItemIcon>
								<ListItemText primary={this.props.t("Timesheets")} />
							</ListItem>
						) : null}

						{UserSettings !== null && UserSettings.EnableExpenses ? (
							<ListItem button={true} onClick={() => this.props.history.replace('/expenses')}>
								<ListItemIcon>
									<Icon>card_giftcard</Icon>
								</ListItemIcon>
								<ListItemText primary={this.props.t("Expenses")} />
							</ListItem>
						) : null}

						{UserSettings !== null && UserSettings.EnableQuotes ? (
							<ListItem button={true} onClick={() => this.props.history.replace({ 
								pathname: '/job-quotes-list', state: { clearScrollState: true } 
								})}>
								<ListItemIcon>
									<Icon>monetization_on</Icon>
								</ListItemIcon>
								<ListItemText primary={this.props.t("Quotes")} />
							</ListItem>
						) : null}


						<SiteList UserSettings={UserSettings} history={this.props.history} />
						<FormLibrary UserSettings={UserSettings} history={this.props.history} />
						<StorageAreas UserSettings={UserSettings} history={this.props.history} />
					</List>

					<List className="details-list">

						<Divider />

						{UserSettings !== null ? (
							<ListItem button={true} onClick={() => this.props.history.push('/settings')}>
								<ListItemIcon>
									<Icon>settings</Icon>
								</ListItemIcon>
								<ListItemText primary={this.props.t("Settings")} />
							</ListItem>
						) : null}

					</List>
				</SwipeableDrawer>
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	User: state.User,
});

const FormLibrary = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/form-library')}>
			<ListItemIcon>
				<Icon>description</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Form Library")} />
		</ListItem>
	)
};

const SiteList = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/sites-list')}>
			<ListItemIcon>
				<Icon>explore</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Site List")} />
		</ListItem>
	)
};

const AssetList = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/asset-list/list/list')}>
			<ListItemIcon>
				<Icon>important_devices</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Asset List")} />
		</ListItem>
	)
};

const StorageAreas = props => {
	const [t, i18n] = useTranslation();
	return (
		<ListItem button={true} onClick={() => props.history.replace('/storage-areas-list')}>
			<ListItemIcon>
				<Icon>store</Icon>
			</ListItemIcon>
			<ListItemText primary={t("Storage Areas")} />
		</ListItem>
	)
};

export default withTranslation()(connect(mapStateToProps)(SideDrawer));
