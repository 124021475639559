import { isNullOrUndefined } from 'util';

const isIos13 = (): boolean => {
	if (/iP(hone|od|ad)/.test(navigator.platform)) {
		var appv = navigator.appVersion.match(/OS (\d+)_(\d+)_?(\d+)?/);
		if (!isNullOrUndefined(appv)) {
			var version = [parseInt(appv[1], 10), parseInt(appv[2], 10), parseInt(appv[3] || '0', 10)];
			return version[0] >= 13 ? true : false;
		}
		return false;
	}
	return false;
};

const isIpadOS13 = () => {
	return navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1 ? true : false;
};

export const isIos = (): boolean => {
	const isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	return isIos;
};

export const isIOS13 = (): boolean => {
	const isIos = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);
	if (isIos) {
		const IsIOS13 = isIos13();
		const IsIOS13IPAD = isIpadOS13();

		return IsIOS13 || IsIOS13IPAD ? true : false;
	}
	return false;
};
