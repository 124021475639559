// React
import * as React from 'react';

// Material UI
import AddAssetButton from '../asset/AddAssetButton';

interface AddAssetToJobButtonProps {
	documentID: string;
	jobTaskID?: string;
	disabled: boolean;
}

const AddAssetButtons = (props: AddAssetToJobButtonProps) => {
	const { documentID, disabled, jobTaskID } = props;
	return (
		<AddAssetButton
			documentId={documentID}
			jobTaskId={jobTaskID}
			assetListPath={(jobTaskID ? jobTaskID + '/' : '') + documentID}
			stateParams={{
				addAssetDocumnetType: 'job',
			}}
			disabled={disabled}
			formTemplateFBID={''}
			taskID={''} 
		/>
	);
};

export default AddAssetButtons;
