import * as React from 'react';
import cardColour from '../../utils/Colors';
import { unixToDateString } from '../../utils/Times';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { useTranslation, withTranslation } from 'react-i18next';

class ExpensesList extends React.Component<any, any> {
	constructor(props) {
		super(props);
	}

	render() {
		return (
			this.props.expenses.map((expense: Expense.Expense, i: number) => (
				<Link to={`/expense-details/${expense.ID}`} key={i}>
					<ExpensesCard
						style={{
							border: `2.5px solid ${cardColour(expense.Status)}`,
							marginBottom: '10px',
							fontSize: '14px'
						}}
						className="card-shadow"
					>
						<TopRow>
							<TopContent>{expense.Description}</TopContent>
							<Label>{this.props.t("Description")}</Label>
						</TopRow>
						<BottomRow>

							<HeaderContainer>
								<Content
								style={{
									fontSize: '14px;'
								}}
								>{unixToDateString(expense.DateCreated, true)}</Content>
								<Label>{this.props.t("Date Created")}</Label>
							</HeaderContainer>
							<HeaderContainer>
								<Content>{this.props.t(expense.Status)}</Content>
								<Label>{this.props.t("Status")}</Label>
							</HeaderContainer>
							<HeaderContainer>
								<Content>{expense.Units}</Content>
								<Label>{this.props.t("# Units")}</Label>
							</HeaderContainer>
							<HeaderContainer>
								<Content>{expense.TotalCost.toFixed(2)}</Content>
								<Label>{this.props.t("Cost")}</Label>
							</HeaderContainer>
							{/* {
							((!isNullOrUndefined(expense.JobTaskNumber)) && (isNullOrUndefined(this.props.jobTaskFBID) || this.props.jobTaskFBID == '')) && 
								// <HeaderContainer>
								// 	<Content>{expense.JobTaskNumber}</Content>
								// 	<Label>Job Task</Label> 
								// </HeaderContainer>
							} */}
						</BottomRow>
					</ExpensesCard>
				</Link>
			))
		);
	}
}

const ExpensesCard = styled.div`
	display: flex;
	flex-direction: column;
`;

const TopRow = styled.div`
	display: grid;
	grid-template-columns: 1fr;
	grid-column-gap: 7.5px;
	padding: 7.5px;
`;

const TopContent = styled.p`
	font-size: 14px;
	color: #000000;
	margin-bottom: 5px;
`;

const BottomRow = styled.div`
	display: grid;
	grid-template-columns: repeat(auto-fit, minmax(80px, 1fr));
	grid-column-gap: 7px;
	grid-row-gap: 7px;
	padding: 5px;
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Label = styled.p`
	font-size: 14px;
	color: var(--dark-blue);
`;

const Content = styled.p`
	font-size: 14px;
	margin-bottom: 5px;
`;

export default withTranslation()(ExpensesList);