import Dexie from 'dexie';

export default class TrackplanDatabase extends Dexie {
	photos!: Dexie.Table<indexDb.Photos, number>; // id is number in this case
	requestPhotos!: Dexie.Table<indexDb.RequestPhotos, number>; // id is number in this case
	requestDocuments!: Dexie.Table<indexDb.RequestDocuments, number>; // id is number in this case
	documents!: Dexie.Table<indexDb.Documents, number>; // id is number in this case
	localQueue!: Dexie.Table<indexDb.LocalQueue, string>; // id is number in this case

	constructor() {
		super('TrackplanContractorDB');
		this.version(1).stores({
			photos: '++id,documentId,&guid',
			localQueue: '&id, documentId',
			documents: '++id,documentId,guid, documentFBID',
			requestPhotos: '++id,guid',
			requestDocuments: '++id,guid',
		});

		this.version(2).stores({
			photos: '++id,documentId,&guid,isComplete,formId',
			localQueue: '&id, documentId',
			documents: '++id,documentId,guid,isComplete,formId, documentFBID',
			requestPhotos: '++id,guid',
			requestDocuments: '++id,guid,documentTableGuid',
		});
 
		this.version(3).stores({
			photos: '++id,documentId,&guid,isComplete,formId,photoId',
			localQueue: '&id, documentId, photoId',
			documents: '++id,documentId,guid,isComplete,formId, documentFBID',
			requestPhotos: '++id,guid',
			requestDocuments: '++id,guid,documentTableGuid',
		});

		this.version(4).stores({
			photos: '++id,documentId,&guid,isComplete,formId,photoId',
			localQueue: '&id, documentId, photoId',
			documents: '++id,documentId,guid,isComplete,formId, documentFBID',
			requestPhotos: '++id,guid',
			requestDocuments: '++id,guid,documentTableGuid',
		});

		this.photos = this.table('photos');
		this.documents = this.table('documents');
		this.requestPhotos = this.table('requestPhotos');
		this.requestDocuments = this.table('requestDocuments');
		this.localQueue = this.table('localQueue');
	}
}
