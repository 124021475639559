// React
import * as React from 'react';

interface FieldHolderProps {
	pullRight?: boolean;
	fullLine?: boolean;
	label: string;
	content: string;
}

const FieldHolder = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p className={ props.bold ? 'field-content-alt break-word-wrap almost-bold-text' : 'field-content-alt break-word-wrap' }>
			{props.content}
		</p>
		<p className="field-label-alt">{props.label}</p>
	</div>
);

export default FieldHolder;