import { Icon, IconButton } from '@material-ui/core';
import { History } from 'history';
import React from 'react';
import useDialog from '../../hooks/useDialog';
import '../../styles/storage-areas/storage-area-details.css';
import { readQRCodeAndNavigate } from '../../utils/QRCode';
import QRCodeDialog from '../shared/QRCodeDialog';

interface QRCodeSearchProps {
	history: History;
}

const QRCodeSearch = ({ history }: QRCodeSearchProps) => {
	const { isDialogOpen, openDialog, closeDialog } = useDialog();
	return (
		<>
			<IconButton onClick={openDialog}>
				<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
			</IconButton>
			<QRCodeDialog
				isOpen={isDialogOpen}
				close={closeDialog}
				history={history}
				readQRCodeAndNavigate={readQRCodeAndNavigate}
			/>
		</>
	);
};

export default QRCodeSearch;
