// React
import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import { connect } from 'react-redux';

//Components
import SideDrawer from '../components/shared/SideDrawer';
import BackButton from '../components/shared/BackButton';
import LoadingSpinner from '../components/shared/LoadingSpinner';

// Material UI
import { Button, Grid } from '@material-ui/core';
import BuildIcon from '@material-ui/icons/Build';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import ArrowRightAltIcon from '@material-ui/icons/ArrowRightAlt';
import DoneIcon from '@material-ui/icons/Done';

//Utils
import { fire } from '..';
import Api from '../apiConfiguration.json';
import ApiKeyObj from '../utils/ApiObjectKey';
import { SendErrorData } from '../utils/WindowError';
import { getBaseURL } from '../utils/getBaseURL';
import { withTranslation } from 'react-i18next';



interface CreatedJobScreenProps {
	UserSettings: Store.UserSettings;
	match: match;
	history: History;
	t: any;
}

interface CreatedJobScreenState {
	JobFBID: string;
	Loading: boolean;
}


class CreatedJobScreen extends React.Component<CreatedJobScreenProps, CreatedJobScreenState> {
	constructor(props) {
		super(props);
		this.state = {
			JobFBID: '',
			Loading: true
		};
	}

	async componentDidMount() {
		const { id } = await this.props.match.params as any;
		this.setState({ JobFBID: id, Loading: false});

		console.log(this.props.UserSettings);
	}

    handleLogout() {
		fire.auth.signOut().then(() => location.reload());
	};

    routeCreatedJob() {
		this.props.history.push('/job-task-details/' + this.state.JobFBID);
	};

    routeViewJobs() {
		this.props.history.push('/job-task-list');
	};

    routeCreateJob() {
		this.props.history.push('/new-request');
	};

    backButton = () => {
        this.props.history.replace('/job-task-list');
};
	
	render() {

		if(!this.state.Loading){
			return (	
				<div className="assetDetailsBackground">
				<div id="details-outer-asset">
                    <div className="top-card-create card-shadow" style={{ border: '1px solid grey' }}>
					<SideDrawer
						history={this.props.history}
						title={this.props.t("Job Created Successfully")}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
						<Grid container={true} direction="column" justify="center" alignItems="stretch">
                        <div className="top-card action-buttons" style={{paddingTop: "10px"}}>
						<div className='full-line-asset'>
							<h1 style={{color: "#23a847", fontSize: "24px", textAlign: "center", fontFamily: 'Montserrat', marginBottom: "15px", alignItems: 'center'}}>
							{this.props.t("Your job was created successfully")}<DoneIcon style={ {paddingLeft: "5px", fontSize: "24px" }}></DoneIcon>
							 </h1>
							 <hr />
							
							<Button onClick={() => this.routeCreateJob()}
							    style={{color: '#f59e38', marginTop: "10px"}}
								variant="outlined"
								size="large"
								fullWidth
								 >							 
							    {this.props.t("Create New Job")}
									<AddCircleOutlineIcon style={ {paddingLeft: "5px" }}></AddCircleOutlineIcon>
							</Button>
							</div>
                             {this.props.UserSettings.CanSelfDeliver && (this.state.JobFBID) ? <div className='full-line-asset'>
                            <Button onClick={() => this.routeCreatedJob()}
							    style={{ backgroundColor: '#2196f3', marginTop: 10 }}
								variant="contained"
								color="secondary"
								size="large"
								fullWidth
								 >							 
							    {this.props.t("View Created Job")}
									<ArrowRightAltIcon style={ {paddingLeft: "5px" }}></ArrowRightAltIcon>
							</Button>
							</div> : null } 
                            <Button onClick={() => this.routeViewJobs()}
							    style={{ backgroundColor: '#f59e38', marginTop: 10}}
								variant="contained"
								color="secondary"
								size="large"
								fullWidth
								 >							 
							    {this.props.t("View All Jobs")}
                                <BuildIcon style={ {paddingLeft: "5px" }}></BuildIcon>
							</Button>
						</div>
						</Grid>
				</div>
				</div>
			</div>
			);
		}
		else {
			return <LoadingSpinner color="primary" text={this.props.t("Loading")}/>;
		}
	}
}
const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(CreatedJobScreen));

