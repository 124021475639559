import { useEffect, useState } from 'react';
import { fire, idb } from '..';

const useDocumentTags = () => {
	const [tags, setTags] = useState<indexDb.DocumentTag[]>([]);

	useEffect(() => {
		fire
			.getDocumentTagsFiltered()
			.get()
			.then(tags => {
				if (tags.empty) setTags([]);
				else
					setTags(
						tags.docs.map(doc => {
							let data = doc.data();
							let documentTag: indexDb.DocumentTag = {
								tagFBID: data.TagFBID,
								tagName: data.TagName,
								tagAvailability: data.TagAvailability,
							};
							return documentTag;
						}),
					);
			});
	}, []);

	const addTagToDocument = async (
		tag: indexDb.DocumentTag,
		collection: string,
		associatedFBID: string,
		selectedDocumentGuid: string,
		selectedDocumentFBID: string,
		callback?: () => void
	) => {
		console.log(tag);
		console.log(collection);
		console.log(associatedFBID);
		console.log(selectedDocumentGuid);
		console.log(selectedDocumentFBID);
		await fire.addTagToDocument(tag.tagFBID, collection, associatedFBID, selectedDocumentGuid, selectedDocumentFBID);
		await idb.addDocumentTags(selectedDocumentGuid, tag);
		callback ? callback() : null;
	};

	return {
		tags,
		addTagToDocument,
	};
};

export default useDocumentTags;
