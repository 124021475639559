import * as React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { generateFirebaseId } from '../../utils/Guids';
import { fire } from '../../index';
import { isNullOrUndefined } from 'util';

// Material UI
import {
    Dialog,
    DialogActions,
    DialogTitle,
    DialogContent,
    TextField,
    Icon,
    Button,
} from '@material-ui/core';

import { IsChrome } from '../../utils/IsChrome';
import { windowError } from '../../utils/WindowError';
import { parseDateForDateTimeFields, stringtoUnixUTC } from '../../utils/Times';
import { withTranslation } from 'react-i18next';

interface CreateCostDialogProps {
    open: boolean;
    closeModal: () => void;
    jobTaskFBID: string;
    UserSettings: Store.UserSettings;
    t: any;
}

interface CreateCostDialogState {
    NewDate: number;
    InvoiceDate: string;
    InvoiceNumber: string;
    Description: string;
    Materials: string;
    Labour: string;
    CallOut: string;
    TotalAmount: number;
    InvoiceDateRequired: boolean;
    InvoiceNumberRequired: boolean;
    DescriptionRequired: boolean;
    LabourRequired: boolean;
    MaterialsRequired: boolean;
    CallOutRequired: boolean;
    savingCost: boolean;
}

class CreateCostDialog extends React.Component<CreateCostDialogProps, CreateCostDialogState> {
    constructor(props) {
        super(props);
        this.state = {
            InvoiceDate: '',
            InvoiceNumber: '',
            Description: '',
            Materials: '',
            CallOut: '',
            Labour: '',
            TotalAmount: 0,
            NewDate: new Date().getTime(),
            InvoiceDateRequired: false,
            InvoiceNumberRequired: false,
            DescriptionRequired: false,
            LabourRequired: false,
            MaterialsRequired: false,
            CallOutRequired: false,
            savingCost: false,

        }
    }

    componentWillMount() {
        windowError(
            this.props.UserSettings.Email,
            this.props.UserSettings.UserUID,
            this.props.UserSettings.ServerName,
            'CreateCostDialog'
        );

        if(isNullOrUndefined(this.state.InvoiceDate) || this.state.InvoiceDate === '') {
            this.setState({
                InvoiceDate: parseDateForDateTimeFields(this.state.NewDate),
            })
        }
    }

    checkForNullFields = () => {
        const { InvoiceNumber, Description, Materials, Labour, CallOut } = this.state;

        let errors = 0;

        if(isNullOrUndefined(InvoiceNumber) || InvoiceNumber === '') {
            this.setState({ InvoiceNumberRequired: true });
            errors += 1;
        } else {
            this.setState({ InvoiceNumberRequired: false });
        }

        if(isNullOrUndefined(Description) || Description === '') {
            this.setState({ DescriptionRequired: true });
            errors += 1;
        } else {
            this.setState({ DescriptionRequired: false });
        }

        if(isNullOrUndefined(Labour) || Labour === '') {
            this.setState({ LabourRequired: true });
            errors += 1;
        } else {
            this.setState({ LabourRequired: false });
        }

        if(isNullOrUndefined(CallOut) || CallOut === '') {
            this.setState({ CallOutRequired: true });
            errors += 1;
        } else {
            this.setState({ CallOutRequired: false });
        }

        if(isNullOrUndefined(Materials) || Materials === '') {
            this.setState({ MaterialsRequired: true });
            errors += 1;
        } else {
            this.setState({ MaterialsRequired: false });
        }

        return errors > 0;
    }

    saveCost(): void {
        this.setState({ savingCost: true });

        const errorsPresent = this.checkForNullFields();

        if(errorsPresent) {
            return this.setState({ savingCost: false });
        }

        if(!isNullOrUndefined(this.props.jobTaskFBID)) {

            const firebaseId = generateFirebaseId();

            const costObj = {
                ResourceId: this.props.UserSettings.UserUID,
                JobTaskFBID: this.props.jobTaskFBID,
                CostFBID: firebaseId,
                InvoiceDate: stringtoUnixUTC(this.state.InvoiceDate),
                InvoiceNumber: this.state.InvoiceNumber,
                Description: this.state.Description,
                CostStatus: 'Pending',
                Materials: parseFloat(this.state.Materials).toFixed(2),
                Labour: parseFloat(this.state.Labour).toFixed(2),
                CallOut: parseFloat(this.state.CallOut).toFixed(2),
                DateCreated: Date.now(),
            }

            fire.baseQuery.collection('Costs').doc(firebaseId).set(costObj);

            costObj['JobAction'] = "CostAdd";

            fire.postToJobQueue(firebaseId, costObj).catch(err => {
                alert(this.props.t('There was an error adding cost. If problem persists please email - support@trackplanfm.com'));
                console.log(err);
            })

            this.resetCostFields();
            this.props.closeModal();

            this.setState({ savingCost: false });
        }
    }

    resetCostFields = () => {
        this.setState({
            Description: '',
            InvoiceDate: '',
            InvoiceNumber: '',
            Materials: '',
            CallOut: '',
            Labour: '',
            TotalAmount: 0,
        });
    }

    handleValueChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        } as CreateCostDialogState, this.calculateTotalAmount)
    }
    
    calculateTotalAmount = () => {
        const { Materials, Labour, CallOut } = this.state;
        const total = (+Materials) + (+Labour) + (+CallOut);
        const roundedTotal = (Math.round(total * 100) / 100).toFixed(2);
        this.setState({
            TotalAmount: parseFloat(roundedTotal)
        })
    }

    handleDateTimeChange = (value: string): void => {
        this.setState({
            InvoiceDate: value,
            NewDate: stringtoUnixUTC(value),
        })
    }

    render() {
        return (
            <Dialog open={this.props.open} fullScreen={true} onClose={this.props.closeModal} maxWidth="lg">
                <DialogTitle id="form-dialog-title">{this.props.t("Add Cost")}</DialogTitle>
                <DialogContent>
                    <Content>
                        <TextField 
                            error={this.state.InvoiceNumberRequired}
                            value={this.state.InvoiceNumber}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_invoiceNumber"
                            type="text"
                            label={this.props.t("Invoice Number")}
                            placeholder={this.props.t("Enter Invoice Number")}
                            name="InvoiceNumber"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.InvoiceNumberRequired}
                            textForField={this.props.t("Invoice Number is Required")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <TextField 
                            fullWidth={true}
                            id="cost_invoiceDate"
                            type="datetime-local"
                            label={this.props.t("Invoice Date")}
                            placeholder={this.props.t("Enter Invoice Date")}
                            name="InvoiceDate"
                            onChange={event => this.handleDateTimeChange(event.target.value)}
                            defaultValue={
                                !isNullOrUndefined(this.state.InvoiceDate) && this.state.InvoiceDate !== ''
                                    ? this.state.InvoiceDate
                                    : parseDateForDateTimeFields(this.state.NewDate)
                            }
                            style={{ marginBottom: '15px', width: 320 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            error={this.state.DescriptionRequired}
                            value={this.state.Description}
                            autoComplete={IsChrome()}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_description"
                            label={this.props.t("Description")}
                            placeholder={this.props.t("Enter Description")}
                            name="Description"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.DescriptionRequired}
                            textForField={this.props.t("Job Cost Description Cannot be Empty")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            error={this.state.MaterialsRequired}
                            value={this.state.Materials}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_materials"
                            type="number"
                            label={this.props.t("Materials")}
                            placeholder={this.props.t("Enter Materials Cost")}
                            name="Materials"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.MaterialsRequired}
                            textForField={this.props.t("Materials Cost Cannot Be Empty")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            error={this.state.LabourRequired}
                            value={this.state.Labour}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_labour"
                            type="number"
                            label={this.props.t("Labour")}
                            placeholder={this.props.t("Enter Labour Cost")}
                            name="Labour"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.LabourRequired}
                            textForField={this.props.t("Labour Cost Cannot Be Empty")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            error={this.state.CallOutRequired}
                            value={this.state.CallOut}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_other"
                            type="number"
                            label={this.props.t("Other")}
                            placeholder={this.props.t("Enter CallOut Cost")}
                            name="CallOut"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.CallOutRequired}
                            textForField={this.props.t("Other Cost Cannot Be Empty")}
                            style={{ marginBottom: '15px' }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            disabled={true}
                            value={this.state.TotalAmount}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_totalamt"
                            type="number"
                            label={this.props.t("Total Amount")}
                            placeholder={this.props.t("Total Amount")}
                            name="TotalAmount"
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => this.props.closeModal()}
                        color="primary"
                        style={{ backgroundColor: 'var(--light-grey', color: 'white' }}
                    >
                        <Icon style={{ fontSize: 20 }}>undo</Icon> &nbsp;
                        <p style={{ marginTop: 2 }}>{this.props.t("Back")}</p>
                    </Button>
                    <Button
                        onClick={() => this.saveCost()}
                        color="primary"
                        style={{ backgroundColor: 'var(--light-green', color: 'white' }}
                    >
                        <Icon style={{ fontSize: 20 }}>save</Icon> &nbsp;
                        <p style={{ marginTop: 2 }}>{this.props.t("Save")}</p>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
};

let FieldEmptyWarning = props => (
	<div style={{ ...props.style, paddingTop: 8 }}>
		<p
			style={{
				color: '#c62828',
				fontStyle: 'italic',
				display: props.showField ? 'flex' : 'none',
			}}
		>
			{props.textForField}
		</p>
	</div>
);

const Content = styled.div`
    margin-top: 15px;    
`;

const mapStateToProps = (state: Store.Store) => ({
    UserSettings: state.User.UserSettings
});

export default withTranslation()(connect(mapStateToProps)(CreateCostDialog));