// React
import React, { useState } from 'react';

// Material UI
import Button from '@material-ui/core/Button';
import Icon from '@material-ui/core/Icon/Icon';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import QRCodeDialog from '../shared/QRCodeDialog';
import { Result } from '@zxing/library';
import { addAssetFromQRCode } from '../shared/addAssetFromQRCode';

//style
import './add-asset-button-alt.css';

interface AddAssetButtonProps {
	documentId: string;
	jobTaskId?: string;
	assetListPath: string;
	stateParams: {
		addAssetDocumnetType: AddToAssetDocumentType;
		questionAnswerID?: string;
		[key: string]: string | undefined;
	};
	disabled: boolean;
	formTemplateFBID: string;
	taskID: string;
}

const AddAssetButton = (props: AddAssetButtonProps) => {
	const { documentId, jobTaskId, assetListPath: path, disabled, stateParams, formTemplateFBID, taskID } = props;
	const { t } = useTranslation();
	const history = useHistory();
	const [qrDialogIsOpen, setQrDialogIsOpen] = useState(false);

	return (
		<>
			<div className='buttons-grid'>
				<Button
					onClick={() =>
						history.replace({
							pathname: `/asset-list/${path}`,
							state: {
								...stateParams,
								formTemplateFBID,
								taskID
							}
						})
					}
					className={formTemplateFBID !== '' ?  'old-select-asset-button' : 'select-asset-button'}
					variant="contained"
					disabled={disabled}
				>
					<Icon className='icon'>add_to_queue</Icon>&nbsp;
					<p className='label-margin'> {t('Select Asset')}</p>
				</Button>
				<Button
					onClick={() => setQrDialogIsOpen(true)}
					className={formTemplateFBID !== '' ?  'old-scan-asset-button' : 'scan-asset-button'}
					variant="contained"
					disabled={disabled}
				>
					<Icon className='icon'>add_to_queue</Icon>&nbsp;
					<p className='label-margin'>{t('Scan Asset')}</p>
				</Button>
			</div>

			<QRCodeDialog
				isOpen={qrDialogIsOpen}
				close={() => setQrDialogIsOpen(false)}
				history={history}
				readQRCodeAndNavigate={(QRCode: Result, history: History, DocumentID?: string, JobTaskID?: string, 
					DocumentType?: AddToAssetDocumentType, QuestionAnswerID?: string | null, translate?: any) => {
					addAssetFromQRCode(
						documentId,
						jobTaskId ? jobTaskId : '',
						stateParams.addAssetDocumnetType,
						stateParams.questionAnswerID ? stateParams.questionAnswerID : null,
						QRCode,
						history,
						t,
						formTemplateFBID,
						taskID
					);
				}}
			/>
		</>
	);
};

export default AddAssetButton;
