import * as React from 'react';
import { NativeSelect, InputLabel, NativeSelectProps, FormControl, FormHelperText } from '@material-ui/core';

/**
 * Enhanced MUI NativeSelected component encapsulated inside a <FormControl/> along an <InputLabel/>
 * Inspired from {@link https://v4.mui.com/components/selects/#native-select}
 * @privateRemarks Don't forget to add an id prop to this component, it will be for used for the label `for` attribute
 */
export const NativeSelectField = (
	props: { inputLabel: string; helperText?: string } & NativeSelectProps,
): JSX.Element => {
	const { inputLabel, helperText, ...nativeSelectProps } = props;
	return (
		<FormControl fullWidth>
			{/* shrink props set to true to prevent the label from overflowing <NativeSelect/> */}
			<InputLabel shrink htmlFor={props.id}>
				{inputLabel}
			</InputLabel>
			<NativeSelect {...nativeSelectProps}>{nativeSelectProps.children}</NativeSelect>
			{props.helperText && <FormHelperText>{helperText}</FormHelperText>}
		</FormControl>
	);
};
