// React
import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { match } from 'react-router';
import firebase from 'firebase';
// Config File
import Api from '../../apiConfiguration.json';

// Firebase
import { fire, idb } from '../../index';

// Material UI
import { Button, Dialog, DialogActions, Fab, Grid, Icon, IconButton } from '@material-ui/core';

// Components
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import SiteCard from '../../components/sites/SiteCard';

// Utils
import { isNullOrUndefined } from 'util';
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';
import Algolia from '../../utils/Algolia';

// CSS
import '../../styles/sites/site-list.css';
import { QrReader } from '@blackbox-vision/react-qr-reader';
import SiteSearchModal from '../../components/sites/SiteSearchModal';
import { readQRCodeAndNavigate } from '../../utils/QRCode';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { withTranslation } from 'react-i18next';
import { getArrayChunks } from '../../utils/Converters';
import { BusinessTypes } from '../../utils/shared';

interface SitesListScreenProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface SitesListScreenState {
	siteArray: Site[];
	SelectedSite: Site | null;
	lastLoadedSite: any;
	loading: boolean;
	loadingMore: boolean;
	LoadingPageGrid: boolean;
	endOfList: boolean;
	documentsPerPage: number;
	DocumentID: string;
	yAxisScroll: number;
	SearchbyQr: boolean;
	searchInput: string;
	searchModalOpen: boolean;
	algoliaActive: boolean;


	siteTypeFilter?: {
		value: number;
		label: string;
	} | null;
	loadedSiteTypes: any[];
	siteTypesPerPage: 50;
	siteTypeValue: '',
	endSiteType: boolean;
	lastLoadedSiteType: any;

	regionFilter?: {
		value: number;
		label: string;
	} | null;
	loadedRegions: any[];
	regionsPerPage: 50;
	regionValue: '',
	endRegion: boolean;
	lastLoadedRegion: any;

	contractFilter?: {
		value: number;
		label: string;
	} | null;
	loadedContracts: any[];
	contractsPerPage: 50;
	contractValue: '',
	endContract: boolean;
	lastLoadedContract: any;
	showDownloadAlert: boolean;
	downloadItemText: string;
	dataDownloadLoading: boolean;
}

class SitesListScreen extends React.Component<SitesListScreenProps, SitesListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			siteArray: [],
			SelectedSite: null,
			lastLoadedSite: null,
			loading: false,
			loadingMore: false,
			LoadingPageGrid: true,
			endOfList: false,
			documentsPerPage: 25,
			DocumentID: '',
			yAxisScroll: 0,
			SearchbyQr: false,
			searchInput: '',
			searchModalOpen: false,
			algoliaActive: false,

			siteTypeFilter: null,
			loadedSiteTypes: [],
			siteTypesPerPage: 50,
			siteTypeValue: '',
			endSiteType: false,
			lastLoadedSiteType: null,

			regionFilter: null,
			loadedRegions: [],
			regionsPerPage: 50,
			regionValue: '',
			endRegion: false,
			lastLoadedRegion: null,

			contractFilter: null,
			loadedContracts: [],
			contractsPerPage: 50,
			contractValue: '',
			endContract: false,
			lastLoadedContract: null,
			dataDownloadLoading: false,
			downloadItemText: "",
			showDownloadAlert: false
		};
		this._onOfflineDataDownloadClick = this._onOfflineDataDownloadClick.bind(this);
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'SiteList',
		);

		this.setState({ loading: true });
	}

	componentDidMount() {
		const { id } = this.props.match.params as any;
		this.setState({ DocumentID: id });
		this.getSites();

		if (this.state.searchInput != "") {
			this.setState({ algoliaActive: true });
		};
	}

	/* Download data for offline start */

	private async _onOfflineDataDownloadClick() {
		if (navigator.onLine) {
			this.setState({ dataDownloadLoading: true, downloadItemText: "Site Forms" });

			let siteArrayChunks: string[] = [];
			const loadedSiteIds = this.state.siteArray
				&& this.state.siteArray.map(_site => _site.SiteFBID);

			if (loadedSiteIds && loadedSiteIds.length) {
				siteArrayChunks = getArrayChunks(loadedSiteIds, 10);
			}

			// Clear inactive Site docs from indexdb

			if (loadedSiteIds && loadedSiteIds.length) {
				await idb.removeInactiveDocumentsFromIDB(
					BusinessTypes.Site,
					loadedSiteIds
				);
			}

			// get site forms
			if (siteArrayChunks && siteArrayChunks.length) {
				for (let chunk of siteArrayChunks) {
					await fire.getFormsForLoadedData(chunk, "SiteFBID", "Site")
						.then(() => console.info("Site Forms downloaded"))
						.catch((err) => console.warn("Error with downloading site Forms"));
				}
				this.setState({ downloadItemText: "Site Form Templates" });
				await fire.getFormTemplates()
					.then(() => console.info("Site Form Templates downloaded"))
					.catch((err) => console.warn("Site with downloading task Form Templates"));
			}

			// get site photos
			if (loadedSiteIds && loadedSiteIds.length) {
				this.setState({ downloadItemText: "Site Photos" });
				for (let siteId of loadedSiteIds) {
					await idb.syncPhotosOrDocsFromFireBase(
						`Sites/${siteId}/Documents`,
						`${siteId}`,
						BusinessTypes.Site,
						true
					);
				}
			}

			// get site locations

			if (siteArrayChunks && siteArrayChunks.length) {
				let allLocations: any[] = [];
				for (let chunk of siteArrayChunks) {
					const locations = await fire.getLocationsForLoadedSite(chunk);
					allLocations = [...allLocations, ...locations]
				}
				if (allLocations && allLocations.length) {

					// Clear inactive Location docs from indexdb

					const locIds = allLocations.map(_loc => _loc.Id);
					let locArrayChunks: string[] = [];

					await idb.removeInactiveDocumentsFromIDB(
						BusinessTypes.Locations,
						locIds
					);

					// get site location Forms
					this.setState({ downloadItemText: "Site Location Forms" });
					if (locIds && locIds.length) {
						locArrayChunks = getArrayChunks(locIds, 10);
						for (let chunk of locArrayChunks) {
							await fire.getFormsForLoadedData(chunk, "LocationFBID", "Location")
								.then(() => console.info("Location Forms downloaded"))
								.catch((err) => console.warn("Error with downloading Location Forms"));
						}
					}

					// get site location Photos
					this.setState({ downloadItemText: "Site Location Photos" });
					if (locIds && locIds.length) {
						for (let locId of locIds) {
							await idb.syncPhotosOrDocsFromFireBase(
								`Locations/${locId}/Documents`,
								`${locId}`,
								BusinessTypes.Locations,
								true
							);
						}
					}

				}

				// get site sub locations 

				if (allLocations && allLocations.length) {
					let allSubLocations: any[] = [];
					const allMainLocIDs = allLocations.map(_loc => _loc.LocationID);
					const locSiteArrayChunks = getArrayChunks(allMainLocIDs, 10);
					for (let chunk of locSiteArrayChunks) {
						const subLocations = await fire.getSubLocationsForLoadedSite(chunk);
						allSubLocations = [...allSubLocations, ...subLocations]
					}

					if (allSubLocations && allSubLocations.length) {
						const subLocIds = allSubLocations.map(_subLoc => _subLoc.Id);
						let subLocArrayChunks: string[] = [];

						// get site sub-location Forms
						this.setState({ downloadItemText: "Site Sub-Location Forms" });
						if (subLocIds && subLocIds.length) {
							subLocArrayChunks = getArrayChunks(subLocIds, 10);
							for (let chunk of subLocArrayChunks) {
								await fire.getFormsForLoadedData(chunk, "SublocationFBID", "Sublocation")
									.then(() => console.info("Sub-Location Forms downloaded"))
									.catch((err) => console.warn("Error with downloading Sub-Location Forms"));
							}
						}

						// Clear inactive Sub-Location docs from indexdb

						await idb.removeInactiveDocumentsFromIDB(
							BusinessTypes.SubLocations,
							subLocIds
						);

						// get site sub-location Photos
						this.setState({ downloadItemText: "Site Sub-Location Photos" });
						if (subLocIds && subLocIds.length) {
							for (let subLocId of subLocIds) {
								await idb.syncPhotosOrDocsFromFireBase(
									`SubLocations/${subLocId}/Documents`,
									`${subLocId}`,
									BusinessTypes.SubLocations,
									true
								);
							}
						}
					}
				}
			}

			this.setState({ dataDownloadLoading: false });
		} else {
			this.setState({ showDownloadAlert: true }, () => {
				setTimeout(() => {
					this.setState({ showDownloadAlert: false });
				}, 2000);
			})
		}
	}

	/* Download data for offline end */

	getSites() {
		this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
			this.unsubscribe = fire
				.getSitesCollectionPaginated(this.state.documentsPerPage, this.state.lastLoadedSite)
				.get()
				.then(query => {
					this.handleSiteSnapshot(query)
				});
		});
	}

	handleSiteSnapshot = (query: firebase.firestore.QuerySnapshot) => {
		if (query.empty) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedSite)) this.setState({ siteArray: [] });
			return;
		}

		let siteArray = isNullOrUndefined(this.state.lastLoadedSite) ? [] : this.state.siteArray;
		siteArray.push(
			...query.docs.map(siteRow => {
				const row = siteRow.data() as Site;
				row.ID = siteRow.id;
				return row;
			}),
		);

		siteArray.sort((a, b) => a.SiteName.toLowerCase().localeCompare(b.SiteName.toLowerCase()));

		this.setState({
			siteArray,
			lastLoadedSite: query.docs[query.docs.length - 1],
			loading: false,
			loadingMore: false,
		}, () => {
			window.scrollTo(0, this.state.yAxisScroll);
		});
	};

	backButton = () => {
		this.props.history.push('/job-list/');
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		fire.auth.signOut().then(() => location.reload());
	};

	handleClearSearch = () => {
		this.setState(
			{
				searchInput: '',
				loading: true,
				endOfList: false,
				algoliaActive: false,
				lastLoadedSite: null,

				siteTypeFilter: null,
				loadedSiteTypes: [],
				siteTypeValue: '',
				lastLoadedSiteType: null,

				regionFilter: null,
				loadedRegions: [],
				regionValue: '',
				lastLoadedRegion: null,

				contractFilter: null,
				loadedContracts: [],
				contractValue: '',
				lastLoadedContract: null
			},
			() => {
				this.handleLocalStorageFilters();
				this.getSites();
			},
		);
	};

	handleLocalStorageFilters() {
		const SearchArray = {
			searchInput: this.state.searchInput
		};

		LocalstorageSetItem({ Key: 'SiteFilterOptions', Value: SearchArray });
	};

	handleSearchModal = () => {
		this.setState({
			searchModalOpen: !this.state.searchModalOpen,
			lastLoadedSiteType: null,
			lastLoadedRegion: null,
			lastLoadedContract: null
		});
	};

	areFiltersActive = () => {
		return (
			this.state.searchInput !== '' ||
			!isNullOrUndefined(this.state.siteTypeFilter) ||
			!isNullOrUndefined(this.state.regionFilter) ||
			!isNullOrUndefined(this.state.contractFilter)
		);
	};

	handleSearchInput = input => {
		this.setState({ searchInput: input });
	};

	async searchSites() {
		await this.setState(
			{
				searchModalOpen: false,
				endOfList: false,
			},
			async () => {
				if (this.state.searchInput != "" || !isNullOrUndefined(this.state.contractFilter) || !isNullOrUndefined(this.state.siteTypeFilter)
					|| !isNullOrUndefined(this.state.regionFilter)) {
					await this.handleTextSearch(this.state.searchInput);
				} else {
					this.setState({ algoliaActive: false });
					this.getSites();
				}
			},
		);
	};

	handleTextSearch = async (textInput: string) => {
		this.setState({ loading: true });

		const algolia = new Algolia();
		let textFilters = this.generateFilterOptions();

		let searchResults = await algolia.sitesSearch(textInput, this.props.UserSettings.ClientUID, textFilters);

		await this.setState({ siteArray: searchResults, algoliaActive: true, loading: false });
	};

	generateFilterOptions() {
		let siteFilterOptions = {
			canAccessAllSites: !isNullOrUndefined(this.props.UserSettings.CanAccessAllSites) ? this.props.UserSettings.CanAccessAllSites : null,
			userUID: !isNullOrUndefined(this.props.UserSettings.UserUID) ? this.props.UserSettings.UserUID : null,
			siteType: !isNullOrUndefined(this.state.siteTypeFilter) ? this.state.siteTypeFilter.value : null,
			region: !isNullOrUndefined(this.state.regionFilter) ? this.state.regionFilter.value : null,
			contract: !isNullOrUndefined(this.state.contractFilter) ? this.state.contractFilter.value : null,
			contractFBID: !isNullOrUndefined(this.props.UserSettings.ContractFBID) ? this.props.UserSettings.ContractFBID : null
		};

		return siteFilterOptions;
	};

	loadSiteTypes = async (siteTypeName, loadedOptions) => {
		return new Promise((resolve) => {
			fire.getSiteTypesPaginated(this.state.siteTypesPerPage, this.state.lastLoadedSiteType).get().then(docs => {
				if (!docs.empty) {
					let siteTypes: any = [];
					docs.forEach(function (siteType) {
						const siteTypeData = {
							value: siteType.id,
							label: siteType.data().SiteTypeName
						}
						siteTypes.push(siteTypeData);
					});
					this.setState({ loadedSiteTypes: siteTypes, lastLoadedSiteType: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedSiteTypes,
							hasMore: !this.state.endSiteType
						});
					})
				} else {
					this.setState({ endSiteType: true });
					return resolve({
						options: [],
						hasMore: !this.state.endSiteType
					});
				}
			})
		})
	};

	loadRegions = async (regionName, loadedOptions) => {
		return new Promise((resolve) => {
			fire.getRegionsPaginated(this.state.regionsPerPage, this.state.lastLoadedRegion).get().then(docs => {
				if (!docs.empty) {
					let regions: any = [];
					docs.forEach(function (region) {
						const regionData = {
							value: region.id,
							label: region.data().RegionName
						}
						regions.push(regionData);
					});
					this.setState({ loadedRegions: regions, lastLoadedRegion: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedRegions,
							hasMore: !this.state.endRegion
						});
					})
				} else {
					this.setState({ endRegion: true });
					return resolve({
						options: [],
						hasMore: !this.state.endRegion
					});
				}
			})
		})
	};

	loadContracts = async (contractName, loadedOptions) => {
		return new Promise((resolve) => {
			fire.getContractsPaginated(this.state.contractsPerPage, this.state.lastLoadedContract).get().then(docs => {
				if (!docs.empty) {
					let contracts: any = [];
					docs.forEach(function (contract) {
						const contractData = {
							value: contract.id,
							label: contract.data().ContractName
						}
						contracts.push(contractData);
					});
					this.setState({ loadedContracts: contracts, lastLoadedContract: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedContracts,
							hasMore: !this.state.endContract
						});
					})
				} else {
					this.setState({ endContract: true });
					return resolve({
						options: [],
						hasMore: !this.state.endContract
					});
				}
			})
		})
	};

	handleSiteTypeFilter = siteType => {
		this.setState({
			siteTypeFilter: { value: siteType.value, label: siteType.label },
			lastLoadedSiteType: null
		});
	};

	handleRegionFilter = region => {
		this.setState({
			regionFilter: { value: region.value, label: region.label },
			lastLoadedRegion: null
		});
	};

	handleContractFilter = contract => {
		this.setState({
			contractFilter: { value: contract.value, label: contract.label },
			lastLoadedContract: null
		});
	};

	clearSelectedSiteType = () => {
		this.setState({
			lastLoadedSiteType: null,
			loadedSiteTypes: [],
			siteTypeFilter: null,
			endSiteType: false,
		});
	};

	clearSelectedRegion = () => {
		this.setState({
			lastLoadedRegion: null,
			loadedRegions: [],
			regionFilter: null,
			endRegion: false,
		});
	};

	clearSelectedContract = () => {
		this.setState({
			lastLoadedContract: null,
			loadedContracts: [],
			contractFilter: null,
			endContract: false,
		});
	};

	render() {
		const title = this.props.t('Site List');

		if (this.state.loading) {
			return <LoadingSpinner text={this.props.t("Loading Sites...")} />;
		}

		if (this.state.dataDownloadLoading) {
			return <LoadingSpinner
				text={this.props.t(`Downloading ${this.state.downloadItemText}...`)}
			/>;
		}


		if (this.state.showDownloadAlert) {
			return <LoadingSpinner
				text={this.props.t("Download not available as you are offline")}
				showAlert
			/>;
		}


		if (!isNullOrUndefined(this.state.siteArray) && this.state.siteArray.length < 1) {
			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						colour="primary"
						rightMenuButton={
							<div>
								{navigator.onLine && (
									<IconButton
										onClick={this._onOfflineDataDownloadClick} color="primary">
										<Icon
											style={{ color: 'white', marginRight: "0.8em" }}
											title='Download data for offline use'
										>
											download_for_offline
										</Icon>
									</IconButton>
								)}
								<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
									<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
								</IconButton>
							</div>
						}
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main-site" style={{ marginTop: '10px' }}>
							<div className="no-jobtasks-card">
								<h1 className="hot-pink">{this.props.t("There are no sites available.")}</h1>
							</div>
						</div>
					</Grid>
					<Fab
						className={this.areFiltersActive() ? 'not-hidden' : 'hidden'}
						id="clear-search-fab"
						color="inherit"
						aria-label="Add"
						style={{ backgroundColor: 'var(--light-red)' }}
						onClick={() => this.handleClearSearch()}
					>
						<Icon style={{ color: 'white' }}>clear</Icon>
					</Fab>

					<Fab
						id="search-fab"
						color="inherit"
						aria-label="Add"
						style={{ backgroundColor: 'var(--light-blue)' }}
						onClick={() => this.handleSearchModal()}
					>
						<Icon style={{ color: 'white' }}>search</Icon>
					</Fab>

					<SiteSearchModal
						searchModalOpen={this.state.searchModalOpen}
						handleSearchModal={this.handleSearchModal}
						searchInput={this.state.searchInput}
						handleSearchInput={this.handleSearchInput}
						searchSites={() => this.searchSites()}

						siteTypeFilter={this.state.siteTypeFilter}
						handleSiteTypeFilter={this.handleSiteTypeFilter}
						loadSiteTypes={this.loadSiteTypes}
						clearSelectedSiteType={this.clearSelectedSiteType}

						regionFilter={this.state.regionFilter}
						handleRegionFilter={this.handleRegionFilter}
						loadRegions={this.loadRegions}
						clearSelectedRegion={this.clearSelectedRegion}

						contractFilter={this.state.contractFilter}
						handleContractFilter={this.handleContractFilter}
						loadContracts={this.loadContracts}
						clearSelectedContract={this.clearSelectedContract}
					/>
				</div>
			);
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={title}
					colour="primary"
					rightMenuButton={
						<div>
							{navigator.onLine && (
								<IconButton
									onClick={this._onOfflineDataDownloadClick} color="primary">
									<Icon
										style={{ color: 'white', marginRight: "0.8em" }}
										title='Download data for offline use'
									>
										download_for_offline
									</Icon>
								</IconButton>
							)}
							<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
								<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
							</IconButton>
						</div>
					}
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<br />
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main-site" style={{ marginTop: '10px' }}>
						<SiteCard
							sites={this.state.siteArray}
							DocumentID={this.state.DocumentID}
							history={this.props.history}
						/>

						{this.state.endOfList ? (
							<Button variant="outlined" size="large" fullWidth disabled>
								{this.props.t("All Sites Loaded")}
							</Button>
						) : !this.state.algoliaActive ? (
							<Button variant="outlined" color="primary" size="large" fullWidth onClick={() => this.getSites()}>
								{this.props.t("Load More Sites")}
							</Button>
						) : null}

						<Fab
							className={this.areFiltersActive() ? 'not-hidden' : 'hidden'}
							id="clear-search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-red)' }}
							onClick={() => this.handleClearSearch()}
						>
							<Icon style={{ color: 'white' }}>clear</Icon>
						</Fab>

						<Fab
							id="search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-blue)' }}
							onClick={() => this.handleSearchModal()}
						>
							<Icon style={{ color: 'white' }}>search</Icon>
						</Fab>

						<SiteSearchModal
							searchModalOpen={this.state.searchModalOpen}
							handleSearchModal={this.handleSearchModal}
							searchInput={this.state.searchInput}
							handleSearchInput={this.handleSearchInput}
							searchSites={() => this.searchSites()}

							siteTypeFilter={this.state.siteTypeFilter}
							handleSiteTypeFilter={this.handleSiteTypeFilter}
							loadSiteTypes={this.loadSiteTypes}
							clearSelectedSiteType={this.clearSelectedSiteType}

							regionFilter={this.state.regionFilter}
							handleRegionFilter={this.handleRegionFilter}
							loadRegions={this.loadRegions}
							clearSelectedRegion={this.clearSelectedRegion}

							contractFilter={this.state.contractFilter}
							handleContractFilter={this.handleContractFilter}
							loadContracts={this.loadContracts}
							clearSelectedContract={this.clearSelectedContract}
						/>

					</div>
					<div>
						<QRCodeDialog
							isOpen={this.state.SearchbyQr}
							close={() => this.setState({ SearchbyQr: false })}
							history={this.props.history}
							readQRCodeAndNavigate={readQRCodeAndNavigate}
						/>
					</div>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(SitesListScreen));
