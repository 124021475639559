import React from 'react';
import AddAssetButton from '../asset/AddAssetButton';

interface AddAssetToFormButtonProps {
	formID: string;
	questionAnswerID: string;
	disabled: boolean;
	formTemplateFBID: string;
	taskID: string;
}

const AddAssetToFormButton = (props: AddAssetToFormButtonProps) => {
	const { formID, questionAnswerID, disabled, formTemplateFBID, taskID } = props;
	return (
		<AddAssetButton
			documentId={formID}
			assetListPath={formID}
			disabled={disabled}
			stateParams={{
				addAssetDocumnetType: 'form',
				questionAnswerID,
			}}
			formTemplateFBID={formTemplateFBID} 
			taskID={taskID}
		/>
	);
};

export default AddAssetToFormButton;
