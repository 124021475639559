import * as React from 'react';

interface FieldHolderProps {
	pullRight?: boolean;
	fullLine?: boolean;
	whitespace?: string;
	label: string;
	content: string;
}

const mystyle = {
    whitespace: 'pre-line'            
} 

const FieldHolder = (props: FieldHolderProps) => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
	    {
			props.whitespace ? <p className="field-content" style={{whiteSpace: "pre-line"}} >{props.content}</p> : <p className="field-content" >{props.content}</p>
		}
		<p className="field-label">{props.label}</p>
	</div>
);

export default FieldHolder;
