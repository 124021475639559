/* eslint-disable prettier/prettier */
// React
import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { match } from 'react-router';
import { QrReader } from '@blackbox-vision/react-qr-reader';

// Actions
import { clearCurrentAssetsInfo } from '../../actions/AssetActions';

// Config File
import Api from '../../apiConfiguration.json';

// Firebase
import { fire, idb } from '../../index';

// Material UI
import { Button, Grid, Icon, IconButton, Fab, Dialog, DialogActions } from '@material-ui/core';

// Components
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import AssetDetailsModal from './AssetDetailsModal';
import AssetSearchModal from '../../components/asset/AssetSearchModal';
import AssetCard from '../../components/asset/AssetCard';

// Utils
import { isNullOrUndefined } from 'util';
import { searchFreeTextInput } from '../../utils/assets/AssetSearch';
import { assetClassSearch } from '../../utils/assets/AssetClassSearch';
//import { assetQrSearch } from '../../utils/assets/AssetQrSearch';
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';

// CSS
import '../../styles/asset/asset-list.css';
import { generateFirebaseId } from '../../utils/Guids';
import firebase from 'firebase';
import { assetQrSearch } from '../../utils/assets/AssetQrSearch';

//Free-Text
import Algolia from '../../utils/Algolia';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { readQRCodeAndNavigate } from '../../utils/QRCode';
import { withTranslation } from 'react-i18next';
import { Result } from '@zxing/library';
import { addAssetFromQRCode, handleAddAsset } from '../../components/shared/addAssetFromQRCode';
import { getArrayChunks } from '../../utils/Converters';
import { BusinessTypes } from '../../utils/shared';

interface AssetListScreenProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	clearCurrentAssetsInfo: () => void;
	AssetsInfo: Store.AssetsInfoState;
	t: any;
}

interface AssetListScreenState {
	resourceArray: Asset[];
	//AssetClassList: any[] | null;
	SelectedResource: Asset | null;
	lastLoadedResource: any;
	loading: boolean;
	loadingMore: boolean;
	endOfList: boolean;
	documentsPerPage: number;
	DocumentID: string;
	DocumentType: AddToAssetDocumentType;
	QuestionAnswerID: string | null;
	formTemplateFBID: string,
	taskID: string;
	JobID: string;
	resourceDetailsModalOpen: boolean;
	searchInput: string;
	searchModalOpen: boolean;
	//SelectedAssetClass: string;
	SiteID: number;
	SearchbyQr: boolean;
	yAxisScroll: number;

	assetClassFilter?: {
		value: number;
		label: string;
	} | null;
	loadedAssetClasses: any[];
	assetClassesPerPage: 50;
	assetClassValue: '',
	endAssetClass: boolean;
	lastLoadedAssetClass: any;

	assetSubClassFilter?: {
		value: string;
		label: string;
	} | null;
	loadedAssetSubClasses: any[];
	assetSubClassesPerPage: 50;
	assetSubClassValue: '',
	endAssetSubClass: boolean;
	lastLoadedAssetSubClass: any;

	// Sites, Locations, Sub Locations
	siteFilter?: {
		value: number;
		label: string;
	} | null;
	loadedSites: [];
	sitesPerPage: number;
	siteValue: string;
	lastLoadedSite: any;
	endSite: boolean;

	locationsPerPage: number;
	lastLoadedLocation: any;
	locationValue: string;
	loadedLocations: [];
	endLocation: boolean;
	locationFilter?: {
		value: number;
		label: string;
	} | null;
	locationDisabled: boolean;

	subLocationsPerPage: number;
	lastLoadedSubLocation: any;
	subLocationValue: string;
	loadedSubLocations: [];
	endSubLocation: boolean;
	subLocationFilter?: {
		value: number;
		label: string;
	} | null;
	subLocationDisabled: boolean;
	displayBackButton: boolean;
	LinkedFromComponent: boolean;
	algoliaRanOnce: boolean;
	algoliaActive: boolean;
	showDownloadAlert: boolean;
	downloadItemText: string;
	dataDownloadLoading: boolean;
}

class AssetListScreen extends React.Component<AssetListScreenProps, AssetListScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			resourceArray: [],
			//AssetClassList: [],
			SelectedResource: null,
			lastLoadedResource: null,
			loading: false,
			loadingMore: false,
			endOfList: false,
			documentsPerPage: 20,
			DocumentID: '',
			DocumentType: '',
			QuestionAnswerID: '',
			formTemplateFBID: '',
			taskID: '',
			JobID: '',
			resourceDetailsModalOpen: false,
			searchInput: '',
			searchModalOpen: false,
			//SelectedAssetClass: '',
			SiteID: 0,
			SearchbyQr: false,
			yAxisScroll: 0,

			assetClassFilter: null,
			loadedAssetClasses: [],
			assetClassesPerPage: 50,
			assetClassValue: '',
			endAssetClass: false,
			lastLoadedAssetClass: null,

			assetSubClassFilter: null,
			loadedAssetSubClasses: [],
			assetSubClassesPerPage: 50,
			assetSubClassValue: '',
			endAssetSubClass: false,
			lastLoadedAssetSubClass: null,

			siteFilter: null,
			sitesPerPage: 50,
			lastLoadedSite: null,
			siteValue: '',
			loadedSites: [],
			endSite: false,

			locationsPerPage: 50,
			lastLoadedLocation: null,
			locationValue: '',
			loadedLocations: [],
			endLocation: false,
			locationFilter: null,
			locationDisabled: true,

			subLocationsPerPage: 50,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],
			endSubLocation: false,
			subLocationFilter: null,
			subLocationDisabled: true,
			displayBackButton: false,
			LinkedFromComponent: false,
			algoliaRanOnce: false,
			algoliaActive: false,
			dataDownloadLoading: false,
			downloadItemText: "",
			showDownloadAlert: false
		};
		this._onOfflineDataDownloadClick = this._onOfflineDataDownloadClick.bind(this);
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'AssetList',
		);

		//Handle site, location and sub-location filters
		let locationState = this.props.history.location.state;
		if (!isNullOrUndefined(locationState)) {
			this.setState({ displayBackButton: true });
			const {
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName,
				addAssetDocumnetType,
				questionAnswerID,
				formTemplateFBID,
				taskID,
			} = this.props.history.location.state;

			const siteFilter = {
				value: selectedSiteID,
				label: selectedSiteName
			};


			const locationFilter = {
				value: selectedLocationID,
				label: selectedLocationName
			}

			const subLocationFilter = {
				value: selectedSubLocationID,
				label: selectedSubLocationName
			}

			if (!isNullOrUndefined(selectedSubLocationID)) {
				this.setState({
					LinkedFromComponent: true,
					siteFilter,
					locationFilter,
					subLocationFilter,
					siteValue: selectedSiteName,
					locationValue: selectedLocationName,
					subLocationValue: selectedSubLocationName,
					displayBackButton: true,
					loading: true,
					resourceArray: [],
				}, () => { this.getAssets('list'); });
				this.checkLocationExists();
				this.checkSubLocationExists();
			}

			else if (!isNullOrUndefined(selectedLocationID)) {
				this.setState({
					LinkedFromComponent: true,
					siteFilter,
					locationFilter,
					siteValue: selectedSiteName,
					locationValue: selectedLocationName,
					displayBackButton: true,
					loading: true,
					resourceArray: [],
				}, () => { this.getAssets('list'); });
				this.checkLocationExists();
				this.checkSubLocationExists();
			}

			else if (!isNullOrUndefined(selectedSiteID)) {
				//console.log("This ran.");
				this.setState({
					LinkedFromComponent: true,
					siteFilter,
					siteValue: selectedSiteName,
					displayBackButton: true,
					resourceArray: [],
				}, () => { this.getAssets('list'); });
				this.checkLocationExists();
				this.checkSubLocationExists();
			}

			else if (!isNullOrUndefined(addAssetDocumnetType)) {
				this.setState({
					LinkedFromComponent: true,
					displayBackButton: true,
					resourceArray: [],
					DocumentType: addAssetDocumnetType === "job" || addAssetDocumnetType === "form" ? addAssetDocumnetType : "job",
					QuestionAnswerID: questionAnswerID,
					formTemplateFBID: formTemplateFBID,
					taskID: taskID,
				}, () => { this.getAssets('list') });
			}

			this.setState({ loading: true });
		}
	}

	async componentDidMount() {
		const { jobtaskid, jobid, formid } = this.props.match.params as any;
		if (jobtaskid && jobid) {
			this.setState({ DocumentID: jobtaskid, JobID: jobid });
		} else {
			this.setState({ JobID: formid });
		}

		//load previously set filters from last search from local storage
		//but do not load in if the asset list has been accessed from the job/task page (jobid != "list")
		const LocalItem = LocalstorageGetItem('AssetFilterOptions') || '{}';
		const JsonObj = JSON.parse(LocalItem);

		if (!this.state.LinkedFromComponent) {
			this.setState(
				{
					searchInput: JsonObj.searchInput || '',
					siteFilter: JsonObj.siteFilter || null,
					locationFilter: JsonObj.locationFilter || null,
					subLocationFilter: JsonObj.subLocationFilter || null,
					assetClassFilter: JsonObj.assetClassFilter || null,
					assetSubClassFilter: JsonObj.assetSubClassFilter || null
				},
				() => {

					if (!isNullOrUndefined(this.state.siteFilter)) {
						fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
							this.setState({ locationDisabled: !res });
						})
					}

					if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
						fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
							this.setState({ subLocationDisabled: !res });
						})
					}

					if (jobid != 'list') {
						if (this.state.DocumentType === 'job') {
							if (!(jobid === null || jobid === undefined)) {
								this.getLocation(jobid);
							}
						} else {
							this.getAssets('list');
						}
					} else {
						this.getAssets(jobtaskid);
					}
				},
			);
		}

		if (this.state.searchInput != "") {
			this.setState({ algoliaActive: true });
		}
	}

	/* Download data for offline start */

	private async _onOfflineDataDownloadClick() {
		if (navigator.onLine) {
			this.setState({ dataDownloadLoading: true, downloadItemText: "Asset Forms" });
			let assetArrayChunks: string[] = [];
			const loadedAssetIds = this.state.resourceArray
				&& this.state.resourceArray.map(_asset => _asset.AssetFBID);

			if (loadedAssetIds && loadedAssetIds.length) {
				assetArrayChunks = getArrayChunks(loadedAssetIds, 10);
			}

			// Clear inactive Asset docs from indexdb

			if (loadedAssetIds && loadedAssetIds.length) {
				await idb.removeInactiveDocumentsFromIDB(
					BusinessTypes.Asset,
					loadedAssetIds
				);
			}

			// get asset forms
			if (assetArrayChunks && assetArrayChunks.length) {
				for (let chunk of assetArrayChunks) {
					await fire.getFormsForLoadedData(chunk, "AssetFBID", "Asset")
						.then(() => console.info("Asset Forms downloaded"))
						.catch((err) => console.warn("Error with downloading asset Forms"));
				}
				this.setState({ downloadItemText: "Asset Form Templates" });
				await fire.getFormTemplates()
					.then(() => console.info("Asset Form Templates downloaded"))
					.catch((err) => console.warn("Asset with downloading task Form Templates"));
			}

			// get asset photos

			if (loadedAssetIds && loadedAssetIds.length) {
				this.setState({ downloadItemText: "Asset Photos" });
				for (let assetId of loadedAssetIds) {
					await idb.syncPhotosOrDocsFromFireBase(
						`Assets/${assetId}/Documents`,
						`${assetId}`,
						BusinessTypes.Asset,
						true
					);
				}
			}

			this.setState({ dataDownloadLoading: false });
		} else {
			this.setState({ showDownloadAlert: true }, () => {
				setTimeout(() => {
					this.setState({ showDownloadAlert: false });
				}, 2000);
			})
		}
	}

	/* Download data for offline end */

	getLocation = async location => {
		if (location !== 'list') {
			const siteID = await fire.getJobSiteID(location);
			this.setState({
				SiteID: siteID,
			});
			//console.log("This ran.");
			this.getAssets(location);
		}
	};

	handleTextSearch = async (textInput: string) => {
		this.setState({ loading: true });
		const algolia = new Algolia();
		let textFilters = this.generateFilterOptions();
		//let searchResults = await algolia.assetsSearch(textInput, this.props.UserSettings.ClientUID, textFilters);
		let searchResults = await algolia.assetsSearch(textInput, this.props.UserSettings.ClientUID, textFilters);
		await this.setState({ resourceArray: searchResults, algoliaActive: true, loading: false });
	}

	async getAssets(fromView: string) {
		const assetFilterOptions = await this.generateFilterOptions();
		if (this.state.searchInput != "") {
			this.handleTextSearch(this.state.searchInput);
			return;
		}

		if (fromView != 'list' && this.state.SiteID > 0) {
			//console.log("this");
			this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
				this.unsubscribe = fire
					.getAssetsForSitePaginated(
						this.state.SiteID,
						this.state.documentsPerPage,
						this.state.lastLoadedResource,
						assetFilterOptions,
					)
					//.onSnapshot(this.handleAssetSnapshot);
					.get()
					.then(query => {
						this.getAssetsOnce(query);
					});
			})
		} else {
			if (this.props.AssetsInfo.totalAssetsLoaded > 0) {
				const { totalAssetsLoaded } = this.props.AssetsInfo;
				this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
					this.unsubscribe = fire
						.getAssetsPaginated(totalAssetsLoaded, this.state.lastLoadedResource, assetFilterOptions)
						//.onSnapshot(this.handleAssetSnapshot);
						.get()
						.then(query => {
							this.getAssetsOnce(query);
						});
				})
			} else {
				this.setState({ yAxisScroll: window.scrollY, loading: true }, () => {
					this.unsubscribe = fire
						.getAssetsPaginated(this.state.documentsPerPage, this.state.lastLoadedResource, assetFilterOptions)
						//.onSnapshot(this.handleAssetSnapshot);
						.get()
						.then(query => {
							this.getAssetsOnce(query);
						});
				})
			}
		}
	}

	getAssetsOnce(query: any) {
		if (query.empty) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			if (isNullOrUndefined(this.state.lastLoadedResource)) this.setState({ resourceArray: [] });
			return;
		}

		let resourceArray = isNullOrUndefined(this.state.lastLoadedResource) ? [] : this.state.resourceArray;


		query.docChanges().forEach(change => {
			if (change.type == "added") {
				const row = change.doc.data() as Asset;
				row.AssetFBID = change.doc.id;
				resourceArray.push(row);
			};
		})

		resourceArray.sort((a, b) => a.AssetName.toLowerCase().localeCompare(b.AssetName.toLowerCase()));

		this.setState({
			resourceArray,
			lastLoadedResource: query.docs[query.docs.length - 1],
			loading: false,
			loadingMore: false,
			endOfList: false,
		},
			() => {
				//this.handleTextSearch(this.state.searchInput);
				if (this.props.AssetsInfo.totalAssetsLoaded != 0) {
					const { yAxisPos } = this.props.AssetsInfo;
					window.scrollTo(0, yAxisPos);

					this.props.clearCurrentAssetsInfo();
				} else {
					window.scrollTo(0, this.state.yAxisScroll);
				}
				//console.log(this.state.resourceArray);
			});

	}

	private checkLocationExists = () => {
		if (!isNullOrUndefined(this.state.siteFilter)) {
			fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
				this.setState({
					locationDisabled: !res
				})
			})
		}
	}

	private checkSubLocationExists = () => {
		if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
			fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
				this.setState({
					subLocationDisabled: !res
				})
			})
		}
	}

	loadAssetClasses = async (loadedOptions) => {
		return new Promise((resolve) => {
			fire.getSearchAssetClassesPaginated(this.state.assetClassesPerPage, this.state.lastLoadedAssetClass).get().then(docs => {
				if (!docs.empty) {
					let assetClasses: any = [];
					docs.forEach(function (assetClass) {
						const assetClassData = {
							value: assetClass.id,
							label: assetClass.data().AssetClassName
						}
						assetClasses.push(assetClassData);
					});
					this.setState({ loadedAssetClasses: assetClasses, lastLoadedAssetClass: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedAssetClasses,
							hasMore: !this.state.endAssetClass
						});
					})
				} else {
					this.setState({ endAssetClass: true });
					return resolve({
						options: [],
						hasMore: !this.state.endAssetClass
					});
				}
			})
		})
	}

	loadAssetSubClasses = async (loadedOptions) => {

		var assetClassFBID;
		if (!isNullOrUndefined(this.state.assetClassFilter)) {
			assetClassFBID = this.state.assetClassFilter.value;
		}
		return new Promise((resolve) => {
			fire.getSearchAssetSubClassesPaginated(this.state.assetSubClassesPerPage, assetClassFBID, this.state.lastLoadedAssetSubClass).get().then(docs => {
				if (!docs.empty) {
					let assetSubClasses: any = [];
					docs.forEach(function (assetSubClass) {
						const assetSubClassData = {
							value: assetSubClass.id,
							label: assetSubClass.data().AssetSubClassName
						}
						assetSubClasses.push(assetSubClassData);
					});
					this.setState({ loadedAssetSubClasses: assetSubClasses, lastLoadedAssetSubClass: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedAssetSubClasses,
							hasMore: !this.state.endAssetSubClass
						});
					})
				} else {
					this.setState({ endAssetSubClass: true });
					return resolve({
						options: [],
						hasMore: !this.state.endAssetSubClass
					});
				}
			})
		})
	}

	loadOptions = async (siteName, loadedOptions) => {
		if (siteName !== this.state.siteValue) {
			await this.setState({ loadedSites: [], lastLoadedSite: null, siteValue: siteName });
			loadedOptions = [];
		}

		return new Promise((resolve) => {
			fire.getSearchSitesPaginated(this.state.sitesPerPage, this.state.siteValue, this.state.lastLoadedSite).get().then(docs => {
				if (!docs.empty) {
					let sites: any = [];
					docs.forEach(function (site) {
						const siteData = {
							value: site.data().SiteID,
							label: site.data().SiteName
						}
						sites.push(siteData);
					});
					this.setState({ loadedSites: sites, lastLoadedSite: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedSites,
							hasMore: !this.state.endSite
						});
					})
				} else {
					this.setState({ endSite: true });
					return resolve({
						options: [],
						hasMore: !this.state.endSite
					});
				}
			})
		})
	}

	loadLocations = async (locationName, loadedOptions) => {

		if (locationName !== this.state.locationValue) {
			await this.setState({ loadedLocations: [], lastLoadedLocation: null, locationValue: locationName });
			loadedOptions = [];
		}

		return new Promise((resolve) => {

			if (!isNullOrUndefined(this.state.siteFilter)) {
				fire.getSearchLocationsPaginated(this.state.locationsPerPage, this.state.siteFilter.value, this.state.locationValue, this.state.lastLoadedLocation).get().then(docs => {
					if (!docs.empty) {
						let locations: any = [];
						docs.forEach(function (location) {
							const locationData = {
								value: location.data().LocationID,
								label: location.data().LocationName
							}
							locations.push(locationData);
						});
						this.setState({ loadedLocations: locations, lastLoadedLocation: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedLocations,
								hasMore: !this.state.endLocation
							});
						})
					} else {
						this.setState({ endLocation: true });
						return resolve({
							options: [],
							hasMore: !this.state.endLocation
						});
					}
				})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	loadSubLocations = async (subLocationName, loadedOptions) => {
		if (subLocationName !== this.state.subLocationValue) {
			await this.setState({ loadedSubLocations: [], lastLoadedSubLocation: null, subLocationValue: subLocationName });
			loadedOptions = [];
		}

		return new Promise((resolve) => {

			if (!isNullOrUndefined(this.state.locationFilter)) {
				fire.getSearchSubLocationsPaginated(this.state.subLocationsPerPage, this.state.locationFilter.value, this.state.subLocationValue, this.state.lastLoadedSubLocation).get().then(docs => {
					if (!docs.empty) {
						let subLocations: any = [];
						docs.forEach(function (subLocation) {
							const subLocationData = {
								value: subLocation.data().SubLocationID,
								label: subLocation.data().SubLocationName
							}
							subLocations.push(subLocationData);
						});
						this.setState({ loadedSubLocations: subLocations, lastLoadedSubLocation: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedSubLocations,
								hasMore: !this.state.endSubLocation
							});
						})
					} else {
						this.setState({ endSubLocation: true });
						return resolve({
							options: [],
							hasMore: !this.state.endSubLocation
						});
					}
				})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	handleAssetSnapshot = async (query: firebase.firestore.QuerySnapshot) => {
		if (query.empty) {
			this.setState({ loading: false, loadingMore: false, endOfList: true });
			window.scrollTo(0, this.state.yAxisScroll);
			if (isNullOrUndefined(this.state.lastLoadedResource)) this.setState({ resourceArray: [] });
			return;
		}

		let assetArray = isNullOrUndefined(this.state.lastLoadedResource) ? [] : this.state.resourceArray;

		assetArray.push(
			...query.docs.map(assetRow => {
				const row = assetRow.data() as Asset;
				row.AssetFBID = assetRow.id;
				return row;
			}),
		);

		assetArray.sort((a, b) => a.AssetName.toLowerCase().localeCompare(b.AssetName.toLowerCase()));

		this.setState({
			resourceArray: assetArray,
			lastLoadedResource: query.docs[query.docs.length - 1],
			loading: false,
			loadingMore: false,
		}, () => {
			if (this.props.AssetsInfo.totalAssetsLoaded != 0) {
				const { yAxisPos } = this.props.AssetsInfo;
				window.scrollTo(0, yAxisPos);

				this.props.clearCurrentAssetsInfo();
			} else {
				window.scrollTo(0, this.state.yAxisScroll);
			}
		});
	};

	async generateFilterOptions() {
		let siteFilter = this.state.siteFilter;
		let locationFilter = this.state.locationFilter;
		let subLocationFilter = this.state.subLocationFilter;

		let assetFilterOptions = {
			site: !isNullOrUndefined(this.state.siteFilter) ? this.state.siteFilter.value : null,
			location: !isNullOrUndefined(this.state.locationFilter) ? this.state.locationFilter.value : null,
			subLocation: !isNullOrUndefined(this.state.subLocationFilter) ? this.state.subLocationFilter.value : null,
			assetClass: !isNullOrUndefined(this.state.assetClassFilter) ? this.state.assetClassFilter.value : null,
			assetSubClass: !isNullOrUndefined(this.state.assetSubClassFilter) ? this.state.assetSubClassFilter.value : null
		};

		if (this.state.DocumentType === "job") {
			const { jobtaskid } = this.props.match.params as any;
			if (jobtaskid) {
				const jobTaskData = (await fire.getJobTaskByFBID(jobtaskid)).data();
				if (jobTaskData) {
					if (jobTaskData.SiteID)
						siteFilter = { value: jobTaskData.SiteID, label: jobTaskData.Site }

					if (jobTaskData.LocationID)
						locationFilter = { value: jobTaskData.LocationID, label: jobTaskData.Location }

					if (jobTaskData.SubLocationID)
						subLocationFilter = { value: jobTaskData.SubLocationID, label: jobTaskData.SubLocation }

				}
			}
			this.setState({
				siteFilter,
				locationFilter,
				subLocationFilter
			}, () => {
				assetFilterOptions = {
					site: !isNullOrUndefined(this.state.siteFilter) ? this.state.siteFilter.value : null,
					location: !isNullOrUndefined(this.state.locationFilter) ? this.state.locationFilter.value : null,
					subLocation: !isNullOrUndefined(this.state.subLocationFilter) ? this.state.subLocationFilter.value : null,
					assetClass: !isNullOrUndefined(this.state.assetClassFilter) ? this.state.assetClassFilter.value : null,
					assetSubClass: !isNullOrUndefined(this.state.assetSubClassFilter) ? this.state.assetSubClassFilter.value : null
				};
			})
		}

		if (this.state.DocumentType === "form") {
			const { formid } = this.props.match.params as any;
			if (formid) {
				const currentForm = (await fire.getFormByFBID(formid)).data();
				if (currentForm) {
					if (currentForm.SiteFBID) {
						const sitedata = (await fire.getSiteInfo(currentForm.SiteFBID)).data();
						if (sitedata && sitedata.SiteID)
							siteFilter = { value: sitedata.SiteID, label: sitedata.SiteName }
					}

					if (currentForm.LocationFBID) {
						const locationData = (await fire.getLocationInfo(currentForm.LocationFBID)).data();
						if (locationData && locationData.LocationID)
							locationFilter = { value: locationData.LocationID, label: locationData.LocationName }
					}


					if (currentForm.SublocationFBID) {
						const subLocationData = (await fire.getSubLocationInfo(currentForm.SublocationFBID)).data();
						if (subLocationData && subLocationData.SubLocationID)
							subLocationFilter = { value: subLocationData.SubLocationID, label: subLocationData.SubLocationName }
					}
				}
			}

			this.setState({
				siteFilter,
				locationFilter,
				subLocationFilter
			}, () => {
				assetFilterOptions = {
					site: !isNullOrUndefined(this.state.siteFilter) ? this.state.siteFilter.value : null,
					location: !isNullOrUndefined(this.state.locationFilter) ? this.state.locationFilter.value : null,
					subLocation: !isNullOrUndefined(this.state.subLocationFilter) ? this.state.subLocationFilter.value : null,
					assetClass: !isNullOrUndefined(this.state.assetClassFilter) ? this.state.assetClassFilter.value : null,
					assetSubClass: !isNullOrUndefined(this.state.assetSubClassFilter) ? this.state.assetSubClassFilter.value : null
				};
			})
		}

		return assetFilterOptions;
	}

	backButton = () => {
		window.history.back();
	};

	handleLogout() {
		LocalstorageRemoveItem('NewJobDetails');
		LocalstorageRemoveItem('JobFilterOptions');
		LocalstorageRemoveItem('ResourceFilterOptions');
		fire.auth.signOut().then(() => location.reload());
	}

	handleSearchModal = () => {
		this.setState({
			searchModalOpen: !this.state.searchModalOpen,
			lastLoadedSite: null,
		});
	};

	handleSearchInput = input => {
		this.setState({ searchInput: input });
	};

	getAssetqrsNew = id => {
		let AssetArr: Asset[] = [];
		alert(id);

		fire.baseQuery
			.collection('Assets')
			.where('QRCode', '==', id)
			.get()
			.then(AssetJobs => {
				AssetArr = AssetJobs.docs.map(assetJob => {
					let data = assetJob.data() as Asset;
					//data.AssetName = isNullOrUndefined(data.AssetName) ? '' : data.AssetName;
					return data;
				});
				//AssetArray.sort((a, b) => a.AssetName.toLowerCase().localeCompare(b.AssetName.toLowerCase()));
				alert(AssetArr[0].AssetName);
				this.setState({ resourceArray: AssetArr });
				//alert(this.state.assetArray[0].AssetName);
			});
	};

	handleAssetClassFilter = assetClass => {
		this.setState({
			assetClassFilter: { value: assetClass.value, label: assetClass.label },
			lastLoadedAssetClass: null,
		})
		this.clearSelectedAssetSubClass();
	}

	handleAssetSubClassFilter = assetSubClass => {
		this.setState({
			assetSubClassFilter: { value: assetSubClass.value, label: assetSubClass.label },
			lastLoadedAssetSubClass: null
		})
	}

	handleSiteFilter = site => {
		this.setState({
			siteFilter: { value: site.value, label: site.label },
			lastLoadedSite: null,
			locationFilter: null,
			loadedLocations: [],
			lastLoadedSubLocation: null,
			subLocationFilter: null,
			loadedSubLocations: [],
			locationValue: '',
			subLocationValue: '',

			locationDisabled: true,
			subLocationDisabled: true,
		}, () => {
			if (!isNullOrUndefined(this.state.siteFilter)) {
				fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
					this.setState({
						locationDisabled: !res
					})
				})
			}
		});
	}

	handleLocationFilter = location => {
		this.setState({
			locationFilter: { value: location.value, label: location.label },
			lastLoadedLocation: null,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],

			subLocationDisabled: true,
		}, () => {
			if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
				fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
					this.setState({
						subLocationDisabled: !res
					})
				})
			}
		});
	}

	handleSubLocationFilter = subLocation => {
		this.setState({
			subLocationFilter: { value: subLocation.value, label: subLocation.label },
			lastLoadedSubLocation: null
		});
	}

	areFiltersActive = () => {
		return (
			this.state.searchInput !== '' ||
			//this.state.SelectedAssetClass != '' || 
			!isNullOrUndefined(this.state.siteFilter) ||
			!isNullOrUndefined(this.state.locationFilter) ||
			!isNullOrUndefined(this.state.subLocationFilter) ||
			!isNullOrUndefined(this.state.assetClassFilter) ||
			!isNullOrUndefined(this.state.assetSubClassFilter)
		);
	};

	handleClearSearch = () => {
		this.setState(
			{
				searchInput: '',
				//SelectedAssetClass: '',
				loading: true,
				lastLoadedResource: null,
				endOfList: false,
				siteFilter: null,
				loadedSites: [],
				lastLoadedSite: null,
				siteValue: '',
				locationFilter: null,
				subLocationFilter: null,
				subLocationValue: '',
				locationValue: '',
				loadedLocations: [],
				loadedSubLocations: [],
				lastLoadedLocation: null,
				lastLoadedSubLocation: null,
				locationDisabled: true,
				subLocationDisabled: true,
				assetClassFilter: null,
				loadedAssetClasses: [],
				lastLoadedAssetClass: null,
				assetSubClassFilter: null,
				loadedAssetSubClasses: [],
				lastLoadedAssetSubClass: null,
				algoliaActive: false
			},
			() => {
				this.handleLocalStorageFilters();
				//console.log("This ran.");
				this.getAssets(this.state.DocumentID);
			},
		);
	};

	clearSelectedAssetClass = () => {
		this.setState({
			lastLoadedAssetClass: null,
			loadedAssetClasses: [],
			assetClassFilter: null,
			endAssetClass: false,
		})
		this.clearSelectedAssetSubClass();
	}

	clearSelectedAssetSubClass = () => {
		this.setState({
			assetSubClassFilter: null,
			endAssetSubClass: false,
			lastLoadedAssetSubClass: null,
			loadedAssetSubClasses: []
		})
	}

	clearSelectedSitesOrLocations = async (typeId) => {
		// typeId = 1 (Site), typeId = 2 (Location), typeId = 3 (Sub Location)
		// If site is cleared, then location and sub location is also cleared as well since they depend on selected site id.
		// If location is cleared, then sub location is also cleared since it depends on selected location id.

		if (typeId === 1) {
			await this.setState({
				lastLoadedSite: null,
				loadedSites: [],
				siteFilter: null,
				siteValue: '',
				endSite: false,
				locationDisabled: true,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2) {
			await this.setState({
				lastLoadedLocation: null,
				loadedLocations: [],
				locationFilter: null,
				locationValue: '',
				endLocation: false,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2 || typeId === 3) {
			await this.setState({
				lastLoadedSubLocation: null,
				loadedSubLocations: [],
				subLocationFilter: null,
				subLocationValue: '',
				endSubLocation: false,
			});
		}

		this.handleLocalStorageFilters();
	}

	handleLocalStorageFilters() {
		const SearchArray = {
			searchInput: this.state.searchInput,
			//SelectedAssetClass: this.state.SelectedAssetClass,
			siteFilter: this.state.siteFilter,
			locationFilter: this.state.locationFilter,
			subLocationFilter: this.state.subLocationFilter,
			assetClassFilter: this.state.assetClassFilter,
			assetSubClassFilter: this.state.assetSubClassFilter
		}

		LocalstorageSetItem({ Key: 'AssetFilterOptions', Value: SearchArray });
	}

	async searchAssets() {
		this.handleLocalStorageFilters();

		await this.setState(
			{
				searchModalOpen: false,
				loading: true,
				lastLoadedResource: null,
				endOfList: false,
				loadedSites: [],
				lastLoadedSite: null,
				siteValue: '',
				loadedLocations: [],
				loadedSubLocations: [],
				locationValue: '',
				subLocationValue: '',
				loadedAssetClasses: [],
				lastLoadedAssetClass: null,
				loadedAssetSubClasses: [],
				lastLoadedAssetSubClass: null,
			},
			async () => {
				if (this.state.searchInput != "") {
					await this.handleTextSearch(this.state.searchInput);
				} else {
					this.setState({ algoliaActive: false });
					//console.log("This ran.");
					this.getAssets(this.state.DocumentID);
				}
			},
		);
	}

	render() {
		const title = this.props.t('Asset List');

		if (this.state.loading) {
			return <LoadingSpinner text={this.props.t("Loading Assets...")} />;
		}

		if (this.state.dataDownloadLoading) {
			return <LoadingSpinner
				text={this.props.t(`Downloading ${this.state.downloadItemText}...`)}
			/>;
		}

		if (this.state.showDownloadAlert) {
			return <LoadingSpinner
				text={this.props.t("Download not available as you are offline")}
				showAlert
			/>;
		}

		if (!isNullOrUndefined(this.state.resourceArray) && this.state.resourceArray.length < 1) {
			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						rightMenuButton={
							<div>
								{navigator.onLine && (
									<IconButton
										onClick={this._onOfflineDataDownloadClick} color="primary">
										<Icon
											style={{ color: 'white', marginRight: "0.8em" }}
											title='Download data for offline use'
										>
											download_for_offline
										</Icon>
									</IconButton>
								)}
								<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
									<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
								</IconButton>
								{this.state.DocumentID === 'list' && this.props.UserSettings.CanAddAssets === true ? (
									<IconButton onClick={() => this.props.history.push('/new-asset')}>
										<Icon style={{ color: 'white' }}>add_circle_outline</Icon>
									</IconButton>
								) : null}
								{this.state.LinkedFromComponent ? <BackButton callbackMethod={this.backButton} /> : null}
							</div>
						}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main-asset" style={{ marginTop: '10px' }}>
							<div className="no-jobtasks-card">
								<h1 className="hot-pink">{this.props.t("There are no assets.")}</h1>
							</div>

							<Fab
								className={this.areFiltersActive() ? 'not-hidden' : 'hidden'}
								id="clear-search-fab"
								color="inherit"
								aria-label="Add"
								style={{ backgroundColor: 'var(--light-red)' }}
								onClick={() => this.handleClearSearch()}
							>
								<Icon style={{ color: 'white' }}>clear</Icon>
							</Fab>

							<Fab
								id="search-fab"
								color="inherit"
								aria-label="Add"
								style={{ backgroundColor: 'var(--light-blue)' }}
								onClick={() => this.handleSearchModal()}
							>
								<Icon style={{ color: 'white' }}>search</Icon>
							</Fab>

							<AssetSearchModal
								searchModalOpen={this.state.searchModalOpen}
								handleSearchModal={this.handleSearchModal}
								searchInput={this.state.searchInput}
								handleSearchInput={this.handleSearchInput}
								//SelectedAssetClass={this.state.SelectedAssetClass}
								//handleClassChange={this.handleClassChange}
								//AssetClassList={this.state.AssetClassList}
								//clearClassFilter={this.clearClassFilter}
								//handleQrCodechange={this.handleQrCodechange}
								//handleSearchbyQr={this.handleSearchbyQr}
								//SearchbyQr={this.state.SearchbyQr}
								searchAssets={() => this.searchAssets()}
								siteFilter={this.state.siteFilter}
								loadOptions={this.loadOptions}
								handleSiteFilter={this.handleSiteFilter}
								locationFilter={this.state.locationFilter}
								locationValue={this.state.locationValue}
								loadLocations={this.loadLocations}
								handleLocationFilter={this.handleLocationFilter}
								subLocationFilter={this.state.subLocationFilter}
								subLocationValue={this.state.subLocationValue}
								loadSubLocations={this.loadSubLocations}
								handleSubLocationFilter={this.handleSubLocationFilter}
								clearSelectedSitesOrLocations={(typeId) => this.clearSelectedSitesOrLocations(typeId)}
								locationDisabled={this.state.locationDisabled}
								subLocationDisabled={this.state.subLocationDisabled}
								assetClassFilter={this.state.assetClassFilter}
								handleAssetClassFilter={this.handleAssetClassFilter}
								loadAssetClasses={this.loadAssetClasses}
								clearSelectedAssetClass={this.clearSelectedAssetClass}
								assetSubClassFilter={this.state.assetSubClassFilter}
								handleAssetSubClassFilter={this.handleAssetSubClassFilter}
								loadAssetSubClasses={this.loadAssetSubClasses}
								clearSelectedAssetSubClass={this.clearSelectedAssetSubClass}
							/>
						</div>
						<div>
							<QRCodeDialog
								isOpen={this.state.SearchbyQr}
								close={() => this.setState({ SearchbyQr: false })}
								history={this.props.history}
								readQRCodeAndNavigate={readQRCodeAndNavigate}
							/>
						</div>
					</Grid>
				</div>
			);
		}

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={title}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					rightMenuButton={
						<div>
							{navigator.onLine && (
								<IconButton
									onClick={this._onOfflineDataDownloadClick} color="primary">
									<Icon
										style={{ color: 'white', marginRight: "0.8em" }}
										title='Download data for offline use'
									>
										download_for_offline
									</Icon>
								</IconButton>
							)}
							<IconButton onClick={() => this.setState({ SearchbyQr: !this.state.SearchbyQr })} color="primary">
								<Icon style={{ color: 'white', marginRight: 10 }}>image_search</Icon>
							</IconButton>
							{this.state.DocumentID === 'list' && this.props.UserSettings.CanAddAssets === true ? (
								<IconButton onClick={() => this.props.history.push('/new-asset')}>
									<Icon style={{ color: 'white', marginRight: 10 }}>add_circle_outline</Icon>
								</IconButton>
							) : null}
							{this.state.LinkedFromComponent ? <BackButton callbackMethod={this.backButton} /> : null}
						</div>
					}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>

				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main-asset" style={{ marginTop: '10px' }}>
						<AssetCard
							numAssets={this.state.resourceArray.length}
							assets={this.state.resourceArray}
							JobTaskID={this.state.DocumentID}
							DocumentType={this.state.DocumentType}
							QuestionAnswerID={this.state.QuestionAnswerID}
							DocumentID={this.state.JobID}
							handleAddAsset={handleAddAsset}
							history={this.props.history}
							formTemplateFBID={this.state.formTemplateFBID}
							taskID={this.state.taskID}
						/>

						{this.state.endOfList ? (
							<Button variant="outlined" size="large" fullWidth disabled>
								{this.props.t("All Assets Loaded")}
							</Button>
						) : this.state.LinkedFromComponent ? null : (
							<Button
								variant="outlined"
								color="primary"
								size="large"
								fullWidth
								onClick={() => this.getAssets(this.state.DocumentID)}
							>
								{this.props.t("Load More Assets")}
							</Button>
						)}

						<Fab
							className={this.areFiltersActive() ? 'not-hidden' : 'hidden'}
							id="clear-search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-red)' }}
							onClick={() => this.handleClearSearch()}
						>
							<Icon style={{ color: 'white' }}>clear</Icon>
						</Fab>

						<Fab
							id="search-fab"
							color="inherit"
							aria-label="Add"
							style={{ backgroundColor: 'var(--light-blue)' }}
							onClick={() => this.handleSearchModal()}
						>
							<Icon style={{ color: 'white' }}>search</Icon>
						</Fab>

						<AssetSearchModal
							searchModalOpen={this.state.searchModalOpen}
							handleSearchModal={this.handleSearchModal}
							searchInput={this.state.searchInput}
							handleSearchInput={this.handleSearchInput}
							//SelectedAssetClass={this.state.SelectedAssetClass}
							//handleClassChange={this.handleClassChange}
							//AssetClassList={this.state.AssetClassList}
							//clearClassFilter={this.clearClassFilter}
							//handleQrCodechange={this.handleQrCodechange}
							//handleSearchbyQr={this.handleSearchbyQr}
							//SearchbyQr={this.state.SearchbyQr}
							searchAssets={() => this.searchAssets()}
							siteFilter={this.state.siteFilter}
							siteValue={this.state.siteValue}
							loadOptions={this.loadOptions}
							handleSiteFilter={this.handleSiteFilter}
							locationFilter={this.state.locationFilter}
							locationValue={this.state.locationValue}
							loadLocations={this.loadLocations}
							handleLocationFilter={this.handleLocationFilter}
							subLocationFilter={this.state.subLocationFilter}
							subLocationValue={this.state.subLocationValue}
							loadSubLocations={this.loadSubLocations}
							handleSubLocationFilter={this.handleSubLocationFilter}
							clearSelectedSitesOrLocations={(typeId) => this.clearSelectedSitesOrLocations(typeId)}
							locationDisabled={this.state.locationDisabled}
							subLocationDisabled={this.state.subLocationDisabled}
							assetClassFilter={this.state.assetClassFilter}
							handleAssetClassFilter={this.handleAssetClassFilter}
							loadAssetClasses={this.loadAssetClasses}
							clearSelectedAssetClass={this.clearSelectedAssetClass}
							assetSubClassFilter={this.state.assetSubClassFilter}
							handleAssetSubClassFilter={this.handleAssetSubClassFilter}
							loadAssetSubClasses={this.loadAssetSubClasses}
							clearSelectedAssetSubClass={this.clearSelectedAssetSubClass}
						/>
					</div>
					<div>
						<QRCodeDialog
							isOpen={this.state.SearchbyQr}
							close={() => this.setState({ SearchbyQr: false })}
							history={this.props.history}
							readQRCodeAndNavigate={(QRCode: Result, history: History) => {
								console.log("QRCode", QRCode);
								if (this.state.DocumentID !== 'list') {
									addAssetFromQRCode(
										this.state.JobID,
										this.state.DocumentID,
										this.state.DocumentType,
										this.state.QuestionAnswerID,
										QRCode,
										history,
										this.props.t,
										this.state.formTemplateFBID ? this.state.formTemplateFBID : '',
										this.state.taskID ? this.state.taskID : '')
								} else {
									readQRCodeAndNavigate(QRCode, history, this.state.DocumentID, '', this.state.DocumentType, this.state.QuestionAnswerID, this.props.t)
								}
							}}
						/>
					</div>
				</Grid>
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
	AssetsInfo: state.AssetsInfo
});

const mapDispatchToProps = dispatch => ({
	clearCurrentAssetsInfo: () => dispatch(clearCurrentAssetsInfo())
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(AssetListScreen));
