import * as React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';
import OuterCard from '../shared/OuterCard';
import CardHeader from '../shared/CardHeader';
import { History } from 'history';
import Button from '@material-ui/core/Button';
import { generateFirebaseId } from '../../utils/Guids';
import { fire } from '../../index';
import { withTranslation } from 'react-i18next';
import { stringtoUnixUTC, unixToDateString } from '../../utils/Times';
import LoadingSpinner from "../shared/LoadingSpinner";

//card icon
import FORM from '../../assets/images/icons/FORM.svg';

interface FormsListProps {
	history: History;
	Forms: Forms.Form[];
	TaskId: string;
	JobID: string;
	AvailableForms: FormTemplates.FormTemplate[];
	showAttachFormButton?: boolean;
	UserSettings: Store.UserSettings;
	t: any;
}

interface FormsListState {
	openDialog: boolean;
	expand: boolean;
	formLoading: boolean;
}

class FormsList extends React.Component<FormsListProps, FormsListState> {
	constructor(props: Readonly<FormsListProps>) {
		super(props);

		this.state = {
			openDialog: false,
			expand: false,
			formLoading: false
		};
	}

	handleClick(row: Forms.Form) {
		this.props.history.push(`/form-details/${this.props.TaskId}/${row.Id}`);
	}

	changeDialogVisibility = () => {
		this.setState({ openDialog: !this.state.openDialog });
	};

	attachFormToJobTask = async (selectedForm: FormTemplates.FormTemplate) => {
		if (!selectedForm) return;
		this.setState({ formLoading: true });
		const JobTaskFBID = this.props.TaskId;
		const JobFBID = this.props.JobID;
		const FormTemplateFBID = selectedForm.Id;
		const FormFBID = generateFirebaseId();

		const newFormData = {
			CompletedByUser: null,
			CompletedDate: null,
			CurrentFormDate: null,
			FormName: selectedForm.FormName,
			FormTemplateFBID: FormTemplateFBID,
			FormType: selectedForm.FormType,
			IsCompleted: false,
			IsRequiredForJobCompletion: false,
			JobFBID: JobFBID,
			JobTaskFBID: JobTaskFBID,
			Status: 'Pending',
			LastUpdatedDate: null,
			LastUpdatedByUserFBID: null,
			LastUpdatedByUserName: null,
			Sections: {},
			CreatedDate: stringtoUnixUTC(new Date().toString()),
			SiteFBID: null,
			LocationFBID: null,
			AssetFBID: null,
			SublocationFBID: null,
		};
		await fire.CreateForm(newFormData, FormFBID);
		await fire
			.postToJobQueue(FormFBID, {
				JobAction: 'JobTaskNewFormCreated',
				JobTaskFBID: JobTaskFBID,
				FormTemplateFBID: FormTemplateFBID,
				FormFBID: FormFBID,
			}).then();

		await fire.getFormTemplateSections(FormTemplateFBID).then(async sectionsTemplate => {
			let sectionsData = sectionsTemplate.data();
			await fire.getFormTemplateQuestions(FormTemplateFBID).then(async questions => {
				if (questions.docs.length > 0) {
					var parsedQuestions = questions.docs;
					var sections: any = [];
					if (sectionsData && sectionsData.Sections) {
						sections = sectionsData.Sections;
					} else {
						alert(this.props.t("firebase could not retrieve the sections information"));
						return
					}
					for (const question of parsedQuestions) {
						const QuestionAnswerFBID = generateFirebaseId();
						const data = question.data() as Forms.QuestionAnswer;
						const QuestionFBID = question.id;

						if (data.IsRepeatable) {
							const section = sections.find(x => x.SectionName === data.Section.Name); /* avoid grouping name in the future */

							if (section) {
								if (section.RepeatableSections[0] === undefined) {
									section.RepeatableSections.push(QuestionAnswerFBID);
								} else {
									section.RepeatableSections[0] = section.RepeatableSections[0] + "," + QuestionAnswerFBID;
								}
							} else {
								var newSectionTest = await sections.push({ SectionName: data.Section.Name, IsRepeatable: true, 
									RepeatableSections: [[QuestionAnswerFBID]], TemplateSectionID: data.Section.TemplateSectionID });
							}
						};

						if (data.TemplateSectionID <= 0)
							data.TemplateSectionID = 1;
						await fire.CreateFormQuestionAnswers(data, FormFBID, QuestionAnswerFBID);
						await fire
							.postToJobQueue(
								QuestionAnswerFBID,
								{
									JobAction: 'JobTaskFormNewQuestionAnswer',
									JobTaskFBID: JobTaskFBID,
									FormTemplateFBID: FormTemplateFBID,
									FormFBID: FormFBID,
									QuestionFBID: QuestionFBID,
									DateCreated: new Date().getTime(),
								},
								new Date().getTime() + '-' + QuestionAnswerFBID,
							)
							.then();
					};

					newFormData.Sections = sections;
					await fire.updateForm(FormFBID, newFormData);
				}
			});
		});
		this.setState({ formLoading: false });
		this.changeDialogVisibility();
		this.props.history.push(`/form-details/${this.props.TaskId}/${FormFBID}`);
	};

	attachFormDialog = () => {
		if (this.props.AvailableForms.length > 0) {
			return (
				<Dialog
					open={this.state.openDialog}
					onClose={this.changeDialogVisibility}
					fullScreen={true}
					scroll="paper"
				>

					{!this.state.formLoading && (
						<DialogTitle id="form-dialog-title">
							{this.props.t("Select Form")}
						</DialogTitle>
					)}

					<DialogContent
						id="content"
						classes={{ root: 'dialog-content-root' }}
						// @ts-ignore
						dividers={scroll === 'paper'}
					>
						{!this.state.formLoading ? (
							<Table style={{ width: '100%' }}>
								<TableHead>
									<TableRow>
										<TableCell className="cell" padding="none">
											{this.props.t("Name")}
										</TableCell>
										<TableCell className="cell" padding="none">
											{this.props.t("Type")}
										</TableCell>
										<TableCell className="cell" padding="none" />
									</TableRow>
								</TableHead>
								<TableBody>
									{this.props.AvailableForms.map((form, index, _availableForms) => {
										return (
											<TableRow key={index}>
												<TableCell className="form-Template-cell" padding="none" >
													{form.FormName}
												</TableCell>
												<TableCell className="form-Template-cell" padding="none">
													{form.FormType}
												</TableCell>
												<TableCell className="form-Template-cell" padding="none" title='Add Form'>
													<IconButton onClick={() => this.attachFormToJobTask(form)}>
														<Icon>add_circle</Icon>
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table>
						) : (
							<LoadingSpinner
								text={this.props.t("Form loading - this may take up to 10 seconds.")}
								loadscreenButton={false}
							/>
						)}
					</DialogContent>

					<DialogActions classes={{ root: 'action-buttons-list' }}>
						{!this.state.formLoading && (
							<Button onClick={this.changeDialogVisibility} color="primary">
								{this.props.t("Close")}
							</Button>
						)}
					</DialogActions>
				</Dialog>
			);
		} else {
			return (
				<Dialog open={this.state.openDialog} onClose={this.changeDialogVisibility} fullScreen={false}>
					<DialogTitle id="form-dialog-title">{this.props.t("No Forms Available")}</DialogTitle>

					<DialogActions classes={{ root: 'action-buttons-list' }}>
						<Button onClick={this.changeDialogVisibility} color="primary">
							{this.props.t("Close")}
						</Button>
					</DialogActions>
				</Dialog>
			);
		}
	};

	handleExpand = (value) => {
		this.setState({ expand: value })
	}

	render() {
		return (
			<div>
				{this.attachFormDialog()}

				{(
					<OuterCard id="forms-card" padding="0px" styles='border-radius'>
						<CardHeader
							title={this.props.t("Job Forms")}
							titleIcon={FORM}
							textColor="white"
							backgroundColor="darkblue"
							showActionButton={this.props.showAttachFormButton}
							actionButtonID="attach-form-button"
							actionButtonIcon="description"
							actionButtonText={this.props.t("Add Form")}
							actionButtonClickHandler={this.changeDialogVisibility}
							expand={this.state.expand}
							handleExpand={this.handleExpand}
							showExpand={true}
						/>
						{this.props.Forms.length < 1 ? (
                        <div className='no-elements-card-text'>
                            {this.state.expand && <p>{this.props.t("No Job Forms")}</p>}
                        </div>
                    	) : <>{this.state.expand ? <Table>
							<TableHead>
								<TableRow style={{ padding: '0', paddingBottom: '5px' }}>
									<TableCell className="form-cell-name-header" padding="none">
										{this.props.t("Name")}
									</TableCell>
									<TableCell className="form-cell-task-header" padding="none">
										{this.props.t("Created Date")}
									</TableCell>
									<TableCell className="form-cell-task-header" padding="none">
										{this.props.t("Submitted Date")}
									</TableCell>
									<TableCell className="form-cell-button-header" padding="none" />
								</TableRow>
							</TableHead>
							<TableBody>
								{this.props.Forms.map((row, i) => {
									return (
										<TableRow key={i}>
											<TableCell className="form-cell-name" padding="none">
												{row.FormName}
											</TableCell>
											<TableCell className="form-cell-task " padding="none">
												{row.CreatedDate ? unixToDateString(row.CreatedDate) : null}
											</TableCell>
											<TableCell className="form-cell-task " padding="none">
												{row.CompletedDate ? unixToDateString(row.CompletedDate) : null}
											</TableCell>
											<TableCell className="form-cell-button" padding="none">
												<IconButton onClick={() => this.handleClick(row)}>
													<Icon>pageview</Icon>
												</IconButton>
											</TableCell>
										</TableRow>
									);
								})}
							</TableBody>
						</Table> : <></>}</>}
					</OuterCard>
				)}
			</div>
		);
	}
}

export default withTranslation()(FormsList);
