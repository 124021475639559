import * as React from 'react';

// Third Party
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import { connect } from 'react-redux';
import * as Sentry from '@sentry/browser';

import { isNullOrUndefined } from 'util';

// Styles
import '../styles/login/login.css';

// Actions
import { loginUser, loginUserError, loginUserSuccess } from '../actions/UserActions';

import firebase from 'firebase';
import { Redirect } from 'react-router';
import { fire } from '../index';
import imgLogo from '../assets/images/big-logo.png';
import dimoLogo from '../assets/images/dimologo.png';
import LoadingSpinner from '../components/shared/LoadingSpinner';
import { installOfflineWatcher } from '../utils/Network';
import { IsChrome } from '../utils/IsChrome';
import IOSAddToHomeScreenBubble from '../components/IOS/IOSAddToHomeScreenBubble';
import Api from '../apiConfiguration.json';
import axios, { AxiosError } from 'axios';
import ApiKeyObj from '../utils/ApiObjectKey';
import { generateFirebaseId } from '../utils/Guids';
import LoginForm from './LoginForm';

interface LoginState {
	Email: string;
	Password: string;
	LoginErrorMessage: string;
	LoginSuccessMessage: string;
	User: firebase.User | null;
	offline: boolean;
	NewPassword: string;
	NewPasswordCheck: string;
	setPassword: boolean;
	EmailLink: boolean;
	clientUID: string;
	ForgotPassword: boolean;
	loginImage: string;
}

interface LoginProps {
	loginUser: () => void;
	loginUserSuccess: (user: firebase.User) => void;
	loginUserError: (error: firebase.auth.Error | string) => void;
	UID: number;
	IsLoggedIn: boolean;
	Loading: boolean;
	Error: string;
	User: firebase.User | null;
	ClientID: number;
	UserSettings: Store.UserSettings;

}

class LoginScreen extends React.Component<LoginProps, LoginState> {
	constructor(props: any) {
		super(props);
		this.state = {
			Email: '',
			Password: '',
			LoginErrorMessage: '',
			LoginSuccessMessage: '',
			User: null,
			offline: false,
			NewPassword: '',
			NewPasswordCheck: '',
			setPassword: false,
			EmailLink: false,
			clientUID: '',
			ForgotPassword: false,
			loginImage: imgLogo,
		};
	}
	componentDidMount() {
		installOfflineWatcher(offline => {
			this.setState({ offline });
		});

		this.checkIfDimo();
	}

	checkIfDimo(){
		var url = window.location.href;
		if(url.includes("appres.trackplanfm.com")){
			this.setState({loginImage: dimoLogo});
		}
	}

	UNSAFE_componentWillReceiveProps(newProps: LoginProps): void {
		if (newProps.Error !== this.props.Error) {
			this.setState({
				LoginErrorMessage: newProps.Error,
			});
		}
		if (newProps.User !== this.props.User) {
			this.setState({ User: newProps.User });
		}
	}

	sendForgotPasswordLink(){
		let actionCodeSettings = {
			url: 'https://resource.trackplanfm.com/'
		};

		fire.auth.sendPasswordResetEmail(this.state.Email, actionCodeSettings)
		.then((result) => {
			console.log(result);
			this.setState({LoginErrorMessage: '', LoginSuccessMessage: 'An email has been sent out successfully to the address you have entered. Please check your inbox and follow the instructions.'});
		  }).catch((error) => {
			console.log(error);
			this.setState({LoginErrorMessage: error.message, LoginSuccessMessage: ''});
		  });
	}

	handleSubmit = () => {
		if (this.state.Email.length === 0) {
			this.setState({ LoginErrorMessage: 'Email not defined.' });
			return;
		}
		if (!this.state.Email.includes('@') || !this.state.Email.includes('.')) {
			this.setState({ LoginErrorMessage: 'Not a valid email.' });
			return;
		}
		if (this.state.Password.length === 0) {
			this.setState({ LoginErrorMessage: 'Password not defined.' });
			return;
		}
		if (this.state.Password.length === 0 && this.state.Email.length === 0) {
			this.setState({ LoginErrorMessage: 'Email & Password not defined.' });
			return;
		}
		this.loginFirebase();
	};

	setStateHandler = (state: LoginState) => {
		this.setState(state)
	}

	loginFirebase(): void {
		this.props.loginUser();
		fire.auth
			.signInWithEmailAndPassword(this.state.Email, this.state.Password)
			.then(async user => {
				const fbUser = user.user as firebase.User;
				const doesResourceExist = await fire.doesResourceExist(fbUser.uid);
				if (!doesResourceExist) {
					this.setState({
						LoginErrorMessage: 'Resource not found. Please check login details and try again.',
					});
					fire.auth.signOut();
				} else {
					var clientUID = await fire.getClient(fbUser.uid, this.state.Email);
					if(clientUID !== ''){
						var isExpired = await fire.isClientExpired(clientUID);
						if(isExpired){
							this.setState({
								LoginErrorMessage:
									'The client has expired. Please contact your administrator to re-enable your account.',
							});
							this.handleLogout();
						} else {
							this.props.loginUserSuccess(fbUser);
						}
					} else {
						this.setState({
							LoginErrorMessage:
								'Your client could not be found.',
						});
						this.handleLogout();
					}
				}
			})
			.catch((err: firebase.auth.Error) => this.props.loginUserError(err.message));
	}

	loginMicrosoft = async () => {
		// Initial configuration
		var microsoftProvider = new firebase.auth.OAuthProvider("microsoft.com");

		await firebase.auth().signInWithPopup(microsoftProvider).then(async (user) => {
			const fbUser = user.user as firebase.User;
			const doesResourceExist = await fire.doesResourceExist(fbUser.uid);
			if (!doesResourceExist) {
				this.setState({
					LoginErrorMessage: 'Resource not found. Please check login details and try again.',
				});
				fbUser.delete();
				this.handleLogout();
			} else {
				this.props.loginUserSuccess(fbUser);
			}
		}).catch(error => {
			if(error.code === 'auth/account-exists-with-different-credential') {
				var pendingCred = error.credential;
				var existingEmail = error.email;

				fire.auth.fetchSignInMethodsForEmail(existingEmail).then(methods => {
					if(methods[0] === 'password') {
						var password = window.prompt('Please Enter the Password for your Resource App Login.  This will only be needed once.') as string;

						fire.auth.signInWithEmailAndPassword(existingEmail, password).then(user => {
							const fbUser = user.user as firebase.User;
							return fbUser.linkWithCredential(pendingCred);
						}).then(async(user) => {
							const fbUser = user.user as firebase.User;
							const doesResourceExist = await fire.doesResourceExist(fbUser.uid);
							if (!doesResourceExist) {
								this.setState({
									LoginErrorMessage: 'Resource not found. Please check login details and try again.',
								});
								this.handleLogout();
							} else {
								this.props.loginUserSuccess(fbUser);
							}
						});
						return;
					}

					// Other Providers will be handled here
				})
			}
		});

	}

	handleLogout() {
		fire.auth.signOut();
	}

	handleReturnKey = e => {
		if (e.key === 'Enter') {
			this.handleSubmit();
		}
	};

	async handleRefresh(){
		if (navigator.onLine) {
			if (confirm('This will refresh the App and the LocalDB. Are you sure you wish to continue? ')) {

				await self.indexedDB.deleteDatabase('TrackplanContractorDB');

				const data = {
					VersionNumber: Api.VERSION,
					DbVersionNumber: Api.INDEXEDDB_VERSION,
					//UserUID: UserSettings.UserUID,
					deviceInfo: navigator.appVersion,
					triggeredBy: 'User Reload',
				};



				await axios({
					data: data,
					method: 'post',
					headers: ApiKeyObj,
				}).catch((err: AxiosError) => {
				});
				await window.location.reload();
			}
		}
	}

	async handleEmailVerification() {
		let email = this.state.Email.toLowerCase();

		if (!isNullOrUndefined(email)) {
			fire.auth.signInWithEmailLink(email, window.location.href)
				.then(async result => {
					const newUser = await result.user as firebase.User;
					var clientUID = await fire.InitializeResourceClient(newUser.uid, email);
					this.setState({ setPassword: true, EmailLink: true, LoginErrorMessage: '', clientUID: clientUID });
					return;
				})
				.catch((error) => {
					this.setState({ LoginErrorMessage: 'The e-mail you have entered did not match. Please verify and try again.' });
				})
		};
	}

	async setPassword() {
		if (this.state.NewPassword.length < 6) {
			this.setState({ LoginErrorMessage: 'Password must be six characters minimum.' });
			return;
		}


		var user = fire.auth.currentUser;
		var newPassword = this.state.NewPassword;
		var serverName = "";

		await fire.getServerName(this.state.clientUID).then(res => {
			const data = res.data();
			if(!isNullOrUndefined(data))
				serverName = data.ServerName;
		});

		if (!isNullOrUndefined(user)){
			user.updatePassword(newPassword).then(() => {
				if (!isNullOrUndefined(user)){
					const JobQueueObject = {
						JobAction: 'ResourceActivatedEmail',
						Email: user.email,
						Password: this.state.NewPassword,
						UID: user.uid,
						ServerName: serverName
					};
					fire.postToJobQueueLogin(generateFirebaseId(), JobQueueObject);
					var url= document.location.href;
					window.history.pushState({}, "", url.split("?")[0]);

				}

			}).catch(error => {
				console.log(error);
				return });
		}

		this.setState({ setPassword: false });
	}

	render() {
		if (fire.auth.isSignInWithEmailLink(window.location.href) && this.state.EmailLink == false) {
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">Please Verify your Email Address.</h1>
						<TextField
							autoComplete={IsChrome()}
							id="email"
							label="Email"
							className="text-inputs"
							value={this.state.Email}
							onChange={evt => this.setState({ Email: evt.target.value })}
							margin="normal"
							disabled={this.state.offline}
						/>
						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.handleEmailVerification()}
						>
							Verify Email
						</Button>
					</div>
				</div>
			)
		}

		if(this.state.ForgotPassword){
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h3 className="title-text">Please enter your email address.</h3>
						<TextField
							autoComplete={IsChrome()}
							id="email"
							label="Email"
							className="text-inputs"
							value={this.state.Email}
							onChange={evt => this.setState({ Email: evt.target.value })}
							margin="normal"
							type="email"
							disabled={this.state.offline}
						/>

						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<p style={{ color: 'green' }}>{this.state.LoginSuccessMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.sendForgotPasswordLink()}
						>
							Send Email Link
						</Button>
						<Button color="primary" className="login-buttons"
							onClick={() => this.setState({ForgotPassword: false, LoginSuccessMessage: '', LoginErrorMessage: ''})} >
							Back
						</Button>
					</div>
				</div>
			)
		}

		if (this.state.setPassword) {
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">Resource App</h1>
						<TextField
							autoComplete={IsChrome()}
							id="password"
							label="Password"
							className="text-inputs"
							value={this.state.NewPassword}
							onChange={evt => this.setState({ NewPassword: evt.target.value })}
							margin="normal"
							type="password"
							disabled={this.state.offline}
							onKeyDown={e => this.handleReturnKey(e)}
						/>

						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.setPassword()}
						>
							Set Password
						</Button>
					</div>
				</div>
			)
		}
		if (this.props.User !== null && this.props.ClientID !== 0 && this.props.UserSettings !== null) {
			if (process.env.NODE_ENV === 'production') {
				Sentry.setUser({
					id: this.props.User.uid,
					email: this.props.User.email || '',
				});
			}
			return (<LoginForm uid={this.props.User.uid} email={this.props.User.email}
				loginImage={this.state.loginImage} 
				stateEmail={this.state.Email}
				statePassword={this.state.Password}
				stateOffline={this.state.offline}
				setStateHandler={this.setStateHandler}
				handleReturnKey={this.handleReturnKey}
				handleSubmit={this.handleSubmit}
				loginMicrosoft={this.loginMicrosoft}
				loginErrorMessage={this.state.LoginErrorMessage}
				 />);

		}
		if (!this.props.Loading) {
			return (
				<div className="outer-container">
					<div className="inputContainer">
						<img src={this.state.loginImage} alt="Logo" width="300" id="logo" />
						<h1 className="title-text">Resource App</h1>
						<TextField
							autoComplete={IsChrome()}
							id="email"
							label="Email"
							className="text-inputs"
							value={this.state.Email}
							onChange={evt => this.setState({ Email: evt.target.value })}
							margin="normal"
							disabled={this.state.offline}
						/>
						<TextField
							autoComplete={IsChrome()}
							id="password"
							label="Password"
							className="text-inputs"
							value={this.state.Password}
							onChange={evt => this.setState({ Password: evt.target.value })}
							margin="normal"
							type="password"
							disabled={this.state.offline}
							onKeyDown={e => this.handleReturnKey(e)}
						/>
						<p style={{ color: 'red' }}>{this.state.LoginErrorMessage}</p>
						<Button
							disabled={this.state.offline}
							variant="contained"
							id="loginBtn"
							className="login-buttons"
							onClick={() => this.handleSubmit()}
						>
							Login
						</Button>

						<span style={{ margin: '15px 0', textAlign: 'center' }}><strong>OR</strong></span>

						<Button
							disabled={this.state.offline}
							variant="contained"
							id="msLoginBtn"
							onClick={() => this.loginMicrosoft()}
						>
							Login With Microsoft
						</Button>

						<Button color="secondary" className="login-buttons"
						 	disabled={this.state.offline}
							 onClick={() => this.setState({ForgotPassword: true})} >
							 Forgotten Password
						 </Button> 

						{this.state.offline ? (
							<p style={{ marginTop: '10px', textAlign: 'center', color: 'red' }}>
								Cannot login while offline. Please try again with an internet connection.
							</p>
						) : (
							<div style={{ color: 'white' }}>
								<IOSAddToHomeScreenBubble messageText="To install this Web App in your iPhone/iPad press the button below and then Add to Home Screen." />
							</div>
						)}

						{/* <Button color="secondary" className="login-buttons">
							Forgotten Password
						</Button> */}
					</div>
				</div>
			);
		} else {
			return (<div id="outer-container">

				<LoadingSpinner text="Checking your details." loadscreenButton = {true} buttonFunction = {this.handleRefresh} />

			</div>);
		}
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UID: state.User.UID,
	IsLoggedIn: state.User.IsLoggedIn,
	Loading: state.User.Loading,
	Error: state.User.Error,
	User: state.User.User,
	ClientID: state.User.ClientID,
	UserSettings: state.User.UserSettings,
});

const mapDispatchToProps = dispatch => ({
	loginUser: () => dispatch(loginUser()),
	loginUserSuccess: user => dispatch(loginUserSuccess(user)),
	loginUserError: error => dispatch(loginUserError(error)),
});

export default connect(mapStateToProps, mapDispatchToProps)(LoginScreen);
