import * as React from 'react';
import { Dialog, DialogActions, DialogContent, DialogTitle, TextField, Button, Icon } from '@material-ui/core';
import Firebase from '../../utils/Firebase';
import { IsChrome } from '../../utils/IsChrome';
import { withTranslation } from 'react-i18next';

interface EditStockProps {
	open: boolean;
	stockObject: JobTask.JobTaskStock;
	editStockDialogClose: () => void;
	fire: Firebase;
	jobTaskFBID: string;
	t: any;
}

interface EditStockState {
	quantity: string;
}

class EditStockModal extends React.Component<EditStockProps, EditStockState> {
	constructor(props: Readonly<EditStockProps>) {
		super(props);
		this.state = {
			quantity: "",
		};
	}

	UNSAFE_componentWillReceiveProps(newProps: EditStockProps) {
		if (newProps.stockObject !== this.props.stockObject) {
			this.setState({ quantity: newProps.stockObject.Quantity });
		}
	}

	saveChanges(): void {
		const stockEditObject: Partial<JobTask.JobTaskStock> & { JobAction: string; StockAction: string } = {
			StockFBID: this.props.stockObject.StockFBID,
			JobAction: 'StockJobTask',
			StockAction: 'Edit',
			Quantity: this.state.quantity,
			JobTaskStockFBID: this.props.stockObject.JobTaskStockFBID,
			StorageAreaFBID: this.props.stockObject.StorageAreaFBID,
			StorageAreaName: this.props.stockObject.StorageAreaName,
		};

		const ref = this.props.fire.baseQuery
			.collection('JobTasks')
			.doc(this.props.jobTaskFBID)
			.collection('Stock')
			.doc(this.props.stockObject.JobTaskStockFBID);

		this.props.fire.updateDocument(ref, { Quantity: this.state.quantity });
		this.props.fire.postToJobQueue(this.props.jobTaskFBID, stockEditObject);

		this.props.editStockDialogClose();
	}

	handleQuantityChange(value: any): void {
		this.setState({ quantity: value });
	}

	render() {
		return (
			<Dialog open={this.props.open} aria-labelledby="Add Stock" fullScreen={false} maxWidth="lg">
				<DialogTitle id="form-dialog-title">
					{this.props.t('Edit') + ' ' + this.props.stockObject.Name + ' ' + this.props.t('Stock')}
				</DialogTitle>
				<DialogContent>
					<TextField
						autoComplete={IsChrome()}
						margin="none"
						id="quantity"
						label={this.props.t('Quantity')}
						type="number"
						fullWidth={true}
						placeholder="0"
						onChange={e => this.handleQuantityChange(e.target.value)}
						style={{ marginBottom: '15px' }}
						value={this.state.quantity}
					/>
				</DialogContent>
				<DialogActions>
					<Button
						onClick={() => this.props.editStockDialogClose()}
						color="primary"
						style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
					>
						<Icon style={{ fontSize: 20 }}>undo</Icon> &nbsp;
						<p style={{ marginTop: 2 }}>{this.props.t('Back')}</p>
					</Button>
					<Button
						onClick={() => this.saveChanges()}
						color="primary"
						style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
					>
						<Icon style={{ fontSize: 20 }}>save</Icon>&nbsp;
						<p style={{ marginTop: 2 }}>{this.props.t('Save')}</p>
					</Button>
				</DialogActions>
			</Dialog>
		);
	}
}

export default withTranslation()(EditStockModal);
