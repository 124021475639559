import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import firebase from 'firebase';
import { fire } from '../..';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import { CircularProgress, Dialog, Grid } from '@material-ui/core';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import FormHeader from '../../components/forms/FormHeader';
import OuterCard from '../../components/shared/OuterCard';
import FormSection from '../../components/forms/FormSection';

import '../../styles/forms/forms.css';
import { isNullOrUndefined } from 'util';
import { groupAndSort, isQuestionComplete, nameSort, orderSort } from '../../utils/forms/FormHelpers';
import { windowError } from '../../utils/WindowError';
import { connect } from 'react-redux';
import { withTranslation } from 'react-i18next';
import TransparentLoadingSpinner from '../../components/shared/TransparentLoadingSpinner';

interface FormDetailsProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface FormDetailsState {
	questionAnswers: Forms.Section[] | null;
	formDetails: Forms.ExtraFormDetails;
	jobTaskId: string;
	loading: boolean;
	formComplete: boolean;
	readyToSubmit: boolean;
	FormId: string;
	TaskNumber: string;
	arrowsLoading: boolean;
}

class FormDetailsScreen extends React.Component<FormDetailsProps, FormDetailsState> {
	unsubscribe: any = null;
	constructor(props: FormDetailsProps) {
		super(props);
		this.reloadFormQuestions = this.reloadFormQuestions.bind(this);
		this.setLoading = this.setLoading.bind(this);
		this.state = {
			loading: false,
			questionAnswers: null,
			jobTaskId: '',
			formDetails: {
				Id: '',
				FormType: '',
				FormName: '',
				Status: '',
				IsCompleted: false,
				CompletedDate: null,
				formRef: null,
				IsRequiredForJobCompletion: false,
				JobTaskFBID: '',
				JobTaskNumber: '',
				LastUpdatedByUserFBID: '',
				LastUpdatedByUserName: '',
				LastUpdatedDate: null,
				Sections: null,
				FormTemplateFBID: '',
				AssetFBID: null,
				LocationFBID: null,
				SiteFBID: null,
				SublocationFBID: null,
				AssetName: '',
				LocationName: '',
				SiteName: '',
				SubLocationName: '',
			},
			formComplete: false,
			readyToSubmit: false,
			FormId: '',
			TaskNumber: '',
			arrowsLoading: false,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'FormDetailsScreen',
		);
	}

	componentDidMount() {
		const { jobtaskid, formid } = this.props.match.params as any;
		if (!isNullOrUndefined(jobtaskid)) {
			fire.baseQuery
				.collection('JobTasks')
				.doc(jobtaskid)
				.onSnapshot(jobTaskObj => {
					const data = jobTaskObj.data() as JobTask.JobTask;
					if (!isNullOrUndefined(data))
						this.setState({ TaskNumber: data.JobTaskNumber.toString() });
				});
		}

		this.setState({ jobTaskId: jobtaskid, FormId: formid, loading: true });

		const query = fire.baseQuery.collection('Forms').doc(formid);

		this.getFormDetails(query);
		//this.getQuestionAnswers(query);
	}

	reloadFormQuestions(formFBID: any, sections: Forms.Section[]) {
		const query = fire.baseQuery.collection('Forms').doc(formFBID);
		this.setState({ loading: true });
		this.getFormDetails(query);
		this.getQuestionAnswers(query, sections);
	}

	async setLoading(value: boolean) {
		this.setState({ arrowsLoading: value });
	};

	componentWillUnmount() {
		this.unsubscribe = null;
	}

	getFormDetails = (query: firebase.firestore.DocumentReference) => {
		this.unsubscribe = query.onSnapshot(async doc => {
			const data = doc.data() as Forms.Form;
			data.formRef = doc.ref;
			data.Id = doc.id;
			if (this.state.questionAnswers !== null) {
				let discrepancy = false;
				for (let i = 0; i < data.Sections.length; i++) {
					if (this.state.formDetails.Sections[i] && data.Sections[i]['RepeatableSections'] && data.Sections[i]['RepeatableSections'].length !== this.state.formDetails.Sections[i].RepeatableSections.length) {
						discrepancy = true
					}
				}

				//the number of entries on a repeatable section is not matching, something needs to render
				if (discrepancy) {
					this.unsubscribe()
					this.unsubscribe = query
						.collection('QuestionAnswers')
						.onSnapshot(this.handleSections);
				}
			}
			this.setState({ formDetails: await fire.getExtraFormDetails(data) });
			if (this.state.questionAnswers === null) {
				this.getQuestionAnswers(query, data.Sections);
			}
		});
	};

	getQuestionAnswers = (query: firebase.firestore.DocumentReference, sections: Forms.Section[]) => {
		if (this.state.questionAnswers === null) {
			this.unsubscribe = query
				.collection('QuestionAnswers')
				.onSnapshot(this.handleSections);
		} else {
			let discrepancy = false;
			for (let i = 0; i < sections.length; i++) {
				if (sections[i]['RepeatableSections'].length !== this.state.formDetails.Sections[i].RepeatableSections.length) {
					discrepancy = true
				}
			}

			//the number of entries on a repeatable section is not matching, something needs to render
			if (discrepancy) {
				this.unsubscribe()
				this.unsubscribe = query
					.collection('QuestionAnswers')
					.onSnapshot(this.handleSections);
			}
		}
	};

	handleSections = async (querySnapshot: firebase.firestore.QuerySnapshot) => {

		//if snapshot is empty exit
		if (querySnapshot.empty && this.state.formDetails.Sections == null) {
			this.setState({
				readyToSubmit: true,
			});
			return;
		}

		let questionAnswersArray: Forms.QuestionAnswer[] = [];

		//gets docs and maps them as question answers
		for (const doc of querySnapshot.docs) {
			var data = await doc.data() as Forms.QuestionAnswer;
			data.Id = await doc.id;
			data.questionRef = await doc.ref;
			data.CompletedAnswer = await isQuestionComplete(data);
			data.FormFBID = this.state.formDetails.Id;
			await questionAnswersArray.push(data);
		};

		questionAnswersArray = await questionAnswersArray.filter(data => data.IsRepeatable == false);
		let nonRepeatableQuestions = await questionAnswersArray.every(data => data.CompletedAnswer);

		await fire.getFormSections(this.state.FormId).then(results => {
			var form = results.data();
			if (form != undefined) {
				groupAndSort(
					questionAnswersArray,
					this.state.formDetails.Sections,
					this.state.FormId,
					(question: Forms.QuestionAnswer) => question.Section.Name,
					(question: Forms.QuestionAnswer) => question.Section.Order,
				).then(async res => {
					const groupedArray = res[0] as Forms.Section[];
					let allQuestionsAnswered = await res[1];
					let formComplete = allQuestionsAnswered && !isNullOrUndefined(this.state.formDetails.CompletedDate);

					if (this.state.questionAnswers) {
						for (let index = 0; index < this.state.questionAnswers.length; index++) {
							if ((groupedArray[index].CurrentSectionNumber !== this.state.questionAnswers[index].CurrentSectionNumber)) {
								let realCurrent = this.state.questionAnswers[index].CurrentSectionNumber;
								let lastEntry = groupedArray[index].CurrentSectionNumber;

								if (realCurrent && lastEntry && (realCurrent < lastEntry)
									&& this.state.questionAnswers[index].QuestionFBIDs.length === groupedArray[index].QuestionFBIDs.length) {
									groupedArray[index].CurrentSectionNumber = realCurrent
									this.viewPreviousSection(realCurrent + 1, groupedArray[index].Name, true)
								}
								if (lastEntry && (this.state.questionAnswers[index].QuestionFBIDs.length < groupedArray[index].QuestionFBIDs.length)) {
									this.viewPreviousSection(lastEntry - 1, groupedArray[index].Name, false, groupedArray)
								}
							}
						}
						await this.changeQuestionAnswers(groupedArray, formComplete, (allQuestionsAnswered && nonRepeatableQuestions))
					} else {
						await this.changeQuestionAnswers(groupedArray, formComplete, (allQuestionsAnswered && nonRepeatableQuestions))
					}
				});
			}
		})
	};

	async changeQuestionAnswers(groupedArray, formCompleted, allQuestionsAnswered) {
		this.setState({
			questionAnswers: groupedArray,
			formComplete: formCompleted,
			readyToSubmit: allQuestionsAnswered,
			loading: false
		})
	}

	backButton = () => {
		window.history.back();
	};

	viewPreviousSection = async (currentCount: number, sectionName: string, isPreviousSection: boolean, arrayQuestions?: Forms.Section[] | null) => {
		let newCount = 0;

		this.setState({ arrowsLoading: true })

		if (isPreviousSection)
			newCount = currentCount - 1;
		else
			newCount = currentCount + 1;

		let newQuestionAnswers: Forms.Section[] | null;

		if (arrayQuestions) {
			newQuestionAnswers = arrayQuestions;
		} else {
			newQuestionAnswers = this.state.questionAnswers;
		}

		if (newQuestionAnswers != null) {
			let sectionArrayIndex = newQuestionAnswers.findIndex(element => element.Name == sectionName) || 0;
			newQuestionAnswers[sectionArrayIndex].QuestionAnswers = [];
			newQuestionAnswers[sectionArrayIndex].CurrentSectionNumber = newCount;

			for (const fbid of newQuestionAnswers[sectionArrayIndex].QuestionFBIDs[newCount - 1]) {
				if (newQuestionAnswers != null) {
					await fire.getQuestion(this.state.formDetails.Id, fbid).then(doc => {
						const data = doc.data() as Forms.QuestionAnswer;
						if (data != undefined && newQuestionAnswers != null) {
							data.Id = doc.id;
							data.questionRef = doc.ref;
							data.FormFBID = this.state.formDetails.Id;
							data.CompletedAnswer = isQuestionComplete(data);
							newQuestionAnswers[sectionArrayIndex].QuestionAnswers.push(data);
						}
					});
				}
			};

			let tempQuestionAnswers = newQuestionAnswers[sectionArrayIndex].QuestionAnswers;
			tempQuestionAnswers = tempQuestionAnswers.sort((a, b) => orderSort(a.Order, b.Order));
			tempQuestionAnswers = tempQuestionAnswers.sort((a, b) =>
				nameSort(a.Order, b.Order, a.QuestionTitle, b.QuestionTitle),
			);

			newQuestionAnswers[sectionArrayIndex].QuestionAnswers = tempQuestionAnswers;
			this.setState({ questionAnswers: newQuestionAnswers, arrowsLoading: false });
		}
	};

	_renderSections = () => {
		if (this.state.questionAnswers === null) {
			return (
				<OuterCard styles="outer-card">
					<p style={{ color: '#e91e63' }}>{this.props.t("Loading questions...")}</p>
				</OuterCard>
			);
		}
		else if (this.state.questionAnswers.length < 1) {
			return (
				<OuterCard styles="outer-card">
					<p style={{ color: '#e91e63' }}>{this.props.t("No questions for this form.")}</p>
				</OuterCard>
			);
		}
		return this.state.questionAnswers.map(array => (
			<FormSection
				key={array.Name}
				sectionName={array.Name}
				questionAnswers={array.QuestionAnswers}
				IsSectionCompleted={array.SectionComplete}
				IsFormCompleted={this.state.formComplete}
				formId={this.state.FormId}
				formTemplateFBID={this.state.formDetails.FormTemplateFBID}
				taskId={this.state.jobTaskId}
				history={this.props.history}
				formName={this.state.formDetails.FormName}
				canCreateRequest={this.props.UserSettings.CanCreateRequest}
				IsRepeatable={array.IsRepeatable}
				currentSectionNumber={array.CurrentSectionNumber}
				viewPreviousSection={this.viewPreviousSection}
				RepeatableSectionCount={array.RepeatableSectionCount}
				templateSectionID={array.TemplateSectionID}
				reloadFormQuestions={this.reloadFormQuestions}
				highestTemplateSectionID={array.HighestTemplateSectionID}
				readyToSubmit={this.state.readyToSubmit}
				setLoading={this.setLoading}
				formDetailsStatus={this.state.formDetails.Status}
			/>
		));
	};

	render() {
		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={this.props.t("Form Details")}
					rightMenuButton={<BackButton callbackMethod={this.backButton} />}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main">
						<div id="details-outer">
							<FormHeader
								formDetails={this.state.formDetails}
								readyToSubmit={this.state.readyToSubmit}
								taskNumber={this.state.TaskNumber}
								fromFormLibrary={false}
								match={this.props.match}
								history={this.props.history}
							/>
							{this._renderSections()}
						</div>
					</div>
				</Grid>
				{this.state.arrowsLoading && <TransparentLoadingSpinner />}
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps, null)(FormDetailsScreen));
