import * as mime from 'react-native-mime-types';
import GetMimeType from './MimeTypes';
import { MIME_TYPE_DOCUMENT, MIME_TYPE_IMAGE } from './database/indexdb/IndexDb';

export enum BusinessTypes {
    JobTask = "JobTask",
    Asset = "Asset",
    Site = "Site",
    Locations = "Locations",
    SubLocations = "SubLocations",
    Expense = "Expense"
}

export const getBusinessTypeByCollection = (collectionName: string): string => {

    let businessType;

    if (collectionName === "JobTasks") {
        businessType = BusinessTypes.JobTask;
    } else if (collectionName === "Assets") {
        businessType = BusinessTypes.Asset;
    } else if (collectionName === "Sites") {
        businessType = BusinessTypes.Site;
    } else if (collectionName === "Locations") {
        businessType = BusinessTypes.Locations;
    } else if (collectionName === "SubLocations") {
        businessType = BusinessTypes.SubLocations;
    }

    return businessType;
}

export const sortFBPhotosByCreatedDate = (photos: any[]): any[] => {
    const sortedPhotos = photos.sort((a, b) => b.DateCreated - a.DateCreated);

    return sortedPhotos;
}


export const sortIDBPhotosByCreatedDate = (photos: any[]): any[] => {
    const sortedPhotos = photos.sort((a, b) => b.dateCreated - a.dateCreated);

    return sortedPhotos;
}

export const validateUploadedImages = (uploadedFiles: FileList | null): boolean => {
    let success = true;
    if (uploadedFiles && uploadedFiles.length) {
        for (let i = 0; i < uploadedFiles.length; i++) {
            const fileName = uploadedFiles[i].name;
            const docType = (mime.lookup(fileName) || GetMimeType(fileName) || '')
                .split('/')[0] === MIME_TYPE_IMAGE ?
                MIME_TYPE_IMAGE : MIME_TYPE_DOCUMENT;
            if (docType !== MIME_TYPE_IMAGE) {
                success = false
                break;
            }
        }
    }
    return success
}