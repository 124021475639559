import * as React from 'react';
import styled from 'styled-components';
import Icon from '@material-ui/core/Icon/Icon';

interface AddToHomeScreenBubbleProps {
	messageText: string;
}

interface AddToHomeScreenBubbleState {
	showInstallMessage: boolean;
}

class IOSAddToHomeScreenBubble extends React.Component<AddToHomeScreenBubbleProps, AddToHomeScreenBubbleState> {
	constructor(props: any) {
		super(props);
		this.state = {
			showInstallMessage: false,
		};
	}

	hideshowMessage(value: boolean) {
		this.setState({ showInstallMessage: value });
	}

	isSafari() {
		return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/) && !navigator.userAgent.match('EdgiOS');
	}

	isIos() {
		const userAgent = window.navigator.userAgent.toLowerCase();
		return /iphone|ipad|ipod/.test(userAgent);
	}

	isInStandaloneMode = () => window.matchMedia('(display-mode: standalone)').matches;

	componentDidMount() {
		if (this.isSafari() && this.isIos() && !this.isInStandaloneMode()) {
			this.hideshowMessage(true);
		}
	}

	SpeechBubble = styled.div`
		position: fixed;
		background: #526370;
		border-radius: 0.4em;
		margin-left: 0px;
		width: 300px;
		bottom: 0;
		margin-bottom: 25px;

		&:after {
			content: '';
			position: absolute;
			bottom: 0;
			left: 50%;
			width: 0;
			height: 0;
			border: 22px solid transparent;
			border-top-color: #526370;
			border-bottom: 0;
			margin-left: -22px;
			margin-bottom: -22px;
		}
	`;

	SpeechBubbleText = styled.p`
		margin-left: 15px;
		margin-bottom: 3px;
		text-align: justify;
		color: var(--pure-white);
		font-weight: 500;
		margin-top: -22px !important;
		margin-right: 45px !important;
		line-height: 1.1;
	`;

	SpeechCloseButton = styled.button`
		width: 12%;
		color: var(--pure-white);
		background-color: var(--dark-red);
		margin-left: 88%;
		margin-top: 0px !important;
		margin-bottom: 0px !important;
		border: 1px solid var(--dark-red);
		border-top-right-radius: 0.4em;
		border-bottom-left-radius: 0.4em;
	`;

	render() {
		if (this.state.showInstallMessage) {
			return (
				<this.SpeechBubble>
					<this.SpeechCloseButton onClick={() => this.hideshowMessage(false)}>
						<Icon fontSize="small" style={{ marginLeft: '-5px' }}>
							close
						</Icon>
					</this.SpeechCloseButton>
					<this.SpeechBubbleText>{this.props.messageText}</this.SpeechBubbleText>
				</this.SpeechBubble>
			);
		} else {
			return null;
		}
	}
}

export default IOSAddToHomeScreenBubble;
