import React, { ReactNode, useState } from 'react';
import { History } from 'history';
import { Icon, Button } from '@material-ui/core';
import styled from 'styled-components';

import YesNoQuestion from '../form-questions/YesNoQuestion';
import TextInputQuestion from '../form-questions/TextInputQuestion';
import DateQuestion from '../form-questions/DateQuestion';
import FileInputQuestion from '../form-questions/FileInputQuestion';
import MultiSelectQuestion from '../form-questions/MultiSelectQuestion';
import CalculatedFieldQuestion from '../form-questions/CalculatedFieldQuestion';
import SignatureQuestion from '../form-questions/SignatureQuestion';
import DropdownQuestion from '../form-questions/DropdownQuestion';
import { useTranslation, WithTranslation } from 'react-i18next';
import { ArrowLeft, ArrowRight } from '@material-ui/icons';
import { generateFirebaseId } from '../../utils/Guids';
import { fire } from '../..';
import AssetQuestion from '../form-questions/AssetQuestion';

interface FormSectionProps {
	sectionName: string;
	questionAnswers: Forms.QuestionAnswer[];
	IsSectionCompleted: boolean;
	IsFormCompleted: boolean;
	formId: string;
	formTemplateFBID: string;
	taskId: string;
	history: History;
	formName: string;
	canCreateRequest: boolean;
	IsRepeatable?: boolean;
	RepeatableSectionCount: number | null;
	currentSectionNumber: number | null;
	templateSectionID: number | null;
	viewPreviousSection: (currentCount: number, sectionName: string, isPreviousSection: boolean) => void;
	reloadFormQuestions: (formFBID: string, sections: Forms.Section[]) => void;
	setLoading: (value: boolean) => void;
	highestTemplateSectionID: number | null;
	readyToSubmit: boolean;
	formDetailsStatus: string;
}

const chooseQuestion = (
	questions: Forms.QuestionAnswer[],
	isFormCompleted: boolean,
	formId: string,
	formTemplateFBID: string,
	taskId: string,
	history: History,
	formName: string,
	canCreateRequest: boolean,
	t: WithTranslation['t'],
): ReactNode => {

	questions = questions.filter((v, i, a) => a.findIndex(v2 => (v2.Id === v.Id)) === i)

	return questions.map((question, index) => {
		//const[t, i18n] = useTranslation()
		switch (question.QuestionType) {
			case 'Yes/No':
			case 'Pass/Fail':
				return (
					<YesNoQuestion
						key={question.Id}
						data={question}
						formComplete={isFormCompleted}
						formId={formId}
						taskId={taskId}
						history={history}
						formName={formName}
						questionId={question.Id}
						canCreateRequest={canCreateRequest}
						formTemplateFBID={formTemplateFBID}
					/>
				);

			case 'TextBox':
			case 'Integer':
			case 'Decimal':
			case 'DisplayText':
				return <TextInputQuestion key={question.Id} data={question} formComplete={isFormCompleted} />;

			case 'DateTime':
			case 'Date':
				return <DateQuestion key={question.Id} data={question} formComplete={isFormCompleted} />;

			case 'Photo':
			case 'Document':
				return <FileInputQuestion
					key={question.Id} data={question}
					formComplete={isFormCompleted}
					formId={formId}
					formBusinessType={taskId}
				/>;

			case 'Signature':
				return <SignatureQuestion key={question.Id} data={question} formComplete={isFormCompleted} formId={formId} />;

			case 'MultiSelect':
				return <MultiSelectQuestion key={question.Id} data={question} formComplete={isFormCompleted} />;

			case 'Calculated Field':
				return <CalculatedFieldQuestion key={question.Id} data={question} />;

			case 'Dropdown':
				return <DropdownQuestion key={question.Id} data={question} formComplete={isFormCompleted} />;

			case 'Asset':
				return <AssetQuestion key={question.Id} questionAnswer={question} formComplete={isFormCompleted} formId={formId} formTemplateFBID={formTemplateFBID} taskID={taskId} />;

			default:
				return (
					<h1 key={index}>
						Error: could not find matching question type {question.QuestionType}. {question.Id}
					</h1>
				);
		}
	});
};

const addRepeatableFormSection = (formTemplateFBID: string, sectionName: string, formFBID: string, repeatableSectionCount: number, templateSectionID: any,
	reloadFormQuestions: any, highestTemplateSectionID: number, setLoading: any) => {
	let questionArray: any = [];

	setLoading(true);
	//Optimize 1: change this to only get questions for the new selected section. 
	fire.getFormTemplateQuestionsForSectionID(formTemplateFBID, templateSectionID).then(questions => {
		if (questions.docs.length > 0) {
			var newQuestionFBIDs: string[] = []
			questions.docs.forEach(question => {
				//for(const question of questions.docs){
				const data = question.data() as Forms.QuestionAnswer;
				if (data.Section.Name != sectionName) /* avoid grouping name in the future */
					return;

				data.TemplateSectionID = highestTemplateSectionID + 1;
				const QuestionAnswerFBID = generateFirebaseId();
				const QuestionFBID = question.id;
				newQuestionFBIDs.push(QuestionAnswerFBID);

				fire.CreateFormQuestionAnswers(data, formFBID, QuestionAnswerFBID);
				data.TemplateSectionID = templateSectionID;
				questionArray.push({
					JobAction: "FormNewQuestionAnswer",
					FormTemplateFBID: formTemplateFBID,
					FormFBID: formFBID,
					QuestionFBID: QuestionFBID,
					TemplateResponseFBID: formFBID,
					DateCreated: new Date().getTime(),
					TemplateSectionID: parseInt(templateSectionID),
					QuestionAnswerFBID: QuestionAnswerFBID
				});
			})

			var joinedFBIDs = newQuestionFBIDs.join(',');
			fire.getFormSections(formFBID).then(doc => {
				var form = doc.data();
				if (form != undefined) {
					var currentSection = form.Sections.findIndex(element => element.SectionName == sectionName);
					form.Sections[currentSection].RepeatableSections.push(joinedFBIDs);
					fire.updateForm(formFBID, form);
					reloadFormQuestions(formFBID, form.Sections);
				}
			});

			const RepeatableSectionFBID = generateFirebaseId();
			fire.postToJobQueue(
				RepeatableSectionFBID, {
				TemplateSectionID: parseInt(templateSectionID),
				Questions: questionArray,
				FormTemplateResponseFBID: formFBID,
				JobAction: "FormNewRepeatableSection"
			});
		}

	}).then(x => setLoading(false));
};

const removeRepeatableFormSection = (formFBID: string, sectionName: string, questions: any[], currentSectionNumber: number, confirmText: string, reloadFormQuestions) => {
	const confirm = window.confirm(confirmText);
	if (confirm) {
		if (questions && questions.length > 0) {
			fire.getFormSections(formFBID).then(doc => {
				var form = doc.data();
				if (form != undefined) {
					var currentSection = form.Sections.findIndex(element => element.SectionName == sectionName);

					var RepeatableSections = form.Sections[currentSection].RepeatableSections
					RepeatableSections.splice(currentSectionNumber - 1, 1);

					form.Sections[currentSection].RepeatableSections = RepeatableSections

					fire.updateForm(formFBID, form);
					reloadFormQuestions(formFBID, form.Sections);
				}
			});
			const RepeatableSectionFBID = generateFirebaseId();
			fire.postToJobQueue(
				RepeatableSectionFBID, {
				TemplateSectionID: parseInt(questions[0].TemplateSectionID),
				QuestionFBID: questions[0].Id,
				JobAction: "FormRemoveRepeatableSection"
			});
		}
	}
};

const FormSection = (props: FormSectionProps) => {
	const [open, setOpen] = useState(!props.IsSectionCompleted);
	const [t, _i18n] = useTranslation();
	return (
		<SectionCard className={`card-shadow ${props.IsSectionCompleted ? 'green-border' : 'orange-border'}`}>
			<SectionHeader onClick={() => setOpen(!open)}>
				<SectionTitle>{props.sectionName}</SectionTitle>
				<Icon style={{ padding: 0, marginTop: '-3px' }}>{open ? 'expand_less' : 'expand_more'}</Icon>
			</SectionHeader>
			<SectionQuestionContainer className="section-question-container" hidden={!open}>
				{chooseQuestion(
					props.questionAnswers,
					props.IsFormCompleted,
					props.formId,
					props.formTemplateFBID,
					props.taskId,
					props.history,
					props.formName,
					props.canCreateRequest,
					t,
				)}
				{props.IsRepeatable ?
					<RepeatableSectionUI RepeatableSectionCount={props.RepeatableSectionCount ? props.RepeatableSectionCount : 0} viewPreviousSection={props.viewPreviousSection}
						sectionName={props.sectionName} currentSectionNumber={props.currentSectionNumber ? props.currentSectionNumber : 0}
						formTemplateFBID={props.formTemplateFBID} formFBID={props.formId} templateSectionID={props.templateSectionID}
						reloadFormQuestions={props.reloadFormQuestions} highestTemplateSectionID={props.highestTemplateSectionID ? props.highestTemplateSectionID : 0}
						setLoading={props.setLoading}
						IsFormCompleted={props.IsSectionCompleted} questionAnswers={props.questionAnswers} formDetailsStatus={props.formDetailsStatus} /> : null}
			</SectionQuestionContainer>
		</SectionCard>
	);
};

interface RepeatableSectionUIProps {
	RepeatableSectionCount: number;
	viewPreviousSection: (currentCount: number, sectionName: string, isPreviousSection: boolean) => void;
	sectionName: string;
	currentSectionNumber: number;
	formTemplateFBID: string;
	formFBID: string;
	templateSectionID: number | null;
	reloadFormQuestions: (formFBID: string, sections: Forms.Section[]) => void;
	highestTemplateSectionID: number;
	setLoading: (value: boolean) => void;
	IsFormCompleted: boolean;
	questionAnswers: Forms.QuestionAnswer[];
	formDetailsStatus: string;
}

const RepeatableSectionUI = (props: RepeatableSectionUIProps) => {
	const [t, i18n] = useTranslation();

	return (
		<>
			<p className="field-label-current-count" style={{ marginTop: '15px' }}>{t("Current Entry: ") + props.currentSectionNumber}</p>
			<p className="field-label-repeatable-count">{"Total Entries: " + props.RepeatableSectionCount}</p>
			<RepeatableButtons>
				{props.currentSectionNumber != 1 ? <Button onClick={() => props.viewPreviousSection(props.currentSectionNumber, props.sectionName, true)} style={{ color: '#2196f3', width: '100%', marginBottom: '5px', marginTop: '5px' }}
					variant="outlined">
					<ArrowLeft></ArrowLeft>&nbsp;
					<p style={{ marginTop: 2 }}> {t("Previous")}</p>
				</Button> : <h2></h2>}
				{props.RepeatableSectionCount != props.currentSectionNumber ? <Button onClick={() => props.viewPreviousSection(props.currentSectionNumber, props.sectionName, false)} style={{ color: '#f59e38', width: '100%', marginBottom: '5px', marginTop: '5px' }}
					variant="outlined">
					<ArrowRight></ArrowRight>
					<p style={{ marginTop: 2 }}> {t("Next")}</p>
				</Button> : null}
			</RepeatableButtons>
			{props.IsFormCompleted ?
				<Button disabled={props.formDetailsStatus === "Submitted"} onClick={() => addRepeatableFormSection(props.formTemplateFBID, props.sectionName, props.formFBID, props.RepeatableSectionCount, props.templateSectionID, props.reloadFormQuestions, props.highestTemplateSectionID, props.setLoading)} style={props.formDetailsStatus === "Submitted" ? { width: '100%', marginBottom: '5px' } : { color: 'var(--light-green)', width: '100%', marginBottom: '5px' }}
					variant="outlined">
					<Icon style={{ fontSize: 18 }}>add_box</Icon>&nbsp;
					<p style={{ marginTop: 2 }}> {t("Add Another")}</p>
				</Button>
				: <h2></h2>}
			{props.RepeatableSectionCount !== 1 && <Button disabled={props.formDetailsStatus === "Submitted"} onClick={() => removeRepeatableFormSection(props.formFBID, props.sectionName, props.questionAnswers, props.currentSectionNumber, t('Are you sure?'), props.reloadFormQuestions)} style={props.formDetailsStatus === "Submitted" ? { width: '100%', marginBottom: '5px' } : { color: 'var(--light-red)', width: '100%', marginBottom: '5px' }}
				variant="outlined">
				<Icon style={{ fontSize: 18 }}>delete_forever</Icon>&nbsp;
				<p style={{ marginTop: 2 }}> {t("Delete current section")}</p>
			</Button>}
		</>
	)
}

const SectionQuestionContainer = styled.div`
	padding: 0;
`;

const SectionTitle = styled.h1`
	color: var(--dark-blue);
`;

const SectionCard = styled.div`
	border: 1px solid grey;
	padding: 12.5px;
	margin-bottom: 15px;
`;

const SectionHeader = styled.div`
	display: flex;
	justify-content: space-between;
`;

const RepeatableButtons = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr;
	grid-column-gap: 15px;
	padding: 0px;
	margin-bottom: 5px;
`;

export default FormSection;
