import * as React from 'react';
import { History } from 'history';
import {
    Table,
    TableHead,
    TableBody,
    TableRow,
    TableCell
} from '@material-ui/core';
import OuterCard from '../shared/OuterCard';
import CardHeader from '../shared/CardHeader';
import { fire } from '../../index';
import { unixToDateString, unixToTimeString } from '../../utils/Times';
import { withTranslation } from 'react-i18next';

//card icon
import QUOTE from '../../assets/images/icons/QUOTE.svg'

interface JobTaskQuotesProps {
    jobTaskFBID: string;
    history: History;
    t: any;
}

interface JobTaskQuotesState {
    quotes: JobQuote.JobQuote[];
    costRows: any;
    expand: boolean;
}

class JobTaskQuotes extends React.Component<JobTaskQuotesProps, JobTaskQuotesState> {
    constructor(props) {
        super(props);
        this.state = {
            quotes: [],
            costRows: [],
            expand: false
        }
    }

    componentDidMount() {
        this.getJobQuotes();
    }

    getJobQuotes = () => {
        fire.baseQuery
            .collection('Quotes')
            .where('JobTaskFBID', '==', this.props.jobTaskFBID)
            .onSnapshot(quotesList => {
                const quoteArray = quotesList.docs.map(quote => {
                    const quoteObj = quote.data() as JobQuote.JobQuote;
                    quoteObj.Id = quote.id;

                    this.getQuoteCost(quoteObj.Id);
                    return quoteObj;
                });
                this.setState({ quotes: quoteArray });
            });
    }

    getQuoteCost = (id) => {
        var total_cost = 0;
        fire.baseQuery.collection('Quotes').doc(id).collection('CostRow').onSnapshot(costList => {
            costList.docs.map(cost => {
                const costObj = cost.data() as JobQuote.CostRow;
                total_cost += (+costObj.Labour) + (+costObj.Materials) + (+costObj.CallOut);
            });
            this.setState(prevState => ({
                costRows: {
                    ...prevState.costRows,
                    [id]: (Math.round(total_cost * 100) / 100).toFixed(2)
                }
            }))
        })
    }

    handleAddQuote = () => {
        this.props.history.push({
            pathname: `/new-quote/${this.props.jobTaskFBID}`,
            state: {
                from: 'JobTaskQuotes'
            }
        })
    }

    handleQuoteClick = (quoteId) => {
        this.props.history.push({
            pathname: `/job-quote/${quoteId}`,
            state: {
                from: 'JobTaskQuotes'
            }
        });
    }

    handleExpand = (value) => {
        this.setState({ expand: value })
    }

    render() {

        const quoteRowStyles = (status) => {
            if (status.toLowerCase() === 'pending') {
                // Greyish
                return {
                    borderLeft: "5px solid grey",
                    cursor: "pointer"
                };
            }
            if (status.toLowerCase() === 'submitted') {
                // Orange
                return {
                    borderLeft: "5px solid orange",
                    cursor: "pointer"
                };
            }
            if (status.toLowerCase() === 'approved') {
                // Green
                return {
                    borderLeft: "5px solid green",
                    cursor: "pointer"
                };
            }
            if (status.toLowerCase() === 'rejected') {
                // Red
                return {
                    borderLeft: "5px solid red",
                    cursor: "pointer"
                };
            }
            return {
                borderLeft: "5px solid grey",
                cursor: "pointer"
            }
        }

        return (
            <div>
                <OuterCard id="quotes-card" padding="0px" styles='border-radius'>
                    <CardHeader title={this.props.t("Job Task Quotes")} titleIcon={QUOTE} textColor="white" backgroundColor="#738678"
                        expand={this.state.expand} handleExpand={this.handleExpand} showExpand={true}
                        showActionButton={true} actionButtonText={this.props.t("Add Quote")} actionButtonIcon="monetization_on"
                        actionButtonClickHandler={this.handleAddQuote} />
                    {this.state.quotes.length < 1 ? (
                        <div className='no-elements-card-text'>
                            {this.state.expand && <p>{this.props.t("No Job Task Quotes")}</p>}
                        </div>
                    ) : <>{this.state.expand ? <Table>
                        <TableHead>
                            <TableRow style={{ padding: '0', paddingBottom: '5px' }}>
                                <TableCell className="note-cell-1-header" padding="none">
                                    {this.props.t("Request Date")}
                                </TableCell>
                                <TableCell className="note-cell-2-header" padding="none">
                                    {this.props.t("Quote Reference")}
                                </TableCell>
                                <TableCell className="note-cell-3-header" padding="none">
                                    {this.props.t("Total Cost")}
                                </TableCell>
                                <TableCell className="note-cell-4-header" padding="none">
                                    {this.props.t("Status")}
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {this.state.quotes.map(row => {
                                return (
                                    <TableRow key={row.Id} onClick={() => this.handleQuoteClick(row.Id)} style={quoteRowStyles(row.Status)}>
                                        <TableCell className="note-cell-1" padding="none">
                                            <em>{unixToTimeString(row.RequestedDate)}</em>
                                            <br />
                                            {unixToDateString(row.RequestedDate, true)}
                                        </TableCell>
                                        <TableCell className="note-cell-2" padding="none">
                                            {row.Reference}
                                        </TableCell>
                                        <TableCell className="note-cell-3" padding="none">
                                            {this.state.costRows[row.Id]}
                                        </TableCell>
                                        <TableCell className="note-cell-4" padding="none">
                                            {this.props.t(row.Status)}
                                        </TableCell>
                                    </TableRow>
                                )
                            })}
                        </TableBody>
                    </Table> : <></>}</>}
                </OuterCard>
            </div>
        )
    }
}

export default withTranslation()(JobTaskQuotes);