import * as React from 'react';
import firebase from 'firebase';
import { isNullOrUndefined } from 'util';
import { idb, fire } from '../../index';
import imgNotAvailable from '../../assets/images/not-available.jpg';
import PhotosGrid from '../shared/PhotosGrid';
import { JOB_DOCUMENT_SOURCE_FROM_APP, JOB_DOCUMENT_SOURCE_OTHER } from '../../utils/database/indexdb/IndexDb';
import { withTranslation } from 'react-i18next';
import { validateUploadedImages } from '../../utils/shared';

interface JobTaskPhotosState {
	photos: JobTask.JobTaskPhoto[];
	syncDocsLoading: boolean;
	errorMsg: string;
}
interface JobTaskPhotosProps {
	history?: History;
	documentId: string;
	isComplete: boolean;
	t: any;
	expandTakenForApp: boolean;
	handleExpandTakenForApp: (value: boolean) => void;
	expandSentByMainApp: boolean;
	handleExpandSentByMainApp: (value: boolean) => void;
	expandUncategorised: boolean;
	handleExpandUncategorised: (value: boolean) => void;
	openImageDialog: () => void;
	saveImage: (selectorFiles: FileList | null, isComplete: boolean) => Promise<void>;
	completedDate: number | null | undefined;
}

class JobTaskPhotos extends React.Component<JobTaskPhotosProps, JobTaskPhotosState> {
	constructor(props) {
		super(props);
		this.state = {
			photos: [],
			syncDocsLoading: false,
			errorMsg: ""
		};
		this.getPhotos = this.getPhotos.bind(this);
	}

	async componentDidMount() {
		this.getPhotos();
		this.watchPhotos();
		this._synFromFireBase();
	}

	watchPhotos() {
		idb.db.documents.hook('creating', (_primaryKey, document) => {
			let photo = idb.parseDocumentToPhoto(document);
			if (!isNullOrUndefined(photo)) this.addPhoto(photo);
		});
	}

	getPhotos() {
		idb
			.getPhotosFromDocuments(this.props.documentId)
			.then(async photos => {
				if (photos.length > 0) {
					photos.forEach(photo => {
						this.addPhoto(photo);
					});
				}
			})
			.catch(err => console.error(err));
	}

	private async _synFromFireBase() {
		if (navigator.onLine) {
			this.setState({ syncDocsLoading: true });
			await idb.syncPhotosCardFromFb(this.props.documentId, `JobTasks`, "", this.getPhotos);
			this.setState({ syncDocsLoading: false });
		}
	}

	addPhoto(photo: indexDb.Photos) {
		let photos = this.state.photos;
		const isPhotoExist = photos && photos.some(_photo => _photo.Guid === photo.guid);

		if (!isPhotoExist) {
			let photoURL = imgNotAvailable;

			if (photo.file.byteLength !== new ArrayBuffer(0).byteLength) {
				photoURL = URL.createObjectURL(new Blob([photo.file]));
			}

			if (photo.file.byteLength > 0) {
				photos.push({
					Guid: photo.guid,
					FirebaseStoragePath: photoURL,
					Filename: photo.fileName,
					UploadedBy: photo.uploadedBy,
					Source: photo.source,
					DateCreated: photo.dateCreated,
				});
			}
			this.setState({ photos: photos });
		}

	}

	photoExists = Id => {
		if (this.state.photos === undefined) return false;
		return this.state.photos.some(el => el.Guid === Id);
	};

	handleDownloadPhoto = (curPhoto, filename) => {
		const downloadLink = document.createElement('a');
		downloadLink.href = curPhoto;
		downloadLink.setAttribute('download', filename);
		downloadLink.click();
	}

	handleDownloadAllPhotos = (photos) => {
		photos.forEach((photo) => {
			this.handleDownloadPhoto(photo.FirebaseStoragePath, photo.Filename);
		});
	}

	handleRemovePhoto = (guid, docReference) => {
		const photoQuery = fire.baseQuery.collection('JobTasks').doc(docReference).collection('Documents').where('Guid', '==', guid);

		photoQuery.get().then(function (snapshot) {
			const docId = snapshot.docs[0].id;

			fire.baseQuery.collection('JobTasks').doc(docReference).collection('Documents').doc(docId).delete();
			idb.removePhotoByGuid(guid);

			if (navigator.onLine) {
				const fileStorageLocation = snapshot.docs[0].data()["FirebaseRef"];
				const storage = firebase.storage();
				const storageRef = storage.ref();
				storageRef.child(fileStorageLocation).delete();
			}

			// Post to JobQueue so that main application can remove the photo as well.
			const deleteDocumentObj = {
				JobAction: 'DeleteDocument',
				JobTaskFBID: docReference,
				FBID: docId
			}

			fire.postToJobQueue(docReference, deleteDocumentObj);

		}).then(() => {
			this.setState({ photos: [] })
			this.getPhotos();
		})
	}

	render() {
		return (
			<div>
				<input
					id="image-upload"
					type="file"
					multiple
					style={{ display: 'none' }}
					onChange={(_event) => {
						if (validateUploadedImages(_event.target.files)) {
							this.setState({ errorMsg: "" });
							this.props.saveImage(_event.target.files, !isNullOrUndefined(this.props.completedDate))
						} else {
							this.setState({ errorMsg: "Please check the file types. Only Photos are supported." });
						}
					}}
				/>
				{this.state.errorMsg && (
					<p className="error-msg">
						{this.props.t(this.state.errorMsg)}
					</p>
				)}
				<PhotosGrid
					key={"PhotosTakenFromApp"}
					history={this.props.history}
					jobTaskId={this.props.documentId}
					isComplete={this.props.isComplete}
					color=""
					photos={this.state.photos.filter(photo => photo.Source === JOB_DOCUMENT_SOURCE_FROM_APP)}
					titleText={this.props.t("Photos Taken From App")}
					noPhotosText={this.props.t("No Photos Taken From App")}
					isForRequest={false}
					canRemovePhoto={true}
					canDownloadPhoto={true}
					canEditPhoto={true}
					handleDownloadPhoto={this.handleDownloadPhoto}
					handleRemovePhoto={this.handleRemovePhoto}
					handleDownloadAllPhotos={() => this.handleDownloadAllPhotos(this.state.photos.filter(
						photo => photo.Source === JOB_DOCUMENT_SOURCE_FROM_APP
					))}
					displayDownloadAll={true}
					expand={this.props.expandTakenForApp}
					handleExpand={this.props.handleExpandTakenForApp}
					photosLoading={this.state.syncDocsLoading}
					fromTaskPhotos={true}
					showActionButton={true}
					openImageDialog={this.props.openImageDialog}
				/>

				<PhotosGrid
					key={"PhotosSentFromApp"}
					history={this.props.history}
					jobTaskId={this.props.documentId}
					isComplete={this.props.isComplete}
					color=""
					photos={this.state.photos.filter(photo => photo.Source === JOB_DOCUMENT_SOURCE_OTHER)}
					titleText={this.props.t("Photos Sent To App")}
					noPhotosText={this.props.t("No Photos Sent To App")}
					isForRequest={false}
					canRemovePhoto={false}
					canDownloadPhoto={true}
					canEditPhoto={true}
					handleDownloadPhoto={this.handleDownloadPhoto}
					handleDownloadAllPhotos={() => this.handleDownloadAllPhotos(this.state.photos.filter(
						photo => photo.Source === JOB_DOCUMENT_SOURCE_OTHER
					))}
					displayDownloadAll={true}
					expand={this.props.expandSentByMainApp}
					handleExpand={this.props.handleExpandSentByMainApp}
					photosLoading={this.state.syncDocsLoading}
					fromTaskPhotos={true}
					showActionButton={false}
					openImageDialog={this.props.openImageDialog}
				/>

				<PhotosGrid
					key={"PhotosUncategorised"}
					history={this.props.history}
					jobTaskId={this.props.documentId}
					isComplete={this.props.isComplete}
					color=""
					photos={this.state.photos.filter(
						photo => photo.Source !== JOB_DOCUMENT_SOURCE_FROM_APP && photo.Source !== JOB_DOCUMENT_SOURCE_OTHER,
					)}
					titleText={this.props.t("Uncategorised Photos")}
					noPhotosText=""
					isForRequest={false}
					canRemovePhoto={true}
					canDownloadPhoto={false}
					canEditPhoto={true}
					handleDownloadPhoto={this.handleDownloadPhoto}
					handleDownloadAllPhotos={() => this.handleDownloadAllPhotos(this.state.photos.filter(
						photo => photo.Source !== JOB_DOCUMENT_SOURCE_FROM_APP && photo.Source !== JOB_DOCUMENT_SOURCE_OTHER,
					))}
					displayDownloadAll={true}
					expand={this.props.expandUncategorised}
					handleExpand={this.props.handleExpandUncategorised}
					photosLoading={this.state.syncDocsLoading}
					fromTaskPhotos={true}
					showActionButton={false}
					openImageDialog={this.props.openImageDialog}
				/>
			</div>
		);
	}
}

export default withTranslation()(JobTaskPhotos)