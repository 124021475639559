import * as React from 'react';

const TextBoxAnswer = (props: {
	answer?: string | null;
	onChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
	onBlur: () => void;
	error: boolean;
	formComplete: boolean;
}) => {
	return (
		<div className="text-question-container">
			<input
				type="text"
				name="QuestionTextBoxAnswer"
				className={`number-question-container-input ${props.error ? 'input-error' : ''}`}
				value={props.answer || ''}
				onChange={val => props.onChange(val)}
				onBlur={props.onBlur}
				disabled={props.formComplete}
			/>
		</div>
	);
};

export default TextBoxAnswer;
