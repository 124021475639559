import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import { match} from 'react-router';
import { Link } from 'react-router-dom';
import { AsyncPaginate, Response } from 'react-select-async-paginate';

// Load Image for Saving files and Compressing them
import * as loadImage from 'blueimp-load-image';

// Config File
import Api from '../../apiConfiguration.json';

// Firebase + IndexedDB + localQueue
import { fire, idb, localQueue } from '../../index';

// Material UI
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';
import TextField from '@material-ui/core/TextField';
import FormControl from '@material-ui/core/FormControl';
import { Icon, IconButton, NativeSelect } from '@material-ui/core';

// Components
import SideDrawer from '../../components/shared/SideDrawer';
import AutoCompleteDropDown from '../../components/inputs/AutoCompleteDropdown';
import BackButton from '../../components/shared/BackButton';
import LoadingSpinner from '../../components/shared/LoadingSpinner';

// Utils
import { generateFirebaseId, generateGuid } from '../../utils/Guids';
import { fileToArrayBuffer } from '../../utils/Converters';
import { stringtoUnixUTC } from '../../utils/Times';
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../../utils/LocalStorage';
import { isNullOrUndefined } from 'util';
import { windowError, SendErrorData } from '../../utils/WindowError';
import { parseDateForDateTimeFields } from '../../utils/Times';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';
import DateOrPriority from '../../components/inputs/DateOrPriority';

// CSS
import '../../styles/new-issue/new-issue.css';
import Firebase from '../../utils/Firebase.js';
import { useTranslation, withTranslation } from 'react-i18next';
import { handleAddAsset } from '../../components/shared/addAssetFromQRCode';

interface NewAssetScreenProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface NewAssetScreenState {
	AssetID: number | null;
	AssetClass: string;
	AssetClassFBID: string | null;
	AssetSubClass: string;
	AssetSubClassFBID: string | null;
	AssetCode: string | null;
	AssetName: string;
	AssetStatus: string | null;
	Comments: string | null;
	DateCreated: number | null;
	LocationID: number | null;
	LocationName: string | null;
	SerialNumber: string | null;
	SiteName: string;
	SiteID: number | null;
	ClientFBID: string | undefined;
	SubLocationName: string | null;
	SubLocationID: number | null;
	Manufacturer: string | null;
	ModelNumber: string | null;
	QRCode: string | null;
	guid: string;
	AssetFBID: string;
	CreatingAsset: boolean;
	FieldsDisabled: boolean;
	AssetClassDisabled: boolean;
	SiteIDRequired: boolean;
	AssetNameRequired: boolean;
	AssetCodeRequired: boolean;
	AssetClassRequired: boolean;
	SiteRequired: boolean;
	CodeDuplicated: boolean;

	assetClassFilter?: {
		value: string;
		label: string;
	} | null;
	loadedAssetClasses: any[];
	assetClassesPerPage: 50;
	assetClassValue: '',
	endAssetClass: boolean;
	lastLoadedAssetClass: any;

	assetSubClassFilter?: {
		value: string;
		label: string;
	} | null;
	loadedAssetSubClasses: any[];
	assetSubClassesPerPage: 50;
	assetSubClassValue: '',
	endAssetSubClass: boolean;
	lastLoadedAssetSubClass: any;

	// Sites. Locations and Sub Locations
	sitesPerPage: number;
	lastLoadedSite: any;
	siteValue: string;
	loadedSites: [];
	endSite: boolean;
	sitesLocationCanBeSelected: boolean;
	siteFilter?: {
		value: number;
		label: string;
	} | null;

	locationsPerPage: number;
	lastLoadedLocation: any;
	locationValue: string;
	loadedLocations: [];
	endLocation: boolean;
	locationFilter?: {
		value: number;
		label: string;
	} | null;
	locationDisabled: boolean;

	subLocationsPerPage: number;
	lastLoadedSubLocation: any;
	subLocationValue: string;
	loadedSubLocations: [];
	endSubLocation: boolean;
	subLocationFilter?: {
		value: number;
		label: string;
	} | null;
	subLocationDisabled: boolean;

	enableTextSearchSite: boolean;
	enableTextSearchLocation: boolean;
	enableTextSearchSubLocation: boolean;
	
	siteMenuOpen: boolean;
	locationMenuOpen: boolean;
	sublocationMenuOpen: boolean;

	showKeyboard: boolean;
}

class AssetCreateScreen extends React.Component<NewAssetScreenProps, NewAssetScreenState> {
	private codes: any = '';
	private siteRef: React.RefObject<HTMLInputElement>;
	private locationRef: React.RefObject<HTMLInputElement>;
	private sublocationRef: React.RefObject<HTMLInputElement>;

	constructor(props) {
		super(props);

		this.state = {
			AssetID: null,
			AssetClass: '',
			AssetClassFBID: '',
			AssetSubClass: '',
			AssetSubClassFBID: null,
			AssetCode: '',
			AssetName: '',
			AssetStatus: '',
			Comments: '',
			DateCreated: null,
			LocationID: null,
			LocationName: '',
			SerialNumber: '',
			ClientFBID: fire.ClientID,
			SiteName: '',
			SiteID: null,
			SubLocationName: '',
			SubLocationID: null,
			Manufacturer: '',
			ModelNumber: '',
			QRCode: '',
			guid: '',
			AssetFBID: generateFirebaseId(),
			CreatingAsset: false,
			FieldsDisabled: false,
			AssetClassDisabled: false,
			SiteIDRequired: false,
			AssetNameRequired: false,
			AssetCodeRequired: false,
			AssetClassRequired: false,
			SiteRequired: false,
			CodeDuplicated: false,

			assetClassFilter: null,
			loadedAssetClasses: [],
			assetClassesPerPage: 50,
			assetClassValue: '',
			endAssetClass: false,
			lastLoadedAssetClass: null,

			assetSubClassFilter: null,
			loadedAssetSubClasses: [],
			assetSubClassesPerPage: 50,
			assetSubClassValue: '',
			endAssetSubClass: false,
			lastLoadedAssetSubClass: null,

			sitesPerPage: 50,
			lastLoadedSite: null,
			siteValue: '',
			loadedSites: [],
			endSite: false,
			sitesLocationCanBeSelected: true,
			siteFilter: null,

			locationsPerPage: 50,
			lastLoadedLocation: null,
			locationValue: '',
			loadedLocations: [],
			endLocation: false,
			locationFilter: null,
			locationDisabled: true,

			subLocationsPerPage: 50,
			lastLoadedSubLocation: null,
			subLocationValue: '',
			loadedSubLocations: [],
			endSubLocation: false,
			subLocationFilter: null,
			subLocationDisabled: true,

			enableTextSearchSite: false,
			enableTextSearchLocation: false,
			enableTextSearchSubLocation: false,

			siteMenuOpen: false,
			locationMenuOpen: false,
			sublocationMenuOpen: false,

			showKeyboard: false
		};
		this.siteRef = React.createRef();
		this.locationRef = React.createRef();
		this.sublocationRef = React.createRef();
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'NewAssetScreen',
		);

		/*
		const localItem = LocalstorageGetItem('NewAssetetails');
		if (!isNullOrUndefined(localItem) && localItem !== '') {
			const LocalJSONObject = JSON.parse(localItem);
			this.setState({
				AssetID: LocalJSONObject.AssetID,
				AssetClass: LocalJSONObject.AssetClass,
				AssetClassFBID: LocalJSONObject.AssetClassFBID,
				AssetCode: LocalJSONObject.AssetCode,
				AssetName: LocalJSONObject.AssetName,
				AssetStatus: LocalJSONObject.AssetStatus,
				Comments: LocalJSONObject.Comments,
				DateCreated: LocalJSONObject.DateCreated,
				LocationID: LocalJSONObject.LocationID,
				ClientFBID: LocalJSONObject.ClientFBID,
				LocationName: LocalJSONObject.LocationName,
				SerialNumber: LocalJSONObject.SerialNumber,
				SiteName: LocalJSONObject.SiteName,
				SiteID: LocalJSONObject.SiteID,
				SubLocationName: LocalJSONObject.SubLocationName,
				SubLocationID: LocalJSONObject.SubLocationID,
				Manufacturer: LocalJSONObject.Manufacturer,
				ModelNumber: LocalJSONObject.ModelNumber,
				QRCode: LocalJSONObject.QRCode,
			});
		}*/

		let selectedSiteID = this.props.history.location.state;
		if (!isNullOrUndefined(selectedSiteID)){
			const {
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName
			} = this.props.history.location.state;

			const siteFilter = {
				value: selectedSiteID,
				label: selectedSiteName
			};


			const locationFilter = {
				value: selectedLocationID,
				label: selectedLocationName
			}

			const subLocationFilter = {
				value: selectedSubLocationID,
				label: selectedSubLocationName
			}
			
			if(!isNullOrUndefined(selectedSubLocationID)){			
				this.setState({
					siteFilter,
					locationFilter,
					subLocationFilter,
					siteValue: selectedSiteName,
					locationValue: selectedLocationName,
					subLocationValue: selectedSubLocationName,
					SiteID: selectedSiteID,
					LocationID: selectedLocationID,
					SubLocationID: selectedSubLocationID,
					SiteName: selectedSiteName,
					LocationName: selectedLocationName,
					SubLocationName: selectedSubLocationName					
				});


			}

			else if(!isNullOrUndefined(selectedLocationID)){			
				this.setState({
					siteFilter,
					locationFilter,
					siteValue: selectedSiteName,
					locationValue: selectedLocationName,
					SiteID: selectedSiteID,
					LocationID: selectedLocationID,
					SiteName: selectedSiteName,
					LocationName: selectedLocationName
				});	

				fire.doesSubLocationsExist(selectedLocationID).then((res) => {
					this.setState({
						subLocationDisabled: !res
					});
					if(res){
						this.loadOptions(selectedSiteName, []);
						this.handleLocationChange(locationFilter);
					}
				});		
		}
			else if(!isNullOrUndefined(selectedSiteID)){
				this.setState({
					siteFilter,
					siteValue: selectedSiteName,
					SiteName: selectedSiteName,
					SiteID: selectedSiteID
				});

				fire.doesLocationsExist(selectedSiteID).then((res) => {
					this.setState({
						locationDisabled: !res
					});
					if(res){
						this.loadOptions(selectedSiteName, []);
						this.handleSiteChange(siteFilter);
					}
			});
			}
		}
	}

	async componentDidMount() {
		this.updatePredicate();
		window.addEventListener("resize", this.updatePredicate);

		const guid = this.props.history.location.state !== undefined ? this.props.history.location.state.guid : null;
		this.setState({ guid });
		//this.getSites();
		//this.getLocations();
		//this.getSubLocations();
		//this.getAssetClass();
		window.scrollTo(0, 0);

		const localItem = (await LocalstorageGetItem('QRCodeTextJson')) || '{}';
		const QRCodeTextJson = JSON.parse(localItem);

		if(QRCodeTextJson.QRCodeText !== null && QRCodeTextJson.QRCodeText !== undefined){
			this.setState({ QRCode: QRCodeTextJson.QRCodeText });
		}

		if(!this.props.UserSettings.CanAddAssets){
			alert(this.props.t("You do not have permissions to create assets"))
			history.back()
		}
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updatePredicate);
		LocalstorageRemoveItem("QRCodeTextJson");
	}

	updatePredicate = () => {
		this.setState({ showKeyboard: window.innerWidth < 600 })
	}

	loadAssetClasses = async() => {
		return new Promise<Response>(resolve => {
            fire.getSearchAssetClassesPaginated(this.state.assetClassesPerPage, this.state.lastLoadedAssetClass).get().then(docs => {
                if(!docs.empty) {
                    let assetClasses:any = [];
                    docs.forEach(function(assetClass) {
                        const assetClassData = {
                            value: assetClass.id,
                            label: assetClass.data().AssetClassName
                        }
                        assetClasses.push(assetClassData);
                    });
                    this.setState({ loadedAssetClasses: assetClasses, lastLoadedAssetClass: docs.docs[docs.docs.length - 1] }, () => {
                        return resolve({
                            options: this.state.loadedAssetClasses,
                            hasMore: !this.state.endAssetClass
                        });
                    })
                } else {
                    this.setState({ endAssetClass: true });
                    return resolve({
                        options: [],
                        hasMore: !this.state.endAssetClass
                    });
                }
            })
        })
	}

	loadAssetSubClasses = async() => {
		return new Promise<Response>(resolve => {
			fire.getSearchAssetSubClassesPaginated(this.state.assetSubClassesPerPage, this.state.AssetClassFBID, this.state.lastLoadedAssetSubClass).get().then(docs => {
				if(!docs.empty) {
					let assetSubClasses:any = [];
					docs.forEach(function(assetSubClass) {
						const assetSubClassData = {
							value: assetSubClass.id,
							label: assetSubClass.data().AssetSubClassName
						}
						assetSubClasses.push(assetSubClassData);
					});
					this.setState({ loadedAssetSubClasses: assetSubClasses, lastLoadedAssetSubClass: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedAssetSubClasses,
							hasMore: !this.state.endAssetSubClass
						})
					});
				} else {
					this.setState({ endAssetSubClass: true });
					return resolve({
						options: [],
						hasMore: !this.state.endAssetSubClass
					});
				}
			})
		})
	}

	loadOptions = async(siteName, loadedOptions) => {
        if(siteName !== this.state.siteValue) {
            await this.setState({ loadedSites: [], lastLoadedSite: null, siteValue: siteName });
            loadedOptions = [];
        }

		return new Promise<Response>(resolve => {
            fire.getSearchSitesPaginated(this.state.sitesPerPage, this.state.siteValue, this.state.lastLoadedSite).get().then(docs => {
                if(!docs.empty) {
                    let sites:any = [];
                    docs.forEach(function(site) {
                        const siteData = {
                            value: site.data().SiteID,
                            label: site.data().SiteName
                        }
                        sites.push(siteData);
                    });
                    this.setState({ loadedSites: sites, lastLoadedSite: docs.docs[docs.docs.length - 1] }, () => {
                        return resolve({
                            options: this.state.loadedSites,
                            hasMore: !this.state.endSite
                        });
                    })
                } else {
                    this.setState({ endSite: true });
                    return resolve({
                        options: [],
                        hasMore: !this.state.endSite
                    });
                }
            })
        })
	}

	loadLocations = async(locationName, loadedOptions) => {

		if(locationName !== this.state.locationValue) {
			await this.setState({ loadedLocations: [], lastLoadedLocation: null, locationValue: locationName });
			loadedOptions = [];
		}
	
		return new Promise<Response>(resolve => {
			if(!isNullOrUndefined(this.state.SiteID)) {
				fire.getSearchLocationsPaginated(this.state.locationsPerPage, this.state.SiteID, this.state.locationValue, this.state.lastLoadedLocation).get().then(docs => {
					if(!docs.empty) {
						let locations:any = [];
						docs.forEach(function(location) {
							const locationData = {
								value: location.data().LocationID,
								label: location.data().LocationName
							}
							locations.push(locationData);
						});
						this.setState({ loadedLocations: locations, lastLoadedLocation: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedLocations,
								hasMore: !this.state.endLocation
							});
						})
					} else {
						this.setState({ endLocation: true });
						return resolve({
							options: [],
							hasMore: !this.state.endLocation
						});
					}
				})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	loadSubLocations = async(subLocationName, loadedOptions) => {
		if(subLocationName !== this.state.subLocationValue) {
			await this.setState({ loadedSubLocations: [], lastLoadedSubLocation: null, subLocationValue: subLocationName });
			loadedOptions = [];
		}
	
		return new Promise<Response>(resolve => {
			if (!isNullOrUndefined(this.state.LocationID)) {
				fire.getSearchSubLocationsPaginated(this.state.subLocationsPerPage, this.state.LocationID, this.state.subLocationValue, this.state.lastLoadedSubLocation).get().then(docs => {
					if(!docs.empty) {
						let subLocations:any = [];
						docs.forEach(function(subLocation) {
							const subLocationData = {
								value: subLocation.data().SubLocationID,
								label: subLocation.data().SubLocationName
							}
							subLocations.push(subLocationData);
						});
						this.setState({ loadedSubLocations: subLocations, lastLoadedSubLocation: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedSubLocations,
								hasMore: !this.state.endSubLocation
							});
						})
					} else {
						this.setState({ endSubLocation: true });
						return resolve({
							options: [],
							hasMore: !this.state.endSubLocation
						});
					}
				})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	backButton = () => {
		//LocalstorageRemoveItem('NewAssetetails');
		window.history.back();
	};

	saveAsset = async () => {
		const errorsPresent = await this.checkForNullFields(
			this.state.SiteID,
			this.state.AssetName,
			this.state.AssetCode,
			this.state.AssetClass,
		);
		if (errorsPresent) {
			return;
		}
		this.setState({
			FieldsDisabled: true,
			AssetClassDisabled: true,
			//LocationDisabled: true,
			//SubLocationDisabled: true,
			CreatingAsset: true,
		});
		//LocalstorageRemoveItem('NewAssetetails');
		this.setState({ CreatingAsset: false });
		//this.props.history.replace('/asset-list/list/list');

		//this.props.history.replace('/job-list');

		const NewAssetDetails = {
			AssetID: this.state.AssetID,
			AssetClass: this.state.AssetClass,
			AssetClassFBID: this.state.AssetClassFBID,
			AssetSubClass: this.state.AssetSubClass,
			AssetSubClassFBID: this.state.AssetSubClassFBID,
			AssetCode: this.state.AssetCode,
			AssetName: this.state.AssetName,
			AssetStatus: this.state.AssetStatus,
			AssetStatusID : null,
			AssetFBID: this.state.AssetFBID,
			Comments: this.state.Comments,
			DateCreated: Date.now(),
			LocationID: this.state.LocationID,
			LocationName: this.state.LocationName,
			SerialNumber: this.state.SerialNumber,
			SiteName: this.state.SiteName,
			SiteID: this.state.SiteID,
			ClientFBID: this.state.ClientFBID,
			SubLocationName: this.state.SubLocationName,
			SubLocationID: this.state.SubLocationID,
			Manufacturer: this.state.Manufacturer,
			ModelNumber: this.state.ModelNumber,
			QRCode: this.state.QRCode,
		};
		fire.CreateAsset(NewAssetDetails, this.state.AssetFBID);

		const Details = {
			AssetID: this.state.AssetID,
			AssetClass: this.state.AssetClass,
			AssetClassFBID: this.state.AssetClassFBID,
			AssetSubClass: this.state.AssetSubClass,
			AssetSubClassFBID: this.state.AssetSubClassFBID,
			AssetCode: this.state.AssetCode,
			AssetFBID: this.state.AssetFBID,
			AssetName: this.state.AssetName,
			AssetStatus: this.state.AssetStatus,
			Comments: this.state.Comments,
			DateCreated: Date.now(),
			LocationID: this.state.LocationID,
			LocationName: this.state.LocationName,
			SerialNumber: this.state.SerialNumber,
			SiteName: this.state.SiteName,
			SiteID: this.state.SiteID,
			ClientFBID: this.state.ClientFBID,
			SubLocationName: this.state.SubLocationName,
			SubLocationID: this.state.SubLocationID,
			Manufacturer: this.state.Manufacturer,
			ModelNumber: this.state.ModelNumber,
			QRCode: this.state.QRCode,
			JobAction: 'CreateAsset',
		};

		fire.postToJobQueue(this.state.AssetFBID, Details).catch(err => {
			alert(this.props.t("There was an error creating the asset, if problem persists please email - support@trackplanfm.com"));
		});

		const localItem = (await LocalstorageGetItem('QRCodeTextJson')) || '{}';
		const QRCodeTextJson = JSON.parse(localItem);

		if(QRCodeTextJson.DocumentType === 'job' || QRCodeTextJson.DocumentType === 'form'){
			handleAddAsset(
						Details.AssetFBID,
						Details.AssetName,
						QRCodeTextJson.DocumentID,
						QRCodeTextJson.JobTaskID,
						QRCodeTextJson.DocumentType,
						QRCodeTextJson.QuestionAnswerID,
						this.props.history,
						this.props.t
					)
					LocalstorageRemoveItem("QRCodeTextJson");
		}else{
			this.props.history.replace(`/asset-nmdetails/${Details.AssetFBID}/list/list`);
			LocalstorageRemoveItem("QRCodeTextJson");
		}
	};

	checkForNullFields = async (SiteID, AssetName, AssetCode, AssetClass) => {
		let errors = 0;
		if (SiteID === null) {
			this.setState({ SiteIDRequired: true });
			errors += 1;
		} else {
			this.setState({ SiteIDRequired: false });
		}

		if (AssetName === undefined || AssetName === null || AssetName === '') {
			this.setState({ AssetNameRequired: true });
			errors += 1;
		} else {
			this.setState({ AssetNameRequired: false });
		}
		this.codes = (
			await fire.baseQuery
				.collection('Assets')
				.where('AssetCode', '==', AssetCode)
				.get()
		).empty;
		if (this.codes === false) {
			this.setState({ CodeDuplicated: true });
			errors += 1;
		} else {
			this.setState({ CodeDuplicated: false });
		}

		if (AssetCode === undefined || AssetCode === null || AssetCode === '') {
			this.setState({ AssetCodeRequired: true });
			errors += 1;
		} else {
			this.setState({ AssetCodeRequired: false });
		}
		if (AssetClass === undefined || AssetClass === null || AssetClass === '') {
			this.setState({ AssetClassRequired: true });
			errors += 1;
		} else {
			this.setState({ AssetClassRequired: false });
		}
		return errors > 0;
	};

	// eslint-disable-next-line prettier/prettier

	handleLogout() {
		LocalstorageRemoveItem('NewAssetDetails');
		fire.auth.signOut().then(() => location.reload());
	}

	private handleAssetClassChange = (object: { value: string; label: string }) => {
		if(!isNullOrUndefined(object)) {
			this.setState({
				assetClassFilter: object,
				AssetClassFBID: object.value,
				AssetClass: object.label,
				lastLoadedAssetClass: null,
			})
		}
		this.clearSelectedAssetSubClass();
	}

	private handleAssetSubClassChange = (object: { value: string; label: string }) => {
		if(!isNullOrUndefined(object)) {
			this.setState({
				assetSubClassFilter: object,
				AssetSubClassFBID: object.value,
				AssetSubClass: object.label,
				lastLoadedAssetSubClass: null,
			})
		}
	}

	private handleSiteChange = (object: { value: number; label: string }) => {
		this.setState({ siteMenuOpen: false });
		const SiteID = object.value;
		let siteidN: number | null = SiteID;
		if (!isNullOrUndefined(SiteID)) {
			this.setState({
				SiteName: object.label,
				SiteID: SiteID,
				LocationID: null,
				LocationName: '',
				SubLocationName: '',
				SubLocationID: null,
				lastLoadedSite: null,
				siteFilter: object,
				lastLoadedLocation: null,
				locationFilter: null,
				loadedLocations: [],
				locationValue: '',
				subLocationValue: '',
				loadedSubLocations: [],
				subLocationFilter: null,
			}, () => {
				if(!isNullOrUndefined(this.state.siteFilter)) {
					fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
						this.setState({
							locationDisabled: !res
						})
					})
				}
			});
		} else {
			this.setState({
				SiteID: null,
				LocationName: '',
				LocationID: null,
				SubLocationName: '',
				SubLocationID: null,
				lastLoadedSite: null,
				siteFilter: null,
				lastLoadedLocation: null,
				locationFilter: null,
				loadedLocations: [],
				locationValue: '',
				lastLoadedSubLocation: null,
				subLocationFilter: null,
				loadedSubLocations: [],
				subLocationValue: '',
			});
		}
	};

	private handleLocationChange = (object: { value: number; label: string }) => {
		this.setState({ locationMenuOpen: false });
		const LocationID = object.value;
		if (!isNullOrUndefined(LocationID)) {
			this.setState({
				LocationName: object.label,
				LocationID: LocationID,
				SubLocationName: '',
				SubLocationID: null,
				lastLoadedLocation: null,
				locationFilter: object,
				subLocationValue: '',
				loadedSubLocations: [],
				subLocationFilter: null,
			}, () => {
				if(!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
					fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
						this.setState({
							subLocationDisabled: !res
						})
					})
				}
			});
		} else {
			this.setState({
				LocationName: '',
				LocationID: null,
				SubLocationName: '',
				SubLocationID: null,
				lastLoadedLocation: null,
				locationFilter: null,
				subLocationValue: '',
				loadedSubLocations: [],
				subLocationFilter: null,
			});
		}
	}

	private handleSubLocationChange = (object: { value: number; label: string }) => {
		this.setState({ sublocationMenuOpen: false });
		const SubLocationID = object.value;
		if (!isNullOrUndefined(SubLocationID)) {
			this.setState({
				SubLocationName: object.label,
				SubLocationID: SubLocationID,
				lastLoadedSubLocation: null,
				subLocationFilter: object,
			});
		} else {
			this.setState({
				SubLocationName: '',
				SubLocationID: null,
				lastLoadedSubLocation: null,
				subLocationFilter: null,
			});
		}
	};

	clearSelectedAssetClass = () => {
		this.setState({
			lastLoadedAssetClass: null,
			loadedAssetClasses: [],
			assetClassFilter: null,
			endAssetClass: false,
			AssetClass: '',
			AssetClassFBID: '',
		})
		this.clearSelectedAssetSubClass();
	}

	clearSelectedAssetSubClass = () => {
		this.setState({
			assetSubClassFilter: null,
			AssetSubClass: '',
			AssetSubClassFBID: null,
			endAssetSubClass: false,
			lastLoadedAssetSubClass: null,
			loadedAssetSubClasses: []
		})
	}

	clearSelectedSitesOrLocations = async(typeId) => {
		// typeId = 1 (Site), typeId = 2 (Location), typeId = 3 (Sub Location)
		// If site is cleared, then location and sub location is also cleared as well since they depend on selected site id.
		// If location is cleared, then sub location is also cleared since it depends on selected location id.

		if(typeId === 1) {
			await this.setState({ 
				SiteID: null,
				SiteName: '',
				lastLoadedSite: null, 
				loadedSites: [], 
				siteFilter: null, 
				siteValue: '', 
				endSite: false ,
				locationDisabled: true,
				subLocationDisabled: true,
			});
		}

		if(typeId === 1 || typeId === 2) {
			await this.setState({
				LocationName: '',
				LocationID: null,
				lastLoadedLocation: null,
				loadedLocations: [],
				locationFilter: null,
				locationValue: '',
				endLocation: false,
				subLocationDisabled: true,
			});
		}

		if(typeId === 1 || typeId === 2 || typeId === 3) {
			await this.setState({
				SubLocationName: '',
				SubLocationID: null,
				lastLoadedSubLocation: null,
				loadedSubLocations: [],
				subLocationFilter: null,
				subLocationValue: '',
				endSubLocation: false,
			});
		}
	}

	openSiteMenu = () => {
		this.setState({ siteMenuOpen: true}, () => { if(!isNullOrUndefined(this.siteRef.current)) this.siteRef.current.focus() });
	}

	closeSiteMenu = () => {
		this.setState({ siteMenuOpen: false });
	}

	openLocationMenu = () => {
		this.setState({ locationMenuOpen: true}, () => { if(!isNullOrUndefined(this.locationRef.current)) this.locationRef.current.focus() });
	}

	closeLocationMenu = () => {
		this.setState({ locationMenuOpen: false });
	}

	openSubLocationMenu = () => {
		this.setState({ sublocationMenuOpen: true}, () => { if(!isNullOrUndefined(this.sublocationRef.current)) this.sublocationRef.current.focus() });
	}

	closeSubLocationMenu = () => {
		this.setState({ sublocationMenuOpen: false });
	}


	render() {
		const { showKeyboard } = this.state;
		const iOS = !!navigator.platform && /iPad|iPhone|iPod/.test(navigator.platform);

		if (this.state.CreatingAsset === true) {
			return <LoadingSpinner text={this.props.t("Creating Asset...")} />;
		}
		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={this.props.t("Create New Asset")}
					rightMenuButton={<BackButton callbackMethod={this.backButton} />}
					colour="primary"
					handleLogout={this.handleLogout}
					User={this.props.UserSettings}
					versionApp={Api.VERSION}
					versionDb={Api.INDEXEDDB_VERSION}
					SendErrorData={SendErrorData}
					getBaseURL={getBaseURL}
					ApiKeyObj={ApiKeyObj}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main">
						<div className="padding-25-15">
							<div className="container-div" style={{ marginTop: 20 }}>
								<TextField
									id="Asset-Name"
									type="text"
									fullWidth={true}
									multiline={true}
									value={this.state.AssetName}
									onChange={event => this.setState({ AssetName: event.target.value })}
									label={this.props.t("Asset Name")}
									variant="standard"
									disabled={this.state.FieldsDisabled}
								/>
								<FieldEmptyWarning showField={this.state.AssetNameRequired} />
							</div>

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px' }}> {this.props.t("Asset Class")} </p>
								{/* <p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> 
									<span>Asset Class</span>
									{
										(/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) && (
											<IconButton onClick={() => this.setState({ enableTextSearchAssetClass: !this.state.enableTextSearchAssetClass }) }>
												<Icon>keyboard</Icon>	
											</IconButton>	
										)
									}
								</p> */}
								<AsyncPaginate
									id="assetClass-helper"
									value={this.state.assetClassFilter}
									onChange={this.handleAssetClassChange}
									loadOptions={this.loadAssetClasses}
									blurInputOnSelect={true}
									loadingMessage={() => this.props.t("Loading Asset Classes...")}
									noOptionsMessage={() => this.props.t("No Asset Classes Found")}
									cacheUniqs={[this.state.assetClassFilter]}
									isSearchable={false}
									//isSearchable={ (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) ? this.state.enableTextSearchSite : true }
								/>
								<br />
								{!isNullOrUndefined(this.state.assetClassFilter) && (
									<div style={{ marginTop: -5 }}>
										<span>{this.props.t("Selected") + ": "}</span>
										<IconButton onClick={() => this.clearSelectedAssetClass()} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.AssetClass}
									</div>
								)}
							</div>
							<FieldEmptyWarning showField={this.state.AssetClassRequired} />

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px' }}> {this.props.t("Asset Sub Class")}</p>
								<AsyncPaginate
									id="assetSubClass-helper"
									value={this.state.assetSubClassFilter}
									onChange={this.handleAssetSubClassChange}
									loadOptions={this.loadAssetSubClasses}
									loadingMessage={() => this.props.t("Loading Asset Sub Classes...")}
									blurInputOnSelect={true}
									noOptionsMessage={() => this.props.t("No Asset Sub Classes Found")}
									cacheUniqs={[this.state.assetSubClassFilter, this.state.assetClassFilter]}
									isSearchable={false}
								/>
								<br />
								{!isNullOrUndefined(this.state.assetSubClassFilter) && (
									<div style={{ marginTop: -5, marginBottom: 10 }}> 
										<span>{this.props.t("Selected") + ": "} </span>
										<IconButton onClick={() => this.clearSelectedAssetSubClass()} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.AssetSubClass}
									</div>
								)}
							</div>

							<div className="container-div">
								<TextField
									id="Asset-id"
									type="text"
									fullWidth={true}
									multiline={true}
									value={this.state.AssetCode}
									onChange={event => this.setState({ AssetCode: event.target.value })}
									label={this.props.t("Asset Code")}
									variant="standard"
									disabled={this.state.FieldsDisabled}
								/>
								<FieldEmptyWarning showField={this.state.AssetCodeRequired} />
								<FieldDuplicateWarning showField={this.state.CodeDuplicated} />
							</div>
							
							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> 
								<span>{this.props.t("Site")}</span>
									{
										(showKeyboard) && (
											<IconButton onClick={() => this.setState({ enableTextSearchSite: !this.state.enableTextSearchSite }, () => this.openSiteMenu()) }>
												<Icon style={{ color: this.state.enableTextSearchSite ? 'green' : '' }} >keyboard</Icon>	
											</IconButton>	
										)
									}
								</p>
								<AsyncPaginate
									id="site-helper"
									value={this.state.siteFilter}
									selectRef={this.siteRef}
									onFocus={this.openSiteMenu}
									onBlur={this.closeSiteMenu}
									menuIsOpen={this.state.siteMenuOpen}
									onChange={this.handleSiteChange}
									loadOptions={this.loadOptions}
									blurInputOnSelect={true}
									loadingMessage={() => this.props.t("Loading Sites...")}
									noOptionsMessage={() => this.props.t("No Sites Found")}
									cacheUniqs={[this.state.siteFilter, this.state.siteValue]}
									isSearchable={ showKeyboard ? this.state.enableTextSearchSite : true }
								/>
								<br />
								{!isNullOrUndefined(this.state.siteFilter) && (
									<div style={{ marginTop: -5 }}>
										<span>{this.props.t("Selected") + ": "} </span>
										<IconButton onClick={() => this.clearSelectedSitesOrLocations(1)} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.SiteName}
									</div>
								)}
							</div>
							<FieldEmptyWarning showField={this.state.SiteIDRequired} />

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> 
								<span>{this.props.t("Location")}</span>
									{
										(showKeyboard && !this.state.locationDisabled) && (
											<IconButton onClick={() => this.setState({ enableTextSearchLocation: !this.state.enableTextSearchLocation }, () => this.openLocationMenu()) }>
												<Icon style={{ color: this.state.enableTextSearchLocation ? 'green' : '' }} >keyboard</Icon>	
											</IconButton>	
										)
									}
									
								</p>
								<AsyncPaginate
									id="location-helper"
									value={this.state.locationFilter}
									selectRef={this.locationRef}
									onFocus={this.openLocationMenu}
									onBlur={this.closeLocationMenu}
									menuIsOpen={this.state.locationMenuOpen}
									onChange={this.handleLocationChange}
									loadOptions={this.loadLocations}
									blurInputOnSelect={true}
									loadingMessage={() => this.props.t("Loading Locations...")}
									noOptionsMessage={() => this.props.t("No Locations Found")}
									isDisabled={this.state.locationDisabled}
									cacheUniqs={[this.state.locationFilter, this.state.locationValue, this.state.SiteID]}
									isSearchable={ showKeyboard ? this.state.enableTextSearchLocation : true }
								/>
								<br />
								{!isNullOrUndefined(this.state.locationFilter) && (
									<div style={{ marginTop: -5, marginBottom: 15 }}>
										<span>{this.props.t("Selected") + ": "} </span>
										<IconButton onClick={() => this.clearSelectedSitesOrLocations(2)} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.LocationName}
									</div>
								)}
							</div>

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}> 
								<span>{this.props.t("Sub Location")}</span>
									{
										(showKeyboard && !this.state.subLocationDisabled) && (
											<IconButton onClick={() => this.setState({ enableTextSearchSubLocation: !this.state.enableTextSearchSubLocation }, () => this.openSubLocationMenu()) }>
												<Icon style={{ color: this.state.enableTextSearchSubLocation ? 'green' : '' }} >keyboard</Icon>	
											</IconButton>	
										)
									}	
								</p>
								<AsyncPaginate
									id="subLocation-helper"
									value={this.state.subLocationFilter}
									selectRef={this.sublocationRef}
									onFocus={this.openSubLocationMenu}
									onBlur={this.closeSubLocationMenu}
									menuIsOpen={this.state.sublocationMenuOpen}
									onChange={this.handleSubLocationChange}
									loadOptions={this.loadSubLocations}
									blurInputOnSelect={true}
									loadingMessage={() => this.props.t("Loading Sub Locations...")}
									isDisabled={this.state.subLocationDisabled}
									cacheUniqs={[this.state.subLocationFilter, this.state.subLocationValue, this.state.SiteID, this.state.LocationID]}
									isSearchable={ showKeyboard ? this.state.enableTextSearchSubLocation : true }
								/>
								<br />
								{!isNullOrUndefined(this.state.subLocationFilter) && (
									<div style={{ marginTop: -5, marginBottom: 15 }}>
										<span>{this.props.t("Selected") + ": "} </span>
										<IconButton onClick={() => this.clearSelectedSitesOrLocations(3)} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.LocationName}
									</div>
								)}
							</div>

							<div className="container-div">
								<TextField
									id="SerialNumber"
									type="text"
									fullWidth={true}
									multiline={true}
									value={this.state.SerialNumber}
									onChange={event => this.setState({ SerialNumber: event.target.value })}
									label={this.props.t("Serial Number")}
									variant="standard"
									disabled={this.state.FieldsDisabled}
								/>
							</div>
							<div className="container-div" style={{ marginTop: 20 }}>
								<TextField
									id="ModelNumber"
									type="text"
									fullWidth={true}
									multiline={true}
									value={this.state.ModelNumber}
									onChange={event => this.setState({ ModelNumber: event.target.value })}
									label={this.props.t("Model Number")}
									variant="standard"
									disabled={this.state.FieldsDisabled}
								/>
							</div>
							<div className="container-div" style={{ marginTop: 20 }}>
								<TextField
									id="Manufacturer"
									type="text"
									fullWidth={true}
									multiline={true}
									value={this.state.Manufacturer}
									onChange={event => this.setState({ Manufacturer: event.target.value })}
									label={this.props.t("Manufacturer")}
									variant="standard"
									disabled={this.state.FieldsDisabled}
								/>
							</div>
			
							<Button
								color="primary"
								variant="contained"
								style={{ backgroundColor: 'green', marginTop: 15 }}
								onClick={() => this.saveAsset()}
								disabled={this.state.FieldsDisabled}
							>
								<Icon>save</Icon>&nbsp; {this.props.t("Create")}
							</Button>
						</div>
					</div>
				</Grid>
			</div>
		);
	}
}

const FieldEmptyWarning = props => {
	const [t, i18n] = useTranslation();
	return (
	<div style={{ ...props.style, paddingTop: 10 }}>
		<p
			style={{
				color: '#c62828',
				fontStyle: 'italic',
				display: props.showField ? 'flex' : 'none',
			}}
		>
			{t("This field is required.")}
		</p>
	</div>
)};

const FieldDuplicateWarning = props => {
	const [t, i18n] = useTranslation();
	return (
	<div style={{ ...props.style, paddingTop: 10 }}>
		<p
			style={{
				color: '#c62828',
				fontStyle: 'italic',
				display: props.showField ? 'flex' : 'none',
			}}
		>
			{t("This value is already used.")}
		</p>
	</div>
)};

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(AssetCreateScreen));
