import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Grid,
	TextField,
	useMediaQuery
} from '@material-ui/core';
import useTheme from '@material-ui/core/styles/useTheme';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';
import '../../styles/storage-areas/storage-areas-search-modal.css';
import SearchSelect from './SearchSelect';

interface StorageAreasSearchModalProps {
	isOpen: boolean;
	close(): void;
	search: string;
	setSearch: (search: string) => void;
	selectedSite: any;
	handleSiteChange: (newSite: any) => void;
	selectedLocation: any;
	handleLocationChange: (newLocation: any) => void;
	selectedSubLocation: any;
	handleSubLocationChange: (newSubLocation: any) => void;
	searchStorageAreas(): void;
}

const StorageAreasSearchModal = (props: StorageAreasSearchModalProps) => {
	const [t, i18n] = useTranslation();
	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		props.setSearch(e.target.value);
	};

	return (
		<Dialog
			open={props.isOpen}
			onClose={props.close}
			aria-labelledby={t("Storage Areas Search")}
			fullScreen={useMediaQuery(useTheme().breakpoints.down('xs'))}
		>
			<DialogTitle>{t("Search Storage Areas")}</DialogTitle>
			<DialogContent style={{ height: '580px' }}>
				<Grid container spacing={5}>
					{/* <Grid item xs={12}>
						<TextField
							fullWidth
							label={t("Search")}
							variant="outlined"
							margin="dense"
							value={props.search}
							onChange={handleSearchChange}
						/>
					</Grid> */}
				<SearchSelect
					label={t("Site")}
					collectionName="Sites"
					nameProperty="SiteName"
					selectedOption={props.selectedSite}
					setSelectedOption={props.handleSiteChange}
					isDisabled={false}
				/>
				<SearchSelect
					label={t("Location")}
					collectionName="Locations"
					nameProperty="LocationName"
					selectedOption={props.selectedLocation}
					setSelectedOption={props.handleLocationChange}
					isDisabled={!props.selectedSite}
					dependsOn={props.selectedSite}
					dependsOnField="SiteID"
				/>
				<SearchSelect
					label={t("Sublocation")}
					collectionName="SubLocations"
					nameProperty="SubLocationName"
					selectedOption={props.selectedSubLocation}
					setSelectedOption={props.handleSubLocationChange}
					isDisabled={!props.selectedLocation}
					dependsOn={props.selectedLocation}
					dependsOnField="LocationID"
				/>
				</Grid>
			</DialogContent>
			<DialogActions classes={{ root: 'dialog-action-buttons' }}>
				<Button onClick={props.close} color="primary">
					{t("Cancel")}
				</Button>
				<Button onClick={props.searchStorageAreas} color="primary">
					{t("Search")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

export default StorageAreasSearchModal;
