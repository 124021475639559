// React
import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import QRCode from 'react-qr-code';

// Material UI
import DialogContent from '@material-ui/core/DialogContent';
import {
	Button,
	Grid
} from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { fire } from '../../index';
import { connect } from 'react-redux';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BuildIcon from '@material-ui/icons/Build';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';
import { fireSiteConverter } from '../../utils/FirebaseConverters';

// Components
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import FieldHolder from '../../components/styled-components/FieldHolderSiteCard';

// Utils
import { SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import Api from '../../apiConfiguration.json';
import ApiKeyObj from '../../utils/ApiObjectKey';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { readAndAddQRCode } from '../../utils/QRCode';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { useTranslation, withTranslation } from 'react-i18next';
import FormsListCards from '../../components/shared/FormsListCards';
import PhotosCard from '../../components/shared/PhotosCard';
import DocumentsCard from '../../components/shared/DocumentsCard';
import FieldHolderName from '../../components/styled-components/FieldHolderName';


interface SiteDetailProps {
	UserSettings: Store.UserSettings;
	match: match;
	history: History;
	t: any;
}
interface SiteDetailsState {
	JobTaskIds: string[] | null;
	JobTasks: JobTask.JobTask[];
	Assets: Asset[];
	siteDetails: Site | null;
	SearchbyQr: boolean;
	SiteFBID: string;
}



const routeToAssociatedJobTasks = (selectedSite: Site | null, history: History) => {
	if (!isNullOrUndefined(selectedSite)) {
		let ids: any;
		ids = selectedSite.SiteFBID;
		history.push({
			pathname: '/associated-jobtasks',
			state: {
				SelectedSiteID: selectedSite.SiteID,
				SelectedSiteName: selectedSite.SiteName
			},
		});
	}
};



const routeToAssociatedAssets = (selectedSite: Site | null, history: History) => {
	if (!isNullOrUndefined(selectedSite)) {
		let ids: any;
		ids = selectedSite.SiteFBID;
		history.push({
			pathname: '/asset-list/list/list',
			state: {
				selectedSiteID: selectedSite.SiteID,
				selectedSiteName: selectedSite.SiteName
			},
		});
	}
};

class SiteDetails extends React.Component<SiteDetailProps, SiteDetailsState> {
	constructor(props) {
		super(props);
		this.state = {
			JobTaskIds: [],
			JobTasks: [],
			Assets: [],
			siteDetails: null,
			SearchbyQr: false,
			SiteFBID: '',
		};
	}

	async componentWillReceiveProps(newProps: SiteDetailProps) {
		const { id } = newProps.match.params as any;
		await this.handleComponentLoad(id);
	}

	handleComponentLoad(id: string) {
		const site = fire.getDocumentQuery('Sites', id).withConverter(fireSiteConverter);

		site.onSnapshot(siteObj => {
			const Site = siteObj.data();
			Site!.QRCode = Site!.QRCode || '';
			this.setState({
				siteDetails: Site!,
			});

			if (!isNullOrUndefined(this.state.siteDetails)) {
				this.getJobTasks(this.state.siteDetails.SiteID);
				this.getAssets(this.state.siteDetails.SiteID);
			}
		});
	}

	async componentDidMount() {
		const { id } = this.props.match.params as any;
		this.setState({ SiteFBID: id });

		await this.handleComponentLoad(id);
	}

	sortingFunction = (joblist: JobTask.JobTask[]) => {
		return joblist.sort((a, b) => {
			if (a.JobTaskNumber === b.JobTaskNumber) return 0;
			else if (a.JobTaskNumber === 0) return -1;
			else if (b.JobTaskNumber === 0) return 1;
			else if (a.JobTaskNumber < b.JobTaskNumber) return 1;
			else if (b.JobTaskNumber < a.JobTaskNumber) return -1;
			else return 0;
		});
	};

	getJobTasks(id) {
		let JobTaskArray: JobTask.JobTask[] = [];
		if (!isNullOrUndefined(id)) {
			fire.baseQuery
				.collection('JobTasks')
				.where('SiteID', '==', id)
				.get()
				.then(siteJobs => {
					JobTaskArray = siteJobs.docs.map(siteJob => {
						let data = siteJob.data() as JobTask.JobTask;
						data.JobTaskNumber = isNullOrUndefined(data.JobTaskNumber) ? 0 : data.JobTaskNumber;
						return data;
					});
					JobTaskArray = this.sortingFunction(JobTaskArray);
					this.setState({ JobTasks: JobTaskArray });
				});
		}
	}

	getAssets(id) {
		let assetArray: Asset[] = [];
		if (!isNullOrUndefined(id)) {
			fire.baseQuery
				.collection('Assets')
				.where('SiteID', '==', id)
				.get()
				.then(siteAssets => {
					assetArray = siteAssets.docs.map(siteAsset => {
						let data = siteAsset.data() as Asset;
						return data;
					});
					this.setState({ Assets: assetArray });
				});
		}
	}

	backButton = () => {
		window.history.back();
	};

	handleLogout() {
		fire.auth.signOut().then(() => location.reload());
	}

	routeLocations() {
		this.props.history.push('/locations-list/' + this.state.SiteFBID);
	}

	routeSiteJobTasks() {
		this.props.history.push('/jobtasks-for-site/');
	}

	JobTaskDetailsButton = async id => {
		let doesJobTaskExist = await fire.doesJobExist(id);
		if (doesJobTaskExist) {
			if (!isNullOrUndefined(this.state.siteDetails)) {
				this.props.history.push('/jobtask-details/' + id + '/' + this.state.SiteFBID);
			} else {
				this.props.history.push('/jobtask-details/' + id);
			}
		} else {
			alert(this.props.t('Job cannot be accessed'));
		}
	};

	AssetDetailsButton = async id => {
		this.props.history.push('/asset-nmdetails/' + id);
	};

	handleRaiseNewJobTaskfromSite = (selectedSite: Site | null, history: History) => {
		if (!isNullOrUndefined(selectedSite)) {
			let ids: any;
			ids = selectedSite.SiteFBID;
			history.push({
				pathname: '/new-request-from-Site/' + selectedSite.SiteFBID,
				state: {
					JobRequestDetails: this.props.t('Requested at Site:') + ' ' + selectedSite.SiteName,
					selectedSiteID: selectedSite.SiteID,
					selectedSiteName: selectedSite.SiteName,
					AssetsAttached: ids,
					guid: null,
				},
			});
		}
	};

	handleCreateNewAssetfromSite = (selectedSite: Site | null, history: History, UserSettings: Store.UserSettings) => {
		if (UserSettings.CanAddAssets === false) {
			alert(
				this.props.t(
					'This user is not authorized to create assets. If this is a mistake, please contact your administrator and ensure permission has been granted to create assets from the main dashboard.',
				),
			);
			return;
		}

		if (!isNullOrUndefined(selectedSite)) {
			let ids: any;
			ids = selectedSite.SiteFBID;
			history.push({
				pathname: '/new-asset',
				state: {
					selectedSiteID: selectedSite.SiteID,
					selectedSiteName: selectedSite.SiteName,
				},
			});
		}
	};

	render() {
		if (!isNullOrUndefined(this.state.siteDetails)) {
			const title =
				this.state.siteDetails.SiteName.length > 15
					? this.state.siteDetails.SiteName.substr(0, 15) + '...'
					: this.state.siteDetails.SiteName;

			return (
				<div className="assetDetailsBackground">
					<SideDrawer
						history={this.props.history}
						title={title}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					{!isNullOrUndefined(this.state.siteDetails) ? (
						<Grid container={true} direction="column" justify="center" alignItems="stretch">
							<DialogContent>
								<div id="details-outer-asset">
									<div className="top-card-alt card-shadow-alt">
										<FieldHolderName
											content={this.state.siteDetails.SiteName}
											label={this.props.t('Site Name')}
											fullLine={true}
										/>
										<FieldHolder content={this.state.siteDetails.SiteCode} label={this.props.t('Site Code')} />
										<FieldHolder content={this.state.siteDetails.SiteType} label={this.props.t('Site Type')} />
										<FieldHolder content={this.state.siteDetails.MainContact} label={this.props.t('Main Contact')} />
										<FieldHolder content={this.state.siteDetails.Telephone} label={this.props.t('Telephone Number')} />
									</div>
									<div className="top-card-alt card-shadow-alt">
										<FieldHolder
											content={this.state.siteDetails.Address}
											label={this.props.t('Address')}
											fullLine="true"
										/>
										<FieldHolder content={this.state.siteDetails.Region} label={this.props.t('Region')} />
										<FieldHolder content={this.state.siteDetails.Town} label={this.props.t('Town')} />
										{!isNullOrUndefined(this.state.siteDetails.Contract) && (
											<FieldHolder content={this.state.siteDetails.Contract} label={this.props.t('Contract')} />
										)}
									</div>
									<div className="top-card-alt card-shadow-alt">
										<FieldHolder content={this.state.siteDetails.QRCode} label={this.props.t('QRCode')} />
										<QRCodeDialog
											isOpen={this.state.SearchbyQr}
											close={() => this.setState({ SearchbyQr: false })}
											readAndAddQRCode={QRCode => readAndAddQRCode(QRCode, 'Sites', 'Site', this.props.match.params.id)}
										/>
										{this.state.siteDetails.QRCode && this.state.siteDetails.QRCode !== '' ? (
											<QRCode
												bgColor="#FFFFFF"
												fgColor="#000000"
												level="L"
												size={64}
												value={this.state.siteDetails.QRCode as string}
											/>
										) : (
											<Button
												onClick={() => this.setState({ SearchbyQr: true })}
												className='add-qr-code-button'
												variant="outlined"
												size="large"
											>
												{this.props.t('Add QR Code')}{' '}
												<CameraEnhanceIcon className='padding-left'></CameraEnhanceIcon>
											</Button>
										)}
									</div>
									<FormsListCards fromView="Site" associatedFBID={this.state.SiteFBID}
									 areTemplatesRestricted={this.state.siteDetails.FormTemplatesRestricted != null ? 
										this.state.siteDetails.FormTemplatesRestricted : false}
									  restrictedTemplates={this.state.siteDetails.RestrictedFormTemplates != null ? 
									  this.state.siteDetails.RestrictedFormTemplates : []}></FormsListCards>
									<PhotosCard fromView="Site" associatedFBID={this.state.SiteFBID}></PhotosCard>
									<DocumentsCard fromView="Site" associatedFBID={this.state.SiteFBID}></DocumentsCard>
									<div className="top-card action-buttons">
										<Button
											onClick={() => this.routeLocations()}
											className='view-locations-button'
											variant="contained"
											color="secondary"
											size="large"
										>
											{this.props.t('View Locations')}
											<HomeWorkIcon className='padding-left'></HomeWorkIcon>
										</Button>
									</div>
									<div className="top-card-alt-with-two-buttons card-shadow-alt">
										<Button
											onClick={() => routeToAssociatedJobTasks(this.state.siteDetails, this.props.history)}
											className='view-job-tasks-button'
											variant="contained"
											color="secondary"
											size="large"
										>
											{this.props.t('View Job Tasks')} <BuildIcon className='padding-left'></BuildIcon>
										</Button>
										<Button
											onClick={() => this.handleRaiseNewJobTaskfromSite(this.state.siteDetails, this.props.history)}
											variant="outlined"
											className='raise-job-task-button'
											size="large"
										>
											{this.props.t('Raise Job Task')}
											<AddCircleIcon className='padding-left'></AddCircleIcon>
										</Button>
									</div>
									<div className="top-card-alt-with-two-buttons card-shadow-alt">
										<Button
											onClick={() => routeToAssociatedAssets(this.state.siteDetails, this.props.history)}
											className='view-assets-button'
											variant="contained"
											color="secondary"
											size="large"
										>
											{this.props.t('View Assets')} <DynamicFeedIcon className='padding-left'></DynamicFeedIcon>
										</Button>
										<Button
											onClick={() =>
												this.handleCreateNewAssetfromSite(
													this.state.siteDetails,
													this.props.history,
													this.props.UserSettings,
												)
											}
											variant="outlined"
											className='create-asset-button'
											size="large"
										>
											{this.props.t('Create New Asset')}
											<AddBoxIcon className='padding-left'></AddBoxIcon>
										</Button>
									</div>
								</div>
							</DialogContent>
						</Grid>
					) : null}
				</div>
			);
		}
		return <LoadingSpinner color="primary" text={this.props.t('Loading Site Details')} />;
	}
}
const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(SiteDetails));
