import * as React from 'react';
import * as loadImage from 'blueimp-load-image';
import imageCompression from 'browser-image-compression';

import { Icon, IconButton } from '@material-ui/core';
import SignaturePad from 'signature_pad';
import { History } from 'history';

import '../../styles/photos/edit-photo.css';
import { idb, localQueue, fire } from '../../index';
import { fileToArrayBuffer } from '../../utils/Converters';
import { generateFirebaseId } from '../../utils/Guids';
import { getLocation } from '../../utils/Geolocation';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import { windowError } from '../../utils/WindowError';
import { connect } from 'react-redux';
import { JOB_DOCUMENT_SOURCE_FROM_APP } from '../../utils/database/indexdb/IndexDb';

interface EditPhotoScreenProps {
	photoUri: string;
	clearPhoto: () => void;
	jobTaskId: string;
	newJob: boolean;
	history: History;
	guid: string | null;
	UserSettings: Store.UserSettings;
	isComplete: boolean;
	fromFormFail: boolean;
	formId: string;
	formFailData: any;
	shouldRedirect?: boolean | true;
	noRedirectCallback?: () => void;
}

interface EditPhotoScreenState {
	disableSaveButton: boolean;
}

class EditPhotoScreen extends React.PureComponent<EditPhotoScreenProps, EditPhotoScreenState> {
	private signaturePad: SignaturePad | null;
	private photoCanvas: HTMLCanvasElement | null;
	private drawingCanvas: HTMLCanvasElement | null;

	constructor(props) {
		super(props);
		this.signaturePad = null;
		this.photoCanvas = null;
		this.drawingCanvas = null;
		this.state = { disableSaveButton: false };
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'EditPhotoScreen',
		);
	}

	componentDidMount() {
		const canvasParent = document.getElementById('rendered-canvas');
		this.photoCanvas = document.getElementById('photo-canvas') as HTMLCanvasElement;
		this.drawingCanvas = document.getElementById('drawing-canvas') as HTMLCanvasElement;
		this.signaturePad = new SignaturePad(this.drawingCanvas);

		if (
			this.photoCanvas !== null &&
			this.drawingCanvas.parentElement !== null &&
			this.photoCanvas.parentElement !== null
		) {
			const photoContext = this.photoCanvas.getContext('2d') as CanvasRenderingContext2D;
			const drawingContext = this.photoCanvas.getContext('2d') as CanvasRenderingContext2D;

			const width = canvasParent !== null ? canvasParent.clientWidth : window.innerWidth;
			const height = window.innerHeight;

			const imageObj = new Image();
			imageObj.onload = () => {
				photoContext.imageSmoothingEnabled = false;
				let imageWidth = imageObj.width;
				let imageHeight = imageObj.height;
				let ratio = Math.min(width / imageWidth, height / imageHeight);
				imageWidth = imageWidth * ratio;
				imageHeight = imageHeight * ratio;

				if (this.drawingCanvas !== null && this.photoCanvas !== null) {
					this.drawingCanvas.width = imageWidth;
					this.drawingCanvas.height = imageHeight;

					this.photoCanvas.width = imageWidth;
					this.photoCanvas.height = imageHeight;

					if (canvasParent !== null) {
						canvasParent.style.height = (imageHeight + 50) + 'px';
					}

					drawingContext.clearRect(0, 0, this.drawingCanvas.width, this.drawingCanvas.height);
					//this.coverImg(photoContext, imageObj, "cover");
					photoContext.drawImage(
						imageObj,
						0,
						0,
						imageObj.width,
						imageObj.height,
						0,
						0,
						this.photoCanvas.width,
						this.photoCanvas.height,
					);
				}
			};
			imageObj.src = this.props.photoUri;
		}
	}
	
	// coverImg = (ctx, img, type = 'cover') => {
	// 	const imgRatio = img.height / img.width
	// 	const winRatio = window.innerHeight / window.innerWidth
	// 	if ((imgRatio < winRatio && type === 'contain') || (imgRatio > winRatio && type === 'cover')) {
	// 		const h = window.innerWidth * imgRatio
	// 		ctx.drawImage(img, 0, (window.innerHeight - h) / 2, window.innerWidth, h)
	// 	}
	// 	if ((imgRatio > winRatio && type === 'contain') || (imgRatio < winRatio && type === 'cover')) {
	// 		const w = window.innerWidth * winRatio / imgRatio
	// 		ctx.drawImage(img, (window.innerWidth - w) / 2, 0, w, window.innerHeight)
	// 	}
	// }

	blobToFile = (theBlob, fileName) => {
		//A Blob() is almost a File() - it's just missing the two properties below which we will add
		theBlob.lastModifiedDate = new Date();
		theBlob.name = fileName;
		return theBlob;
	}
	
	// Old method to compress
	// saveImage11 = () => {
	// 	this.setState({ disableSaveButton: true });
	// 	const mergedCanvas = document.createElement('canvas');
	// 	const context = mergedCanvas.getContext('2d');

	// 	mergedCanvas.height = this.drawingCanvas !== null ? this.drawingCanvas.height : 0;
	// 	mergedCanvas.width = this.drawingCanvas !== null ? this.drawingCanvas.width : 0;

	// 	if (context !== null && this.photoCanvas !== null && this.drawingCanvas !== null) {
	// 		context.drawImage(this.photoCanvas, 0, 0);
	// 		context.drawImage(this.drawingCanvas, 0, 0);

	// 		mergedCanvas.toBlob(async blob => {
	// 			if (blob === null) return;

	// 			loadImage(
	// 				blob,
	// 				(canvas: HTMLCanvasElement) => {
	// 					canvas.toBlob(async blob => {
	// 						//converts blob to array buffer
	// 						const arrayBuffer = await fileToArrayBuffer(blob as Blob);

	// 						// If its a new job request
	// 						if (this.props.newJob) {
	// 							idb.setPhotoForRequest(arrayBuffer, this.props.guid).then(guid => {
	// 								this.setState({ disableSaveButton: false });
	// 								if (this.props.fromFormFail) {
	// 									this.props.history.push({
	// 										pathname: `/new-request-form-fail/${this.props.formId}`,
	// 										state: {
	// 											guid,
	// 											fromFormFail: true,
	// 											jobRequestDetails: this.props.formFailData.jobRequestDetails,
	// 											selectedSiteName: this.props.formFailData.selectedSiteName,
	// 											selectedSiteID: this.props.formFailData.selectedSiteID,
	// 											selectedLocationName: this.props.formFailData.selectedLocationName,
	// 											selectedLocationID: this.props.formFailData.selectedLocationID,
	// 											selectedSubLocationName: this.props.formFailData.selectedSubLocationName,
	// 											selectedSubLocationID: this.props.formFailData.selectedSubLocationID,
	// 											taskID: this.props.formFailData.taskID,
	// 											formID: this.props.formFailData.formID,
	// 											questionID: this.props.formFailData.questionID,
	// 											questionRef: this.props.formFailData.questionRef,
	// 										},
	// 									});
	// 								} else {
	// 									this.props.history.replace('/job-task-list');
	// 									this.props.history.push({
	// 										pathname: `/new-request`,
	// 										state: { guid, formFormFail: false },
	// 									});
	// 								}
	// 							});
	// 						} else {
	// 							// if its for a photo on an existing job
	// 							idb
	// 								.savePhotoToLocal(
	// 									arrayBuffer,
	// 									this.props.jobTaskId,
	// 									this.props.isComplete.toString(),
	// 									`Contractor-Photo-${new Date().getTime()}.jpeg`,
	// 									undefined,
	// 									undefined,
	// 									undefined,
	// 									fire.currentUser.email || undefined,
	// 									JOB_DOCUMENT_SOURCE_FROM_APP,
	// 									new Date().getTime(),
	// 								)
	// 								.then(async guid => {
	// 									const geoLocation = await getLocation();
	// 									const photoNewFBID = generateFirebaseId();
	// 									await fire.CreatePhotoPlaceholder(photoNewFBID, this.props.jobTaskId, guid);

	// 									const jobTaskQueueObject = {
	// 										JobAction: 'AddPhotoExistingJobTask',
	// 										PhotoGuid: guid,
	// 										PhotoNewFBID: photoNewFBID,
	// 										Latitude: geoLocation.Latitude,
	// 										Longitude: geoLocation.Longitude,
	// 									};

	// 									await localQueue
	// 										.saveToLocalJobQueue(
	// 											this.props.jobTaskId,
	// 											jobTaskQueueObject,
	// 											undefined,
	// 											undefined,
	// 											undefined,
	// 											arrayBuffer,
	// 										)
	// 										.then(() => {
	// 											if (this.props.shouldRedirect) {
	// 												this.setState({ disableSaveButton: false });
	// 												this.props.history.replace('/job-task-list');
	// 												this.props.history.push(`/job-task-details/${this.props.jobTaskId}`);
	// 											} else {
	// 												// @ts-ignore
	// 												this.props.noRedirectCallback();
	// 											}
	// 										});
	// 								});
	// 						}
	// 					});
	// 				},
	// 				{  maxHeight: 800, maxWidth: 800, orientation: true },
	// 			);
	// 		});
	// 	}
	// };

	saveImage = () => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 800,
			useWebWorker: true
		}
		
		this.setState({ disableSaveButton: true });
		const mergedCanvas = document.createElement('canvas');
		const context = mergedCanvas.getContext('2d');

		mergedCanvas.height = this.drawingCanvas !== null ? this.drawingCanvas.height : 0;
		mergedCanvas.width = this.drawingCanvas !== null ? this.drawingCanvas.width : 0;

		if (context !== null && this.photoCanvas !== null && this.drawingCanvas !== null) {
			context.drawImage(this.photoCanvas, 0, 0);
			context.drawImage(this.drawingCanvas, 0, 0);

			mergedCanvas.toBlob(async blob => {
				if (blob === null) return;

				const file = this.blobToFile(blob, "image.jpg");
				//const compressedFile = await imageCompression(file, options);

				//const CompressionRatio = GetFileCompressionRatio(blob);

				const arrayBuffer = await fileToArrayBuffer(file);

				// If its a new job request
				if(this.props.newJob) {
					idb.setPhotoForRequest(arrayBuffer, this.props.guid).then(guid => {
						this.setState({ disableSaveButton: false });
						if (this.props.fromFormFail) {
							this.props.history.push({
								pathname: `/new-request-form-fail/${this.props.formId}`,
								state: {
									guid,
									fromFormFail: true,
									jobRequestDetails: this.props.formFailData.jobRequestDetails,
									selectedSiteName: this.props.formFailData.selectedSiteName,
									selectedSiteID: this.props.formFailData.selectedSiteID,
									selectedLocationName: this.props.formFailData.selectedLocationName,
									selectedLocationID: this.props.formFailData.selectedLocationID,
									selectedSubLocationName: this.props.formFailData.selectedSubLocationName,
									selectedSubLocationID: this.props.formFailData.selectedSubLocationID,
									taskID: this.props.formFailData.taskID,
									formID: this.props.formFailData.formID,
									questionID: this.props.formFailData.questionID,
									questionRef: this.props.formFailData.questionRef,
								},
							});
						} else {
							this.props.history.replace('/job-task-list');
							this.props.history.push({
								pathname: `/new-request`,
								state: { guid, formFormFail: false },
							});
						}
					});
				} else {
					const photoNewFBID = generateFirebaseId();
					// if its for a photo on an existing job
						idb
							.savePhotoToLocal(
								arrayBuffer,
								this.props.jobTaskId,
								this.props.isComplete.toString(),
								photoNewFBID,
								`Contractor-Photo-${new Date().getTime()}.jpeg`,
								undefined,
								undefined,
								undefined,
								fire.currentUser.email || undefined,
								JOB_DOCUMENT_SOURCE_FROM_APP,
								new Date().getTime(),
							)
						.then(async guid => {
							const geoLocation = await getLocation();
							
							await fire.CreatePhotoPlaceholder(photoNewFBID, this.props.jobTaskId, guid);
		
							const jobTaskQueueObject = {
								JobAction: 'AddPhotoExistingJobTask',
								PhotoGuid: guid,
								PhotoNewFBID: photoNewFBID,
								Latitude: geoLocation.Latitude,
								Longitude: geoLocation.Longitude,
							};
		
							await localQueue
								.saveToLocalJobQueue(
									this.props.jobTaskId,
									jobTaskQueueObject,
									undefined,
									undefined,
									undefined,
									arrayBuffer,
								)
								.then(() => {
									if (this.props.shouldRedirect) {
										this.setState({ disableSaveButton: false });
										this.props.history.replace('/job-task-list');
										this.props.history.push(`/job-task-details/${this.props.jobTaskId}`);
									} else {
										// @ts-ignore
										this.props.noRedirectCallback();
									}
								});
						});
				}

			});
		}
	};

	render() {
		return (
			<div id="edit-photo-container">
				<div id="rendered-canvas" style={{ position: 'relative' }}>
					<canvas id="photo-canvas"/>
					<canvas id="drawing-canvas"/>
				</div>
				<div className="photo-screen-actions">
					<IconButton color="inherit" aria-label="Back" onClick={() => this.props.clearPhoto()}>
						<Icon className="white" fontSize="large">
							delete
						</Icon>
					</IconButton>
					<IconButton
						color="inherit"
						aria-label="Take Photo"
						onClick={() => (this.signaturePad !== null ? this.signaturePad.clear() : null)}
					>
						<Icon className="white" fontSize="large">
							layers_clear
						</Icon>
					</IconButton>
					<IconButton
						disabled={this.state.disableSaveButton}
						color="inherit"
						aria-label="Flash"
						onClick={() => this.saveImage()}
					>
						<Icon className="white" fontSize="large" color="primary">
							save
						</Icon>
					</IconButton>
				</div>
				
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default connect(mapStateToProps, null)(EditPhotoScreen);
