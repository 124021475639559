import * as React from 'react';
import {
	Button,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Icon,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
	TextField,
} from '@material-ui/core';
import styled from 'styled-components';
import { IsChrome } from '../../utils/IsChrome';
import { fire } from '../../index';
import AutoCompleteDropDown from '../inputs/AutoCompleteDropdown';
import { withTranslation } from 'react-i18next';

interface JobTask {
	name: string;
	value: string;
}

interface CreateExpenseDialogState {
	jobTasks: JobTask[];
}

 class CreateExpenseDialog extends React.Component<any, CreateExpenseDialogState> {
	constructor(props) {
		super(props);

		this.state = {
			jobTasks: [],
		};
	}

	componentDidMount() {
		this.getJobTasks();
	}

	getJobTasks = () => {
		const jobTaskCollection = fire.getJobTaskCollectionQuery();

		jobTaskCollection.onSnapshot(query => {
			if (query.empty) {
				this.setState({ jobTasks: [] });
				return;
			}

			const jobTasks = query.docs.map(jobTasks => {
				const data = jobTasks.data() as JobTask.JobTask;
				return {
					name: data.JobTaskNumber.toString(),
					value: data.JobFBID,
				} as JobTask;
			});

			this.setState({ jobTasks });
		});
	};

	render() {
		return (
			<Dialog open={this.props.open} onClose={this.props.handleDialogClose} fullScreen={true} maxWidth="lg">
				<DialogTitle id="form-dialog-title">{this.props.t("Create Expense")}</DialogTitle>
				<DialogContent>
					<Content>
						<TextField
							autoComplete={IsChrome()}
							autoFocus={true}
							margin="none"
							id="create-expense-description"
							label={this.props.t("Description")}
							type="text"
							name="DescriptionForCreateExpense"
							fullWidth={true}
							variant="outlined"
							onChange={this.props.newDescriptionChange}
							defaultValue={this.props.descriptionDefaultValue}
						/>
					</Content>

					<Content>
						<TextField
							autoComplete={IsChrome()}
							margin="none"
							id="create-expense-supplier"
							label={this.props.t("Supplier")}
							type="text"
							name="SupplierForCreateExpense"
							fullWidth={true}
							variant="outlined"
							onChange={this.props.newSupplierChange}
							defaultValue={this.props.supplierDefaultValue}
						/>
					</Content>

					<Content>
						<TextField
							autoComplete={IsChrome()}
							margin="none"
							id="create-expense-quantity"
							label={this.props.t("Quantity")}
							type="number"
							fullWidth={true}
							variant="outlined"
							placeholder="0"
							onChange={this.props.newQuantityChange}
							defaultValue={this.props.quantityDefaultValue}
						/>
					</Content>
					<Content>
						<TextField
							autoComplete={IsChrome()}
							margin="none"
							id="create-expense-price"
							label={this.props.t("Price")}
							type="number"
							fullWidth={true}
							variant="outlined"
							placeholder="0"
							onChange={this.props.newPriceChange}
							defaultValue={this.props.priceDefaultValue}
						/>
					</Content>
					{this.props.createExpense === true ? (
						<div>
							<Button
								id="document-upload-button"
								variant="contained"
								color="inherit"
								onClick={() => this.props.openDocumentDialog()}
								style={{ marginTop: '15px' }}
							>
								<Icon style={{ fontSize: 20 }}>attach_file</Icon>&nbsp;
								<p style={{ marginTop: 2 }}>{this.props.t("Add Document")}</p>
							</Button>
							<input
								id="expense-document-upload"
								type="file"
								style={{ display: 'none' }}
								onChange={e => this.props.saveExpenseDocument(e.target.files)}
							/>
						</div>
					) : null}
					{this.props.newExpenseDocuments.length > 0 ? (
						<Table>
							<TableHead>
								<TableRow style={{ padding: '0', paddingBottom: '5px' }}>
									<TableCell className="cell5" padding="none">
									{this.props.t("File Name")}
									</TableCell>
									<TableCell className="cell4" padding="none" />
								</TableRow>
							</TableHead>
							<TableBody>
								{this.props.newExpenseDocuments.map((docs, i) => (
									<TableRow key={i} data-rowid={i}>
										<TableCell className="cell5" padding="none">
											{docs.name}
										</TableCell>
										<TableCell className="cell4" padding="none">
											<IconButton onClick={() => this.props.removeDocumentFromList(docs)} style={{ float: 'right' }}>
												<Icon style={{ color: 'black' }}>clear</Icon>
											</IconButton>
										</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					) : null}
				</DialogContent>
				<DialogActions>
					<Button
						onClick={this.props.handleExpenseDialogVisibility}
						color="primary"
						variant="contained"
						style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
					>
						<Icon>undo</Icon>&nbsp; {this.props.t("Back")}
					</Button>
					{this.props.createExpense === true ? (
						<Button
							onClick={this.props.handleCreateExpense}
							color="primary"
							style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
						>
							<Icon>save</Icon>&nbsp; {this.props.t("Create Expense")}
						</Button>
					) : (
						<Button
							onClick={this.props.handleUpdateExpense}
							color="primary"
							style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
						>
							<Icon>save</Icon>&nbsp; {this.props.t("Update Expense")}
						</Button>
					)}
				</DialogActions>
			</Dialog>
		);
	}
}

const Content = styled.div`
	margin-top: 15px;
`;

export default withTranslation()(CreateExpenseDialog)
