// React
import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import QRCode from 'react-qr-code';

// Material UI
import DialogContent from '@material-ui/core/DialogContent';
import {
	Button,
	Grid
} from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { fire } from '../../index';
import { connect } from 'react-redux';
import HomeWorkIcon from '@material-ui/icons/HomeWork';
import BuildIcon from '@material-ui/icons/Build';
import CameraEnhanceIcon from '@material-ui/icons/CameraEnhance';
import DynamicFeedIcon from '@material-ui/icons/DynamicFeed';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import AddBoxIcon from '@material-ui/icons/AddBox';


// Components
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';
import FieldHolder from '../../components/styled-components/FieldHolderSiteCard';
import PhotosCard from '../../components/shared/PhotosCard';
import DocumentsCard from '../../components/shared/DocumentsCard';

// Utils
import { SendErrorData } from '../../utils/WindowError';
import { getBaseURL } from '../../utils/getBaseURL';
import Api from '../../apiConfiguration.json';
import ApiKeyObj from '../../utils/ApiObjectKey';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { readAndAddQRCode } from '../../utils/QRCode';
import QRCodeDialog from '../../components/shared/QRCodeDialog';
import { useTranslation, withTranslation } from 'react-i18next';
import FormsListCards from '../../components/shared/FormsListCards';
import { fireLocationConverter } from '../../utils/FirebaseConverters';
import FieldHolderName from '../../components/styled-components/FieldHolderName';

interface LocationDetailProps {
	UserSettings: Store.UserSettings;
	match: match;
	history: History;
	t: any;
}
interface LocationDetailsState {
	JobTaskIds: string[] | null;
	JobTasks: JobTask.JobTask[];
	Assets: Asset[];
	locationDetails: Location | null;
	SearchbyQr: boolean;
	LocationFBID: string;
	LocationCode: string | null;
	SiteName: string | null;
}

const routeToAssociatedJobs = (selectedLocation: Location | null, history: History) => {
	if (!isNullOrUndefined(selectedLocation)) {
		let ids: any;
		history.push({
			pathname: '/associated-jobtasks',
			state: {
				SelectedSiteID: selectedLocation.SiteID,
				SelectedSiteName: selectedLocation.SiteName,
				SelectedLocationID: selectedLocation.LocationID,
				SelectedLocationName: selectedLocation.LocationName
			},
		});
	}
};

const routeToAssociatedAssets = (selectedLocation: Location | null, history: History) => {
	if (!isNullOrUndefined(selectedLocation)) {
		let ids: any;
		ids = selectedLocation.SiteFBID;
		history.push({
			pathname: '/asset-list/list/list',
			state: {
				selectedSiteID: selectedLocation.SiteID,
				selectedSiteName: selectedLocation.SiteName,
				selectedLocationID: selectedLocation.LocationID,
				selectedLocationName: selectedLocation.LocationName
			},
		});
	}
};

class LocationDetails extends React.Component<LocationDetailProps, LocationDetailsState> {
	constructor(props) {
		super(props);
		this.state = {
			JobTaskIds: [],
			JobTasks: [],
			Assets: [],
			locationDetails: null,
			SearchbyQr: false,
			LocationFBID: '',
			LocationCode: '',
			SiteName: ''
		};
	}

	async componentWillReceiveProps(newProps: LocationDetailProps) {
		const { id } = newProps.match.params as any;
		await this.handleComponentLoad(id);
	}

	  handleComponentLoad(id: string) {
		const location = fire.getDocumentQuery('Locations', id);		

		location.withConverter(fireLocationConverter).onSnapshot(async locationObj => {
			let siteName = '';

			const Location = locationObj.data()!;
			await fire.getSiteInfoFromID(Location.SiteID)
			.then(data => {
				data.docs.forEach(site => {
					siteName = site.data().SiteName;
				})
			});
			this.getSite(Location.SiteID);			
			Location.QRCode = Location.QRCode || '';
			Location.SiteName = siteName;

			this.setState({
				locationDetails: Location,
				LocationCode: Location.LocationID ? Location.LocationID.toString() : '',
			});

			if(!isNullOrUndefined(this.state.locationDetails)){
				this.getJobTasks(this.state.locationDetails.ID);
				this.getAssets(this.state.locationDetails.ID);
			}
		});
		
}

	async componentDidMount() {
		const { id } = this.props.match.params as any;
		this.getSite(id);
		this.setState({ LocationFBID: id });

		await this.handleComponentLoad(id);
	}

	sortingFunction = (joblist: JobTask.JobTask[]) => {
		return joblist.sort((a, b) => {
			if (a.JobTaskNumber === b.JobTaskNumber) return 0;
			else if (a.JobTaskNumber === 0) return -1;
			else if (b.JobTaskNumber === 0) return 1;
			else if (a.JobTaskNumber < b.JobTaskNumber) return 1;
			else if (b.JobTaskNumber < a.JobTaskNumber) return -1;
			else return 0;
		});
	};

	getJobTasks(id) {
		let JobTaskArray: JobTask.JobTask[] = [];
		if (!isNullOrUndefined(id)) {
			fire.baseQuery
				.collection('JobTasks')
				.where('SiteID', '==', id)
				.get()
				.then(siteJobs => {
					JobTaskArray = siteJobs.docs.map(siteJob => {
						let data = siteJob.data() as JobTask.JobTask;
						data.JobTaskNumber = isNullOrUndefined(data.JobTaskNumber) ? 0 : data.JobTaskNumber;
						return data;
					});
					JobTaskArray = this.sortingFunction(JobTaskArray);
					this.setState({ JobTasks: JobTaskArray });
				});
		}
	}

	getAssets(id) {
		let assetArray: Asset[] = [];
		if (!isNullOrUndefined(id)) {
			fire.baseQuery
				.collection('Assets')
				.where('LocationID', '==', id)
				.get()
				.then(locationAssets => {
					assetArray = locationAssets.docs.map(locationAsset => {
						let data = locationAsset.data() as Asset;
						return data;
					});
					this.setState({ Assets: assetArray });
				});
		}
	}

	getSite(id){
		fire.getSiteInfoFromID(id).then(result => {
			result.forEach(doc => {
				const Site = doc.data();
				this.setState({ SiteName: Site.SiteName });
			})
		});
	}


	backButton = () => {
		window.history.back()
	};

	handleLogout() {
		fire.auth.signOut().then(() => location.reload());
	}

	routeSubLocations(){
		this.props.history.push('/sublocations-list/' + this.state.LocationCode);
	}

	DetailsButton = async id => {
		let doesJobExist = await fire.doesJobExist(id);
		if (doesJobExist) {
			if (!isNullOrUndefined(this.state.locationDetails)) {
				this.props.history.push('/jobtask-details/' + id + '/' + this.state.LocationFBID);
			} else {
				this.props.history.push('/jobtask-details/' + id);
			}

		} else {
			alert(this.props.t('Job cannot be accessed'));
		}
	};

	AssetDetailsButton = async id => {
		this.props.history.push('/asset-nmdetails/' + id);
	};

	handleRaiseNewJobTaskfromLocation = (selectedLocation: Location | null, history: History) => {
		if (!isNullOrUndefined(selectedLocation)) {
			let ids: any;
			ids = selectedLocation.ID;
			history.push({
				pathname: '/new-request-from-Location/' + selectedLocation.ID,
				state: {
					JobRequestDetails: this.props.t('Requested at Location:') + " " + selectedLocation.LocationName,
					selectedSiteID: selectedLocation.SiteID,
					selectedSiteName: selectedLocation.SiteName,
					selectedLocationID: selectedLocation.LocationID,
					selectedLocationName: selectedLocation.LocationName,
					AssetsAttached: ids,
					guid: null,
				},
			});
		}
	};
	
    handleCreateNewAssetfromLocation = (selectedLocation: Location | null, history: History, UserSettings: Store.UserSettings) => {
		if(UserSettings.CanAddAssets === false){
			alert(this.props.t("This user is not authorized to create assets. If this is a mistake, please contact your administrator and ensure permission has been granted to create assets from the main dashboard."));
			return;
		}
		
		if (!isNullOrUndefined(selectedLocation)) {
			let ids: any;
			ids = selectedLocation.ID;
			history.push({
				pathname: '/new-asset',
				state: {
					selectedSiteID: selectedLocation.SiteID,
					selectedSiteName: selectedLocation.SiteName,
					selectedLocationID: selectedLocation.LocationID,
					selectedLocationName: selectedLocation.LocationName,
				},
			});
		}
	};
	
	render() {
		if (!isNullOrUndefined(this.state.locationDetails)) {
			const title = this.state.locationDetails.LocationName.length > 15 ? this.state.locationDetails.LocationName.substr(0, 15) + "..." : 
			this.state.locationDetails.LocationName;

			//Future consideration: google maps implementation with location.address passed in as URL parameter.
			//const gmapLink = "https://maps.google.com/?q=" + this.state.locationDetails.Address;

			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={title}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
						colour="primary"
						handleLogout={this.handleLogout}
						User={this.props.UserSettings}
						versionApp={Api.VERSION}
						versionDb={Api.INDEXEDDB_VERSION}
						SendErrorData={SendErrorData}
						getBaseURL={getBaseURL}
						ApiKeyObj={ApiKeyObj}
					/>
					{!isNullOrUndefined(this.state.locationDetails) ? (
						<Grid container={true} direction="column" justify="center" alignItems="stretch">
							<DialogContent>
								<div id="details-outer-asset">
									<div className="top-card-alt card-shadow-alt">
									<FieldHolderName content={this.state.locationDetails.LocationName} label={this.props.t("Location Name")} fullLine={true}/>
										<FieldHolder content={this.state.locationDetails.Code} label={this.props.t("Location Code")} />
										<FieldHolder content={this.state.SiteName} label={this.props.t("Site Name")} />
										</div>
										<div className="top-card-alt card-shadow-alt">
										<FieldHolder content={this.state.locationDetails.QRCode} label={this.props.t("QRCode")} />
										<QRCodeDialog
											isOpen={this.state.SearchbyQr}
											close={() => this.setState({ SearchbyQr: false })}
											readAndAddQRCode={QRCode => readAndAddQRCode(QRCode, 'Locations', 'Location', this.props.match.params.id)}
										/>	
										{this.state.locationDetails.QRCode && this.state.locationDetails.QRCode !== '' ? (
											<QRCode
												bgColor="#FFFFFF"
												fgColor="#000000"
												level="L"
												size={64}
												value={this.state.locationDetails.QRCode as string}
											/>
										) : (
											<Button
												onClick={() => this.setState({ SearchbyQr: true })}
												className='add-qr-code-button'
												variant="outlined"
												size="large"
											>
												{this.props.t("Add QR Code")} <CameraEnhanceIcon style={ {paddingLeft: "5px" }}></CameraEnhanceIcon>
											</Button>
										)}
									</div>
									<FormsListCards fromView="Location" associatedFBID={this.state.LocationFBID}
									areTemplatesRestricted={this.state.locationDetails.FormTemplatesRestricted != null ? 
										this.state.locationDetails.FormTemplatesRestricted : false}
									restrictedTemplates={this.state.locationDetails.RestrictedFormTemplates != null ? 
									this.state.locationDetails.RestrictedFormTemplates : []}></FormsListCards>
									<PhotosCard fromView="Location" associatedFBID={this.state.LocationFBID}></PhotosCard>
									<DocumentsCard fromView="Location" associatedFBID={this.state.LocationFBID}></DocumentsCard>
									
									<div className="top-card action-buttons">
									<Button onClick={() => this.routeSubLocations()}
											className='view-sublocations-button'
											variant="contained"
											color="secondary"
											size="large"
											>
											{this.props.t("View Sub-Locations")}
											<HomeWorkIcon style={ {paddingLeft: "5px" }}></HomeWorkIcon>
											</Button>
											</div>

										

									<div className="top-card-alt-with-two-buttons card-shadow-alt">
										<Button onClick={() => routeToAssociatedJobs(this.state.locationDetails, this.props.history)}
										className='view-job-tasks-button'
										variant="contained"
										color="secondary"
										size="large"
										>
										{this.props.t("View Job Tasks")} <BuildIcon style={ {paddingLeft: "5px" }}></BuildIcon>
										</Button>	

											<Button onClick={() => this.handleRaiseNewJobTaskfromLocation(this.state.locationDetails, this.props.history)}
										variant="outlined"
										className='raise-job-task-button'
										size="large"
										 >							 
										{this.props.t("Raise Job Task")}
										<AddCircleIcon style={ {paddingLeft: "5px" }}></AddCircleIcon>
										</Button>

										</div>

									<div className="top-card-alt-with-two-buttons card-shadow-alt">
										<Button onClick={() => routeToAssociatedAssets(this.state.locationDetails, this.props.history)}
										className='view-assets-button'
									    variant="contained"
										color="secondary"
										size="large"
										 >
										{this.props.t("View Assets")} <DynamicFeedIcon style={ {paddingLeft: "5px" }}></DynamicFeedIcon>
										</Button>
										<Button onClick={() => this.handleCreateNewAssetfromLocation(this.state.locationDetails, this.props.history, 
										this.props.UserSettings)}
											variant="outlined"
											className='create-asset-button'
											size="large"
											>							 
											{this.props.t("Create New Asset")}
											<AddBoxIcon style={ {paddingLeft: "5px" }}></AddBoxIcon>
											</Button>
										</div>
								</div>
							</DialogContent>
						</Grid>
					) : null}
				</div>
			);
		}
		return <LoadingSpinner color="primary" text="Loading Location Details" />;
	}
}
const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(LocationDetails));
