import * as React from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import OuterCard from '../shared/OuterCard';
import CardHeader from '../shared/CardHeader';
import { IconButton, Icon } from '@material-ui/core';
import { History } from 'history';
import { withTranslation } from 'react-i18next';
import AddAssetToJobButton from '../job-task/AddAssetToJobButton';

//card icon
import ASSET from '../../assets/images/icons/ASSET.svg'

interface AssetJobListProps {
	history: History;
	Assets: Asset[];
	TaskId?: string;
	JobId?: string;
	removeAssetAttached: (id) => void;
	t: any;
	documentID: string;
	jobTaskID: string;
}
interface AssetJobListState {
	resourceDetailsModalOpen: boolean;
	SelectedAsset: Asset | null;
	expand: boolean;
}
class AssetJobList extends React.Component<AssetJobListProps, AssetJobListState> {
	constructor(props: Readonly<AssetJobListProps>) {
		super(props);
		this.state = {
			resourceDetailsModalOpen: false,
			SelectedAsset: null,
			expand: false,
		};
	}

	handleExpand = (value) => {
		this.setState({ expand: value })
	}

	render() {
		return (
			<OuterCard styles="forms-card border-radius" padding="0px">
				<CardHeader title={this.props.t("Assets")} titleIcon={ASSET} textColor="white" backgroundColor="#b821f3" expand={this.state.expand}
				handleExpand={this.handleExpand} showExpand={true} />

				{this.state.expand && <div>
					<AddAssetToJobButton
						documentID={this.props.documentID}
						jobTaskID={this.props.jobTaskID}
						disabled={false}
					/>
				</div>}

				{this.props.Assets.length < 1 ?
                        <div className='no-elements-card-text'>
                            {this.state.expand && <p>{this.props.t('No Assets Attached')}</p>}
                        </div> : <> 
						{this.state.expand ? <>
							<div className='sub-title-in-card'>{this.props.t("Assets Attached")}</div>
						<Table>
					<TableHead>
						<TableRow style={{ padding: '0', paddingLeft: '2px' }}>
							<TableCell
								style={{ width: '40%', color: 'black', paddingLeft: '5px' }}
								className="form-cell-name-header"
								padding="none"
							>
								{this.props.t("Name")}
							</TableCell>
							<TableCell style={{ width: '20%', color: 'black' }} className="form-cell-name-header" padding="none">
								{this.props.t("Code")}
							</TableCell>
							<TableCell style={{ width: '30%', color: 'black' }} className="form-cell-name-header" padding="none">
								{this.props.t("Class")}
							</TableCell>
							<TableCell style={{ width: '10%', color: 'black' }} className="form-cell-name-header" padding="none" />
						</TableRow>
					</TableHead>
					<TableBody>
						{this.props.Assets.map((row, i) => {
							return (
								<TableRow key={i}>
									<TableCell className="form-cell-name" style={{ paddingLeft: '5px' }}>
										{row.AssetName}
									</TableCell>
									<TableCell className="form-cell-name" padding="none">
										{row.AssetCode}
									</TableCell>
									<TableCell className="form-cell-name" padding="none">
										{row.AssetClass}
									</TableCell>

									<TableCell className="form-cell-name" padding="none">
										<IconButton
											onClick={() =>
												this.props.history.replace(`/asset-nmdetails/${row.AssetFBID}` + '/' + this.props.TaskId)
											}
										>
											<Icon>pageview</Icon>
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table></> : <></>}</>}
			</OuterCard>
		);
	}
}

export default withTranslation()(AssetJobList);
