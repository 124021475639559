import * as React from 'react';
import { connect } from 'react-redux';
import firebase from 'firebase';
import { History } from 'history';
import { isNullOrUndefined } from 'util';
import * as loadImage from 'blueimp-load-image';
import { AsyncPaginate, Response } from 'react-select-async-paginate';

import {
	Button,
	Checkbox,
	FormControl,
	FormControlLabel,
	Grid,
	Icon,
	NativeSelect,
	TextField,
	IconButton,
} from '@material-ui/core';

import SideDrawer from '../components/shared/SideDrawer';
import { fire, idb, localQueue } from '../index';
import PhotosGrid from '../components/shared/PhotosGrid';
import BackButton from '../components/shared/BackButton';

// Styles
import '../styles/new-issue/new-issue.css';
import { SaveNewIssueState, ClearNewIssueState } from '../actions/JobTaskActions';
import { fileToArrayBuffer } from '../utils/Converters';
import { generateFirebaseId } from '../utils/Guids';
// import { convertBlobsToBase64 } from '../utils/Converters';
import { GetFileCompressionRatio } from '../utils/FileCompressionRatio';
import { windowError } from '../utils/WindowError';
import { IsChrome } from '../utils/IsChrome';
import { LocalstorageRemoveItem, LocalstorageGetItem, LocalstorageSetItem } from '../utils/LocalStorage';
import { stringtoUnixUTC } from '../utils/Times';
import DateOrPriority from '../components/inputs/DateOrPriority';
import { parseDateForDateTimeFields } from '../utils/Times';
import { withTranslation } from 'react-i18next';

interface NewJobRequestScreenProps {
	history: History;
	SaveNewIssueState: (state: any) => void;
	ClearNewIssueState: () => void;
	NewIssueState: any;
	CanSelfDeliverSetting: boolean;
	UserSettings: Store.UserSettings;
	t: any;
}

interface NewJobRequestScreenState {
	jobRequestDetails: string;
	selectedSiteID: number | null;
	selectedLocationID: number | null;
	selectedSubLocationID: number | null;
	selectedSiteName: string;
	selectedLocationName: string;
	selectedSubLocationName: string;
	guid: string;
	requestPhotos: JobTask.JobTaskPhoto[];
	detailsRequired: boolean;
	siteIdRequired: boolean;
	SelfDeliverRequestValue: boolean;
	CanSelfDeliverSetting: boolean;
	savingRequest: boolean;
	fromAssetData: any;
	jobTypes: any[];
	selectedJobType: {
		Name: string;
		ID: number;
	} | null;
	jobSubTypesSource: any[];
	jobSubTypes: any[];
	selectedJobSubType: {
		Name: string;
		ID: number;
	} | null;
	jobSubTypeDisabled: boolean;
	fromFormFail: boolean;
	taskID: string;
	formID: string;
	questionID: string;
	formFailData: any;
	prioritiesSource: any[];
	selectedPriority: {
		Name: string;
		ID: string;
	} | null;
	NewDate: number;
	ExpectedByDate: string;
	fromFormLibrary: boolean;
	formTemplateFBID: string;
	AssetsAttached: string | null | undefined;
	questionRef: firebase.firestore.DocumentReference | null;
	GeneratedGuid: string;

	// Sites, Locations and Sub Locations
	sitesPerPage: number;
	lastLoadedSite: any;
	siteValue: string;
	loadedSites: [];
	endSite: boolean;
	sitesLocationCanBeSelected: boolean;
	siteFilter?: {
		value: number;
		label: string;
	} | null;

	locationsPerPage: number;
	lastLoadedLocation: any;
	locationValue: string;
	loadedLocations: [];
	endLocation: boolean;
	locationFilter?: {
		value: number;
		label: string;
	} | null;
	locationDisabled: boolean;

	subLocationsPerPage: number;
	lastLoadedSubLocation: any;
	subLocationValue: string;
	loadedSubLocations: [];
	endSubLocation: boolean;
	subLocationFilter?: {
		value: number;
		label: string;
	} | null;
	subLocationDisabled: boolean;

	enableTextSearchSite: boolean;
	enableTextSearchLocation: boolean;
	enableTextSearchSubLocation: boolean;
	siteMenuOpen: boolean;
	locationMenuOpen: boolean;
	sublocationMenuOpen: boolean;

	showKeyboard: boolean;
	siteFreeze: boolean;
	locationFreeze: boolean;
	sublocationFreeze: boolean;
	expand: boolean;
}

class NewJobRequestScreen extends React.Component<NewJobRequestScreenProps, NewJobRequestScreenState> {
	private iOS: boolean;
	private siteRef: React.RefObject<HTMLInputElement>;
	private locationRef: React.RefObject<HTMLInputElement>;
	private sublocationRef: React.RefObject<HTMLInputElement>;

	constructor(props) {
		super(props);
		this.iOS =
			!!navigator.platform &&
			(/iPad|iPhone|iPod/.test(navigator.platform) ||
				(navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1));
		let newState = null;
		if (this.props.NewIssueState !== null) {
			this.props.NewIssueState.requestPhotos = [];
			newState = this.props.NewIssueState;
		}
		newState !== null
			? (this.state = newState)
			: (this.state = {
				jobRequestDetails: '',
				selectedSiteID: null,
				selectedLocationID: null,
				selectedSubLocationID: null,
				selectedSiteName: '',
				selectedLocationName: '',
				selectedSubLocationName: '',
				requestPhotos: [],
				guid: generateFirebaseId(),
				detailsRequired: false,
				siteIdRequired: false,
				fromAssetData: '',
				SelfDeliverRequestValue: false,
				CanSelfDeliverSetting: this.props.CanSelfDeliverSetting,
				savingRequest: false,
				//JobType
				jobTypes: [],
				selectedJobType: null,
				//JobSubType
				jobSubTypesSource: [],
				jobSubTypes: [],
				selectedJobSubType: null,
				jobSubTypeDisabled: true,
				fromFormFail: false,
				taskID: '',
				formID: '',
				questionID: '',
				formFailData: '',
				prioritiesSource: [],
				selectedPriority: null,
				NewDate: new Date().getTime(),
				ExpectedByDate: '',
				fromFormLibrary: false,
				formTemplateFBID: '',
				questionRef: null,
				AssetsAttached: '',
				GeneratedGuid: generateFirebaseId(),

				sitesPerPage: 50,
				lastLoadedSite: null,
				siteValue: '',
				loadedSites: [],
				endSite: false,
				sitesLocationCanBeSelected: true,
				siteFilter: null,

				locationsPerPage: 50,
				lastLoadedLocation: null,
				locationValue: '',
				loadedLocations: [],
				endLocation: false,
				locationFilter: null,
				locationDisabled: true,

				subLocationsPerPage: 50,
				lastLoadedSubLocation: null,
				subLocationValue: '',
				loadedSubLocations: [],
				endSubLocation: false,
				subLocationFilter: null,
				subLocationDisabled: true,

				enableTextSearchSite: false,
				enableTextSearchLocation: false,
				enableTextSearchSubLocation: false,
				siteMenuOpen: false,
				locationMenuOpen: false,
				sublocationMenuOpen: false,

				showKeyboard: false,
				siteFreeze: false,
				locationFreeze: false,
				sublocationFreeze: false,
				expand: false
			});
		this.siteRef = React.createRef();
		this.locationRef = React.createRef();
		this.sublocationRef = React.createRef();
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'NewJobRequestScreen',
		);

		if (this.props.UserSettings.NewJobDatePriorityTreatment === 'Date') {
			if (isNullOrUndefined(this.state.ExpectedByDate) || this.state.ExpectedByDate === '') {
				this.setState({
					ExpectedByDate: parseDateForDateTimeFields(this.state.NewDate),
				});
			}
		}

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('new-request-from-Asset')
		) {
			const {
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName,
				AssetsAttached,
			} = this.props.history.location.state;

			const fromAssetData = {
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName,
				AssetsAttached,
			};
			//alert(fromAssetData.jobRequestDetails);

			this.setState({
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName,
				AssetsAttached,
				fromAssetData,
				siteFreeze: true,
				locationFreeze: true,
				sublocationFreeze: true,
			}, () => this.loadDropdowns());
			//alert(this.state.jobRequestDetails);
		}

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('new-request-from-Site')
		) {
			console.log("This ran.");
			console.log(this.props.history.location.state);
			const {
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName
			} = this.props.history.location.state;

			const fromAssetData = {
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName
			};

			this.setState({
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				fromAssetData,
				siteFreeze: true
			}, () => this.loadDropdowns());
		}

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('new-request-from-Location')
		) {
			const {
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName

			} = this.props.history.location.state;

			const fromAssetData = {
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName
			};

			this.setState({
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				fromAssetData,
				siteFreeze: true,
				locationFreeze: true
			}, () => this.loadDropdowns());
		}

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('new-request-from-Sublocation')
		) {
			const {
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName

			} = this.props.history.location.state;

			const fromAssetData = {
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName,
			};

			console.log(fromAssetData);

			this.setState({
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName,
				fromAssetData,
				siteFreeze: true,
				locationFreeze: true,
				sublocationFreeze: true,
			}, () => this.loadDropdowns());
		}

		if (
			!isNullOrUndefined(this.props.history.location.state) &&
			window.location.href.includes('new-request-form-fail')
		) {
			const {
				fromFormFail,
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName,
				taskID,
				formID,
				questionID,
				questionRef,
				formTemplateFBID,
			} = this.props.history.location.state;

			const formFailData = {
				fromFormFail,
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName,
				taskID,
				formID,
				questionID,
				questionRef,
				formTemplateFBID,
			};

			this.setState({
				fromFormFail,
				jobRequestDetails,
				selectedSiteID,
				selectedSiteName,
				selectedLocationID,
				selectedLocationName,
				selectedSubLocationID,
				selectedSubLocationName,
				taskID,
				formID,
				questionID,
				formFailData,
				questionRef,
				formTemplateFBID,
			}, () => this.loadDropdowns());
		}
	}

	loadDropdowns = () => {
		if (!isNullOrUndefined(this.state.selectedSiteID) && !isNullOrUndefined(this.state.selectedSiteName)) {
			this.setState({
				siteFilter: {
					value: this.state.selectedSiteID,
					label: this.state.selectedSiteName
				}
			}, () => this.checkLocations());
		}

		if (!isNullOrUndefined(this.state.selectedLocationID) && !isNullOrUndefined(this.state.selectedLocationName)) {
			this.setState({
				locationFilter: {
					value: this.state.selectedLocationID,
					label: this.state.selectedLocationName
				}
			}, () => this.checkSubLocations());
		}

		if (!isNullOrUndefined(this.state.selectedSubLocationID) && !isNullOrUndefined(this.state.selectedSubLocationName)) {
			this.setState({
				subLocationFilter: {
					value: this.state.selectedSubLocationID,
					label: this.state.selectedSubLocationName
				}
			})
		}
	}

	async componentDidMount() {
		this.updatePredicate();
		window.addEventListener("resize", this.updatePredicate);

		if (this.state.fromFormFail && !isNullOrUndefined(this.state.questionID) && !isNullOrUndefined(this.state.formID)) {
			fire.baseQuery
				.collection('Forms')
				.doc(this.state.formID)
				.collection('QuestionAnswers')
				.doc(this.state.questionID)
				.onSnapshot(data => {
					this.setState({ questionRef: data.ref });
				});
		}

		if (this.state.fromFormFail) {
			if (!isNullOrUndefined(this.state.selectedSiteID)) {
				let selSiteID = 0;
				selSiteID = this.state.selectedSiteID;

				if (selSiteID > 0) {
					await this.setState({ sitesLocationCanBeSelected: false });
				}
			}
		}

		const guid = this.props.history.location.state !== undefined ? this.props.history.location.state.guid : null;
		this.setState({ guid });
		this.getPhotos(guid);

		//fetch data for dropdowns
		// this.getSites(sitesLocationCanBeSelected);
		//this.getLocations();
		//this.getSubLocations();
		this.getJobTypes();
		this.getJobSubTypes();
		this.getPriorities();
	}

	updatePredicate = () => {
		this.setState({ showKeyboard: window.innerWidth < 600 });
	}

	componentWillUnmount() {
		window.removeEventListener("resize", this.updatePredicate);
	}

	loadOptions = async (siteName, loadedOptions) => {
		if (siteName !== this.state.siteValue) {
			await this.setState({ loadedSites: [], lastLoadedSite: null, siteValue: siteName });
			loadedOptions = [];
		}

		return new Promise<Response>(resolve => {
			fire.getSearchSitesPaginated(this.state.sitesPerPage, this.state.siteValue, this.state.lastLoadedSite).get().then(docs => {
				if (!docs.empty) {
					let sites: any = [];
					docs.forEach(function (site) {
						const siteData = {
							value: site.data().SiteID,
							label: site.data().SiteName
						}
						sites.push(siteData);
					});
					this.setState({ loadedSites: sites, lastLoadedSite: docs.docs[docs.docs.length - 1] }, () => {
						return resolve({
							options: this.state.loadedSites,
							hasMore: !this.state.endSite
						});
					})
				} else {
					this.setState({ endSite: true });
					return resolve({
						options: [],
						hasMore: !this.state.endSite
					});
				}
			})
		})
	}

	loadLocations = async (locationName, loadedOptions) => {

		if (locationName !== this.state.locationValue) {
			await this.setState({ loadedLocations: [], lastLoadedLocation: null, locationValue: locationName });
			loadedOptions = [];
		}

		return new Promise<Response>(resolve => {

			if (!isNullOrUndefined(this.state.selectedSiteID)) {
				fire.getSearchLocationsPaginated(this.state.locationsPerPage, this.state.selectedSiteID, this.state.locationValue, this.state.lastLoadedLocation).get().then(docs => {
					if (!docs.empty) {
						let locations: any = [];
						docs.forEach(function (location) {
							const locationData = {
								value: location.data().LocationID,
								label: location.data().LocationName
							}
							locations.push(locationData);
						});
						this.setState({ loadedLocations: locations, lastLoadedLocation: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedLocations,
								hasMore: !this.state.endLocation
							});
						})
					} else {
						this.setState({ endLocation: true });
						return resolve({
							options: [],
							hasMore: !this.state.endLocation
						});
					}
				})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	loadSubLocations = async (subLocationName, loadedOptions) => {
		if (subLocationName !== this.state.subLocationValue) {
			await this.setState({ loadedSubLocations: [], lastLoadedSubLocation: null, subLocationValue: subLocationName });
			loadedOptions = [];
		}

		return new Promise<Response>(resolve => {

			if (!isNullOrUndefined(this.state.selectedLocationID)) {
				fire.getSearchSubLocationsPaginated(this.state.subLocationsPerPage, this.state.selectedLocationID, this.state.subLocationValue, this.state.lastLoadedSubLocation).get().then(docs => {
					if (!docs.empty) {
						let subLocations: any = [];
						docs.forEach(function (subLocation) {
							const subLocationData = {
								value: subLocation.data().SubLocationID,
								label: subLocation.data().SubLocationName
							}
							subLocations.push(subLocationData);
						});
						this.setState({ loadedSubLocations: subLocations, lastLoadedSubLocation: docs.docs[docs.docs.length - 1] }, () => {
							return resolve({
								options: this.state.loadedSubLocations,
								hasMore: !this.state.endSubLocation
							});
						})
					} else {
						this.setState({ endSubLocation: true });
						return resolve({
							options: [],
							hasMore: !this.state.endSubLocation
						});
					}
				})
			} else {
				return resolve({
					options: [],
					hasMore: false
				})
			}
		})
	}

	// handleSiteFilter = site => {
	// 	this.setState({ siteFilter: { value: site.value, label: site.label }, selectedSite: site.label, lastLoadedSite: null});
	// }

	getPhotos(guid: string) {
		if (isNullOrUndefined(guid)) return;
		idb.getPhotosForRequest(guid).then(photoArray => {
			if (photoArray.length > 0) {
				const arrayMap = photoArray.map(photo => {
					const blob = new Blob([photo.file]);
					const blobUrl = URL.createObjectURL(blob);
					return { FirebaseStoragePath: blobUrl };
				});

				this.setState({ requestPhotos: arrayMap });
			}
		});
	}

	backButton = () => {
		this.props.ClearNewIssueState();

		window.history.back();

		if (!isNullOrUndefined(this.state.guid)) {
			idb.removePhotosForRequest(this.state.guid);
		}
	};

	openImageDialog = () => {
		const imageUploadButton = document.getElementById('image-upload');
		if (imageUploadButton !== null) {
			imageUploadButton.click();
		}
	};

	saveImage = async (selectorFiles: FileList | null) => {

		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 800,
			useWebWorker: true
		}

		if (selectorFiles !== null) {
			for (let i = 0; i < selectorFiles.length; i++) {
				const photo: File = selectorFiles[i];
				const CompressionRatio = GetFileCompressionRatio(photo);

				loadImage(photo,
					(canvas: HTMLCanvasElement) => {
						canvas.toBlob(async blob => {
							const arrayBuffer = await fileToArrayBuffer(blob as Blob);
							idb
								.setPhotoForRequest(arrayBuffer, this.state.GeneratedGuid)
								.then(guid => {
									this.getPhotos(guid);
									this.setState({ guid });
								})
								.catch(err => console.error(err));
						});
					},
					{
						maxWidth: 800, maxHeight: 800, orientation: true,
						//downsamplingRatio: CompressionRatio, 
						canvas: true
					}
				);
			}
		}
	};

	clearSelectedSitesOrLocations = async (typeId) => {
		// typeId = 1 (Site), typeId = 2 (Location), typeId = 3 (Sub Location)
		// If site is cleared, then location and sub location is also cleared as well since they depend on selected site id.
		// If location is cleared, then sub location is also cleared since it depends on selected location id.

		if (typeId === 1) {
			await this.setState({
				selectedSiteID: null,
				selectedSiteName: '',
				lastLoadedSite: null,
				loadedSites: [],
				siteFilter: null,
				siteValue: '',
				endSite: false,
				locationDisabled: true,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2) {
			await this.setState({
				selectedLocationName: '',
				selectedLocationID: null,
				lastLoadedLocation: null,
				loadedLocations: [],
				locationFilter: null,
				locationValue: '',
				endLocation: false,
				subLocationDisabled: true,
			});
		}

		if (typeId === 1 || typeId === 2 || typeId === 3) {
			await this.setState({
				selectedSubLocationName: '',
				selectedSubLocationID: null,
				lastLoadedSubLocation: null,
				loadedSubLocations: [],
				subLocationFilter: null,
				subLocationValue: '',
				endSubLocation: false,
			});
		}
	}

	openSiteMenu = () => {
		this.setState({ siteMenuOpen: true }, () => { if (!isNullOrUndefined(this.siteRef.current)) this.siteRef.current.focus() });
	}

	closeSiteMenu = () => {
		this.setState({ siteMenuOpen: false });
	}

	openLocationMenu = () => {
		this.setState({ locationMenuOpen: true }, () => { if (!isNullOrUndefined(this.locationRef.current)) this.locationRef.current.focus() });
	}

	closeLocationMenu = () => {
		this.setState({ locationMenuOpen: false });
	}

	openSubLocationMenu = () => {
		this.setState({ sublocationMenuOpen: true }, () => { if (!isNullOrUndefined(this.sublocationRef.current)) this.sublocationRef.current.focus() });
	}

	closeSubLocationMenu = () => {
		this.setState({ sublocationMenuOpen: false });
	}


	handleExpand = (value) => {
		this.setState({ expand: value });
	}
	render() {
		const { showKeyboard } = this.state;

		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={window.location.href.includes('new-request-form-fail') ? this.props.t('Create New Job From') : this.props.t('New Job')}
					rightMenuButton={<BackButton callbackMethod={this.backButton} />}
					title2={window.location.href.includes('new-request-form-fail') ? this.props.t('Form Fail') : ''}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main">
						<div className="padding-25-15">
							<TextField
								autoComplete={IsChrome()}
								id="job-request-details"
								type="text"
								name="NewIssueDetails"
								fullWidth={true}
								label={this.props.t("New Issue Details")}
								multiline={true}
								value={this.state.jobRequestDetails}
								onChange={event => {
									this.setState({ jobRequestDetails: event.target.value });
									this.checkForNullFields(this.state.selectedSiteID, event.target.value);
								}}
								variant="standard"
							/>
							<FieldEmptyWarning
								showField={this.state.detailsRequired}
								textForField={this.props.t("New Issue Details Field is Required")}
							/>

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<span>{this.props.t("Site")}</span>
									{
										(showKeyboard && !this.state.siteFreeze) && (
											<IconButton onClick={() =>
												this.setState({ enableTextSearchSite: !this.state.enableTextSearchSite }, () => this.openSiteMenu())}>
												<Icon style={{ color: this.state.enableTextSearchSite ? 'green' : '' }} >keyboard</Icon>
											</IconButton>
										)
									}

								</p>
								<AsyncPaginate
									id="site-helper"
									selectRef={this.siteRef}
									onFocus={this.openSiteMenu}
									onBlur={this.closeSiteMenu}
									menuIsOpen={this.state.siteMenuOpen}
									value={this.state.siteFilter}
									isDisabled={this.state.siteFreeze}
									onChange={this.handleSiteChange}
									loadOptions={this.loadOptions}
									blurInputOnSelect={true}
									loadingMessage={() => this.props.t("Loading Sites...")}
									noOptionsMessage={() => this.props.t("No Sites Found")}
									cacheUniqs={[this.state.siteFilter, this.state.siteValue]}
									isSearchable={showKeyboard ? this.state.enableTextSearchSite : true}
								/>
								<br />
								{(!isNullOrUndefined(this.state.siteFilter) && !this.state.siteFreeze) && (
									<div style={{ marginTop: -5 }}>
										<b>{this.props.t("Selected") + ": "}</b>
										<IconButton onClick={() => this.clearSelectedSitesOrLocations(1)} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.selectedSiteName}
									</div>
								)}
							</div>
							<FieldEmptyWarning showField={this.state.siteIdRequired} textForField="Sites Field is Required" />

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<span>{this.props.t("Location")}</span>
									{
										(showKeyboard && !this.state.locationDisabled && !this.state.locationFreeze) && (
											<IconButton onClick={() => this.setState({ enableTextSearchLocation: !this.state.enableTextSearchLocation }, () => this.openLocationMenu())}>
												<Icon style={{ color: this.state.enableTextSearchLocation ? 'green' : '' }} >keyboard</Icon>
											</IconButton>
										)
									}
								</p>
								<AsyncPaginate
									id="location-helper"
									value={this.state.locationFilter}
									selectRef={this.locationRef}
									onFocus={this.openLocationMenu}
									onBlur={this.closeLocationMenu}
									menuIsOpen={this.state.locationMenuOpen}
									onChange={this.handleLocationChange}
									loadOptions={this.loadLocations}
									blurInputOnSelect={true}
									loadingMessage={() => this.props.t("Loading Locations...")}
									noOptionsMessage={() => this.props.t("No Locations Found")}
									isDisabled={this.state.locationDisabled || this.state.locationFreeze}
									cacheUniqs={[this.state.locationFilter, this.state.locationValue, this.state.selectedSiteID]}
									isSearchable={showKeyboard ? this.state.enableTextSearchLocation : true}
								/>
								<br />
								{(!isNullOrUndefined(this.state.locationFilter) && !this.state.locationFreeze) && (
									<div style={{ marginTop: -5, marginBottom: 15 }}>
										<span>{this.props.t("Selected") + ": "} </span>
										<IconButton onClick={() => this.clearSelectedSitesOrLocations(2)} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.selectedLocationName}
									</div>
								)}
							</div>

							<div style={{ marginTop: 10 }}>
								<p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: '5px', display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
									<span>{this.props.t("Sub Location")}</span>
									{
										(showKeyboard && !this.state.subLocationDisabled && !this.state.sublocationFreeze) && (
											<IconButton onClick={() => this.setState({ enableTextSearchSubLocation: !this.state.enableTextSearchSubLocation }, () => this.openSubLocationMenu())}>
												<Icon style={{ color: this.state.enableTextSearchSubLocation ? 'green' : '' }} >keyboard</Icon>
											</IconButton>
										)
									}
								</p>
								<AsyncPaginate
									id="subLocation-helper"
									value={this.state.subLocationFilter}
									selectRef={this.sublocationRef}
									onFocus={this.openSubLocationMenu}
									onBlur={this.closeSubLocationMenu}
									menuIsOpen={this.state.sublocationMenuOpen}
									onChange={this.handleSubLocationChange}
									loadOptions={this.loadSubLocations}
									blurInputOnSelect={true}
									loadingMessage={() => this.props.t("Loading Sub Locations...")}
									isDisabled={this.state.subLocationDisabled || this.state.sublocationFreeze}
									cacheUniqs={[this.state.subLocationFilter, this.state.subLocationValue, this.state.selectedSiteID, this.state.selectedLocationID]}
									isSearchable={showKeyboard ? this.state.enableTextSearchSubLocation : true}
								/>
								<br />
								{(!isNullOrUndefined(this.state.subLocationFilter) && !this.state.sublocationFreeze) && (
									<div style={{ marginTop: -5, marginBottom: 15 }}>
										<span>{this.props.t("Selected") + ": "} </span>
										<IconButton onClick={() => this.clearSelectedSitesOrLocations(3)} style={{ float: 'right', marginTop: '-5px', cursor: 'pointer' }}>
											<Icon color="error">
												delete_forever
											</Icon>
										</IconButton>
										{this.state.selectedSubLocationName}
									</div>
								)}
							</div>

							{/* JobTypes Select */}
							<FormControl style={{ width: '100%', marginTop: '10px' }}>
								<NativeSelect
									value={this.state.selectedJobType === null ? '' : this.state.selectedJobType.Name}
									onChange={evt => this.handleJobTypeChange(evt)}
									name={this.props.t("Job Type")}
								>
									{this.state.jobTypes.length > 0 ? (
										this.state.jobTypes.map((jobType, i) => (
											<option
												key={jobType.Data.JobTypeName}
												data-jobtypeid={jobType.Data.JobTypeID}
												value={jobType.Data.Id}
											>
												{jobType.Data.JobTypeName}
											</option>
										))
									) : (
										<option key={1} value="empty">
											{this.props.t("No Job Types")}
										</option>
									)}
								</NativeSelect>
							</FormControl>

							{/* JobSubTypes Select */}
							<FormControl style={{ width: '100%', marginTop: '25px' }}>
								<NativeSelect
									value={this.state.selectedJobSubType === null ? '' : this.state.selectedJobSubType.Name}
									onChange={evt => this.handleJobSubTypeChange(evt)}
									disabled={this.state.jobSubTypeDisabled}
									name={this.props.t("Job Sub Type")}
								>
									{this.state.jobSubTypes.length > 0 ? (
										this.state.jobSubTypes.map((jobSubType, i) => (
											<option
												key={jobSubType.Data.JobSubTypeName}
												data-jobtypeid={jobSubType.Data.JobSubTypeID}
												value={jobSubType.Data.Id}
											>
												{jobSubType.Data.JobSubTypeName}
											</option>
										))
									) : (
										<option key={1} value="empty">
											{this.props.t("No Job Sub Types")}
										</option>
									)}
								</NativeSelect>
							</FormControl>

							<DateOrPriority
								NewDate={this.state.NewDate}
								ExpectedByDate={this.state.ExpectedByDate}
								selectedPriority={this.state.selectedPriority}
								prioritiesSource={this.state.prioritiesSource}
								handlePriorityChange={this.handlePriorityChange}
								handleDateTimeChange={this.handleDateTimeChange}
								NewJobDatePriorityTreatment={this.props.UserSettings.NewJobDatePriorityTreatment}
							/>

							{this.state.CanSelfDeliverSetting && (
								<FormControlLabel
									control={
										<Checkbox checked={this.state.SelfDeliverRequestValue} onChange={() => this.handleSelfDeliver()} />
									}
									label={this.props.t("Self Deliver Job Task")}
									style={{ marginTop: '25px' }}
									classes={{ label: 'self-delivery-label' }}
								/>
							)}

							<div className="btn-container">
								{
									// !this.iOS ? ( // for android
									//   <Button color="default" variant="contained" onClick={() => this.handleCameraButton()}>
									//     <Icon>add_a_photo</Icon>&nbsp; Add Photo
									// </Button>
									// ) : (
									//     // for IOS
									<div>
										<Button variant="contained" color="default" onClick={() => this.openImageDialog()}>
											<Icon>add_a_photo</Icon>&nbsp; {this.props.t("Add Photo")}
										</Button>
										<input
											id="image-upload"
											type="file"
											multiple
											onChange={e => this.saveImage(e.target.files)}
											style={{ display: 'none' }}
										/>
									</div>
									// )
								}

								<Button
									disabled={this.state.savingRequest}
									color="primary"
									variant="contained"
									onClick={() => this.saveJobRequest()}
									style={{ backgroundColor: 'var(--light-green)' }}
								>
									<Icon>save</Icon>&nbsp; {this.props.t("Submit")}
								</Button>
							</div>
							<PhotosGrid
								color=""
								photos={this.state.requestPhotos}
								titleText={this.props.t("Attached Photos")}
								noPhotosText={this.props.t("No Photos")}
								isForRequest={true}
								canRemovePhoto={false}
								canDownloadPhoto={false}
								expand={this.state.expand}
								handleExpand={this.handleExpand}
								showActionButton={false}
								openImageDialog={this.openImageDialog}
							/>
						</div>
					</div>
				</Grid>
			</div>
		);
	}

	private handleSelfDeliver = () => {
		this.setState({
			SelfDeliverRequestValue: !this.state.SelfDeliverRequestValue,
		});
	};

	private handleCameraButton = () => {
		const formFailData = {
			fromFormFail: this.state.fromFormFail,
			jobRequestDetails: this.state.jobRequestDetails,
			selectedSiteID: this.state.selectedSiteID,
			selectedSiteName: this.state.selectedSiteName,
			selectedLocationID: this.state.selectedLocationID,
			selectedLocationName: this.state.selectedLocationName,
			selectedSubLocationID: this.state.selectedSubLocationID,
			selectedSubLocationName: this.state.selectedSubLocationName,
			taskID: this.state.taskID,
			formID: this.state.formID,
			questionID: this.state.questionID,
			questionRef: this.state.questionRef,
			formTemplateFBID: this.state.formTemplateFBID,
			AssetsAttached: this.state.AssetsAttached,
			fromAssetData: this.state.fromAssetData,
		};

		this.props.SaveNewIssueState(this.state);

		this.props.history.push({
			pathname: '/take-photo',
			state: {
				newJob: true,
				guid: this.state.guid,
				fromFormFail: this.state.fromFormFail,
				formId: this.state.formID,
				formFailData: formFailData,
			},
		});
	};

	private handleJobTaskFilterOptions = () => {
		const localItem = JSON.parse(LocalstorageGetItem('JobTaskFilterOptions'));

		if (!isNullOrUndefined(localItem)) {
			const SearchArray = {
				searchInput: '',
				openClosedJobFilter: 'Open',
				plannedReactiveJobFilter: '',
				IsDueToday: false,
				SelectedPageNumber: 0,
				YAxisScroll: 0,
				IsNotSynced: false,
				order: localItem.order,
			};

			LocalstorageRemoveItem('JobTaskFilterOptions');
			LocalstorageSetItem({ Key: 'JobTaskFilterOptions', Value: SearchArray });
		}
	};

	private saveJobRequest = async () => {
		this.setState({ savingRequest: true });

		const errorsPresent = this.checkForNullFields(this.state.selectedSiteID, this.state.jobRequestDetails);
		if (errorsPresent) {
			return this.setState({ savingRequest: false });
		}



		const OBjectFBID = generateFirebaseId();
		const createdDate = new Date().getTime();

		this.props.ClearNewIssueState();
		if (this.state.fromFormFail) {
			if (!(isNullOrUndefined(this.state.formTemplateFBID) || this.state.formTemplateFBID.length === 0)) {
				this.props.history.replace('/form-library-details/' + this.state.formTemplateFBID + '/' + this.state.formID);
			} else {
				this.props.history.replace('/form-details/' + this.state.taskID + '/' + this.state.formID);
			}

			alert(this.props.t('A New Job will be added in the background, click ok to return to the Form.'));
		} else {
			if (this.state.SelfDeliverRequestValue) {
				this.props.history.replace('/jobsuccessful/' + OBjectFBID);
			}

			else {
				this.props.history.replace('/jobsuccessful/');
			}
		}

		//create the assettojob record first, as do no want to override the job number when it comes back from the portal.
		let assetToJobFBID = generateFirebaseId(); //this is only used if there ia an asset to attach.
		if (!isNullOrUndefined(this.state.AssetsAttached) && this.state.AssetsAttached !== '') {
			//create job first so the assets have something to attach to.  We may introduce this later to the whole  job process.  Lets see how this goes.
			const JobDetails = {
				Site: this.state.selectedSiteName,
				Location: this.state.selectedLocationName,
				SubLocationName: this.state.selectedSubLocationName,
				JobType: this.state.selectedJobType !== null ? this.state.selectedJobType.Name : null,
				JobSubType: this.state.selectedJobSubType !== null ? this.state.selectedJobSubType.Name : null,
				SiteID: this.state.selectedSiteID,
				LocationID: this.state.selectedLocationID,
				SubLocationID: this.state.selectedSubLocationID,
				//JobTypeID: this.state.selectedJobType?.ID,
				//JobSubTypeID: this.state.selectedJobSubType?.ID,
				JobDetails: this.state.jobRequestDetails,
				FirebaseId: OBjectFBID,
				ExpectedByDate: this.props.UserSettings.NewJobDatePriorityTreatment === 'Date' ? this.state.NewDate : 0,
				DateCreated: Date.now(),
				JobStatus: 'Pending',
				JobNumber: 0,
				AssignedToResource: { ResourceName: null, ResourceObjFBID: null },
				AssignedToResourceMultiple: null,
				IsMultipleResource: false,
				AssetsAttached: this.state.AssetsAttached,
				Priority:
					this.props.UserSettings.NewJobDatePriorityTreatment === 'Priority' && this.state.selectedPriority !== null
						? this.state.selectedPriority.Name
						: '',
			};



			console.log(OBjectFBID);
			await fire.CreateJob(JobDetails, OBjectFBID);

			const AssetJob = {
				AssetFBID: this.state.AssetsAttached,
				JobFBID: OBjectFBID,
				DateCreated: Date.now(),
			};

			await fire.createAssetToJob(AssetJob, assetToJobFBID);
		}

		let RequestObj = {};
		if (this.state.SelfDeliverRequestValue) {
			//this.handleJobTaskFilterOptions(); //this clears the filter options for some reason - I think that it is so the self deliver job appears in the task list - but is causing confuusion
			const SiteInformation = await fire.GetSiteInformation(this.state.selectedSiteID as number);

			RequestObj = {
				AcceptedDate: null,
				Address: SiteInformation.Address,
				ArrivedDate: null,
				AssignedResource: fire.currentUser.uid,
				ClientFBID: fire.ClientID,
				ClientJobNumber: 0,
				JobFBID: OBjectFBID,
				CompletedDate: null,
				Contact: SiteInformation.Contact,
				CreatedDate: createdDate,
				Description: this.state.jobRequestDetails,
				EnableFirebaseNotifications: false,
				ExpectedDate: this.props.UserSettings.NewJobDatePriorityTreatment === 'Date' ? this.state.NewDate : 0,
				FirebaseId: null,
				IsPlanned: false,
				JobSubType: this.state.selectedJobSubType !== null ? this.state.selectedJobSubType.Name : null,
				JobTaskNumber: '0-001',
				JobType: this.state.selectedJobType !== null ? this.state.selectedJobType.Name : null,
				Latitude: 0,
				leftDate: null,
				Location: this.state.selectedLocationName,
				Longitude: 0,
				Site: this.state.selectedSiteName,
				Status: 'Instructed',
				SubLocation: this.state.selectedSubLocationName,
				Telephone: SiteInformation.Telephone,
				TravelStartDate: null,
				Priority:
					this.props.UserSettings.NewJobDatePriorityTreatment === 'Priority' && this.state.selectedPriority !== null
						? this.state.selectedPriority.Name
						: '',
			};
		}

		if (!isNullOrUndefined(this.state.questionRef) && this.state.fromFormFail) {
			await this.state.questionRef.update({ FailJobNumber: 0 });
		}

		const JobRequestObj = {
			JobRequestDetails: this.state.jobRequestDetails,
			SiteID: this.state.selectedSiteID || null,
			LocationID: this.state.selectedLocationID || null,
			SubLocationID: this.state.selectedSubLocationID || null,
			JobTypeID: this.state.selectedJobType !== null ? this.state.selectedJobType.ID : null,
			JobSubTypeID: this.state.selectedJobSubType !== null ? this.state.selectedJobSubType.ID : null,
			JobAction: 'NewJobRequest',
			SelfDeliverRequest: this.state.SelfDeliverRequestValue,
			CreatedDate: createdDate,
			TemplateResponseFBID: this.state.formID,
			TemplateQuestionAnswerFBID: this.state.questionID,
			ObjectFBID: OBjectFBID,
			JobFBID: OBjectFBID,
			ExpectedByDate: this.props.UserSettings.NewJobDatePriorityTreatment === 'Date' ? this.state.NewDate : 0,
			PriorityID:
				this.props.UserSettings.NewJobDatePriorityTreatment === 'Priority' && this.state.selectedPriority !== null
					? this.state.selectedPriority.ID
					: '',

			NewJobDatePriorityTreatment: this.props.UserSettings.NewJobDatePriorityTreatment,
			AssetFBID: this.state.AssetsAttached,
			AssetToJobFBID: assetToJobFBID,
		};

		//if job request has photos attached, its needs to be sent to local job queue
		if (this.state.requestPhotos.length > 0) {
			if (this.state.SelfDeliverRequestValue) {
				fire.CreateJobTask(RequestObj, OBjectFBID);
				console.log(OBjectFBID);

				fire.postToJobQueue(OBjectFBID, JobRequestObj).catch(err => {
					alert(this.props.t('There was an error adding job request, if problem persists please email - support@trackplanfm.com'));
					console.error(err);
				});

				const LocalQueuedetails = {
					guid: this.state.guid,
					documentId: OBjectFBID,
					JobAction: 'NewRequestPhotos',
				};

				await localQueue.saveToLocalJobQueue(this.state.guid, LocalQueuedetails);
			} else {
				// post to local job queue
				await localQueue.saveToLocalJobQueue(this.state.guid as string, JobRequestObj);
			}

			this.setState({ savingRequest: false });
		} else {
			if (this.state.SelfDeliverRequestValue) {
				await fire.CreateJobTask(RequestObj, OBjectFBID);
			}

			fire.postToJobQueue(OBjectFBID, JobRequestObj).catch(err => {
				alert(this.props.t('There was an error adding job request, if problem persists please email - support@trackplanfm.com'));
				console.error(err);
			});

			this.setState({ savingRequest: false });
		}
	};

	private checkLocations = () => {
		if (!isNullOrUndefined(this.state.siteFilter)) {
			fire.doesLocationsExist(this.state.siteFilter.value).then((res) => {
				this.setState({
					locationDisabled: !res
				})
			})
		}
	}

	private checkSubLocations = () => {
		if (!isNullOrUndefined(this.state.siteFilter) && !isNullOrUndefined(this.state.locationFilter)) {
			fire.doesSubLocationsExist(this.state.locationFilter.value).then((res) => {
				this.setState({
					subLocationDisabled: !res
				})
			})
		}
	}

	private handleSiteChange = (object: { value: number; label: string }) => {
		this.setState({ siteMenuOpen: false });
		const siteId = object.value;
		let siteidN: number | null = siteId;

		if (!isNullOrUndefined(siteId)) {
			siteidN = siteId;
			this.setState({
				selectedSiteName: object.label,
				selectedSiteID: siteId,
				selectedLocationID: null,
				selectedLocationName: '',
				selectedSubLocationName: '',
				selectedSubLocationID: null,
				lastLoadedSite: null,
				siteFilter: object,
				lastLoadedLocation: null,
				lastLoadedSubLocation: null,
				loadedLocations: [],
				locationValue: '',
				subLocationValue: '',
				locationFilter: null,
				subLocationFilter: null,
			}, () => this.checkLocations());
		} else {
			this.setState({
				selectedSiteName: '',
				selectedSiteID: null,
				selectedLocationName: '',
				selectedLocationID: null,
				selectedSubLocationName: '',
				selectedSubLocationID: null,
				lastLoadedSite: null,
				siteFilter: null,
				lastLoadedLocation: null,
				locationFilter: null,
				loadedLocations: [],
				locationValue: '',
				lastLoadedSubLocation: null,
				subLocationFilter: null,
				loadedSubLocations: [],
				subLocationValue: '',
			});
		}

		//to remove the validation message
		const errorsPresent = this.checkForNullFields(siteidN, this.state.jobRequestDetails);
	};

	private handleLocationChange = (object: { value: number; label: string }) => {
		this.setState({ locationMenuOpen: false });
		const LocationID = object.value;
		if (!isNullOrUndefined(LocationID)) {
			this.setState({
				selectedLocationName: object.label,
				selectedLocationID: LocationID,
				selectedSubLocationName: '',
				selectedSubLocationID: null,
				lastLoadedLocation: null,
				locationFilter: object,
				subLocationValue: '',
				loadedSubLocations: [],
				subLocationFilter: null,
			}, () => this.checkSubLocations());
		} else {
			this.setState({
				selectedLocationName: '',
				selectedLocationID: null,
				selectedSubLocationName: '',
				selectedSubLocationID: null,
				lastLoadedLocation: null,
				locationFilter: null,
				subLocationValue: '',
				loadedSubLocations: [],
				subLocationFilter: null,
			});
		}
	}

	private handleSubLocationChange = (object: { value: number; label: string }) => {
		this.setState({ sublocationMenuOpen: false });
		const SubLocationID = object.value;
		if (!isNullOrUndefined(SubLocationID)) {
			this.setState({
				selectedSubLocationName: object.label,
				selectedSubLocationID: SubLocationID,
				lastLoadedSubLocation: null,
				subLocationFilter: object,
			});
		} else {
			this.setState({
				selectedSubLocationName: '',
				selectedSubLocationID: null,
				lastLoadedSubLocation: null,
				subLocationFilter: null,
			});
		}
	};


	private handleJobTypeChange(event: React.ChangeEvent<HTMLSelectElement>) {
		const {
			value,
			dataset: { jobtypeid },
		} = event.target.options[event.target.selectedIndex];
		const jobSubTypeList = this.state.jobSubTypesSource.filter(subType => subType.Data.JobTypeID == jobtypeid);
		const subTypeDisabled = jobSubTypeList.length < 1;

		if (!subTypeDisabled)
			jobSubTypeList.unshift({ Data: { JobSubTypeName: this.props.t('Select Job SubType'), JobSubTypeID: null }, Id: '1' });

		if (!isNullOrUndefined(jobtypeid)) {
			this.setState({
				selectedJobType: { ID: Number(jobtypeid), Name: value },
				jobSubTypes: jobSubTypeList,
				jobSubTypeDisabled: subTypeDisabled,
			});
		} else {
			this.setState({
				selectedJobType: null,
				selectedJobSubType: null,
				jobSubTypes: [],
				jobSubTypeDisabled: subTypeDisabled,
			});
		}
	}

	handleJobSubTypeChange(event: React.ChangeEvent<HTMLSelectElement>): void {
		const {
			value,
			dataset: { jobtypeid: jobsubtypeid },
		} = event.target.options[event.target.selectedIndex];

		if (!isNullOrUndefined(jobsubtypeid)) {
			this.setState({
				selectedJobSubType: { ID: Number(jobsubtypeid), Name: value },
			});
		} else {
			this.setState({
				selectedJobSubType: null,
			});
		}
	}

	handlePriorityChange = (event: React.ChangeEvent<HTMLSelectElement>): void => {
		const {
			value,
			dataset: { priorityid },
		} = event.target.options[event.target.selectedIndex];

		if (!isNullOrUndefined(priorityid)) {
			this.setState({
				selectedPriority: { ID: priorityid, Name: value },
			});
		} else {
			this.setState({
				selectedPriority: null,
			});
		}
	};

	handleDateTimeChange = (value: string): void => {
		this.setState({
			ExpectedByDate: value,
			NewDate: stringtoUnixUTC(value),
		});
	};

	private getJobTypes = () => {
		fire
			.getJobTypes()
			.then(jobTypes => {
				const array = jobTypes.docs.map(jobType => ({ Data: jobType.data(), Id: jobType.id }));
				array.sort((a, b) => a.Data.JobTypeName.toLowerCase().localeCompare(b.Data.JobTypeName.toLowerCase()));
				array.unshift({ Data: { JobTypeName: this.props.t('Select Job Type'), JobTypeID: null }, Id: '1' });

				return array;
			})
			.then(array => {
				this.setState({ jobTypes: array });
			});
	};

	private getJobSubTypes = () => {
		fire
			.getCollection('JobSubTypes', 'JobSubTypeName')
			.then(jobSubTypes => {
				const array = jobSubTypes.docs.map(jobSubType => ({ Data: jobSubType.data(), Id: jobSubType.id }));
				array.sort((a, b) => a.Data.JobSubTypeName.toLowerCase().localeCompare(b.Data.JobSubTypeName.toLowerCase()));

				return array;
			})
			.then(array => {
				this.setState({ jobSubTypesSource: array });
			});
	};

	private getPriorities = () => {
		fire
			.getCollection('Priorities', 'DisplayOrder', 'asc')
			.then(priorities => {
				const array = priorities.docs.map(priority => ({ Data: priority.data(), Id: priority.id }));
				array.sort((a, b) => {
					if (a.Data.DisplayOrder > b.Data.DisplayOrder) {
						return 1;
					} else if (a.Data.DisplayOrder < b.Data.DisplayOrder) {
						return -1;
					}

					if (a.Data.Name < b.Data.Name) {
						return -1;
					} else if (a.Data.Name > b.Data.Name) {
						return 1;
					} else {
						return 0;
					}
				});

				//a.Data.DisplayOrder - b.Data.DisplayOrder
				array.unshift({ Data: { Name: this.props.t('Select Priority') }, Id: '' });

				return array;
			})
			.then(array => {
				this.setState({ prioritiesSource: array });
			});
	};

	private checkForNullFields = (siteId: number | null, jobDetails) => {
		let errors = 0;

		if (isNullOrUndefined(siteId)) {
			this.setState({ siteIdRequired: true });
			errors += 1;
		} else {
			this.setState({ siteIdRequired: false });
		}

		if (isNullOrUndefined(jobDetails) || jobDetails === '') {
			this.setState({ detailsRequired: true });
			errors += 1;
		} else {
			this.setState({ detailsRequired: false });
		}

		return errors > 0;
	};
}

let FieldEmptyWarning = props => (
	<div style={{ ...props.style, paddingTop: 8 }}>
		<p
			style={{
				color: '#c62828',
				fontStyle: 'italic',
				display: props.showField ? 'flex' : 'none',
			}}
		>
			{props.textForField}
		</p>
	</div>
);

const mapStateToProps = (state: Store.Store) => ({
	NewIssueState: state.JobTask.NewIssueState,
	CanSelfDeliverSetting: state.User.UserSettings !== null ? state.User.UserSettings.CanSelfDeliver : false,
	UserSettings: state.User.UserSettings,
});

const mapDispatchToProps = dispatch => ({
	SaveNewIssueState: state => dispatch(SaveNewIssueState(state)),
	ClearNewIssueState: () => dispatch(ClearNewIssueState()),
});

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(NewJobRequestScreen));
