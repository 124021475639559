import * as React from 'react';
import { Provider } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { HashRouter as Router } from 'react-router-dom';

// Local imports
import store from './store.js';

// Routes
import JobTaskDetails from './views/JobTask/JobTaskDetails';
import JobTaskListScreen from './views/JobTask/JobTaskListScreen';
import CalendarScreen from './views/Calendar/Calendar';
import LoginScreen from './views/LoginScreen';
import NewJobRequestScreen from './views/NewJobRequestScreen';
import TakePhotoScreen from './views/Photos/TakePhotoScreen';
import TimesheetListScreen from './views/Timesheets/TimesheetListScreen';
import TimesheetDetailsScreen from './views/Timesheets/TimesheetDetailsScreen';
import ExpensesListScreen from './views/Expenses/ExpensesListScreen';
import ExpensesDetailsScreen from './views/Expenses/ExpensesDetailsScreen';
import FormDetailsScreen from './views/Forms/FormDetailsScreen';
import FormLibraryListScreen from './views/form-library/FormLibraryListScreen';
import FormLibraryDetailsScreen from './views/form-library/FormLibraryDetailsScreen';
import AssetListScreen from './views/Assets/AssetList';
import AssetCreateScreen from './views/Assets/AssetCreateScreen';
import AssetDetailsModal from './views/Assets/AssetDetailsModal';
import AssetDetails from './views/Assets/AssetDetails';
import NotificationsList from './views/Notifications/NotificationsList';
import JobQuotesList from './views/JobQuotes/JobQuotesList';
import JobQuoteCreateScreen from './views/JobQuotes/JobQuoteCreateScreen';
import JobQuote from './views/JobQuotes/JobQuote';
import JobCostDetails from './views/Costs/JobCostDetails';
import SitesList from './views/Sites/SitesList';
import SiteDetails from './views/Sites/SiteDetails';
import LocationList from './views/Locations/LocationsList';
import LocationDetails from './views/Locations/LocationDetails';
import SubLocationsList from './views/Locations/SublocationsList';
import SubLocationDetails from './views/Locations/SublocationDetails';
import SettingsScreen from './views/Settings/SettingsScreen';
import PasswordReset from './views/Settings/PasswordReset';
import CreatedJobScreen from './views/CreatedJobScreen';
import StorageAreasList from "./views/StorageAreas/StorageAreasList";
import StorageAreaDetails from "./views/StorageAreas/StorageAreaDetails";

const PrivateRoute = ({ component: Component, ...rest }) => {
	return (
		<Route
			{...rest}
			render={props => {
				return store.getState().User.User !== null ? (
					<Component {...props} />
				) : (
					<Redirect
						to={{
							pathname: '/',
							state: { from: props.location },
						}}
					/>
				);
			}}
		/>
	);
};

const App = () => {
	return (
		<Provider store={store}>
			<Router>
				<div>
					<Route path="/" exact={true} component={LoginScreen} />

					{/* Translated */}
					<PrivateRoute path="/job-task-list" component={JobTaskListScreen} />
					<PrivateRoute path="/new-request" component={NewJobRequestScreen} />
					<PrivateRoute path="/new-request-form-fail/:formid" component={NewJobRequestScreen} />
					<PrivateRoute path="/take-photo" component={TakePhotoScreen} />
					<PrivateRoute path="/timesheets" component={TimesheetListScreen} />
					<PrivateRoute path="/expenses" component={ExpensesListScreen} />
					<PrivateRoute path="/settings" component={SettingsScreen} />
					<PrivateRoute path="/passwordreset" component={PasswordReset} />
					<PrivateRoute path="/timesheet-details/:id" component={TimesheetDetailsScreen} />
					<PrivateRoute path="/job-task-details/:id/:fromassetfbid?" component={JobTaskDetails} />
					<PrivateRoute path="/expense-details/:id" component={ExpensesDetailsScreen} />				
					<PrivateRoute path="/form-details/:jobtaskid/:formid" component={FormDetailsScreen} />
					<PrivateRoute path="/form-library" component={FormLibraryListScreen} />	
					<PrivateRoute path="/form-library-details/:formtemplateid/:formid" component={FormLibraryDetailsScreen} />				
					<PrivateRoute path="/asset-list/:formid/" component={AssetListScreen} exact={true} />
					<PrivateRoute path="/asset-list/:jobtaskid/:jobid" component={AssetListScreen} exact={true}/>
					<PrivateRoute path="/asset-details/:id" component={AssetDetailsModal} />					
					<PrivateRoute path="/asset-nmdetails/:id/:jobtaskfbid?/:jobfbid?" component={AssetDetails} />
					<PrivateRoute path="/new-request-from-Asset/:AssetID" component={NewJobRequestScreen} />
					<PrivateRoute path="/new-asset" component={AssetCreateScreen} />
					<PrivateRoute path="/notifications" component={NotificationsList} />

					<PrivateRoute path="/new-request-from-SubLocation/:SubLocationID" component={NewJobRequestScreen} />
					<PrivateRoute path="/new-request-from-Location/:LocationID" component={NewJobRequestScreen} />
					<PrivateRoute path="/new-request-from-Site/:SiteID" component={NewJobRequestScreen} />
					<PrivateRoute path="/associated-jobtasks" component={JobTaskListScreen} />	
					<PrivateRoute path="/associated-assets" component={AssetListScreen} />
					<PrivateRoute path="/new-quote/:jobtaskid" component={JobQuoteCreateScreen} />
					<PrivateRoute path="/job-quotes-list" component={JobQuotesList} />
					<PrivateRoute path="/job-quote/:id" component={JobQuote} />
					<PrivateRoute path="/job-cost/:id" component={JobCostDetails} />
					<PrivateRoute path="/site-assets" component={AssetListScreen} />
					<PrivateRoute path="/sites-list" component={SitesList} />
					<PrivateRoute path="/locations-list/:id" component={LocationList} />
					<PrivateRoute path="/site-details/:id" component={SiteDetails} />
					<PrivateRoute path="/sublocations-list/:id" component={SubLocationsList} />
					<PrivateRoute path="/location-details/:id" component={LocationDetails} />
					<PrivateRoute path="/sublocation-details/:id" component={SubLocationDetails} />
					<PrivateRoute path="/storage-areas-list" component={StorageAreasList} />
					<PrivateRoute path="/storage-area-details/:id" component={StorageAreaDetails} />
					<PrivateRoute path="/jobsuccessful/:id?" component={CreatedJobScreen} />
					
					{/*To be Translated*/}
					<PrivateRoute path="/Calendar" component={CalendarScreen} />
				
															
				</div>
			</Router>
		</Provider>
	);
};

export default App;
