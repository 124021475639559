import * as React from 'react';

import { fire } from '../../index';
import { isNullOrUndefined } from 'util';
import { Dialog, DialogActions, DialogTitle, DialogContent, TextField, Icon, Button } from '@material-ui/core';
import styled from 'styled-components';
import { IsChrome } from '../../utils/IsChrome';
import { stringtoUnixUTC, unixToDateString, parseDateForDateTimeFields } from '../../utils/Times';
import { withTranslation } from 'react-i18next';

interface EditCostDialogProps {
    open: boolean;
    closeModal: () => void;
    JobCostFBID: string;
    JobCostDetails: Cost.Cost;
    t: any;
}

interface EditCostDialogState {
    InvoiceNumber: string;
    InvoiceDate: string;
    Description: string;
    CallOut: string;
    Labour: string;
    Materials: string;
    TotalAmount: number;
    NewDate: number;
    InvoiceDateRequired: boolean;
    InvoiceNumberRequired: boolean;
    DescriptionRequired: boolean;
    LabourRequired: boolean;
    MaterialsRequired: boolean;
    CallOutRequired: boolean;
    savingCost: boolean;
}

class EditCostDialog extends React.Component<EditCostDialogProps, EditCostDialogState> {
    constructor(props) {
        super(props);
        this.state = {
            InvoiceNumber: '',
            InvoiceDate: '',
            Description: '',
            CallOut: '',
            Labour: '',
            Materials: '',
            TotalAmount: 0,
            NewDate: new Date().getTime(),
            InvoiceDateRequired: false,
            InvoiceNumberRequired: false,
            DescriptionRequired: false,
            LabourRequired: false,
            MaterialsRequired: false,
            CallOutRequired: false,
            savingCost: false,
        }
    }

    componentWillReceiveProps(newProps: EditCostDialogProps) {
        this.setState({
            InvoiceNumber: newProps.JobCostDetails.InvoiceNumber,
            InvoiceDate: parseDateForDateTimeFields(newProps.JobCostDetails.InvoiceDate),
            Description: newProps.JobCostDetails.Description,
            CallOut: newProps.JobCostDetails.CallOut,
            Labour: newProps.JobCostDetails.Labour,
            Materials: newProps.JobCostDetails.Materials,
        }, this.calculateTotalAmount);
    }

    calculateTotalAmount = () => {
        const { Materials, Labour, CallOut } = this.state;
        const total = (+Materials) + (+Labour) + (+CallOut);
        const roundedTotal = (Math.round(total * 100) / 100).toFixed(2);
        this.setState({
            TotalAmount: parseFloat(roundedTotal)
        })
    }

    checkForNullFields = () => {
        const { InvoiceNumber, Description, Materials, Labour, CallOut } = this.state;

        let errors = 0;

        if(isNullOrUndefined(InvoiceNumber) || InvoiceNumber === '') {
            this.setState({ InvoiceNumberRequired: true });
            errors += 1;
        } else {
            this.setState({ InvoiceNumberRequired: false });
        }

        if(isNullOrUndefined(Description) || Description === '') {
            this.setState({ DescriptionRequired: true });
            errors += 1;
        } else {
            this.setState({ DescriptionRequired: false });
        }

        if(isNullOrUndefined(Labour) || Labour === '') {
            this.setState({ LabourRequired: true });
            errors += 1;
        } else {
            this.setState({ LabourRequired: false });
        }

        if(isNullOrUndefined(CallOut) || CallOut === '') {
            this.setState({ CallOutRequired: true });
            errors += 1;
        } else {
            this.setState({ CallOutRequired: false });
        }

        if(isNullOrUndefined(Materials) || Materials === '') {
            this.setState({ MaterialsRequired: true });
            errors += 1;
        } else {
            this.setState({ MaterialsRequired: false });
        }

        return errors > 0;

    }

    saveChanges(): void {
        this.setState({ savingCost: true });

        const errorsPresent = this.checkForNullFields();

        if(errorsPresent) {
            return this.setState({ savingCost: false });
        }

        const { InvoiceNumber, InvoiceDate, Description, Materials, Labour, CallOut} = this.state;

        const { CostFBID } = this.props.JobCostDetails;

        const JobCostObj = { 
            InvoiceNumber, 
            InvoiceDate: stringtoUnixUTC(InvoiceDate), 
            Description, 
            Materials: parseFloat(Materials).toFixed(2),
            Labour: parseFloat(Labour).toFixed(2),
            CallOut: parseFloat(CallOut).toFixed(2)
        };

        const ref = fire.baseQuery.collection('Costs').doc(CostFBID);

        fire.updateDocument(ref, JobCostObj);

        JobCostObj['JobAction'] = "CostEdit";

        fire.postToJobQueue(CostFBID, JobCostObj).catch(err => {
            alert(this.props.t('There was an error editing job cost. If problem pesists please email - support@trackplanfm.com'));
            console.log(err);
        });

        this.props.closeModal();
        this.setState({ savingCost: false });
    }

    handleValueChange = e => {
        this.setState({
            [e.target.name]: e.target.value
        } as EditCostDialogState, this.calculateTotalAmount)
    }

    handleDateTimeChange = (value: string): void => {
        this.setState({
            InvoiceDate: value
        })
    }

    render() {
        const { InvoiceDate } = this.state;
        return (
            <Dialog open={this.props.open} aria-labelledby="Edit Job Cost" fullScreen={true} onClose={this.props.closeModal} maxWidth="lg">
                <DialogTitle id="form-dialog-title">{this.props.t("Edit Job Cost")}</DialogTitle>
                <DialogContent>
                    <Content>
                        <TextField 
                            error={this.state.InvoiceNumberRequired}
                            value={this.state.InvoiceNumber}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_invoiceNumber"
                            type="text"
                            label={this.props.t("Invoice Number")}
                            placeholder="Enter Invoice Number"
                            name="InvoiceNumber"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.InvoiceNumberRequired}
                            textForField={this.props.t("Invoice Number is Required")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content style={{ display: 'flex', flexWrap: 'wrap' }}>
                        <TextField 
                            value={this.state.InvoiceDate}
                            variant="standard"
                            fullWidth={true}
                            id="cost_invoiceDate"
                            type="datetime-local"
                            label={this.props.t("Invoice Date")}
                            placeholder="Enter Invoice Date"
                            name="InvoiceDate"
                            onChange={event => this.handleDateTimeChange(event.target.value)}
                            style={{ marginBottom: '15px', width: 320 }}
                            InputLabelProps={{
                                shrink: true,
                            }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            error={this.state.DescriptionRequired}
                            value={this.state.Description}
                            autoComplete={IsChrome()}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_description"
                            label={this.props.t("Description")}
                            placeholder="Enter Description"
                            name="Description"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.DescriptionRequired}
                            textForField= {this.props.t("Job Cost Description Cannot be Empty")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            error={this.state.MaterialsRequired}
                            value={this.state.Materials}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_materials"
                            type="number"
                            label={this.props.t("Materials")}
                            placeholder="Enter Materials Cost"
                            name="Materials"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.MaterialsRequired}
                            textForField={this.props.t("Materials Cost Cannot be Empty or Less than 0")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            error={this.state.LabourRequired}
                            value={this.state.Labour}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_labour"
                            type="number"
                            label={this.props.t("Labour")}
                            placeholder="Enter Labour Cost"
                            name="Labour"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.LabourRequired}
                            textForField={this.props.t("Labour Cost Cannot be Empty or Less than 0")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            error={this.state.CallOutRequired}
                            value={this.state.CallOut}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_other"
                            type="number"
                            label={this.props.t("Other")}
                            placeholder="Enter CallOut Cost"
                            name="CallOut"
                            onChange={e => this.handleValueChange(e)}
                        />
                        <FieldEmptyWarning
                            showField={this.state.CallOutRequired}
                            textForField= {this.props.t("CallOut Cost Cannot be Empty or Less than 0")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField 
                            disabled={true}
                            value={this.state.TotalAmount}
                            margin="none"
                            fullWidth={true}
                            variant="outlined"
                            id="cost_totalamt"
                            type="number"
                            label={this.props.t("Total Amount")}
                            placeholder="Total Amount"
                            name="TotalAmount"
                        />
                    </Content>
                </DialogContent>

                <DialogActions>
                    <Button
                        onClick={() => this.props.closeModal()}
                        color="primary"
                        style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
                    >
                        <Icon style={{ fontSize: 20 }}>undo</Icon> &nbsp;
                        <p style={{ marginTop: 2 }}>{this.props.t("Back")}</p>
                    </Button>
                    <Button
                        onClick={() => this.saveChanges()}
                        color="primary"
                        style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
                    >
                        <Icon style={{ fontSize: 20 }}>save</Icon>&nbsp;
                        <p style={{ marginTop: 2 }}>{this.props.t("Save")}</p>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

let FieldEmptyWarning = props => (
	<div style={{ ...props.style, paddingTop: 8 }}>
		<p
			style={{
				color: '#c62828',
				fontStyle: 'italic',
				display: props.showField ? 'flex' : 'none',
			}}
		>
			{props.textForField}
		</p>
	</div>
);

const Content = styled.div`
    margin-top: 15px;
`;

export default withTranslation()(EditCostDialog);