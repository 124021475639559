import * as React from 'react';
import { IconButton, Icon } from '@material-ui/core';

interface IBackButtonProps {
	callbackMethod: () => void;
}

export default class BackButton extends React.Component<IBackButtonProps> {
	render() {
		return (
			<IconButton onClick={() => this.props.callbackMethod()}>
				<Icon style={{ color: 'white', zIndex: 1 }}>arrow_back</Icon>
			</IconButton>
		);
	}
}
