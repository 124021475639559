// React
import * as React from 'react';
import { Link } from 'react-router-dom';

// Material UI
import { isNullOrUndefined } from 'util';
import { Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const SubLocationCard = props => {
	const [t, i18n] = useTranslation();	
	return props.locations.map((sublocations: SubLocation, i: number) => {

		const destinationUrl = `/sublocation-details/${sublocations.ID}`;

		return (
			<Link to={destinationUrl}>
			<div
				key={i}
				style={{
					border: `2.5px solid var(--light-grey)`,
					marginBottom: '10px',
					padding: '10px',
				}}
				className="card-shadow-site site-card"
			>
				<Grid container>
					<Grid container xs={6} justify="flex-start" alignItems="flex-end">
						<div>
							<p style={{ overflowWrap: 'break-word' }} className="content-site">
								<strong>{sublocations.SubLocationName}</strong>
							</p>
							<p className="label-site">{t("SubLocation Name")}</p>
							<br />
						</div>
					</Grid>

					<Grid container xs={6} justify="flex-start" alignItems="flex-end">
						<div>
							
							<p className="content-site">{sublocations.Code ? sublocations.Code : 'n/a'}</p>
							<p className="label-site">{t("SubLocation Code")}</p>
							<br />
						</div>
					</Grid>

					<Grid container xs={6} justify="flex-start" alignItems="flex-end">
						<div>
							<p className="content-site">{sublocations.LocationName}</p>
							<p className="label-site">{t("Location Name")}</p>
							<br />
						</div>
					</Grid>

					<Grid container xs={6} justify="flex-start" alignItems="flex-end">
						<div>
							<p className="content-site">{sublocations.SiteName}</p>
							<p className="label-site">{t("Site Name")}</p>
							<br />
						</div>
					</Grid>

				</Grid>
			</div>
			</Link>
		);
	});
};

export default SubLocationCard;
