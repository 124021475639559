import { Result } from '@zxing/library';
import { fire } from '../..';
import { generateFirebaseId } from '../../utils/Guids';
import { History } from 'history';
import { LocalstorageSetItem } from '../../utils/LocalStorage';

const addAssetFromQRCode = async (
	DocumentID: string,
	JobTaskID: string,
	DocumentType: AddToAssetDocumentType,
	QuestionAnswerID: string | null,
	QRCode: Result,
	history: History,
	translate: any,
	formTemplateFBID: string,
	taskID: string
) => {
	let QRCodeText = QRCode.getText();

	await fire.QRSearch(QRCodeText).then(result => {
		if (result.docs.length > 0) {
			const data = result.docs[0].data();
			if (
				data.AssetFBID !== null &&
				data.AssetFBID !== undefined &&
				data.AssetName !== null &&
				data.AssetName !== undefined
			) {
				handleAddAsset(
					result.docs[0].data().AssetFBID,
					result.docs[0].data().AssetName,
					DocumentID,
					JobTaskID,
					DocumentType,
					QuestionAnswerID,
					history,
					translate,
					formTemplateFBID,
					taskID
				);
			} else {
				alert(translate('The scanned QRCode is not associated to an asset'));

			}
		} else {
			const confirm = window.confirm(translate('The scanned QRCode is not associated to an asset, do you want to add it as a new asset?'));
            if (confirm) {
				LocalstorageSetItem({ 
					Key: 'QRCodeTextJson', 
					Value: { QRCodeText: QRCodeText, 
							DocumentID: DocumentID, 
							JobTaskID: JobTaskID, 
							DocumentType: DocumentType, 
							QuestionAnswerID: QuestionAnswerID } 
				});
                history.push('/new-asset');
            }
        }
	});
};

async function handleAddAsset(
	AssetID: string,
	AssetName: string,
	DocumentID: string,
	JobTaskID: string,
	DocumentType: AddToAssetDocumentType,
	QuestionAnswerID: string | null,
	history: History,
	translate: any,
	formTemplateFBID?: string,
	taskID?: string
) {
	if (DocumentType === 'job') {
		//first check if asset already added to this job
		let doesAssetToJobAlreadyExist = await fire.doesAssetToJobAlreadyExist(AssetID, DocumentID);
		if (doesAssetToJobAlreadyExist) {
			//alert(this.props.t('Asset already assigned to this job'));
			return;
		}

		if (confirm(translate('Are you sure you wish to add') + ' ' + AssetName + ' ' + translate('to this job?'))) {
			const AssetJob = {
				AssetFBID: AssetID,
				JobFBID: DocumentID,
				DateCreated: Date.now(),
			};
			let assetjobid = generateFirebaseId();
			let JobNumber = await fire.createAssetToJob(AssetJob, assetjobid);

			await fire.createAssetToJob(AssetJob, assetjobid);

			const Details = {
				AssetFBID: AssetID,
				JobFBID: DocumentID,
				DateCreated: Date.now(),
				JobNumber: JobNumber, //this is sent back to tell the main application whether the job has been created yet in the main application.
				JobAction: 'AddAssetToJob',
			};
			fire.postToJobQueue(assetjobid, Details).catch(err => {
				//alert(this.props.t("There was an error the asset to the job, if problem persists please email - support@trackplanfm.com"));
				console.error(err);
			});

			history.push('/job-task-details/' + JobTaskID);
		}
	} else if (DocumentType === 'form') {
		if (!QuestionAnswerID) {
			return;
		}
		//first check if asset already added to this form
		let doesAssetToFormAlreadyExist = await fire.doesAssetToFormAlreadyExist(AssetID, DocumentID, QuestionAnswerID);
		if (doesAssetToFormAlreadyExist) {
			alert('Error attaching asset. This asset is already selected on the form question.');
			fire.getFormTemplateFBID(DocumentID).then(formTemplateFBID => {
				history.replace('/form-details/' + formTemplateFBID + '/' + DocumentID);
			});
			return;
		}

		if (confirm(translate('Are you sure you wish to add') + ' ' + AssetName + ' ' + translate(`to this form?`))) {
			const AssetForm = {
				AssetFBID: AssetID,
				FormFBID: DocumentID,
				QuestionAnswerFBID: QuestionAnswerID,
			};

			fire.createAssetToForm(AssetForm).then(() => {
				const Details = {
					AssetFBID: AssetID,
					FormFBID: DocumentID,
					QuestionFBID: QuestionAnswerID,
					DateCreated: Date.now(),
					JobAction: 'FormAnswer',
					QuestionType: 'Asset'
				};

				fire.postToJobQueue(DocumentID, Details).catch(err => {
					alert(
						translate(
							'There was an error the asset to the job, if problem persists please email - support@trackplanfm.com',
						),
					);
					console.error(err);
				});
			});

			console.log("taskID", taskID)
			console.log("formTemplateFBID", formTemplateFBID)
			console.log("JobTaskID", JobTaskID)

			if(taskID === ''){
				history.replace('/form-details/' + formTemplateFBID + '/' + DocumentID);
			} else {
				history.replace('/form-details/' + taskID + '/' + DocumentID);
			}
		}
	}
}

export { addAssetFromQRCode, handleAddAsset };
