import { Button, Dialog, DialogContent, DialogContentText, DialogTitle, Grid, TextField } from '@material-ui/core';
import React, { ChangeEvent } from 'react';
import { useTranslation } from 'react-i18next';

enum Action {
	ADD,
	REMOVE,
}

interface TableDialogProps {
	isOpen: boolean;
	action: Action;
	stockToChange: StockToStorageArea;
	quantity: number | string;
	handleQuantityChange: (event: ChangeEvent<HTMLInputElement>) => void;
	comments: string;
	setComments: any;
	handleAction: () => void;
	handleClose: () => void;
}

const TableDialog = ({
	isOpen,
	action,
	stockToChange,
	quantity,
	handleQuantityChange,
	comments,
	setComments,
	handleAction,
	handleClose,
}: TableDialogProps) => {
	const [t, i18n] = useTranslation();
	const color = action === Action.ADD ? 'primary' : 'secondary';
	const actionText = action === Action.ADD ? t('Add') : t('Remove');

	const handleCommentsChange = (event: ChangeEvent<HTMLInputElement>) => {
		setComments(event.target.value);
	};

	return (
		<Dialog open={isOpen} onClose={handleClose}>
			<DialogTitle>{`${actionText} ${stockToChange.StockName}`}</DialogTitle>
			<DialogContent>
				<DialogContentText>
				{t("Current quantity of") + " " + stockToChange.StockName + " : " + stockToChange.CurrentLevel}
				</DialogContentText>
				<DialogContentText>{t('How many {{x}} do you want to {{y}}?', {x: stockToChange.StockName, y: actionText})}</DialogContentText>
				<TextField
					autoFocus
					fullWidth
					margin="dense"
					label={t("Quantity")}
					type="number"
					variant="outlined"
					color={color}
					style={{ marginTop: 15, marginBottom: 15 }}
					value={quantity}
					onChange={handleQuantityChange}
					InputProps={{ inputProps: { min: 0 } }}
				/>
				<TextField
					fullWidth
					margin="dense"
					color={color}
					label={t("Comments")}
					multiline
					rows={4}
					style={{ marginBottom: 25 }}
					placeholder={t("Your comments...")}
					variant="outlined"
					value={comments}
					onChange={handleCommentsChange}
				/>
				<Grid container justify="space-between">
					<Button onClick={handleClose} color={color} variant="outlined">
						{t("Cancel")}
					</Button>
					<Button onClick={handleAction} color={color} variant="contained">
						{actionText + " " + t("Quantity")}
					</Button>
				</Grid>
			</DialogContent>
		</Dialog>
	);
};

export default TableDialog;
