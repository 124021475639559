import { useTranslation } from "react-i18next";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Icon, IconButton,
	Table, TableBody, TableCell, TableHead, TableRow } from "@material-ui/core";
import useFormsListCards from "../../hooks/FormsListCards/useFormsListCards";
import React, { useState } from 'react';
import OuterCard from "./OuterCard";
import CardHeader from './CardHeader';
import { unixToDateString } from "../../utils/Times";
import { isNullOrUndefined } from "util";
import LoadingSpinner from "./LoadingSpinner";

//card icon
import FORM from '../../assets/images/icons/FORM.svg';

type FormsListCardsProps = {
	associatedFBID: string;
	fromView: string;
	areTemplatesRestricted: boolean;
	restrictedTemplates: string[];
};

const FormsListCards = ({ associatedFBID, fromView, areTemplatesRestricted, restrictedTemplates }: FormsListCardsProps) => {
	const {
		forms,
		displayDialog,
		setDisplayDialog,
		availableForms,
		attachForm,
		handleClick,
		formLoading
	} = useFormsListCards(associatedFBID, fromView, areTemplatesRestricted, restrictedTemplates);
	const { t } = useTranslation();

	const [expand, setExpand] = useState(false);

	const handleExpand = (value: boolean) => {
		setExpand(value)
	}

	return (
		<div>
			{(
				<OuterCard padding="0px" styles="forms-card border-radius">
					<CardHeader
						title={t("Forms")}
						textColor="white"
						backgroundColor="darkblue"
						showActionButton={true}
						actionButtonID="attach-form-button"
						actionButtonIcon="description"
						actionButtonText={t("Add")}
						actionButtonClickHandler={() => setDisplayDialog(!displayDialog)}
						expand={expand}
						handleExpand={handleExpand}
						showExpand={true}
						titleIcon={FORM}
					/>
					{forms.length < 1 ? (
                        <div className='no-elements-card-text'>
                            {expand && <p>{t("No Forms")}</p>}
                        </div>
                    	) : <>{expand ? <Table style={{ paddingLeft: '10px' }}>
						<TableHead>
							<TableRow style={{ padding: '5px', paddingBottom: '5px', paddingLeft: '5px' }}>
								<TableCell className="form-cell-name-header">
									{t("Name")}
								</TableCell>
								<TableCell className="form-cell-task-header" padding="none">
									{t("Created Date")}
								</TableCell>
								<TableCell className="form-cell-task-header" padding="none">
									{t("Submitted Date")}
								</TableCell>
								<TableCell className="form-cell-button-header" padding="none" />
							</TableRow>
						</TableHead>
						<TableBody>
							{forms.map((row, i) => {
								return (
									<TableRow key={i}>
										<TableCell className="form-cell-name" padding="none">
											{row.FormName}
										</TableCell>
										<TableCell className="form-cell-task" padding="none">
											{!isNullOrUndefined(row.CreatedDate) ? (
												unixToDateString(row.CreatedDate)
											) : null}
										</TableCell>
										<TableCell className="form-cell-task" padding="none">
											{!isNullOrUndefined(row.CompletedDate) ? (
												unixToDateString(row.CompletedDate)
											) : null}
										</TableCell>
										<TableCell className="form-cell-button" padding="none">
											<IconButton onClick={() => handleClick(row)}>
												<Icon>pageview</Icon>
											</IconButton>
										</TableCell>
									</TableRow>
								);
							})}
						</TableBody>
					</Table> : <></>}</>}
				</OuterCard>
			)}

			{displayDialog ? (
				availableForms.length > 0 ? (<Dialog open={displayDialog} onClose={() => setDisplayDialog(!displayDialog)} fullScreen={true} scroll="paper">
					{formLoading === false && <DialogTitle id="form-dialog-title">{t("Select Form")}</DialogTitle>}
					<DialogContent
						id="content"
						classes={{ root: 'dialog-content-root' }}
						// @ts-ignore
						dividers={scroll === 'paper'}
					>
						{formLoading === false ?
							<Table>
								<TableHead>
									<TableRow>
										<TableCell className="cell" padding="none">
											{t("Name")}
										</TableCell>
										<TableCell className="cell" padding="none">
											{t("Type")}
										</TableCell>
										<TableCell className="cell" padding="none" />
									</TableRow>
								</TableHead>
								<TableBody>
									{availableForms.map((form, index, _availableForms) => {
										return (
											<TableRow key={index}>
												<TableCell className="form-Template-cell" padding="none">
													{form.FormName}
												</TableCell>
												<TableCell className="form-Template-cell" padding="none">
													{t(form.FormType)}
												</TableCell>
												<TableCell className="form-Template-cell" padding="none">
													<IconButton onClick={() => attachForm(form)} >
														<Icon>add_circle</Icon>
													</IconButton>
												</TableCell>
											</TableRow>
										);
									})}
								</TableBody>
							</Table> : <LoadingSpinner text={t("Form loading - this may take up to 10 seconds.")} loadscreenButton={false} />}
					</DialogContent>
					<DialogActions classes={{ root: 'action-buttons-list' }}>
						<Button onClick={() => setDisplayDialog(!displayDialog)} color="primary">
							{t("Close")}
						</Button>
					</DialogActions>
				</Dialog>) :

					(<Dialog open={displayDialog} onClick={() => setDisplayDialog(!displayDialog)} fullScreen={false}>
						<DialogTitle id="form-dialog-title">{t("No Forms Available")}</DialogTitle>
						<DialogActions classes={{ root: 'action-buttons-list' }}>
							<Button onClick={() => setDisplayDialog(!displayDialog)} color="primary">
								{t("Close")}
							</Button>
						</DialogActions>
					</Dialog>)
			) : null}
		</div>
	);
};

export default FormsListCards;


