import { CURRENT_QUOTES, CURRENT_QUOTES_SCROLLY, CLEAR_QUOTES_STATE } from '../actions/JobQuoteActions';

const initialState = {
    totalQuotesLoaded: 0,
    yAxisPos: 0
};

const JobQuotesReducer = (state = initialState, action) => {

    switch (action.type) {
        case CURRENT_QUOTES:
            state = {
                ...state, totalQuotesLoaded: action.payload
            }
            return state;
        case CURRENT_QUOTES_SCROLLY:
            state = {
                ...state, yAxisPos: action.payload
            };
            return state;
        case CLEAR_QUOTES_STATE:
            state = initialState;
            return state;
        default:
            return state;
    }
};

export default JobQuotesReducer;