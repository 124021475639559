import * as React from 'react';
import { TextField } from '@material-ui/core';
import { parseDateForDateTimeFields } from '../../utils/Times';
import { FormControl, NativeSelect } from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { withTranslation } from 'react-i18next';

interface DateOrPriorityProps {
	NewDate: number;
	ExpectedByDate: string;
	selectedPriority: {
		Name: string;
		ID: string;
	} | null;
	prioritiesSource: any[];
	handlePriorityChange(event: React.ChangeEvent<HTMLSelectElement>): void;
	handleDateTimeChange(value: string): void;
	NewJobDatePriorityTreatment: string | null | undefined;
	t: any;
}

 class DateOrPriority extends React.Component<DateOrPriorityProps> {
	render() {
		if (
			isNullOrUndefined(this.props.NewJobDatePriorityTreatment) ||
			this.props.NewJobDatePriorityTreatment === '' ||
			this.props.NewJobDatePriorityTreatment === 'Neither'
		) {
			return null;
		}

		if (this.props.NewJobDatePriorityTreatment === 'Date') {
			return (
				<TextField
					id="expected-date"
					fullWidth={true}
					type="datetime-local"
					onChange={event => this.props.handleDateTimeChange(event.target.value)}
					defaultValue={
						!isNullOrUndefined(this.props.ExpectedByDate) && this.props.ExpectedByDate !== ''
							? this.props.ExpectedByDate
							: parseDateForDateTimeFields(this.props.NewDate)
					}
				/>
			);
		}

		if (this.props.NewJobDatePriorityTreatment === 'Priority') {
			return (
				<FormControl style={{ width: '100%', marginTop: '25px' }}>
					<NativeSelect
						value={this.props.selectedPriority === null ? '' : this.props.selectedPriority.Name}
						onChange={evt => this.props.handlePriorityChange(evt)}
						name={this.props.t("Job SubType")}
					>
						{this.props.prioritiesSource.length > 0 ? (
							this.props.prioritiesSource.map((priorities, i) => (
								<option key={priorities.Data.Name} data-priorityid={priorities.Id} value={priorities.Data.Name}>
									{priorities.Data.Name}
								</option>
							))
						) : (
							<option key={1} value="empty">
								{this.props.t("No Priorities")}
							</option>
						)}
					</NativeSelect>
				</FormControl>
			);
		}
	}
}

export default withTranslation()(DateOrPriority)
