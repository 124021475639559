import * as React from 'react';
import Button from '@material-ui/core/Button';
import { isNullOrUndefined } from 'util';
import { History } from 'history';
import { Icon } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
interface JobTaskActionButtonProps {
	userSettings: Store.UserSettings;
	JobTask: JobTask.JobTask;
	acceptTask: () => void;
	startTravel: () => void;
	arriveOnSite: () => void;
	leaveSite: () => void;
	currentJobTask: string | null | undefined;
	taskId: string;
	style: any;
	riskAssessmentsArray: [string];
	history: History;
}

const isDisabled = (currentJobTask: string | null | undefined, taskId: string, userSettings: Store.UserSettings) => {
	//setting to block other job task actions when task active, defaults to false
	if (!userSettings.EnableJobTaskActiveBlock) {
		return false;
	}

	if (isNullOrUndefined(currentJobTask)) {
		return false;
	}

	return currentJobTask !== taskId;
};

const className = (disabled: boolean, originalClassName: string) => {
	if (disabled) {
		return 'button-disabled';
	}
	return originalClassName;
};

const JobTaskActionButton = (props: JobTaskActionButtonProps) => {
	const [t, i18n] = useTranslation();
	const settings: Store.UserSettings = props.userSettings as Store.UserSettings;
	const task = props.JobTask;
	const disabled = isDisabled(props.currentJobTask, props.taskId, props.userSettings);

	const reasonDisabledNote = (disabled: boolean) => {
		if (disabled) {
			return <p className="disabled-button-note">{t("Disabled due to another active job task.")}</p>;
		}
		return null;
	};

	if (!isNullOrUndefined(task.CompletedDate)) {
		return null;
	}

	// Require accept is true and accepted date is null
	if (settings.RequireAccept && isNullOrUndefined(task.AcceptedDate)) {
		return (
			<Button variant="contained" className="accept-button" onClick={() => props.acceptTask()}>
				<Icon style={{ fontSize: 20 }}>thumb_up</Icon>&nbsp;
				<p style={{ marginTop: 2 }}>{t("Accept")}</p>
			</Button>
		);
	}

	if (
		settings.EnableStartTravel &&
		(isNullOrUndefined(task.TravelStartDate) || task.Status === 'Left Site' || task.Status === 'Left Task')
	) {
		return (
			<div>
				<Button
					disabled={disabled}
					variant="contained"
					className={className(disabled, 'start-travel-button')}
					onClick={() => props.startTravel()}
					style={props.style}
				>
					<Icon style={{ fontSize: 20 }}>directions_car</Icon>&nbsp;
					<p style={{ marginTop: 2 }}>{t("Start Travel")}</p>
				</Button>
				{reasonDisabledNote(disabled)}
			</div>
		);
	}

	if (props.riskAssessmentsArray.length > 0) {
		return (
			<div>
				<Button
					disabled={disabled}
					variant="contained"
					className={className(disabled, 'risk-assessment-button')}
					onClick={() => {
						const url = `/form-details/${props.taskId}/${props.riskAssessmentsArray[0]}`;
						props.history.push(url);
					}}
					style={props.style}
				>
					<Icon style={{ fontSize: 20 }}>assignment</Icon>&nbsp;
					<p style={{ marginTop: 2 }}>{t("Complete Risk Assessment")}</p>
				</Button>
			</div>
		);
	}

	if (settings.EnableArriveOnSite && isNullOrUndefined(task.ArrivedDate)) {
		return (
			<div>
				<Button
					disabled={disabled}
					variant="contained"
					className={className(disabled, 'arrive-button')}
					onClick={() => props.arriveOnSite()}
					style={props.style}
				>
					<Icon style={{ fontSize: 20 }}>announcement</Icon>&nbsp;
					<p style={{ marginTop: 2 }}> {t("Start Task")}</p>
				</Button>
				{reasonDisabledNote(disabled)}
			</div>
		);
	}

	return (
		<div>
			<Button
				disabled={disabled}
				variant="contained"
				className={className(disabled, 'leave-site-button')}
				onClick={() => props.leaveSite()}
				style={props.style}
			>
				<Icon style={{ fontSize: 20 }}>exit_to_app</Icon>&nbsp;
				<p style={{ marginTop: 2 }}> {t("Leave Task")}</p>
			</Button>
			{reasonDisabledNote(disabled)}
		</div>
	);
};

export default JobTaskActionButton;
