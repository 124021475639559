import * as React from 'react';
import Fab from '@material-ui/core/Fab/Fab';
import Icon from '@material-ui/core/Icon/Icon';
import { History } from 'history';
import { darkblue, lightyellow, lightblue, lightgreen } from '../../styles/Colors';
import { isNullOrUndefined } from 'util';
import { CircularProgress } from '@material-ui/core';
import { fire } from '../../index';
import { withTranslation } from 'react-i18next';

interface FloatingTaskStatusButtonProps {
	history: History;
	t: any;
}
interface FloatingTaskStatusButtonState {
	CurrentJobTask: string | null | undefined;
	CurrentJobTaskStatus: string | null | undefined;
}

 class FloatingTaskStatusButton extends React.Component<
	FloatingTaskStatusButtonProps,
	FloatingTaskStatusButtonState
> {
	private mounted: boolean = false;

	constructor(props) {
		super(props);
		this.state = {
			CurrentJobTask: null,
			CurrentJobTaskStatus: null,
		};
	}

	componentDidMount() {
		this.mounted = true;
		this.getCurrentJobTaskAndStatus();
	}

	componentWillUnmount() {
		this.mounted = false;
	}

	getCurrentJobTaskAndStatus = () => {
		fire.getResourceSettings().onSnapshot(doc => {
			const data = doc.data() as Store.UserSettings;
			if (this.mounted) {
				this.setState({
					CurrentJobTask: data.CurrentJobTask,
					CurrentJobTaskStatus: data.CurrentJobTaskStatus,
				});
			}
		});
	};

	handleButtonClick = (travellingHome: boolean) => {
		if (travellingHome) {
			const allow = window.confirm(this.props.t('Please confirm that you have arrived at home?'));
			if (allow) {
				const jobTaskQueueObj = {
					JobAction: 'TaskArriveHome',
					TravelHomeEndDate: Date.now(),
				};

				fire.postToJobQueue(this.state.CurrentJobTask as string, jobTaskQueueObj);
				fire.updateCurrentActiveJobTask(null, null);
			}
		} else {
			this.props.history.push(`/job-task-details/${this.state.CurrentJobTask}`);
		}
	};

	changeColor = (status: string) => {
		if (isNullOrUndefined(status)) {
			return darkblue;
		}
		if (status.includes('Started Travel')) {
			return lightblue;
		}
		if (status.includes('Started')) {
			return lightyellow;
		}
		if (status.includes('Travelling Home')) {
			return lightgreen;
		}
		return darkblue;
	};

	changeSize = (status: string) => {
		if (isNullOrUndefined(status)) {
			return 224;
		}
		if (status.includes('Started Travel')) {
			return 200;
		}
		if (status.includes('Travelling Home')) {
			return 224;
		}
		if (status.includes('Unsynced Task')) {
			return 200;
		}
		if (status.includes('Started')) {
			return 155;
		}

		return 224;
	};

	render() {
		if (isNullOrUndefined(this.state.CurrentJobTask) || isNullOrUndefined(this.state.CurrentJobTaskStatus)) {
			return null;
		}

		const travellingHome = this.state.CurrentJobTaskStatus.includes('Travelling Home');
		return (
			<div>
				<Fab
					variant="extended"
					id="status-fab"
					color="inherit"
					aria-label={this.props.t("Add")}
					onClick={() => this.handleButtonClick(travellingHome)}
					style={{
						backgroundColor: this.changeColor(this.state.CurrentJobTaskStatus),
						width: this.changeSize(this.state.CurrentJobTaskStatus),
					}}
				>
					{travellingHome ? (
						<div style={{ position: 'absolute', left: 20, top: 12 }}>
							<CircularProgress style={{ color: 'white' }} color="inherit" size={24} />
							&nbsp;&nbsp;
						</div>
					) : (
						<div style={{ position: 'absolute', left: 20, top: 12 }}>
							<Icon style={{ color: 'white', fontSize: '24px' }}>sync</Icon>&nbsp;
						</div>
					)}
					<div style={{ position: 'absolute', left: 60, top: 4 }}>
						<h4 style={{ color: 'white' }}>
							{this.props.t(this.state.CurrentJobTaskStatus).slice(0, this.props.t(this.state.CurrentJobTaskStatus).indexOf('-'))}
						</h4>
					</div>
					<div style={{ position: 'absolute', left: 60, top: 22 }}>
						<h4 style={{ color: 'white' }}>
							{this.props.t(this.state.CurrentJobTaskStatus).slice(
								this.props.t(this.state.CurrentJobTaskStatus).indexOf('-') + 1,
								this.props.t(this.state.CurrentJobTaskStatus).length,
							)}
						</h4>
					</div>
				</Fab>
			</div>
		);
	}
}

export default withTranslation()(FloatingTaskStatusButton)
