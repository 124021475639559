// React
import * as React from 'react';

// Material UI
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Icon,
  InputLabel,
  TextField
} from '@material-ui/core';
import { withTranslation } from 'react-i18next';

interface SubmitFormModalProps {
  handleSubmitFormDate: any;
  handleSubmitFormModal: any;
  submitForm: any;
  submitFormModalOpen: any;
  value: number;
  t: any;
}

class SubmitFormModal extends React.Component<SubmitFormModalProps> {
  constructor(props: SubmitFormModalProps) {
    super(props);
  }

  render() {
    return (
      <Dialog
        open={this.props.submitFormModalOpen}
        onClose={() => this.props.handleSubmitFormModal()}
        fullScreen={false}
        maxWidth="lg"
      >
        <DialogContent id="content" classes={{ root: 'dialog-content-root' }}>
          <div id="filters-row">
            <div style={{ width: '100%' }}>
              <InputLabel htmlFor="free-text-input">{this.props.t("Form Submission Date")}</InputLabel>

              <TextField
                autoFocus={true}
                margin="dense"
                id="create-form-date-input"
                type="date"
                fullWidth={true}
                value={new Date(this.props.value).toISOString().slice(0,10)}
                onChange={e => this.props.handleSubmitFormDate(Date.parse(e.target.value))}
              />
            </div>
          </div>
        </DialogContent>

        <DialogActions>
          <Button
            onClick={this.props.handleSubmitFormModal}
            color="primary"
            style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
          >
            <Icon style={{ fontSize: 20 }}>undo</Icon>&nbsp;
  				<p style={{ marginTop: 2 }}>{this.props.t("Back")}</p>
          </Button>

          <Button
            onClick={() => this.props.submitForm()}
            color="primary"
            style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
          >
            <Icon style={{ fontSize: 20 }}>save</Icon>&nbsp;
  				<p style={{ marginTop: 2 }}>{this.props.t("Submit Form")}</p>
          </Button>
        </DialogActions>
      </Dialog>
    )
  }
};

export default withTranslation()(SubmitFormModal)
