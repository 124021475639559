import * as React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import SideDrawer from '../../components/shared/SideDrawer';
import { History } from 'history';
import BackButton from '../../components/shared/BackButton';
import { fire, idb, localQueue } from '../../index';
import styled from 'styled-components';
import { match } from 'react-router-dom';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { unixToDateString } from '../../utils/Times';
import JobTaskDocuments from '../../components/job-task/JobTaskDocuments';
import Button from '@material-ui/core/Button';
import CreateExpenseDialog from '../../components/expenses/CreateExpenseDialog';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import * as loadImage from 'blueimp-load-image';
import imageCompression from 'browser-image-compression';
import { connect } from 'react-redux';
import { windowError } from '../../utils/WindowError';

import Icon from '@material-ui/core/Icon/Icon';
import '../../styles/job-task/expenses.css';
import { generateGuid } from '../../utils/Guids';
import { isNullOrUndefined } from 'util';
import { withTranslation } from 'react-i18next';
import { BusinessTypes } from '../../utils/shared';

interface ExpensesProps {
	history: History;
	match: match;
	UserSettings: Store.UserSettings;
	t: any;
}

interface ExpensesState {
	expense: Expense.Expense | null;
	expenseFBID: string;
	expenseSubmitted: boolean;
	expenseApproved: boolean;
	descriptionUpdate: string;
	supplierUpdate: string;
	unitsUpdate: string;
	unitPriceUpdate: string;
	expenseDialogOpen: boolean;
}

class ExpensesDetailsScreen extends React.Component<ExpensesProps, ExpensesState> {
	constructor(props) {
		super(props);
		this.state = {
			expense: null,
			expenseFBID: '',
			expenseSubmitted: false,
			expenseApproved: false,
			descriptionUpdate: '',
			supplierUpdate: '',
			unitsUpdate: '',
			unitPriceUpdate: '',
			expenseDialogOpen: false,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'ExpensesDetailsScreen',
		);
	}

	componentDidMount() {
		const { id } = this.props.match.params as any;
		this.setState({ expenseFBID: id });
		this.getExpensesDetails(id);
	}

	updateDescriptionChange = e => {
		this.setState({ descriptionUpdate: e.target.value });
	};

	updateSupplierChange = e => {
		this.setState({ supplierUpdate: e.target.value });
	};

	updateQuantityChange = e => {
		this.setState({ unitsUpdate: parseInt(e.target.value, 10).toString() });
	};

	updatePriceChange = e => {
		const unitPriceUpdate: string = parseFloat(e.target.value)
			.toFixed(2)
			.toString();
		this.setState({ unitPriceUpdate });
	};

	handleExpenseDialogVisibility = () => {
		const ExpenseDetails = this.state.expense;
		this.setState({
			expenseDialogOpen: !this.state.expenseDialogOpen,
		});

		if (ExpenseDetails !== null) {
			this.setState({
				descriptionUpdate: ExpenseDetails.Description,
				supplierUpdate: ExpenseDetails.Supplier,
				unitsUpdate: ExpenseDetails.Units,
				unitPriceUpdate: ExpenseDetails.UnitPrice,
			});
		}
	};

	handleUpdateExpense = () => {
		if (
			this.state.descriptionUpdate &&
			this.state.supplierUpdate &&
			this.state.unitsUpdate &&
			this.state.unitPriceUpdate &&
			this.state.expenseFBID !== null
		) {
			const localObj = {
				Description: this.state.descriptionUpdate,
				Supplier: this.state.supplierUpdate,
				Units: this.state.unitsUpdate.toString(),
				UnitPrice: this.state.unitPriceUpdate.toString(),
			};

			const jobTaskQueueObj = {
				Description: this.state.descriptionUpdate,
				Supplier: this.state.supplierUpdate,
				Units: this.state.unitsUpdate.toString(),
				UnitPrice: this.state.unitPriceUpdate.toString(),
				JobAction: 'UpdateExpense',
			};

			fire.updateExpense(this.state.expenseFBID, localObj);
			fire.postToJobQueue(this.state.expenseFBID, jobTaskQueueObj);

			if (this.state.expense !== null) {
				this.setState({
					expense: {
						...this.state.expense,
						Description: this.state.descriptionUpdate,
						Supplier: this.state.supplierUpdate,
						Units: this.state.unitsUpdate,
						UnitPrice: this.state.unitPriceUpdate,
						TotalCost: Number(this.state.unitPriceUpdate) * Number(this.state.unitsUpdate),
					},
				});
			}

			this.setState({
				expenseDialogOpen: !this.state.expenseDialogOpen,
			});
		} else {
			alert(
				this.props.t('All fields must be entered before you can update the expense. Quantity and Price can also must be greater than 0.'),
			);
		}
	};

	backButton = () => {
		this.props.history.goBack();
		//TODO: remove documents for if back button is hit
	};

	getExpensesDetails = (expenseFBID: string) => {
		fire.baseQuery
			.collection('Expenses')
			.doc(expenseFBID)
			.onSnapshot(query => {
				const Details = query.data() as Expense.Expense;
				Details.TotalCost = Number(Details.UnitPrice) * Number(Details.Units);
				this.setState({
					descriptionUpdate: Details.Description,
					supplierUpdate: Details.Supplier,
					unitsUpdate: Details.Units,
					unitPriceUpdate: Details.UnitPrice,
					expense: Details,
					expenseSubmitted: Details.Status !== 'Pending',
					expenseApproved: Details.Status === 'Approved',
				});
			});
	};

	openDocumentDialog = () => {
		const imageUploadButton = document.getElementById('document-upload');
		if (imageUploadButton !== null) {
			imageUploadButton.click();
		}
	};

	saveDocument = async (selectorFiles: FileList | null) => {
		const options = {
			maxSizeMB: 1,
			maxWidthOrHeight: 800,
			useWebWorker: true
		}

		if (selectorFiles !== null && !isNullOrUndefined(this.state.expenseFBID)) {
			const uploadedDocument: File = selectorFiles[0];

			const Guid = generateGuid();
			const newDocObj = { JobAction: 'AddDocumentExistingExpense', Guid };

			if (uploadedDocument['type'].split('/')[0] === 'image') {
				const extention = uploadedDocument.name.split('.').pop();
				//const CompressionRatio = GetFileCompressionRatio(document);

				const compressedFile: Blob = await imageCompression(uploadedDocument, options);

				var b: any = compressedFile;
				b.lastModifiedDate = new Date();
				b.name = `Contractor-Photo-${new Date().getTime()}.` + extention;

				idb
					.saveDocumentToLocal(
						compressedFile as File,
						this.state.expenseFBID,
						'true',
						"",
						Guid,
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
						BusinessTypes.Expense
					)
					.then(object =>
						localQueue.saveToLocalJobQueue(
							this.state.expenseFBID,
							newDocObj,
							undefined,
							undefined,
							Guid,
							object.File,
						),
					);

				// loadImage(
				// 	document,
				// 	(canvas: HTMLCanvasElement) => {
				// 		canvas.toBlob(async blob => {
				// 			var b: any = blob;
				// 			b.lastModifiedDate = new Date();
				// 			b.name = `Contractor-Photo-${new Date().getTime()}.` + extention;

				// 			idb
				// 				.saveDocumentToLocal(blob as File, this.state.expenseFBID, 'true', Guid)
				// 				.then(object =>
				// 					localQueue.saveToLocalJobQueue(
				// 						this.state.expenseFBID,
				// 						newDocObj,
				// 						undefined,
				// 						undefined,
				// 						Guid,
				// 						object.File,
				// 					),
				// 				);
				// 		});
				// 	},
				// 	{ maxWidth: 400, maxHeight: 400, orientation: true, downsamplingRatio: CompressionRatio },
				// );
			} else {
				idb
					.saveDocumentToLocal(uploadedDocument,
						this.state.expenseFBID,
						'true',
						"",
						Guid,
						undefined,
						undefined,
						undefined,
						undefined,
						undefined,
						BusinessTypes.Expense
					)
					.then(object =>
						localQueue.saveToLocalJobQueue(this.state.expenseFBID, newDocObj, undefined, undefined, Guid, object.File),
					);
			}
		}
	};

	SubmitExpense = () => {
		if (this.state.expense !== null && this.state.expenseFBID !== null) {
			const confirmed = window.confirm(
				this.props.t('Are you sure you wish to submit this expense? You cannot undo this once submitted.'),
			);

			if (confirmed) {
				this.setState({
					expense: {
						...this.state.expense,
						Status: 'Submitted',
					},
					expenseSubmitted: true,
				});

				const localObj = {
					Status: 'Submitted',
				};

				const expenseQueueObj = {
					Status: 'Submitted',
					JobAction: 'SubmitExpense',
				};

				fire.SubmitExpense(this.state.expenseFBID, localObj).catch(err => console.error(err));
				fire.postToJobQueue(this.state.expenseFBID, expenseQueueObj).catch(err => console.error(err));
			}
		}
	};

	render() {
		if (this.state.expense !== null) {
			const expense = this.state.expense;

			return (
				<div style={{ margin: '5px 0 15px 0' }}>
					<SideDrawer
						history={this.props.history}
						title={this.props.t("Expense Details")}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main">
							<div id="details-outer">
								<div className="top-card card-shadow" style={{ border: '1px solid grey' }}>
									<FieldHolder content={expense.Description} label={this.props.t("Description")} fullLine={true} />
									<FieldHolder content={expense.JobTaskNumber} label={this.props.t("Job Task")} />
									<FieldHolder content={this.props.t(expense.Status)} label={this.props.t("Status")} />
									<FieldHolder content={unixToDateString(expense.DateCreated)} label={this.props.t("Date Created")} />
									<FieldHolder content={expense.UnitPrice} label={this.props.t("Unit Price")} />
									<FieldHolder content={expense.Units} label={this.props.t("Units")} />
									{expense.Supplier !== null ? <FieldHolder content={expense.Supplier} label={this.props.t("Supplier")} /> : null}
									<FieldHolder content={expense.TotalCost.toFixed(2)} label={this.props.t("Total Cost")} />
									{expense.Status === 'UnderQuery' ? (
										<FieldHolder content={expense.Note} label={this.props.t("Under Query Note")} fullLine={true} />
									) : null}
								</div>
								<JobTaskDocuments documentId={this.state.expenseFBID} From="Expenses" isComplete={true} />
								<Button
									id="document-upload-button"
									variant="contained"
									color="inherit"
									onClick={() => this.openDocumentDialog()}
								>
									<Icon style={{ fontSize: 20 }}>attach_file</Icon>&nbsp;
									<p style={{ marginTop: 2 }}>{this.props.t("Add Document")}</p>
								</Button>
								<input
									id="document-upload"
									type="file"
									onChange={e => this.saveDocument(e.target.files)}
									style={{ display: 'none' }}
								/>
								{!this.state.expenseSubmitted ? (
									<Button
										id="submit-expense-button"
										variant="contained"
										color="inherit"
										onClick={() => this.SubmitExpense()}
										style={{ backgroundColor: '#5cb85c' }}
									>
										<Icon style={{ fontSize: 20 }}>save</Icon>&nbsp;
										<p style={{ marginTop: 2 }}>{this.props.t("Submit Expense")}</p>
									</Button>
								) : null}
								{!this.state.expenseApproved ? (
									<div style={{ width: '100%' }}>
										<CreateExpenseDialog
											open={this.state.expenseDialogOpen}
											handleExpenseDialogVisibility={this.handleExpenseDialogVisibility}
											newDescriptionChange={this.updateDescriptionChange}
											newSupplierChange={this.updateSupplierChange}
											newQuantityChange={this.updateQuantityChange}
											newPriceChange={this.updatePriceChange}
											handleCreateExpense={null}
											handleUpdateExpense={this.handleUpdateExpense}
											openDocumentDialog={this.openDocumentDialog}
											saveDocument={this.saveDocument}
											newExpenseDocuments={[]}
											removeDocumentFromList={null}
											createExpense={false}
											descriptionDefaultValue={this.state.descriptionUpdate}
											supplierDefaultValue={this.state.supplierUpdate}
											quantityDefaultValue={this.state.unitsUpdate}
											priceDefaultValue={this.state.unitPriceUpdate}
										/>
										<Button
											onClick={() => this.handleExpenseDialogVisibility()}
											color="primary"
											variant="contained"
											id="edit-expense-button"
										>
											<Icon style={{ fontSize: 20 }}>create</Icon>&nbsp;
											<p style={{ marginTop: 2 }}>{this.props.t("Edit Expense")}</p>
										</Button>
									</div>
								) : null}
								<div />
							</div>
						</div>
					</Grid>
				</div>
			);
		}
		return <LoadingSpinner color="primary" text={this.props.t("Loading expense details...")} />;
	}
}

const FieldHolder = props => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
		<p className="field-content">{props.content}</p>
		<p className="field-label">{props.label}</p>
	</div>
);

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(
	mapStateToProps,
	null,
)(ExpensesDetailsScreen));
