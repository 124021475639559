import { fire, localQueue, idb } from '../..';
import * as loadImage from 'blueimp-load-image';
import imageCompression from 'browser-image-compression';
import { stringtoUnixUTC } from '../Times';
import { isNullOrUndefined } from 'util';
import { generateGuid, generateFirebaseId } from '../Guids';
import { GetFileCompressionRatio } from '../../utils/FileCompressionRatio';
import { isQuestionComplete } from './FormHelpers';
import { BusinessTypes } from '../shared';

export const isFileImage = (file: File | Blob): boolean => file && file['type'].split('/')[0] === 'image';

export const downloadFile = (downloadUrl: string, fileName: string) => {
	const isFirebase = downloadUrl.includes('firebasestorage');

	if (isFirebase) {
		fetch(downloadUrl)
			.then(resp => {
				return resp.blob();
			})
			.then(blobObj => {
				const blob = new Blob([blobObj]);
				const link = document.createElement('a');
				link.href = window.URL.createObjectURL(blob);
				link.download = fileName;
				link.click();
			});
	} else {
		let link = document.createElement('a');
		link.download = fileName;
		link.href = downloadUrl;
		link.click();
		link.remove();
	}
};

export const removePhoto = async (
	Guid: string,
	questionData: Forms.QuestionAnswer,
	setStateCallback: (
		uploadingFile: boolean,
		deletingFile: boolean,
		fileName: string | null,
		fileUrl: string | null,
		guid?: string,
	) => void,
) => {
	setStateCallback(false, true, '', null);
	const Data = { ...questionData };
	const photoData = await idb.getPhotoByGuid(Guid);
	let PhotoFBID = "";
	if (photoData && photoData.photoId) {
		PhotoFBID = photoData.photoId
	} else if (photoData && photoData.source) {
		PhotoFBID = photoData.source
	}
	await idb.removeLocalQueueByPhotoId(PhotoFBID as string);
	if (Guid) {
		await idb.removePhotobyGuid(Guid);
	}
	//delete question ref from object and assign it to new variable
	setStateCallback(false, false, '', null);
	const dataObj = {
		QuestionType: Data.QuestionType,
		JobAction: 'RemoveFormAnswer',
		QuestionFBID: Data.Id,
		PhotoFBID: PhotoFBID,
	};

	await fire.postToJobQueue(Data.FormFBID, dataObj);
	const ref = questionData.questionRef.path;
	await fire.DeleteFromQuestionAnswer(PhotoFBID as string, ref);
};

export const updateFirestore = (
	DocumentAnswer: { FileName: string; DownloadUrl: string },
	questionData: Forms.QuestionAnswer,
	docToUpdateRef: string,
	questionState?: any,
) => {
	//update question in firebase
	fire.db
		.doc(docToUpdateRef)
		.update({ DocumentAnswer })
		.catch(err => console.error(`Error updating answer - ${err}`));

	//add answer to job queue
	if (isNullOrUndefined(questionState)) {
		fire.answerQuestion({
			...questionData,
			DocumentAnswer,
		});
	} else {
		fire.answerQuestion(
			{
				...questionData,
				DocumentAnswer,
			},
			{
				PassFailDateTimeStamp: stringtoUnixUTC(questionState.dateTimeAnswer || '').toString(),
				Answer: questionState.booleanAnswer ? 'True' : 'False',
				PassFailTextBox: questionState.textAnswer,
			},
		);
	}
};

export const uploadToFirebaseStorage = (
	document: File | Blob,
	documentName: string,
	questionData: any,
	docToUpdateRef: string,
	questionState?: any,
) => {
	return fire
		.uploadDocumentForFormAnswer(document, documentName)
		.then(async firebaseRef => {
			if (firebaseRef !== null) {
				const firebaseUrl: string | null = await firebaseRef.ref.getDownloadURL();
				if (firebaseUrl === null) {
					return alert('Error has occured.');
				}
				if (document === null) return alert('Error has occured');
				const DocumentAnswer = { FileName: documentName, DownloadUrl: firebaseUrl };
				updateFirestore(DocumentAnswer, questionData, docToUpdateRef, questionState);
				return firebaseUrl;
			}
		})
		.catch(err => console.error(err));
};

export const savePhoto = async (
	blob: Blob | null,
	questionData: Forms.QuestionAnswer,
	docName: string,
	setStateCallback?: (
		uploadingFile: boolean,
		deletingFile: boolean,
		fileName: string | null,
		fileUrl: string | null,
		guid?: string,
		docId?: string
	) => void,
	questionState?: any,
	isComplete?: string,
	formId?: string,
	businessType?: string,
	getPhotos?: (docId) => void
) => {
	if (blob === null) return;

	//creates an array buffer from the blob
	const arrayBuffer = await new Response(blob).arrayBuffer();

	if (questionData.QuestionType === 'Signature') {
		await idb.deletePhotosByDocId(questionData.Id);
	}

	let guid = '';
	const PhotoFBID = generateFirebaseId();

	if (questionData.QuestionType === 'Signature') {
		guid = await idb.savePhotoToLocal(
			arrayBuffer,
			questionData.Id,
			isComplete as string,
			PhotoFBID,
			docName,
			undefined,
			formId,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			businessType
		);
	} else {
		//save photo to local index db
		guid = await idb.savePhotoToLocal(
			arrayBuffer,
			questionData.Id,
			isComplete as string,
			PhotoFBID,
			docName,
			undefined,
			formId,
			PhotoFBID,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			undefined,
			businessType
		);
		if (getPhotos) {
			getPhotos(questionData.Id);
		}
	}

	//Add job action
	questionData['JobAction'] = 'FormAnswer';

	//delete question ref from object and assign it to new variable
	const ref = questionData.questionRef.path;
	const data = { ...questionData };
	delete data.questionRef;

	if (questionData.QuestionType !== 'Signature') {
		fire.CreatePhotoRecordForQuestionAnswer(ref, { Guid: guid }, PhotoFBID);
	}

	//save to local queue and callback once save has completed
	localQueue.saveToLocalJobQueue(data.Id, data, questionState, ref, guid, arrayBuffer, docName, PhotoFBID).then(() => {
		if (setStateCallback === undefined) return;
		setStateCallback(false, false, docName, null, guid, questionData.Id);
	});
};

export const fileChangeListener = async (
	event: React.ChangeEvent<HTMLInputElement>,
	setStateCallback: (
		uploadingFile: boolean,
		deletingFile: boolean,
		fileName: string | null,
		fileUrl: string | null,
		guid?: string,
	) => void,
	isPhoto: boolean,
	questionData: Forms.QuestionAnswer,
	handleExpand: (value: boolean) => void,
	questionState?: any,
	isComplete?: string,
	formId?: string,
	formBusinessType?: string,
	getPhotos?: (docId) => void
) => {
	let document: File | null = event.target.files !== null ? event.target.files[0] : null;

	if (document === null) return;

	let docName = isPhoto ? `Contractor-Photo-${new Date().getTime()}.jpeg` : document.name;

	questionData['JobAction'] = 'FormAnswer';

	setStateCallback(true, false, docName, null);

	const options = {
		maxSizeMB: 1,
		maxWidthOrHeight: 800,
		useWebWorker: true
	}

	let businessType;

	if (formBusinessType === "Asset") {
		businessType = BusinessTypes.Asset
	} else if (formBusinessType === "Site") {
		businessType = BusinessTypes.Site
	} else if (formBusinessType === "Location") {
		businessType = BusinessTypes.Locations
	} else if (formBusinessType === "Sublocation") {
		businessType = BusinessTypes.SubLocations
	} else {
		businessType = BusinessTypes.JobTask
	}

	if (isPhoto) {
		const containsImage = document.type.split('/')[0] === 'image';
		if (!containsImage) {
			return alert('You must upload a photo, no other file types are permitted.');
		}

		const extention = document.name.split('.').pop();
		const photo: File = document;
		const CompressionRatio = GetFileCompressionRatio(photo);

		loadImage(
			document,
			(canvas: HTMLCanvasElement) => {
				canvas.toBlob(async blob => {
					var b: any = blob;
					b.lastModifiedDate = new Date();
					b.name = `Photo-${new Date().getTime()}.` + extention;
					savePhoto(
						blob,
						questionData, b.name,
						setStateCallback,
						questionState,
						isComplete,
						formId,
						businessType,
						getPhotos
					);
				});
			},
			{
				maxWidth: 800, maxHeight: 800, orientation: true,
				//downsamplingRatio: CompressionRatio, 
				canvas: true
			},
		);		
		handleExpand(true);
	} else {
		//delete any documents for question already existing
		await idb.deleteDocumentsByDocId(questionData.Id);

		if (document.type.split('/')[0] === 'image') {
			const extention = document.name.split('.').pop();
			const photo: File = document;
			//const CompressionRatio = GetFileCompressionRatio(photo);

			const compressedFile: Blob = await imageCompression(photo, options);

			var b: any = compressedFile;
			b.lastModifiedDate = new Date();
			b.name = docName;
			// b.name = `Photo-${new Date().getTime()}.` + extention;

			const object = await idb.saveDocumentToLocal(
				compressedFile as File,
				questionData.Id,
				isComplete as string,
				"",
				undefined,
				formId,
				undefined,
				undefined,
				undefined,
				undefined,
				businessType
			);

			//delete question ref from object and assign it to new variable
			const ref = questionData.questionRef.path;
			const data = { ...questionData };
			delete data.questionRef;

			//save to local queue and callback once save has completed
			localQueue
				.saveToLocalJobQueue(data.Id, data, undefined, ref, object.Guid, object.File, b.name)
				.then(() => setStateCallback(false, false, b.name, object.Guid));

			// loadImage(
			// 	document,
			// 	(canvas: HTMLCanvasElement) => {
			// 		canvas.toBlob(async blob => {
			// 			var b: any = blob;
			// 			b.lastModifiedDate = new Date();
			// 			b.name = docName;
			// 			// b.name = `Photo-${new Date().getTime()}.` + extention;

			// 			//saves document to local documents table
			// 			const object = await idb.saveDocumentToLocal(
			// 				blob as File,
			// 				questionData.Id,
			// 				isComplete as string,
			// 				undefined,
			// 				formId,
			// 			);

			// 			//delete question ref from object and assign it to new variable
			// 			const ref = questionData.questionRef.path;
			// 			const data = { ...questionData };
			// 			delete data.questionRef;

			// 			//save to local queue and callback once save has completed
			// 			localQueue
			// 				.saveToLocalJobQueue(data.Id, data, undefined, ref, object.Guid, object.File, b.name)
			// 				.then(() => setStateCallback(false, false, b.name, object.Guid));
			// 		});
			// 	},
			// 	{ maxWidth: 400, maxHeight: 400, orientation: true, downsamplingRatio: CompressionRatio },
			// );
		} else {
			//saves document to local documents table
			const object = await idb.saveDocumentToLocal(
				document,
				questionData.Id,
				isComplete as string
				, "",
				undefined,
				formId,
				undefined,
				undefined,
				undefined,
				undefined,
				businessType
			);

			//delete question ref from object and assign it to new variable
			const ref = questionData.questionRef.path;
			const data = { ...questionData };
			delete data.questionRef;

			//save to local queue and callback once save has completed
			localQueue
				.saveToLocalJobQueue(data.Id, data, undefined, ref, object.Guid, object.File, docName)
				.then(() => setStateCallback(false, false, docName, object.Guid));
		}
	}

	//isQuestionComplete(questionData);
};

export const getFromIndexDb = async (
	covertToBlobAndSave: (file: ArrayBuffer, filename?: string) => void,
	isPhoto: boolean,
	documentId: string,
	guid?: string,
) => {
	if (isPhoto) {
		let photo: indexDb.Documents | undefined;
		if (isNullOrUndefined(guid)) {
			photo = await idb.getPhotoByDocId(documentId);
		} else {
			photo = await idb.getPhotoByGuid(guid);
		}

		if (isNullOrUndefined(photo)) return;
		covertToBlobAndSave(photo.document, photo.documentName);
	} else {
		let doc: indexDb.Documents | undefined;
		if (isNullOrUndefined(guid)) {
			doc = await idb.getDocumentByDocId(documentId);
		} else {
			doc = await idb.getDocumentByGuid(guid);
		}
		if (isNullOrUndefined(doc)) return;
		covertToBlobAndSave(doc.document, doc.documentName);
	}
};

export const downloadFromServer = async (
	documentId: string,
	covertToBlobAndSave: (file: ArrayBuffer, filename?: string) => void,
	isPhoto: boolean,
	downloadUrl?: string | null,
	fileName?: string | null,
	isComplete?: string,
	formId?: string,
) => {
	if (isNullOrUndefined(downloadUrl)) return;
	if (isNullOrUndefined(fileName)) return;

	await idb.deletePhotosByDocId(documentId);

	fetch(downloadUrl)
		.then(resp => resp.arrayBuffer())
		.then(async arrayBuffer => {
			if (isPhoto) {
				//saves photo to local db
				const exists = await idb.photoExistsByDocId(documentId);
				const photoNewFBID = generateFirebaseId();

				if (!exists) {
					const guid = await idb.savePhotoToLocal(
						arrayBuffer,
						documentId,
						isComplete as string,
						photoNewFBID,
						fileName as string,
						undefined,
						formId,
					);
					//gets photo from local db by guid
					const photo = await idb.getPhotoByGuid(guid);
					if (photo === undefined) return;

					//coonverts file to blob annd sets to state
					covertToBlobAndSave(photo.document, photo.documentName);
				}
			} else {
				const file = new File([arrayBuffer], fileName as string);
				const guid = await idb.saveDocumentToLocal(file, documentId, isComplete as string, "", undefined, formId);
				const doc = await idb.getDocumentByGuid(guid.Guid);
				if (doc === undefined) return;

				covertToBlobAndSave(doc.document, doc.documentName);
			}
		});
};

export const getPhoto = async (
	documentId: string,
	covertToBlobAndSave: (file: ArrayBuffer, filename?: string) => void,
	downloadUrl?: string | null,
	fileName?: string | null,
	isComplete?: string,
	formId?: string,
) => {
	//get document from index db
	const photo = await idb.getPhotoByDocId(documentId);

	if (navigator.onLine) {
		//if no photo present, try and download from server
		if (isNullOrUndefined(photo))
			return downloadFromServer(documentId, covertToBlobAndSave, true, downloadUrl, fileName, isComplete, formId);

		//if filenames are the same, return file from local
		if (photo.documentName === fileName) return covertToBlobAndSave(photo.document, photo.documentName);

		//if filenames do not match, get new document from server
		return downloadFromServer(documentId, covertToBlobAndSave, true, downloadUrl, fileName, isComplete, formId);
	} else {
		if (!isNullOrUndefined(photo)) {
			return covertToBlobAndSave(photo.document, photo.documentName);
		} else {
			return covertToBlobAndSave(new ArrayBuffer(0), 'unavailable');
		}
	}
};

export const getDocument = async (
	documentId: string,
	covertToBlobAndSave: (file: ArrayBuffer, filename?: string) => void,
	downloadUrl?: string | null,
	fileName?: string | null,
	isComplete?: string,
	formId?: string,
) => {
	//get document from index db
	const document = await idb.getDocumentByDocId(documentId);

	if (navigator.onLine) {
		//if no document present, try and download from server
		if (isNullOrUndefined(document))
			return downloadFromServer(documentId, covertToBlobAndSave, false, downloadUrl, fileName, isComplete, formId);

		//if filenames are the same, return file from local
		if (document.documentName === fileName) return covertToBlobAndSave(document.document, document.documentName);

		//if filenames do not match, get new document from server
		return downloadFromServer(documentId, covertToBlobAndSave, false, downloadUrl, fileName, isComplete, formId);
	} else {
		if (!isNullOrUndefined(document)) {
			return covertToBlobAndSave(document.document, document.documentName);
		}

		if (isComplete) {
			return covertToBlobAndSave(new ArrayBuffer(0), 'unavailable');
		}
	}
};
