// React
import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';

// Store
import store from '../../store';

// Actions
import { assetsLoaded, currentScrollPosition } from '../../actions/AssetActions';

// Material UI
import Icon from '@material-ui/core/Icon/Icon';
import IconButton from '@material-ui/core/IconButton/IconButton';
import { withTranslation } from 'react-i18next';

interface AssetCardProps {
	numAssets: number;
	assets: Asset[];
	JobTaskID: string;
	DocumentID: string;
	DocumentType: string;
	QuestionAnswerID: string | null;
	history: History;
	handleAddAsset: (assetID, assetName, documentId, jobTaskId, documentType, questionAnswer, history, translate, formTemplateFBID, jobID) => void;
	assetsLoaded: (totalAssets) => void;
	currentScrollPosition: (scrollPosition) => void;
	t: any;
	formTemplateFBID: string;
	taskID: string;
}

class AssetCard extends React.Component<AssetCardProps> {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	updateAssetsInfo = () => {
		this.props.assetsLoaded(this.props.numAssets);
		this.props.currentScrollPosition(window.scrollY);
	}

	render() {
		return this.props.assets.map((assets: Asset, i: number) => {
			const destinationUrl = `/asset-nmdetails/${assets.AssetFBID}/${this.props.JobTaskID}/${this.props.DocumentID}`;
			return (
				<Link key={i} onClick={this.updateAssetsInfo} to={destinationUrl} replace={this.props.DocumentID !== 'list' ? true : false}>
					<div key={i}>
						<div
							style={{
								border: `2.5px solid var(--light-grey)`,
								marginBottom: '10px',
							}}
							className="card-shadow asset-card"
						>
							<div className="top-row-choose-asset">
								<div>
									<p style={{ overflowWrap: 'break-word' }} className="top-content-asset">
										{assets.AssetName}
									</p>
									<p className="label-asset">{this.props.t("Asset Name")}</p>
								</div>
								<div>
									<p style={{ overflowWrap: 'break-word' }} className="top-content-asset">
										{assets.AssetClass}
									</p>
									<p className="label-asset">{this.props.t("Asset Class")}</p>
								</div>
								<div>
									{this.props.JobTaskID !== 'list' ? (
										<IconButton
											style={{
												display: 'inline',
												float: 'right',
												marginTop: '-10px',
												marginRight: '1px',
											}}
											onClick={() =>
												this.props.handleAddAsset(
													assets.AssetFBID,
													assets.AssetName,
													this.props.DocumentID,
													this.props.JobTaskID,
													this.props.DocumentType,
													this.props.QuestionAnswerID,
													this.props.history,
													this.props.t, 
													this.props.formTemplateFBID, 
													this.props.taskID
												)
											}
										>
											<Icon style={{ color: 'var(--dark-green)', fontSize: '32px' }}>add_box</Icon>
										</IconButton>
									) : null}
								</div>
							</div>
							<div className="bottom-row-asset">
							<div className="header-container-asset">
								<p className="content-asset">{assets.AssetCode}</p>
								<p className="label-asset">{this.props.t("Asset Code")}</p>
							</div>
							<div className="header-container-asset">
								<p className="content-asset">{assets.SiteName}</p>
								<p className="label-asset">{this.props.t("Site")}</p>
							</div>
						</div>
						<div className="bottom-row-asset">
							<div className="header-container-asset">
								<p className="content-asset">{assets.LocationName}</p>
								<p className="label-asset">{this.props.t("Location")}</p>
							</div>
							<div className="header-container-asset">
								<p className="content-asset">{assets.SubLocationName}</p>
								<p className="label-asset">{this.props.t("Sublocation")}</p>
							</div>
							</div>
					</div>
							
				</div>
				</Link>
			);
		});
	}
}

const mapDispatchToProps = dispatch => ({
	assetsLoaded: (totalAssetsLoaded) => dispatch(assetsLoaded(totalAssetsLoaded)),
	currentScrollPosition: (yPos) => dispatch(currentScrollPosition(yPos)),
});

export default withTranslation()(connect(null, mapDispatchToProps)(AssetCard));
