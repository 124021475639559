// react
import * as React from 'react';

// Babel
import { JSXAttribute } from '@babel/types';

interface OuterCardProps {
	id?: string;
	padding?: number | string | null;
	color?: string | null;
	styles?: JSXAttribute | string | null;
	children: JSX.Element[] | JSX.Element;
	border?: string;
	marginBottom?: string;
	height?: string;
}

const CardBorder = (props: OuterCardProps) => (
	<div
		id={props.id}
		className={`card-shadow-alt padding ${props.styles}`}
		style={{
			border: props.border || '0px solid' + props.color || ' transparent',
			padding: props.padding || '',
			marginBottom: props.marginBottom || '',
			height: props.height || '',
		}}
	>
		{props.children}
	</div>
);

export default CardBorder;
