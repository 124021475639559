import * as React from 'react';
import Grid from '@material-ui/core/Grid/Grid';
import SideDrawer from '../../components/shared/SideDrawer';
import { History } from 'history';
import BackButton from '../../components/shared/BackButton';
import { fire } from '../../index';
import { unixToDateString } from '../../utils/Times';
import styled from 'styled-components';
import cardColour from '../../utils/Colors';
import { Link } from 'react-router-dom';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { windowError } from '../../utils/WindowError';
import { connect } from 'react-redux';
import { useTranslation, withTranslation } from 'react-i18next';

interface Timesheet {
	ID: string;
	AssignedResource: string;
	DateCreated: number;
	EndPeriod: number;
	StartPeriod: number;
	Status: string;
}

interface TimesheetsProps {
	history: History;
	UserSettings: Store.UserSettings;
	t: any;
}

interface TimesheetsState {
	timesheetArray: Timesheet[];
	loading: boolean;
}

class TimesheetListScreen extends React.Component<TimesheetsProps, TimesheetsState> {
	constructor(props) {
		super(props);
		this.state = {
			timesheetArray: [],
			loading: true,
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'TimesheetListScreen',
		);
	}

	componentDidMount() {
		this.getTimesheets();
	}

	backButton = () => {
		this.props.history.replace('/');
	};

	getTimesheets = () => {
		this.setState({ loading: true });

		const timeSheetsCollection = fire.baseQuery
			.collection('Timesheets')
			.where('AssignedResource', '==', fire.resourceFBID)
			.orderBy('EndPeriod', 'desc');

		timeSheetsCollection.onSnapshot(query => {
			if (query.empty) {
				this.setState({ timesheetArray: [], loading: false });
				return;
			}
			const timesheetArray = query.docs.map(timesheetRow => {
				const row = timesheetRow.data() as Timesheet;
				row.ID = timesheetRow.id;
				return row;
			});
			this.setState({ timesheetArray, loading: false });
		});
	};

	render() {
		if (this.state.loading) {
			return <LoadingSpinner text={this.props.t("Loading timesheets...")} />;
		}
		// There are no timesheets to show
		if (this.state.timesheetArray.length < 1) {
			return (
				<div>
					<SideDrawer
						history={this.props.history}
						title={this.props.t("Timesheets")}
						rightMenuButton={<BackButton callbackMethod={this.backButton} />}
					/>
					<Grid container={true} direction="column" justify="center" alignItems="center">
						<div className="main">
							<div className="no-jobtasks-card">
								<h1 className="hot-pink">{this.props.t("There are no timesheets.")}</h1>
							</div>
						</div>
					</Grid>
				</div>
			);
		}
		return (
			<div>
				<SideDrawer
					history={this.props.history}
					title={this.props.t("Timesheets")}
					rightMenuButton={<BackButton callbackMethod={this.backButton} />}
				/>
				<Grid container={true} direction="column" justify="center" alignItems="center">
					<div className="main-timesheet">
						<div style={{ height: '25px' }} />
						<TimesheetList timesheets={this.state.timesheetArray} />
					</div>
				</Grid>
			</div>
		);
	}
}
const isWithinTimePeriod = (startPeriod: number, endPeriod: number) => {
	const currentDate = new Date().getTime();
	const isWithinRange: boolean = currentDate >= startPeriod && currentDate <= endPeriod ? true : false;
	return isWithinRange;
};

const TimesheetList = props => {
	const [t, i18n] = useTranslation();
	return props.timesheets.map((timesheet: Timesheet, i: number) => (
		<Link to={`/timesheet-details/${timesheet.ID}`} key={i}>
			<TimesheetCard
				style={{
					border: `2.5px solid ${cardColour(timesheet.Status)}`,
					marginBottom: '10px',
				}}
				className="card-shadow"
			>
				<TopRow>
					<HeaderContainer>
						<Content>{t(timesheet.Status)}</Content>
						<Label>{t("Status")}</Label>
					</HeaderContainer>
					<HeaderContainer>
						<Content>{unixToDateString(timesheet.StartPeriod, true)}</Content>
						<Label>{t("Start Period")}</Label>
					</HeaderContainer>
					<HeaderContainer>
						<Content>{unixToDateString(timesheet.EndPeriod, true)}</Content>
						<Label>{t("End Period")}</Label>
					</HeaderContainer>
					<HeaderContainer>
						{isWithinTimePeriod(timesheet.StartPeriod, timesheet.EndPeriod) ? <Chip>{t("Current")}</Chip> : null}
					</HeaderContainer>
				</TopRow>
			</TimesheetCard>
		</Link>
	));
};

const Chip = styled.p`
	background-color: var(--light-green);
	padding: 1.5px 2px;
	border-radius: 7.5px;
	font-size: 14px;
	color: white;
	text-align: center;
`;

const TimesheetCard = styled.div`
	display: flex;
	flex-direction: column;
`;

const TopRow = styled.div`
	display: grid;
	grid-template-columns: 1fr 1fr 1fr 70px;
	grid-column-gap: 7.5px;
	padding: 7.5px;
`;

const HeaderContainer = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: space-between;
`;

const Label = styled.p`
	font-size: 12px;
	color: var(--dark-blue);
`;

const Content = styled.p`
	font-size: 14px;
`;

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(
	mapStateToProps,
	null,
)(TimesheetListScreen));
