import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { History } from 'history';
import OuterCard from "./OuterCard";
import CardHeader from "./CardHeader";
import AssetJobCard from '../asset/AssetJobCard';

//card icon
import JOB from '../../assets/images/icons/JOB.svg';

const JobListCard: React.FC<IProps> = ({
    assetJobs,
    history,
    detailsButton,
    activeAsset,
    handleRaiseNewJobfromAsset,
}) => {
    const { t } = useTranslation();
    const [expand, setExpand] = useState(false);
    const [displayDialog, setDisplayDialog] = useState(false);

    const handleExpand = (value: boolean) => {
        setExpand(value)
    }

    return (
        <div>
            <OuterCard padding="0px" styles="forms-card border-radius">
                <CardHeader
                    title={t("Jobs")}
                    textColor="white"
                    backgroundColor="#01a901"
                    showActionButton={true}
                    actionButtonID="raise-jobs-button"
                    actionButtonIcon="add_box"
                    actionButtonText={t("Raise Job")}
                    actionButtonClickHandler={() => setDisplayDialog(!displayDialog)}
                    expand={expand}
                    handleExpand={handleExpand}
                    showExpand={true}
                    activeAsset={activeAsset}
                    handleRaiseNewJobfromAsset={handleRaiseNewJobfromAsset}
                    isAssetJobHeader
                    history={history}
                    titleIcon={JOB}
                />
                {assetJobs && assetJobs.length < 1 ? (
                        <div className='no-elements-card-text'>
                            {expand && <p>{t("No Jobs")}</p>}
                        </div>
                    	) : <>{expand ? <AssetJobCard
                    jobs={assetJobs}
                    history={history}
                    detailsButton={detailsButton}
                /> : <></>}</>}
            </OuterCard>
        </div>
    )
};

export default JobListCard;

interface IProps {
    assetJobs: JobTask.Job[];
    history: History;
    detailsButton: (id: string) => void;
    activeAsset: Asset;
    handleRaiseNewJobfromAsset: (activeAsset: Asset, history: History) => void;
}