export function DelayPostToJobQueue(JobAction: string) {
	if (
		JobAction === 'AddNoteJobTask' ||
		JobAction === 'AddPhotoExistingJobTask' ||
		JobAction === 'TaskLeaveSite' ||
		JobAction === 'TaskTravelStart' ||
		JobAction === 'AcceptedJobTask' ||
		JobAction === 'TaskArriveOnSite' ||
		JobAction === 'NewRequestPhotos' ||
		JobAction === 'NewJobFromFormFailPhotos' ||
		JobAction === 'AddDocumentExistingJobTask' ||
		JobAction === 'TaskArriveHome' ||
		JobAction === 'StockJobTask'
		//|| JobAction === 'FormLibraryNewFormCreated'
	) {
		return true;
	}

	return false;
}
