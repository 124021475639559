import { CircularProgress, Icon } from '@material-ui/core';
import * as React from 'react';
import Button from '@material-ui/core/Button';
import "../../styles/shared.css"





const LoadingSpinner = props => (
	<div
		style={{
			display: 'flex',
			justifyContent: 'center',
			alignItems: 'center',
			height: props.height || '100vh',
			flexDirection: 'column',
		}}
	>
		<br></br>
		<div style={{ textAlign: 'center', maxWidth: '60%', width: '100%' }}>
			{props.showAlert ? (
				<div className='alertBar'>
					<Icon className='alertIcon'>warning</Icon> {props.text}
				</div>
			) : (
				<>
					<h1 style={{ marginBottom: '20px', fontSize: '25px', color: '#e91e63' }}>{props.text || 'Loading...'}</h1>
					<CircularProgress size={props.size || 50} color={props.color || 'primary'} />
				</>
			)}
		</div>
		{props.loadscreenButton ?
			<Button
				variant="contained"
				color="secondary"
				id="refreshBtn"
				className="login-buttons"
				size='small'
				onClick={() => props.buttonFunction()}
			>
				If this screen shows for more than 10 seconds there may be an issue, click here to refresh the cache.
			</Button> : null}
	</div>
);

export default LoadingSpinner;
