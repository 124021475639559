export const lightgrey = '#9E9E9E';
export const darkgrey = '#424242';
export const darkblue = '#3f51b5';
export const lightblue = '#2196F3';
export const darkgreen = '#2E7D32';
export const lightgreen = '#66BB6A';
export const lightamber = '#FFC107';
export const darkamber = '#FF6F00';
export const lightyellow = '#FDD835';
export const darkyellow = '#F57F17';
export const lightred = '#f44336';
export const darkred = '#c62828';
export const offwhite = '#f1f2f7';
export const lightpink = '#f8bbd0';
export const hotpink = '#e91e63';
