import React from 'react';
import { connect } from 'react-redux';
import { History } from 'history';
import { match } from 'react-router';
import { isNullOrUndefined } from 'util';

import { 
    Button, 
    Grid, 
    Icon, 
    TextField 
} from '@material-ui/core';

// import { fire } from '../../index';
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';

import { generateFirebaseId } from '../../utils/Guids';

// import { IsChrome } from '../../utils/IsChrome';
import { windowError } from '../../utils/WindowError';
import { parseDateForDateTimeFields, stringtoUnixUTC } from '../../utils/Times';
import { fire } from '../../index';
import { withTranslation } from 'react-i18next';

interface JobQuoteCreateScreenProps {
    history: History;
    match: match;
    UserSettings: Store.UserSettings;
    t: any;
}

interface JobQuoteCreateScreenState {
    selectedTaskId: string;
    jobQuoteReference: string;
    referenceRequired: boolean;
    jobQuoteDescription: string;
    descriptionRequired: boolean;
    NewDate: number;
    jobQuoteDate: string;
    savingQuote: boolean;
    JobTaskNumber: string;
    siteNumber: number;
    siteName: string;
}

class JobQuoteCreateScreen extends React.Component<JobQuoteCreateScreenProps, JobQuoteCreateScreenState> {
    constructor(props) {
        super(props);
        this.state = {
            JobTaskNumber: 'xxxx-001',
            selectedTaskId: '',
            jobQuoteReference: '',
            referenceRequired: false,
            jobQuoteDescription: '',
            descriptionRequired: false,
            NewDate: new Date().getTime(),
            jobQuoteDate: '',
            savingQuote: false,
            siteName: '',
            siteNumber: 0,
        }
    }

    componentDidMount() {
        const { jobtaskid } = this.props.match.params as any;
        this.setState({
            selectedTaskId: jobtaskid
        }, () => this.getSiteInfo());
    }

    getSiteInfo() {
        fire.baseQuery.collection('JobTasks').doc(this.state.selectedTaskId).get().then(docs => {
            const SiteInfo = docs.data();
            if(!isNullOrUndefined(SiteInfo)) {
                this.setState({ JobTaskNumber: SiteInfo.JobTaskNumber,siteName: SiteInfo.Site, siteNumber: SiteInfo.SiteID });
            }
        });
    }

    componentWillMount() {
        windowError(
            this.props.UserSettings.Email,
            this.props.UserSettings.UserUID,
            this.props.UserSettings.ServerName,
            'JobQuoteCreateScreen'
        );

        if(isNullOrUndefined(this.state.jobQuoteDate) || this.state.jobQuoteDate === '') {
            this.setState({
                jobQuoteDate: parseDateForDateTimeFields(this.state.NewDate)
            })
        }
    }

    backButton = () => {
        if(!isNullOrUndefined(this.props.history.location.state) &&  this.props.history.location.state.from === 'JobTaskQuotes') {
            this.props.history.replace(`/job-task-details/${this.state.selectedTaskId}`);
        } else {
            this.props.history.replace(`/job-quotes-list/${this.state.selectedTaskId}`);
        }
    }

    checkForNullFields(jobQuoteReference: string, jobQuoteDescription: string) {
        let errors = 0;

        if(isNullOrUndefined(jobQuoteReference) || jobQuoteReference === '') {
            this.setState({ referenceRequired: true });
            errors += 1;
        } else {
            this.setState({ referenceRequired: false });
        }

        if(isNullOrUndefined(jobQuoteDescription) || jobQuoteDescription === '') {
            this.setState({ descriptionRequired: true });
        } else {
            this.setState({ descriptionRequired: false });
        }

        return errors > 0;
    }

    saveJobQuote() {
        this.setState({ savingQuote: true });

        const errorsPresent = this.checkForNullFields(this.state.jobQuoteReference, this.state.jobQuoteDescription);
        
        if(errorsPresent) {
            return this.setState({ savingQuote: false });
        }

        const ObjectFBID = generateFirebaseId();

        // Object for firebase collection
        let quoteObj = {
            ResourceId: this.props.UserSettings.UserUID,
            ClientFBID: fire.ClientID,
            JobTaskFBID: this.state.selectedTaskId,
            JobQuoteNumber: '0-001',
            SiteName: this.state.siteName,
            SiteNumber: this.state.siteNumber,
            FirebaseId: ObjectFBID,
            Description: this.state.jobQuoteDescription,
            Reference: this.state.jobQuoteReference,
            RequestedDate: stringtoUnixUTC(this.state.jobQuoteDate),
            Status: 'Pending',
            SubmittedDate: null
        };

        // Object for firebase queue
        let JobQuoteObj = {
            ResourceId: this.props.UserSettings.UserUID,
            JobAction: 'QuoteNew',
            JobTaskFBID: this.state.selectedTaskId,
            JobQuoteNumber: '0-001',
            SiteName: this.state.siteName,
            SiteNumber: this.state.siteNumber,
            Description: this.state.jobQuoteDescription,
            Reference: this.state.jobQuoteReference,
            RequestedDate: stringtoUnixUTC(this.state.jobQuoteDate),
            Status: 'Pending',
            SubmittedDate: null
        }
        
        fire.CreateJobQuote(quoteObj, ObjectFBID);

        fire.postToJobQueue(ObjectFBID, JobQuoteObj).catch(err => {
            alert(this.props.t('There was an error adding job quote. If problem persists please email - support@trackplanfm.com'));
            console.log(err);
        });

        this.setState({ savingQuote: false }, () => {
            this.props.history.replace(`/job-quote/${ObjectFBID}`);
        });
    }

    render() {
        return (
            <div>
                <SideDrawer
                    history={this.props.history}
                    title={this.props.t("New Job Quote")}
                    rightMenuButton={
                        <BackButton callbackMethod={this.backButton} />
                    }
                />  

                <Grid container={true} direction="column" justify="center" alignItems="center">
                    <div className="main">
                        <div className="padding-25-15">

                            <div style={{ marginTop: 20 }}>
                                {/* <p style={{ fontWeight: 600, fontStyle: 'italic' }}> Reference </p> */}
                                <TextField 
                                    error={this.state.referenceRequired}
                                    id="job-quote-reference"
                                    type="text"
                                    fullWidth={true}
                                    name="JobQuoteReference"
                                    label={this.props.t("Job Quote Reference")}
                                    multiline={true}
                                    value={this.state.jobQuoteReference}
                                    onChange={event => {
                                        this.setState({ jobQuoteReference: event.target.value });
                                    }}
                                />
                            </div>
                            <FieldEmptyWarning
                                showField={this.state.referenceRequired}
                                textForField={this.props.t("Job Quote Reference is Required")}
                            />

                            <div style={{ marginTop: 20 }}>
                                {/* <p style={{ fontWeight: 600, fontStyle: 'italic' }}> Description </p> */}
                                <TextField 
                                    error={this.state.descriptionRequired}
                                    id="job-quote-description"
                                    type="text"
                                    fullWidth={true}
                                    name="JobQuoteDescription"
                                    label={this.props.t("Job Quote Description")}
                                    multiline={true}
                                    value={this.state.jobQuoteDescription}
                                    onChange={event => {
                                        this.setState({ jobQuoteDescription: event.target.value });
                                    }}
                                />
                            </div>
                            <FieldEmptyWarning
                                showField={this.state.descriptionRequired}
                                textForField={this.props.t("Job Quote Description is Required")}
                            />

                            <div className="container-div" style={{ marginTop: 20 }}>
                                <p style={{ fontWeight: 600, fontStyle: 'italic', marginBottom: 5 }}> {this.props.t("Quote Date")} </p>
                                <TextField
                                    variant="standard"
                                    id="quote-date"
                                    fullWidth={true}
                                    type="datetime-local"
                                    onChange={event => {
                                        this.setState({ jobQuoteDate: event.target.value, NewDate: stringtoUnixUTC(event.target.value) })
                                    }}
                                    defaultValue={
                                        !isNullOrUndefined(this.state.jobQuoteDate) && this.state.jobQuoteDate !== '' ? this.state.jobQuoteDate : parseDateForDateTimeFields(this.state.NewDate)
                                    }
                                />
                            </div>
                            
                            <Grid container alignItems="flex-start" justify="flex-end" direction="row">
                                <div className="btn-container">
                                    <Button
                                        disabled={this.state.savingQuote}
                                        color="primary"
                                        variant="contained"
                                        style={{ backgroundColor: 'green' }}
                                        onClick={() => this.saveJobQuote()}
                                    >
                                        <Icon>save</Icon>&nbsp; {this.props.t("Create")}
                                    </Button>
                                </div>
                            </Grid>
                        </div>
                    </div>
                </Grid>
            </div>
        )
    }
}

let FieldEmptyWarning = props => (
	<div style={{ ...props.style, paddingTop: 8 }}>
		<p
			style={{
				color: '#c62828',
				fontStyle: 'italic',
				display: props.showField ? 'flex' : 'none',
			}}
		>
			{props.textForField}
		</p>
	</div>
);

const mapStateToProps = (state: Store.Store) => ({
    UserSettings: state.User.UserSettings
});

export default withTranslation()(connect(mapStateToProps)(JobQuoteCreateScreen));