import * as React from 'react';
import firebase from 'firebase';
import { saveAs } from 'file-saver';
import { isNullOrUndefined } from 'util';
import {
	CircularProgress,
	Dialog,
	DialogContent,
	Icon,
	IconButton,
	Table,
	TableBody,
	TableCell,
	TableHead,
	TableRow,
} from '@material-ui/core';

import { idb, fire } from '../../index';
import OuterCard from '../shared/OuterCard';
import CardHeader from '../shared/CardHeader';
import GetMimeType from '../../utils/MimeTypes';
import { isIos, isIOS13 } from '../../utils/IsIos';
import { MIME_TYPE_DOCUMENT } from '../../utils/database/indexdb/IndexDb';
import DocumentDetails from '../shared/DocumentDetails';
import { withTranslation } from 'react-i18next';
import { BusinessTypes } from '../../utils/shared';

//card icon
import DOCUMENT from '../../assets/images/icons/DOCUMENT.svg'

interface JobTaskDocumentsState {
	documents: indexDb.Documents[];
	downloading: boolean;
	activeRow: number | null;
	Online: boolean;
	From: string;
	modalOpen: boolean;
	Filename?: string;
	UploadedBy?: string;
	Source?: string;
	DateCreated?: number;
	expand: boolean;
}

interface JobTaskDocumentsProps {
	documentId: string;
	From: string;
	isComplete: boolean;
	t: any;
}

class JobTaskDocuments extends React.Component<JobTaskDocumentsProps, JobTaskDocumentsState> {
	constructor(props: Readonly<JobTaskDocumentsProps>) {
		super(props);

		this.state = {
			documents: [],
			downloading: false,
			activeRow: null,
			Online: navigator.onLine,
			From: '',
			modalOpen: false,
			expand: false,
		};
	}

	handleExpand = (value) => {
		this.setState({ expand: value })
	}

	componentDidMount() {
		this.getDocuments(this.props.From);
		this.setState({ From: this.props.From });
		if (this.props.From === 'Expenses') {
			idb.syncDocumentsFromFirebase(
				`Expenses/${this.props.documentId}/Documents`,
				this.props.documentId,
				this.props.isComplete,
				BusinessTypes.Expense
			);
		} else {
			idb.syncDocumentsFromFirebase(
				`JobTasks/${this.props.documentId}/Documents`,
				this.props.documentId,
				this.props.isComplete,
			);
		}
		this.watchDocuments();
	}

	watchDocuments() {
		idb.db.documents.hook('creating', (_primaryKey, document) => {
			if (this.props.From === 'Expenses' || document.mimeType === MIME_TYPE_DOCUMENT) {
				this.addDocument(document);
			}
		});
	}

	getDocuments = (source: string) => {
		if (source == 'Expenses') {
			idb.getDocuments(this.props.documentId).then(documents => {
				if (documents.length > 0) {
					documents.forEach(documentObject => {
						this.addDocument(documentObject);
					});
				}
			});
		} else {
			idb.getDocumentsWithoutPhotos(this.props.documentId).then(documents => {
				if (documents.length > 0) {
					documents.forEach(documentObject => {
						this.addDocument(documentObject);
					});
				}
			});
		}
	};

	addDocument(document: indexDb.Documents) {
		if (!this.documentExists(document.guid)) {
			this.setState({
				documents: [...this.state.documents, document],
			});
		}
	}

	documentExists = guid => this.state.documents.some(el => el.guid === guid);

	toggleModal = () => {
		this.setState({
			modalOpen: !this.state.modalOpen,
		});
	};

	downloadFile = (row: indexDb.Documents, rowIndex: number) => {
		this.setState({
			downloading: true,
			activeRow: rowIndex,
		});

		//gets the mime type of the file
		const mimeType =
			isNullOrUndefined(row.mimeType) || row.mimeType === '' ? GetMimeType(row.documentName) : row.mimeType;

		//creates a new file with the mime type
		const file = new File([row.document], row.documentName, { type: mimeType });

		//saves the file with the filename
		saveAs(file);

		this.setState({
			downloading: false,
		});
	};

	removeFile(row: indexDb.Documents, rowIndex: number) {
		const { documentId } = this.props;

		const documentQuery = fire.baseQuery.collection('JobTasks').doc(documentId).collection('Documents').where('Guid', '==', row.guid);

		documentQuery.get().then(function (snapshot) {
			const docId = snapshot.docs[0].id;

			fire.baseQuery.collection('JobTasks').doc(documentId).collection('Documents').doc(docId).delete();
			idb.removeDocumentByGuid(row.guid);

			if (navigator.onLine) {
				const fileStorageLocation = snapshot.docs[0].data()["FirebaseUrl"];
				const fileRef = firebase.storage().refFromURL(fileStorageLocation)["location"].path_;
				const storage = firebase.storage();
				const storageRef = storage.ref();
				storageRef.child(fileRef).delete();
			}

			const deleteDocumentObj = {
				JobAction: 'DeleteDocument',
				JobTaskFBID: documentId,
				FBID: docId
			}

			fire.postToJobQueue(documentId, deleteDocumentObj);


		}).then(() => {
			this.setState({ documents: [] });
			this.getDocuments(this.props.From);
		})
	}

	render() {
		if (this.state.documents.length < 1) {
			let docName = 'job task';
			if (this.state.From === 'Expenses') {
				docName = 'expense';
			}
			return (
				<OuterCard styles="outer-card">
					<p style={{ color: '#e91e63' }}>{this.props.t("No documents")}</p>
				</OuterCard>
			);
		}

		return (
			<OuterCard id="documents-card" padding="0px">
				{this.state.From !== 'Expenses' ? (
					<CardHeader title={this.props.t("Job Documents")} textColor="white" backgroundColor="#2196f3" expand={this.state.expand} handleExpand={this.handleExpand} showExpand={false} titleIcon={DOCUMENT} />
				) : (
					<CardHeader title={this.props.t("Expenses Documents")} textColor="white" backgroundColor="#2196f3" expand={this.state.expand} handleExpand={this.handleExpand} showExpand={true} titleIcon={DOCUMENT} />
				)}
				{this.state.expand ? <Table>
					<TableHead>
						<TableRow style={{ padding: '0', paddingBottom: '5px' }}>
							<TableCell className="cell3" padding="none">
								{this.props.t("File Name")}
							</TableCell>

							{isIos() && !isIOS13() ? null : (
								<TableCell className="cell5" padding="none" align="center">
									{this.props.t("Remove")}
								</TableCell>
							)}

							{isIos() && !isIOS13() ? null : (
								<TableCell className="cell5" padding="none" align="center">
									{this.props.t("Download")}
								</TableCell>
							)}

							<TableCell className="cell5" padding="none" align="center">
								{this.props.t("Details")}
							</TableCell>
						</TableRow>
					</TableHead>

					<TableBody>
						{this.state.documents.map((row, i) => {
							return (
								<TableRow key={i} data-rowid={i}>
									<TableCell className="cell3" padding="none">
										{row.documentName}
									</TableCell>

									{isIos() && !isIOS13() ? null : (
										<TableCell className="cell5" padding="none" align="center">
											<IconButton onClick={() => this.removeFile(row, i)}>
												<Icon style={{ color: 'black' }}>clear</Icon>
											</IconButton>
										</TableCell>
									)}

									{isIos() && !isIOS13() ? null : (
										<TableCell className="cell5" padding="none" align="center">
											{this.state.downloading && this.state.activeRow === i ? (
												<CircularProgress color="secondary" size={25} style={{ marginLeft: '12px' }} />
											) : (
												<IconButton onClick={() => this.downloadFile(row, i)}>
													<Icon style={{ color: 'black' }}>cloud_download</Icon>
												</IconButton>
											)}
										</TableCell>
									)}

									<TableCell className="cell5" padding="none" align="center">
										<IconButton
											onClick={() => {
												this.setState({
													Filename: row.documentName,
													UploadedBy: row.uploadedBy,
													Source: row.source,
													DateCreated: row.dateCreated,
												});
												this.toggleModal();
											}}
										>
											<Icon style={{ color: 'black' }}>info</Icon>
										</IconButton>
									</TableCell>
								</TableRow>
							);
						})}
					</TableBody>
				</Table> : <></>}

				<Dialog open={this.state.modalOpen} onClose={() => this.toggleModal()} fullWidth={false} maxWidth={false}>
					<Icon style={{ position: 'absolute', cursor: 'pointer', top: 5, right: 5 }} onClick={this.toggleModal}>
						close
					</Icon>
					<br />

					<DialogContent>
						<DocumentDetails
							Filename={this.state.Filename}
							UploadedBy={this.state.UploadedBy}
							Source={this.state.Source}
							DateCreated={this.state.DateCreated}
						/>
					</DialogContent>
				</Dialog>
			</OuterCard>
		);
	}
}

export default withTranslation()(JobTaskDocuments);
