import * as React from 'react';

import { fire } from '../../index';
import { isNullOrUndefined } from 'util';
import { Dialog, DialogActions, DialogTitle, DialogContent, TextareaAutosize, TextField, Icon, Button } from '@material-ui/core';
import styled from 'styled-components';
import { IsChrome } from '../../utils/IsChrome';
import { withTranslation } from 'react-i18next';

interface EditQuoteCostRowModalProps {
    open :boolean;
    closeModal: () => void;
    jobQuoteFBID: string;
    costRow: JobQuote.CostRow;
    t: any;
}

interface EditQuoteCostRowModalState {
    Description: string;
    CallOut: string;
    Labour: string;
    Materials: string;
    totalCost: number;
    descriptionError: boolean;
    calloutError: boolean;
    materialsError: boolean;
    labourError: boolean;
    savingQuoteCostRow: boolean;
}

class EditQuoteCostRowModal extends React.Component<EditQuoteCostRowModalProps, EditQuoteCostRowModalState> {
    constructor(props) {
        super(props);

        this.state = {
            Description: '',
            CallOut: '',
            Labour: '',
            Materials: '',
            descriptionError: false,
            calloutError: false,
            materialsError: false,
            labourError: false,
            totalCost: 0,
            savingQuoteCostRow: false,
        }
    }

    UNSAFE_componentWillReceiveProps(newProps: EditQuoteCostRowModalProps) {
		if (newProps.costRow !== this.props.costRow) {
			this.setState({ 
                Description: newProps.costRow.Description,
                CallOut: newProps.costRow.CallOut,
                Labour: newProps.costRow.Labour,
                Materials: newProps.costRow.Materials,
            }, this.calculateTotalCost);
		}
    }

    calculateTotalCost = () => {
        const { Materials, Labour, CallOut } = this.state;
        const total = (+Materials) + (+Labour) + (+CallOut);
        const roundedTotal = (Math.round(total * 100) / 100).toFixed(2);
        this.setState({
            totalCost: parseFloat(roundedTotal)
        })
    }

    checkForNullFields = () => {
        let errors = 0;

        if(this.state.Description === '') {
            this.setState({ descriptionError: true });
            errors += 1;
        } else {
            this.setState({ descriptionError: false });
        }

        if(this.state.CallOut === '') {
            this.setState({ calloutError: true });
            errors += 1;
        } else {
            this.setState({ calloutError: false });
        }

        if(this.state.Materials === '') {
            this.setState({ materialsError: true });
            errors += 1;
        } else {
            this.setState({ materialsError: false });
        }

        if(this.state.Labour === '') {
            this.setState({ labourError: true });
            errors += 1;
        } else {
            this.setState({ labourError: false });
        }
        return errors > 0;
    }

    saveChanges(): void {
        this.setState({ savingQuoteCostRow: true });

        const errorsPresent = this.checkForNullFields();
        if(errorsPresent) {
            return this.setState({ savingQuoteCostRow: false });
        }

        if(!isNullOrUndefined(this.props.jobQuoteFBID) && !isNullOrUndefined(this.props.costRow.Id)) {
            const { Id } = this.props.costRow;

            const costRowObject = {
                Description: this.state.Description,
                CallOut: parseFloat(this.state.CallOut).toFixed(2),
                Materials: parseFloat(this.state.Materials).toFixed(2),
                Labour: parseFloat(this.state.Labour).toFixed(2),
            }

            const ref = fire.baseQuery.collection('Quotes').doc(this.props.jobQuoteFBID).collection('CostRow').doc(Id);

            fire.updateDocument(ref, costRowObject);

            costRowObject['JobAction'] = "QuoteCostRowEdit";
            costRowObject['JobQuoteFBID'] = this.props.jobQuoteFBID;

            fire.postToJobQueue(Id, costRowObject).catch(err => {
                alert(this.props.t('There was an error editing cost row for job quote. If problem persists please email - support@trackplanfm.com'));
                console.log(err);
            });

            this.props.closeModal();

            this.setState({ savingQuoteCostRow: false });
        }
    }

    handleDescriptionChange(e: any) {
        this.setState({ Description: e.target.value });
    }

    handleValueChange(e: any) {
        this.setState({ [e.target.name]: e.target.value } as EditQuoteCostRowModalState, this.calculateTotalCost);
    }

    render() {
        return (
            <Dialog open={this.props.open} aria-labelledby="Edit Quote Cost Row" fullScreen={true} onClose={this.props.closeModal} maxWidth="lg">
                <DialogTitle id="form-dialog-title">{this.props.t("Edit Quote Cost Row")}</DialogTitle>
                <DialogContent>
                    <Content>
                        <TextField
                            error={this.state.descriptionError}
                            autoComplete={IsChrome()}
                            autoFocus={true}
                            margin= "none"
                            fullWidth={true}
                            variant="outlined"
                            id="CostDescription"
                            label={this.props.t("Description")}
                            placeholder="Description about the cost row item"
                            name="Description"
                            onChange={e => this.handleDescriptionChange(e)}
                            value={this.state.Description} 
                        />
                        <FieldEmptyWarning
                            showField={this.state.descriptionError}
                            textForField={this.props.t("Quote Cost Row Description Cannot be Empty")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField
                            error={this.state.materialsError}
                            autoComplete={IsChrome()}
                            margin="none"
                            id="materials"
                            label={this.props.t("Materials")}
                            type="number"
                            fullWidth={true}
                            placeholder="0"
                            variant="outlined"
                            name="Materials"
                            onChange={e => this.handleValueChange(e)}
                            value={this.state.Materials}
                        />
                        <FieldEmptyWarning
                            showField={this.state.materialsError}
                            textForField={this.props.t("Materials Cost Cannot be Empty or Less than 0")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField
                            error={this.state.labourError}
                            autoComplete={IsChrome()}
                            margin="none"
                            id="labour"
                            label={this.props.t("Labour")}
                            type="number"
                            fullWidth={true}
                            placeholder="0"
                            variant="outlined"
                            name="Labour"
                            onChange={e => this.handleValueChange(e)}
                            value={this.state.Labour}
                        />
                        <FieldEmptyWarning
                            showField={this.state.labourError}
                            textForField= {this.props.t("Labour Cost Cannot be Empty or Less than 0")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField
                            error={this.state.calloutError}
                            autoComplete={IsChrome()}
                            margin="none"
                            id="other"
                            label={this.props.t("Other")}
                            type="number"
                            fullWidth={true}
                            variant="outlined"
                            placeholder="0"
                            name="CallOut"
                            onChange={e => this.handleValueChange(e)}
                            value={this.state.CallOut}
                        />
                        <FieldEmptyWarning
                            showField={this.state.calloutError}
                            textForField={this.props.t("CallOut Cost Cannot be Empty or Less than 0")}
                            style={{ marginBottom: '5px' }}
                        />
                    </Content>
                    <Content>
                        <TextField
                            value={this.state.totalCost}
                            disabled={true}
                            margin="none"
                            id="totalCost"
                            label={this.props.t("Total Cost")}
                            type="number"
                            fullWidth={true}
                            variant="outlined"
                            name="TotalCost"
                            style={{ marginBottom: '15px' }}
                            />
                    </Content>
                </DialogContent>
                
                <DialogActions>
                    <Button
                        onClick={() => this.props.closeModal()}
                        color="primary"
                        style={{ backgroundColor: 'var(--light-grey)', color: 'white' }}
                    >
                        <Icon style={{ fontSize: 20 }}>undo</Icon> &nbsp;
                        <p style={{ marginTop: 2 }}>{this.props.t("Back")}</p>
                    </Button>
                    <Button
                        onClick={() => this.saveChanges()}
                        color="primary"
                        style={{ backgroundColor: 'var(--light-green)', color: 'white' }}
                    >
                        <Icon style={{ fontSize: 20 }}>save</Icon>&nbsp;
                        <p style={{ marginTop: 2 }}>{this.props.t("Save")}</p>
                    </Button>
                </DialogActions>
            </Dialog>
        )
    }
}

let FieldEmptyWarning = props => (
	<div style={{ ...props.style, paddingTop: 8 }}>
		<p
			style={{
				color: '#c62828',
				fontStyle: 'italic',
				display: props.showField ? 'flex' : 'none',
			}}
		>
			{props.textForField}
		</p>
	</div>
);

const Content = styled.div`
	margin-top: 15px;
`;


export default withTranslation()(EditQuoteCostRowModal);