import Api from '../apiConfiguration.json';

export const IsDevelopment = (AppType: string) => {
    if(AppType == "Resource"){
        var url = window.location.href;
		if(url.includes("appres.trackplanfm.com")){
            return 'https://appres.trackplanfm.com';
        }
        else if (Api.ENVIRONMENT === 'development') {
            return 'https://resource-staging.trackplanfm.com';
        } else {
            return 'https://resource.trackplanfm.com';
        }
    }

    else if(AppType == "Manager"){
        var url = window.location.href;
		if(url.includes("appges.trackplanfm.com")){
            return 'https://appges.trackplanfm.com';
        }
        else if (Api.ENVIRONMENT === 'development') {
            return 'https://manager-staging.trackplanfm.com';
        } else {
            return 'https://manager.trackplanfm.com';
        }
    }

    else if(AppType == "Request"){
        var url = window.location.href;
		if(url.includes("appdem.trackplanfm.com")){
            return 'https://appdem.trackplanfm.com';
        }
        else if (Api.ENVIRONMENT === 'development') {
            return 'https://request-staging.trackplanfm.com';
        } else {
            return 'https://request.trackplanfm.com';
        }
    }

    else return "";
    
};
