import * as React from 'react';
import {
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	TextField,
	Button,
	Collapse,
	IconButton,
	Box,
} from '@material-ui/core';
import { isNullOrUndefined } from 'util';
import { generateFirebaseId } from '../../utils/Guids';
import Icon from '@material-ui/core/Icon/Icon';
import { IsChrome } from '../../utils/IsChrome';
import ClearIcon from '@material-ui/icons/Clear';
import { useTranslation, withTranslation, WithTranslation } from 'react-i18next';
import { fire } from '../..';
import { StorageAreaStockSelected, StockSelectorContainer, newEmptyStorageAreaStockSelected } from './StockSelector';

interface AddStockProps extends WithTranslation {
	open: boolean;
	stockDialogClose: () => void;
	addStockAction: () => void;
	jobTaskFBID: string;
}
interface AddStockState {
	chosenStockItem: StorageAreaStockSelected;
	customStockName: string;
	comment: string;
	quantity: string;
	stockSelectorError: boolean;
	quantityError: boolean;
}

/**
 * Dialog buttons for the Stock dialog
 */
function AddStockDialogButton(props: { stockDialogClose: () => void; saveStock: () => void }): JSX.Element {
	const [t, _i18n] = useTranslation();
	return (
		<DialogActions>
			<Button
				onClick={() => props.stockDialogClose()}
				color="primary"
				style={{
					backgroundColor: 'var(--light-grey)',
					color: 'white',
				}}
			>
				<Icon fontSize="small">undo</Icon>
				&nbsp;
				<p
					style={{
						marginTop: 2,
					}}
				>
					{t('Back')}
				</p>
			</Button>
			<Button
				onClick={() => props.saveStock()}
				color="primary"
				style={{
					backgroundColor: 'var(--light-green)',
					color: 'white',
				}}
			>
				<Icon fontSize="small">save</Icon>
				&nbsp;
				<p
					style={{
						marginTop: 2,
					}}
				>
					{t('Add Stock')}
				</p>
			</Button>
		</DialogActions>
	);
}

/**
 * Custom Name, Quantity, Comment fields for the Stock dialog
 */
function NameQuantityCommentFields(props: {
	stockNameError?: boolean;
	chosenStockItem: StorageAreaStockSelected;
	handleStockNameChange: (newVal: string) => void;
	stockNameValue: string;
	quantityError?: boolean;
	handleQuantityChange: (newVal: string) => void;
	quantityValue: number;
	handleCommentChange: (newVal: string) => void;
	commentValue: string;
}) {
	const [t, _i18n] = useTranslation();
	const isChrome = IsChrome();
	// reset field value if user selected a storage area from the select field
	if (props.chosenStockItem.storageArea.value && props.stockNameValue) {
		props.handleStockNameChange('');
	}
	return (
		<>
			<Box marginBottom={4}>
				{/* if the user selected a storage area, hide the custom name field */}
				<Collapse in={!props.chosenStockItem.storageArea.value}>
					<TextField
						autoComplete={isChrome}
						variant="outlined"
						error={props.stockNameError}
						margin="none"
						id="custom-stock-name"
						label={t('Free text name')}
						type="text"
						name="StockNameForCreateStock"
						fullWidth
						onChange={e => props.handleStockNameChange(e.target.value)}
						InputProps={{
							// a clear button to clear the input field, unfortunately mui v4 doesn't support this natively
							endAdornment: props.stockNameValue ? (
								<IconButton size="small" onClick={() => props.handleStockNameChange('')}>
									<ClearIcon />
								</IconButton>
							) : (
								undefined
							),
						}}
						style={{
							marginBottom: '15px',
						}}
						value={props.stockNameValue}
					/>
				</Collapse>
			</Box>

			<TextField
				autoComplete={isChrome}
				variant="outlined"
				error={props.quantityError}
				margin="none"
				id="quantity"
				label={t('Quantity')}
				type="number"
				fullWidth
				placeholder="0"
				onChange={e => props.handleQuantityChange(e.target.value)}
				style={{
					marginBottom: '15px',
				}}
				value={props.quantityValue}
			/>
			<TextField
				autoComplete={isChrome}
				variant="outlined"
				margin="none"
				id="comment"
				label={t('Comment')}
				type="text"
				name="CommentForCreateStock"
				fullWidth
				onChange={e => props.handleCommentChange(e.target.value)}
				style={{
					marginBottom: '15px',
				}}
				value={props.commentValue}
			/>
		</>
	);
}

/**
 * Dialog for attaching stock to a job task
 */
class AddStockModal extends React.Component<AddStockProps, AddStockState> {
	constructor(props: AddStockProps) {
		super(props);
		this.state = {
			chosenStockItem: newEmptyStorageAreaStockSelected(),
			customStockName: '',
			comment: '',
			quantity: '',
			stockSelectorError: false,
			quantityError: false,
		};
	}

	handleStockSelectedChange = (chosenStock: StorageAreaStockSelected) => {
		this.setState({
			chosenStockItem: chosenStock,
			customStockName: '',
		});
	};

	handleStockNameChange = (value: string): void => {
		this.setState({ customStockName: value });
	};

	handleCommentChange = (value: string): void => {
		this.setState({ comment: value });
	};
	handleQuantityChange = (value: string): void => {
		this.setState({ quantity: value });
	};

	resetStockFields(): void {
		this.setState({
			chosenStockItem: newEmptyStorageAreaStockSelected(),
			customStockName: '',
			comment: '',
			quantity: '',
			stockSelectorError: false,
			quantityError: false,
		});
	}

	validateFormFields = () => {
		let noErrors = true;

		if (this.state.chosenStockItem.stockName.value === '') {
			if (this.state.customStockName === '') {
				this.setState({ stockSelectorError: true });
				noErrors = false;
			}
		} else {
			this.setState({ stockSelectorError: false });
		}

		if (this.state.customStockName === '') {
			if (this.state.chosenStockItem.stockName.value === '') {
				this.setState({ stockSelectorError: true });
				noErrors = false;
			}
		} else {
			this.setState({ stockSelectorError: false });
		}

		if (!this.state.quantity) {
			this.setState({ quantityError: true });
			return false;
		}

		this.setState({ quantityError: false });

		return noErrors;
	};

	saveStock = (): void => {
		const noNullErrors = this.validateFormFields();

		if (!isNullOrUndefined(this.props.jobTaskFBID) && noNullErrors) {
			const jobTaskCollection = fire.baseQuery.collection('JobTasks').doc(this.props.jobTaskFBID);

			const firebaseId = generateFirebaseId();

			const stockObject: JobTask.JobTaskStock = {
				StockFBID: this.state.chosenStockItem.stockName.value,
				Name: this.state.customStockName === '' ? this.state.chosenStockItem.stockName.text : '',
				Comment: this.state.comment,
				FreeTextName: this.state.customStockName,
				Quantity: this.state.quantity,
				JobTaskStockFBID: firebaseId,
				StorageAreaFBID: this.state.chosenStockItem.storageArea.value,
				StorageAreaName: this.state.chosenStockItem.storageArea.text,
			};

			jobTaskCollection
				.collection('Stock')
				.doc(firebaseId)
				.set(stockObject);

			fire.postToJobQueue(this.props.jobTaskFBID, { ...stockObject, JobAction: 'StockJobTask', StockAction: 'Add' });

			this.props.stockDialogClose();
			this.resetStockFields();
			this.props.addStockAction()
		}
	};

	render() {
		return (
			<Dialog
				open={this.props.open}
				onClose={this.props.stockDialogClose}
				aria-labelledby={this.props.t('Attach Stock')}
				fullScreen
				maxWidth="lg"
			>
				<DialogTitle id="form-dialog-title">{this.props.t('Attach Stock')}</DialogTitle>
				<DialogContent>
					{/* collapse if user entered a custom stock name */}
					<Collapse in={!this.state.customStockName}>
						<StockSelectorContainer
							error={this.state.stockSelectorError}
							value={this.state.chosenStockItem}
							onChange={this.handleStockSelectedChange}
						/>
					</Collapse>
					<NameQuantityCommentFields
						stockNameError={this.state.stockSelectorError}
						chosenStockItem={this.state.chosenStockItem}
						handleStockNameChange={this.handleStockNameChange}
						stockNameValue={this.state.customStockName}
						quantityError={this.state.quantityError}
						handleQuantityChange={this.handleQuantityChange}
						quantityValue={Number(this.state.quantity)}
						handleCommentChange={this.handleCommentChange}
						commentValue={this.state.comment}
					/>
				</DialogContent>
				<AddStockDialogButton stockDialogClose={this.props.stockDialogClose} saveStock={this.saveStock} />
			</Dialog>
		);
	}
}

export default withTranslation()(AddStockModal);
