import * as React from 'react';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import RadioGroup from '@material-ui/core/RadioGroup/RadioGroup';
import Radio from '@material-ui/core/Radio/Radio';
import NativeSelect from '@material-ui/core/NativeSelect/NativeSelect';
import Input from '@material-ui/core/Input/Input';
import FormHelperText from '@material-ui/core/FormHelperText/FormHelperText';
import { isNullOrUndefined } from 'util';
import Icon from '@material-ui/core/Icon/Icon';
import { IsChrome } from '../../utils/IsChrome';
import { useTranslation } from 'react-i18next';

const LeaveSiteDialogPopup = props => {
	const [t, i18n] = useTranslation();
	let moreWorkDisabled = props.permitsObj.permitsArray.length > 0;
	let moreWorkRequired = props.moreWorkRequired;

	return (
		<Dialog
			open={props.open}
			onClose={props.handleDialogClose}
			aria-labelledby={t("Leave Task")}
			fullScreen={false}
			maxWidth="lg"
			onEntered={() => {
				props.initializeSignaturePad();

				if (moreWorkDisabled) {
					moreWorkRequired = 'true';
					props.handleMoreWorkRequired('true');
				}
			}}
		>
			<DialogTitle id="form-dialog-title">{t("Leave Task")}</DialogTitle>
			<DialogContent>
				<TextField
					autoComplete={IsChrome()}
					margin="none"
					id="complete-note"
					label={
						isNullOrUndefined(props.JobTaskLeaveLabel) || props.JobTaskLeaveLabel === ''
							? t('Note')
							: props.JobTaskLeaveLabel
					}
					type="text"
					name="NoteForLeaveSite"
					fullWidth={true}
					multiline={true}
					rows={1}
					onChange={e => props.completeNoteChange(e)}
					style={{ marginBottom: '15px' }}
				/>
				<RadioGroup
					aria-label="MoreWorkRequired"
					name="MoreWorkRequired"
					value={moreWorkRequired}
					onChange={(val: any) => props.handleMoreWorkRequired(val.target.value)}
					row={true}
				>
					<FormControlLabel disabled={moreWorkDisabled} value="false" control={<Radio />} label={t("Task Complete")} />
					<FormControlLabel disabled={moreWorkDisabled} value="true" control={<Radio />} label={t("More Work Required")} />
				</RadioGroup>

				{/* Permit to work  */}
				{moreWorkDisabled ? (
					<p style={{ marginTop: '5px', marginBottom: '10px', color: 'red', fontSize: '12px', fontStyle: 'italic' }}>
						{t("Cannot fully complete task while there is a permit to work incomplete.")}
					</p>
				) : null}

				{(props.permitsObj.permitsArray.length < 1) ? null : 
				<Button
					onClick={() => {
						const url = `/form-details/${props.permitsObj.jobTaskId}/${props.permitsObj.permitsArray[0]}`;
						props.permitsObj.history.push(url);
					}}
					variant="contained"
					className='permit-to-work-button'
					>
					{t("Complete Permit To Work")}
					</Button>}
				

				{props.moreWorkRequiredReasons.length > 0 ? (
					<div id="mwrr">
						<FormHelperText
							style={{
								display: `${props.moreWorkRequired === 'true' ? 'block' : 'none'}`,
							}}
						>
							{t("More Work Required Reason")}
						</FormHelperText>
						<NativeSelect
							value={props.moreWorkRequiredValue}
							onChange={selection =>
								props.handleMoreWorkRequiredSelection(selection.target.options[selection.target.selectedIndex])
							}
							input={<Input name="mwrr" id="mwrr" />}
							name="mwrr"
							style={{
								display: `${props.moreWorkRequired === 'true' ? 'block' : 'none'}`,
							}}
							id="MoreWorkRequiredSelect"
						>
							{props.moreWorkRequiredReasons.map(
								(reason: MoreWorkRequiredReasons.MoreWorkRequiredReasons, i: number) => {
									if (i === 0) {
										return [
											<option key={i} value="">
												{t("None")}
											</option>,
											<option key={reason.Id} value={reason.Id} data-follow={reason.FollowUpQuestion}>
												{reason.Description}
											</option>,
										];
									}
									return (
										<option key={reason.Id} value={reason.Id} data-follow={reason.FollowUpQuestion}>
											{reason.Description}
										</option>
									);
								},
							)}
						</NativeSelect>
						<MoreWorkFollowUpQuestion
							style={{
								display: `${props.moreWorkRequired === 'true' ? 'block' : 'none'}`,
								marginTop: '25px',
							}}
							followQuestion={props.moreWorkFollowUpQuestion}
							followNoteChange={props.followNoteChange}
						/>
					</div>
				) : null}

				{props.signatureEnabled ? (
					<div className="signature">
						<h2 id="signature-heading">{t("Signature")}</h2>
						<div id="signature-container">
							<canvas id="signature" />
						</div>
						<Button variant="text" color="primary" onClick={() => props.clearSignature()}>
							{t("Clear Signature")}
						</Button>
					</div>
				) : null}
			</DialogContent>
			<DialogActions>
				<Button onClick={props.handleLeaveSiteDialogVisibility} color="primary">
					{t("Back")}
				</Button>
				{props.enableTravelHome ? (
					<Button onClick={() => props.handleLeaveSite(true)} color="primary">
						{t("Travel Home")}
					</Button>
				) : null}

				<Button onClick={() => props.handleLeaveSite(false)} color="primary">
					{t("Leave")}
				</Button>
			</DialogActions>
		</Dialog>
	);
};

const MoreWorkFollowUpQuestion = props => {
	if (props.followQuestion === null) {
		return null;
	} else {
		return (
			<div style={{ ...props.style }}>
				<label style={{ color: 'rgba(0, 0, 0, 0.54)' }} htmlFor="followUp-note">
					{props.followQuestion}
				</label>
				<TextField
					autoComplete={IsChrome()}
					margin="none"
					id="followUp-note"
					type="text"
					name="FollowUpNoteForLeaveSite"
					fullWidth={true}
					onChange={e => props.followNoteChange(e.target.value)}
					style={{ ...props.style, marginBottom: '15px', marginTop: '0px' }}
				/>
			</div>
		);
	}
};

export default LeaveSiteDialogPopup;
