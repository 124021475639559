import * as React from 'react';
import { History } from 'history';
import { match } from 'react-router';
import { connect } from 'react-redux';

// Fire
import { fire } from '../../index';

// Shared Components
import SideDrawer from '../../components/shared/SideDrawer';
import BackButton from '../../components/shared/BackButton';

// Material UI
import { Table, TableBody, TableCell, TableHead, TableRow, Grid, FormControl, InputLabel, Select, MenuItem, NativeSelect, Input, DialogContent, Link } from '@material-ui/core';
import { Icon, IconButton, Button, Paper } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';

import { isNullOrUndefined } from 'util';
import LockIcon from '@material-ui/icons/Lock';
import { generateFirebaseId } from '../../utils/Guids';
import AccessibilityNewIcon from '@material-ui/icons/AccessibilityNew';
import ReplayIcon from '@material-ui/icons/Replay';
import Dexie from 'dexie';
import axios, { AxiosError } from 'axios';
import Api from '../../apiConfiguration.json';
import { getBaseURL } from '../../utils/getBaseURL';
import ApiKeyObj from '../../utils/ApiObjectKey';
import { SendErrorData } from '../../utils/WindowError';
import { LocalstorageRemoveItem } from '../../utils/LocalStorage';
import i18n from '../../i18n';
import { withTranslation } from 'react-i18next';
import { AsyncPaginate, Response } from 'react-select-async-paginate';

interface SettingsScreenProps {
    history: History;
    location: Location;
    match: match;
    User: Store.UserState;
    t: any;
}

interface SettingsScreenState {
    loading: boolean;
    resourceAvailability: string;
    availabilityColor: string;
    userSettings: any;
    indexedDBVersion: number;
    language: any;
    languageMenuOpen: boolean;
    loadedLanguages: [];
    lastLoadedLanguage: any;
    endLanguage: boolean;
    languageFilter?: {
        value: any;
        label: string;
    } | null;
}

//Checks to see if being passed in link URL and if so sets a link tag around the field content.
const FieldHolder = props => (
    <div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line' : ''} field`}>
        <p style={{ overflowWrap: 'break-word' }} className="field-content-asset">
            {props.content}
        </p>
        <p className="field-label-asset">{props.label}</p>
    </div>
);

class SettingsScreen extends React.Component<SettingsScreenProps, SettingsScreenState> {
    private languageRef: React.RefObject<HTMLInputElement>;
    constructor(props) {
        super(props);
        this.state = {
            loading: false,
            resourceAvailability: 'Available',
            availabilityColor: '#00ff00',
            userSettings: null,
            indexedDBVersion: 0,
            language: null,
            languageMenuOpen: false,
            lastLoadedLanguage: null,
            loadedLanguages: [],
            endLanguage: false,
            languageFilter: null
        }
        this.languageRef = React.createRef();
        this.handleChange = this.handleChange.bind(this);
    }

    async componentDidMount() {
        const { UserSettings } = this.props.User;
        this.setState({ userSettings: this.props.User })

        await new Dexie('TrackplanContractorDB').open().then(async db => {
            this.setState({ indexedDBVersion: db.verno });
            console.log(this.state.indexedDBVersion);
        });

        await this.initializeResourceAvailability(UserSettings);
        this.initializeLanguage();
    }

    async initializeResourceAvailability(userSettings) {
        if (isNullOrUndefined(userSettings.ResourceAvailabilityStatus) || userSettings.ResourceAvailabilityStatus == "Available") {
            this.setState({ resourceAvailability: 'Available' });
            return;
        }

        switch (userSettings.ResourceAvailabilityStatus) {
            case "Busy":
                this.setState({ resourceAvailability: 'Busy', availabilityColor: '#f79216' });
                return;

            case "Off Duty":
                this.setState({ resourceAvailability: 'Off Duty', availabilityColor: '#f72516' });
                return;
        }
    }

    async reloadEverything(UserSettings) {
        if (navigator.onLine) {
            if (confirm(this.props.t('This will refresh the App and the LocalDB. Are you sure you wish to continue?'))) {

                await self.indexedDB.deleteDatabase('TrackplanContractorDB');

                const data = {
                    VersionNumber: Api.VERSION,
                    DbVersionNumber: Api.INDEXEDDB_VERSION,
                    UserUID: UserSettings.UserUID,
                    deviceInfo: navigator.appVersion,
                    triggeredBy: 'User Reload',
                };

                await axios({
                    data: data,
                    method: 'post',
                    url: getBaseURL(UserSettings.ServerName) + '/api/upgradealert/resourceapp',
                    headers: ApiKeyObj,
                }).catch((err: AxiosError) => {
                    SendErrorData(
                        UserSettings.Email,
                        UserSettings.UserUID,
                        UserSettings.ServerName,
                        err.response + ' ' + err.stack + ' ' + err.message,
                        '',
                        '',
                    );
                });

                //Checks if service worker is online, if so then unregister and print to console.
                navigator.serviceWorker.ready.then(reg => {
                    reg.unregister().then(ifUnregistered => {
                        ifUnregistered ? console.log("Service worker has successfully unregistered.") : console.log("Service worker did not successfully unregister.");
                    })
                });

                await window.location.reload();
            }
        }
    }

    hideLanguageMenu = () => {
        this.setState({ languageMenuOpen: false })
    };

    showLanguageMenu = () => {
        this.setState({ languageMenuOpen: true }, () => { if (!isNullOrUndefined(this.languageRef.current)) this.languageRef.current.focus() });
    };

    handleChange = (event) => {
        switch (event.target.value) {
            case "Available":
                this.setState({ resourceAvailability: 'Available', availabilityColor: '#00ff00' });
                break;

            case "Busy":
                this.setState({ resourceAvailability: 'Busy', availabilityColor: '#f79216' });
                break;

            case "Off Duty":
                this.setState({ resourceAvailability: 'Off Duty', availabilityColor: '#f72516' });
                break;
        }
        const data = {
            JobAction: "ResourceAvailabilityChange",
            Value: event.target.value
        }
        fire.postToJobQueue(generateFirebaseId(), data)
        fire.updateResourceAvailability(event.target.value);
    };

    backButton = () => {
        window.history.back();
    };

    getResourceName = (UserSettings) => {

    }

    routePassword() {
        this.props.history.push('/passwordreset/');
    }

    loadLanguages = async (languageName, loadedOptions) => {
        return new Promise<Response>(resolve => {
            fire
                .getLanguages(this.state.lastLoadedLanguage)
                .get()
                .then(docs => {
                    if (!docs.empty) {
                        let languages: any = [];
                        docs.forEach(function (language) {
                            console.log(language.data());
                            const languageData = {
                                value: language.data(),
                                label: language.data().LanguageLabel
                            }
                            languages.push(languageData);
                        });
                        this.setState({ loadedLanguages: languages, lastLoadedLanguage: docs.docs[docs.docs.length - 1] }, () => {
                            return resolve({
                                options: this.state.loadedLanguages,
                                hasMore: !this.state.endLanguage
                            });
                        })
                    } else {
                        this.setState({ endLanguage: true });
                        return resolve({
                            options: [],
                            hasMore: !this.state.endLanguage
                        });
                    }
                })
        })
    }

    handleSelectedLanguageChange = (newLanguage: any) => {
        this.setState({ language: newLanguage, languageFilter: { value: newLanguage, label: newLanguage.LanguageLabel } });

        if (newLanguage != null) {
            console.log(newLanguage.LanguageLabel);
            i18n.changeLanguage(newLanguage.LanguageLabel);
            fire.updateUserLanguage(this.state.userSettings.UserUID, newLanguage.LanguageLabel, newLanguage.LanguageCulture);
        }

        else {
            i18n.changeLanguage("English");
            fire.updateUserLanguage(this.state.userSettings.UserUID, "English", "EN");
        }
    };

    initializeLanguage = async () => {
        fire.getUserLanguage(this.state.userSettings.UserSettings.UserUID).then(userRecord => {
            var userLanguage = userRecord.docs[0].data().Language;
            if (userLanguage != undefined && userLanguage != null) {
                fire.getLanguage(userLanguage).then(language => {
                    this.setState({ language: language.docs[0].data(), languageFilter: { value: language.docs[0].data(), label: language.docs[0].data().LanguageLabel } });
                })
            }
        });
    };

    logout() {
        LocalstorageRemoveItem('JobQuoteFilterOptions');
        LocalstorageRemoveItem('JobTaskFilterOptions');
        LocalstorageRemoveItem('FormLibraryFilterOptions');
        fire.auth.signOut().then(() => location.reload());
    }

    render() {
        const { UserSettings } = this.props.User;
        return (
            <Grid container={true} direction="column" justify="center" alignItems="stretch">
                <SideDrawer
                    history={this.props.history}
                    title={this.props.t("Settings")}
                    rightMenuButton={<BackButton callbackMethod={this.backButton} />}
                />
                <DialogContent>
                    <div id="details-outer-asset">
                        <div className="top-card card-shadow settingCard" style={{ border: '2px ridge grey' }}>

                            {UserSettings ? <FieldHolder content={UserSettings.Email} label={this.props.t("Resource Email")} /> : null}

                            <FieldHolder content={Api.VERSION} label={this.props.t("App Version")} />
                            <div className={"field"}>
                                <p style={{ overflowWrap: 'break-word' }} className="field-content-asset">
                                    <AsyncPaginate
                                        blurInputOnSelect
                                        value={this.state.languageFilter}
                                        selectRef={this.languageRef}
                                        onFocus={this.showLanguageMenu}
                                        onBlur={this.hideLanguageMenu}
                                        menuIsOpen={this.state.languageMenuOpen}
                                        onChange={(e) => { this.handleSelectedLanguageChange(e.value) }}
                                        loadOptions={this.loadLanguages}
                                        loadingMessage={() => this.props.t("Loading Languages")}
                                        noOptionsMessage={() => this.props.t("No languages found.")}
                                        isSearchable={false}
                                        isDisabled={false}
                                    />
                                </p>
                                <p className="field-label-asset">{this.props.t("Language")}</p>
                            </div>

                            <FormControl>
                                <InputLabel id="demo-simple-select-label">{this.props.t("Resource Availability")}</InputLabel>
                                <Select
                                    labelId="demo-simple-select-label"
                                    id="demo-simple-select"
                                    value={this.state.resourceAvailability}
                                    onChange={this.handleChange}
                                >
                                    <MenuItem value={"Available"}>{this.props.t("Available")}</MenuItem>
                                    <MenuItem value={"Busy"}>{this.props.t("Busy")}</MenuItem>
                                    <MenuItem value={"Off Duty"}>{this.props.t("Off Duty")}</MenuItem>
                                </Select>
                                <AccessibilityNewIcon style={{ color: this.state.availabilityColor }}></AccessibilityNewIcon>
                            </FormControl>
                        </div>
                    </div>
                    <Button
                        style={{ backgroundColor: '#f59e38', justifyContent: 'center' }}
                        onClick={() => this.routePassword()}
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                    >
                        {this.props.t("Change Password")}
                        <LockIcon style={{ paddingLeft: "5px" }}></LockIcon>
                    </Button>
                    <Button
                        style={{ backgroundColor: '#2196f3', justifyContent: 'center', marginTop: '10px' }}
                        onClick={() => this.reloadEverything(UserSettings)}
                        variant="contained"
                        color="secondary"
                        size="large"
                        fullWidth
                    >
                        {this.props.t("Reload Application")}
                        <ReplayIcon style={{ paddingLeft: "5px" }}></ReplayIcon>
                    </Button>
                    <Button
                        style={{ color: '#f59e38', justifyContent: 'center', marginTop: '10px' }}
                        onClick={() => this.logout()}
                        variant="outlined"
                        size="large"
                        fullWidth
                    >
                        {this.props.t("Logout")}
                        <ExitToAppIcon style={{ paddingLeft: "5px" }}></ExitToAppIcon>
                    </Button>
                </DialogContent>
            </Grid>
        )
    }
}


const mapStateToProps = (state: Store.Store) => ({
    UserSettings: state.User.UserSettings,
    User: state.User
});


export default withTranslation()(connect(mapStateToProps)(SettingsScreen));  