// React
import * as React from 'react';
import { useTranslation } from 'react-i18next';

// Utils
import { isNullOrUndefined } from 'util';
import { parseDate } from '../../utils/Times';

interface FormCardProps {
	form: FormTemplates.FormTemplate;
	startNewForm: (Template: FormTemplates.FormTemplate) => void;
	editCurrentForm: (FormTemplateID: string, FormFBID: string) => void;
}

const FormCard = (props: FormCardProps) => {
	const [t, i18n] = useTranslation();
	const color =
		!isNullOrUndefined(props.form.FormFBID) && !isNullOrUndefined(props.form.CurrentFormDate)
			? 'var(--dark-amber)'
			: 'var(--light-green)';

	return (
		<div
			className="form-card"
			style={{ borderTop: `2px solid ${color}`, borderLeft: `6px solid ${color}`, borderRight: `2px solid ${color}` }}
			onClick={
				!isNullOrUndefined(props.form.FormFBID) && !isNullOrUndefined(props.form.CurrentFormDate)
					? () => props.editCurrentForm(props.form.Id, props.form.FormFBID as string)
					: () => props.startNewForm(props.form)
			}
		>
			<div className="bottom-row-form-card">
				<div className="header-container-form">
					<p style={{ overflowWrap: 'break-word' }} className="content-form">
						{props.form.FormName}
					</p>
					<p className="label-form">{t("Form Name")}</p>
				</div>
				{!isNullOrUndefined(props.form.CurrentFormDate) ? (
					<div className="header-container-form">
						<p className="content-form">{parseDate('Date', props.form.CurrentFormDate.toString())}</p>
						<p className="label-form">{t("Current Form Date")}</p>
					</div>
				) : null}
			</div>
			<div className="bottom-row-form-card">
				<div className="header-container-form">
					<p className="content-form">{t(props.form.FormType)}</p>
					<p className="label-form">{t("Form Type")}</p>
				</div>
				<div className="header-container-form">
					<p className="content-form">{props.form.FormCategory}</p>
					<p className="label-form">{t("Form Category")}</p>
				</div>
			</div>
			{!isNullOrUndefined(props.form.AssetFBID) ? (
				<div className="bottom-row-form-card">
					<div className="header-container-form">
						<p className="content-form">{props.form.AssetName}</p>
						<p className="label-form">{t("Asset Name")}</p>
					</div>
					<div className="header-container-form">
						<p className="content-form">{props.form.AssetCode}</p>
						<p className="label-form">{t("Asset Code")}</p>
					</div>
				</div>
			) : !isNullOrUndefined(props.form.SiteFBID) ? (
				<div className="top-row-form-card">
					<div className="header-container-form">
						<p className="content-form">{props.form.SiteName}</p>
						<p className="label-form">{t("Site Name")}</p>
					</div>
				</div>
			) : null}
			<div className="bottom-row-form-card">
				<div className="header-container-form">
					<p className="content-form">{props.form.FormAccess}</p>
					<p className="label-form">{t("Form Access")}</p>
				</div>
				{!isNullOrUndefined(props.form.CurrentFormDate) ? (
					<div className="header-container-form">
						<p className="content-form">{props.form.LastUpdatedBy}</p>
						<p className="label-form">{t("Last Updated By")}</p>
					</div>
				) : null}
			</div>
			{!isNullOrUndefined(props.form.FormFBID) && !isNullOrUndefined(props.form.CurrentFormDate) ? (
				<button className="form-start-edit-button" style={{ backgroundColor: color, color: 'white' }}>
					<p className="library-p-tag">{t("Edit Current Form")}</p>
				</button>
			) : (
				<button className="form-start-edit-button" style={{ backgroundColor: color, color: 'white' }}>
					<p className="library-p-tag">{t("Start Next Form")}</p>
				</button>
			)}
		</div>
	);
};

export default FormCard;
