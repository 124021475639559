import * as React from 'react';

const OuterCard = props => (
	<div
		className={`card-shadow-alt padding ${props.styles}`}
		style={{ padding: props.padding || '', border: props.noBorder ? '' : '1px solid' + props.color }}
	>
		{props.children}
	</div>
);

export default OuterCard;
