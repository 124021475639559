import * as React from 'react';
import { connect } from 'react-redux';

import { jobtasksLoaded, currentScrollPosition } from '../../actions/JobTaskActions';

import '../../styles/job-task/job-task-card.css';
import { unixToDateString, isTodaysDate } from '../../utils/Times';
import { Icon } from '@material-ui/core';
import cardColour from '../../utils/Colors';
import { isNullOrUndefined } from 'util';
import { History } from 'history';
import { Link } from 'react-router-dom';
import { withTranslation } from 'react-i18next';

interface JobTaskCardProps {
	jobTask: JobTask.JobTask;
	history: History;
	jobsLoaded: any;
	lastLoaded: any;
	fromNotifications?: boolean;
	numJobtasks: number;
	jobtasksLoaded: (totalJobtasks) => void;
	currentScrollPosition: (scrollPosition) => void;
	t: any;
}

class JobTaskCard extends React.Component<JobTaskCardProps> {
	constructor(props) {
		super(props);
		this.state = {
		}
	}

	updateJobtasksInfo = () => {
		this.props.jobtasksLoaded(this.props.numJobtasks);
		this.props.currentScrollPosition(window.scrollY);
	}

	render() {

		const jobTask = this.props.jobTask;
		const statusColor = cardColour(jobTask.Status);
		const borderStyle = {
			border: `2px solid ${statusColor}`,
			borderLeft: `7.5px solid ${statusColor}`,
		};
		const jobTaskNumberStyle = {
			backgroundColor: `${statusColor}`,
			color: statusColor === 'transparent' ? 'black' : 'white',
		};
		const destinationUrl = `/job-task-details/${jobTask.Id}`;
		const isDueToday = (expectedDate: number) => isTodaysDate(expectedDate);
		const showLocation = jobTask.Location !== '' && !isNullOrUndefined(jobTask.Location);
		const showSubLocation = jobTask.SubLocation !== '' && !isNullOrUndefined(jobTask.SubLocation);

		return (
			<Link onClick={this.updateJobtasksInfo} to={{ pathname: destinationUrl, state: { fromNotifications: this.props.fromNotifications } }} >
				<div id="outer-card" className="card-shadow" style={borderStyle}>
					<div className="top-row">
						<div className="status-container">
							<p className="task-number" style={jobTaskNumberStyle}>
								{jobTask.JobTaskNumber.toString() === '0-001' ? this.props.t('Not Synced') : jobTask.JobTaskNumber}
							</p>
							{jobTask.IsPlanned ? <Icon style={{ color: statusColor, marginLeft: '10px' }}>cached</Icon> : null}
						</div>
						<div className="status-container">
							{isDueToday(jobTask.ExpectedDate) ? <p className="due-today-flag">{this.props.t("Due Today")}</p> : null}
							<p className="task-number" style={jobTaskNumberStyle}>
								{this.props.t(jobTask.Status)}
							</p>
						</div>
					</div>
					<hr style={{ color: statusColor }} />
					<div className="middle-row" style={{ marginTop: 5 }}>
						<div className="content-container">
							<p className="header">{this.props.t("Details") + ": "} </p>
							{jobTask.Description.length > 300 ? 
							<p className="content">{jobTask.Description.slice(0,300)}...<span style={{color: 'blue', textDecoration: 'underline'}}>[{this.props.t("Show more")}]</span></p>
							 : <p className="content">{jobTask.Description}</p>}
						</div>
						<div className="content-container">
							<p className="header">{this.props.t("Site") + ": "}</p>
							<p className="content">{jobTask.Site}</p>
						</div>
						{showLocation ? (
							<div className="content-container">
								<p className="header">{this.props.t("Location") + ": "} </p>
								<p className="content">{jobTask.Location}</p>
							</div>
						) : null}
						{showLocation && showSubLocation ? (
							<div className="content-container">
								<p className="header">{this.props.t("Sub-location") + ": "} </p>
								<p className="content">{jobTask.SubLocation}</p>
							</div>
						) : null}
						<div className="content-container">
							<p className="header">{this.props.t("Address") + ": "}</p>
							<p className="content">{jobTask.Address}</p>
						</div>
						<div className="content-container">
							<p className="header">{this.props.t("Contact") + ": "}</p>
							<p className="content">{jobTask.Contact}</p>
						</div>
					</div>
					{jobTask.ExpectedDate > 0 ? (
						<div className="bottom-row">
							<div className="content-container">
								<p className="header">{this.props.t("Expected Start") + ": "}</p>
								<p className="content">{unixToDateString(jobTask.ExpectedDate)}</p>
							</div>
						</div>
					) : null}
					{jobTask.ExpectedCompleteDate > 0 ? (
						<div className="bottom-row">
							<div className="content-container">
								<p className="header">{this.props.t("Expected Complete") + ": "}</p>
								<p className="content">{unixToDateString(jobTask.ExpectedCompleteDate)}</p>
							</div>
						</div>
					) : null}
				</div>
			</Link>
		);
	}
}

const mapDispatchToProps = dispatch => ({
	jobtasksLoaded: (totalJobtasksLoaded) => dispatch(jobtasksLoaded(totalJobtasksLoaded)),
	currentScrollPosition: (yPos) => dispatch(currentScrollPosition(yPos))
})

export default withTranslation()(connect(null, mapDispatchToProps)(JobTaskCard));
