import * as React from 'react';
import { History } from 'history';
import { fire } from '../../index';
import { unixToDateString, unixToTimeString } from '../../utils/Times';

// Shared
import OuterCard from '../../components/shared/OuterCard';
import CardHeader from '../shared/CardHeader';

// Material UI
import {
    Table,
    TableRow,
    TableBody,
    TableHead,
    TableCell,
} from '@material-ui/core';

import CreateCostDialog from '../costs/CreateCostDialog';
import { withTranslation } from 'react-i18next';

//card icon
import COST from '../../assets/images/icons/COST.svg'

interface JobTaskCostsProps {
    jobTaskFBID: string;
    history: History;
    t: any;
}

interface JobTaskCostsState {
    costs: Cost.Cost[];
    loading: boolean;
    costDialogOpen: boolean;
    expand: boolean;
}

class JobTaskCosts extends React.Component<JobTaskCostsProps, JobTaskCostsState> {
    constructor(props) {
        super(props);
        this.state = {
            costs: [],
            loading: false,
            costDialogOpen: false,
            expand: false,
        }
    }

    componentDidMount() {
        this.getJobCosts();
    }

    getJobCosts() {
        fire.baseQuery
            .collection('Costs')
            .where('JobTaskFBID', '==', this.props.jobTaskFBID)
            .onSnapshot(costsList => {
                const costsArray = costsList.docs.map(cost => {
                    const costObj = cost.data() as Cost.Cost;
                    costObj.CostFBID = cost.id;

                    return costObj;
                });

                this.setState({ costs: costsArray });
            });
    }

    calculateAmount = (row) => {
        let total = (+row.Materials) + (+row.Labour) + (+row.CallOut);
        return (Math.round(total * 100) / 100).toFixed(2);
    }

    handleCostDialog = () => {
        this.setState({ costDialogOpen: !this.state.costDialogOpen });
    }

    handleCostClick = (costId) => {
        this.props.history.push(`/job-cost/${costId}`);
    }

    handleExpand = (value) => {
		this.setState({expand: value})
	}

    render() {
        return (
            <div>
                <>
                    <CreateCostDialog 
                        open={this.state.costDialogOpen}
                        jobTaskFBID={this.props.jobTaskFBID}
                        closeModal={this.handleCostDialog}
                    />
                </>
                {(
                    <OuterCard id="costs-card" padding="0px" styles="border-radius">
                        <CardHeader title={this.props.t("Job Task Costs")} titleIcon={COST} textColor="white" backgroundColor="#b20000"
                        expand={this.state.expand} handleExpand={this.handleExpand} showExpand={true}
                    	showActionButton={true} actionButtonText={this.props.t("Add Cost")} actionButtonIcon="payment"
                        actionButtonClickHandler={this.handleCostDialog} />
                        {this.state.costs.length < 1 ? (
                        <div className='no-elements-card-text'>
                            {this.state.expand && <p>{this.props.t("No Job Task Costs")}</p>}
                        </div>
                        ) : <>{this.state.expand ? <Table>
                            <TableHead>
                                <TableRow style={{ padding: '0', paddingBottom: '5px' }}>
                                    <TableCell className="note-cell-1-header" padding="none">
                                        {this.props.t("Invoice Number")}
                                    </TableCell>
                                    <TableCell className="note-cell-2-header" padding="none">
                                        {this.props.t("Invoice Date")}
                                    </TableCell>
                                    <TableCell className="note-cell-3-header" padding="none">
                                        {this.props.t("Amount")}
                                    </TableCell>
                                    <TableCell className="note-cell-4-header" padding="none">
                                        {this.props.t("Status")}
                                    </TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {this.state.costs.map(row => {
                                    return (
                                        <TableRow key={row.CostFBID} style={{ cursor: 'pointer' }} onClick={() => this.handleCostClick(row.CostFBID)}>
                                            <TableCell className="note-cell-1" padding="none">
                                                {row.InvoiceNumber || '#'}
                                            </TableCell>
                                            <TableCell className="note-cell-2" padding="none">
                                                {unixToDateString(row.InvoiceDate, true) || 'N/A'}
                                            </TableCell>
                                            <TableCell className="note-cell-3" padding="none">
                                                {this.calculateAmount(row)}
                                            </TableCell>
                                            <TableCell className="note-cell-4" padding="none">
                                                {this.props.t(row.CostStatus)}
                                            </TableCell>
                                        </TableRow>
                                    )
                                })}
                            </TableBody>
                        </Table> : <></>}</>}
                    </OuterCard>
                )}
            </div>
        )
    }
}

export default withTranslation()(JobTaskCosts);