import * as React from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import { History } from 'history';

// Actions
import { quotesLoaded, currentScrollPosition } from '../../actions/JobQuoteActions';

import { unixToDateString } from '../../utils/Times';
import { withTranslation } from 'react-i18next';

interface JobQuoteCardProps {
    history: History;
    jobTaskID: string;
    jobQuote: JobQuote.JobQuote;
    numQuotes: number;
    quotesLoaded: (totalQuotes) => void;
    currentScrollPosition: (scrollPosition) => void;
    JobQuotesInfo: Store.JobQuotesInfoState;
    t: any;
}

interface JobQuoteCardState {
}

class JobQuoteCard extends React.Component<JobQuoteCardProps, JobQuoteCardState> {
    constructor(props) {
        super(props);
        this.state = {
        }
    }

    updateJobQuotesInfo = () => {
		this.props.quotesLoaded(this.props.numQuotes);
		this.props.currentScrollPosition(window.scrollY);
		// console.log(this.props.JobsInfo);
	}

    readonly destinationUrl = `/job-quote/${this.props.jobQuote.Id}`;

    render() {

        const cardStyles = (status) => {
            if(status.toLowerCase() === 'pending') {
                // Greyish
                return {
                    border: "2px solid grey",
                    borderLeft: "7.5px solid grey",
                    cursor: "pointer"
                };
            }
            if(status.toLowerCase() === 'submitted') {
                // Orange
                return {
                    border: "2px solid orange",
                    borderLeft: "5px solid orange",
                    cursor: "pointer"
                };
            }
            if(status.toLowerCase() === 'approved') {
                // Green
                return {
                    border: "2px solid green",
                    borderLeft: "5px solid green",
                    cursor: "pointer"
                };
            }
            if(status.toLowerCase() === 'rejected') {
                // Red
                return {
                    border: "2px solid red",
                    borderLeft: "5px solid red",
                    cursor: "pointer"
                };
            }
            return {
                border: "2px solid grey",
                borderLeft: "7.5px solid grey",
                cursor: "pointer"
            }
        }

        const jobQuoteNumberStyle = (status) => {
            if(status.toLowerCase() === 'pending') {
                // Greyish
                return {
                    backgroundColor: "grey",
                    color: "white"
                };
            }
            if(status.toLowerCase() === 'submitted') {
                // Orange
                return {
                    backgroundColor: "orange",
                    color: "white"
                };
            }
            if(status.toLowerCase() === 'approved') {
                // Green
                return {
                    backgroundColor: "green",
                    color: "white"
                };
            }
            if(status.toLowerCase() === 'rejected') {
                // Red
                return {
                    backgroundColor: "red",
                    color: "white"
                };
            }

            return {
                backgroundColor: "white",
                color: "black"
            }
        }

        const { jobQuote } = this.props;

        return (
            <Link onClick={this.updateJobQuotesInfo} to={{ pathname: this.destinationUrl, state: { from: 'QuotesList' }}}>
                <div id="outer-card" className="card-shadow" style={cardStyles(jobQuote.Status)}>
                    <div className="top-row">
                        <div className="status-container">
                            <p className="task-number" style={jobQuoteNumberStyle(jobQuote.Status)}>
                                { jobQuote.JobQuoteNumber.toString() === '0-001' ? this.props.t('Not Synced') : jobQuote.JobQuoteNumber}
                            </p>
                        </div>
                        <div className="status-container">
                            <p className="task-number" style={jobQuoteNumberStyle(jobQuote.Status)}>
                                { this.props.t(jobQuote.Status) }
                            </p>
                        </div>
                    </div>
                    <hr style={{ color: 'grey', margin: '5px 0' }} />
                    <div className="middle-row" >
                        <div className="content-container">
                            <p className="header">{this.props.t("Description") + ": "}</p>
                            <p className="content">{ jobQuote.Description }</p>
                        </div>
                        <div className="content-container">
                            <p className="header">{this.props.t("Reference") + ": "}</p>
                            <p className="content">{ jobQuote.Reference }</p>
                        </div>
                        <div className="content-container">
                            <p className="header">{this.props.t("Site Name:") + " "}</p>
                            <p className="content">{ jobQuote.SiteName }</p>
                        </div>
                        <div className="content-container">
                            <p className="header">{this.props.t("Date") + ": "}</p>
                            <p className="content">{ unixToDateString(jobQuote.RequestedDate) }</p>
                        </div>
                    </div>
                </div>
            </Link>
        )
    }
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
	JobQuotesInfo: state.JobQuotesInfo
});

const mapDispatchToProps = dispatch => ({
	quotesLoaded: (totalQuotesLoaded) => dispatch(quotesLoaded(totalQuotesLoaded)),
	currentScrollPosition: (yPos) => dispatch(currentScrollPosition(yPos))
})

export default withTranslation()(connect(mapStateToProps, mapDispatchToProps)(JobQuoteCard));