import * as React from 'react';
import { History } from 'history';
import { connect } from 'react-redux';
import {
	Scheduler,
	AgendaView,
	TimelineView,
	DayView,
	WeekView,
	MonthView,
	SchedulerItem,
} from '@progress/kendo-react-scheduler';
import '@progress/kendo-theme-material/dist/all.css';
import { windowError } from '../../utils/WindowError';
import { fire, localQueue } from '../..';
import { isNullOrUndefined } from 'util';
import { hoursAfter } from '../../utils/Times';
import dayjs from 'dayjs';
import BackButton from '../../components/shared/BackButton';
import SideDrawer from '../../components/shared/SideDrawer';
import LoadingSpinner from '../../components/shared/LoadingSpinner';
import { withTranslation } from 'react-i18next';

import { IntlProvider, LocalizationProvider, load, loadMessages } from '@progress/kendo-react-intl';

import likelySubtags from 'cldr-core/supplemental/likelySubtags.json';
import currencyData from 'cldr-core/supplemental/currencyData.json';
import weekData from 'cldr-core/supplemental/weekData.json';

// This is used to get calendar translations (months) in the user language
import { getCalendarLocalizationMonths } from './CalendarLocalizationImports';

interface CalendarScreenProps {
	history: History;
	UserSettings: Store.UserSettings;
	t: any;
}

interface CalendarScreenState {
	jobs: JobTask.JobTask[];
	filteredJobTasks: JobTask.JobTask[];
	paginationJobTasks: JobTask.JobTask[];
	loading: boolean;
	searchModalOpen: boolean;
	searchInput: string;
	openClosedTaskFilter: string;
	plannedReactiveTaskFilter: string;
	filtersActive: boolean;
	isDueToday: boolean;
	lastPageNumber: number;
	selectedPageNumber: number;
	documentsPerPage: number;
	isNotSynced: boolean;
	yAxisScroll: number;
	order: string;
	Data: any[];
}

class CalendarScreen extends React.Component<CalendarScreenProps, CalendarScreenState> {
	private unsubscribe: any = null;

	constructor(props) {
		super(props);
		this.state = {
			jobs: [],
			filteredJobTasks: [],
			paginationJobTasks: [],
			loading: false,
			searchModalOpen: false,
			searchInput: '',
			openClosedTaskFilter: 'Open',
			plannedReactiveTaskFilter: '',
			filtersActive: false,
			isDueToday: false,
			lastPageNumber: 0,
			selectedPageNumber: 0,
			documentsPerPage: 20,
			isNotSynced: false,
			yAxisScroll: 0,
			order: 'DESC',
			Data: [],
		};
	}

	componentWillMount() {
		windowError(
			this.props.UserSettings.Email,
			this.props.UserSettings.UserUID,
			this.props.UserSettings.ServerName,
			'JobTaskListScreen',
		);
		//Checks for documents in local queue and processes them if needed
		localQueue.getDocumentsInLocalQueue();

		// Feb 14, 2022 Burhan
		// Here, we create a json for all the required keys that the calendar needs for localization
		// and then call the load function which will apply the labels on the calendar
		var calendarMessages = {
			"scheduler": {
				"today": this.props.t('Today'),
				"allDay": this.props.t('All day'),
				"showWorkDay": this.props.t('Show Business Hours'),
				"showFullDay": this.props.t('Show Full Day'),
				"dayViewTitle": this.props.t('Day'),
				"weekViewTitle": this.props.t('Week'),
				"monthViewTitle": this.props.t('Month'),
				"dateTitle": this.props.t('Date'),
				"noEvents": this.props.t('No events'),
				"timeTitle": this.props.t("Time"),
				"eventTitle": this.props.t("Event"),
				"allEvents": this.props.t("All events"),
				"timelineViewTitle": this.props.t("Timeline"),
        		"agendaViewTitle": this.props.t("Agenda")
			}
		};

		const months = getCalendarLocalizationMonths(this.props.UserSettings.Culture);
		// Load the calendar in the user's culture
		loadMessages(calendarMessages, this.props.UserSettings.Language || "English");
		load(likelySubtags, currencyData, weekData, "", "", months || "", "", "");
	}

	async componentDidMount() {
		this.fetchJobTasks();
		// idb.removeCompletedPhotosFromTable();
		// idb.removeCompletedDocumentsFromTable();
		//this.setCalendarDate();
		//alert('complete');
	}

	componentWillUnmount() {
		this.unsubscribe = null;
	}

	fetchJobTasks = () => {
		this.setState({
			loading: true,
		});
		this.unsubscribe = fire.getJobTaskCollectionQuery().onSnapshot(this.handleJobTaskSnapshot);
	};

	handleJobTaskSnapshot = async (query: firebase.default.firestore.QuerySnapshot) => {
		if (query.empty || query.docChanges().length < 1) {
			return this.setState({
				loading: false,
			});
		}

		const docChanges = query.docChanges();

		let jobTaskList: JobTask.JobTask[] = this.state.jobs;
		let data: any[] = this.state.Data;
		// const data = [
		// 	{
		// 		id: 0,
		// 		title: 'Breakfast with Tom',
		// 		start: new Date('2019-08-27T08:30:00.000Z'),
		// 		end: new Date('2019-08-27T09:00:00.000Z'),
		// 	},
		// ];

		for (const job of docChanges) {
			const jobTask = job.doc.data() as JobTask.JobTask;
			//checks if jobtask already exists in array
			const jobTaskIndex = jobTaskList.findIndex(x => x.Id === job.doc.id);
			const jobTaskObj = {
				...jobTask,
				Id: job.doc.id,
			};
			let d = jobTaskObj.ExpectedDate;
			let duration = 2;

			if (!isNullOrUndefined(jobTaskObj.Duration) && jobTaskObj.Duration != 0 && jobTaskObj.Duration >= 1) {
				duration = jobTaskObj.Duration;
			}
			data.push({
				id: jobTaskObj.Id,
				Jobid: jobTaskObj.Id,
				Completed: jobTaskObj.Status,
				Site: jobTaskObj.Site,
				Location: jobTaskObj.Location,
				title: jobTaskObj.JobTaskNumber + ' ' + jobTaskObj.Site + ' ' + jobTaskObj.Description,
				start: new Date(dayjs(d).toDate()),
				end: new Date(hoursAfter(d, duration)),
			});

			if (jobTaskIndex === -1) {
				jobTaskList.push(jobTaskObj);
			} else {
				jobTaskList[jobTaskIndex] = jobTaskObj;
			}

			if (job.type === 'removed') {
				jobTaskList.splice(jobTaskIndex, 1);
			}
		}

		//sorts job task list

		this.setState({
			jobs: jobTaskList,
			Data: data,
			loading: false,
		});
	};

	backButton = () => {
		this.props.history.push('/job-task-list/');
	};
	today = dayjs().toDate();
	CustomItem = props => {
		const ref = React.useRef(null);
		const handleClick = React.useCallback(
			event => {
				this.props.history.push(`/job-task-details/${props.dataItem.Jobid}`);

				if (props.onClick) {
					props.onClick(event);
				}
			},
			[props.onClick],
		);
		return (
			<React.Fragment>
				<SchedulerItem
					{...props}
					// {/* {props.children}
					// {
					// 	<SchedulerItemContent>
					// 		{'Site'} - {props.dataItem.Site}
					// 		{'Location'} - {props.dataItem.Location}
					// 	</SchedulerItemContent>
					// } */}
					style={{
						...props.style,
						backgroundColor: props.dataItem.Completed == 'Completed' ? 'green' : 'blue',
					}}
					ref={ref}
					onClick={handleClick}

				/>
			</React.Fragment>
		);
	};
	// CustomItem = this.state.Data.map(jobdata => {
	// 	<SchedulerItem
	// 		{...jobdata}
	// 		style={{ ...jobdata.style, backgroundColor: jobdata.start < this.today ? 'pink' : 'blue' }}
	// 	/>;
	// });
	render() {
		if (this.state.loading) {
			return <LoadingSpinner color="primary" text="Loading " />;
		}
		return (
			<div>
				<SideDrawer history={this.props.history} rightMenuButton={<BackButton callbackMethod={this.backButton} />} />
				
				<LocalizationProvider language={this.props.UserSettings.Language || "English"}>
					<IntlProvider locale={(this.props.UserSettings.Culture === "zz" ? "en" : this.props.UserSettings.Culture) || "en"}>
						<Scheduler editable = {{
											add: false,
											drag: false,
											resize: false,
											select: false,
											edit: false,
											remove: false}}  data={this.state.Data} defaultDate={this.today} defaultView="day" item={this.CustomItem}>
							<AgendaView editable = {{
												add: false,
												drag: false,
												resize: false,
												select: false,
												edit: false,
												remove: false}} />
							<TimelineView />
							<DayView />
							<WeekView />
							<MonthView />
						</Scheduler>
					</IntlProvider>
				</LocalizationProvider>
				
			</div>
		);
	}
}

const mapStateToProps = (state: Store.Store) => ({
	UserSettings: state.User.UserSettings,
});

export default withTranslation()(connect(mapStateToProps)(CalendarScreen));
