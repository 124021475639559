import {
	LOGIN_USER,
	LOGIN_USER_ERROR,
	LOGIN_USER_SUCCESS,
	UPDATE_USER,
	SET_USER_SETTINGS,
	UPDATE_AVAILABLE,
} from '../actions/UserActions';

const User: Store.UserState = {
	UID: 0,
	Email: '',
	Loading: true,
	IsLoggedIn: false,
	Error: '',
	User: null,
	UserSettings: null,
	ClientID: 0,
	UpdateAvailable: false,
};

const UserReducer = (state = User, action) => {
	switch (action.type) {
		case LOGIN_USER:
			state = {
				...state,
				Loading: true,
			};
			return state;

		case LOGIN_USER_SUCCESS:
			state = {
				...state,
				// Loading: false,
				UID: action.payload.UID,
				Email: action.payload.Email,
				Error: '',
			};
			return state;

		case LOGIN_USER_ERROR:
			state = {
				...state,
				Loading: false,
				Error: action.payload,
			};
			return state;

		case UPDATE_AVAILABLE:
			state = {
				...state,
				UpdateAvailable: action.payload,
			};
			return state;

		case UPDATE_USER:
			state = {
				...state,
				User: action.payload,
			};
			return state;

		case SET_USER_SETTINGS:
			state = {
				...state,
				Loading: false,
				UserSettings: action.payload,
				ClientID: action.payload.ClientID,
			};
			return state;

		default:
			return state;
	}
};
export default UserReducer;
