import { Grid, IconButton, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import RemoveCircleOutlineIcon from '@material-ui/icons/RemoveCircleOutline';
import React from 'react';
import { useTranslation } from 'react-i18next';
import useStocksTable from '../../hooks/StorageAreas/useStocksTable';
import '../../styles/storage-areas/storage-area-table.css';
import TableDialog from './TableDialog';

interface StocksTableProps {
	storageArea: StorageArea;
	refetchStorageArea: () => void;
}

enum Action {
	ADD,
	REMOVE,
}

const StocksTable = ({ storageArea, refetchStorageArea }: StocksTableProps) => {
	const {
		isDialogOpen,
		handleOpenDialog,
		closeDialog,
		action,
		stockToChange,
		quantity,
		handleQuantityChange,
		comments,
		setComments,
		handleAction,
	} = useStocksTable(storageArea, refetchStorageArea);
	const [t, i18n] = useTranslation();

	return (
		<>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className='table-left-padding'>
							<p className="table-head-cell">{t("Stock Name")}</p>
						</TableCell>
						<TableCell align="right">
							<p className="table-head-cell">{t("Stock Quantity")}</p>
						</TableCell>
						<TableCell align="right" />
					</TableRow>
				</TableHead>
				<TableBody>
					{storageArea.StockToStorageArea.map((stock, index) => (
						<TableRow key={index}>
							<TableCell component="th" scope="row" className='table-left-padding'>
								<p className="table-body-cell">{stock.StockName}</p>
							</TableCell>
							<TableCell align="right">
								<p className="table-body-cell">{stock.CurrentLevel}</p>
							</TableCell>
							<TableCell align="right">
								<Grid container justify="flex-end" spacing={4}>
									<Grid item>
										<IconButton
											onClick={() => handleOpenDialog(Action.ADD, stock)}
											style={{ color: 'var(--dark-green)' }}
										>
											<AddCircleOutlineIcon fontSize="large" />
										</IconButton>
									</Grid>
									<Grid item>
										<IconButton
											onClick={() => handleOpenDialog(Action.REMOVE, stock)}
											style={{ color: 'var(--dark-red)' }}
										>
											<RemoveCircleOutlineIcon fontSize="large" />
										</IconButton>
									</Grid>
								</Grid>
							</TableCell>
						</TableRow>
					))}
				</TableBody>
			</Table>
			<TableDialog
				isOpen={isDialogOpen}
				action={action}
				stockToChange={stockToChange}
				quantity={quantity}
				handleQuantityChange={handleQuantityChange}
				comments={comments}
				setComments={setComments}
				handleAction={handleAction}
				handleClose={closeDialog}
			/>
		</>
	);
};

export default StocksTable;
