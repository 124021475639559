// React
import * as React from 'react';

interface FieldHolderNameProps {
	pullRight?: boolean;
	fullLine?: boolean;
	label: string;
	content: string;
}

const FieldHolderName = (props: FieldHolderNameProps) => (
	<div className={`${props.pullRight ? 'pull-right' : ''}${props.fullLine ? 'full-line-asset' : ''} assetField`}>
		<h3 className='field-holder-name'>
			{props.content}
		</h3>
		<p className="field-label-alt">{props.label}</p>
	</div>
);

export default FieldHolderName;