import { isNullOrUndefined } from 'util';
import {fire} from '../index';
import { generateFirebaseId } from './../utils/Guids';


//Public server key used for encryption, generated through the web-push Node.js library. The private key 
//is also needed for sending out push notifications, this will be contained in the C# push notification class.
const publicVapidKey = 'BHkF6bT7bXyk4iHubPWAWaiKmZQ-8xcpJwXCNRqurXarPIVYgSIrhE4oKjxKttb9pb8_FKMZWcHonXor9baFLE8';



//Subscribes the user to the push notification service.
export default function PushNotification(ResourceFBID: any) {

    
    if((/^((?!chrome|android).)*safari/i.test(navigator.userAgent)) == false){
    navigator.serviceWorker.ready.then(reg => {

        console.log("Service worker online.");
        if (!isNullOrUndefined(reg)) {

            //console.log("Not undefined");
            //Check to see if the user is already subscribed to the push manager.
            reg.pushManager.getSubscription().then(existingSub => {
                if (isNullOrUndefined(existingSub)){
                    reg.pushManager.subscribe({
                        userVisibleOnly: true,
                        applicationServerKey: urlBase64ToUint8Array(
                            publicVapidKey
                        )
                    }).then(subscription => {
                        //console.log("Not undefined");
                        //JSON'ified subscription object (containing client endpoint and auth key) is stored to PushSubscriptions collection inside of client ID.
                        //console.log(JSON.stringify(subscription));
                        let SubscriptionObject = subscription.toJSON();
                        let SubscriptionID = generateFirebaseId();
                        let JobQueueID = generateFirebaseId();

                        SubscriptionObject['JobAction'] = "NewPushSubscription";
                        SubscriptionObject['ResourceFBID'] = ResourceFBID;
                        SubscriptionObject['PushSubscriptionFBID'] = SubscriptionID;
                        SubscriptionObject['Status'] = "LIVE";
                        
                        fire.postSubscription(subscription.toJSON(), SubscriptionID);
                        fire.postToJobQueue(JobQueueID, SubscriptionObject).catch(err => {
                            console.error(err);
                            //console.log(SubscriptionObject);
                        });
                        
                    })
                        .catch(err => {
                            if (Notification.permission === 'denied') {
                                console.warn('Permission for notifications was denied');
                            } else {
                                console.error('Failed to subscribe the user: ', err);
                            }
                        });
                }
            });

            reg.pushManager.getSubscription()
                .then(subscription => console.log("Subscription object is: " + JSON.stringify(subscription)));
        }
    });
}
}


//Encryption function called when subscribing the public key.
function urlBase64ToUint8Array(base64String) {
    var padding = '='.repeat((4 - base64String.length % 4) % 4);
    var base64 = (base64String + padding)
        .replace(/\-/g, '+')
        .replace(/_/g, '/');

    var rawData = window.atob(base64);
    var outputArray = new Uint8Array(rawData.length);

    for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
}
